import "./mylisting.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "../Catogery/catogery.css";

import * as Yup from "yup";

import {
  Accessoriescard,
  FailureAlert,
  Footer,
  LoadingPage,
  Mylistingcard,
  Navbar,
  Scrollerbutton,
  Sliderproductcard,
} from "../../components";
import {
  BlackCamInput,
  Cross,
  ExcludeLeft,
  ExcludeRight,
  Filter,
  Filterwbg,
  GoPro,
  GoPro2,
  Laptop,
  Left,
  Macbook,
  MobileFilter,
  MobileFilterCloseBtn,
  OrderAccepted,
  Pc,
  PhotoCross,
  Playstation,
  Playstn,
  Right,
  SmallCross,
  Sort,
  UploadImg,
} from "../../assets";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  clearErrorSlice,
  createNewListingRequest,
  getUsersListigRequestApp,
  setError,
} from "../../redux/features/listingRequestInvenotry/linstingReqSlice";
import { useDispatch, useSelector } from "react-redux";

import Login from "../Login/login";
import { Navigation } from "swiper";
import PopUp from "../../components/PopUp/PopUp";
import React from "react";
import { SUCCESS } from "../../constants/constants";
import { createUserCartSlice } from "../../redux/features/loginSlice/loginSlice";
import { useEffect } from "react";
import { useFormik } from "formik";
import { usePopper } from "react-popper";
import { useState } from "react";

export const ListProductPopUp = ({ setIsListing, isListing }) => {
  const pinCodeRegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
  const [packageImage, setPackageImages] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [imageError, setImageError] = useState("\u00A0");

  const dispatch = useDispatch();
  const [accTags, setAcctags] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  function removeAccTag(index) {
    setAcctags(accTags.filter((el, i) => i !== index));
  }
  function addAccTag(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setAcctags([...accTags, value]);
    e.target.value = "";
  }
  const removeImage = (idxx) => {
    setImageArray(
      imageArray.filter((elm, idx) => {
        return idx !== idxx;
      })
    );
    setPackageImages(
      packageImage.filter((elm, idx) => {
        return idx !== idxx;
      })
    );
  };
  const addImgTag = (e) => {
    const imgFile = e.target.files;
    const imgFilesArray = Array.from(imgFile);
    const imagesArray = imgFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    for (const value of e.target.files) {
      packageImage.push(value);
    }
    packageImage.length < 1
      ? setImageError("Atleast 1 image is required")
      : setImageError("\u00A0");
    setImageArray([...imageArray, ...imagesArray]); //for client side display purpose
  };
  const customAlert = useSelector(
    (state) => state.rootReducer.listingRequest.isError
  );
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  const error = useSelector(
    (state) => state.rootReducer.listingRequest.errorData
  );
  const loading = useSelector(
    (state) => state.rootReducer.listingRequest.loading
  );
  const listProductFormik = useFormik({
    initialValues: {
      name: "",
      additionalInfo: "",
      accessories: "",
      warranty: "",
      city: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string("Must be a string").required(
        "Product name is required filled"
      ),
      additionalInfo: Yup.string("must be a String")
        .min(3, "add more Information")
        .max(100, "max length is 100"),
      pincode: Yup.string()
        .matches(pinCodeRegex, "please enter valid pincode")
        .required("pincode is required field"),
    }),
    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("pincode", values.pincode);
      formData.append("warranty", values.warranty);
      formData.append("additionalInfo", values.additionalInfo);
      //
      packageImage.forEach((element, idx) => {
        formData.append(`image`, element);
      });

      if (packageImage.length >= 1) {
        dispatch(createNewListingRequest(formData)).then((data) => {
          data.payload.type == "SUCCESS" && setIsDone(!isDone);
          setImageArray([]);
          setPackageImages([]);
          resetForm();
        });
      } else {
        dispatch(
          setError({
            message: "Images Error",
            type: "Validation Error",
            errors: [{ 1: "Atleast 1 image is required" }],
          })
        );
      }
    },
  });
  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div
        className="mylisting-newproduct-list-content"
        id={isListing.visible ? "fade-out" : "fade-in"}
      >
        <p id="title-new-list">
          New Listing{" "}
          <img
            src={SmallCross}
            alt="smallcross"
            onClick={() => setIsListing(false)}
            style={{
              // width: "2rem",
              // height: "2rem",
              cursor: "pointer",
            }}
          />
        </p>
        <label id="list-product-error">
          {listProductFormik.errors.name && listProductFormik.touched.name
            ? listProductFormik.errors.name
            : "\u00A0"}
        </label>
        <input
          type="text"
          name="name"
          value={listProductFormik.values.name}
          onChange={listProductFormik.handleChange}
          onBlur={listProductFormik.handleBlur}
          id=""
          placeholder="Enter your product name"
        />
        <label id="list-product-error">
          {listProductFormik.errors.additionalInfo &&
          listProductFormik.touched.additionalInfo
            ? listProductFormik.errors.additionalInfo
            : "\u00A0"}
        </label>
        <div className="mylisting-product-accessories-container">
          {accTags.map((accTag, index) => (
            <div className="mylisting-product-accessories-tags" key={index}>
              <span id="mylisting-prod-acc-text">{accTag}</span>
              <span
                id="mylisting-prod-acc-close"
                onClick={() => removeAccTag(index)}
              >
                <img
                  src={SmallCross}
                  alt="smallcross"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
          ))}

          <input
            // onKeyDown={addAccTag}
            name="additionalInfo"
            type="text"
            id="add-accessories"
            value={listProductFormik.values.additionalInfo}
            onChange={listProductFormik.handleChange}
            onBlur={listProductFormik.handleBlur}
            placeholder="Add Accessories/Additional Info"
          />
        </div>
        <p id="suggestion-text">
          <span>Suggestion: </span>
        </p>
        <label id="list-product-error">
          {" "}
          {listProductFormik.errors.pincode && listProductFormik.touched.pincode
            ? listProductFormik.errors.pincode
            : "\u00A0"}
        </label>
        <div className="mylisting-pincode-warrenty-container">
          <input
            type="text"
            name="pincode"
            id=""
            value={+listProductFormik.values.pincode.slice(0, 6)}
            onInput={(e) => e.target.value.slice(0, 5)}
            onChange={listProductFormik.handleChange}
            onBlur={listProductFormik.handleBlur}
            placeholder="Area pincode"
          />
          <input
            type="text"
            name="warranty"
            id=""
            value={listProductFormik.values.warranty}
            onChange={listProductFormik.handleChange}
            onBlur={listProductFormik.handleBlur}
            placeholder="Warranty if any"
          />
        </div>
        <label id="list-product-error" style={{ marginBottom: "-1rem" }}>
          {imageError}
        </label>
        <div className="mylisting-upload-img-container">
          {/* <input type="file" name="" id="" /> */}
          <p>Upload Image</p>
        </div>
        <div className="mylisting-prod-img-container">
          {imageArray.map((elm, idx) => (
            <div className="prod-image-card">
              <img
                id="photo-cross-img"
                src={PhotoCross}
                alt="photocros"
                style={{ width: "1rem", height: "1rem" }}
                onClick={() => removeImage(idx)}
              />
              <img
                id="prod-img"
                src={elm}
                alt="gopro"
                // stylref={setRef}
                e={{
                  width: "4rem",
                  height: "4rem",
                  borderRadius: "0.375rem",
                }}
              />
            </div>
          ))}

          <div className="prod-image-input-card">
            <label htmlFor="imageUpload">
              <img
                src={BlackCamInput}
                alt=""
                style={{
                  height: "3rem",
                  width: "3rem",
                  margin: ".6rem .6rem",
                  cursor:"pointer"
                }}
              />
              <input
                onBlur={() => {
                  setImageError("Atleast 1 image is required");
                }}
                type="file"
                multiple
                name="imageUpload"
                id="imageUpload"
                // onChange={(e) => {
                //   setImageArray([...imageArray, e.target.value]);
                // }}
                onChange={addImgTag}
              />
            </label>
          </div>
        </div>
        <button
          type="submit"
          onClick={() => {
            listProductFormik.handleSubmit();
          }}
        >
          Confirm Details
        </button>
        {isDone && (
          <>
            <div className="mylisting-congrulation-base-container">
              <div className="mylisting-congrulation-container">
                <div className="mylisting-congrulation-content">
                  <div className="mylisting-congrulation-text-container">
                    <img
                      src={OrderAccepted}
                      alt="orderaccept"
                      style={{
                        width: "7.434rem",
                        height: "4.063rem",
                      }}
                    />
                    <div className="mylisting-congrulation-desc">
                      <p id="congrulation-text">Congrulation</p>
                      <p id="congrulation-desc">
                        Your product has been sent on a job interview for
                        selection. We will notify you once it has been approved.
                      </p>
                    </div>
                  </div>

                  <div className="mylisting-suggestion-img-container">
                    <p>See around! Do you also have?</p>
                    <div className="mylisting-suggestion-img-content">
                      <img src={Playstn} alt="playstation" />
                      <img src={Laptop} alt="laptop" />
                      <img src={Playstation} alt="Playstation" />
                      <img src={GoPro} alt="gopro" />
                      <img src={Macbook} alt="macbook" />
                      <img src={Pc} alt="pc" />
                      <img src={GoPro2} alt="gopro2" />
                      <img src={Playstn} alt="playstation" />
                      <img src={Laptop} alt="laptop" />
                      <img src={Playstation} alt="playstation" />
                    </div>
                    <button
                      type="submit"
                      onClick={() => {
                        setIsDone(false);
                        setIsListing(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const formattedDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

const formattedNextDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 1
);

const Mylisting = () => {
  const [isListing, setIsListing] = useState(false);
  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const [addCart, setAddCart] = useState(false);
  const [startDateValue, setStartDateValue] = useState(formattedDate);
  const [endDateValue, setEndDateValue] = useState(formattedNextDate);
  const [selectedFreeAddons, setSelectedFreeAddons] = useState([]);
  const [selectedPaidAddons, setSelectedPaidAddons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [deliveryTime, setDeliveryTime] = useState({
    startHour: 10,
    endHour: 12,
  });


  const [isFilter, setIsFilter] = useState(false);
  const swiper = useSwiper();
  const [loginPopUp, setLoginPopUp] = useState(false);
  const error = useSelector(
    (state) => state.rootReducer.listingRequest.errorData
  );
  const customAlert = useSelector(
    (state) => state.rootReducer.listingRequest.isError
  );
  const loading = useSelector(
    (state) => state.rootReducer.listingRequest.loading
  );
  const listingRequestData = useSelector(
    (state) => state.rootReducer.listingRequest.fetchedData
  );
  const currentPackageDetails = useSelector(
    (state) => state.rootReducer.homePage.data.packageDetails
  );

  const updateDone = useSelector(
    (state) => state.rootReducer.listingRequest.updateDone
  );
  const updateDoneLogin = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );
  const [searchParams, setSearcParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) {
      dispatch(getUsersListigRequestApp());
    }
  }, []);

  // for updating dat on creating new list
  useEffect(() => {
    if (isLogin) {
      dispatch(getUsersListigRequestApp());
    }
  }, [updateDone, updateDoneLogin]);

  const handlefilterModal = () => {
    setIsFilter((prevState) => !prevState);
  };

  useEffect(() => {
    if (isListing) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  const setSort = (sort) => {
    let params = Object.fromEntries(searchParams);
    params["sort"] = sort;
    setSearcParams(createSearchParams(params));
  };
  //for removing added images from the array

  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  // for maintaining to array one for displaying purpose and one for sending data to form data
  // formik for collecting inputs
  // const listProductFormik = useFormik({
  //   initialValues: {
  //     name: "",
  //     additionalInfo: "",
  //     accessories: "",
  //     warranty: "",
  //     city: "",
  //     pincode: "",
  //   },
  //   validationSchema: Yup.object({
  //     name: Yup.string("Must be a string").required(
  //       "Product name is required filled"
  //     ),
  //     additionalInfo: Yup.string("must be a String")
  //       .min(3, "add more Information")
  //       .max(100, "max length is 100"),
  //     pincode: Yup.string()
  //       .matches(pinCodeRegex, "please enter valid pincode")
  //       .required("pincode is required field"),
  //   }),
  //   onSubmit: (values, { resetForm }) => {
  //     let formData = new FormData();
  //     formData.append("name", values.name);
  //     formData.append("pincode", values.pincode);
  //     formData.append("warranty", values.warranty);
  //     formData.append("additionalInfo", values.additionalInfo);
  //     //
  //     packageImage.forEach((element, idx) => {
  //       formData.append(`image`, element);
  //     });

  //     if (packageImage.length >= 5) {
  //       dispatch(createNewListingRequest(formData)).then((data) => {
  //         data.payload.type == "SUCCESS" && setIsDone(!isDone);
  //         setImageArray([]);
  //         setPackageImages([]);
  //         resetForm();
  //       });
  //     } else {
  //       dispatch(
  //         setError({
  //           message: "Images Error",
  //           type: "Validation Error",
  //           errors: [{ 1: "Atleast 5 images are required" }],
  //         })
  //       );
  //     }
  //   },
  // });

  function createCart() {
    const data = {
      packageId: currentPackageDetails._id,
      startDate: startDateValue,
      endDate: endDateValue,
      freeAddons: selectedFreeAddons,
      paidAddons: selectedPaidAddons,
      coupon: couponCode._id,
      deliveryTime: deliveryTime,
    };
    dispatch(createUserCartSlice(data)).then((data) => {
      if (data.payload.type == SUCCESS) {
        setAddCart(!addCart);
      }
    });
  }
  const [ref, setRef] = useState();
  const [pop, setPop] = useState();
  const popUpParams = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };

  const popper = usePopper(ref, pop, popUpParams);

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}

      <div className="mylisting-heading-container">
        <div className="mylisting-heading-content">
          <p>My Listing</p>
        </div>
      </div>

      <div className="mylisting-sorting-container">
        <div className="mylisting-sorting-content">
          <div className="mylisting-filter-container">
            <div className="mobile-view-filter-div">
              <img src={MobileFilter} alt="" />
            </div>
            <p>
              <img src={Sort} alt="sort" /> Sort:
            </p>
            <div
              className="mylisting-sorting-filter"
              style={{ cursor: "pointer" }}
              onClick={handlefilterModal}
              ref={setRef}
            >
              <p>
                Recent{" "}
                <img src={Filter} alt="filter" style={{ userSelect: "none" }} />
              </p>
            </div>
          </div>

          <div className="mylisting-btn-container">
            <Link
              to="/listing_home"
              style={{ textDecoration: "none", color: "#0E9BE1" }}
            >
              <button id="mylisting-learn-btn">Learn More</button>
            </Link>
            {isLogin ? (
              <button
                id="mylisting-list-btn"
                onClick={() => setIsListing(!isListing)}
              >
                List Product +
              </button>
            ) : (
              <>
                <button
                  id="mylisting-list-btn"
                  onClick={() => setLoginPopUp(true)}
                >
                  Login Please
                </button>
                <PopUp
                  display={loginPopUp}
                  callback={setLoginPopUp}
                  center={true}
                  uniqueId={"loginPopup"}
                >
                  <Login
                    loginClose={() => {
                      setLoginPopUp(false);
                    }}
                    addToCartCallback={createCart}
                  />
                </PopUp>
              </>
            )}
            {/* {loginPopUp && (
              <PopUp
                display={loginPopUp}
                callback={setLoginPopUp}
                center={true}
                uniqueId={"loginPopup"}
              >
                <Login
                  loginClose={() => {
                    setLoginPopUp(false);
                  }}
                />
              </PopUp>
            )} */}
            <PopUp
              display={isListing}
              callback={setIsListing}
              center={true}
              uniqueId={"listProdPopup"}
            >
              <ListProductPopUp
                setIsListing={setIsListing}
                isListing={isListing}
              />
            </PopUp>
            {/* {isListing && (
              <></>
              // <>
              //   <div className="mylisting-newproduct-list-container">
              //     <div className="mylistingheading-mobile-view-container">
              //       <p>
              //         New Listing{" "}
              //         <img
              //           src={SmallCross}
              //           onClick={() => setIsListing(!isListing)}
              //           alt=""
              //         />
              //       </p>
              //     </div>
              //     <div
              //       className="mylisting-newproduct-list-content"
              //       id={isListing.visible ? "fade-out" : "fade-in"}
              //     >
              //       <p>
              //         New Listing{" "}
              //         <img
              //           src={SmallCross}
              //           alt="smallcross"
              //           onClick={() => setIsListing(!isListing)}
              //           style={{
              //             width: "2rem",
              //             height: "2rem",
              //             cursor: "pointer",
              //           }}
              //         />
              //       </p>
              //       <label id="list-product-error">
              //         {listProductFormik.errors.name &&
              //         listProductFormik.touched.name
              //           ? listProductFormik.errors.name
              //           : "\u00A0"}
              //       </label>
              //       <input
              //         type="text"
              //         name="name"
              //         value={listProductFormik.values.name}
              //         onChange={listProductFormik.handleChange}
              //         onBlur={listProductFormik.handleBlur}
              //         id=""
              //         placeholder="Enter your product name"
              //       />
              //       <label id="list-product-error">
              //         {listProductFormik.errors.additionalInfo &&
              //         listProductFormik.touched.additionalInfo
              //           ? listProductFormik.errors.additionalInfo
              //           : "\u00A0"}
              //       </label>
              //       <div className="mylisting-product-accessories-container">
              //         {accTags.map((accTag, index) => (
              //           <div
              //             className="mylisting-product-accessories-tags"
              //             key={index}
              //           >
              //             <span id="mylisting-prod-acc-text">{accTag}</span>
              //             <span
              //               id="mylisting-prod-acc-close"
              //               onClick={() => removeAccTag(index)}
              //             >
              //               <img
              //                 src={SmallCross}
              //                 alt="smallcross"
              //                 style={{
              //                   width: "1rem",
              //                   height: "1rem",
              //                   cursor: "pointer",
              //                 }}
              //               />
              //             </span>
              //           </div>
              //         ))}

              //         <input
              //           // onKeyDown={addAccTag}
              //           name="additionalInfo"
              //           type="text"
              //           id="add-accessories"
              //           value={listProductFormik.values.additionalInfo}
              //           onChange={listProductFormik.handleChange}
              //           onBlur={listProductFormik.handleBlur}
              //           placeholder="Add Accessories/Additional Info"
              //         />
              //       </div>
              //       <p id="suggestion-text">
              //         <span>Suggestion: </span>
              //       </p>
              //       <label id="list-product-error">
              //         {" "}
              //         {listProductFormik.errors.pincode &&
              //         listProductFormik.touched.pincode
              //           ? listProductFormik.errors.pincode
              //           : "\u00A0"}
              //       </label>
              //       <div className="mylisting-pincode-warrenty-container">
              //         <input
              //           type="text"
              //           name="pincode"
              //           id=""
              //           value={listProductFormik.values.pincode}
              //           onChange={listProductFormik.handleChange}
              //           onBlur={listProductFormik.handleBlur}
              //           placeholder="Area pincode"
              //         />
              //         <input
              //           type="text"
              //           name="warranty"
              //           id=""
              //           value={listProductFormik.values.warranty}
              //           onChange={listProductFormik.handleChange}
              //           onBlur={listProductFormik.handleBlur}
              //           placeholder="Warranty if any"
              //         />
              //       </div>
              //       <label
              //         id="list-product-error"
              //         style={{ marginBottom: "-1rem" }}
              //       >
              //         {imageError}
              //       </label>
              //       <div className="mylisting-upload-img-container">
              //         <input type="file" name="" id="" />
              //         <p>Upload Image</p>
              //       </div>
              //       <div className="mylisting-prod-img-container">
              //         {console.log(imageArray)}
              //         {imageArray.map((elm, idx) => (
              //           <div className="prod-image-card">
              //             <img
              //               id="photo-cross-img"
              //               src={PhotoCross}
              //               alt="photocros"
              //               style={{ width: "1rem", height: "1rem" }}
              //               onClick={() => removeImage(idx)}
              //             />
              //             <img
              //               id="prod-img"
              //               src={elm}
              //               alt="gopro"
              //               stylref={setRef}
              //               e={{
              //                 width: "4rem",
              //                 height: "4rem",
              //                 borderRadius: "0.375rem",
              //               }}
              //             />
              //           </div>
              //         ))}

              //         <div className="prod-image-input-card">
              //           <label htmlFor="imageUpload">
              //             <img
              //               src={BlackCamInput}
              //               alt=""
              //               style={{
              //                 height: "3rem",
              //                 width: "3rem",
              //                 margin: ".6rem .6rem",
              //               }}
              //             />
              //             <input
              //               onBlur={() => {
              //                 setImageError("5 Images are Required");
              //               }}
              //               type="file"
              //               multiple
              //               name="imageUpload"
              //               id="imageUpload"
              //               // onChange={(e) => {
              //               //   setImageArray([...imageArray, e.target.value]);
              //               // }}
              //               onChange={addImgTag}
              //             />
              //           </label>
              //         </div>
              //       </div>
              //       <button
              //         type="submit"
              //         onClick={() => {
              //           listProductFormik.handleSubmit();
              //         }}
              //       >
              //         Confirm Details
              //       </button>
              //       {isDone && (
              //         <>
              //           <div className="mylisting-congrulation-base-container">
              //             <div className="mylisting-congrulation-container">
              //               <div className="mylisting-congrulation-content">
              //                 <div className="mylisting-congrulation-text-container">
              //                   <img
              //                     src={OrderAccepted}
              //                     alt="orderaccept"
              //                     style={{
              //                       width: "7.434rem",
              //                       height: "4.063rem",
              //                     }}
              //                   />
              //                   <div className="mylisting-congrulation-desc">
              //                     <p id="congrulation-text">Congrulation</p>
              //                     <p id="congrulation-desc">
              //                       Your product has been sent on a job
              //                       interview for selection. We will notify you
              //                       once it has been approved.
              //                     </p>
              //                   </div>
              //                 </div>

              //                 <div className="mylisting-suggestion-img-container">
              //                   <p>See around! Do you also have?</p>
              //                   <div className="mylisting-suggestion-img-content">
              //                     <img src={Playstn} alt="playstation" />
              //                     <img src={Laptop} alt="laptop" />
              //                     <img src={Playstation} alt="Playstation" />
              //                     <img src={GoPro} alt="gopro" />
              //                     <img src={Macbook} alt="macbook" />
              //                     <img src={Pc} alt="pc" />
              //                     <img src={GoPro2} alt="gopro2" />
              //                     <img src={Playstn} alt="playstation" />
              //                     <img src={Laptop} alt="laptop" />
              //                     <img src={Playstation} alt="playstation" />
              //                   </div>
              //                   <button
              //                     type="submit"
              //                     onClick={() => {
              //                       setIsDone(!isDone);
              //                       setIsListing(!isListing);
              //                     }}
              //                   >
              //                     Done
              //                   </button>
              //                 </div>
              //               </div>
              //             </div>
              //           </div>
              //         </>
              //       )}
              //     </div>
              //   </div>
              // </>
            )} */}
          </div>
        </div>
      </div>

      <PopUp display={isFilter} callback={setIsFilter} popper={popper}>
        <div
          className="price-filtering"
          ref={setPop}
          style={{ ...popper?.styles?.popper }}
          {...popper?.attributes}
        >
          <div className="high-to-low">
            <input
              type="radio"
              name="recent"
              id="htol"
              checked={parseInt(searchParams.get("sort")) == -1}
              onChange={() => {
                setSort(-1);
              }}
            />
            <label htmlFor="old">Recent</label>
          </div>

          <div className="low-to-high">
            <input
              type="radio"
              name="old"
              id="ltoh"
              checked={parseInt(searchParams.get("sort")) == 1}
              onChange={() => {
                setSort(1);
              }}
              style={{ marginLeft: "-0.7rem", marginRight: "-1rem" }}
            />
            <label htmlFor="old" style={{ marginLeft: "1rem" }}>
              Old
            </label>
          </div>
        </div>
      </PopUp>

      <div className="mylisting-card-container">
        <div className="mylisting-card-content">
          {listingRequestData.map((elm, idx) => (
            <Mylistingcard
              elm={elm}
              img={elm?.productInfo?.images[0]}
              className="prod-status-approval-pending"
              status={elm.status}
            />
          ))}

          {/* <Mylistingcard
              className="prod-status-approved"
              status="Approved"
              agreeContainer="mylisting-prod-card-agreement-container"
            /> */}
        </div>
      </div>

      {/* <div className="mylisting-accessories-card-container">
        <div className="mylisting-accessories-card-content">
          <div className="trending-text-container">
            <h1 className="trendText">Lend And Get</h1>
            <div className="trendButton">
              <div className="leftButton" onClick={() => swiper.slidePrev()}>
                <img
                  src={Left}
                  onMouseOver={(e) => (e.currentTarget.src = ExcludeLeft)}
                  onMouseOut={(e) => (e.currentTarget.src = Left)}
                  className="leftVector"
                />
              </div>
              <div className="rightButton" onClick={() => swiper.slideNext()}>
                <img
                  src={Right}
                  onMouseOver={(e) => (e.currentTarget.src = ExcludeRight)}
                  onMouseOut={(e) => (e.currentTarget.src = Right)}
                  className="rightVector"
                />
              </div>
            </div>
          </div>
          <div className="mylisting-card-slider-container">
            <Accessoriescardslider />
          </div>
        </div>
      </div> */}

      {/* <Scrollerbutton /> */}

      <Footer />
    </>
  );
  // }
};

export default Mylisting;
