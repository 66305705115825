import { LOGGEDIN } from "../../constants/constants";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { loginByToken } from "../../redux/features/loginSlice/loginSlice";
import { useEffect } from "react";
import { LocationPopUp } from "../../components/Navbar/navbar";

const Homeredirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const publicLocation = useSelector(
    (state) => state.rootReducer.userInfo.data.lastLocation
  );
  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const loginCount = useSelector(
    (state) => state.rootReducer.userInfo.loginCount
  );
  useEffect(() => {
    console.log("login count", loginCount);
    let home = `/home`;
    console.log(publicLocation, "1st log");
    // console.log(
    //   searchParams.get("refer_id") && searchParams.get("refer_id") != "null"
    // );
    if (searchParams.get("refer_id")) {
      home = home + `?refer_id=${searchParams.get("refer_id")}`;
    }
    dispatch(loginByToken()).then((data) => {
      if (data?.payload?.type == LOGGEDIN) {
        console.log(publicLocation, "1st log");
        navigate(
          `/${data?.payload?.data?.lastLocation?.toLowerCase() || ""}${home}`
        );
      } else {
        console.log(publicLocation, "1st log");

        // navigate("/mumbai/home")
        // navigate("/preAuth");
        // navigate(`/${publicLocation?.toLowerCase() || "mumbai"}${home}`);
      }
    });
  }, []);

  const [open, setOpen] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    !isLogin &&
    loginCount < 1 && (
      <>
        <LocationPopUp
        isOpen={open}
        setIsOpen={setOpen}
        toggleMenu={setToggleMenu}
      />
      </>
    )
  );
};

export default Homeredirect;
