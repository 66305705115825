import "./adminblogcard.css";

import {
  Option,
  //  Blog1
} from "../../../assets";
import { Link } from "react-router-dom";
import { deleteBlogByIdSlice } from "../../../redux/features/blogReqSlice";

import { ADMIN_FRONTEND_POST_BLOG } from "../../../constants/links";
import React from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";

const Adminblogcard = (props) => {
  const [isDropdown, setIsDropdown] = useState(false);

  // Async thunk
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="admin-blog-card-container">
        <div className="admin-blog-card-img-container">{props.imgSrc}</div>
        <div className="admin-blog-card-desc-container">
          <p id="admin-blog-card-heading-text">{props.heading}</p>
          <div className="admin-blog-card-catogery-container">
            <p>{props.category}</p>
          </div>
          <p id="admin-blog-card-desc-text">{props.desc}</p>
          <div className="admin-blog-card-author-container">
            <p>{props.author}</p>
            <p>{props.date}</p>
          </div>
        </div>
        <img
          src={Option}
          alt="option"
          style={{ width: "1.5rem", height: "2rem" }}
          onClick={() => setIsDropdown(!isDropdown)}
        />
        {isDropdown && (
          <>
            <div className="admin-blog-card-is-dropdown-container">
              <Link to={ADMIN_FRONTEND_POST_BLOG(props.blogId)}>Edit Blog</Link>
              <p
                id="remove-text"
                onClick={() => {
                  dispatch(deleteBlogByIdSlice(props.normalId));
                }}
              >
                Delete Blog
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Adminblogcard;
