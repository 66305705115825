import "./gstdetailspopup.css";
import * as Yup from "yup";

import React, { useEffect } from "react";
import {
  addGstDetailsToCartSlice,
  getUserCartByIdSlice,
  // getUserCartSlice,
} from "../../redux/features/loginSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";

import { SUCCESS } from "../../constants/constants";
import { SmallCross } from "../../assets";
import { useFormik } from "formik";
// import { useParams } from "react-router-dom";

const Gstdetailspopup = (props) => {
  // Async thunk
  const dispatch = useDispatch();
  const getUserCartById = useSelector(
    (state) => state.rootReducer.userInfo.currentUserCart
  );

  const updateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );

  useEffect(() => {
    dispatch(getUserCartByIdSlice(props.cartId));
  }, [updateDone]);

  const gstDetails = useFormik({
    initialValues: {
      companyName: getUserCartById?.gstDetails?.companyName,
      gstNo: getUserCartById?.gstDetails?.gstNo,
      gstAddress: getUserCartById?.gstDetails?.gstAddress,
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .min(2, "Must be of 2 characters or more")
        .max(300, "Must be of 300 characters or less"),
      gstNo: Yup.string()
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid gstNo"
        )
        .min(15, "Must be of 15 characters")
        .max(15, "Must be of 15 characters"),
    }),
    onSubmit: (values) => {
      const data = {
        cartId: props.cartId,
        companyName: values.companyName,
        gstNo: values.gstNo,
        gstAddress: values.gstAddress,
      };
      dispatch(addGstDetailsToCartSlice(data)).then((data) => {
        if (data.payload.type === SUCCESS) {
          props.onclick();
        }
      });
    },
  });

  if (window.innerWidth <= 991) {
    return (
      // <>
      //   <div className="tablet-gst-details-container">
      <div className="tablet-gst-details-content">
        <p>
          GST Details{" "}
          <img
            src={SmallCross}
            alt="cross"
            style={{
              width: "1.75rem",
              height: "1.75rem",
              cursor: "pointer",
            }}
            onClick={props.onclick}
          />
        </p>

        <input type="text" name="cname" id="" placeholder="Company name" />

        <input type="text" name="gstnumber" id="" placeholder="GST number" />

        <button type="submit">Claim GST</button>
      </div>
      //   </div>
      // </>
    );
  } else {
    return (
      // <>
      //   <div className="delivery-gst-details-container">
      <div className="delivery-gst-details-content">
        <p>
          GST Details{" "}
          <img
            src={SmallCross}
            alt="cross"
            style={{
              width: "2rem",
              height: "2rem",
              cursor: "pointer",
            }}
            onClick={props.onclick}
          />
        </p>

        <div
          className="add-new-admin-order-container"
          style={{ marginTop: "1rem" }}
        >
          <label htmlFor="" style={{ color: "#d34e4e" }}>
            {gstDetails.errors.companyName && gstDetails.touched.companyName
              ? gstDetails.errors.companyName
              : "\u00A0"}
          </label>
          <label
            htmlFor=""
            style={{
              color:
                !gstDetails.errors.gstNo &&
                gstDetails.touched.gstNo &&
                gstDetails.values.gstNo
                  ? "#008000"
                  : "#d34e4e",
            }}
          >
            {gstDetails.errors.gstNo && gstDetails.touched.gstNo
              ? gstDetails.errors.gstNo
              : "\u00A0"}
            {!gstDetails.errors.gstNo &&
            gstDetails.touched.gstNo &&
            gstDetails.values.gstNo
              ? "GST No Is Valid"
              : "\u00A0"}
          </label>
        </div>
        <div
          className="delivery-gst-no-container"
          style={{ marginTop: "1rem" }}
        >
          <input
            type="text"
            name="companyName"
            id=""
            placeholder="Company name"
            value={gstDetails.values.companyName}
            onChange={gstDetails.handleChange}
            onBlur={gstDetails.handleBlur}
          />
          <input
            type="text"
            name="gstNo"
            id=""
            placeholder="GST number"
            value={gstDetails.values.gstNo}
            onChange={gstDetails.handleChange}
            onBlur={gstDetails.handleBlur}
          />
        </div>

        <div
          className="add-new-admin-order-container"
          style={{ marginTop: "1.5rem" }}
        >
          <label htmlFor="" style={{ color: "#d34e4e" }}>
            {gstDetails.errors.gstAddress && gstDetails.touched.gstAddress
              ? gstDetails.errors.gstAddress
              : "\u00A0"}
          </label>
        </div>
        <input
          style={{ marginTop: "1rem" }}
          className="gstadd-input"
          type="text"
          name="gstAddress"
          id="gadd"
          placeholder="GST Address"
          value={gstDetails.values.gstAddress}
          onChange={gstDetails.handleChange}
          onBlur={gstDetails.handleBlur}
        />

        <button type="submit" onClick={gstDetails.handleSubmit}>
          Claim GST
        </button>
      </div>
      //   </div>
      // </>
    );
  }
};

export default Gstdetailspopup;
