import "./errorboundaries.css";

import { Learn } from "../../assets";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, info) {
    this.setState({
      error: error,
      errorInfo: info,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="error-basecontainer">
          <div className="error-logo">
            <img src={Learn} alt="" />
          </div>
          <h2>Something went wrong....</h2>
          <p
            onClick={() => (window.location.href = window.location.origin)}
            className="redirect"
          >
            Click here to redirect
          </p>
          {process.env.NODE_ENV == "development" && (
            <details style={{ whiteSpace: "pre-wrap", color: "white" }}>
              <summary>Details</summary>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
