import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOADING,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Swiper from "swiper";
import axios from "axios";

export const getInvMaxcount = createAsyncThunk(
  "listed_inventory/get_inv_maxcount",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/listed_inventory/get_inv_maxcount`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createNewProductAndAddAssetThunk = createAsyncThunk(
  "create_asset_andAddAsset/admin/listed_inventory/",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/create_inventory_addAssetlist`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllInventoryItemsThunk = createAsyncThunk(
  "get/admin/listed_inventory/",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/get_inventory`,
        payload
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchAllInventoryItemsThunk = createAsyncThunk(
  "get/admin/listed_inventory/get_all_inventory",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/get_all_inventory`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const findInvItemByIdThunk = createAsyncThunk(
  "admin/listed_inventory/id",
  async (payload) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/listed_inventory/${payload.currId}`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const maintenanceProduct = createAsyncThunk(
  "fetch_maintenanceproductDetail/listed_inventory/maintenance/list",
  async (payload) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/listed_inventory/${payload.currId}`
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeInventoryAssetStatus = createAsyncThunk(
  "/admin/listed_inventory/change_asset_status",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/change_asset_status`,
        {
          currId: payload.currId,
          data: payload.data,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Changing Asset status to Rented from instore
export const changeRenteduInvStatus = createAsyncThunk(
  "/admin/listed_inventory/change_rent_status",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/change_rent_status`,
        payload
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// Changing Asset status to instore from Rented
export const instoreAsset = createAsyncThunk(
  "/admin/listed_inventory/istore_asset",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/instore_asset`,
        payload
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeInvetoryItem = createAsyncThunk(
  "admin/listed_inventory/remove",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/remove`,
        {
          assetId: id,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addMaintenanceInfo = createAsyncThunk(
  "post/admin/listed_inventory/addmaintenance_info",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/addmaintenance_info`,
        {
          currId: payload.currId,
          maintenanceInfo: payload.maintenanceInfo,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getMaintenanceList = createAsyncThunk(
  "post/admin/listed_inventory/get_maintenancelist",
  async ({ data }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/get_maintenancelist`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getProductListByStatus = createAsyncThunk(
  "/admin/listed_inventory/get_productlist",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/get_productlist`,
        {
          status: payload.status,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createInventoryThunkV1 = createAsyncThunk(
  "createInventory_v1",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/create_inventory_v1`,

        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
// =================Edit Asset ===========================
export const editAssetThunk = createAsyncThunk("edit/asset", async (data) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/admin/listed_inventory/edit_asset`,

      data
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
});
// ==========================================================

export const updateAssetsOrderDetailsThunk = createAsyncThunk(
  "update_asset_order_details",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/update_asset_order_details`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteAssetImageThunk = createAsyncThunk(
  "delete/delete_asset_image",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/delete_asset_image`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllProductsThunk = createAsyncThunk(
  "post/admin/listed_inventory/getAllProduct",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listed_inventory/getAllProduct`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  isError: false,
  inventoryCount: 0,
  maintenanceMorInfoId: "",
  currentProduct: "",
  updateDone: false,
  currentCategory: "GAMING",
  data: {
    assignedAssets: [],
    maintenanceList: [],
    products: [],

    productDetail: {},
    maintenanceProductDetail: {},
    productAvailability: [],
    productAvailabilityDisplay: [],
    allProduct: [],
  },
  errorData: {
    message: "",
    type: " ",
    errors: [],
  },
  updateDone: false,
  status: {
    createInventoryThunkV1: IDLE,
    getAllInventoryItemsThunk: IDLE,
    updateAssetsOrderDetailsThunk: IDLE,
    deleteAssetImageThunk: IDLE,
    getAllProductsThunk: IDLE,
  },
};

const inventorySlice = createSlice({
  name: "inventorySlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, { payload }) => {
      state.isError = false;
      state.errorData = {};
    },
    changeCategory: (state, { payload }) => {
      state.currentCategory = payload.category;
    },
    setProductDetailId: (state, { payload }) => {
      state.currentProduct = payload.currId;
    },
    setInventoryError: (state, { payload }) => {
      state.isError = true;
      state.errorData = {
        message: payload.message,
        type: payload.type,
        errors: payload.errors,
      };
    },
    clearError: (state, { payload }) => {
      state.isError = false;
      state.errorData = {};
    },
    setMaintenanceMorInfoId: (state, { payload }) => {
      state.maintenanceMorInfoId = payload.id;
    },
    filterSubCatData: (state, { payload }) => {
      state.data.products = payload.data;
    },
    searchingData: (state, { payload }) => {
      state.data.searchedData = payload.data;
    },
    assignAssets: (state, { payload }) => {
      state.data.assignedAssets = payload;
    },
    setProductAvailability: (state, { payload }) => {
      state.data.productAvailabilityDisplay = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvMaxcount.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getInvMaxcount.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.inventoryCount = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getInvMaxcount.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      //  create new product and add AssetThunk
      .addCase(
        createNewProductAndAddAssetThunk.pending,
        (state, { payload }) => {
          state.loading = true;
        }
      )
      .addCase(
        createNewProductAndAddAssetThunk.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.updateDone = !state.updateDone;
              state.status.createNewProductThunk = FULFILLED;
              break;
            default:
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        createNewProductAndAddAssetThunk.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.isError = true;
          state.errorData = REJECTED_ERROR;
        }
      )

      .addCase(createInventoryThunkV1.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createInventoryThunkV1.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            // state.status.createNewProductThunk = FULFILLED;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createInventoryThunkV1.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // editAssetThunk
      .addCase(editAssetThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(editAssetThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            // state.status.createNewProductThunk = FULFILLED;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(editAssetThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all inventory items
      .addCase(getAllInventoryItemsThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAllInventoryItemsThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllInventoryItemsThunk = FULFILLED;
            state.data.products = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllInventoryItemsThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // fetch all inventory items
      // .addCase(fetchAllInventoryItemsThunk.pending, (state, { payload }) => {
      //   state.loading = true;
      // })
      // .addCase(fetchAllInventoryItemsThunk.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   switch (payload.type) {
      //     case SUCCESS:
      //       state.status.getAllInventoryItemsThunk = FULFILLED;
      //       state.data.products = payload.data;
      //       state.data.searchedData = payload.data;

      //       break;
      //     default:
      //       state.isError = true;
      //       state.errorData = {
      //         message: payload.message,
      //         type: payload.type,
      //         errors: payload.errors,
      //       };
      //   }
      // })
      // .addCase(fetchAllInventoryItemsThunk.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   state.isError = true;
      //   state.errorData = REJECTED_ERROR;
      // })
      // ========================================================//
      // find inventory item by id
      .addCase(findInvItemByIdThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(findInvItemByIdThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.productDetail = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(findInvItemByIdThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // ========================================================//
      // maintenance product
      .addCase(maintenanceProduct.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(maintenanceProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.maintenanceProductDetail = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(maintenanceProduct.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // ========================================================//
      // remove inventory item
      .addCase(removeInvetoryItem.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(removeInvetoryItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(removeInvetoryItem.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // ========================================================//
      // change inventory asset status
      .addCase(changeInventoryAssetStatus.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(changeInventoryAssetStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(changeInventoryAssetStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // ========================================================//
      // add maintenance info
      .addCase(addMaintenanceInfo.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(addMaintenanceInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addMaintenanceInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // ========================================================//
      // get maintenancelist
      .addCase(getMaintenanceList.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getMaintenanceList.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.maintenanceList = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getMaintenanceList.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // ========================================================//
      // get product list by status
      .addCase(getProductListByStatus.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getProductListByStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.productAvailability = payload.data;
            state.data.productAvailabilityDisplay = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getProductListByStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = {
          message: payload.message,
          type: payload.type,
          errors: payload.errors,
        };
      })
      // ========================================================//
      .addCase(changeRenteduInvStatus.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(changeRenteduInvStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(changeRenteduInvStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // ========================================================//
      .addCase(instoreAsset.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(instoreAsset.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(instoreAsset.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // complete order ========================================================//
      .addCase(updateAssetsOrderDetailsThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(
        updateAssetsOrderDetailsThunk.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.status.updateAssetsOrderDetailsThunk = FULFILLED;
          switch (payload.type) {
            case SUCCESS:
              state.updateDone = !state.updateDone;
              break;
            default:
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(updateAssetsOrderDetailsThunk.rejected, (state, { payload }) => {
        state.status.updateAssetsOrderDetailsThunk = ERROR;
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // deleteAssetImageThunk
      .addCase(deleteAssetImageThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteAssetImageThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status.deleteAssetImageThunk = FULFILLED;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteAssetImageThunk.rejected, (state, { payload }) => {
        state.status.deleteAssetImageThunk = ERROR;
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      //getAllProductsThunk===================================================================
      .addCase(getAllProductsThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAllProductsThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data.allProduct = payload.data;
        state.status.getAllProductsThunk = FULFILLED;
        switch (payload.type) {
          case SUCCESS:
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllProductsThunk.rejected, (state, { payload }) => {
        state.status.getAllProductsThunk = ERROR;
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      });
  },
  // extraReducer: {},
});

export default inventorySlice.reducer;
export const {
  clearError,
  setProductDetailId,
  clearErrorSlice,
  changeCategory,
  filterSubCatData,
  setMaintenanceMorInfoId,
  searchingData,
  assignAssets,
  setProductAvailability,
  setInventoryError,
} = inventorySlice.actions;
