// import * as Yup from "yup";

import { FailureAlert, LoadingPage } from "../..";
import {
  clearErrorSlice,
  deleteExtensionOfferSlice,
  getExtensionOfferSlice,
  pushExtensionOfferSlice,
  setError,
} from "../../../redux/features/orderSlice/orderSlice";
import {
  getAdminPrice,
  getPackagePriceSlice,
} from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import {
  // getDaysByDuration,
  getDuration,
  priceFormatterrrr,
} from "../../../utils/displayUtils";
import { useDispatch, useSelector } from "react-redux";

import { PopUpClose } from "../../../assets";
import React from "react";
import { SUCCESS } from "../../../constants/constants";
import lodash from "lodash";
import { useEffect } from "react";
// import { useFormik } from "formik";
// import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Extendpackage = (props) => {
  const [pushoffer, setpushoffer] = useState(false);
  // const [tenure, setTenure] = useState(1);
  const [currDuration, setCurrDuration] = useState();
  const [newDuration, setNewDuration] = useState();
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    if (pushoffer) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  // Async thunk
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const getExtensionOffer = useSelector(
    (state) => state.rootReducer.orderInfo.data.extensionOffer
  );

  const updateDone = useSelector(
    (state) => state.rootReducer.orderInfo.updateDone
  );

  const getPackagePrice = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.packageBasePrice
  );

  const fetchedPrices = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.adminPrices
  );
  // Constant Error
  const loading = useSelector((state) => state.rootReducer.orderInfo.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.orderInfo.isError
  );

  const error = useSelector((state) => state.rootReducer.orderInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  const priceWithDiscount = (discount, newDuration) => {
    return (
      fetchedPrices[newDuration] - (discount / 100) * fetchedPrices[newDuration]
    );
  };

  const getDaysCount = (dur) => {
    switch (parseInt(dur)) {
      case 30:
        return 1;
      case 90:
        return 3;
      case 180:
        return 6;
      case 365:
        return 12;
      default:
        return;
    }
  };
  const newPrice = priceWithDiscount(discount, newDuration);

  const oldPriceFunction = (price, duration) => {
    if (duration < 30) {
      return lodash.round(price / duration, 2);
    }
    if (duration >= 30) {
      return lodash.round(price / getDaysCount(duration), 2);
    }
  };

  const newPriceFunction = (price, duration) => {
    if (duration < 30) {
      return lodash.round(price / duration, 2);
    }
    if (duration >= 30) {
      return lodash.round(price / getDaysCount(duration), 2);
    }
  };

  const createOffer = () => {
    const data = {
      orderId: props.orderId,
      currPrice: oldPriceFunction(fetchedPrices[currDuration], currDuration),
      newPrice: newPriceFunction(newPrice, newDuration),
      duration: newDuration,
      discount: discount,
    };
    if (getExtensionOffer[0]?.extensionOffer?.length < 5) {
      dispatch(pushExtensionOfferSlice(data)).then((data) => {
        if (data.payload.type === SUCCESS) {
        }
      });
    } else {
      dispatch(
        setError({
          message: "You cannot add more than 5 offers",
          type: "Validation Error",
          errors: [{ 1: "Offer cannot be more than 5" }],
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getExtensionOfferSlice(props.orderId));
    dispatch(getPackagePriceSlice(props.packageId));
  }, [updateDone]);

  useEffect(() => {
    dispatch(getAdminPrice({ price: getPackagePrice[0]?.price }));
  }, [getPackagePrice]);

  function Pushoffer() {
    return (
      <div className="base-offer-push-popup-container">
        <div className="push-offer-popup-container">
          <div className="push-offer-popup-title-container">
            <p id="push-offertitle">
              {getExtensionOffer[0]?.extensionOffer?.length} Extension Offer has
              been sent to {props.username}
            </p>
          </div>
          <div className="push-offer-popup-btn-container">
            <button
              onClick={() => {
                setpushoffer(!pushoffer);
                props.changestate(props.extendpackage);
              }}
              id="pushoffer-btn"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div className="create-offer-extension-popup-container">
        <div className="create-offer-extension-popup-inner-container">
          <div className="extension-popup-head-container">
            <p id="create-offer-popup-head">Extension Offer</p>
            <img
              onClick={() => props.changestate(props.extendpackage)}
              id="create-offer-popup-close-icon"
              src={PopUpClose}
              alt="closeicon"
            />
          </div>
          <div className="extension-popup-body-container">
            <div className="extension-popup-body-left-container">
              <div className="currentrentprice-container extension-popup-inner-container-padding">
                <p className="extension-popup-title">Current Rent Price:</p>
                <div className="extension-popup-oneline-container">
                  <input
                    id="e-priceinput"
                    type="number"
                    name="currPrice"
                    disabled
                    value={fetchedPrices[currDuration]}
                    placeholder="Enter price"
                    className="extension-popup-input"
                    autoFocus
                  />
                  <select
                    style={{ width: "9rem" }}
                    id="e-priceinput"
                    className="extension-popup-input"
                  >
                    <option value="" selected disabled>
                      {getDuration(newDuration)
                        ? getDuration(newDuration)
                        : "Duration"}
                    </option>
                  </select>
                </div>
              </div>
              <div className="newrentprice-container extension-popup-inner-container-padding">
                <div className="e-newprice-price">
                  <p
                    style={{ color: "#163F7C" }}
                    className="extension-popup-title"
                  >
                    New Rent Price:
                  </p>
                  <div className="extension-popup-oneline-container">
                    <input
                      id="newprice-input"
                      type="number"
                      name="newPrice"
                      placeholder="Enter price"
                      disabled
                      value={priceWithDiscount(discount, newDuration)}
                      className="extension-popup-input"
                    />
                    <select
                      id="newprice-select"
                      className="extension-popup-input"
                      onChange={(e) => {
                        setNewDuration(e.target.value);
                        setCurrDuration(e.target.value);
                      }}
                    >
                      <option value="" disabled selected>
                        Duration
                      </option>
                      <option value="1">1 Day</option>
                      <option value="3">3 Days</option>
                      <option value="7">1 Week</option>
                      <option value="30">1 Mth</option>
                      <option value="90">3 Mths</option>
                      <option value="180">6 Mths</option>
                      <option value="365">1 Year</option>
                    </select>
                  </div>
                </div>
                {/* <div className="e-duration-container">
                  <p
                    style={{ color: "#7C3E99" }}
                    className="extension-popup-title"
                  >
                    Duration:
                  </p>
                  <div className="extension-popup-oneline-container">
                    <input
                      id="duration-input"
                      type="number"
                      min={0}
                      name="duration"
                      className="extension-popup-input"
                      value={offerDetails.values.duration}
                      onChange={offerDetails.handleChange}
                      onBlur={offerDetails.handleBlur}
                    />
                    <select
                      id="duration-select"
                      className="extension-popup-input"
                      onChange={(e) => {
                        setTenure(e.target.value);
                      }}
                    >
                      <option value="1">Month</option>
                      <option value="0">Day</option>
                    </select>
                  </div>
                </div> */}
              </div>
              <div className="discount-given-container extension-popup-inner-container-padding">
                <div className="extension-popup-oneline-container">
                  <p
                    style={{ color: "#1D602C" }}
                    className="extension-popup-title"
                  >
                    Discount Given:
                  </p>
                  <input
                    id="discountgiven-input"
                    type="number"
                    min={0}
                    max={99}
                    name="discount"
                    onChange={(e) => setDiscount(e.target.value)}
                    className="extension-popup-input"
                  />
                </div>
              </div>
              <div className="extension-popup-body-left-container-buttons-container extension-popup-inner-container-padding">
                <div className="e-popup-leftbtncontainer-innerdiv">
                  <button
                    type="submit"
                    id="creater-offer-btn"
                    onClick={createOffer}
                  >
                    Create Offer
                  </button>
                  <button
                    id="push-offer-btn"
                    onClick={() => {
                      setpushoffer(!pushoffer);
                    }}
                  >
                    Push Offer
                  </button>
                  {pushoffer && <Pushoffer />}
                </div>
              </div>
            </div>
            <div className="extension-popup-body-right-container">
              <div className="e-popup-right-head-container">
                <p className="extension-popup-title" id="offer-preview-tittle">
                  Offer Preview
                </p>
              </div>
              <div className="e-popup-right-offer-previwes-scroller-container">
                <div className="e-popup-right-offer-previwes-inner-container">
                  {getExtensionOffer?.map((elm, idx) =>
                    elm?.extensionOffer?.map((data, idx) => (
                      <div className="base-offer-container">
                        <div className="base-offer-heading-container">
                          <p
                            className="offer-container-cancel-icon"
                            onClick={() => {
                              const offerData = {
                                orderId: props.orderId,
                                extId: data._id,
                              };
                              dispatch(deleteExtensionOfferSlice(offerData));
                            }}
                          >
                            x
                          </p>
                          <p className="offer-heading1st">
                            {data?.discount}%
                            <span className="offer-span">OFF</span>
                          </p>
                        </div>
                        <div className="offer-container-information">
                          <p className="original-price">
                            ₹
                            {priceFormatterrrr(data?.duration, data?.currPrice)}
                            <span className="cancel-line"></span>
                          </p>
                          <p className="offer-price">
                            ₹{priceFormatterrrr(data?.duration, data?.newPrice)}
                          </p>
                          <p className="validity-time">
                            For {getDuration(data?.duration)}
                          </p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Extendpackage;
