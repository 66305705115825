import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOADING,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;
// #=========================================== 
export const postBannerThunk = createAsyncThunk(
  "post/admin/admin_frontend/banner/postBanner",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/banner/postBanner`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllBannerThunk = createAsyncThunk(
  "get/admin/admin_frontend/banner/getAllBanner",
  async () => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/banner/getAllBanner`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const editBannerThunk = createAsyncThunk(
  "post/admin/admin_frontend/banner/editBanner",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/banner/editBanner`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteBannerThunk = createAsyncThunk(
  "post/admin/admin_frontend/banner/deleteBanner",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/banner/deleteBanner`,
        { bannerId: id }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  isDone: false,
  updateDone: false,
  //---------
  data: {
    banner: [],
  },
  status: {
    postBannerThunk: IDLE,
    getAllBannerThunk: IDLE,
    editBannerThunk: IDLE,
    deleteBannerThunk: IDLE,
  },
};

const adminFrontendBannerSlice = createSlice({
  name: "adminFrontendBanner",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    showError: (state, action) => {
      state.isError = true;
      state.errorData = { message: action.payload.message, type: action.payload.type };
    }
  },
  extraReducers: (builder) => {
    builder
      // post banner
      .addCase(postBannerThunk.pending, (state, action) => {
        // state.status.getAllAddonsList = LOADING;
        state.loading = true;
      })
      .addCase(postBannerThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.postBannerThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(postBannerThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.postBannerThunk = ERROR;
      })

      // get all banner
      .addCase(getAllBannerThunk.pending, (state, action) => {
        // state.status.getAllAddonsList = LOADING;
        state.loading = true;
      })
      .addCase(getAllBannerThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.banner = payload.data;
            state.status.getAllBannerThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllBannerThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllBannerThunk = ERROR;
      })

      // edit banner
      .addCase(editBannerThunk.pending, (state, action) => {
        // state.status.getAllAddonsList = LOADING;
        state.loading = true;
      })
      .addCase(editBannerThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.editBannerThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(editBannerThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.editBannerThunk = ERROR;
      })

      // delete banner
      .addCase(deleteBannerThunk.pending, (state, action) => {
        // state.status.getAllAddonsList = LOADING;
        state.loading = true;
      })
      .addCase(deleteBannerThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deleteBannerThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteBannerThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deleteBannerThunk = ERROR;
      });
  },
});

export default adminFrontendBannerSlice.reducer;
export const { clearErrorSlice, showError } = adminFrontendBannerSlice.actions;
