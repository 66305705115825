import React, { useEffect, useState } from "react";

function useResponsiveWindow() {
  const getWindowSize = () => {
    if (window.innerWidth > 991) {
      return { pc: true, tablet: false, phone: false };
    }
    if (window.innerWidth <= 991 && window.innerWidth > 576) {
      return { pc: false, tablet: true, phone: false };
    }
    if (window.innerWidth <= 576) {
      return { pc: false, tablet: false, phone: true };
    }
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const onResize = () => {
      setWindowSize(getWindowSize());
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return windowSize;
}

export default useResponsiveWindow;
