import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./MyorderDocumentReupload.css";
import { FailureAlert } from "..";
import { SmallCross } from "../../assets";
import {
  clearErrorSlice,
  getSingleOrderSlice,
  getUserAddressById,
  updateOrderStatusSlice,
} from "../../redux/features/orderSlice/orderSlice";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReUploadDocument from "./ReUploadDocument";
import LiveSelfiee from "./LiveSelfiee";
import IdentityProof from "./IdentityProof";
import { REUPLOAD, SUCCESS } from "../../constants/constants";
import BankStatement from "./bankStatement";
import EnterpriseProofReupload from "./EnterpriseProofReupload";

const MyorderDocumentReupload = (props) => {
  const dispatch = useDispatch();
  const [ currentUserId, setCurrentUserId ] = useState(props?.data?._id);
  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const [addressData, setAddressData] = useState({});

  const customAlert = useSelector(
    (state) => state.rootReducer.orderInfo.isError
  );
  const error = useSelector((state) => state.rootReducer.orderInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  const selfieStatus = useSelector((state) => state?.rootReducer?.orderInfo?.data?.currentUserOrder?.document?.[0]?.status)
  const identityStatus = useSelector((state) => state?.rootReducer?.userInfo?.currentUserInfo?.document?.[0]?.status)
  const addressStatus = useSelector((state) => state?.rootReducer?.orderInfo?.data?.currentAddress?.document?.status)
  const bankStatementStatus = useSelector((state) => state?.rootReducer?.orderInfo?.data?.currentUserOrder?.document?.[1]?.status)
  const addressId = useSelector((state) => state?.rootReducer?.orderInfo?.data?.currentUserOrder?.userInfo?.address)
  const enterpriseProofStatus = useSelector((state) => state?.rootReducer?.userInfo?.currentUserInfo?.enterpriseProof?.[0]?.status)

  // console.log("enterpriseProofStatus", enterpriseProofStatus)
  /* Hooks */
  const [confirmReUpload, setConfirmReUpload] = useState(false);

  useEffect(() => {
    if (confirmReUpload) {
      document.body.style.position = "fixed";
    }
  }, [confirmReUpload]);

  useEffect(() => {
    if (addressId) {
      dispatch(getUserAddressById(addressId))
        .unwrap()
        .then((data) => {
          setAddressData({...data.data,userName:data.userName});
        });
    }
  }, [addressId]);

  useEffect(() => {
    if(
        selfieStatus !== undefined
        && identityStatus !== undefined
        && addressStatus !== undefined
      ){
        if(
          selfieStatus !== REUPLOAD
          && identityStatus !== REUPLOAD
          && addressStatus !== REUPLOAD
          && (bankStatementStatus !== REUPLOAD || bankStatementStatus === undefined)
          && (enterpriseProofStatus !== REUPLOAD || enterpriseProofStatus === undefined)
        ){
            dispatch(updateOrderStatusSlice(
              {
                orderId: props.orderId,
              }
            ))
            .unwrap()
            .then((data) => {
              if(data.type === SUCCESS){
                props.setStatus(true)
              }
            });
        }
      }
  }, [selfieStatus, identityStatus, addressStatus, bankStatementStatus, enterpriseProofStatus])

  useEffect(() => {
    if (isLogin) {
      dispatch(getSingleOrderSlice(props.orderId));
    }
  }, [isLogin]);


  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}

      <div className="myorder-re-upload-package-container">
        <div className="myorder-re-upload-package-content">
          <p className="re-upload-extended-text">
            Reupload Documents
            <img
              src={SmallCross}
              alt="cross"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
              }}
              onClick={props.onclick}
            />
          </p>
          {
            addressStatus === REUPLOAD &&
            <ReUploadDocument data={props.data}/>
          }
          {
            selfieStatus === REUPLOAD &&
            <LiveSelfiee data={props.data}/>
          }
          {
            identityStatus === REUPLOAD &&
            <IdentityProof orderId={props.data._id}/>
          }
          {
            bankStatementStatus === REUPLOAD &&
            <BankStatement orderId={props.data._id}/>
          }
          {
            enterpriseProofStatus === REUPLOAD &&
            <EnterpriseProofReupload orderId={props.data._id}/>
          }
        </div>
      </div>
    </>
  );
};

export default MyorderDocumentReupload;
