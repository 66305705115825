import "./scrollerbutton.css";

import { GoTop, Scroll, UpArrow } from "../../assets";
import React, { useEffect, useState } from "react";

const Gotopbutton = (props) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (window.innerWidth < 576) {
    return (
      <>
        <div className={props.class} style={{ display: "none" }}>
          <div className="mobileGoTopContent" onClick={goToTop}>
            <img src={GoTop} alt="goTop" className="goTop" />
            <img src={UpArrow} alt="upArrow" className="upArrow" />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {showTopBtn && (
          <div
            className="reScroll"
            onClick={goToTop}
            style={{ display: "none" }}
          >
            <img src={Scroll} alt="" className="scrollImg" />
          </div>
        )}
      </>
    );
  }
};

const Scrollerbutton = (props) => {
  return (
    <>
      <Gotopbutton className={props.class} />
    </>
  );
};

export default Scrollerbutton;
