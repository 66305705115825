import "./index.css";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary.jsx";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import store from "./redux/app/store.js";
import * as ServiceWorkerRegistration from './ServiceWorkerRegistration.js'
// import { ErrorBoundary } from "react-error-boundary";


ServiceWorkerRegistration.register();



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
);
