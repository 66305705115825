import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOGGEDIN,
  OTP_ACTIVE,
  REGISTERED,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

// import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = true;

// write proper statuses
// clear out extra reducers
// replace get phone no to get Otp

//============================================================
export const getPhoneNumber = createAsyncThunk(
  "post/auth/get_phone",
  async (phone) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/get_phone`, {
        phone: phone,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "post/auth/verify_otp",
  async (payload) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/verify_otp`, {
        otp: payload.otp,
        phone: payload.phone,
      });
      localStorage.setItem("USER_TOKEN", JSON.stringify(res.data.USER_TOKEN));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const loginOrRegister = createAsyncThunk(
  "post/auth/login",
  async (phone) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/register`, {
        phone: phone,
      });
      if (res.data.type === SUCCESS)
        localStorage.setItem("USER_TOKEN", JSON.stringify(res.data.USER_TOKEN));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const userLogout = createAsyncThunk("post/auth/logout", async () => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/logout`, {});
    localStorage.removeItem("USER_TOKEN");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
});

export const loginByToken = createAsyncThunk(
  "get/auth/token_login",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/auth/token_login`);
      if (res.data.type === SUCCESS)
        localStorage.setItem("USER_TOKEN", JSON.stringify(res.data.USER_TOKEN));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updatePersonalDetails = createAsyncThunk(
  "post/personalDetails",
  async (payload) => {
    try {
      const data = {
        fullname: payload.fullName,
        gender: payload.gender,
        email: payload.email,
        dob: payload.dateOfBirth,
        alternatePhone: payload.alternativeNumber,
      };
      const res = await axios.post(`${BASE_URL}/auth/update`, data);
      return res.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updateBasicDetails = createAsyncThunk(
  "get/auth/update",
  async (payload) => {
    try {
      if (payload.referralCode) {
        const data = {
          fullname: payload.fullName,
          email: payload.email,
          alternatePhone: payload.alternatePhone,
          friendReferral: payload.referralCode,
          basicDetails: true,
        };
        const res = await axios.post(`${BASE_URL}/auth/update`, data);
        return {
          fullname: payload.fullName, 
          ...res.data 
        };
      }
      const data = {
        fullname: payload.fullName,
        email: payload.email,
        alternatePhone: payload.alternatePhone,
        basicDetails: true,
      };
      const res = await axios.post(`${BASE_URL}/auth/update`, data); 
      return {
        fullname: payload.fullName, 
        ...res.data 
      };
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserLocation = createAsyncThunk(
  "post/auth/change_location",
  async (payload) => {
    try {
      const data = { lastLocation: payload.lastLocation.toUpperCase() };
      const res = await axios.post(`${BASE_URL}/auth/change_location`, data);
      return res.data;
    } catch (err) {
      return err.response.data;
      // throw new Error(JSON.stringify(err.response.data))
    }
  }
);

export const registerUserByAdminSlice = createAsyncThunk(
  "post/admin/registerUserByAdmin/registerUser",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/registerUserByAdmin/registerUser`,
        data
      );
      return res.data;
    } catch (err) {
      return err.response.data;
      // throw new Error(JSON.stringify(err.response.data))
    }
  }
);

export const getUserByPhoneAndTypeSlice = createAsyncThunk(
  "post/admin/registerUserByAdmin/getUserByPhone",
  async (phone) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/registerUserByAdmin/getUserByPhone`,
        {
          phone: phone,
        }
      );
      return res.data;
    } catch (err) {
      return err.response.data;
      // throw new Error(JSON.stringify(err.response.data))
    }
  }
);

export const createUserCartSlice = createAsyncThunk(
  "post/auth/createUserCart",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/createUserCart`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserCartSlice = createAsyncThunk(
  "post/auth/updateUserCart",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/updateUserCart`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserCartSlice = createAsyncThunk(
  "get/auth/getUserCart",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/getUserCart`, data);
      return res.data;

    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteUserCartSlice = createAsyncThunk(
  "get/auth/deleteUserCart",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/deleteUserCart`, {
        cartId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserCartByIdSlice = createAsyncThunk(
  "post/auth/getUserCartById",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/getUserCartById`, {
        cartId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserAddressSlice = createAsyncThunk(
  "post/auth/updateUserAddress",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/updateUserAddress`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserAddressById = createAsyncThunk(
  "post/auth/get_user_address",
  async () => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/get_user_address`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const removeAddressById = createAsyncThunk(
  "post/auth/remove_user_address",
  async (id) => {
    // console.log(id);
    try {
      const res = await axios.post(`${BASE_URL}/auth/remove_user_address`, {
        addressId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteUserAddressSlice = createAsyncThunk(
  "post/auth/deleteUserAddress",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/deleteUserAddress`, {
        addressId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addAddressToCartSlice = createAsyncThunk(
  "post/auth/addAddressToCart",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/addAddressToCart`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addGstDetailsToCartSlice = createAsyncThunk(
  "post/auth/addGstDetailsToCart",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/addGstDetailsToCart`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateCouponSlice = createAsyncThunk(
  "post/auth/updateCoupon",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/updateCoupon`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addProofToCartSlice = createAsyncThunk(
  "post/auth/addProofToCart",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/addProofToCart`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPersonalInfoThunk = createAsyncThunk(
  "get/auth/get_personalinfo",
  async (data) => {
    try {
      const res = await axios.get(`${BASE_URL}/auth/get_personalinfo`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPersonalCreditInfoThunk = createAsyncThunk(
  "get/auth/get_creditinfo",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/get_creditinfo`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const verifyCartAgainstCreditsThunk = createAsyncThunk(
  "post/user/cart/verify_cart_credit",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/cart/verify_cart_credit`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const resendOtpThunk = createAsyncThunk(
  "post/auth/resendOtp",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/resendOtp`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateCreditStatusSlice = createAsyncThunk(
  "post/auth/update_credit_status",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/update_credit_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const UnauthorizedAccessStatusSlice = createAsyncThunk(
  "post/auth/",
  async (status) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/update_UnauthorizedAccess_status`,
        { status }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserDocumentsSlice = createAsyncThunk(
  "post/auth/update_user_documents",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/auth/update_user_documents`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateEnterpriseProofSlice = createAsyncThunk(
  "post/auth/update_enterprise_proof_documents",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/auth/update_enterprise_proof_documents`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserAddressStatus = createAsyncThunk(
  "put/auth/update_user_address_status",
  async (data) => {
    try {
      // console.log("updateUserAddressStatus data: ", data)
      const res = await axios.put(
        `${BASE_URL}/auth/update_user_address_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserIdentityProofStatus = createAsyncThunk(
  "put/auth/update_user_identity_proof_status",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/auth/update_user_identity_proof_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserEnterpriseProofProofStatus = createAsyncThunk(
  "put/auth/update_user_enterprise_proof_status",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/auth/update_user_enterprise_proof_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserAddressByAddId = createAsyncThunk(
  "post/auth/get_user_address_by_add_id",
  async (payload) => {
    try {
      const data = { addressId: payload.addressid };
      const res = await axios.post(`${BASE_URL}/auth/get_user_address_by_add_id`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);


//============================================================
const initialState = {
  loading: false,
  loginToken: localStorage.getItem("USER_TOKEN")
    ? JSON.parse(localStorage.getItem("USER_TOKEN"))
    : null,
  isUnauthorizedAccess: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  isLogin: false,
  loginCount:0,
  location: "",
  addressData: [],
  addressDataById: [],
  data: {
    OTPExpiry: 0,
    phone: "",
    userName: "",
    lastLocation: "",
    userInfo: {},
    creditInfo: {},
    cartCreditInfo: {},
  },
  currentUserCart: {},
  currentUserInfo: {},
  gstDetails: {},
  updateDone: false,
  status: {
    getPhoneNumber: IDLE,
    verifyOtp: IDLE,
    updateUserLocation: IDLE,
    loginByToken: IDLE,
    loginOrRegister: IDLE,
    userLogout: IDLE,
    updateBasicDetails: IDLE,
    updatePersonalDetails: IDLE,
    registerUserByAdminSlice: IDLE,
    getUserByPhoneAndTypeSlice: IDLE,
    createUserCartSlice: IDLE,
    getUserCartSlice: IDLE,
    deleteUserCartSlice: IDLE,
    getUserCartByIdSlice: IDLE,
    updateUserAddressSlice: IDLE,
    deleteUserAddressSlice: IDLE,
    addAddressToCartSlice: IDLE,
    addGstDetailsToCartSlice: IDLE,
    getGstDetailsFromCartSlice: IDLE,
    updateCouponSlice: IDLE,
    addProofToCartSlice: IDLE,
    getPersonalInfoThunk: IDLE,
    getPersonalCreditInfoThunk: IDLE,
    verifyCartAgainstCreditsThunk: IDLE,
    resendOtpThunk: IDLE,
    updateUserCartSlice: IDLE,
    updateCreditStatusSlice: IDLE,
    updateUserDocumentsSlice: IDLE,
    updateEnterpriseProofSlice: IDLE,
    updateUserAddressStatus: IDLE,
    updateUserIdentityProofStatus: IDLE,
    getUserAddressByAddId: IDLE
  },
  // locationStatus: IDLE,
  // loginStatus: IDLE,
  // tokenStatus: IDLE,
  // verifyOtpStatus: IDLE,
  // logoutStatus: IDLE,
  // getPhoneStatus: IDLE,
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    setUpdateDone: (state, action) => {
      state.updateDone = !state.updateDone;
    },
    incrementLoginCount:(state,action) => {
      state.loginCount++
    },
    changeUserLocationPublic: (state, action) => {
      state.data.lastLocation = action.payload.lastLocation;
      state.updateDone = !state.updateDone;
    },
    userSetLogin: (state, action) => {
      state.isLogin = action.payload.isLogin;
      state.data.userName = action.payload.userName;
    },
    setUserPhone: (state, action) => {
      state.data.phone = action.payload.phone;
    },
    clearNavigate: (state, action) => {
      state.redirect = "";
    },
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setOtpExpiry: (state, { payload }) => {
      state.data.OTPExpiry = 0;
    },
    setError: (state, { payload }) => {
      state.isError = true;
      state.errorData = {
        message: payload.message,
        type: payload.type,
        errors: payload.errors,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // ===============================================================================
      // Login or register
      .addCase(loginOrRegister.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginOrRegister.fulfilled, (state, { payload }) => {
        if (payload.type === "SUCCESS") {
          // console.log("Payload in loginOrRegister: ", payload)
          state.data = { ...state.data, userName: payload.data.userName };
          state.loginToken = payload.USER_TOKEN;
          state.isLogin = true;
          state.loading = false;
        }
        state.status.loginOrRegister = FULFILLED;
      })
      .addCase(loginOrRegister.rejected, (state, action) => {
        state.loading = false;
        state.status.loginOrRegister = "FAILED";
        state.errorData = action.error.message;
      })
      // ===============================================================================
      //login by token
      .addCase(loginByToken.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginByToken.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case LOGGEDIN:
            // console.log("Payload in loginByToken: ", payload)
            state.data.phone = payload.data.phone;
            state.data.userName = payload.data.userName;
            state.data.lastLocation = payload.data.lastLocation;
            state.status.loginByToken = FULFILLED;
            state.updateDone = !state.updateDone;
            state.loading = false;
            state.isLogin = true;
            state.loginToken = payload.USER_TOKEN;
            break;
          case REGISTERED:
            state.data.userName = payload.data.userName;
            state.status.loginByToken = FULFILLED;
            state.loading = false;
            state.isLogin = true;
            state.loginToken = payload.USER_TOKEN;
            break;
        }
      })
      .addCase(loginByToken.rejected, (state, action) => {
        state.status.loginByToken = "FAILED";
        state.errorData = action.error.message;
      })

      // ===============================================================================
      // userLogout
      .addCase(userLogout.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userLogout.fulfilled, (state, { payload }) => {
        if (payload.type === SUCCESS) {
          state.data = { phone: "", userName: "", lastLocation: "MUMBAI" };
          state.loading = false;
          state.isLogin = false;
          state.currentUserInfo = {};
          state.status.userLogout = FULFILLED;
          state.status.loginByToken = IDLE;
          state.loginToken = null;
        }
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.data.userLogout = "FAILED";
        state.loading = false;
        state.errorData = action.error.message;
      })

      // ===============================================================================
      // Verify OTP
      .addCase(verifyOtp.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case LOGGEDIN:
            // console.log("Payload in verifyOtp Loggedin: ", payload)
            state.data.phone = payload.data.phone;
            state.data.userName = payload.data.userName;
            state.status.verifyOtp = FULFILLED;
            state.loginToken = payload.USER_TOKEN;
            state.loading = false;
            state.isLogin = true;
            break;
          case REGISTERED:
            // console.log("Payload in verifyOtp registered: ", payload)
            state.data.phone = payload.data.userName;
            state.data.userName = payload.data.userName;
            state.status.verifyOtp = FULFILLED;
            state.loginToken = payload?.USER_TOKEN;
            state.loading = false;
            state.isLogin = true;
            break;
          default:
            state.isError = true;
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })

      .addCase(verifyOtp.rejected, (state, action) => {
        state.status.verifyOtp = ERROR;
        state.errorData = action.error.message;
        state.loading = false;
      })

      // ===============================================================================
      // getPhone /sendOTP;
      .addCase(getPhoneNumber.pending, (state, action) => {
        // state.getPhoneStatus = LOADING;
        state.loading = true;
      })
      .addCase(getPhoneNumber.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.phone = payload.data.phone;
            state.data.OTPExpiry = payload.data.expiry;
            state.status.getPhoneNumber = FULFILLED;
            state.loading = false;
            break;
          case OTP_ACTIVE:
            state.data.phone = payload.data.phone;
            state.data.OTPExpiry = payload.data.expiry;
            state.status.getPhoneNumber = FULFILLED;
            state.loading = false;
            break;
          default:
            state.isError = true;
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPhoneNumber.rejected, (state, action) => {
        state.status.getPhoneNumber = ERROR;
        state.loading = false;
        state.errorData = action.error.message;
      })

      // ===============================================================================
      // update profile Datails
      .addCase(updatePersonalDetails.pending, (state, action) => {
        // state.updatesatus = LOADING;
        state.loading = true;
      })
      .addCase(updatePersonalDetails.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.updatePersonalDetails = FULFILLED;
            state.loading = false;
            break;
          default:
            state.isError = true;
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updatePersonalDetails.rejected, (state, action) => {
        state.status.updateBasicDetails = ERROR;
        state.error = action.error.message;
        state.loading = false;
      })

      // ===============================================================================
      // update profile Datails
      .addCase(updateUserLocation.pending, (state, action) => {
        // state.status.updateUserLocation = LOADING;
        state.loading = true;
      })
      .addCase(updateUserLocation.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.location = payload.data.location;
            state.data.lastLocation = payload.data.location;
            state.status.updateUserLocation = "FULFILLED";
            state.updateDone = !state.updateDone;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })

      .addCase(updateUserLocation.rejected, (state, action) => {
        state.loading = false;
        state.status.updateUserLocation = ERROR;
      })

      // ===============================================================================
      // register User by admin
      .addCase(registerUserByAdminSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(registerUserByAdminSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.registerUserByAdminSlice = FULFILLED;
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(registerUserByAdminSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.registerUserByAdminSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(updateBasicDetails.fulfilled, (state, action) => {
        const { fullname } = action.payload;
        state.data.userName = fullname; 
        state.isLogin = true; 
      })
      .addCase(updateBasicDetails.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.status.updateBasicDetails = ERROR;
        state.loading = false;
        state.isError = true;
      })

      // ===============================================================================
      // get user by phone
      .addCase(getUserByPhoneAndTypeSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserByPhoneAndTypeSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.currentUserInfo = payload.data;
            state.status.getUserByPhoneAndTypeSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserByPhoneAndTypeSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getUserByPhoneAndTypeSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // create user cart ===============================================
      .addCase(createUserCartSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(createUserCartSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.createUserCartSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createUserCartSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.createUserCartSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get user cart ===============================================
      .addCase(getUserCartSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserCartSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.currentUserInfo = payload.data;
            state.status.getUserCartSlice = FULFILLED;
            break;
          default:
            state.loading = false;
          // state.isError = true;
          // state.errorData = {
          //   message: payload.message,
          //   type: payload.type,
          //   errors: payload.errors,
          // };
        }
      })
      .addCase(getUserCartSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getUserCartSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // delete user cart ===============================================
      .addCase(deleteUserCartSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(deleteUserCartSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.deleteUserCartSlice = FULFILLED;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteUserCartSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.deleteUserCartSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get user cart by id ===============================================
      .addCase(getUserCartByIdSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserCartByIdSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.currentUserCart = payload.data;
            state.status.getUserCartByIdSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserCartByIdSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getUserCartByIdSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // update user address ===============================================
      .addCase(updateUserAddressSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateUserAddressSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.updateUserAddressSlice = FULFILLED;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateUserAddressSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.updateUserAddressSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // getUserAddressById
      .addCase(getUserAddressById.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserAddressById.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.addressData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserAddressById.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
      })
      // removeAddressById
      .addCase(removeAddressById.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(removeAddressById.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(removeAddressById.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
      })
      // delete user address ===============================================
      .addCase(deleteUserAddressSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(deleteUserAddressSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.deleteUserAddressSlice = FULFILLED;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteUserAddressSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.deleteUserAddressSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // add address to cart ===============================================
      .addCase(addAddressToCartSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(addAddressToCartSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.addAddressToCartSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addAddressToCartSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.addAddressToCartSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // add gst details to cart ===============================================
      .addCase(addGstDetailsToCartSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(addGstDetailsToCartSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.addGstDetailsToCartSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addGstDetailsToCartSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.addGstDetailsToCartSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // update coupon slice ===============================================
      .addCase(updateCouponSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateCouponSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.updateCouponSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateCouponSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.updateCouponSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // add proof to cart slice ===============================================
      .addCase(addProofToCartSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(addProofToCartSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.addProofToCartSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addProofToCartSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.addProofToCartSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get personal info ===============================================
      .addCase(getPersonalInfoThunk.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getPersonalInfoThunk.fulfilled, (state, { payload }) => {
        console.log({ payload });
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.getPersonalInfoThunk = FULFILLED;
            state.data.userInfo = payload.data;
            state.data.userName = payload.data.fullname;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPersonalInfoThunk.rejected, (state, action) => {
        state.loading = false;
        state.status.getPersonalInfoThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get personal info ===============================================
      .addCase(getPersonalCreditInfoThunk.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getPersonalCreditInfoThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.getPersonalCreditInfoThunk = FULFILLED;
            state.data.creditInfo = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPersonalCreditInfoThunk.rejected, (state, action) => {
        state.loading = false;
        state.status.getPersonalCreditInfoThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get cart cerdit info ===============================================
      .addCase(verifyCartAgainstCreditsThunk.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(
        verifyCartAgainstCreditsThunk.fulfilled,
        (state, { payload }) => {
          switch (payload.type) {
            case SUCCESS:
              state.loading = false;
              state.status.verifyCartAgainstCreditsThunk = FULFILLED;
              state.data.cartCreditInfo = payload.data;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(verifyCartAgainstCreditsThunk.rejected, (state, action) => {
        state.loading = false;
        state.status.verifyCartAgainstCreditsThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // resend otp thunk ===============================================
      .addCase(resendOtpThunk.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(resendOtpThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.resendOtpThunk = FULFILLED;
            state.data.OTPExpiry = payload.data.expiry;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(resendOtpThunk.rejected, (state, action) => {
        state.loading = false;
        state.status.resendOtpThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // update user cart thunk ===============================================
      .addCase(updateUserCartSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateUserCartSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.updateUserCartSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateUserCartSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.updateUserCartSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // update user cart thunk ===============================================
      .addCase(updateCreditStatusSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateCreditStatusSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.updateCreditStatusSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateCreditStatusSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.updateCreditStatusSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(UnauthorizedAccessStatusSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        UnauthorizedAccessStatusSlice.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.isUnauthorizedAccess = payload.data;
        }
      )
      .addCase(UnauthorizedAccessStatusSlice.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(updateUserDocumentsSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserDocumentsSlice.fulfilled, (state, { payload }) => {
        if (payload.type === SUCCESS) {
          state.loading = false;
          state.currentUserInfo = payload.data;
          state.status.updateUserDocumentsSlice = FULFILLED;
        }
      })
      .addCase(updateUserDocumentsSlice.rejected, (state, action) => {
        state.data.updateUserDocumentsSlice = ERROR;
        state.loading = false;
        state.errorData = action.error.message;
      })

      .addCase(updateEnterpriseProofSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateEnterpriseProofSlice.fulfilled, (state, { payload }) => {
        if (payload.type === SUCCESS) {
          state.loading = false;
          state.currentUserInfo = payload.data;
          state.status.updateEnterpriseProofSlice = FULFILLED;
        }
      })
      .addCase(updateEnterpriseProofSlice.rejected, (state, action) => {
        state.data.updateEnterpriseProofSlice = ERROR;
        state.loading = false;
        state.errorData = action.error.message;
      })

      .addCase(updateUserAddressStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserAddressStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.addressData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateUserAddressStatus.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
      })

      .addCase(updateUserIdentityProofStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserIdentityProofStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.addressData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateUserIdentityProofStatus.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
      })

      .addCase(updateUserEnterpriseProofProofStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserEnterpriseProofProofStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.addressData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateUserEnterpriseProofProofStatus.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
      })
      .addCase(getUserAddressByAddId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserAddressByAddId.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.addressDataById = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserAddressByAddId.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
      })
  },
});

export default userInfoSlice.reducer;
export const {
  userSetLogin,
  setUserPhone,
  clearNavigate,
  clearErrorSlice,
  changeUserLocationPublic,
  incrementLoginCount,
  setUpdateDone,
  setOtpExpiry,
  setError,
} = userInfoSlice.actions;
