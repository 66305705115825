import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

// export const fetchPagenatedContactus = createAsyncThunk(
//     "fetch/admin/users/get_all_users",
//     async (payload) => {
//         try {
//             const res = await axios.post(`${BASE_URL}/admin/users/get_all_users`, {
//                 pagesize: payload.pageSize,
//                 page: payload.page,
//                 sort: payload.sort,
//             });
//             // const res =  await axios.post(
//             //    `${BASE_URL}/admin/users/get_all_users`,
//             //     {}
//             // );
//             return res.data;
//         } catch (error) {
//             return error.response.data;
//         }
//     }
// );

export const deleteContactUsInfo = createAsyncThunk(
  "http://localhost:5000/admin/contactus/delete/",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/contactus/delete/${id}`);
      return res.data;
    } catch (error) {
      return error.response.error;
    }
  }
);

export const getContactUsSlice = createAsyncThunk(
  "get/admin/contactus/get_type",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/contactus/get_type`, {
        pageSize: data.pageSize,
        page: data.page,
        sort: data.sort,
        filters: data.filters,
        contactType: data.contactType,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createContactUsSlice = createAsyncThunk(
  "post/contactus/create",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/contactus/create`, {
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: data.message,
        contactType: data.contactType,
        company: data.company,
        product: data.product,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createUserContactUsSlice = createAsyncThunk(
  "post/public/contactus/create",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/public/contactus/create`, {
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: data.message,
        contactType: data.contactType,
        company: data.company,
        product: data.product,
        location: data.location,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPaginatedSearchReqThunk = createAsyncThunk(
  "admin/search_req/get_paginated",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/search_req/get_paginated`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteSearchReqThunk = createAsyncThunk(
  "admin/search_req/delete_req",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/search_req/delete_req`, {
        reqId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  data: {
    searchReqs: { total: 0, data: [] },
  },
  updateDone: false,
  isError: false,
  filter: {},
  sort: {},
  status: {
    getContactUsSlice: IDLE,
    createContactUsSlice: IDLE,
    getPaginatedSearchReqThunk: IDLE,
  },
  data: {
    req: {
      total: 0,
      data: [],
    },
  },
};

const adminContactUsSlice = createSlice({
  name: "adminContactUsSlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // get all users contactus ===============================================
      .addCase(getContactUsSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getContactUsSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.req = payload.data;
            state.status.getContactUsSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload?.message,
              type: payload?.type,
              errors: payload?.errors,
            };
        }
      })
      .addCase(getContactUsSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload?.error;
        state.status.getContactUsSlice = ERROR;
      })

      // create contactus ===============================================
      .addCase(createContactUsSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(createContactUsSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.createContactUsSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createContactUsSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.createContactUsSlice = ERROR;
      })
      .addCase(deleteContactUsInfo.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteContactUsInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            state.status.deleteContactUsInfo = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteContactUsInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get paginated search req
      .addCase(getPaginatedSearchReqThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getPaginatedSearchReqThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.searchReqs = payload.data;
            state.status.getPaginatedSearchReqThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPaginatedSearchReqThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // delete search req
      .addCase(deleteSearchReqThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteSearchReqThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            state.status.deleteSearchReqThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteSearchReqThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      });
  },
});

export default adminContactUsSlice.reducer;
export const { clearErrorSlice } = adminContactUsSlice.actions;
