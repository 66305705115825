import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "./home.css";
import "./howItWork.css";
import "swiper/css";
import "swiper/css/pagination";

import {
  Aadil,
  AhmedabadMirror,
  Ammy,
  BentoHouse,
  // Apple,
  BusinessLine,
  BusinessNews,
  // Cam360,
  // Camera,
  // Computers,
  DailyHunt,
  Delivered,
  Delivery,
  // DemoReel,
  Deposit,
  // DrumSet,
  Ecosystem,
  // Esport,
  FirstIndia,
  FourStar,
  // GamingPc,
  // GamingPcSale,
  // GoProBanner,
  // GoPros,
  GoogleLogo,
  // GuitarSale,
  HelloEntrepreneurs,
  JaipurMirror,
  // Lbb,
  // Learn,
  Light,
  // MacBookOffer,
  // MacBooks,
  MintMoney,
  // Music,
  // NewTag,
  PlayController,
  // Playstation,
  // Playstn,
  // RentBuy,
  // Renuka,
  RightTick,
  SelectProduct,
  Show,
  // SneakerSale,
  // SteeringSale,
  // SummerSale,
  // Tech,
  // TicketOffer,
  // Times,
  Union,
  // VRSale,
  VerificationRenting,
  // VrBox,
  live,
} from "../../assets";
import { Autoplay, Lazy, Mousewheel, Pagination } from "swiper";
import {
  Catogerycard,
  Contactbutton,
  Footer,
  Scrollerbutton,
  Sliderproductcard,
} from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  changeUserLocationPublic,
  updateUserLocation,
} from "../../redux/features/loginSlice/loginSlice";
import { createSearchParams, useSearchParams } from "react-router-dom";
import {
  getAllLocation,
  getAllSuperCategoriesUser,
  getAllTrendingPackagesPublic,
  getAllTrendingPackagesUser,
  getCityOfferBannerThunk,
  getCityPublicBannerThunk,
} from "../../redux/features/homePageSlice/homePageSlice";
import { useDispatch, useSelector } from "react-redux";

import { ListProductPopUp } from "../Mylisting/mylisting";
import Login from "../Login/login";
import PeopleSaid from "../../components/PeopleSaid/PeopleSaid";
import PopUp from "../../components/PopUp/PopUp";
// import Reel1 from "../../assets/videos/Reel1.mp4";
// import Reel2 from "../../assets/videos/Reel2.mp4";
// import Reel3 from "../../assets/videos/Reel3.mp4";
import ReelSwiper from "../../components/ReelSwiper/ReelSwiper";
// import ReelSwipers from "../../components/ReelSwiper/ReelSwiper";
import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";
import { viewFormatter } from "../../utils/displayUtils";
import Dealhouse from "../Dealhouse/Dealhouse/Dealhouse";

// Import Swiper styles

// import { getAllSuperCategories } from "../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";

// components

// images

const CatogeriesCard = () => {
  // const dispatch = useDispatch();
  const allSuperCategories = useSelector(
    (state) => state.rootReducer.homePage.data.allSuperCategories
  );

  const userLocation = useSelector(
    (state) => state.rootReducer.userInfo.data.lastLocation
  );

  const windowSize = useResponsiveWindow();

  const [sliceLength, setSliceLength] = useState(5);
  useEffect(() => {
    if (windowSize.phone) {
      setSliceLength(5);
    } else {
      // setSliceLength(allSuperCategories.length);
      setSliceLength(6);
    }
  }, [windowSize, allSuperCategories]);

  return (
    <div className="catogeriesContainer">
      <div className="catogeriesContent">
        {/* USe slice here */}
        {allSuperCategories?.slice(0, sliceLength)?.map((elm, idx) => {
          return (
            <Catogerycard
              key={idx}
              imgSrc={elm.frontendIcon}
              className="catImg"
              name={viewFormatter(elm.category)}
              location={userLocation}
            />
          );
        })}
        <Catogerycard
          imgSrc={Show}
          className="showAll"
          name=""
          location={userLocation}
        />
      </div>
    </div>
  );
  // }
};

const Banners = ({
  productDetails,
  trendingProductDetail,
  serviceBanners,
  imageValues,
}) => {
  const [currentIndexs, setCurrentIndexs] = useState(0); //For Product Boxes
  const [isAnimating, setIsAnimating] = useState(false); //For Product Boxes

  const [currentIndex, setCurrentIndex] = useState(0); //For Service Banners
  const [isAnimatings, setIsAnimatings] = useState(false); //For Service Banners

  const [currentIndexSale, setCurrentIndexSale] = useState(0); //For Sale Banners
  const [isAnimatingsSale, setIsAnimatingsSale] = useState(false); //For Sale Banners

  useEffect(() => {
    //For Product Boxes
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 3000);
      setCurrentIndexs((prevIndex) => (prevIndex + 1) % productDetails.length);
    }, 3000); // Change item every 2 seconds

    return () => clearInterval(interval);
  }, [trendingProductDetail]); // Run only once on component mount

  useEffect(() => {
    //For Service Banners
    const intervalService = setInterval(() => {
      setIsAnimatings(true);
      setTimeout(() => {
        setIsAnimatings(false);
      }, 500);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceBanners.length);
    }, 3000); // Change item every 2 seconds

    return () => clearInterval(intervalService);
  }, []); // Run only once on component mount

  useEffect(() => {
    //For Sales Boxes
    const interval = setInterval(() => {
      setIsAnimatingsSale(true);
      setTimeout(() => {
        setIsAnimatingsSale(false);
      }, 2000);
      setCurrentIndexSale((prevIndex) => (prevIndex + 1) % imageValues.length);
    }, 1500); // Change item every 2 seconds

    return () => clearInterval(interval);
  }, [imageValues]); // Run only once on component mount

  return (
    <div className="left-ticket-box">
      <div className="ticket-image-container">
        {/* {cityOfferBanner.map((img,idx)=>{
                            <img
                            src={img.image[0]}
                            className="ticket-image"
                            id={`pic${idx}`}
                          ></img>
                          })} */}
        <img
          src={imageValues[currentIndex]?.image}
          className="ticket-image"
          id={`pic${currentIndex}`}
          loading="lazy"
          alt="banner"
        ></img>
      </div>

      <div
        className={
          isAnimating
            ? "quality-container-fade-out"
            : "quality-container-fade-in"
        }
      >
        <div className="quality-child-container">
          <img
            className="quality-images"
            src={serviceBanners[currentIndex].img}
            alt="feature"
            loading="lazy"
          />
          <div className="quality-information">
            <h4 className="quality-title">
              {serviceBanners[currentIndexs].title}
            </h4>
            <p className="quality-description">
              {serviceBanners[currentIndexs].description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  const { location } = useParams();
  const locationUpperCase = location?.toUpperCase();
  const [isListing, setIsListing] = useState(false);
  const [loginPopUp, setLoginPopUp] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const allSuperCategories = useSelector(
    (state) => state.rootReducer.homePage.data.allSuperCategories
  );

  const filteredDocument = allSuperCategories.filter((doc) => doc.isBentoGrid);

  const windowSize = useResponsiveWindow();

  const [sliceLength, setSliceLength] = useState(5);

  const userUpdateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );

  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const publicLocation = useSelector(
    (state) => state.rootReducer.userInfo.data.lastLocation
  );

  const cityBanner = useSelector(
    (state) => state.rootReducer.homePage.data.cityBanner
  );

  const [curWork, setCurWork] = useState("renting");

  useEffect(() => {
    dispatch(getAllSuperCategoriesUser());
    dispatch(getAllLocation()).then(({ payload }) => {
      if (
        !payload.data
          .map((elm) => elm.name)
          .filter((elm) => elm !== "ALL")
          .includes(locationUpperCase)
      ) {
        if (!isLogin) {
          dispatch(
            getAllTrendingPackagesPublic({
              location: "MUMBAI",
            })
          );
          navigate("/mumbai");
        }
        if (isLogin) {
          navigate(`/${publicLocation?.toLowerCase()}`);
          dispatch(getAllTrendingPackagesUser());
        }
      } else {
        if (isLogin) {
          dispatch(updateUserLocation({ lastLocation: locationUpperCase }));
        } else {
          dispatch(
            changeUserLocationPublic({ lastLocation: locationUpperCase })
          );
        }
      }
    });
  }, [isLogin]);

  useEffect(() => {
    if (isLogin) {
      dispatch(getAllTrendingPackagesUser());
    }
    if (!isLogin) {
      dispatch(
        getAllTrendingPackagesPublic({
          location: locationUpperCase ? locationUpperCase : "MUMBAI",
        })
      );
    }
  }, [userUpdateDone]);

  useEffect(() => {
    dispatch(
      getCityPublicBannerThunk({
        city: locationUpperCase ? locationUpperCase : "MUMBAI",
      })
    );
    dispatch(
      getCityOfferBannerThunk({
        city: locationUpperCase ? locationUpperCase : "MUMBAI",
      })
    );
  }, [location]);

  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 2;
  };

  const serviceBanners = [
    {
      img: RightTick,
      title: "Quality Products",
      description:
        "It's our habit to send each product through a 7-point inspection.",
    },
    {
      img: Delivery,
      title: "Instant Delivery",
      description: "Products delivered within 2-4 hours.",
    },
    {
      img: Deposit,
      title: "Zero Deposit",
      description: "Experience the new world of renting without a fee.",
    },
    {
      img: Light,
      title: "Live Light",
      description: "Naked we came on Earth, Naked we need to go!",
    },
  ];

  // const productDetails = [
  //   {
  //     img: PlayController,
  //     title: "PlayStation 5",
  //     price: "500/Month",
  //   },
  //   {
  //     img: VrBox,
  //     title: "VR Gaming",
  //     price: "500/Month",
  //   },
  //   {
  //     img: GamingPc,
  //     title: "Gaming Pc",
  //     price: "500/Month",
  //   },
  //   {
  //     img: DrumSet,
  //     title: "Digital Drum Set",
  //     price: "500/Month",
  //   },
  //   {
  //     img: GoPros,
  //     title: "Go Pro",
  //     price: "500/Month",
  //   },
  //   {
  //     img: Cam360,
  //     title: "360 Cam",
  //     price: "500/Month",
  //   },
  //   {
  //     img: MacBooks,
  //     title: "MacBook Pro",
  //     price: "500/Month",
  //   },
  // ];

  const cityOfferBanner = useSelector(
    (state) => state?.rootReducer?.homePage?.data?.cityOfferBanner
  );

  const trendingProductDetail = useSelector(
    (state) => state.rootReducer.homePage.data.allTrendingPackages
  );

  let reelValue;
  let imageValue;
  let productDetails = [];

  if (Array.isArray(cityOfferBanner)) {
    imageValue = cityOfferBanner.filter(
      (doc) =>
        Array.isArray(doc.image) &&
        doc.image.length > 0 &&
        doc.image.some((img) => img)
    );

    reelValue = cityOfferBanner.filter(
      (doc) =>
        Array.isArray(doc.video) &&
        doc.video.length > 0 &&
        doc.video.some((img) => img)
    );
  }

  trendingProductDetail.forEach((val) => {
    productDetails.push({
      img: val.image[0],
      title: val.name,
      price: val?.customPlans[3]?.price + `/month`,
      customId: val.customId,
    });
  });

  let imageValues = [];
  let slides = [];
  let bannerLinks = [];

  // imageValues = [
  //   TicketOffer,
  //   GoProBanner,
  //   MacBookOffer,
  //   GamingPcSale,
  //   GuitarSale,
  //   VRSale,
  //   SneakerSale,
  //   SteeringSale,
  //   RentBuy,
  //   SummerSale,
  // ];

  if (Array.isArray(cityOfferBanner)) {
    imageValue.forEach((img) => {
      if (img.image) {
        imageValues.push(img);
      }
    });

    reelValue.forEach((vid) => {
      if (vid.video) {
        slides.push({ link: vid.link, video: vid.video });
      }
    });
  }

  /******************** Fix Looop Issues ****************/

  // const slides = [Reel3, Reel2, Reel1];
  const getNavigate = (name, location) => {
    let params = Object.fromEntries(searchParams);
    params["category"] = name?.toLowerCase();
    params["subcategory"] = "default";
    params["page"] = 0;
    if (!params["pagesize"]) params["pagesize"] = 4;
    let link = `/${
      location?.toLowerCase() || "mumbai"
    }/categories/?${createSearchParams(params).toString()}`;
    return link;
  };

  // const boxes = document.querySelectorAll(".quality-container");
  // let currentIndex = 0;

  // function showNextBox() {
  //   boxes[currentIndex]?.classList?.remove("active");
  //   currentIndex = (currentIndex + 1) % boxes.length;
  //   boxes[currentIndex]?.classList?.add("active");
  // }

  // setInterval(showNextBox, 2000); // Change box every 2 seconds
  const dealHouseRef = useRef(null);

  const scrollToDiv = () => {
    dealHouseRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className="background-black">
        {/* header section  */}
        <div className="headerContainer-home" style={{ borderRadius: "15px" }}>
          <div className="headerContent" style={{ borderRadius: "15px" }}>
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
              style={{ borderRadius: "15px" }}
            >
              {/* <div className="carousel-indicators" style={{ marginTop: "70%" }}>
              {cityBanner.map((elm, idx) => (
                <button
                  type="button"
                  key={idx}
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={`${idx}.toString()`}
                  className={`active slideIndicator${(idx + 1).toString()}`}
                  aria-current="true"
                  aria-label={`Slide ${(idx + 1).toString()}`}
                ></button>
              ))}
            </div> */}

              <div
                className="carousel-inner-home"
                style={{ borderRadius: "15px" }}
              >
                {/* {cityBanner.map((elm, idx) => (
                <div
                  key={idx}
                  className={`carousel-item ${idx === 0 && "active"}`}
                  style={{ borderRadius: "15px", height: "100%" }}
                >
                  <img
                    style={{
                      borderRadius: "15px",
                      width: "100%",
                      height: "100%",
                      //added
                      objectFit: "cover",
                    }}
                    // src={HeaderImg}
                    src={elm.image}
                    className="d-block w-100 h-100 headerImg"
                    alt="..."
                  />
                </div>
              ))} */}
                <div className="carousel-parent">
                  <div
                    className={`insta-reel ${
                      window.screen.width <= 576 ? "collapse " : ""
                    }`}
                    key={window.screen.width}
                  >
                    <ReelSwiper slides={slides} />
                    {/* <div style={{ borderRadius: "15px", height: "100%" }}>
                    <img
                      style={{
                        borderRadius: "15px",
                        width: "100%",
                        height: "100%",
                        //added
                      }}
                      // src={HeaderImg}
                      src={DemoReel}
                      className="d-block w-100 h-100 headerImg"
                      alt="..."
                    />
                  </div> */}
                    {/* <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    freeMode={true}
                    pagination={{192.168.81.198
                      clickable: true,
                    }}
                    modules={[Pagination, Lazy]}
                    className="mySwiper-reel"
                    // key={window.screen.width}
                    lazy={true}
                  >
                    <SwiperSlide className="reel-video-swiper">
                      <video
                        className="reel-dimensions"
                        controls
                        autoplay
                        loop
                        autoFocus
                      >
                        <source src={Reel3} type="video/mp4" />
                      </video>
                    </SwiperSlide>
                    <SwiperSlide>
                      <video className="reel-dimensions" controls autoplay loop>
                        <source src={Reel2} type="video/mp4" />
                      </video>
                    </SwiperSlide>
                    <SwiperSlide>
                      <video className="reel-dimensions" controls autoplay loop>
                        <source src={Reel1} type="video/mp4" />
                      </video>
                    </SwiperSlide>
                  </Swiper> */}
                  </div>
                  <div className="second-child">
                    <div className="second-child-container">
                      <div className="first-row">
                        <div className="ticket-quality-box">
                          <Banners
                            productDetails={productDetails}
                            trendingProductDetail={trendingProductDetail}
                            serviceBanners={serviceBanners}
                            imageValues={imageValues}
                          />
                          <div className="right-category-box">
                            <div className="entrepreneur-message d-flex">
                              <div className="entrepreneur-message-icon">
                                <img src={Ammy} alt="" />
                              </div>
                              <div className="entrepreneur-message-message">
                                <h2>Hello Entrepreneur</h2>
                                <p>
                                  “Rentkar Revolutionizes Sharing Economy in
                                  India: Over 90K+ Customers Served Across
                                  Mumbai, Delhi, Bangalore, Pune in just 3
                                  years“
                                </p>
                              </div>
                            </div>
                            <div className="product-card d-flex" style={{cursor:"pointer"}} onClick={()=>{
                              scrollToDiv();
                              console.log("hello world");
                              
                            }}>
                              <div className="product-card-icon">
                                <img
                                  src={BentoHouse}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <h3>
                                DealHouse
                              </h3>
                            </div>
                            {/* <div className="boxes"> */}
                            {/* {allSuperCategories ? (
                              allSuperCategories
                                ?.filter((doc) => doc.isBentoGrid)
                                ?.map((elm, idx) => (
                                  <div
                                    key={idx}
                                    className="category-box"
                                    onClick={() =>
                                      navigate(
                                        getNavigate(
                                          elm?.category,
                                          elm?.location
                                        )
                                      )
                                    }
                                  >
                                    <div className="" />
                                    <img
                                      className="image-box"
                                      alt="Console"
                                      src={elm?.frontendIcon}
                                    />
                                   
                                    <div className="category-names">
                                      {elm?.category}
                                    </div>
                                  </div>
                                ))
                            ) : (
                              <center>No Categories</center>
                            )} */}
                            {/* {allSuperCategories ? (
                              (() => {
                                let counter = 0;
                                return allSuperCategories
                                  ?.filter((doc) => doc.isBentoGrid)
                                  ?.map((elm, idx) => {
                                    counter++;
                                    if (counter <= 4) {
                                      return (
                                        <div
                                          key={idx}
                                          className="category-box"
                                          onClick={() =>
                                            navigate(
                                              getNavigate(
                                                elm?.category,
                                                elm?.location
                                              )
                                            )
                                          }
                                        >
                                          <div className="" />
                                          <img
                                            className="image-box"
                                            alt="Console"
                                            src={elm?.frontendIcon}
                                          />

                                          <div className="category-names">
                                            {elm?.category}
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return null;
                                    }
                                  });
                              })()
                            ) : (
                              <center>No Categories</center>
                            )} */}

                            {/* <div className="category-box">
                              <div className="" />
                              <img className="image-box" alt="" src={Music} />
                              <div className="">Musical</div>
                            </div>
                            <div className="category-box">
                              <div className="" />
                              <img className="image-box" alt="" src={Apple} />
                              <div className="">Apple</div>
                            </div>
                            <div className="category-box">
                              <div className="" />
                              <img
                                className="image-box"
                                alt=""
                                src={Computers}
                              />
                              <div className="">Computers</div>
                            </div>
                            <div className="category-box">
                              <div className="" />
                              <img className="image-box" alt="" src={Camera} />
                              <div className="">Camera</div>
                            </div> */}
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="second-row">
                        <div className="people-said">
                          <img src={GoogleLogo} alt="" className="googleLogo" />
                          <div className="people-logo">
                            <img src={Aadil} alt="" srcSet="" />
                          </div>
                          <div className="people-detail">
                            <h4 className="people-name">Aadil Singh</h4>
                            <div className="peopleRating-star">
                              <img src={FourStar} alt="Star" />
                            </div>
                            <div className="people-desc">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Blanditiis quia dolorum natus
                                cupiditate ipsam iure officiis quas magni
                                accusamus obcaecati.
                              </p>
                            </div>
                            <h5 className="date">Feb 7,2024</h5>
                          </div>
                        </div>
                        {/* <div
                        className={` ${
                          isAnimating
                            ? "product-box-fade-out"
                            : "product-box-fade-in"
                        } `}
                      >
                        <div
                          className="outer_product_box"
                          onClick={() =>
                            navigate(
                              "/productdetails/" +
                                productDetails[currentIndex].customId
                            )
                          }
                        >
                          <img
                            className="product_img"
                            src={productDetails[currentIndexs]?.img}
                            loading="lazy"
                            alt="product_icon"
                          />
                          <span className="prod_name">
                            {productDetails[currentIndexs]?.title}
                          </span>
                          <p className="prod_price">
                            {productDetails[currentIndexs]?.price}
                          </p>
                        </div>
                      </div> */}
                        <div className="carousel-box">
                          <Swiper
                            direction={"vertical"}
                            slidesPerView={1}
                            spaceBetween={30}
                            mousewheel={true}
                            pagination={{
                              clickable: true,
                            }}
                            modules={[Mousewheel, Pagination, Autoplay]}
                            autoplay={true}
                            className="swiper-image bento-swiper"
                            key={window.screen.width}
                          >
                            {cityBanner.map((elm, idx) => (
                              <SwiperSlide
                                key={idx}
                                className="image-slider"
                                style={{ marginBottom: "0" }}
                              >
                                <img
                                  className="image-swiper img-fluid"
                                  src={elm.image}
                                  alt="banner"
                                  loading="lazy"
                                  onClick={() =>
                                    (window.location.href =
                                      elm.link || "http://www.w3schools.com")
                                  }
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
              style={{ borderRadius: "15px" }}
            >
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
              style={{ borderRadius: "15px" }}
            >
              <span className="visually-hidden">Next</span>
            </button> */}
            </div>
          </div>
        </div>

        {/* 
      catogeries section 
      1 */}
        {/* <div className="catogeryHeading">
        <p>Select From Category </p>
      </div> */}
        <div>
          <CatogeriesCard />
        </div>

        {/* head & Scroll Button */}
        <>
          <Contactbutton />
        </>

        {/* Trending Now section */}
        <div className="cardContainer">
          <div className="cardContainerContent">
            <Sliderproductcard text="Trending Now!" />
          </div>
        </div>


        {/* This is Dealhosue Component */}
        <Dealhouse paddTop={"0rem"} dealHouseRef={dealHouseRef}/>
        {/* Working */}
        {/* <div className="explainedContainer">
        <div className="explainedContent">
          <h1 className="heading1">How Renting Works</h1>
          <p className="details1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, risus
            aenean lectus at amet,{" "}
          </p>
          <video
            className="artwork"
            alt=""
            autoPlay
            muted
            loop
            ref={videoRef}
            onCanPlay={() => setPlayBack()}
          >
            <source src={"/Sequence 01.mp4"} type="video/mp4" />
          </video>
        </div>
      </div> */}

        <div className="listing-home-how-works-container">
          <div className="listing-home-how-works-content">
            <div className="how-works-para">
              <p
                onClick={() => {
                  setCurWork("renting");
                }}
                className="renting-heading"
                id={`${curWork === "renting" ? "active-work" : null}`}
              >
                How Renting Works
              </p>
              <p
                onClick={() => {
                  setCurWork("sharing");
                }}
                className="renting-heading"
                id={`${curWork === "sharing" ? "active-work" : null}`}
              >
                How Sharing Works
              </p>
              {/* <p className={`renting-heading ${curWork === "shareing" && 'active-work'}`}>How Sharing Works</p> */}
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna,
              risus aenean lectus at amet
            </p>
            {curWork === "renting" && (
              <div className="renting-works-container">
                <div className="renting-works-item">
                  <img src={SelectProduct} alt="" className="renting-icon" />
                  <div className="renting-desc">
                    <h5 className="renting-title">Share Your Product</h5>
                    <p id="benefits-info">
                      It's our habit to send each product through a 7-point
                      inspection.
                    </p>
                  </div>
                </div>

                <div className="renting-line">
                  <img src={live} alt="" />
                </div>

                <div className="renting-works-item">
                  <img
                    src={VerificationRenting}
                    alt=""
                    className="renting-icon"
                  />
                  <div className="renting-desc">
                    <h5 className="renting-title">Verification</h5>
                    <p id="benefits-info">
                      It's our habit to send each product through a 7-point
                      inspection.
                    </p>
                  </div>
                </div>

                <div className="renting-line">
                  <img src={live} alt="" />
                </div>

                <div className="renting-works-item">
                  <img src={Delivered} alt="" className="renting-icon" />
                  <div className="renting-desc">
                    <h5 className="renting-title">Get it Delivered</h5>
                    <p id="benefits-info">
                      It's our habit to send each product through a 7-point
                      inspection.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {curWork === "sharing" && (
              <div className="renting-works-container">
                <div className="renting-works-item">
                  <img src={SelectProduct} alt="" className="renting-icon" />
                  <div className="renting-desc">
                    <h5 className="renting-title">Select the Product</h5>
                    <p id="benefits-info">
                      It's our habit to send each product through a 7-point
                      inspection.
                    </p>
                  </div>
                </div>

                <div className="renting-line">
                  <img src={live} alt="" />
                </div>

                <div className="renting-works-item">
                  <img
                    src={VerificationRenting}
                    alt=""
                    className="renting-icon"
                  />
                  <div className="renting-desc">
                    <h5 className="renting-title">Verify and get Listed</h5>
                    <p id="benefits-info">
                      It's our habit to send each product through a 7-point
                      inspection.
                    </p>
                  </div>
                </div>

                <div className="renting-line">
                  <img src={live} alt="" />
                </div>

                <div className="renting-works-item">
                  <img src={Delivered} alt="" className="renting-icon" />
                  <div className="renting-desc">
                    <h5 className="renting-title">Get Returns</h5>
                    <p id="benefits-info">
                      It's our habit to send each product through a 7-point
                      inspection.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* company features */}
        <div className="companyFeaturesContainer">
          <div className="companyFeaturesContent">
            <div className="featuresHeadingContainer">
              <h1 className="heading2">There's More To Us</h1>
              <p className="details2">
                It's not simply a rental but a mission of exceptional service,
                quality, and variety we bring to you. Keep upgrading, exploring,
                and saving!
              </p>
            </div>

            <div className="qualityContainer">
              <div className="qualityContent">
                <img src={Union} alt="union" className="deposit" />
                <div className="qualityContent-desc-container">
                  <h1 className="qualityHeading">Quality Products</h1>
                  <p className="qualityDetails">
                    It's our habit to send each product through a 7-point
                    inspection.
                  </p>
                </div>
              </div>

              <div className="qualityContent">
                <img src={Delivery} alt="union" className="delivery" />
                <div className="qualityContent-desc-container">
                  <h1 className="qualityHeading">Instant Delivery</h1>
                  <p className="qualityDetails">
                    Products delivered within 2-4 hours.{" "}
                  </p>
                </div>
              </div>

              <div className="qualityContent">
                <img src={Deposit} alt="union" className="deposit" />
                <div className="qualityContent-desc-container">
                  <h1 className="qualityHeading">Zero Deposit</h1>
                  <p className="qualityDetails">
                    Experience the new world of renting without a fee.
                  </p>
                </div>
              </div>

              <div className="qualityContent liveLighter">
                <img src={Light} alt="union" className="light" />
                <div className="qualityContent-desc-container">
                  <h1 className="qualityHeading">Live Light</h1>
                  <p className="qualityDetails">
                    Naked we came on Earth, Naked we need to go!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* featured container */}
        <div className="featuredContainer">
          <h1 className="featuredHeading">Featured On</h1>
          <div className="featuredContent">
            <div className="image-contanier">
              <Link to="#">
                <img
                  src={AhmedabadMirror}
                  alt=""
                  className="AhmedabadMirror"
                  id="featuredOn"
                />
              </Link >
              <Link to="#">
                <img
                  src={HelloEntrepreneurs}
                  alt=""
                  className="HelloEntrepreneurs"
                  id="featuredOn"
                />
              </Link>
              <Link to="#">
                <img
                  src={DailyHunt}
                  alt=""
                  className="DailyHunt"
                  id="featuredOn"
                />
              </Link>
              <Link to="#">
                <img
                  src={MintMoney}
                  alt=""
                  className="MintMoney"
                  id="featuredOn"
                />
              </Link>
              
              {/* <img
                src={BusinessLine}
                alt=""
                className="BusinessLine"
                id="featuredOn"
              />
              <img
                src={FirstIndia}
                alt=""
                className="FirstIndia"
                id="featuredOn"
              />
              <img
                src={JaipurMirror}
                alt=""
                className="JaipurMirror"
                id="featuredOn"
              />
              <img
                src={BusinessNews}
                alt=""
                className="BusinessNews"
                id="featuredOn"
              /> */}
            </div>
            <div
            className="featuredContent"
            style={{ marginBottom: "4.001rem" }}
          ></div>
          </div>
          <div className="featuredContentMobile d-flex d-none">
            <ul className="m-0 p-0 d-flex gap-2 pb-5">
              <li>
                <img
                  src={AhmedabadMirror}
                  alt=""
                  className="AhmedabadMirror"
                  id="featuredOn"
                />
              </li>
              <li>
                <img
                  src={HelloEntrepreneurs}
                  alt=""
                  className="AhmedabadMirror"
                  id="featuredOn"
                />
              </li>
              <li>
                <img
                  src={DailyHunt}
                  alt=""
                  className="AhmedabadMirror"
                  id="featuredOn"
                />
              </li>
            </ul>
            {/* <div
            className="featuredContent"
            style={{ paddingBottom: "4.001rem" }}
          ></div> */}
          </div>
          
        </div>

        {/* Part section */}
        <div className="ecosystemContainer">
          <div className="ecosystemContent">
            <div className="blogPart">
              <h1 className="ecoHeading">Be a Part of Our Ecosystem</h1>
              <p className="blogHeading">
                "It takes both sides to build a bridge"
              </p>
              <p className="blogDetails">
                Join our community of lenders sharing their products all around
                the city. We help you with delivery, pick up and total security
                of your products
              </p>

              <div className="buttonPart">
                {isLogin ? (
                  <>
                    <button
                      className="learnMore"
                      onClick={() => setIsListing(true)}
                    >
                      <span className="learnText">Share Your Product</span>
                    </button>
                    <PopUp
                      display={isListing}
                      callback={setIsListing}
                      center={true}
                      uniqueId={"listProdPopup"}
                      zIndex={15}
                    >
                      <ListProductPopUp
                        setIsListing={setIsListing}
                        isListing={isListing}
                      />
                    </PopUp>
                  </>
                ) : (
                  <>
                    <button
                      className="learnMore"
                      onClick={() => setLoginPopUp(true)}
                    >
                      <span className="learnText">Login to Share product</span>
                    </button>
                    <PopUp
                      display={loginPopUp}
                      callback={setLoginPopUp}
                      center={true}
                      uniqueId={"loginPopup"}
                    >
                      <Login
                        loginClose={() => {
                          setLoginPopUp(false);
                        }}
                      />
                    </PopUp>
                  </>
                )}
                <button className="listItemm">
                  <div
                    className="buttonContentt"
                    style={{ background: "#070707" }}
                  >
                    <Link
                      to={`${isLogin ? "/mylisting" : "/listing_home"}`}
                      style={{ textDecoration: "none" }}
                    >
                      <span className="listTextt">Learn More</span>
                    </Link>
                  </div>
                </button>
              </div>
            </div>

            <div className="imagePart img-fluid">
              <img src={Ecosystem} alt="" className="learnArt" />
            </div>
          </div>
        </div>

        <PeopleSaid key={window.screen.width} />

        <Scrollerbutton className="mobileGoTopContainer" />

        <Footer location={location} />
      </div>
    </>
  );
};

export default Home;
