import "./myordercancellation.css";
import "../../pages/Productdetails/productdetails.css";

import { Calender, OrderAccepted, SmallCross } from "../../assets";
import { DatePicker, FailureAlert, LoadingPage } from "..";
import {
  cancelOrderSlice,
  cancelUserOrderSlice,
  clearErrorSlice,
} from "../../redux/features/orderSlice/orderSlice";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import PopUp from "../PopUp/PopUp";
import React from "react";
import { SUCCESS } from "../../constants/constants";
import { dateFormatter } from "../../utils/displayUtils";
import { useEffect } from "react";
import { usePopper } from "react-popper";
import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";
import { useState } from "react";

const Myordercancellation = (props) => {
  const today = new Date();
  const formattedDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const window = useResponsiveWindow();

  const [isCancel, setIsCancel] = useState(false);
  const [returnDate, setReturnDate] = useState(false);
  const [returnDateValue, setReturnDateValue] = useState(formattedDate);
  const [selectReason, setSelectReason] = useState({ index: -1, reason: "" });

  // Popper references ======================================================
  // start date
  const [refStart, setRefStart] = useState();
  const [popStart, setPopStart] = useState();
  // start date mobile
  const [refStartMobile, setRefStartMobile] = useState();
  const [popStartMobile, setPopStartMobile] = useState();

  useEffect(() => {
    if (isCancel) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  const popUpParamsBtmStart = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };

  const popper3 = usePopper(refStart, popStart, popUpParamsBtmStart);
  const popperStartMobile = usePopper(
    refStartMobile,
    popStartMobile,
    popUpParamsBtmStart
  );

  // Async thunk
  const dispatch = useDispatch();

  // Constant Error
  const loading = useSelector((state) => state.rootReducer.orderInfo.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.orderInfo.isError
  );
  const error = useSelector((state) => state.rootReducer.orderInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  // if (window.innerWidth <= 991) {
  //   return (
  //     <>
  //       <div className="myorder-cancellation-container">
  //         <div className="myorder-cancellation-content">
  //           <p id="cancellation-text">
  //             Request a Cancellation{" "}
  //             <img
  //               src={SmallCross}
  //               alt="smallcross"
  //               style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
  //               onClick={props.onclick}
  //             />
  //           </p>
  //           <p id="cancellation-desc">
  //             Did something go wrong? Contact us. We are sorry to hear you
  //             cancel the product before the tenure. You may be charged for the
  //             cancellation of the product before your tenure.
  //           </p>
  //           <p id="cancellation-reason">Select a reason for cancellation</p>

  //           <label htmlFor="">
  //             <input type="radio" name="" id="" />
  //             <span>Defective product received</span>
  //           </label>

  //           <label htmlFor="">
  //             <input type="radio" name="" id="" />
  //             <span>Defective product received</span>
  //           </label>

  //           <label htmlFor="">
  //             <input type="radio" name="" id="" />
  //             <span>The product has been damaged</span>
  //           </label>

  //           <label htmlFor="">
  //             <input type="radio" name="" id="" />
  //             <span>Others</span>
  //           </label>

  //           <input
  //             type="text"
  //             name=""
  //             id=""
  //             placeholder="Reason for cancellation"
  //           />

  //           <div className="myorder-cancellation-calender-container">
  //             <div className="cancellation-calender-content">
  //               <input type="date" name="" id="" />
  //               <div className="calender-img">
  //                 <img src={Calender} alt="calender" />
  //               </div>
  //             </div>
  //             <button type="submit" onClick={() => setIsCancel(!isCancel)}>
  //               Confirm Cancellation
  //             </button>
  //             {isCancel && (
  //               <>
  //                 <div className="myorder-cancellation-submitted-container">
  //                   <div className="myorder-cancellation-submitted-content">
  //                     <img src={OrderAccepted} alt="orderaccept" />
  //                     <p id="cancel-request-text">Cancellation Request Sent</p>
  //                     <p id="cancel-request-desc">
  //                       Your cancellation request is being processed. We will
  //                       get back to you within 24 hours of a working day, for
  //                       further enquiry{" "}
  //                       <span style={{ color: "#0E9BE1" }}>Contact us.</span>
  //                     </p>
  //                     <button
  //                       type="submit"
  //                       id="cancel-request-done-btn"
  //                       onClick={props.onclick}
  //                     >
  //                       Done
  //                     </button>
  //                   </div>
  //                 </div>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // } else {
  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div className="myorder-cancellation-container">
        <div className="myorder-cancellation-content">
          <p id="cancellation-text">
            Request a Cancellation{" "}
            <img
              src={SmallCross}
              alt="smallcross"
              style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
              onClick={props.onclick}
            />
          </p>
          <p id="cancellation-desc">
            Did something go wrong? Contact us. We are sorry to hear you cancel
            the product before the tenure. You may be charged for the
            cancellation of the product before your tenure.
          </p>
          <p id="cancellation-reason">Select a reason for cancellation</p>

          <label htmlFor="">
            <input
              type="radio"
              name=""
              id=""
              checked={selectReason.index == 0}
              onChange={() => {
                setSelectReason({
                  index: 0,
                  reason: "Defective product received",
                });
              }}
            />
            <span>Defective product received</span>
          </label>

          <label htmlFor="">
            <input
              type="radio"
              name=""
              id=""
              checked={selectReason.index == 1}
              onChange={() => {
                setSelectReason({
                  index: 1,
                  reason: "Defective product received",
                });
              }}
            />
            <span>Defective product received</span>
          </label>

          <label htmlFor="">
            <input
              type="radio"
              name=""
              id=""
              checked={selectReason.index == 2}
              onChange={() => {
                setSelectReason({
                  index: 2,
                  reason: "The product has been damaged",
                });
              }}
            />
            <span>The product has been damaged</span>
          </label>

          <label htmlFor="">
            <input
              type="radio"
              name=""
              id=""
              checked={selectReason.index == 3}
              onChange={() => {
                setSelectReason({ index: 3 });
              }}
            />
            <span>Others</span>
          </label>

          <input
            type="text"
            name=""
            id="myorder-reason-for-cancellation"
            placeholder="Reason for cancellation"
            disabled={selectReason.index !== 3}
            onChange={(e) => {
              setSelectReason({ index: 3, reason: e.target.value });
            }}
          />

          <div
            className="myorder-cancellation-calender-container"
            ref={setRefStart}
          >
            <div className="cancellation-calender-content">
              <input
                name="order-cancellation-date-input"
                type="text"
                placeholder="dd/mm/yyyy"
                value={dateFormatter(returnDateValue)}
              />
              <label
                htmlFor="order-cancellation-date-input"
                id="calenderimg-outer-container"
                onClick={() => setReturnDate(!returnDate)}
              >
                <img src={Calender} alt="calender" />
              </label>
            </div>
            <PopUp
              display={returnDate && !window.phone}
              callback={setReturnDate}
              popper={popper3}
            >
              <div>
                <div
                  className="product-details-start-date-container"
                  ref={setPopStart}
                  style={{ ...popper3?.styles?.popper }}
                  {...popper3?.attributes}
                >
                  <DatePicker
                    date={returnDateValue}
                    setDate={setReturnDateValue}
                  />
                  <div className="date-picker-bottom-container">
                    <button onClick={() => setReturnDate(!returnDate)}>
                      Set Date
                    </button>
                  </div>
                </div>
              </div>
            </PopUp>
            <button
              type="submit"
              disabled={selectReason.reason == ""}
              style={{ opacity: selectReason.reason == "" ? 0.5 : 1 }}
              onClick={() => setIsCancel(!isCancel)}
            >
              Request Cancellation
            </button>
            {isCancel && (
              <>
                <div className="myorder-cancellation-submitted-container">
                  <div className="myorder-cancellation-submitted-content">
                    <img src={OrderAccepted} alt="orderaccept" />
                    <p id="cancel-request-text">Cancellation Request Sent</p>
                    <p id="cancel-request-desc">
                      Your cancellation request is being processed. We will get
                      back to you within 24 hours of a working day, for further
                      enquiry{" "}
                      <span style={{ color: "#0E9BE1" }}>Contact us.</span>
                    </p>
                    <button
                      type="submit"
                      id="cancel-request-done-btn"
                      onClick={() => {
                        const data = {
                          orderId: props.orderId,
                          status: "CANCELLED",
                          message: selectReason.reason,
                        };

                        dispatch(cancelUserOrderSlice(data)).then((data) => {
                          if (data.payload.type == SUCCESS) {
                            props.onclick();
                            props.setStatus(true);
                          }
                        });
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default Myordercancellation;
