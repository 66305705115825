import "./adminlayout.css";

import { Adminnavbar, Adminsidebar } from "../../components";
import React, { useState } from "react";

import { Outlet } from "react-router-dom";

function AdminLayout() {
  const [isSlider, setIsSlider] = useState(true);
  return (
    <>
      <Adminnavbar slider={isSlider} onclick={() => setIsSlider(!isSlider)} />
      <Adminsidebar slider={isSlider} />
      <div
        className={
          isSlider ? "main-admin-container" : "main-admin-container-closed"
        }
      >
        <Outlet />
      </div>
    </>
  );
}

export default AdminLayout;
