import "./PaymentDetails.css";
import lodash from "lodash";

import {
  INVOICE,
  dateFormatter,
  formatDateToDMY,
  formatPrice,
  getDeliveryCharge,
  getDuration,
  getNoOfDays,
  getOnePercent,
  getSubTotal,
} from "../../utils/displayUtils";
import { useDispatch, useSelector } from "react-redux";

import LateFeeInfoContainer from "./LateFeeInfoContainer";
import PopUp from "../PopUp/PopUp";
import React from "react";
import { fetchRazorpayPaymentThunk } from "../../redux/features/paymentSlice/paymentSlice";
import { useEffect } from "react";
import { usePopper } from "react-popper";
// import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";
import { useState } from "react";

function PaymentDetails({
  display,
  setDisplay,
  popper,
  setPopper,
  data,
  zIndex,
  uniqueId,
  // offerType,
}) {
  const [lateFeeInfo, setLateFeeInfo] = useState({ display: false, data: {} });
  const userCredit = useSelector(
    (state) => state.rootReducer?.userInfo?.data?.creditInfo?.credits
  );

  const [refElm, setRefElm] = useState();
  const [popElm, setPopElm] = useState();

  const popUpParamsStartEnd = {
    placement: "left-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };

  const lateFeepopper = usePopper(refElm, popElm, popUpParamsStartEnd);

  const paidAmountDetails = useSelector(
    (state) => state.rootReducer.payments.paidPaymentDetails
  );
  const dispatch = useDispatch();
  const orders = data.order.orders;
  useEffect(() => {
    if (display) {
      let paidOrders = orders
        ?.filter((elm) => elm?.razorPayDetails.paymentId.trim() !== "")
        .map((elm) => elm?.razorPayDetails.paymentId);
      dispatch(fetchRazorpayPaymentThunk({ paidOrders: paidOrders }));
    }
  }, [orders, display]);

  const amountColour = (elm, isOverDue, overdue_days) => {
    if (elm.razorPayDetails.paymentId) {
      return { color: "green", fontWeight: "600" };
    }
    if (isOverDue && overdue_days > 0) {
      return { color: "#D34E4E", fontWeight: "600" };
    } else {
      return { color: "#dbab25", fontWeight: "600" };
    }
  };

  const get_amount_details = (data, order) => {
    const {
      lateFees,
      subEndDate,
      price,
      subStartDate,
      extensionNumber,
      extensionSubNumber,
    } = data;
    const gracePeriod = new Date(lateFees.gracePeriod);

    const currentDate = new Date();
    const subEndDate_ = new Date(subEndDate);
    const subStartDate_ = new Date(subStartDate);
    const addedGst = price?.addedGst || NaN;
    const tenure = getNoOfDays(subStartDate_, subEndDate_);
    const exceededDate = currentDate > subEndDate_ ? subEndDate_ : currentDate;
    const orderDetails = {
      creditInfo: function () {
        const credit = {
          creditApplicable: false,
          accessibleCredit: 0,
        };
        if (userCredit > 0 && order?.useCredit) {
          credit["creditApplicable"] = true;
          credit["accessibleCredit"] = userCredit;
          // if (userCredit > getOnePercent(this.monthly)) {
          //   credit["accessibleCredit"] = getOnePercent(this.monthly);
          // } else {
          //   credit["accessibleCredit"] = userCredit;
          // } 
          }
        return credit;
      },
      deliveryInfo: function () {
        const delivery = {
          isApplicable: false,
          deliveryTotal: 0,
        };
        if (extensionNumber === 0 && extensionSubNumber === 0) {
          delivery["isApplicable"] = true;
          delivery["deliveryTotal"] =
            INVOICE.DELIVERY_CHARGE_GST(
              getDeliveryCharge(order?.packageInfo?.packageWeight)
            ).lineTotal || 100;
        }
        return delivery;
      },
      lateFeesApplicable: function () {
        if (currentDate > gracePeriod && lateFees.lateFeesApplicable) {
          return true;
        } else {
          return false;
        }
      },
      dueDate: formatDateToDMY(gracePeriod),
      monthly: getSubTotal(tenure, addedGst),
      lateCharge: function () {
        if (currentDate > gracePeriod && this.lateFeesApplicable()) {
          const addedCharge =
            this.monthly * (lateFees?.lateFeesPercentage / 100);
          return lodash.round(addedCharge * this.overdue_days());
        } else {
          return 0;
        }
      },
      totalAmount: function () {
        let total = this.monthly + this.lateCharge();
        if (this.creditInfo().creditApplicable) {
          total = total - this.creditInfo().accessibleCredit;
        }
        if (this.deliveryInfo().isApplicable) {
          total = total + this.deliveryInfo().deliveryTotal;
        }
        return total;
      },
      overdue_days: function () {
        if (currentDate > subEndDate_ && tenure < 30) {
          return tenure;
        }
        if (currentDate > subEndDate_ && tenure >= 30) {
          return 30;
        }
        if (gracePeriod < currentDate) {
          return Math.floor(
            (exceededDate - subStartDate_) / (1000 * 60 * 60 * 24)
          );
        }
        if (tenure < 30) {
          return Math.floor(
            (exceededDate - subStartDate_) / (1000 * 60 * 60 * 24)
          );
        } else {
          return 0;
        }
      },
    };
    return orderDetails;
  };

  return (
    <PopUp
      display={display}
      callback={setDisplay}
      zIndex={zIndex || 9}
      uniqueId={uniqueId || "pricing-details"}
    >
      <div
        className="price-breakdown-container-new justify-content-evently"
        ref={setPopper}
        style={{
          ...popper?.styles?.popper,
        }}
        {...popper?.attributes}
      >
        <div className="pricing-details-header">Payment Breakdown</div>
        <div
          className="pricing-breakdown-details"
          style={{ borderBottom: "none" }}
        >
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Order Date</p>
            <p id="price-name-thick">Amount</p>
          </div>
          {data?.order?.orders?.map((elm, idx) => {
            return (
              <div className="price-breakdown-detail-row">
                <p id="price-name-light">
                  {dateFormatter(elm?.subStartDate) +
                    " for " +
                    getDuration(
                      getNoOfDays(elm?.subStartDate, elm?.subEndDate)
                    )}
                </p>
                <div
                  id="paymentamountdetail"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                    position: "relative",
                  }}
                >
                  <p
                    id="price-name-thick"
                    style={amountColour(
                      elm,
                      get_amount_details(elm).lateFeesApplicable(),
                      get_amount_details(elm).overdue_days()
                    )}
                  >
                    ₹
                    {elm?.razorPayDetails?.paymentId
                      ? formatPrice(
                          paidAmountDetails[
                            `${elm?.razorPayDetails?.paymentId}`
                          ]?.amount / 100
                        )
                      : formatPrice(
                          get_amount_details(elm, data?.order).totalAmount()
                        )}
                  </p>

                  {/* : formatPrice(
                        getSubTotal(
                          getNoOfDays(elm?.subStartDate, elm?.subEndDate),
                          elm?.price?.addedGst
                        )
                      )} */}

                  {!elm?.razorPayDetails?.paymentId &&
                  (get_amount_details(elm).lateFeesApplicable() ||
                    get_amount_details(elm).deliveryInfo().isApplicable) &&
                  (get_amount_details(elm).overdue_days() > 0 ||
                    get_amount_details(elm).deliveryInfo().isApplicable) ? (
                    <>
                      <svg
                        ref={setRefElm}
                        stroke="currentColor"
                        // fill="#fa6446"
                        fill={`${
                          get_amount_details(elm).overdue_days() > 0
                            ? `#fa6446`
                            : `#0b9e46`
                        }`}
                        strokeWidth="0"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        height="1.3em"
                        width="1.3em"
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() =>
                          setLateFeeInfo({
                            display: !lateFeeInfo.display,
                            data: elm,
                          })
                        }
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      {/* Late fee Amount price breakdown */}
                      <PopUp
                        display={lateFeeInfo.display}
                        callback={setLateFeeInfo}
                        zIndex={25}
                        uniqueId={"latefee-info-container"}
                      >
                        <LateFeeInfoContainer
                          setPopElm={setPopElm}
                          lateFeepopper={lateFeepopper}
                          get_amount_details={get_amount_details(
                            elm,
                            data?.order
                          )}
                          isDelivery={
                            get_amount_details(elm).overdue_days() > 0
                              ? false
                              : true
                          }
                        />
                      </PopUp>
                    </>
                  ) : (
                    <div style={{ height: "1.3em", width: "1.3em" }}></div>
                  )}
                  {/* {lateFeeInfo && idx == 2 && <LateFeeInfoContainer />} */}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`pricing-breakdown-details text-center ${
            data?.order?.orderStatus?.status !== "CANCELLED" ? "d-none" : ""
          }`}
          style={{ borderBottom: "none" }}
        >
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Cancelation Status</p>
            <p id="price-name-thick">
              {data?.order?.isRefund ? "Refundable" : "Chargeable"} Amount
            </p>
          </div>
          {data?.order?.orders?.map((elm, idx) => {
            return (
              <div className="price-breakdown-detail-row justify-content-around">
                <p id="price-name-light">
                  {data?.order?.cancellationAmount < 0 ? "PENDING" : "APPROVED"}
                </p>
                <div
                  id="paymentamountdetail"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                    position: "relative",
                  }}
                >
                  <p
                    id="price-name-thick"
                    style={amountColour(
                      elm,
                      get_amount_details(elm).lateFeesApplicable(),
                      get_amount_details(elm).overdue_days()
                    )}
                  >
                    ₹
                    {data?.order?.cancellationAmount < 0
                      ? 0
                      : data?.order?.cancellationAmount}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="pricing-no-of-days">
          <div className="price-breakdown-detail-row">
            <p id="price-name-light"></p>
            <p id="price-name-thick">{data?.priceNew?.withAddons}/Day</p>
          </div>
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Total Days</p>
            <p id="price-name-thick">x {data?.numdays} Days</p>
          </div>
        </div> */}
        {/* <div className="pricing-coupon-gst">
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Sub total </p>
            <p id="price-name-thick">
              ₹{getSubTotal(data?.numdays, data?.priceNew?.withAddons)}
            </p>
          </div>
          <div
            className="price-breakdown-detail-row"
            style={{ color: "#209032" }}
          >
            <p id="price-name-light">{offerType || "Coupon Discount"} </p>
            <p id="price-name-thick">
              - ₹{getSubTotal(data?.numdays, data?.priceNew?.discountAmount)}
            </p>
          </div>
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">GST </p>
            <p id="price-name-thick">
              + ₹{getSubTotal(data?.numdays, data?.priceNew?.gst)}
            </p>
          </div>
        </div> */}
        {/* <div className="pricing-grand-total">
          <div
            className="price-breakdown-detail-row-grand-total"
            style={{ alignItems: "center" }}
          >
            <p id="price-name-light">Grand Total</p>
            <p id="price-grand-total">₹Total</p>
          </div>
        </div> */}
      </div>
    </PopUp>
  );
}

export default PaymentDetails;
