import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOADING,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

// #===========================================

export const getAllTrendingPackagesPublic = createAsyncThunk(
  "public/package/get_with_filters",
  async (data) => {
    try {
      const body = { userLocation: data.location };

      const res = await axios.post(
        `${BASE_URL}/public/package/get_with_filters`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllTrendingPackagesUser = createAsyncThunk(
  "user/package/get_with_filters",
  async () => {
    try {
      const res = await axios.post(`${BASE_URL}/user/package/get_with_filters`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getProductDetails = createAsyncThunk(
  "public/package/get_package_info",
  async (data) => {
    try {
      const body = { packageId: data };
      const res = await axios.post(
        `${BASE_URL}/public/package/get_package_info`,
        body
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getProductAvailability = createAsyncThunk(
  "post/package/available",
  async (data) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/package/available`,
        { ...data }
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllCoupons = createAsyncThunk(
  "public/coupons/get_active_coupons",
  async (data) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/public/coupons/get_active_coupons`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const applyCoupon = createAsyncThunk(
  "public/coupons/apply_coupon",
  async (data) => {
    try {
      const body = { couponName: data };
      const res = await axios.post(
        `${BASE_URL}/public/coupons/apply_coupon`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllLocation = createAsyncThunk(
  "public/location/get_all_location",
  async (data) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/public/location/get_all_location`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPriceThunk = createAsyncThunk(
  "public/price/get_price",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/public/price/get_price`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllSuperCategoriesUser = createAsyncThunk(
  "public/category/unique_categories",
  async (data) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/public/category/unique_categories`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllSubCategoriesUser = createAsyncThunk(
  "public/category/get_all_sub_categories",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/category/get_all_sub_categories`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllPackages = createAsyncThunk(
  "/public/package/get_all_packages",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/package/get_all_packages`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllPackageNames = createAsyncThunk(
  "/public/package/get_all_package_names",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/package/get_all_package_names`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addSearchSuggestionThunk = createAsyncThunk(
  "/user/search_req/create",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/search_req/create`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getFilteredAddons = createAsyncThunk(
  "public/package/get_filtered_addon",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/package/get_filtered_addon`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCityPublicBannerThunk = createAsyncThunk(
  "post/public/public_banner/banner/getCityBanner",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/public_banner/banner/getCityBannner`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCouponByPackageId = createAsyncThunk(
  "public/coupons/get_coupon_by_packageId",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/coupons/get_coupon_by_packageId `,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCityOfferBannerThunk = createAsyncThunk(
  "post/public/public_bento_grid/getCityOfferBanner",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/public_bento_grid/getCityOfferBanner`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);



const initialState = {
  loading: false,
  millisearchResult:[],
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  status: {
    getAllTrendingPackagesPublic: IDLE,
    getAllTrendingPackagesUser: IDLE,
    getProductDetails: IDLE,
    getProductAvailability: IDLE,
    getAllCoupons: IDLE,
    applyCoupon: IDLE,
    getPriceThunk: IDLE,
    getAllSuperCategoriesUser: IDLE,
    getAllSubCategoriesUser: IDLE,
    getAllPackages: IDLE,
    getAllPackageNames: IDLE,
    addSearchSuggestionThunk: IDLE,
    getFilteredAddons: IDLE,
    getCityPublicBannerThunk: IDLE,
    getCouponByPackageId: IDLE,
    getCityOfferBannerThunk: IDLE,
  },
  currentPackageId: "",
  filters: {
    price: 1,
    popularity: 1,
    offer: 1,
  },
  data: {
    searchName: "",
    allTrendingPackages: [],
    allCoupons: [],
    packageDetails: {},
    allLocation: [],
    priceData: {},
    allSuperCategories: [],
    allSubCategories: [],
    allPackages: [],
    allPackageNames: [],
    allPackageNamesSorted: [],
    filteredAddonData: [],
    cityBanner: [],
    productDetails: {},
    cityOfferBanner: {}
  },
  isError: false,
  updateDone: false,
};

const homePageSlice = createSlice({
  name: "homepageslice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setMillisearchResult:(state,action) => {
      state.millisearchResult = action.payload
    },
    setUpdateDoneSlice: (state, { payload }) => {
      state.updateDone = payload.value;
    },
    setCurrentPackageId: (state, { payload }) => {
      state.currentPackageId = payload;
    },
    setPackageFilters: (state, { payload }) => {
      state.filters = payload;
    },
    setSearchName: (state, { payload }) => {
      state.data.searchName = payload;
    },
    sortPackageNames: (state, { payload }) => {
      state.data.allPackageNamesSorted = state.data.allPackageNames.filter(
        (value) => {
          if (value.name.toLowerCase().includes(payload.toLowerCase()))
            return value;
        }
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // get all trending package public  ======================================================================
      .addCase(getAllTrendingPackagesPublic.pending, (state, action) => {
        // state.status.getAllTrendingPackagesPublic = LOADING;
        state.loading = true;
      })
      .addCase(getAllTrendingPackagesPublic.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllTrendingPackagesPublic = FULFILLED;
            state.loading = false;
            state.data.allTrendingPackages = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllTrendingPackagesPublic.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllTrendingPackagesPublic = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all trending user  ======================================================================
      .addCase(getAllTrendingPackagesUser.pending, (state, action) => {
        // state.status.getAllTrendingPackagesUser = LOADING;
        state.loading = true;
      })
      .addCase(getAllTrendingPackagesUser.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllTrendingPackagesUser = FULFILLED;
            state.loading = false;
            state.data.allTrendingPackages = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllTrendingPackagesUser.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllTrendingPackagesUser = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get package info public  ======================================================================
      .addCase(getProductDetails.pending, (state, action) => {
        // state.status.getProductDetails = LOADING;
        state.loading = true;
      })
      .addCase(getProductDetails.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getProductDetails = FULFILLED;
            state.loading = false;
            state.data.packageDetails = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getProductDetails = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getProductAvailability.pending, (state, action) => {
        // state.status.getProductAvailability = LOADING;
        state.loading = true;
      })
      .addCase(getProductAvailability.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getProductAvailability = FULFILLED;
            state.loading = false;
            state.data.productDetails = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getProductAvailability.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getProductAvailability = ERROR;
      })
      // get all trending package public  ======================================================================
      .addCase(getAllCoupons.pending, (state, action) => {
        // state.status.getAllCoupons = LOADING;
        state.loading = true;
      })
      .addCase(getAllCoupons.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllCoupons = FULFILLED;
            state.loading = false;
            state.data.allCoupons = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllCoupons = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all trending package public  ======================================================================
      .addCase(applyCoupon.pending, (state, action) => {
        // state.status.applyCoupon = LOADING;
        state.loading = true;
      })
      .addCase(applyCoupon.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.applyCoupon = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(applyCoupon.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.applyCoupon = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all location public  ======================================================================
      .addCase(getAllLocation.pending, (state, action) => {
        // state.status.getAllLocation = LOADING;
        state.loading = true;
      })
      .addCase(getAllLocation.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllLocation = FULFILLED;
            state.loading = false;
            state.data.allLocation = payload.data
              .map((elm) => elm.name)
              .filter((elm) => elm != "ALL");
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllLocation.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllLocation = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get price thunk ======================================================================
      .addCase(getPriceThunk.pending, (state, action) => {
        // state.status.getPriceThunk = LOADING;
        state.loading = true;
      })
      .addCase(getPriceThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getPriceThunk = FULFILLED;
            state.loading = false;
            state.data.priceData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPriceThunk.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getPriceThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all super categories ======================================================================
      .addCase(getAllSuperCategoriesUser.pending, (state, action) => {
        // state.status.getAllSuperCategoriesUser = LOADING;
        state.loading = true;
      })
      .addCase(getAllSuperCategoriesUser.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllSuperCategoriesUser = FULFILLED;
            state.loading = false;
            state.data.allSuperCategories = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllSuperCategoriesUser.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllSuperCategoriesUser = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all sub categories ======================================================================
      .addCase(getAllSubCategoriesUser.pending, (state, action) => {
        // state.status.getAllSubCategoriesUser = LOADING;
        state.loading = true;
      })
      .addCase(getAllSubCategoriesUser.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllSubCategoriesUser = FULFILLED;
            state.loading = false;
            state.data.allSubCategories = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllSubCategoriesUser.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllSubCategoriesUser = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all packages ======================================================================
      .addCase(getAllPackages.pending, (state, action) => {
        // state.status.getAllPackages = LOADING;
        state.loading = true;
      })
      .addCase(getAllPackages.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllPackages = FULFILLED;
            state.loading = false;
            state.data.allPackages = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllPackages.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllPackages = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all packages names ======================================================================
      .addCase(getAllPackageNames.pending, (state, action) => {
        // state.status.getAllPackageNames = LOADING;
        state.loading = true;
      })
      .addCase(getAllPackageNames.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllPackageNames = FULFILLED;
            state.loading = false;
            state.data.allPackageNames = payload.data;
            state.data.allPackageNamesSorted = [];
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllPackageNames.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllPackageNames = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // add search suggestion ======================================================================
      .addCase(addSearchSuggestionThunk.pending, (state, action) => {
        // state.status.addSearchSuggestionThunk = LOADING;
        state.loading = true;
      })
      .addCase(addSearchSuggestionThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.addSearchSuggestionThunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addSearchSuggestionThunk.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.addSearchSuggestionThunk = ERROR;
        state.isError = true;
      })
      //  getFilteredAddons
      .addCase(getFilteredAddons.pending, (state, action) => {
        // state.status.addSearchSuggestionThunk = LOADING;
        state.loading = true;
      })
      .addCase(getFilteredAddons.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getFilteredAddons = FULFILLED;
            state.loading = false;
            state.data.filteredAddonData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getFilteredAddons.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getFilteredAddons = ERROR;
        state.isError = true;
      })

      //  get city banner
      .addCase(getCityPublicBannerThunk.pending, (state, action) => {
        // state.status.addSearchSuggestionThunk = LOADING;
        state.loading = true;
      })
      .addCase(getCityPublicBannerThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.cityBanner = payload.data;
            state.status.getCityPublicBannerThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getCityPublicBannerThunk.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getCityPublicBannerThunk = ERROR;
        state.isError = true;
      })

      //get coupons based on packageID
      .addCase(getCouponByPackageId.pending, (state, action) => {
        // state.status.getAllCoupons = LOADING;
        state.loading = true;
      })
      .addCase(getCouponByPackageId.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getCouponByPackageId = FULFILLED;
            state.loading = false;
            state.data.allCoupons = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getCouponByPackageId.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getCouponByPackageId = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      //get city bento grid offer banner
      .addCase(getCityOfferBannerThunk.pending, (state, action) => {
        // state.status.addSearchSuggestionThunk = LOADING;
        state.loading = true;
      })
      .addCase(getCityOfferBannerThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.cityOfferBanner = payload.data;
            state.status.getCityOfferBannerThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getCityOfferBannerThunk.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getCityOfferBannerThunk = ERROR;
        state.isError = true;
      })

  },
});

export default homePageSlice.reducer;
export const {
  clearErrorSlice,
  setCurrentPackageId,
  setPackageFilters,
  setSearchName,
  sortPackageNames,
  setMillisearchResult
} = homePageSlice.actions;
