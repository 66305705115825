import "./myorderextendpackage.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import {
  DatePicker,
  FailureAlert,
  LoadingPage,
  MyorderExtendAgreement,
} from "..";
import { PriceInfo, SmallCross, WhitePhone } from "../../assets";
import {
  clearErrorSlice,
  extendOrderSlice,
  getExtendedOrderPriceDetails,
  getPackageCustomIdThunk,
  getSingleOrderSlice,
  getUserExtensionOfferSlice,
  sendExtReqSlice,
  updateOrderUseCredit,
} from "../../redux/features/orderSlice/orderSlice";
import {
  dateFormatter,
  getDeliveryCharge,
  getDuration,
  getOnePercent,
  getSubTotal,
  getSubTotalMonthCap,
  priceFormatterDaily,
  priceFormatterrrr,
} from "../../utils/displayUtils";
import { useDispatch, useSelector } from "react-redux";

import PopUp from "../PopUp/PopUp";
import PricingDetailsNew from "../PricingDetails/PricingDetailsNew";
import React from "react";
import { SUCCESS } from "../../constants/constants";
import { getPersonalCreditInfoThunk } from "../../redux/features/loginSlice/loginSlice";
import { getProductDetails } from "../../redux/features/homePageSlice/homePageSlice";
import lodash from "lodash";
import { useEffect } from "react";
import { usePopper } from "react-popper";
import { useState } from "react";

const Myorderextendpackage = (props) => {
  // states from global storage//========================
  const getOrderById = useSelector(
    (state) => state.rootReducer.orderInfo?.data?.currentUserOrder
  );
  const updateDone = useSelector(
    (state) => state.rootReducer?.orderInfo?.updateDone
  );
  // const orderEndDate = new Date(getOrderById?.endDate);

  // =============================================================
  const [endDateClick, setEndDateClick] = useState(false);
  const [isClick, setIsClick] = useState({ key: -1, value: false });
  const [isAgreement, setIsAgreement] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [index, setIndex] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [packageExtended, setPackageExtended] = useState(false);
  // Popper references ======================================================

  // pc pricing
  const [refPricing, setRefPricing] = useState();
  const [popPricing, setPopPricing] = useState();
  // useState for date picker as it is not in use
  // const [endDateValue, setEndDateValue] = useState();
  const [newEndDateValue, setNewEndDateValue] = useState();
  const [disableReqButton, setDisableReqButton] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(undefined);
  const [requestDuration, setRequestDuration] = useState(0);
  // orderEnd date
  const [orderEndDate, setOrderEndDate] = useState();
  // for date selector update
  const [offerUpdate, setOfferUpdate] = useState(false);
  // selected Offer price Details
  const [extensionPriceDetails, setExtensionPriceDetails] = useState();

  useEffect(() => {
    if (isClick || isAgreement) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  const popUpParamsStartEnd = {
    placement: "top-end",
    // modifiers: [
    //   {s
    //     name: "offset",
    //     options: {
    //       offset: [0, 8],
    //     },
    //   },
    // ],
  };

  const popperPricing = usePopper(refPricing, popPricing, popUpParamsStartEnd);

  // Async thunk
  const dispatch = useDispatch();

  // Selectors
  const isLogin = useSelector((state) => state.rootReducer?.userInfo?.isLogin);

  const getOfferPrice = useSelector(
    (state) => state.rootReducer.orderInfo?.data?.priceData
  );

  // Constant Error
  const loading = useSelector((state) => state.rootReducer.orderInfo?.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.orderInfo.isError
  );
  const error = useSelector((state) => state.rootReducer.orderInfo?.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  const currentPackageDetails = useSelector(
    (state) => state.rootReducer.homePage?.data?.packageDetails
  );
  //utils functions
  // const newEndDate = (date, duration) => {
  //   let oldDate = new Date(date);
  //   oldDate.setDate(oldDate.getDate() + duration);
  //   return oldDate;
  // };

  // const disabledIndexButton = (index) => {
  //   return index == -1;
  // };

  // const disabledSendReqBtn = (endDate) => {
  //   const todayTime = new Date().getTime();
  //   const endDateTime = new Date(endDate).getTime();
  //   if (todayTime < endDateTime) {
  //     return true;
  //   }
  //   return false;
  // };

  const [creditInfo, setCreditInfo] = useState({});

  useEffect(() => {
    if (isLogin) {
      dispatch(getUserExtensionOfferSlice(props.orderId));
      dispatch(getSingleOrderSlice(props.orderId));
    }
  }, [updateDone, isLogin]);
  useEffect(() => {
    if (isLogin) {
      dispatch(getUserExtensionOfferSlice(props.orderId));
      dispatch(getSingleOrderSlice(props.orderId));
    }
  }, [updateDone, isLogin]);


  // useEffect(() => {
  //   if (getOrderById.endDate && newEndDateValue != "Invalid Date") {
  //     dispatch(
  //       getOfferPriceSlice({
  //         startDate: getOrderById?.endDate,
  //         endDate: newEndDateValue,
  //         packageId: props.packageId,
  //         discount: index != -1 && discountType,
  //         addons: props.paidAddons,
  //       })
  //     );
  //   }
  // }, [duration, index, discount, getOrderById]);

  //  new UseEffect
  useEffect(() => {
    if (!!getOrderById) {
      setOrderEndDate(getOrderById?.endDate);
    }
  }, [getOrderById]);

  // use effect to change the end date assigned on the initial load to the selected extension duration
  useEffect(() => {
    setOrderEndDate(newEndDateValue);
  }, [newEndDateValue]);

  useEffect(() => {
    if (selectedOffer) {
      const newEndDate = new Date(getOrderById?.endDate);
      // to get new end date from order's current end date
      const getNewEndDateForExtension = newEndDate.setDate(
        newEndDate.getDate() + selectedOffer?.duration
      );
      setNewEndDateValue(getNewEndDateForExtension);
    

    dispatch(
      getExtendedOrderPriceDetails({
        orderId: getOrderById?._id,
        startDate: getOrderById?.endDate,
        endDate: getSelectedOfferEndDate(),
        selectedOffer: selectedOffer?._id,
      })
    )
      .unwrap()
      .then((data) => {
        setExtensionPriceDetails(data?.data);
      });
    }
  }, [offerUpdate]);

  // console.log(selectedOffer);

  const getSelectedOfferEndDate = () => {
    const newEndDate = new Date(getOrderById?.endDate);
    // to get new end date from order's current end date
    const getNewEndDateForExtension = newEndDate.setDate(
      newEndDate.getDate() + selectedOffer?.duration
    );
    return getNewEndDateForExtension;
  };

  const selectPackageDuration = (dura) => {
    const newEndDate = new Date(getOrderById?.endDate);
    // to get new end date from order's current end date
    const getNewEndDateForExtension = newEndDate.setDate(
      newEndDate.getDate() + dura
    );
    setRequestDuration(dura);
    setNewEndDateValue(getNewEndDateForExtension);
  };

  useEffect(() => {
    dispatch(getPackageCustomIdThunk(props?.packageId)).then((data) => {
      if (data.payload.type === SUCCESS) {
        dispatch(getProductDetails(data.payload?.data?.customId));
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getPersonalCreditInfoThunk())
      .unwrap()
      .then((data) => {
        if (data.type == SUCCESS) {
          setCreditInfo(data.data);
        }
      });
  }, [updateDone, getOrderById]);

  const [onePercent, setOnePercent] = useState(0);

  // for updating isCredit status in cart
  const creditBtnClickHandler = () => {
    dispatch(
      updateOrderUseCredit({
        orderId: getOrderById?._id,
        creditStatus: !getOrderById?.useCredit,
      })
    );
  };

  const extendDateSubmitHandler = () => {
    dispatch(
      getExtendedOrderPriceDetails({
        orderId: getOrderById?._id,
        startDate: getOrderById?.endDate,
        endDate: getSelectedOfferEndDate(),
        selectedOffer: selectedOffer?._id,
      })
    )
      .unwrap()
      .then((data) => {
        if (data.type === SUCCESS) {
          let temp = getSubTotalMonthCap(
            data?.data?.noOfDays,
            data?.data?.price?.addedGst
          );
          setOnePercent(getOnePercent(temp));
          setIsAgreement(!isAgreement);
        }
      });
  };

  const getCreditAppliedPrice = () => {
    let subTotal = getSubTotal(
      extensionPriceDetails?.numdays > 30 ? 30 : extensionPriceDetails?.numdays,
      extensionPriceDetails?.price?.addedGst
    );
    let onePercent = getOnePercent(subTotal);
    let isEnoughCredit = creditInfo.credits > onePercent;

    if (isEnoughCredit) {
      return priceFormatterDaily(
        extensionPriceDetails?.numdays,
        extensionPriceDetails?.price?.addedGst,
        onePercent
      );
    } else {
      return priceFormatterDaily(
        extensionPriceDetails?.numdays,
        extensionPriceDetails?.price?.addedGst - creditInfo.credits
      );
    }
  };

  const ExtendPackageCompo = () => {
    // start date
    const [refStart, setRefStart] = useState();
    const [popStart, setPopStart] = useState();

    const popUpParamsBtmStart = {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [-150, -340],
          },
        },
      ],
    };

    const popper3 = usePopper(refStart, popStart, popUpParamsBtmStart);
    return (
      <div className="myorder-extend-package-info-conainer">
        <div className="myorder-extend-package-upperpart">
          {/* <p id="extended-text">
            Extend Package Date{" "}
            <img
              src={SmallCross}
              alt="cross"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
              }}
              onClick={props.onclick}
            />
          </p> */}
          <p id="extended-desc"></p>
          <div className="myorder-extended-offer-card-container">
            <div className="myorder-extended-offer-card-inner-container">
              {getOrderById?.extensionOffer?.map((data, idx) => (
                <div
                  className="myorder-extended-offer-card"
                  style={{
                    padding: index === idx && selectedOffer ? "1px" : "none",
                    border:
                      index === idx && selectedOffer
                        ? "2px solid #0E9BE1"
                        : "none",
                    userSelect: "none",
                  }}
                  onClick={() => {
                    setIndex(idx);
                    setSelectedOffer(data);
                    setOfferUpdate(!offerUpdate);
                  }}
                >
                  <p id="card-off-percent">
                    {data?.discount}% <span>OFF</span>
                  </p>
                  <div className="card-offer-desc">
                    <p id="card-cut-price">
                      ₹{priceFormatterrrr(data?.duration, data?.currPrice)}
                    </p>
                    <p id="card-offer-price">
                      ₹{priceFormatterrrr(data?.duration, data?.newPrice)}
                    </p>
                    <p
                      id="card-offer-duration"
                      style={{
                        color:
                          isClick.key === idx && !isClick.value
                            ? "#0E9BE1"
                            : "#DFDFDF",
                      }}
                    >
                      For {getDuration(data?.duration)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <p className="myorder-further-assistance-text">
          For further assistance contact us{" "}
          <p id="further-assistnace-contact-detail">
            <img src={WhitePhone} alt="phone" />
            18000 26597 / 18000 23567
          </p>
        </p>

        <div className="myorder-send-request-container">
          <p id="myorder-request-desc">
            Didn’t find your offers? Send us the end date and we will provide
            offers accordingly.
          </p>

          <div className="myorder-date-extension-container">
            <div className="select-plan-container-extend-package">
              {currentPackageDetails.customPlans?.map((elm, idx) => {
                return (
                  <div
                    key={idx}
                    className="plan-extend-package"
                    style={{
                      padding: requestDuration === elm.duration ? "1px" : "0px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      selectPackageDuration(elm.duration);
                      setSelectedOffer(null);
                    }}
                  >
                    <div className="plan-content-extend-pacakage">
                      <p id="subscription-time">{getDuration(elm.duration)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="package-extend-current-end-date-container">
              <p>Current End Date</p>
              <p>{dateFormatter(getOrderById?.endDate)}</p>
            </div>
            <button
              id="extend-offer-submit-button"
              type="submit"
              disabled={!getOrderById.endDate}
              onClick={() => {
                setDisableReqButton(true);
                const data = {
                  orderId: getOrderById?._id,
                  reqEndDate: new Date(newEndDateValue),
                };
                dispatch(sendExtReqSlice(data)).then(() => {
                  setSubmit(!submit);
                  setDisableReqButton(false);
                });
              }}
            >
              Send Request
            </button>
          </div>
        </div>

        <PopUp zIndex={13} center={true} display={submit} callback={setSubmit}>
          <div className="send-request-popup-container">
            <div className="send-request-popup-text-container">
              <p className="send-request-popup-text">
                Request sent successfully !
              </p>
            </div>
            <div className="use-credit-options-container">
              <button
                id="credit-confirm-popup-no"
                onClick={() => {
                  setSubmit(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </PopUp>

        <PopUp
          display={endDateClick && !window.phone}
          callback={setEndDateClick}
          // popper={popper3}
          uniqueId={"extend-enddate-calender"}
          zIndex={13}
        >
          <div
            ref={setPopStart}
            style={{ ...popper3?.styles?.popper }}
            {...popper3?.attributes}
          >
            <div className="product-details-start-date-container">
              <DatePicker
              // date={endDateValue}
              // setDate={setEndDateValue}
              // disbaleBeforeDate={formattedDate}
              />
            </div>
          </div>
        </PopUp>
      </div>
    );
  };
  const [delivery, setDelivery] = useState(0);

  useEffect(() => {
    setDelivery({
      charge: getDeliveryCharge(currentPackageDetails?.packageWeight),
      gst: lodash.round(
        getDeliveryCharge(currentPackageDetails?.packageWeight) * 0.18,
        2
      ),
    });
  }, [currentPackageDetails]);

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div className="myorder-extend-package-container">
        <div className="myorder-extend-package-content">
          <p id="extended-text">
            Extend Package Date{" "}
            <img
              src={SmallCross}
              alt="cross"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
              }}
              onClick={props.onclick}
            />
          </p>
          {isAgreement ? (
            <>
              <MyorderExtendAgreement
                onclick={props.onclick}
                isChecked={setIsChecked}
                checkedValue={isChecked}
              />
            </>
          ) : (
            <ExtendPackageCompo />
          )}
          {isAgreement && creditInfo.credits > 0 && (
            <div
              className="extend-package-credits-container"
              style={{
                gap: "2.5rem",
                marginTop: "-2rem",
                marginBottom: "0.5rem",
              }}
            >
              <div className="delivery-additional-options-use-credit">
                <p className="delivery-additional-options-use-credit-text">
                  <input
                    type="checkbox"
                    name=""
                    id="credit-btn-cb"
                    checked={getOrderById.useCredit}
                    onInput={() => creditBtnClickHandler()}
                    disabled={loading}
                  />
                  Use Credit
                </p>
              </div>
              <div
                className="credit-details-container"
                style={{
                  width: "25.5rem",
                  height: "3.5rem",
                }}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                  >
                    <path
                      d="M26.2035 12.933L14.0602 0.789754C13.8095 0.538802 13.5117 0.339849 13.1839 0.20432C12.8561 0.0687915 12.5047 -0.00064291 12.15 4.48574e-06H2.7C1.20825 4.48574e-06 0 1.20825 0 2.7V12.15C0 12.8993 0.30375 13.5743 0.7965 14.0603L12.9465 26.2102C13.4325 26.6962 14.1075 27 14.85 27C15.5992 27 16.2742 26.6962 16.7602 26.2102L26.2102 16.7603C26.6962 16.2675 27 15.5925 27 14.85C27 14.1008 26.6962 13.4258 26.2035 12.933ZM4.725 6.75C3.6045 6.75 2.7 5.8455 2.7 4.725C2.7 3.6045 3.6045 2.7 4.725 2.7C5.8455 2.7 6.75 3.6045 6.75 4.725C6.75 5.8455 5.8455 6.75 4.725 6.75ZM20.6145 17.9145L14.85 23.679L9.0855 17.9145C8.478 17.3003 8.1 16.4565 8.1 15.525C8.1 13.662 9.612 12.15 11.475 12.15C12.4065 12.15 13.257 12.528 13.8645 13.1423L14.85 14.1278L15.8355 13.1423C16.4497 12.528 17.2935 12.15 18.225 12.15C20.088 12.15 21.6 13.662 21.6 15.525C21.6 16.4565 21.222 17.3003 20.6145 17.9145Z"
                      fill="url(#paint0_linear_744_1337)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_744_1337"
                        x1="0.506251"
                        y1="0.50625"
                        x2="20.7721"
                        y2="22.1168"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#19EEB5" />
                        <stop offset="0.720714" stop-color="#1BAFC4" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div>
                  <div className="credit-details-container-sup-text">
                    Credits Applicable:
                  </div>
                  <div className="credit-details-container-sub-text">
                    Credits Available:
                  </div>
                </div>
                <div>
                  <div className="credit-details-container-sup-text">
                    ₹
                    {creditInfo?.credits < onePercent ? (
                      <>{creditInfo?.credits} credit info</>
                    ) : (
                      <>{onePercent} onePercent</>
                    )}
                  </div>
                  <div className="credit-details-container-sub-text">
                    ₹{creditInfo?.credits}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="myorder-extend-package-lowerpart">
            <p id="newend-date-text">
              New End Date{" "}
              <span>
                {/* {endDate != "Invalid Date" ? (
                  <p>{dateFormatter(endDate)}</p>
                ) : (
                  <p>{dateFormatter(newEndDateValue)}</p>
                )}{" "} */}
                <p>{dateFormatter(orderEndDate)}</p>
              </span>
            </p>
            {selectedOffer && (
              <p id="total-price-text">
                Total cost{" "}
                <span>
                  ₹
                  {getOrderById?.useCredit && creditInfo?.credits > 0
                    ? getCreditAppliedPrice()
                    : priceFormatterDaily(
                        extensionPriceDetails?.numdays,
                        extensionPriceDetails?.price?.addedGst
                      )}
                  <img
                    ref={setRefPricing}
                    id="myorder-total-price-infomation-img"
                    src={PriceInfo}
                    alt="info"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsHovering(true)}
                  />
                </span>
              </p>
            )}
            {/* change price details */}
            <PricingDetailsNew
              data={{
                ...getOfferPrice,
                useCredit: getOrderById.useCredit,
                creditInfo: creditInfo,
                pkg: { name: props.packageName },
              }}
              uniqueId={"orders-pricing-details"}
              zIndex={15}
              display={isHovering}
              setDisplay={setIsHovering}
              popper={popperPricing}
              setPopper={setPopPricing}
              offerType={"Offer Discount"}
            />
            {isAgreement ? (
              <button
                id="extend-offer-submit-button"
                type="submit"
                disabled={!isChecked}
                onClick={() => {
                  const data = {
                    orderId: props.orderId,
                    selectedOffer: selectedOffer,
                  };
                  dispatch(extendOrderSlice(data)).then((data) => {
                    if (data.payload.type === SUCCESS) {
                      setPackageExtended(!packageExtended);
                    }
                  });
                }}
              >
                I Agree
              </button>
            ) : (
              <button
                id="extend-offer-submit-button"
                className="extend-date-button-new"
                type="submit"
                disabled={!selectedOffer}
                onClick={() => extendDateSubmitHandler()}
              >
                {/* Extend Date */}
              </button>
            )}
            <PopUp
              zIndex={13}
              center={true}
              display={packageExtended}
              callback={setPackageExtended}
            >
              <div className="send-request-popup-container">
                <div className="send-request-popup-text-container">
                  <p className="send-request-popup-text">
                    Packages Extended successfully !
                  </p>
                </div>
                <div className="use-credit-options-container">
                  <button
                    id="credit-confirm-popup-no"
                    onClick={() => {
                      setPackageExtended(!packageExtended);
                      props.onclick();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </PopUp>
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default Myorderextendpackage;
