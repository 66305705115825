// import "./pricingdetailsnew.css";

// import { getOnePercent, getSubTotal } from "../../utils/displayUtils";

// import PopUp from "../PopUp/PopUp";
// import React from "react";
// // import { Rupees } from "../../assets";
// import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";

// function PricingDetailsNew({
//   display,
//   setDisplay,
//   popper,
//   setPopper,
//   data,
//   zIndex,
//   uniqueId,
//   offerType,
// }) {
//   const window = useResponsiveWindow();

//   const getCreditOffAmount = () => {
//     let subTotal = getSubTotal(data?.numdays, data?.priceNew?.addedGst);
//     let onePercent = getOnePercent(subTotal);
//     let isEnoughCredit = data.creditInfo.credits > onePercent;

//     if (data.useCredit && data?.creditInfo?.credits > 0) {
//       if (isEnoughCredit) {
//         return subTotal - onePercent;
//       } else {
//         return subTotal - data?.creditInfo.credits;
//       }
//     }
//   };

//   const appliedCredits = () => {
//     let subTotal = getSubTotal(
//       data?.numdays > 30 ? 30 : data?.numdays,
//       data?.priceNew?.addedGst
//     );
//     let onePercent = getOnePercent(subTotal);
//     let isEnoughCredit = data.creditInfo.credits > onePercent;

//     if (data.useCredit && data?.creditInfo?.credits > 0) {
//       if (isEnoughCredit) {
//         return onePercent;
//       } else {
//         return data?.creditInfo.credits;
//       }
//     }
//   };

//   function getAddonDetails(data) {
//     if (!data) {
//       return false;
//     }
//     const addons = data?.filter((elm, i) => elm.name !== "PACKAGE_NAME");
//     const addonsList = JSON.parse(JSON.stringify(addons)).map((elm) => {
//       let obj = {};
//       obj["name"] = elm["name"];
//       obj["cgst"] = elm["cgst"];
//       obj["sgst"] = elm["sgst"];
//       obj["rate"] = elm["rate"];
//       obj["lineTotal"] = elm["lineTotal"];
//       return obj;
//     });
//     const addonDetails = {
//       addons: addonsList,
//       addonsLineTotal: addonsList.reduce((sum, num) => sum + num.lineTotal, 0),
//     };
//     return addonDetails;
//   }
//   // console.log({ data });

//   return (
//     <PopUp
//       display={display}
//       callback={setDisplay}
//       zIndex={zIndex || 9}
//       uniqueId={uniqueId || "pricing-details"}
//       center={window.phone}
//     >
//       <div
//         className="price-breakdown-container-new"
//         ref={setPopper}
//         style={{
//           ...popper.styles.popper,
//         }}
//         {...popper.attributes}
//       >
//         <div className="pricing-details-header">Price Breakdown</div>
//         <div className="pricing-breakdown-details">
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">{data?.pkg?.name}</p>
//             <p id="price-name-thick">
//               {parseInt(data?.priceNew?.basePrice / data?.numdays)}/Day
//             </p>
//           </div>
//           {getAddonDetails(data.invPrice) &&
//             getAddonDetails(data.invPrice).addons?.length > 0 && (
//               <>
//                 <div className="price-breakdown-detail-row">
//                   <p id="price-name-light" style={{ fontWeight: "600" }}>
//                     Addons
//                   </p>
//                 </div>
//                 {getAddonDetails(data.invPrice)?.addons?.map((elm) => (
//                   <div className="price-breakdown-detail-row">
//                     <p id="price-name-light">{elm.name}</p>
//                     <p id="price-name-thick">₹ {elm.rate}</p>
//                   </div>
//                 ))}
//               </>
//             )}

//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Total Days</p>
//             <p id="price-name-thick">x {data?.numdays} Days</p>
//           </div>
//           {/* {getAddonDetails(data.invPrice).addons.map((elm) => (
//             <div className="price-breakdown-detail-row">
//               <p id="price-name-light">{elm.name}</p>
//               <p id="price-name-thick">₹ {elm.lineTotal}</p>
//             </div>
//           ))}
//           {Object.keys(data?.priceNew?.addons || {}).map((key, idx) => {
//             return (
//               <div className="price-breakdown-detail-row">
//                 <p id="price-name-light">{key}</p>
//                 <p id="price-name-thick">{data?.priceNew?.addons[key]}/Day</p>
//               </div>
//             );
//           })} */}
//         </div>
//         {/* <div className="pricing-no-of-days">
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light"></p>
//             <p id="price-name-thick">{data?.priceNew?.withAddons}/Day</p>
//           </div>
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Total Days</p>
//             <p id="price-name-thick">x {data?.numdays} Days</p>
//           </div>
//         </div> */}
//         <div className="pricing-coupon-gst">
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Total </p>
//             <p id="price-name-thick">
//               ₹{getSubTotal(data?.numdays, data?.priceNew?.withAddons)}
//               {/* ₹ {getSubTotal(data?.numdays, data?.priceNew?.basePrice)} */}
//             </p>
//           </div>
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Delivery Charge</p>
//             <p id="price-name-thick">
//               ₹{data?.delivery?.charge ? data?.delivery?.charge : 0}
//             </p>
//           </div>
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Sub Total </p>
//             <p id="price-name-thick">
//               ₹
//               {getSubTotal(
//                 data?.numdays,
//                 data?.priceNew?.withAddons,
//                 data.delivery?.charge ? data?.delivery?.charge : 0
//               )}
//               {/* commit right now
//               {data?.priceNew?.basePrice + data?.delivery?.charge} */}
//             </p>
//           </div>
//           <div
//             className="price-breakdown-detail-row"
//             style={{ borderTop: "1px solid #464646" }}
//           >
//             <p id="price-name-light">GST </p>
//             {/* {console.log(data.delivery.gst)} */}
//             <p id="price-name-thick">
//               + ₹
//               {getSubTotal(data?.numdays, data?.priceNew?.gst) +
//                  (data?.delivery?.gst ? data?.delivery?.gst : 0)}
//               {/* {parseInt(
//                 data?.priceNew?.addedGst -
//                   data?.priceNew?.basePrice -
//                   data?.delivery?.charge
//               )} */}
//               {console.log("data from popup", data)}
//             </p>
//           </div>
//           <div
//             className="price-breakdown-detail-row"
//             style={{ color: "#209032" }}
//           >
//             <p id="price-name-light">{offerType || "Coupon Discount"} </p>
//             <p id="price-name-thick">
//               - ₹{getSubTotal(data?.numdays, data?.priceNew?.discountAmount)}
//               {/* - ₹{data?.priceNew?.discountAmount} */}
//               {/* - ₹ 0 */}
//             </p>
//           </div>

//           {data?.useCredit && data?.creditInfo?.credits > 0 && (
//             <div className="price-breakdown-detail-row">
//               <p id="price-name-light">Credits </p>
//               <p id="price-name-thick">- ₹{appliedCredits()}</p>
//             </div>
//           )}
//         </div>
//         <div className="pricing-grand-total">
//           <div
//             className="price-breakdown-detail-row-grand-total"
//             style={{ alignItems: "center" }}
//           >
//             <p id="price-name-light">Grand Total</p>
//             {data?.useCredit && data?.creditInfo?.credits > 0 ? (
//               <p id="price-grand-total">₹ {getCreditOffAmount()}</p>
//             ) : (
//               <p id="price-grand-total">
//                 ₹
//                 {getSubTotal(
//                   data?.numdays,
//                   data?.priceNew?.addedGst,
//                   (data?.delivery?.charge ? data?.delivery?.charge : 0) +
//                     (data.delivery?.gst ? data?.delivery?.gst : 0)
//                 )}
//               </p>
//             )}
//           </div>
//         </div>
//       </div>
//     </PopUp>
//   );
// }

// export default PricingDetailsNew;

import "./pricingdetailsnew.css";

import { getOnePercent, getSubTotal } from "../../utils/displayUtils";

import PopUp from "../PopUp/PopUp";
import React from "react";
// import { Rupees } from "../../assets";
import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";

function PricingDetailsNew({
  display,
  setDisplay,
  popper,
  setPopper,
  data,
  zIndex,
  uniqueId,
  offerType,
}) {
  const window = useResponsiveWindow();

  const getCreditOffAmount = () => {
    let subTotal = getSubTotal(data?.numdays, data?.priceNew?.addedGst);
    let onePercent = getOnePercent(subTotal);
    let isEnoughCredit = data.creditInfo.credits > onePercent;

    if (data.useCredit && data?.creditInfo?.credits > 0) {
      if (isEnoughCredit) {
        return subTotal - onePercent;
      } else {
        return subTotal - data?.creditInfo.credits;
      }
    }
  };

  const appliedCredits = () => {
    let subTotal = getSubTotal(
      data?.numdays > 30 ? 30 : data?.numdays,
      data?.priceNew?.addedGst
    );
    let onePercent = getOnePercent(subTotal);
    let isEnoughCredit = data.creditInfo.credits > onePercent;

    if (data.useCredit && data?.creditInfo?.credits > 0) {
      if (isEnoughCredit) {
        return onePercent;
      } else {
        return data?.creditInfo.credits;
      }
    }
  };

  function getAddonDetails(data) {
    if (!data) {
      return false;
    }
    const addons = data?.filter((elm, i) => elm.name !== "PACKAGE_NAME");
    const addonsList = JSON.parse(JSON.stringify(addons)).map((elm) => {
      let obj = {};
      obj["name"] = elm["name"];
      obj["cgst"] = elm["cgst"];
      obj["sgst"] = elm["sgst"];
      obj["rate"] = elm["rate"];
      obj["lineTotal"] = elm["lineTotal"];
      return obj;
    });
    const addonDetails = {
      addons: addonsList,
      addonsLineTotal: addonsList.reduce((sum, num) => sum + num.lineTotal, 0),
    };
    return addonDetails;
  }
  // console.log({ data });

  return (
    <PopUp
      display={display}
      callback={setDisplay}
      zIndex={zIndex || 9}
      uniqueId={uniqueId || "pricing-details"}
      center={window.phone}
    >
      <div
        className="price-breakdown-container-new"
        ref={setPopper}
        style={{
          ...popper.styles.popper,
        }}
        {...popper.attributes}
      >
        <div className="pricing-details-header">Price Breakdown</div>
        <div className="pricing-breakdown-details">
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">{data?.pkg?.name}</p>
            <p id="price-name-thick">{data?.priceNew?.basePrice}/Day</p>
          </div>
          {getAddonDetails(data.invPrice) &&
            getAddonDetails(data.invPrice).addons?.length > 0 && (
              <>
                <div className="price-breakdown-detail-row">
                  <p id="price-name-light" style={{ fontWeight: "600" }}>
                    Addons
                  </p>
                </div>
                {getAddonDetails(data.invPrice)?.addons?.map((elm) => (
                  <div className="price-breakdown-detail-row">
                    <p id="price-name-light">{elm.name}</p>
                    <p id="price-name-thick">₹ {elm.rate}</p>
                  </div>
                ))}
              </>
            )}

          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Total Days</p>
            <p id="price-name-thick">x {data?.numdays} Days</p>
          </div>
          {/* {getAddonDetails(data.invPrice).addons.map((elm) => (
            <div className="price-breakdown-detail-row">
              <p id="price-name-light">{elm.name}</p>
              <p id="price-name-thick">₹ {elm.lineTotal}</p>
            </div>
          ))}
          {Object.keys(data?.priceNew?.addons || {}).map((key, idx) => {
            return (
              <div className="price-breakdown-detail-row">
                <p id="price-name-light">{key}</p>
                <p id="price-name-thick">{data?.priceNew?.addons[key]}/Day</p>
              </div>
            );
          })} */}
        </div>
        {/* <div className="pricing-no-of-days">
          <div className="price-breakdown-detail-row">
            <p id="price-name-light"></p>
            <p id="price-name-thick">{data?.priceNew?.withAddons}/Day</p>
          </div>
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Total Days</p>
            <p id="price-name-thick">x {data?.numdays} Days</p>
          </div>  
        </div> */}
        <div className="pricing-coupon-gst">
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Total </p>
            <p id="price-name-thick">
              ₹{getSubTotal(data?.numdays, data?.priceNew?.withAddons)}
            </p>
          </div>
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Delivery Charge</p>
            <p id="price-name-thick">
              ₹{data?.delivery?.charge ? data?.delivery?.charge : 0}
            </p>
          </div>
          <div className="price-breakdown-detail-row">
            <p id="price-name-light">Sub Total </p>
            <p id="price-name-thick">
              ₹
              {getSubTotal(
                data?.numdays,
                data?.priceNew?.withAddons,
                data.delivery?.charge ? data?.delivery?.charge : 0
              )}
            </p>
          </div>
          <div
            className="price-breakdown-detail-row"
            style={{ borderTop: "1px solid #464646" }}
          >
            <p id="price-name-light">GST </p>
            {/* {console.log(data.delivery.gst)} */}
            <p id="price-name-thick">
              + ₹
              {getSubTotal(data?.numdays, data?.priceNew?.gst) +
                (data?.delivery?.gst ? data?.delivery?.gst : 0)}
            </p>
          </div>
          <div
            className="price-breakdown-detail-row"
            style={{ color: "#209032" }}
          >
            <p id="price-name-light">{offerType || "Coupon Discount"} </p>
            <p id="price-name-thick">
              {/* - ₹{getSubTotal(data?.numdays, data?.priceNew?.discountAmount)} */}
              - ₹{data?.priceNew?.discountAmount}
            </p>
          </div>

          {data?.useCredit && data?.creditInfo?.credits > 0 && (
            <div className="price-breakdown-detail-row">
              <p id="price-name-light">Credits </p>
              <p id="price-name-thick">- ₹{appliedCredits()}</p>
            </div>
          )}
        </div>
        <div className="pricing-grand-total">
          <div
            className="price-breakdown-detail-row-grand-total"
            style={{ alignItems: "center" }}
          >
            <p id="price-name-light">Grand Total</p>
            {data?.useCredit && data?.creditInfo?.credits > 0 ? (
              <p id="price-grand-total">₹ {getCreditOffAmount()}</p>
            ) : (
              <p id="price-grand-total">
                ₹
                {getSubTotal(
                  data?.numdays,
                  data?.priceNew?.addedGst,
                  (data?.delivery?.charge ? data?.delivery?.charge : 0) +
                    (data.delivery?.gst ? data?.delivery?.gst : 0)
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </PopUp>
  );
}

export default PricingDetailsNew;






// import "./pricingdetailsnew.css";

// import { getOnePercent, getSubTotal } from "../../utils/displayUtils";

// import PopUp from "../PopUp/PopUp";
// import React from "react";
// // import { Rupees } from "../../assets";
// import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";

// function PricingDetailsNew({
//   display,
//   setDisplay,
//   popper,
//   setPopper,
//   data,
//   zIndex,
//   uniqueId,
//   offerType,
// }) {
//   const window = useResponsiveWindow();

//   const getCreditOffAmount = () => {
//     let subTotal = getSubTotal(data?.numdays, data?.priceNew?.addedGst);
//     let onePercent = getOnePercent(subTotal);
//     let isEnoughCredit = data.creditInfo.credits > onePercent;

//     if (data.useCredit && data?.creditInfo?.credits > 0) {
//       if (isEnoughCredit) {
//         return subTotal - onePercent;
//       } else {
//         return subTotal - data?.creditInfo.credits;
//       }
//     }
//   };

//   const appliedCredits = () => {
//     let subTotal = getSubTotal(
//       data?.numdays > 30 ? 30 : data?.numdays,
//       data?.priceNew?.addedGst
//     );
//     let onePercent = getOnePercent(subTotal);
//     let isEnoughCredit = data.creditInfo.credits > onePercent;

//     if (data.useCredit && data?.creditInfo?.credits > 0) {
//       if (isEnoughCredit) {
//         return onePercent;
//       } else {
//         return data?.creditInfo.credits;
//       }
//     }
//   };

//   function getAddonDetails(data) {
//     if (!data) {
//       return false;
//     }
//     const addons = data?.filter((elm, i) => elm.name !== "PACKAGE_NAME");
//     const addonsList = JSON.parse(JSON.stringify(addons)).map((elm) => {
//       let obj = {};
//       obj["name"] = elm["name"];
//       obj["cgst"] = elm["cgst"];
//       obj["sgst"] = elm["sgst"];
//       obj["rate"] = elm["rate"];
//       obj["lineTotal"] = elm["lineTotal"];
//       return obj;
//     });
//     const addonDetails = {
//       addons: addonsList,
//       addonsLineTotal: addonsList.reduce((sum, num) => sum + num.lineTotal, 0),
//     };
//     return addonDetails;
//   }
//   // console.log({ data });

//   return (
//     <PopUp
//       display={display}
//       callback={setDisplay}
//       zIndex={zIndex || 9}
//       uniqueId={uniqueId || "pricing-details"}
//       center={window.phone}
//     >
//       <div
//         className="price-breakdown-container-new"
//         ref={setPopper}
//         style={{
//           ...popper.styles.popper,
//         }}
//         {...popper.attributes}
//       >
//         <div className="pricing-details-header">Price Breakdown</div>
//         <div className="pricing-breakdown-details">
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">{data?.pkg?.name}</p>
//             <p id="price-name-thick">{data?.priceNew?.basePrice}/Day</p>
//           </div>
//           {getAddonDetails(data.invPrice) &&
//             getAddonDetails(data.invPrice).addons?.length > 0 && (
//               <>
//                 <div className="price-breakdown-detail-row">
//                   <p id="price-name-light" style={{ fontWeight: "600" }}>
//                     Addons
//                   </p>
//                 </div>
//                 {getAddonDetails(data.invPrice)?.addons?.map((elm) => (
//                   <div className="price-breakdown-detail-row">
//                     <p id="price-name-light">{elm.name}</p>
//                     <p id="price-name-thick">₹ {elm.rate}</p>
//                   </div>
//                 ))}
//               </>
//             )}

//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Total Days</p>
//             <p id="price-name-thick">x {data?.numdays} Days</p>
//           </div>
//           {/* {getAddonDetails(data.invPrice).addons.map((elm) => (
//             <div className="price-breakdown-detail-row">
//               <p id="price-name-light">{elm.name}</p>
//               <p id="price-name-thick">₹ {elm.lineTotal}</p>
//             </div>
//           ))}
//           {Object.keys(data?.priceNew?.addons || {}).map((key, idx) => {
//             return (
//               <div className="price-breakdown-detail-row">
//                 <p id="price-name-light">{key}</p>
//                 <p id="price-name-thick">{data?.priceNew?.addons[key]}/Day</p>
//               </div>
//             );
//           })} */}
//         </div>
//         {/* <div className="pricing-no-of-days">
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light"></p>
//             <p id="price-name-thick">{data?.priceNew?.withAddons}/Day</p>
//           </div>
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Total Days</p>
//             <p id="price-name-thick">x {data?.numdays} Days</p>
//           </div>  
//         </div> */}
//         <div className="pricing-coupon-gst">
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Total </p>
//             <p id="price-name-thick">
//               ₹{getSubTotal(data?.numdays, data?.priceNew?.withAddons)}
//             </p>
//           </div>
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Delivery Charge</p>
//             <p id="price-name-thick">
//               ₹{data?.delivery?.charge ? data?.delivery?.charge : 0}
//             </p>
//           </div>
//           <div className="price-breakdown-detail-row">
//             <p id="price-name-light">Sub Total </p>
//             <p id="price-name-thick">
//               ₹
//               {getSubTotal(
//                 data?.numdays,
//                 data?.priceNew?.withAddons,
//                 data.delivery?.charge ? data?.delivery?.charge : 0
//               )}
//             </p>
//           </div>
//           <div
//             className="price-breakdown-detail-row"
//             style={{ borderTop: "1px solid #464646" }}
//           >
//             <p id="price-name-light">GST </p>
//             {/* {console.log(data.delivery.gst)} */}
//             <p id="price-name-thick">
//               + ₹
//               {getSubTotal(data?.numdays, data?.priceNew?.gst) +
//                 (data?.delivery?.gst ? data?.delivery?.gst : 0)}
//             </p>
//           </div>
//           <div
//             className="price-breakdown-detail-row"
//             style={{ color: "#209032" }}
//           >
//             <p id="price-name-light">{offerType || "Coupon Discount"} </p>
//             <p id="price-name-thick">
//               {/* - ₹{getSubTotal(data?.numdays, data?.priceNew?.discountAmount)} */}
//               - ₹{data?.priceNew?.discountAmount}
//             </p>
//           </div>

//           {data?.useCredit && data?.creditInfo?.credits > 0 && (
//             <div className="price-breakdown-detail-row">
//               <p id="price-name-light">Credits </p>
//               <p id="price-name-thick">- ₹{appliedCredits()}</p>
//             </div>
//           )}
//         </div>
//         <div className="pricing-grand-total">
//           <div
//             className="price-breakdown-detail-row-grand-total"
//             style={{ alignItems: "center" }}
//           >
//             <p id="price-name-light">Grand Total</p>
//             {data?.useCredit && data?.creditInfo?.credits > 0 ? (
//               <p id="price-grand-total">₹ {getCreditOffAmount()}</p>
//             ) : (
//               <p id="price-grand-total">
//                 ₹
//                 {getSubTotal(
//                   data?.numdays,
//                   data?.priceNew?.addedGst,
//                   (data?.delivery?.charge ? data?.delivery?.charge : 0) +
//                     (data.delivery?.gst ? data?.delivery?.gst : 0)
//                 )}
//               </p>
//             )}
//           </div>
//         </div>
//       </div>
//     </PopUp>
//   );
// }

// export default PricingDetailsNew;
