import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOADING,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { initial } from "lodash";

axios.defaults.withCredentials = true;

export const postOfferBannerThunk = createAsyncThunk(
  "post/admin/admin_frontend/bento/postOfferBanner",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/bento/postOfferBanner`,
        data
      );
      console.log("Response data:", res.data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllBentoGridThunk = createAsyncThunk(
  "post/admin/admin_frontend/bento/getBentoGridData",
  async () => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/bento/getBentoGridData`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteBentoOfferThunk = createAsyncThunk(
  "post/admin/admin_frontend/bento/deleteBentoGridOffer",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/bento/deleteBentoGridOffer`,
        { bannerId: id }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const editBentoOfferThunk = createAsyncThunk(
  "post/admin/admin_frontend/bento/editBentoGridOffer",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/bento/editBentoGridOffer`,
        data
      );
      console.log(res);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const postReelThunk = createAsyncThunk(
  "post/admin/admin_frontend/bento/postReel",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/bento/postReel`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const editReelThunk = createAsyncThunk(
  "post/admin/admin_frontend/bento/editReel",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/bento/editReel`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteReelThunk = createAsyncThunk(
  "post/admin/admin_frontend/bento/deleteReel",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/bento/deleteReel`,
        { reelId: id }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  isDone: false,
  updateDone: false,
  //---------
  data: {
    offerBanner: [],
    reelUrls: [],
  },
  status: {
    postOfferBannerThunk: IDLE,
    getAllBentoGridThunk: IDLE,
    deleteBentoOfferThunk: IDLE,
    editBentoOfferThunk: IDLE,
    postReelThunk: IDLE,
    editReelThunk: IDLE,
    deleteReelThunk: IDLE,
  },
};

const adminFrontendBentoGridSlice = createSlice({
  name: "adminFrontendBentoGrid",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    showError: (state, action) => {
      state.isError = true;
      state.errorData = {
        message: action.payload.message,
        type: action.payload.type,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postOfferBannerThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postOfferBannerThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.postOfferBannerThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(postOfferBannerThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.postOfferBannerThunk = ERROR;
      })

      //getAllBentoGrid Data

      .addCase(getAllBentoGridThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllBentoGridThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.offerBanner = payload.data;
            state.status.getAllBentoGridThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllBentoGridThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllBentoGridThunk = ERROR;
      })

      //delete bentogrid offer
      .addCase(deleteBentoOfferThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteBentoOfferThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deleteBentoOfferThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteBentoOfferThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deleteBentoOfferThunk = ERROR;
      })

      //edit bento offer image

      .addCase(editBentoOfferThunk.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(editBentoOfferThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.editBentoOfferThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })

      .addCase(editBentoOfferThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.editBentoOfferThunk = ERROR;
      })
      .addCase(postReelThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postReelThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.postReelThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(postReelThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.postOfferBannerThunk = ERROR;
      })
      .addCase(editReelThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editReelThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.editReelThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(editReelThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.editReelThunk = ERROR;
      })
      .addCase(deleteReelThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteReelThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deleteReelThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteReelThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deleteReelThunk = ERROR;
      });
  },
});

export default adminFrontendBentoGridSlice.reducer;
export const { clearErrorSlice, showError } =
  adminFrontendBentoGridSlice.actions;
