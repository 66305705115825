import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "./PeopleSaid.css";
import "swiper/css/navigation";

import {
  Aadil,
  Ammy,
  FiveStar,
  FourStar,
  GoogleLogo,
  Quotation,
  Renuka,
  Star,
  UnfilledStar,
} from "../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";

import React from "react";
import { auto } from "@popperjs/core";

// import { Autoplay, Navigation, Pagination } from "swiper";

const PeopleSaid = () => {
  SwiperCore.use([Navigation]);
  return (
    <>
      <div className="peopleContainer">
        <div className="peopleContent">
          <div className="headerPart">
            <h1 className="peopleHeading-grey">
              Loved By more than{" "}
              <span className="peopleHeading-white">50K Customers!</span>
            </h1>
            <p className="peopleDetails">
              Overall Rating{" "}
              <span className="peopleDetails-rating-count">4.9</span>
              <span className="peopleDetails-rating-svg">
                <img
                  src={Star}
                  alt="star"
                  className="peopleDetails-start-logo"
                />
              </span>{" "}
              based on <span className="peopleDetails-rating-count">457</span>{" "}
              reviews
            </p>
          </div>

          <div className="wrapper">
            <Swiper
              effect={"coverflow"}
              spaceBetween={20}
              slidesPerView={3}
              centeredSlides={true}
              grabCursor={true}
              loop={true}
              loopFillGroupWithBlank={false}
              pagination={{
                clickable: true,
              }}
              key={window.screen.width}
              modules={[Navigation, Pagination, Autoplay]}
              navigation={{
                spaceBetween: 20,
              }}
              // effect={'coverflow'}
              // grabCursor={true}
              // centeredSlides={true}
              // loop={true}
              // slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              // pagination={{ el: '.swiper-pagination', clickable: true }}
              // navigation={{
              //   nextEl: '.swiper-button-next',
              //   prevEl: '.swiper-button-prev',
              //   clickable: true,
              // }}
              // modules={[EffectCoverflow, Pagination, Navigation]}
              // className="swiper_container"
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                },
                // when window width is >= 577px
                577: {
                  // width: 390,
                  spaceBetween: 25,
                  slidesPerView: 1,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide className="testimonials-slider">
                {({ isActive }) => (
                  <div className="content-wrapper">
                    <div className="peopleCard" id={isActive ? "activeCard":""}>
                      <img
                        src={GoogleLogo}
                        alt="google"
                        className="peopleCard-google-logo"
                        onClick={() =>
                          window.open(
                            "https://maps.app.goo.gl/fuC2QSWJzta37FbRA",
                            "_blank"
                          )
                        }
                      />
                      <div className="peopleImg">
                        <img
                          src={Aadil}
                          alt=""
                          className={
                            isActive ? "activePeopleEllipse" : "peopleEllipse"
                          }
                        />
                      </div>
                      <h1
                        className={
                          isActive ? "activePeopleName" : "peopleName ammyName"
                        }
                      >
                        Aadil Singh
                      </h1>
                      <div
                        className={
                          isActive
                            ? "activePeople-group-star"
                            : "peopleRatings-group-star"
                        }
                      >
                        <img
                          src={FourStar}
                          alt="star"
                          className="peopleDetails-star-logo"
                        />
                      </div>
                      {/* <p className="colan"> <img src={Aadil} alt="" className="peopleEllipse" /></p> */}
                      <div className="cardDesc">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sit at quis enim porta egestas in pellentesque.
                          Donec porta egestas in pellentesque. Donec arcu sit
                          aliquam sodales.
                        </p>
                      </div>
                      <span className="peapleCard-date">Feb 7, 2024</span>
                    </div>
                  </div>
                )}
              </SwiperSlide>

              <SwiperSlide className="testimonials-slider">
                {({ isActive }) => (
                  <div className="content-wrapper">
                    <div className="peopleCard" id={isActive ?"activeCard":""}>
                      <img
                        src={GoogleLogo}
                        alt="google"
                        className="peopleCard-google-logo"
                        onClick={() =>
                          window.open(
                            "https://maps.app.goo.gl/fuC2QSWJzta37FbRA",
                            "_blank"
                          )
                        }
                      />
                      <div className="peopleImg">
                        <img
                          src={Ammy}
                          alt=""
                          className={
                            isActive ? "activePeopleEllipse" : "peopleEllipse"
                          }
                        />
                      </div>
                      <h1
                        className={
                          isActive ? "activePeopleName" : "peopleName ammyName"
                        }
                      >
                        Ammy Hansley
                      </h1>
                      <div
                        className={
                          isActive
                            ? "activePeople-group-star"
                            : "peopleRatings-group-star"
                        }
                      >
                        <img
                          src={FourStar}
                          alt="star"
                          className="peopleDetails-star-logo"
                        />
                      </div>
                      {/* <p className="colan">“</p> */}
                      <div className="cardDesc">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sit at quis enim porta egestas in pellentesque.
                          Donec porta egestas in pellentesque. Donec arcu sit
                          aliquam sodales.
                        </p>
                      </div>
                      {/* <p className="peopleDesc ammyDesc">Lorem Ipsum</p> */}
                      <span className="peapleCard-date">Feb 7, 2024</span>
                    </div>
                  </div>
                )}
              </SwiperSlide>

              <SwiperSlide className="testimonials-slider">
                {({ isActive }) => (
                  <div className="content-wrapper">
                    <div
                      className="peopleCard"
                      id={isActive ? "activeCard":""}
                      onClick={() =>
                        window.open(
                          "https://maps.app.goo.gl/fuC2QSWJzta37FbRA",
                          "_blank"
                        )
                      }
                    >
                      <img
                        src={GoogleLogo}
                        alt="google"
                        className="peopleCard-google-logo"
                      />
                      <div className="peopleImg">
                        <img
                          src={Renuka}
                          alt=""
                          className={
                            isActive ? "activePeopleEllipse" : "peopleEllipse"
                          }
                        />
                      </div>
                      <h1
                        className={
                          isActive ? "activePeopleName" : "peopleName ammyName"
                        }
                      >
                        Renuka Patle
                      </h1>
                      <div
                        className={
                          isActive
                            ? "activePeople-group-star"
                            : "peopleRatings-group-star"
                        }
                      >
                        <img
                          src={FiveStar}
                          alt="star"
                          className="peopleDetails-star-logo"
                        />
                      </div>
                      <div className="cardDesc">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sit at quis enim porta egestas in pellentesque.
                          Donec porta egestas in pellentesque. Donec arcu sit
                          aliquam sodales.
                        </p>
                      </div>
                      {/* <p className="peopleDesc renukaDesc">Lorem Ipsum</p> */}
                      <span className="peapleCard-date">Feb 7, 2024</span>
                    </div>
                  </div>
                )}
              </SwiperSlide>

              {/* <div className="swiper-nav-wrapper">
                <div className="swiper-button-prev"></div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next"></div>
              </div> */}
            </Swiper>
          </div>

          {/* <div className="sliderContent">
            <Swiper
              // width={1530}
              direction="horizontal"
              slidesPerView={1}
              centeredSlides={true}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              loop={false}
              modules={[Pagination, Autoplay]}
              className="testimonialSwiper"
              // breakpoints={{
              //   500: {
              //     height: "37rem",
              //   },
              //   height: "45rem",
              // }}
            >
              <SwiperSlide className="peopleSwiper">
                <div className="cardContent">
                  <div className="content">
                    <p className="colan">“</p>
                    <p className="cardDesc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sit at quis enim porta egestas in pellentesque. Donec
                      porta egestas in pellentesque. Donec arcu sit aliquam
                      sodales.
                    </p>
                    <div className="peopleImg">
                      <img src={Aadil} alt="" className="peopleEllipse" />
                    </div>
                  </div>
                  <h1 className="peopleName">Aadil Singh</h1>
                  <p className="peopleDesc">Lorem Ipsum</p>
                </div>
              </SwiperSlide>

              <SwiperSlide className="peopleSwiper">
                <div className="cardContent">
                  <div className="content">
                    <p className="colan">“</p>
                    <p className="cardDesc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sit at quis enim porta egestas in pellentesque. Donec
                      porta egestas in pellentesque. Donec arcu sit aliquam
                      sodales.
                    </p>
                    <div className="peopleImg">
                      <img src={Ammy} alt="" className="peopleEllipse" />
                    </div>
                  </div>
                  <h1 className="peopleName ammyName">Ammy Hansley</h1>
                  <p className="peopleDesc ammyDesc">Lorem Ipsum</p>
                </div>
              </SwiperSlide>

              <SwiperSlide className="peopleSwiper">
                <div className="cardContent">
                  <div className="content">
                    <p className="colan">“</p>
                    <p className="cardDesc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sit at quis enim porta egestas in pellentesque. Donec
                      porta egestas in pellentesque. Donec arcu sit aliquam
                      sodales.
                    </p>
                    <div className="peopleImg">
                      <img src={Renuka} alt="" className="peopleEllipse" />
                    </div>
                  </div>
                  <h1 className="peopleName renukaName">Renuka Patle</h1>
                  <p className="peopleDesc renukaDesc">Lorem Ipsum</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </div>
        <div className="mobile-peopleContent">
          <div className="headerPart">
            <h1>Happy Customers</h1>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corporis
              iure, fuga esse. Lorem ipsum dolor, sit amet
            </p>
          </div>

          <div className="wrapper">
            <Swiper
              effect={"coverflow"}
              spaceBetween={0}
              slidesPerView={3}
              centeredSlides={true}
              grabCursor={true}
              loop={true}
              loopFillGroupWithBlank={false}
              pagination={{
                clickable: true,
              }}
              key={window.screen.width}
              modules={[Pagination, Autoplay]}
              // navigation={{
              //   spaceBetween: 20,
              // }}
              // effect={'coverflow'}
              // grabCursor={true}
              // centeredSlides={true}
              // loop={true}
              // slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              // pagination={{ el: '.swiper-pagination', clickable: true }}
              // navigation={{
              //   nextEl: '.swiper-button-next',
              //   prevEl: '.swiper-button-prev',
              //   clickable: true,
              // }}
              // modules={[EffectCoverflow, Pagination, Navigation]}
              // className="swiper_container"
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                },
                // when window width is >= 577px
                577: {
                  // width: 390,
                  spaceBetween: 25,
                  slidesPerView: 1,
                },
              }}
            >
              <SwiperSlide className="testimonials-slider">
                {({ isActive }) => (
                  <div className="content-wrapper">
                    <div className="desc-part">
                      <img src={Quotation} />
                      <h2>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sit at quis enim porta egestas in pellentesque. Donec
                        porta egestas in pellentesque. Donec arcu sit aliquam
                        sodales.
                      </h2>
                      <div className="logo">
                        <img src={Aadil} />
                      </div>
                    </div>
                    <div className="people-detail">
                      <h2>Aadil Singh</h2>
                      <h2 style={{color:"#E7E7E77d"}}>Lorem Ipsum</h2>
                    </div>
                  </div>
                )}
              </SwiperSlide>
              <SwiperSlide className="testimonials-slider">
                {({ isActive }) => (
                  <div className="content-wrapper">
                    <div className="desc-part">
                      <img src={Quotation} />
                      <h2>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sit at quis enim porta egestas in pellentesque. Donec
                        porta egestas in pellentesque. Donec arcu sit aliquam
                        sodales.
                      </h2>
                      <div className="logo">
                        <img src={Ammy} />
                      </div>
                    </div>
                    <div className="people-detail">
                      <h2>Ammy Hansley</h2>
                      <h2 style={{color:"#E7E7E77d"}}>Lorem Ipsum</h2>
                    </div>
                  </div>
                )}
              </SwiperSlide>
              <SwiperSlide className="testimonials-slider">
                {({ isActive }) => (
                  <div className="content-wrapper">
                    <div className="desc-part">
                      <img src={Quotation} />
                      <h2>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sit at quis enim porta egestas in pellentesque. Donec
                        porta egestas in pellentesque. Donec arcu sit aliquam
                        sodales.
                      </h2>
                      <div className="logo">
                        <img src={Renuka} />
                      </div>
                    </div>
                    <div className="people-detail">
                      <h2>Renuka Patel</h2>
                      <h2 style={{color:"#E7E7E77d"}}>Lorem Ipsum</h2>
                    </div>
                  </div>
                )}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeopleSaid;
