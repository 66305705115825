import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOADING,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;

export const createOrderThunk = createAsyncThunk(
  "/post/user/payment/create_payment",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/create_payment`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const verifyOrderThunk = createAsyncThunk(
  "/post/user/payment/verify_payment",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/payment/verify_payment`, {
        response: data,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPriceDataByOrderThunk = createAsyncThunk(
  "/post/user/payment/get_order_price_data",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/get_order_price_data`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// export const verifySubscriptionThunk = createAsyncThunk(
//   "/post/user/payment/verify_subscription",
//   async (data) => {
//     try {
//       const res = await axios.post(
//         `${BASE_URL}/user/payment/verify_subscription`,
//         {
//           response: data,
//         }
//       );
//       return res.data;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );

export const createSubscriptionThunk = createAsyncThunk(
  "/post/user/payment/create_subscription",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/create_subscription`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const payUsingCreditThunk = createAsyncThunk(
  "/post/user/payment/pay_using_credit",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/pay_using_credit`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchOrderForPaymentThunk = createAsyncThunk(
  "/post/user/payment/get_order_details",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/get_order_details`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createPaymentV1Thunk = createAsyncThunk(
  "/post/user/payment/create_payment_v1",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/create_payment_v1`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const verifyOrderV1Thunk = createAsyncThunk(
  "/post/user/payment/verify_payment_v1",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/verify_payment_v1`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchRazorpayPaymentThunk = createAsyncThunk(
  "/post/user/payment/fetch_payment_details",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/fetch_payment_details`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchRazorpayPaymentThunkAdmin = createAsyncThunk(
  "/post/admin/payments/fetch_payment_details",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/payments/fetch_payment_details`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const payUsingCreditThunkV1 = createAsyncThunk(
  "/post/user/payment/credit_payment_v1",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/payment/credit_payment_v1`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  paymentInProgress: false,
  updateDone: false,
  isError: false,
  paidPaymentDetails: {},
  data: { order: {}, orderNew: {} },
  status: {
    createOrderThunk: IDLE,
    verifyOrderThunk: IDLE,
    verifySubscriptionThunk: IDLE,
    createSubscriptionThunk: IDLE,
    payUsingCreditThunk: IDLE,
    fetchOrderForPaymentThunk: IDLE,
    createPaymentV1Thunk: IDLE,
    verifyOrderV1Thunk: IDLE,
    payUsingCreditThunkV1: IDLE,
    getPriceDataByOrderThunk: IDLE,
  },
};

const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // create order ===============================================
      .addCase(createOrderThunk.pending, (state, action) => {
        state.status.createOrderThunk = LOADING;
        state.loading = true;
      })
      .addCase(createOrderThunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.createOrder = payload.data;
            state.status.createOrderThunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createOrderThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.createOrderThunk = ERROR;
      })
      // verify order ===============================================
      .addCase(verifyOrderThunk.pending, (state, action) => {
        state.status.verifyOrderThunk = LOADING;
        state.loading = true;
      })
      .addCase(verifyOrderThunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.verifyOrder = payload.data;
            state.status.verifyOrderThunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(verifyOrderThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.verifyOrderThunk = ERROR;
      })
      //  create subscription ===============================================
      .addCase(createSubscriptionThunk.pending, (state, action) => {
        state.status.createSubscriptionThunk = LOADING;
        state.loading = true;
      })
      .addCase(createSubscriptionThunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.verifyOrder = payload.data;
            state.status.createSubscriptionThunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createSubscriptionThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.createSubscriptionThunk = ERROR;
      })
      // verify subs ===============================================
      // .addCase(verifySubscriptionThunk.pending, (state, action) => {
      //   state.status.verifySubscriptionThunk = LOADING;
      //   state.loading = true;
      // })
      // .addCase(verifySubscriptionThunk.fulfilled, (state, { payload }) => {
      //   state.updateDone = !state.updateDone;
      //   switch (payload.type) {
      //     case SUCCESS:
      //       // state.data.verifyOrder = payload.data;
      //       state.status.verifySubscriptionThunk = FULFILLED;
      //       state.loading = false;
      //       break;
      //     default:
      //       state.loading = false;
      //       state.isError = true;
      //       state.errorData = {
      //         message: payload.message,
      //         type: payload.type,
      //         errors: payload.errors,
      //       };
      //   }
      // })
      // .addCase(verifySubscriptionThunk.rejected, (state, action) => {
      //   state.loading = false;
      //   state.errorData = action.payload.error;
      //   state.status.verifySubscriptionThunk = ERROR;
      // })
      // pay using credit  ===============================================
      .addCase(payUsingCreditThunk.pending, (state, action) => {
        state.status.payUsingCreditThunk = LOADING;
        state.loading = true;
      })
      .addCase(payUsingCreditThunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.verifyOrder = payload.data;
            state.status.payUsingCreditThunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(payUsingCreditThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.payUsingCreditThunk = ERROR;
      })
      // fetch order for payment  ===============================================
      .addCase(fetchOrderForPaymentThunk.pending, (state, action) => {
        state.status.fetchOrderForPaymentThunk = LOADING;
        state.loading = true;
      })
      .addCase(fetchOrderForPaymentThunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.verifyOrder = payload.data;
            state.data.order = payload.data;
            state.status.fetchOrderForPaymentThunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(fetchOrderForPaymentThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.fetchOrderForPaymentThunk = ERROR;
      })
      // new add cases ======================================================================
      //
      //
      //
      //
      // create order  ===============================================
      .addCase(createPaymentV1Thunk.pending, (state, action) => {
        state.status.createPaymentV1Thunk = LOADING;
        state.paymentInProgress = true;
        state.loading = true;
      })
      .addCase(createPaymentV1Thunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.verifyOrder = payload.data;
            state.data.orderNew = payload.data;
            state.status.createPaymentV1Thunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createPaymentV1Thunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.createPaymentV1Thunk = ERROR;
      })
      // verify order  ===============================================
      .addCase(verifyOrderV1Thunk.pending, (state, action) => {
        state.status.verifyOrderV1Thunk = LOADING;
        state.loading = true;
      })
      .addCase(verifyOrderV1Thunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.verifyOrder = payload.data;
            // state.data.orderNew = payload.data;
            state.status.verifyOrderV1Thunk = FULFILLED;
            state.loading = false;
            state.paymentInProgress = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.paymentInProgress = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(verifyOrderV1Thunk.rejected, (state, action) => {
        state.loading = false;
        state.paymentInProgress = false;
        state.errorData = action.payload.error;
        state.status.verifyOrderV1Thunk = ERROR;
      })
      // verify order  ===============================================
      .addCase(payUsingCreditThunkV1.pending, (state, action) => {
        state.status.payUsingCreditThunkV1 = LOADING;
        state.loading = true;
      })
      .addCase(payUsingCreditThunkV1.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            // state.data.verifyOrder = payload.data;
            // state.data.orderNew = payload.data;
            state.status.payUsingCreditThunkV1 = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(payUsingCreditThunkV1.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.payUsingCreditThunkV1 = ERROR;
      })
      //getPriceDataByOrderThunk
      .addCase(getPriceDataByOrderThunk.pending, (state, action) => {
        state.status.payUsingCreditThunkV1 = LOADING;
        state.loading = true;
      })
      .addCase(getPriceDataByOrderThunk.fulfilled, (state, { payload }) => {
        state.updateDone = !state.updateDone;
        switch (payload.type) {
          case SUCCESS:
            state.status.getPriceDataByOrderThunk = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPriceDataByOrderThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.payUsingCreditThunkV1 = ERROR;
      })
      // fetchRazorpayPaymentThunk
      .addCase(fetchRazorpayPaymentThunk.pending, (state, action) => {
        state.status.payUsingCreditThunkV1 = LOADING;
        state.loading = true;
      })
      .addCase(fetchRazorpayPaymentThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.paidPaymentDetails = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(fetchRazorpayPaymentThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
      })
      .addCase(fetchRazorpayPaymentThunkAdmin.pending, (state, action) => {
        state.status.payUsingCreditThunkV1 = LOADING;
        state.loading = true;
      })
      .addCase(fetchRazorpayPaymentThunkAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.paidPaymentDetails = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(fetchRazorpayPaymentThunkAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
      });
  },
});

export default paymentSlice.reducer;
export const { clearErrorSlice } = paymentSlice.actions;
