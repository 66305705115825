import { BASE_URL, ERROR, IDLE, SUCCESS } from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  updateDone: false,
  isError: false,
  data: {
    quotationData: {},
  },
  status: {
    getEstimateFareThunk: IDLE,
  },
};

export const getEstimateFareThunk = createAsyncThunk(
  "get_logistic_estimation/get_fare",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/emp/get_logistic_estimation/get_fare`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const logisticSlice = createSlice({
  name: "logisticSlice",
  initialState: initialState,
  reducers: {
    clearLogisticErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEstimateFareThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEstimateFareThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.quotationData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: [payload.error],
            };
        }
      })
      .addCase(getEstimateFareThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getEstimateFareThunk = ERROR;
      });
  },
});

export default logisticSlice.reducer;
export const { clearLogisticErrorSlice } = logisticSlice.actions;
