// import "./deliveryrightcontainer.css";
// import "./deliveryrightcontainernew.css";

// import React, { useEffect, useState } from "react";
// import {
//   clearErrorSlice,
//   getPersonalCreditInfoThunk,
//   getUserCartByIdSlice,
//   updateCreditStatusSlice,
// } from "../../redux/features/loginSlice/loginSlice";
// import {
//   fetchOrderForPaymentThunk,
//   getPriceDataByOrderThunk,
// } from "../../redux/features/paymentSlice/paymentSlice";
// import {
//   getDuration,
//   getOnePercent,
//   getSubTotalMonthCap,
//   priceFormatterDaily,
// } from "../../utils/displayUtils";
// import {
//   reuploadOrderDocuments,
//   setClick,
//   setIDisAgree,
// } from "../../redux/features/orderSlice/orderSlice";
// import { useDispatch, useSelector } from "react-redux";

// import Couponpopup from "../Couponpopup/couponpopup";
// import CreditConfirmPopUp from "../CreditConfirmPopUp/CreditConfirmPopUp";
// import FailureAlert from "../failureAlert/failureAlert";
// import Gstdetailspopup from "../Gstdetailspopup/gstdetailspopup";
// import PopUp from "../PopUp/PopUp";
// import { SUCCESS } from "../../constants/constants";
// import { SmallCross } from "../../assets";
// import { getAllCoupons } from "../../redux/features/homePageSlice/homePageSlice";
// import lodash from "lodash";
// import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// // import CreditConfirmPopUp from "../CreditConfirmPopUp/CreditConfirmPopUp";
// // import { CreditIcon } from "../../assets/index";

// // import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";
// const Deliveryrightcontainer = (props) => {
//   const [isGstdetails, setIsGstdetails] = useState(false);
//   const [priceDetails, setPriceDetails] = useState({});
//   const [isCoupon, setIsCoupon] = useState(false);
//   const [creditInfo, setCreditInfo] = useState({});
//   const [fetchedOrder, setFetchedOrder] = useState({});
//   const [applicableCredit, setApplicableCredit] = useState(0);
//   const [isCreditPopUpRequired, setIsCreditPopUpRequired] = useState(false);
//   // const [currentCred, setCurrentCredit] = useState();

//   // console.log("current user credit state : ", !!credit);
//   // Async thunk
//   const dispatch = useDispatch();
//   // const navigate = useNavigate();

//   const credit = useSelector(
//     (state) => state.rootReducer.userInfo.currentUserCart.isCredit
//   );
//   console.log(credit);
//   const isLoading = useSelector((state) => state.rootReducer.userInfo.loading);
//   const updateDone = useSelector(
//     (state) => state.rootReducer.userInfo.updateDone
//   );

//   const getUserCartById = useSelector(
//     (state) => state.rootReducer.userInfo.currentUserCart
//   );

//   const customAlert = useSelector(
//     (state) => state.rootReducer.userInfo.isError
//   );
//   const setCustomAlert = () => {
//     dispatch(clearErrorSlice());
//   };
//   const error = useSelector((state) => state.rootReducer.userInfo.errorData);

//   const selfieImage = useSelector(
//     (state) => state.rootReducer.orderInfo.selfieURL
//   );
//   const verifyCartCreditInfo = useSelector(
//     (state) => state.rootReducer.userInfo.data.cartCreditInfo
//   );

//   const agreePermission = useSelector(
//     (state) => state.rootReducer.orderInfo.agree
//   );
//   const location = useLocation();

//   useEffect(() => {
//     if (props.orderId) {
//       dispatch(fetchOrderForPaymentThunk({ orderId: props.orderId }))
//         .unwrap()
//         .then((data) => {
//           if (data.type === SUCCESS) {
//             setFetchedOrder(data.data);
//           }
//         });
//     }
//     dispatch(getPersonalCreditInfoThunk())
//       .unwrap()
//       .then((data) => {
//         if (data.type === SUCCESS) {
//           setCreditInfo(data.data);
//         }
//       });
//   }, [props.orderId, updateDone]);

//   useEffect(() => {
//     // dispatch(getAllCoupons());

//     if (location.pathname.split("/")[1] !== "order_payment") {
//       dispatch(getUserCartByIdSlice(props.cartid));
//     }
//   }, [updateDone]);

//   useEffect(() => {
//     if (fetchedOrder?._id) {
//       dispatch(getPriceDataByOrderThunk({ orderId: fetchedOrder?._id }))
//         .unwrap()
//         .then((data) => {
//           if (data.type === SUCCESS) {
//             setPriceDetails(data.data);
//           }
//         });
//     }
//   }, [fetchedOrder?._id]);

//   useEffect(() => {}, [agreePermission]);
//   // for updating isCredit status in cart
//   const creditBtnClickHandler = () => {
//     dispatch(
//       updateCreditStatusSlice({
//         cartId: props?.cartid,
//         status: !!!credit,
//       })
//     );
//   };

//   const proceedBtnHandler = () => {
//     dispatch(setClick());
//     dispatch(setIDisAgree());
//     // const data = {
//     //   selfieProof: props.imageURLFromAgreement,
//     //   orderId: fetchedOrder?._id,
//     // };
//     // console.log(data);
//     // console.log(props.imageURLFromAgreement);
//     // console.log(fetchedOrder?._id);
//     // dispatch(reuploadOrderDocuments(data));
//   };

//   const monthNames = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];

//   const Details = {
//     packageName: props.isOrder
//       ? priceDetails?.orderDetails?.packageInfo?.packageName
//       : getUserCartById.packageInfo?.name,
//     startDate: props.isOrder
//       ? `${new Date(priceDetails?.orderDetails?.startDate).getDate()}, ${
//           monthNames[new Date(priceDetails?.orderDetails?.startDate).getMonth()]
//         } ${new Date(priceDetails?.orderDetails?.startDate).getFullYear()}`
//       : `${new Date(getUserCartById.startDate).getDate()}, ${
//           monthNames[new Date(getUserCartById.startDate).getMonth()]
//         } ${new Date(getUserCartById.startDate).getFullYear()}`,
//     endDate: props.isOrder
//       ? `${new Date(priceDetails?.orderDetails?.endDate).getDate()}, ${
//           monthNames[new Date(priceDetails?.orderDetails?.endDate).getMonth()]
//         } ${new Date(priceDetails?.orderDetails?.endDate).getFullYear()}`
//       : `${new Date(getUserCartById.endDate).getDate()}, ${
//           monthNames[new Date(getUserCartById.endDate).getMonth()]
//         } ${new Date(getUserCartById.endDate).getFullYear()}`,
//     tenure: props.isOrder
//       ? priceDetails?.numDays
//       : getUserCartById?.price?.numdays,
//     addons: props.isOrder
//       ? priceDetails?.price?.addons
//       : getUserCartById.paidAddons,
//     price: props.isOrder
//       ? priceDetails?.price
//       : getUserCartById?.price?.priceNew,
//   };

//   // console.log(Details);
//   const subTotal =
//     getSubTotalMonthCap(Details.tenure, Details.price?.addedGst) +
//     Details.price?.delivery?.lineTotal;

//   const beforeCreditTotal = getSubTotalMonthCap(
//     Details.tenure,
//     Details.price?.addedGst
//   );

//   let onePercent = getOnePercent(beforeCreditTotal);

//   useEffect(() => {
//     onePercent = getOnePercent(beforeCreditTotal);
//     let appliedCreditValue =
//       creditInfo?.credits < onePercent ? creditInfo?.credits : onePercent;
//     if (credit && !props.orderId) {
//       credit ? setApplicableCredit(appliedCreditValue) : setApplicableCredit(0);
//     }
//     if (props.orderId) {
//       props.order?.useCredit
//         ? setApplicableCredit(appliedCreditValue)
//         : setApplicableCredit(0);
//     }
//   }, [credit, props.orderId, beforeCreditTotal]);

//   return (
//     <div className="right-base-container-new">
//       {customAlert && (
//         <FailureAlert
//           setAlert={setCustomAlert}
//           message={error.message}
//           type={error.type}
//           errors={error.errors}
//         />
//       )}
//       <div className="breakdown-wrapper-container">
//         <div className="right-container-navbar">
//           <p>Product Details</p>
//           <img src={SmallCross} onClick={() => props.onclick()} alt="details" />
//         </div>
//         <div className="breakdown-header">
//           <p className="header-package-name">{Details.packageName}</p>
//           <div className="tablet-tenure">
//             <div className="tenure-right-cont">
//               <p id="tenure-first-p">Deliver By</p>
//               <p id="tenure-last-p">{Details.startDate}</p>
//             </div>
//             <div className="tenure-left-cont">
//               <p id="tenure-first-p">Tenure</p>
//               <p id="tenure-last-p">{getDuration(Details.tenure)}</p>
//             </div>
//           </div>
//           <p className="header-addon-name">
//             Addons :{" "}
//             {Details.addons?.map((data) => (
//               <span className="checkout-addons-text">{data?.addonName}, </span>
//             ))}
//           </p>
//           <div className="header-date-cont">
//             <p>{Details.startDate}</p>
//             <p> - </p>
//             <p>{Details.endDate}</p>
//           </div>
//         </div>
//         <div className="breakdown-container">
//           {console.log(Details)}
//           <div className="breakdown-row">
//             <p>{Details.tenure < 30 ? "Daily Rent" : "Monthly Rent"}</p>
//             <p>
//               {" "}
//               {/* {priceFormatterDaily(Details.tenure, Details.price?.withAddons)} */}
//               {/* commrnting for now */}
//               {priceFormatterDaily(Details.tenure, Details.price?.basePrice)}
//             </p>
//           </div>
//           <div className="breakdown-row" id="tenure-pc">
//             <p>Tenure</p>
//             <p>{getDuration(Details.tenure)}</p>
//           </div>
//           <hr />
//           <div className="breakdown-row">
//             <p>Delivery charges</p>
//             <p>₹{Details.price?.delivery?.rate}</p>
//           </div>
//           <div className="breakdown-row">
//             <p>Sub Total</p>
//             <p>
//               ₹
//               {getSubTotalMonthCap(Details.tenure, Details.price?.withAddons) +
//                 Details.price?.delivery?.rate}
//             </p>
//           </div>
//           <div className="breakdown-row">
//             <p
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             >
//               Coupon
//             </p>
//             <p>
//               -₹
//               {/* {
//                 lodash.round(
//                   getSubTotalMonthCap(
//                     Details.tenure,
//                     Details.price?.discountedPrice
//                   ),
//                   0
//                 )
//               } */}
//               {Details.price?.discountAmount}
//             </p>
//           </div>
//           <div className="breakdown-row">
//             <p>GST</p>
//             <p>
//               ₹
//               {getSubTotalMonthCap(Details.tenure, Details.price?.gst) +
//                 (Details.price?.delivery?.CGST +
//                   Details.price?.delivery?.SGST +
//                   Details.price?.delivery?.IGST)}
//             </p>
//           </div>
//           <div className="breakdown-row">
//             Credit
//             <p>
//               {props?.orderId && props.order?.useCredit && (
//                 <>
//                   -₹
//                   {creditInfo?.credits}
//                 </>
//               )}
//               {credit && !props.orderId && (
//                 <>
//                   -₹
//                   {creditInfo?.credits}
//                 </>
//               )}
//               {!props?.order?.useCredit && !credit && <p>-₹0</p>}
//             </p>
//           </div>
//           <hr />
//           <div className="breakdown-row">
//             <p>Total Payment</p>

//             {/* {!props.orderId && (
//               <>
//                 {credit ? (
//                   <p>₹{subTotal - creditInfo?.credits}</p>
//                 ) : (
//                   <p>₹{subTotal}</p>
//                 )}
//               </>
//             )}

//             {!!props?.orderId && (
//               <>
//                 {props?.order?.useCredit ? (
//                   <p>₹{subTotal - creditInfo?.credits}</p>
//                 ) : (
//                   <p>₹{subTotal}</p>
//                 )}
//               </>
//             )} */}

//             {/* Commenting for now */}
//             {Details?.price?.addedGst}
//           </div>
//         </div>

//         {/* New UI Change */}
//         {!props.orderId && (
//           <>
//             <div className="delivery-additional-options">
//               <div className="delivery-additional-options-btn-container">
//                 <button
//                   style={{ display: props.isOrder ? "none" : "" }}
//                   onClick={() => setIsGstdetails(true)}
//                 >
//                   Claim GST +
//                 </button>
//                 <button
//                   style={{ display: props.isOrder ? "none" : "" }}
//                   onClick={() => setIsCoupon(true)}
//                 >
//                   Apply Coupon
//                 </button>
//               </div>
//               {creditInfo?.credits > 0 && (
//                 <div className="delivery-additional-options-use-credit">
//                   <p className="delivery-additional-options-use-credit-text">
//                     <input
//                       type="checkbox"
//                       name=""
//                       id="credit-btn-cb"
//                       checked={credit}
//                       onInput={() => creditBtnClickHandler()}
//                       disabled={isLoading}
//                     />
//                     Use Credit
//                   </p>
//                 </div>
//               )}
//             </div>
//             <div className="credit-details-container">
//               <div>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="27"
//                   height="27"
//                   viewBox="0 0 27 27"
//                   fill="none"
//                 >
//                   <path
//                     d="M26.2035 12.933L14.0602 0.789754C13.8095 0.538802 13.5117 0.339849 13.1839 0.20432C12.8561 0.0687915 12.5047 -0.00064291 12.15 4.48574e-06H2.7C1.20825 4.48574e-06 0 1.20825 0 2.7V12.15C0 12.8993 0.30375 13.5743 0.7965 14.0603L12.9465 26.2102C13.4325 26.6962 14.1075 27 14.85 27C15.5992 27 16.2742 26.6962 16.7602 26.2102L26.2102 16.7603C26.6962 16.2675 27 15.5925 27 14.85C27 14.1008 26.6962 13.4258 26.2035 12.933ZM4.725 6.75C3.6045 6.75 2.7 5.8455 2.7 4.725C2.7 3.6045 3.6045 2.7 4.725 2.7C5.8455 2.7 6.75 3.6045 6.75 4.725C6.75 5.8455 5.8455 6.75 4.725 6.75ZM20.6145 17.9145L14.85 23.679L9.0855 17.9145C8.478 17.3003 8.1 16.4565 8.1 15.525C8.1 13.662 9.612 12.15 11.475 12.15C12.4065 12.15 13.257 12.528 13.8645 13.1423L14.85 14.1278L15.8355 13.1423C16.4497 12.528 17.2935 12.15 18.225 12.15C20.088 12.15 21.6 13.662 21.6 15.525C21.6 16.4565 21.222 17.3003 20.6145 17.9145Z"
//                     fill="url(#paint0_linear_744_1337)"
//                   />
//                   <defs>
//                     <linearGradient
//                       id="paint0_linear_744_1337"
//                       x1="0.506251"
//                       y1="0.50625"
//                       x2="20.7721"
//                       y2="22.1168"
//                       gradientUnits="userSpaceOnUse"
//                     >
//                       <stop stop-color="#19EEB5" />
//                       <stop offset="0.720714" stop-color="#1BAFC4" />
//                     </linearGradient>
//                   </defs>
//                 </svg>
//               </div>
//               <div>
//                 <div className="credit-details-container-sup-text">
//                   Credits Applicable:
//                 </div>
//                 <div className="credit-details-container-sub-text">
//                   Credits Available:
//                 </div>
//               </div>
//               <div>
//                 <div className="credit-details-container-sup-text">
//                   ₹{creditInfo?.credits}
//                 </div>
//                 <div className="credit-details-container-sub-text">
//                   ₹{creditInfo?.credits}
//                 </div>
//               </div>
//             </div>
//           </>
//         )}
//         <div className="breakdown-container-tablet">
//           <div className="breakdown-row">
//             <p>{Details.packageName}</p>
//             <p>₹{Details.price?.basePrice}/day</p>
//           </div>
//           {Object.keys(Details.price?.addons || [])?.map((elm) => {
//             return (
//               <div className="breakdown-row">
//                 <p>{elm}</p>
//                 <p>₹{Details.price?.addons[elm]}/day</p>
//               </div>
//             );
//           })}
//           <hr />
//           <div className="breakdown-row">
//             <p></p>
//             <p>₹{Details.price?.withAddons}/day</p>
//           </div>
//           <div className="breakdown-row">
//             <p>No of Days</p>
//             <p>x{Details.tenure}</p>
//           </div>
//           <hr />
//           <div className="breakdown-row">
//             <p>Delivery Charge</p>
//             <p>{Details.price?.delivery?.rate}</p>
//           </div>
//           <div className="breakdown-row">
//             <p>Sub Total</p>
//             <p>
//               {getSubTotalMonthCap(Details.tenure, Details.price?.withAddons) +
//                 Details.price?.delivery?.rate}
//             </p>
//           </div>
//           <div className="breakdown-row">
//             Coupon
//             <p>
//               {/* {console.log("Details: ", Details)} */}- ₹
//               {getSubTotalMonthCap(
//                 Details.tenure,
//                 Details.price?.discountedPrice
//               )}
//             </p>
//           </div>
//           <div className="breakdown-row">
//             GST
//             <p>
//               + ₹
//               {getSubTotalMonthCap(Details.tenure, Details.price?.gst) +
//                 (Details.price?.delivery?.CGST +
//                   Details.price?.delivery?.SGST +
//                   Details.price?.delivery?.IGST)}
//             </p>
//           </div>
//           <div className="breakdown-row">
//             Credit
//             <p>{credit ? <> -₹{creditInfo?.credits}</> : <>-₹NA</>}</p>
//           </div>
//           <hr />
//           <div className="breakdown-row">
//             <p id="breakdown-grand-total">Grand Total</p>
//             <p id="breakdown-grand-total">
//               {!props.orderId && (
//                 <>
//                   {credit ? (
//                     <p>₹{subTotal - applicableCredit}</p>
//                   ) : (
//                     <p>₹{subTotal}</p>
//                   )}
//                 </>
//               )}

//               {!!props?.orderId && (
//                 <>
//                   {props?.order?.useCredit ? (
//                     <p>₹{subTotal - applicableCredit}</p>
//                   ) : (
//                     <p>₹{subTotal}</p>
//                   )}
//                 </>
//               )}
//             </p>
//           </div>
//         </div>
//         <PopUp
//           center={true}
//           display={isCoupon}
//           callback={setIsCoupon}
//           uniqueId={"coupon-order"}
//           zIndex={13}
//         >
//           <Couponpopup
//             onclick={() => setIsCoupon(!isCoupon)}
//             packageId={getUserCartById?.packageInfo?.customId}
//           />
//         </PopUp>
//         <PopUp
//           zIndex={13}
//           center={true}
//           display={isGstdetails}
//           callback={setIsGstdetails}
//         >
//           <Gstdetailspopup
//             cartId={props.cartid}
//             onclick={() => setIsGstdetails(!isGstdetails)}
//           />
//         </PopUp>
//         {!props.isOrder && (
//           <>
//             <div className="order-proceed-btn">
//               <button
//                 id="proceed-btn"
//                 disabled={agreePermission === false}
//                 onClick={() => {
//                   credit
//                     ? proceedBtnHandler()
//                     : creditInfo?.credits > 0
//                     ? setIsCreditPopUpRequired(true)
//                     : proceedBtnHandler();
//                 }}
//               >
//                 Proceed
//               </button>
//               {/* )} */}
//               {/* {isPopup === true && (
//                 <div className="order-confirm-container">
//                   <div className="order-confirm-content">
//                     <p id="order-text">Order Accepted</p>
//                     <img
//                       src={OrderAccepted}
//                       alt="orderaccepted"
//                       style={{ marginTop: "1rem" }}
//                     />
//                     <p id="credit-text">Woohoo! 65 Credits Earned</p>
//                     <p id="order-desc">
//                       Sit back and relax! Your order has been accepted by us.
//                       You can check out your order details in{" "}
//                       <Link to="">My Orders</Link>.
//                     </p>
//                     <button type="submit">
//                       <Link
//                         to={MYORDERS}
//                         // onClick={() => {
//                         //   dispatch(setPopup());
//                         // }}
//                       >
//                         Done
//                       </Link>
//                     </button>
//                   </div>
//                 </div>
//               )} */}
//               {/* <PaymentButtonV1 orderId={props.orderId} /> */}
//               {/* <div className="coupon-gst-button-cont">
//                 <button type="submit" onClick={() => setIsGstdetails(true)}>
//                   Claim GST +
//                 </button>
//                 <button type="submit" onClick={() => setIsCoupon(true)}>
//                   Apply Coupon
//                 </button>
//               </div> */}
//               {/* <div className="checkboxes-cart-cont">
//                 <input
//                   type="checkbox"
//                   name="credit-checkbox"
//                   disabled={
//                     getNoOfDays(
//                       getUserCartById?.startDate,
//                       getUserCartById?.endDate
//                     ) >= 30 || verifyCartCreditInfo?.diff < 0
//                   }
//                 />
//                 <label for="credit-checkbox">
//                   <span className="checkmark"></span> Use Credit <br />
//                 </label>
//               </div> */}
//               {/* ================================================================================================ */}
//               {/* <p id="coupon-gst-cont-info">
//                 {verifyCartCreditInfo?.diff < 0 &&
//                   "You have insufficient credits to make complete purachase via credits"}
//               </p> */}
//               {/* ================================================================================================ */}
//               {/* <p id="coupon-gst-cont-info">
//                 {getNoOfDays(
//                   getUserCartById?.startDate,
//                   getUserCartById?.endDate
//                 ) >= 30 &&
//                   "Credit can only be used in subscriptions less than 30 days"}
//               </p> */}
//               <PopUp
//                 zIndex={13}
//                 center={true}
//                 display={isCreditPopUpRequired}
//                 callback={setIsCreditPopUpRequired}
//               >
//                 <CreditConfirmPopUp cartid={props?.cartid} />
//                 {/* <div className="use-credit-popup-container">
//                   <div className="use-credit-popup-text-container">
//                     <p className="use-credit-popup-text">
//                       Do you want to use credits ?
//                     </p>
//                   </div>

//                   <div className="use-credit-options-container">
//                     <button
//                       id="credit-confirm-popup-yes"
//                       onClick={() => {
//                         creditBtnClickHandler();
//                         setIsCreditPopUpRequired(false);
//                       }}
//                     >
//                       Yes
//                     </button>
//                     <button
//                       id="credit-confirm-popup-no"
//                       onClick={() => {
//                         proceedBtnHandler();
//                         setIsCreditPopUpRequired(false);
//                       }}
//                     >
//                       No
//                     </button>
//                   </div>
//                 </div> */}
//               </PopUp>
//             </div>
//             <div className="breakdown-note">
//               <p>Note: </p>
//               <p>
//                 You can choose to either complete agreement or the payment to
//                 quickly reserve your order. However, both need to be completed
//                 for us to dispatch your order.
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Deliveryrightcontainer;

import "./deliveryrightcontainer.css";
import "./deliveryrightcontainernew.css";

import React, { useEffect, useState } from "react";
import {
  clearErrorSlice,
  getPersonalCreditInfoThunk,
  getUserCartByIdSlice,
  updateCreditStatusSlice,
} from "../../redux/features/loginSlice/loginSlice";
import {
  fetchOrderForPaymentThunk,
  getPriceDataByOrderThunk,
} from "../../redux/features/paymentSlice/paymentSlice";
import {
  getDuration,
  getOnePercent,
  getSubTotalMonthCap,
  priceFormatterDaily,
} from "../../utils/displayUtils";
import {
  reuploadOrderDocuments,
  setClick,
  setIDisAgree,
} from "../../redux/features/orderSlice/orderSlice";
import { useDispatch, useSelector } from "react-redux";

import Couponpopup from "../Couponpopup/couponpopup";
import CreditConfirmPopUp from "../CreditConfirmPopUp/CreditConfirmPopUp";
import FailureAlert from "../failureAlert/failureAlert";
import Gstdetailspopup from "../Gstdetailspopup/gstdetailspopup";
import PopUp from "../PopUp/PopUp";
import { SUCCESS } from "../../constants/constants";
import { SmallCross } from "../../assets";
import { getAllCoupons } from "../../redux/features/homePageSlice/homePageSlice";
import lodash from "lodash";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import CreditConfirmPopUp from "../CreditConfirmPopUp/CreditConfirmPopUp";
// import { CreditIcon } from "../../assets/index";

// import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";
const Deliveryrightcontainer = (props) => {
  const [isGstdetails, setIsGstdetails] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [isCoupon, setIsCoupon] = useState(false);
  const [creditInfo, setCreditInfo] = useState({});
  const [fetchedOrder, setFetchedOrder] = useState({});
  const [applicableCredit, setApplicableCredit] = useState(0);
  const [isCreditPopUpRequired, setIsCreditPopUpRequired] = useState(false);
  // const [currentCred, setCurrentCredit] = useState();

  // console.log("current user credit state : ", !!credit);
  // Async thunk
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const credit = useSelector(
    (state) => state.rootReducer.userInfo.currentUserCart.isCredit
  );

  const isLoading = useSelector((state) => state.rootReducer.userInfo.loading);
  const updateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );

  const getUserCartById = useSelector(
    (state) => state.rootReducer.userInfo.currentUserCart
  );

  const customAlert = useSelector(
    (state) => state.rootReducer.userInfo.isError
  );
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  const error = useSelector((state) => state.rootReducer.userInfo.errorData);

  const selfieImage = useSelector(
    (state) => state.rootReducer.orderInfo.selfieURL
  );
  const verifyCartCreditInfo = useSelector(
    (state) => state.rootReducer.userInfo.data.cartCreditInfo
  );

  const agreePermission = useSelector(
    (state) => state.rootReducer.orderInfo.agree
  );
  const location = useLocation();

  useEffect(() => {
    if (props.orderId) {
      dispatch(fetchOrderForPaymentThunk({ orderId: props.orderId }))
        .unwrap()
        .then((data) => {
          if (data.type === SUCCESS) {
            setFetchedOrder(data.data);
          }
        });
    }
    dispatch(getPersonalCreditInfoThunk())
      .unwrap()
      .then((data) => {
        if (data.type === SUCCESS) {
          setCreditInfo(data.data);
        }
      });
  }, [props.orderId, updateDone]);

  useEffect(() => {
    // dispatch(getAllCoupons());

    if (location.pathname.split("/")[1] !== "order_payment") {
      dispatch(getUserCartByIdSlice(props.cartid));
    }
  }, [updateDone]);

  useEffect(() => {
    if (fetchedOrder?._id) {
      dispatch(getPriceDataByOrderThunk({ orderId: fetchedOrder?._id }))
        .unwrap()
        .then((data) => {
          if (data.type === SUCCESS) {
            setPriceDetails(data.data);
          }
        });
    }
  }, [fetchedOrder?._id]);

  useEffect(() => {}, [agreePermission]);
  // for updating isCredit status in cart
  const creditBtnClickHandler = () => {
    dispatch(
      updateCreditStatusSlice({
        cartId: props?.cartid,
        status: !!!credit,
      })
    );
  };

  const proceedBtnHandler = () => {
    dispatch(setClick());
    dispatch(setIDisAgree());
    // const data = {
    //   selfieProof: props.imageURLFromAgreement,
    //   orderId: fetchedOrder?._id,
    // };
    // console.log(data);
    // console.log(props.imageURLFromAgreement);
    // console.log(fetchedOrder?._id);
    // dispatch(reuploadOrderDocuments(data));
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const Details = {
    packageName: props.isOrder
      ? priceDetails?.orderDetails?.packageInfo?.packageName
      : getUserCartById.packageInfo?.name,
    startDate: props.isOrder
      ? `${new Date(priceDetails?.orderDetails?.startDate).getDate()}, ${
          monthNames[new Date(priceDetails?.orderDetails?.startDate).getMonth()]
        } ${new Date(priceDetails?.orderDetails?.startDate).getFullYear()}`
      : `${new Date(getUserCartById.startDate).getDate()}, ${
          monthNames[new Date(getUserCartById.startDate).getMonth()]
        } ${new Date(getUserCartById.startDate).getFullYear()}`,
    endDate: props.isOrder
      ? `${new Date(priceDetails?.orderDetails?.endDate).getDate()}, ${
          monthNames[new Date(priceDetails?.orderDetails?.endDate).getMonth()]
        } ${new Date(priceDetails?.orderDetails?.endDate).getFullYear()}`
      : `${new Date(getUserCartById.endDate).getDate()}, ${
          monthNames[new Date(getUserCartById.endDate).getMonth()]
        } ${new Date(getUserCartById.endDate).getFullYear()}`,
    tenure: props.isOrder
      ? priceDetails?.numDays
      : getUserCartById?.price?.numdays,
    addons: props.isOrder
      ? priceDetails?.price?.addons
      : getUserCartById.paidAddons,
    price: props.isOrder
      ? priceDetails?.price
      : getUserCartById?.price?.priceNew,
  };

  // console.log(Details);
  const subTotal =
    getSubTotalMonthCap(Details.tenure, Details.price?.addedGst) +
    Details.price?.delivery?.lineTotal;

  const beforeCreditTotal = getSubTotalMonthCap(
    Details.tenure,
    Details.price?.addedGst
  );

  let onePercent = getOnePercent(beforeCreditTotal);

  useEffect(() => {
    onePercent = getOnePercent(beforeCreditTotal);
    let appliedCreditValue =
      creditInfo?.credits < onePercent ? creditInfo?.credits : onePercent;
    if (credit && !props.orderId) {
      credit ? setApplicableCredit(appliedCreditValue) : setApplicableCredit(0);
    }
    if (props.orderId) {
      props.order?.useCredit
        ? setApplicableCredit(appliedCreditValue)
        : setApplicableCredit(0);
    }
  }, [credit, props.orderId, beforeCreditTotal]);

  return (
    <div className="right-base-container-new">
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      <div className="breakdown-wrapper-container">
        <div className="right-container-navbar">
          <p>Product Details</p>
          <img src={SmallCross} onClick={() => props.onclick()} alt="details" />
        </div>
        <div className="breakdown-header">
          <p className="header-package-name">{Details.packageName}</p>
          <div className="tablet-tenure">
            <div className="tenure-right-cont">
              <p id="tenure-first-p">Deliver By</p>
              <p id="tenure-last-p">{Details.startDate}</p>
            </div>
            <div className="tenure-left-cont">
              <p id="tenure-first-p">Tenure</p>
              <p id="tenure-last-p">{getDuration(Details.tenure)}</p>
            </div>
          </div>
          <p className="header-addon-name">
            Addons :{" "}
            {Details.addons?.map((data) => (
              <span className="checkout-addons-text">{data?.addonName}, </span>
            ))}
          </p>
          <div className="header-date-cont">
            <p>{Details.startDate}</p>
            <p> - </p>
            <p>{Details.endDate}</p>
          </div>
        </div>
        <div className="breakdown-container">
          <div className="breakdown-row">
            <p>{Details.tenure < 30 ? "Daily Rent" : "Monthly Rent"}</p>
            <p>
              {" "}
              {priceFormatterDaily(Details.tenure, Details.price?.withAddons)}
            </p>
          </div>
          <div className="breakdown-row" id="tenure-pc">
            <p>Tenure</p>
            <p>{getDuration(Details.tenure)}</p>
          </div>
          <hr />
          <div className="breakdown-row">
            <p>Delivery charges</p>
            <p>₹{Details.price?.delivery?.rate}</p>
          </div>
          <div className="breakdown-row">
            <p>Sub Total</p>
            <p>
              ₹
              {getSubTotalMonthCap(Details.tenure, Details.price?.withAddons) +
                Details.price?.delivery?.rate}
            </p>
          </div>
          <div className="breakdown-row">
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Coupon
            </p>
            <p>
              -₹
              {/* {
                lodash.round(
                  getSubTotalMonthCap(
                    Details.tenure,
                    Details.price?.discountedPrice
                  ),
                  0
                )
              } */}
              {Details.price?.discountAmount}
            </p>
          </div>
          <div className="breakdown-row">
            <p>GST</p>
            <p>
              ₹
              {getSubTotalMonthCap(Details.tenure, Details.price?.gst) +
                (Details.price?.delivery?.CGST +
                  Details.price?.delivery?.SGST +
                  Details.price?.delivery?.IGST)}
            </p>
          </div>
          <div className="breakdown-row">
            Credit
            <p>
              {props?.orderId && props.order?.useCredit && (
                <>
                  -₹
                  {creditInfo?.credits < onePercent
                    ? creditInfo?.credits
                    : onePercent}{" "}
                </>
              )}
              {credit && !props.orderId && (
                <>
                  -₹
                  {creditInfo?.credits < onePercent
                    ? creditInfo?.credits
                    : onePercent}{" "}
                </>
              )}
              {!props?.order?.useCredit && !credit && <p>-₹NA</p>}
            </p>
          </div>
          <hr />
          <div className="breakdown-row">
            <p>Total Payment</p>

            {!props.orderId && (
              <>
                {credit ? (
                  <p>₹{subTotal - applicableCredit}</p>
                ) : (
                  <p>₹{subTotal}</p>
                )}
              </>
            )}

            {!!props?.orderId && (
              <>
                {props?.order?.useCredit ? (
                  <p>₹{subTotal - applicableCredit}</p>
                ) : (
                  <p>₹{subTotal}</p>
                )}
              </>
            )}
          </div>
        </div>

        {/* New UI Change */}
        {!props.orderId && (
          <>
            <div className="delivery-additional-options">
              <div className="delivery-additional-options-btn-container">
                <button
                  style={{ display: props.isOrder ? "none" : "" }}
                  onClick={() => setIsGstdetails(true)}
                >
                  Claim GST +
                </button>
                <button
                  style={{ display: props.isOrder ? "none" : "" }}
                  onClick={() => setIsCoupon(true)}
                >
                  Apply Coupon
                </button>
              </div>
              {creditInfo?.credits > 0 && (
                <div className="delivery-additional-options-use-credit">
                  <p className="delivery-additional-options-use-credit-text">
                    <input
                      type="checkbox"
                      name=""
                      id="credit-btn-cb"
                      checked={credit}
                      onInput={() => creditBtnClickHandler()}
                      disabled={isLoading}
                    />
                    Use Credit
                  </p>
                </div>
              )}
            </div>
            <div className="credit-details-container">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    d="M26.2035 12.933L14.0602 0.789754C13.8095 0.538802 13.5117 0.339849 13.1839 0.20432C12.8561 0.0687915 12.5047 -0.00064291 12.15 4.48574e-06H2.7C1.20825 4.48574e-06 0 1.20825 0 2.7V12.15C0 12.8993 0.30375 13.5743 0.7965 14.0603L12.9465 26.2102C13.4325 26.6962 14.1075 27 14.85 27C15.5992 27 16.2742 26.6962 16.7602 26.2102L26.2102 16.7603C26.6962 16.2675 27 15.5925 27 14.85C27 14.1008 26.6962 13.4258 26.2035 12.933ZM4.725 6.75C3.6045 6.75 2.7 5.8455 2.7 4.725C2.7 3.6045 3.6045 2.7 4.725 2.7C5.8455 2.7 6.75 3.6045 6.75 4.725C6.75 5.8455 5.8455 6.75 4.725 6.75ZM20.6145 17.9145L14.85 23.679L9.0855 17.9145C8.478 17.3003 8.1 16.4565 8.1 15.525C8.1 13.662 9.612 12.15 11.475 12.15C12.4065 12.15 13.257 12.528 13.8645 13.1423L14.85 14.1278L15.8355 13.1423C16.4497 12.528 17.2935 12.15 18.225 12.15C20.088 12.15 21.6 13.662 21.6 15.525C21.6 16.4565 21.222 17.3003 20.6145 17.9145Z"
                    fill="url(#paint0_linear_744_1337)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_744_1337"
                      x1="0.506251"
                      y1="0.50625"
                      x2="20.7721"
                      y2="22.1168"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#19EEB5" />
                      <stop offset="0.720714" stop-color="#1BAFC4" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div>
                <div className="credit-details-container-sup-text">
                  Credits Applicable:
                </div>
                <div className="credit-details-container-sub-text">
                  Credits Available:
                </div>
              </div>
              <div>
                <div className="credit-details-container-sup-text">
                  ₹
                  {creditInfo?.credits < onePercent
                    ? creditInfo?.credits
                    : onePercent}
                </div>
                <div className="credit-details-container-sub-text">
                  ₹{creditInfo?.credits}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="breakdown-container-tablet">
          <div className="breakdown-row">
            <p>{Details.packageName}</p>
            <p>₹{Details.price?.basePrice}/day</p>
          </div>
          {Object.keys(Details.price?.addons || [])?.map((elm) => {
            return (
              <div className="breakdown-row">
                <p>{elm}</p>
                <p>₹{Details.price?.addons[elm]}/day</p>
              </div>
            );
          })}
          <hr />
          <div className="breakdown-row">
            <p></p>
            <p>₹{Details.price?.withAddons}/day</p>
          </div>
          <div className="breakdown-row">
            <p>No of Days</p>
            <p>x{Details.tenure}</p>
          </div>
          <hr />
          <div className="breakdown-row">
            <p>Delivery Charge</p>
            <p>{Details.price?.delivery?.rate}</p>
          </div>
          <div className="breakdown-row">
            <p>Sub Total</p>
            <p>
              {getSubTotalMonthCap(Details.tenure, Details.price?.withAddons) +
                Details.price?.delivery?.rate}
            </p>
          </div>
          <div className="breakdown-row">
            Coupon
            <p>
              {/* {console.log("Details: ", Details)} */}- ₹
              {getSubTotalMonthCap(
                Details.tenure,
                Details.price?.discountedPrice
              )}
            </p>
          </div>
          <div className="breakdown-row">
            GST
            <p>
              + ₹
              {getSubTotalMonthCap(Details.tenure, Details.price?.gst) +
                (Details.price?.delivery?.CGST +
                  Details.price?.delivery?.SGST +
                  Details.price?.delivery?.IGST)}
            </p>
          </div>
          <div className="breakdown-row">
            Credit
            <p>
              {credit ? (
                <>
                  -₹
                  {creditInfo?.credits < onePercent
                    ? creditInfo?.credits
                    : onePercent}{" "}
                </>
              ) : (
                <>-₹NA</>
              )}
            </p>
          </div>
          <hr />
          <div className="breakdown-row">
            <p id="breakdown-grand-total">Grand Total</p>
            <p id="breakdown-grand-total">
              {!props.orderId && (
                <>
                  {credit ? (
                    <p>₹{subTotal - applicableCredit}</p>
                  ) : (
                    <p>₹{subTotal}</p>
                  )}
                </>
              )}

              {!!props?.orderId && (
                <>
                  {props?.order?.useCredit ? (
                    <p>₹{subTotal - applicableCredit}</p>
                  ) : (
                    <p>₹{subTotal}</p>
                  )}
                </>
              )}
            </p>
          </div>
        </div>
        <PopUp
          center={true}
          display={isCoupon}
          callback={setIsCoupon}
          uniqueId={"coupon-order"}
          zIndex={13}
        >
          <Couponpopup
            onclick={() => setIsCoupon(!isCoupon)}
            packageId={getUserCartById?.packageInfo?.customId}
          />
        </PopUp>
        <PopUp
          zIndex={13}
          center={true}
          display={isGstdetails}
          callback={setIsGstdetails}
        >
          <Gstdetailspopup
            cartId={props.cartid}
            onclick={() => setIsGstdetails(!isGstdetails)}
          />
        </PopUp>
        {!props.isOrder && (
          <>
            <div className="order-proceed-btn">
              <button
                id="proceed-btn"
                disabled={agreePermission === false}
                onClick={() => {
                  credit
                    ? proceedBtnHandler()
                    : creditInfo?.credits > 0
                    ? setIsCreditPopUpRequired(true)
                    : proceedBtnHandler();
                }}
              >
                Proceed
              </button>
              {/* )} */}
              {/* {isPopup === true && (
                <div className="order-confirm-container">
                  <div className="order-confirm-content">
                    <p id="order-text">Order Accepted</p>
                    <img
                      src={OrderAccepted}
                      alt="orderaccepted"
                      style={{ marginTop: "1rem" }}
                    />
                    <p id="credit-text">Woohoo! 65 Credits Earned</p>
                    <p id="order-desc">
                      Sit back and relax! Your order has been accepted by us.
                      You can check out your order details in{" "}
                      <Link to="">My Orders</Link>.
                    </p>
                    <button type="submit">
                      <Link
                        to={MYORDERS}
                        // onClick={() => {
                        //   dispatch(setPopup());
                        // }}
                      >
                        Done
                      </Link>
                    </button>
                  </div>
                </div>
              )} */}
              {/* <PaymentButtonV1 orderId={props.orderId} /> */}
              {/* <div className="coupon-gst-button-cont">
                <button type="submit" onClick={() => setIsGstdetails(true)}>
                  Claim GST +
                </button>
                <button type="submit" onClick={() => setIsCoupon(true)}>
                  Apply Coupon
                </button>
              </div> */}
              {/* <div className="checkboxes-cart-cont">
                <input
                  type="checkbox"
                  name="credit-checkbox"
                  disabled={
                    getNoOfDays(
                      getUserCartById?.startDate,
                      getUserCartById?.endDate
                    ) >= 30 || verifyCartCreditInfo?.diff < 0
                  }
                />
                <label for="credit-checkbox">
                  <span className="checkmark"></span> Use Credit <br />
                </label>
              </div> */}
              {/* ================================================================================================ */}
              {/* <p id="coupon-gst-cont-info">
                {verifyCartCreditInfo?.diff < 0 &&
                  "You have insufficient credits to make complete purachase via credits"}
              </p> */}
              {/* ================================================================================================ */}
              {/* <p id="coupon-gst-cont-info">
                {getNoOfDays(
                  getUserCartById?.startDate,
                  getUserCartById?.endDate
                ) >= 30 &&
                  "Credit can only be used in subscriptions less than 30 days"}
              </p> */}
              <PopUp
                zIndex={13}
                center={true}
                display={isCreditPopUpRequired}
                callback={setIsCreditPopUpRequired}
              >
                <CreditConfirmPopUp cartid={props?.cartid} />
                {/* <div className="use-credit-popup-container">
                  <div className="use-credit-popup-text-container">
                    <p className="use-credit-popup-text">
                      Do you want to use credits ?
                    </p>
                  </div>

                  <div className="use-credit-options-container">
                    <button
                      id="credit-confirm-popup-yes"
                      onClick={() => {
                        creditBtnClickHandler();
                        setIsCreditPopUpRequired(false);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      id="credit-confirm-popup-no"
                      onClick={() => {
                        proceedBtnHandler();
                        setIsCreditPopUpRequired(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div> */}
              </PopUp>
            </div>
            <div className="breakdown-note">
              <p>Note: </p>
              <p>
                You can choose to either complete agreement or the payment to
                quickly reserve your order. However, both need to be completed
                for us to dispatch your order.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Deliveryrightcontainer;
