import * as Yup from "yup";

import React, { useState } from "react";
import {
  // clearErrorSlice,
  setGstDetails,
} from "../../../redux/features/orderSlice/orderSlice";
import { useDispatch, useSelector } from "react-redux";

// import FailureAlert from "../../failureAlert/failureAlert";
import { PopUpClose } from "../../../assets";
import { useFormik } from "formik";

const Admingstpopup = (props) => {
  //   Async thunk
  const dispatch = useDispatch();

  const gstData = useSelector(
    (state) => state.rootReducer.orderInfo.gstDetails
  );

  const gstDetails = useFormik({
    initialValues: {
      companyName: gstData.companyName,
      gstNo: gstData.gstNo,
      gstAddress: gstData.gstAddress,
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .min(2, "Must be of 2 characters or more")
        .max(100, "Must be of 300 characters or less"),
      gstNo: Yup.string()
        .min(15, "Must be of 15 characters")
        .max(15, "Must be of 15 characters"),
    }),
    onSubmit: (values) => {
      dispatch(setGstDetails(values));
      props.onclick();
    },
  });

  return (
    <>
      <div className="gst-main-outer">
        <div className="gst-main-container">
          <div className="add-order-title">
            <h5>Gst Detail</h5>
            <img
              style={{ cursor: "pointer" }}
              src={PopUpClose}
              alt=""
              onClick={props.onclick}
            />
          </div>
          <div className="gst-input-container">
            <div className="add-new-admin-order-container">
              <label htmlFor="">
                {gstDetails.errors.companyName && gstDetails.touched.companyName
                  ? gstDetails.errors.companyName
                  : "\u00A0"}
              </label>
              <label htmlFor="">
                {gstDetails.errors.gstNo && gstDetails.touched.gstNo
                  ? gstDetails.errors.gstNo
                  : "\u00A0"}
              </label>
            </div>
            <div className="row-setup" style={{ marginTop: "0rem" }}>
              <input
                type="text"
                name="companyName"
                placeholder="Company Name"
                value={gstDetails.values.companyName}
                onChange={gstDetails.handleChange}
                onBlur={gstDetails.handleBlur}
              />
              <input
                type="text"
                name="gstNo"
                placeholder="Gst Number"
                value={gstDetails.values.gstNo}
                onChange={gstDetails.handleChange}
                onBlur={gstDetails.handleBlur}
              />
            </div>

            <div className="add-new-admin-order-container">
              <label htmlFor="">
                {gstDetails.errors.gstAddress && gstDetails.touched.gstAddress
                  ? gstDetails.errors.gstAddress
                  : "\u00A0"}
              </label>
            </div>
            <div
              className="row-setup-sigal-input"
              style={{ marginTop: "0rem" }}
            >
              <input
                type="text"
                name="gstAddress"
                placeholder="Gst Address"
                value={gstDetails.values.gstAddress}
                onChange={gstDetails.handleChange}
                onBlur={gstDetails.handleBlur}
              />
            </div>
            <div className="add-gst-btn">
              <button
                type="submit"
                className="add-gst-button"
                onClick={() => {
                  gstDetails.handleSubmit();
                }}
              >
                {" "}
                Add GST{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admingstpopup;
