import "./adminnavbar.css";

import {
  Adminnote,
  Adminnotification,
  Adminsearch,
  Cardcolor,
  Deliverycolor,
  Donecolor,
  Downarrowcolor,
  GradientDone,
  Locationcolor,
  Logout,
  Pickupcolor,
  Slideleft,
  Sliderbtn,
  Slideright,
  Timercolor,
} from "../../../assets";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import {
  empLogout,
  empSetLogin,
  loginEmpByToken,
} from "../../../redux/features/empLoginSlice/empLoginSlice";
import { useDispatch, useSelector } from "react-redux";

import { ADMIN_LOGIN } from "../../../constants/links";
import AdminNotification from "./navbarNotification";
import {
  LOGGEDIN
} from "../../../constants/constants";
import PopUp from "../../PopUp/PopUp";
import { usePopper } from "react-popper";
import useSocketSetup from "../../../utils/useSocketSetup";
import { useState } from "react";

// import { userSetLogin } from "../../../redux/features/loginSlice/loginSlice";
// import { createSearchParams } from "react-router-dom";

const Adminnavbar = (props) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [result, setResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const elementRef = useRef();

  // const clearFocusedIndex = (value) => {
  //   setFocusedIndex(-1);
  // };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < result.length - 1 ? prevIndex + 1 : prevIndex
      );
      if (elementRef.current) {
        const focusedElement = elementRef.current.children[focusedIndex + 1];
        if (focusedElement) {
          focusedElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      if (elementRef.current) {
        const focusedElement = elementRef.current.children[focusedIndex - 1];
        if (focusedElement) {
          focusedElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else if (e.key === "Enter" && focusedIndex !== -1) {
      e.preventDefault();
      navigate(result[focusedIndex]?.link);
      setFocusedIndex(-1);
      setResult([]);
      setSearchTerm("");
    }
  };
  const getSearchResults = () => {
    setFocusedIndex(-1);
    const data = Linksdata.filter((elm) => {
      let reg = new RegExp(".*" + searchTerm + ".*", "i");
      return elm?.keywords.match(reg);
    });
    setResult(data);
  };
  const Notifications = useSelector(
    (state) => state.rootReducer.adminNotification.allNotification
  );
  const Linksdata = useSelector(
    (state) => state.rootReducer.dashboardSlice.searchLinks
  );
  // Notification test
  useSocketSetup();
  // end
  // Admin notification popUp
  const [refElmNoti, setRefElmNoti] = useState();
  const [refElmNoti1, setRefElmNoti1] = useState();
  const [refElmNoti2, setRefElmNoti2] = useState();
  // const [searchParams, setSearchParams] = useSearchParams();

  const [popElmNoti, setPopElmNoti] = useState();
  const [isNotification, setIsnotification] = useState(false);

  // logout pop up
  const [refElmLogout, setRefElmLogout] = useState();
  const [popElmLogout, setPopElmLogout] = useState();

  // search popup
  const [refSearch, setRefSearch] = useState();
  // const [popSearch, setPopSearch] = useState();

  //Functions
  const clearResult = () => {
    setSearchTerm("");
  };

  const popUpParams = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };
  const filterPop = usePopper(refElmNoti, popElmNoti, popUpParams);
  const logoutPop = usePopper(refElmLogout, popElmLogout, popUpParams);

  const searchPop = usePopper(refSearch, elementRef.current, popUpParams);

  const [isTask, setIstask] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const empName = useSelector(
    (state) => state.rootReducer.empInfo.data.userName
  );
  const empImage = useSelector((state) => state.rootReducer.empInfo.data.image);
  const logout = () => {
    dispatch(empLogout())
      .unwrap()
      .then((data) => {
        // switch (data.type) {
        //   case SUCCESS:
        //     dispatch(
        //       empSetLogin({
        //         isLogin: false,
        //         userName: "",
        //         image: "",
        //       })
        //     );
        navigate(ADMIN_LOGIN);
        //     break;
        //   default:
        //     break;
        // }
      });
  };

  useEffect(() => {
    dispatch(loginEmpByToken())
      .unwrap()
      .then((data) => {
        switch (data.type) {
          case LOGGEDIN:
            dispatch(
              empSetLogin({
                isLogin: true,
                userName: data.data.userName,
                image: data.data.image,
                isCurrentAdmin: data.data.isCurrentAdmin,
                empLocation: data.data.empLocation,
              })
            );
            break;
          default:
            navigate(ADMIN_LOGIN);
        }
      });
  }, []);

  useEffect(() => {
    getSearchResults();
  }, [searchTerm]);

  return (
    <>
      <div
        className={
          props.slider
            ? "admin-dashboard-navbar-container"
            : "admin-dashboard-navbar-container-closed"
        }
        // style={{
        //   width: props.slider ? "80%" : "100%",
        //   marginLeft: props.slider ? "20%" : "0%",
        // }}
      >
        <div className="slider-btn-container" onClick={props.onclick}>
          <img src={Sliderbtn} alt="sliderbtn" />
          {props.slider ? (
            <img src={Slideleft} alt="leftarrow" id="slider-nav-img" />
          ) : (
            <img src={Slideright} alt="rightarrow" id="slider-nav-img" />
          )}
        </div>
        <div className="admin-dashboard-navbar-content">
          <div
            className="admin-navbar-search-container"
            ref={setRefSearch}
            onClick={() => {
              setSearchTerm("");
            }}
          >
            <input
              type="search"
              name=""
              id=""
              placeholder="Search"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
            />
            <PopUp
              display={searchTerm}
              callback={clearResult}
              uniqueId={"result-popup"}
              fixed={true}
            >
              <div
                // ref={setPopSearch}
                className="search-results"
                style={{ ...searchPop.styles.popper }}
                {...searchPop.attributes}
                ref={elementRef}
              >
                {result?.map((ele, index) => {
                  const isFocused = focusedIndex === index;

                  return (
                    <div
                      style={{
                        background: isFocused
                          ? "rgb(155 155 155 / 33%)"
                          : "transparent",
                        borderRadius: "4px",
                        padding: "0rem 0.3rem",
                      }}
                    >
                      <Link to={`${ele?.link}`}>
                        <p>{ele.title}</p>
                        <p id="desc">{ele?.Description}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
              {/* </>
              )} */}
            </PopUp>
            <img
              src={Adminsearch}
              alt="search"
              style={{
                marginRight: "1rem",
                width: "1.418rem",
                height: "1.418rem",
              }}
            />
          </div>

          <div className="admin-info-container">
            <div
              ref={setRefElmNoti1}
              className="admin-note-container"
              // onClick={() => {
              //   setRefElmNoti(refElmNoti1);
              //   // setIstask(!isTask);
              //   setIsnotification(true);
              // }}
            >
              <img
                src={Adminnote}
                alt="adminnote"
                /*change it's opacity once button is enabled*/
                style={{ width: "1.179rem", height: "1.5rem", opacity: "0.6" }}
              />
              <p id="admin-note">
                {/*task count keeping it disabled temporarily*/}
              </p>
            </div>

            <div
              ref={setRefElmNoti2}
              className="admin-notification-container"
              onClick={() => {
                setRefElmNoti(refElmNoti2);
                setIsnotification(true);
              }}
            >
              <img
                src={Adminnotification}
                alt="adminnotification"
                style={{ width: "1.359rem", height: "1.5rem" }}
              />
              <p id="admin-noti">{Notifications.length}</p>
            </div>

            <div
              className="admin-profile-container"
              onClick={() => setIsLogout(!isLogout)}
            >
              <div
                ref={setRefElmLogout}
                className="admin-profile-img-container"
              >
                <img src={empImage} alt={empName.split(" ")[0]} />{" "}
              </div>
              <p>{empName}</p>
            </div>
          </div>
        </div>
      </div>
      <PopUp
        display={isNotification}
        callback={setIsnotification}
        styles={{ backdropFilter: "blur(1px)", background: "rgba(0,0,0,0.2)" }}
        fixed={true}
      >
        <AdminNotification
          refProp={setPopElmNoti}
          attributes={filterPop?.attributes}
          styleProp={filterPop?.styles?.popper}
        />
      </PopUp>

      {/* {false && <AdminNotification />} */}

      {isTask && (
        <>
          <div className="admin-notification-popup-container">
            <div className="admin-notification-popup-header">
              <p>Task</p>
              <p>12</p>
            </div>
            <div className="admin-notification-popup-content">
              <div className="admin-notification-popup-inner-content">
                <div className="admin-notification-popup-inner-container">
                  <img
                    src={Deliverycolor}
                    alt="deliverycolor"
                    style={{ marginBottom: "1.3rem" }}
                  />
                  <div className="admin-notification-desc">
                    <p id="admin-popup-text">
                      Delivery: PS4 + 2 Controller..{" "}
                      <img src={Downarrowcolor} alt="downarrow" />
                    </p>
                    <p id="admin-popup-timer">
                      {" "}
                      <img src={Timercolor} alt="" /> 30 sec ago{" "}
                      <img
                        src={Locationcolor}
                        alt="locationcolor"
                        style={{ marginLeft: "1rem" }}
                      />{" "}
                      Malad E
                    </p>
                  </div>
                  <img src={GradientDone} alt="gradientdone" />
                </div>

                <div className="admin-notification-popup-inner-container">
                  <img
                    src={Pickupcolor}
                    alt="pickupcolor"
                    style={{ marginBottom: "1.3rem" }}
                  />
                  <div className="admin-notification-desc">
                    <p id="admin-popup-text">Pick up: PS4 + 2 Controller.. </p>
                    <p id="admin-popup-timer">
                      {" "}
                      <img src={Timercolor} alt="" /> 30 sec ago{" "}
                      <img
                        src={Locationcolor}
                        alt="locationcolor"
                        style={{ marginLeft: "1rem" }}
                      />{" "}
                      Malad E
                    </p>
                  </div>
                  <img src={GradientDone} alt="gradientdone" />
                </div>

                <div className="admin-notification-popup-inner-container">
                  <img
                    src={Cardcolor}
                    alt="cardcolor"
                    style={{ marginBottom: "1.3rem" }}
                  />
                  <div className="admin-notification-desc">
                    <p id="admin-popup-text">Pending Payment: Vikran..</p>
                    <p id="admin-popup-timer">
                      {" "}
                      <img src={Timercolor} alt="" /> 30 sec ago{" "}
                      <img
                        src={Locationcolor}
                        alt="locationcolor"
                        style={{ marginLeft: "1rem" }}
                      />{" "}
                      Malad E
                    </p>
                  </div>
                  <img src={GradientDone} alt="gradientdone" />
                </div>

                <div className="admin-notification-popup-inner-container">
                  <img
                    src={Deliverycolor}
                    alt="deliverycolor"
                    style={{ marginBottom: "1.3rem" }}
                  />
                  <div className="admin-notification-desc">
                    <p id="admin-popup-text">Delivery: PS4 + 2 Controller..</p>
                    <p id="admin-popup-timer">
                      {" "}
                      <img src={Timercolor} alt="" /> 30 sec ago{" "}
                      <img
                        src={Locationcolor}
                        alt="locationcolor"
                        style={{ marginLeft: "1rem" }}
                      />{" "}
                      Malad E
                    </p>
                  </div>
                  <img src={GradientDone} alt="gradientdone" />
                </div>

                <div className="admin-notification-popup-inner-container">
                  <img
                    src={Cardcolor}
                    alt="cardcolor"
                    style={{ marginBottom: "1.3rem" }}
                  />
                  <div className="admin-notification-desc">
                    <p id="admin-popup-text">Pending Payment: Vikran.. </p>
                    <p id="admin-popup-timer">
                      {" "}
                      <img src={Timercolor} alt="" /> 30 sec ago{" "}
                      <img
                        src={Locationcolor}
                        alt="locationcolor"
                        style={{ marginLeft: "1rem" }}
                      />{" "}
                      Malad E
                    </p>
                  </div>
                  <img src={Donecolor} alt="gradientdone" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <PopUp
        display={isLogout}
        callback={setIsLogout}
        uniqueId={"logout-pop-up"}
        zIndex={15}
      >
        <div
          ref={setPopElmLogout}
          style={{ ...logoutPop.styles.popper }}
          {...logoutPop.attributes}
        >
          <div className="admin-profile-logout-container">
            {/* <Link to=ADMIN_LOGIN> */}
            <div className="admin-logout-btn-container" onClick={logout}>
              <p>Logout</p>
              <img src={Logout} alt="logout" />
            </div>
            {/* </Link> */}
          </div>
        </div>
      </PopUp>
    </>
  );
};

export default Adminnavbar;
