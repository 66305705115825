import "./adminmaintenancepopup.css";

import * as Yup from "yup";

import {
  addMaintenanceInfo,
  findInvItemByIdThunk,
} from "../../../redux/features/adminInventorySlice/inventorySlice";

import { Calender } from "../../../assets";
import React from "react";
import { SUCCESS } from "../../../constants/constants";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useState } from "react";

const Donepopup = (props) => {
  return (
    <>
      <div className="admin-maintenance-popup-done-popup-container">
        <div className="admin-maintenance-popup-done-popup-content">
          <p id="admin-maintain-isdone-text">Added for Maintenance</p>
          <p id="admin-maintain-isdone-desc">
            Product 1 has been added in the maintenace list. Lorem Ipsum ajsjhdg
            asd
          </p>
          <button onClick={props.onclick}>Done</button>
        </div>
      </div>
    </>
  );
};

const Error = styled.p`
  margin-bottom: -1.3rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  color: #d34e4e;
  width: 100%;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;
`;

const Adminmaintenancepopup = (props) => {
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  const maintenanceForm = useFormik({
    initialValues: {
      reason: "",
      vendorName: "",
      expectedAmount: "",
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("reason is required field"),
      vendorName: Yup.string().required("vendor's name is a required field"),
      startDate: Yup.string().required(),
      endDate: Yup.string().required(),
    }),
    onSubmit: (values) => {
      dispatch(
        addMaintenanceInfo({ currId: props.id, maintenanceInfo: values })
      ).then((data) => {
        if (data.payload.type === SUCCESS) {
          // dispatch(findInvItemByIdThunk({ currId: props.id }));
          setIsDone(!isDone);
        }
      });
    },
  });

  return (
    <>
      <div className="admin-maintenance-popup-container">
        <div className="admin-maintenance-popup-content">
          <p id="maintain-popup-heading">Add To Maintenance List?</p>
          <p id="maintain-popup-desc">
            Mention the issue with the product below. Donec dictum adipiscing
            aliquam sem etiam sociis est purus.
          </p>
          <Error>
            {maintenanceForm.touched.reason && maintenanceForm.errors.reason}
            &nbsp;
          </Error>
          <textarea
            name="reason"
            id="maintain-popup-textarea"
            cols="30"
            rows="10"
            placeholder="Reason for maintenance"
            value={maintenanceForm.values.reason}
            onBlur={maintenanceForm.handleBlur}
            onChange={maintenanceForm.handleChange}
          ></textarea>

          <Error>
            {maintenanceForm.touched.vendorName &&
              maintenanceForm.errors.vendorName}
            &nbsp;
          </Error>
          <div className="admin-maintain-popup-vend-details-container">
            <input
              type="text"
              name="vendorName"
              value={maintenanceForm.values.vendorName}
              onChange={maintenanceForm.handleChange}
              onBlur={maintenanceForm.handleBlur}
              id=""
              placeholder="Vendor's Name"
            />
            <input
              type="number"
              name="expectedAmount"
              value={maintenanceForm.values.expectedAmount}
              onChange={maintenanceForm.handleChange}
              id=""
              placeholder="Expected Amount"
            />
          </div>
          <div className="partial-div">
            <Error>
              {maintenanceForm.touched.startDate &&
                maintenanceForm.errors.startDate}
              &nbsp;
            </Error>
            <Error>
              {maintenanceForm.touched.endDate &&
                maintenanceForm.errors.endDate}
              &nbsp;
            </Error>
          </div>

          <div className="admin-maintain-popup-vend-date-container">
            <div className="admin-vend-date-content">
              <input
                type="date"
                name="startDate"
                value={maintenanceForm.values.startDate}
                onChange={maintenanceForm.handleChange}
                onBlur={maintenanceForm.handleBlur}
                id=""
              />
              <div className="admin-vend-calender-container">
                <img src={Calender} alt="calender" />
              </div>
            </div>

            <div className="admin-vend-date-content">
              <input
                type="date"
                name="endDate"
                value={maintenanceForm.values.endDate}
                onChange={maintenanceForm.handleChange}
                onBlur={maintenanceForm.handleBlur}
                id=""
              />
              <div className="admin-vend-calender-container">
                <img src={Calender} alt="calender" />
              </div>
            </div>
          </div>

          <div className="admin-maintain-popup-btn-container">
            <button onClick={props.onclick}>Cancel</button>
            <button
              type="submit"
              onClick={() => maintenanceForm.handleSubmit()}
            >
              Add +
            </button>
            {isDone && <Donepopup onclick={props.onclick} />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Adminmaintenancepopup;
