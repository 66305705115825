import React, { useState } from 'react'
import './Studio.css'
import DealProduct from '../../../components/dealhouseProduct/DealProduct';
import Explore from '../Explore';


const Studio = () => {
  const [isOpen, setIsOpen] = useState(false);
    return (
        <section>
          <div className={`studio-background-container ${isOpen?"blur":""}`} >
            <DealProduct prodTitle="Shoes" top="42%" left="33.3%" navLink="/dealhouse/gamingroom" />

            <DealProduct prodTitle="Sony Camera" top="47%" left="41%" navLink="/dealhouse/gamingroom" />

            <DealProduct prodTitle="Camera Gimble" top="88%" left="51%" navLink="/dealhouse/gamingroom" />
    
            <DealProduct prodTitle="Go Pro Cam" top="78%" left="61.5%" navLink="/dealhouse/gamingroom" />

             {/*<DealProduct prodTitle="Logitech G29 Driving Force" top="64%" left="60.5%" navLink="/dealhouse/gamingroom" />
  
            <DealProduct prodTitle="Gaming Moniter" top="52%" left="77%" navLink="/dealhouse/gamingroom" />
    
            <DealProduct prodTitle="Gaminr Mouse" top="69%" left="78.5%" navLink="/dealhouse/gamingroom" />

            <DealProduct prodTitle="Gameing CPU" top="63%" left="85%" navLink="/dealhouse/gamingroom" />
    
            <DealProduct prodTitle="Gameing Laptop" top="82%" left="86%" navLink="/dealhouse/gamingroom" /> */}
          </div>
          <Explore isOpen={isOpen} setIsOpen={setIsOpen}/>
        </section>
      );
}

export default Studio