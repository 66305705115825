import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../../constants/constants";
import axios from "axios";

axios.defaults.withCredentials = true;

// write proper statuses
// clear out extra reducers
// replace get phone no to get Otp

//============================================================
export const getPhoneNumber = createAsyncThunk(
    "post/auth/get_phone",
    async (phone) => {
        try {
            const res = await axios.post(`${BASE_URL}/blog/`, {
                phone: phone,
            });
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }
);


const initialState = {
    phone: ""
};

const testInfoSlice = createSlice({
    name: "testInfo",
    initialState: initialState,
    reducers: {
        testUserPhone: (state, action) => {
            state.phone = getPhoneNumber();
        },
    },
})


export default testInfoSlice.reducer;
export const { testUserPhone } = testInfoSlice.actions;
