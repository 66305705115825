import "./myorderextendagreement.css";

// import { PriceInfo, Rupees, SmallCross } from "../../assets";

// import { MyorderExtendPayment } from "..";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const Myorderextendagreement = ({ onclick, isChecked, checkedValue }) => {
  const [isPayment, setIsPayment] = useState(false);
  useEffect(() => {
    if (isPayment) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  return (
    <>
      <div className="myorder-extend-agreement-upperpart">
        <div className="myorder-extend-agreement-tab-container">
          <div className="myorder-extend-agreement-tab">
            <p id="extend-agreement-active">Agreement</p>

            <p style={{ cursor: "pointer" }}>Payment</p>
          </div>
          <div className="myorder-extend-termscondition-container">
            <p>
              <p id="terms-and-condition-text">Terms & Conditions</p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. At diam
              ut sagittis nunc sodales id cursus nisl integer. Laoreet tortor,
              nunc, vestibulum id non est a id. Imperdiet ante donec et semper
              nisi, ut rutrum ut viverra. Tellus a non amet sit nisl lacus
              laoreet. Donec eu viverra nibh elementum nunc non scelerisque. In
              turpis a sed volutpat. Eget facilisis condimentum lorem
              scelerisque. Est egestas condimentum elit blandit libero, sed
              amet, vel, maecenas. Cras bibendum gravida at quam est praesent
              turpis a. Consequat purus, eget tellus elit sagittis. Cras
              dignissim lectus vestibulum quis. Sed massa volutpat volutpat
              scelerisque lacus, aenean sed. Porttitor auctor consectetur quam
              pretium ut non, purus. Arcu vitae tortor, feugiat facilisis
              laoreet praesent. Varius fringilla Eget facilisis condimentum
              lorem scelerisque. Est egestas condimentum elit blandit libero
              Laoreet tortor, nunc, vestibulum id non est a id. Imperdiet ansa.
              get facilisis condimentum lorem scelerisque. Est egestas
              condimentum elit blandit libero, sed amet, vel, maecenas.
            </p>
          </div>
          <label htmlFor="extendagreement">
            <input
              type="checkbox"
              name="extendagreement"
              id=""
              style={{
                width: "1rem",
                height: "1rem",
                background: "transparent",
              }}
              defaultChecked={checkedValue}
              onChange={() => {
                isChecked(!checkedValue);
              }}
            />
            <span style={{ marginLeft: ".5rem" }}>
              By selecting this checked box , I agree to the terms and
              conditions.
            </span>
          </label>
        </div>
      </div>
      {/* <div className="myorder-extend-agreement-lowerpart">
            <p>
              Total cost:{" "}
              <span style={{ marginLeft: "1rem" }}>
                {" "}
                <img
                  src={Rupees}
                  alt="rupees"
                  style={{ height: "1rem", marginRight: "1rem" }}
                />{" "}
                750.00{" "}
                <img
                  src={PriceInfo}
                  alt="priceinfo"
                  style={{ marginLeft: ".5rem" }}
                />
              </span>
            </p>
            <button type="submit" onClick={() => setIsPayment(!isPayment)}>
              Proceed
            </button>
          </div> */}
      {/* {isPayment && <MyorderExtendPayment onclick={props.onclick} />} */}
    </>
  );
  // }
};

export default Myorderextendagreement;
