import "./login.css";
import "../Otp/otpnew.css";

import * as Yup from "yup";

import {
  // FAILURE,
  // FULFILLED,
  OTP_ACTIVE,
  // SERVER_ERROR,
  SUCCESS,
  // VALIDATION_ERROR,
} from "../../constants/constants";
import {
  // Frame,
  // LoginImg,
  MobilePhone,
  // Phone,
  // Cross,
  redCross,
} from "../../assets";
// import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import {
  clearErrorSlice,
  // clearNavigate,
  getPhoneNumber,
  // setUserPhone,
} from "../../redux/features/loginSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";

import { BsFillCheckCircleFill } from "react-icons/bs";
import { FailureAlert } from "../../components";
// import Navbar from "../../components/Navbar/navbar";
// import { OTP } from "../../constants/links";
import Otp from "../Otp/otp.jsx";
import PopUp from "../../components/PopUp/PopUp";
import { useFormik } from "formik";
import { useState } from "react";

const Login = ({ loginClose, addToCartCallback }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [changedState, setChangedState] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [otp, setOtp] = useState(false);
  // const [errore, setError] = useState({});
  // const [customAlert, setCustomAlert] = useState(false);

  // const setNewError = (err) => {
  //   setError(err);
  //   setCustomAlert(true);
  // };

  // // Constant Error
  // const customAlert = useSelector(
  //   (state) => state.rootReducer.userInfo.isError
  // );

  // refer id
  // const [searchParams, setSearchParams] = useSearchParams();

  // Constant Error
  const customAlert = useSelector(
    (state) => state.rootReducer.userInfo.isError
  );
  const error = useSelector((state) => state.rootReducer.userInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  // ------------ Error end

  // ------------ Error end

  const loginForm = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.number("Invalid Number")
        .max(9999999999, "Invalid Number")
        .min(1000000000, "Invalid Number")
        .required("Phone number is required"),
    }),

    onSubmit: (values) => {
      console.log('submit')
      dispatch(getPhoneNumber(values.phone.toString())).then((data) => {
        if (data.payload.type === SUCCESS || data.payload.type === OTP_ACTIVE) {
          setOtp(true);
          // navigate(`${OTP}?refer_id=${searchParams.get("refer_id")}`);
        }
      });
    },
  });

  // const getPhoneNumberStatus = useSelector(
  //   (state) => state.rootReducer.userInfo.status.getPhoneNumber
  // );

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {/* <div className="otp-outer-container-new"> */}
      <PopUp display={otp} callback={setOtp} center={true}>
        <Otp
          loginClose={loginClose}
          callback={() => {
            setOtp(false);
          }}
          addToCartCallback={addToCartCallback}
        />
      </PopUp>
      <div className="otp-inner-container-new">
        <div className="otp-image-container-left">
          <img src="/Vector.png" alt="" />
          {/* <img src={LoginImg} alt="image" id="upper-image" />
          <img src={Frame} alt="Frame" id="lower-image" /> */}
        </div>
        <div className="part-div-component" />
        <div className="otp-image-container-right">
          <div className="client-login-info-first">
            <img className="login-profile-container" src="/Vector.png" alt="" />
            <h1 className="right-container-title" onClick={() => loginClose()}>
              Login{" "}
            </h1>
            <p className="change-number">
              Let's start by entering your 10-Digit mobile number.{" "}
            </p>
          </div>
          <form onSubmit={loginForm.handleSubmit} className="client-login-info-second">
            <div className="otp-error-label">
              <label>
                {" "}
                {loginForm.errors.phone}{" "}
                {loginForm.errors.phone && loginForm.touched.phone
                  ? ""
                  : "\u00A0"}
              </label>
            </div>

            <div className="client-login-input-container">
              <img src={MobilePhone} alt="" />
              <input
                name="phone"
                value={loginForm.values.phonen}
                onChange={(e) => {
                  // setChangedState(true);
                  loginForm.handleChange(e);

                  !loginForm.errors.phone
                    ? setIsValid(true)
                    : setIsValid(false);
                }}
                onBlur={loginForm.handleBlur}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
                type="number"
                placeholder="Mobile Number"
              />
              {loginForm.errors.phone && loginForm.touched.phone && (
                <img src={redCross} alt="cross" id="wronginput" />
              )}
              {isValid && <BsFillCheckCircleFill className="checkCircle" />}
            </div>
            <button
              type="submit"
              className="sumbit-button"
              // onClick={loginForm.handleSubmit} 
            >
              Confirm
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
