import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  // LOADING,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;
// #===========================================
export const createAddonsList = createAsyncThunk(
  "post/admin_frontend/addons/create_new_list",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/create_new_list`,
        { name: data.name }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateAddonListNameSlice = createAsyncThunk(
  "post/admin_frontend/addons/update_list_name",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/update_list_name`,
        payload
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllAddonsList = createAsyncThunk(
  "post/admin_frontend/addons/get_all_list",
  async () => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/get_all_list`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeAddonFromList = createAsyncThunk(
  "post/admin_frontend/addons/remove_addon",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/remove_addon`,
        payload
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addAddonInList = createAsyncThunk(
  "post/admin_frontend/addons/add_addon",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/add_addon`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAddonsInList = createAsyncThunk(
  "post/admin_frontend/addons/get_addons_in_list",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/get_addons_in_list/`,
        { currId: data.id }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const editAddoninList = createAsyncThunk(
  "post/admin_frontend/addons/edit",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/edit/`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const outOfStockThunk = createAsyncThunk(
  "post/admin_frontend/addons/outofstock",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/addons/outofstock`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  }, // Constant
  isError: false, // Constant,
  // local states converted global
  isDone: false,
  updateDone: false,
  currentAddon: "",
  //---------
  data: {
    // data as property is constant
    addonLists: [],
    addonsInList: [],
    currentAddonList: { name: "", list: [] },
  },
  status: {
    getAllAddonsList: IDLE,
    createAddonsList: IDLE,
    getAddonsInList: IDLE,
    removeAddonFromList: IDLE,
    updateAddonListNameSlice: IDLE,
    addAddonInList: IDLE,
    editAddoninList: IDLE,
    outOfStockThunk: IDLE,
  },
};

const adminFrontendAddonSlice = createSlice({
  name: "adminFrontendAddon",
  initialState: initialState,
  reducers: {
    setNumAddonListsSlice: (state, data) => {
      state.data.numAddonLists = data.payload.value;
    },
    setAddonLists: (state, data) => {
      state.data.addonLists = data.payload.value;
    },
    setAddonsInList: (state, data) => {
      state.data.addonsInList = data.payload.value;
    },
    setCurrentAddonList: (state, data) => {
      state.data.currentAddonList = data.payload.value;
    },
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setIsDoneSlice: (state, { payload }) => {
      state.isDone = payload.value;
    },
    setUpdateDoneSlice: (state, { payload }) => {
      state.updateDone = payload.value;
    },
    setCurrentAddon: (state, { payload }) => {
      state.currentAddon = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      // get all addons
      .addCase(getAllAddonsList.pending, (state, action) => {
        // state.status.getAllAddonsList = LOADING;
        state.loading = true;
      })
      .addCase(getAllAddonsList.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.addonLists = payload.data;
            state.status.getAllAddonsList = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllAddonsList.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllAddonsList = ERROR;
      })
      // create addon list---------------------------------------------------------
      .addCase(createAddonsList.pending, (state, action) => {
        // state.status.createAddonsList = LOADING;
        state.loading = true;
      })
      .addCase(createAddonsList.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.createAddonsList = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createAddonsList.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.status.createAddonsList = ERROR;
        state.loading = false;
      })
      // get addons in  list---------------------------------------------------------
      .addCase(getAddonsInList.pending, (state, action) => {
        // state.status.getAddonsInList = LOADING;
        state.loading = true;
      })
      .addCase(getAddonsInList.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAddonsInList = FULFILLED;
            state.data.currentAddonList = payload.data;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAddonsInList.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAddonsInList = ERROR;
      })
      // remove adddon---------------------------------------------------------
      .addCase(removeAddonFromList.pending, (state, action) => {
        // state.status.removeAddonFromList = LOADING;
        state.loading = true;
      })
      .addCase(removeAddonFromList.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.removeAddonFromList = FULFILLED;
            state.updateDone = !state.updateDone;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(removeAddonFromList.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.status.removeAddonFromList = ERROR;
        state.loading = false;
      })
      // updare adddon name---------------------------------------------------------
      .addCase(updateAddonListNameSlice.pending, (state, action) => {
        // state.status.updateAddonListNameSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateAddonListNameSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.updateAddonListNameSlice = FULFILLED;
            state.updateDone = !state.updateDone;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateAddonListNameSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.status.updateAddonListNameSlice = ERROR;
        state.loading = false;
      })
      // add adddon name---------------------------------------------------------
      .addCase(addAddonInList.pending, (state, action) => {
        // state.status.addAddonInList = LOADING;
        state.loading = true;
      })
      .addCase(addAddonInList.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.addAddonInList = FULFILLED;
            state.updateDone = !state.updateDone;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addAddonInList.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.addAddonInList = ERROR;
      })
      // edit addon in the list---------------------------------------------------------
      .addCase(editAddoninList.pending, (state, action) => {
        // state.status.addAddonInList = LOADING;
        state.loading = true;
      })
      .addCase(editAddoninList.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.editAddoninList = FULFILLED;
            state.updateDone = !state.updateDone;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(editAddoninList.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.editAddoninList = ERROR;
      })
      // update out of stock---------------------------------------------------------
      .addCase(outOfStockThunk.pending, (state, action) => {
        // state.status.addAddonInList = LOADING;
        state.loading = true;
      })
      .addCase(outOfStockThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.outOfStockThunk = FULFILLED;
            state.updateDone = !state.updateDone;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(outOfStockThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.outOfStockThunk = ERROR;
      });
  },
});

export default adminFrontendAddonSlice.reducer;
export const {
  setNumAddonListsSlice,
  setAddonLists,
  setAddonsInList,
  setCurrentAddonList,
  clearErrorSlice,
  setIsDoneSlice,
  setUpdateDoneSlice,
  setCurrentAddon,
} = adminFrontendAddonSlice.actions;
