import "./footer.css";
import "./footernew.css";

import {
  Facebook,
  GoTop,
  Insta,
  Mail,
  Phone,
  Twitter,
  UpArrow,
  Upblack,
  Youtube,
} from "../../assets";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
// import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";

// css

// images

const Footer = ({ location }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [locationstate, useLocationstate] = useState(location);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footerContainer">
      {showTopBtn && (
        <div className="scroller-button-new" onClick={() => goToTop()}>
          <div className="scroller-button-image-container">
            <picture>
              <source
                media="(max-width: 576px)"
                srcSet={GoTop}
                id="bottom-scroll-width"
              />
              <img src={Upblack} alt="gototop" />
            </picture>
          </div>
          <div className="white-arrow-mobile">
            <img src={UpArrow} alt="gototop" />
          </div>
        </div>
      )}
      <div className="footerContent">
        {/* logo */}

        <div className="footerLogo"></div>

        {/* socials */}
        <div className="socials">
          <div className="socialsContent">
            <p style={{ color: "#FFFFFF" }}>Follow us on</p>
            <a
              target="_blank"
              href="https://www.instagram.com/rentkar_app/channel/"
            >
              <img src={Insta} alt="Instagram" className="insta" />
            </a>
            <a target="_blank" href="https://www.facebook.com/rentkarinc">
              <img src={Facebook} alt="Facebook" className="facebook" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@rentkarswitchtoshare7749"
            >
              <img src={Youtube} alt="Youtube" className="youtube" />
            </a>
            <a href="#x">
              <img src={Twitter} alt="Twitter" className="twitter" />
            </a>
          </div>
        </div>
        <div className="contactInfo">
          {/* phone */}
          <a href="tel:+917900042875" style={{ textDecoration: "none" }}>
            <img src={Phone} alt="Phone" className="phone" />
            <span className="number">7900042875</span>
          </a>

          {/* phone */}
          <a href="mailto:" style={{ textDecoration: "none" }}>
            <img src={Mail} alt="Mail" className="mail" />
            <span className="mailId">rentkarr@gmail.com</span>
          </a>
        </div>
        {/* description */}
        <p className="description">
          Rentkar is a leading rental tech platform based in Mumbai, Pune, Delhi and
          Bengaluru. The mission for us is to create a sustainable sharing
          culture.We are grateful to have served 30,000+ customers with love and
          quality service.
        </p>
        {/* footer links */}
        <div className="footer-links">
          <div className="footer-links-content">
            <Link to="/aboutus" className="otherLink">
              About Us
            </Link>
            <Link to="/policy" className="otherLink">
              Policy
            </Link>
            <Link to="/termandcondition" className="otherLink">
              Terms And Condition
            </Link>
            <Link to="/mycredit?page=0&pagesize=7&sort=1" className="otherLink">
              Refer
            </Link>
            <Link to="/Faqs" className="otherLink">
              FAQs
            </Link>
            <Link to="/blog" className="otherLink">
              Blog
            </Link>
            <Link
              to={{
                pathname: "/contactus",
              }}
              state={{
                location: locationstate,
              }}
              className="otherLink"
            >
              ContactUs
            </Link>
          </div>
        </div>

        <p className="copyRight">
          Copyright © Quantum Rental Solutions Pvt. Ltd. All Right Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
