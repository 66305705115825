import "../../pages/Productdetails/productdetailsnew.css";
import "../../pages/Productdetails/productdetails.css";

import { Gradientcouppon, SmallCross } from "../../assets";
import React, { useEffect, useState } from "react";
import {
  applyCoupon,
  getAllCoupons,
  getCouponByPackageId,
} from "../../redux/features/homePageSlice/homePageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { SUCCESS } from "../../constants/constants";
import { updateCouponSlice } from "../../redux/features/loginSlice/loginSlice";

const Couponpopup = ({ onclick, packageId }) => {
  const [couponInput, setCouponInput] = useState("");
  const [isCuppon, setIsCuppon] = useState(false);
  const [couponCode, setCouponcode] = useState("");
  const isLoading = useSelector((state) => state.rootReducer.userInfo.loading);

  useEffect(() => {
    if (isCuppon) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  // Async thunk
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const updateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );

  const activeCoupons = useSelector(
    (state) => state.rootReducer.homePage.data.allCoupons
  );

  const getUserCartById = useSelector(
    (state) => state.rootReducer.userInfo.currentUserCart
  );

  const location = useSelector(
    (state) => state?.rootReducer?.userInfo?.location
  );

  useEffect(() => {
    //dispatch(getAllCoupons());
    dispatch(getCouponByPackageId({ packageId, location }));
    setCouponcode(
      getUserCartById?.coupon?._id ? getUserCartById?.coupon?._id : ""
    );
  }, [updateDone]);

  const { id } = useParams();

  return (
    // <>
    //   <div className="coupon-popup-full-container">
    <div className="coupon-popup-main-container">
      <div className="coupon-popup-heading-content">
        <p>
          <img
            src={Gradientcouppon}
            alt="couppon"
            style={{ marginRight: "2px" }}
          />{" "}
          Coupons
        </p>
        <img
          src={SmallCross}
          alt="cross"
          style={{ cursor: "pointer" }}
          onClick={onclick}
        />
      </div>

      <div className="enter-coupon-code-container">
        <input
          type="text"
          value={couponInput}
          placeholder="Enter coupon code"
          required
          onChange={(e) => {
            setCouponInput(e.target.value);
          }}
        />
        <button
          type="submit"
          className="enter-coupon-btn"
          style={{ marginLeft: "2rem" }}
          onClick={() => {
            if (!couponInput || couponInput.trim() === "") {
              return;
            }
            dispatch(applyCoupon(couponInput)).then(({ payload }) => {
              if (payload.type === SUCCESS) setCouponcode(payload.data);
            });
          }}
        >
          Apply Code
        </button>
      </div>

      <div className="redeem-coupon-container">
        {/* {console.log(activeCoupons)} */}
        {activeCoupons?.map((elm, idx) => {
          return (
            <div className="coupon-main-content-one">
              <h3 className="coupon-main-content-heading">{elm.couponName}</h3>
              <p className="coupon-main-content-desc">{elm.content}</p>
              <button
                type="submit"
                className="coupon-main-content-btn"
                disabled={isLoading}
                onClick={() => {
                  const data = {
                    couponId: elm._id,
                    cartId: id,
                  };

                  dispatch(updateCouponSlice(data));
                  // .then((data) => {
                  //   if (data.payload.type == SUCCESS) {

                  //   }
                  // });
                }}
              >
                {/* {console.log(
                      elm._id,
                      couponCode._id,
                      couponCode._id == elm._id
                    )} */}
                {getUserCartById?.coupon?._id === elm._id
                  ? "Applied"
                  : "Redeem"}
              </button>
            </div>
          );
        })}
      </div>
    </div>
    //   </div>
    // </>
  );
};

export default Couponpopup;
