import "./orderaccepted.css";

import { MYORDERS } from "../../constants/links";
import { OrderAccepted } from "../../assets";
import React from "react";
import { useNavigate } from "react-router-dom";

const Orderaccepted = ({ callback, credits, isCredit}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="order-accepted-container-new">
        <div className="order-accepted-content-new">
          <p id="order-text-new">Order Accepted</p>
          <img src={OrderAccepted} alt="orderaccept" />
          {isCredit === true ? (
            <p id="credit-text-new">Woohoo! Order Confirmed</p>
          ) : (
            <p id="credit-text-new">Woohoo! {credits} Credits Earned</p>
          )}
          {isCredit === true ? (
            <p id="order-accept-desc">
              Sit back and relax! Your order has been accepted by us. You can
              check out your order details in{" "}
              <a
                href={`${MYORDERS}`}
                onClick={() => {
                  callback(false);
                }}
                // id="payment-button-test"
                style={{ textDecoration: "none" }}
              >
                My Order
              </a>
              .
            </p>
          ) : (
            <p id="order-accept-desc">
              Sit back and relax! Your order has been accepted by us. You can
              check out your order details in{" "}
              <a
                href={`${MYORDERS}`}
                onClick={() => {
                  callback(false);
                }}
                // id="payment-button-test"
                style={{ textDecoration: "none" }}
              >
                My Order
              </a>
              .
            </p>
          )}
          <button
            type="submit"
            id="payment-button-test"
            onClick={() => {
              callback(false);
              navigate(`${MYORDERS}`);
            }}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
};

export default Orderaccepted;
