import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

function PrivateRoute({ children }) {
  // const token  = useSelector((state) => state.rootReducer.preLaunchAuth.preLaunchtoken)

  // if (token !== null) {  
  //   return children
  // } else {
  //   return <Navigate to="/preAuth" />  
  // }
  return children
}

export default PrivateRoute