// import "../../../pages/Admin/Adminorder/adminorder.css";

import "./disapprovepopup.css";

import * as Yup from "yup";

import React, { useEffect, useState } from "react";

import { SUCCESS } from "../../../constants/constants";
import { changeOrderStatusSlice } from "../../../redux/features/orderSlice/orderSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

const Disapprovepopup = (props) => {
  const [confirmdisapprove, setconfirmdisapprove] = useState(false);

  useEffect(() => {
    if (confirmdisapprove) {
      document.body.style.position = "fixed";
    }
  });

  // Async thunk
  const dispatch = useDispatch();

  const orderStatus = useFormik({
    initialValues: {
      status: "",
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .min(10, "message cannot be less than 10")
        .max(300, "message cannot be more than 300"),
    }),
    onSubmit: (values) => {
      const data = {
        orderId: props.mongoId,
        status: props.orderStatus,
        message: values.message,
      };
      dispatch(changeOrderStatusSlice(data)).then((data) => {
        if (data.payload.type == SUCCESS) {
          setconfirmdisapprove(!confirmdisapprove);
        } else {
        }
      });
    },
  });

  return (
    <>
      <div className="admin-order-moreopt-disapprove-popup">
        <div className="admin-order-moreopt-disapprove-popup-inner-container">
          <div className="admin-order-moreopt-disapprove-popup-inner-content">
            <div className="disapprove-popup-head">
              <p id="dispprove-title-one">Disapprove Confirmation</p>
            </div>
            <div className="disapprove-order-customer-info">
              <p id="disapprove-orderinfo-p">
                Disapprove order no. <span> {props.orderId} </span> of
                <span> {props.userName} </span>
                for package <span> {props.packageName} </span>? Dispprove Order
                can be found in disapprove list
              </p>
            </div>
            <div
              className="add-new-admin-order-container"
              style={{ marginTop: "0rem" }}
            >
              <label htmlFor="">
                {orderStatus.errors.message && orderStatus.touched.message
                  ? orderStatus.errors.message
                  : "\u00A0"}
              </label>
            </div>
            <div
              className="disapprove-order-textinput-container"
              style={{ marginTop: "0rem" }}
            >
              <textarea
                id="disapprove-reason"
                cols="30"
                rows="10"
                name="message"
                placeholder="Reason for Disapprove"
                value={orderStatus.values.message}
                onChange={orderStatus.handleChange}
                onBlur={orderStatus.handleBlur}
              ></textarea>
            </div>
            <div className="disapprove-inner-last-container">
              <div className="disapprove-buttons-container">
                <button onClick={props.onclick} id="disapproval-cancel-btn">
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    orderStatus.handleSubmit();
                    props.setState("DISAPPROVED");
                  }}
                  id="disapproval-main-btn"
                >
                  Disapprove
                </button>
                {confirmdisapprove && (
                  <>
                    <div className="disapprove-confirm-main-container">
                      <div className="disapprove-confirm-outer-main-container">
                        <div className="disapprove-confirn-inner-main-container">
                          <div className="disapprove-confirm-title-main">
                            <p id="disapprove-confirm-title-one">
                              Order Disapproved
                            </p>
                          </div>
                          <div className="disapprove-confirm-order-informartion">
                            <p id="dissapprove-confirm-orderno-information">
                              Order no.{props.orderId} has been dissapproved. by
                              you
                            </p>
                          </div>
                          <div className="dissapprove-confirm-done-button">
                            <button
                              onClick={props.onclick}
                              id="dissapprove-confirm-done"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disapprovepopup;
