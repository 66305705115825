import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";

export const preLaunchAuthSlice = createAsyncThunk(
  "post/prelaunch/preAuth",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/prelaunch/preAuth`, data);
      localStorage.setItem("PRE_TOKEN", JSON.stringify(res.data.PRE_TOKEN));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const setLaunchAuthSlice = createAsyncThunk(
  "post/prelaunch/getpreAuth",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/prelaunch/getpreAuth`);
      localStorage.removeItem("PRE_TOKEN");
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//============================================================
const initialState = {
  loading: false,
  preLaunchtoken: localStorage.getItem("PRE_TOKEN") ? JSON.parse(localStorage.getItem("PRE_TOKEN")) : null,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  isLogin: false,
  location: "",
  updateDone: false,
  status: {
    preLaunchAuthSlice: IDLE,
  },
};

// Then, handle actions in your reducers:
const preLaunchSlice = createSlice({
  name: "preLaunchAuth",
  initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    // ===============================================================================
    builder
      // Login or register
      .addCase(preLaunchAuthSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(preLaunchAuthSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.preLaunchAuthSlice = FULFILLED;
            state.preLaunchtoken = payload.PRE_TOKEN;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(preLaunchAuthSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.preLaunchAuthSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(setLaunchAuthSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(setLaunchAuthSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.preLaunchtoken = null;
            break;
          default:
            state.preLaunchtoken = null;
        }
        state.loading = false;
      })
      .addCase(setLaunchAuthSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
        state.preLaunchtoken = null;
      });
  },
});

export default preLaunchSlice.reducer;
export const { clearErrorSlice } = preLaunchSlice.actions;
