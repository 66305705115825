import "./adminproductslider.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import 'react-toastify/dist/ReactToastify.css';

import { Leftbtn, Rightbtn } from "../../../assets";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ToastContainer, toast } from "react-toastify";
import {
  clearErrorSlice,
  getAllCategories,
} from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { useDispatch, useSelector } from "react-redux";

import FailureAlert from "../../failureAlert/failureAlert";
import { Navigation } from "swiper";
import { SUCCESS } from "../../../constants/constants";

const Adminproductslider = ({
  activeCategory,
  activeSubCategory,
  setActiveSubCategory,
}) => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const dispatch = useDispatch();
  const updateDone = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.updateDone
  );
  // Constant Error
  const loading = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.loading
  );
  const customAlert = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.isError
  );
  const error = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.errorData
  );
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  const [allCategories, setAllCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    dispatch(getAllCategories())
      .unwrap()
      .then((data) => {
        if (data?.type === SUCCESS) {
          setAllCategories(data?.data);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    setFilteredCategories(
      allCategories.filter(
        (elm) => elm?.category === activeCategory && !!elm?.subCategory
      )
    );
  }, [activeCategory, activeSubCategory, allCategories]);

  const handleSlidePrev = () => {
    if (swiperInstance && swiperInstance.isBeginning) {
      toast.info("No more slides in this direction");
    } else if (swiperInstance && swiperInstance.slidePrev) {
      swiperInstance.slidePrev();
    }
  };

  const handleSlideNext = () => {
    if (swiperInstance && swiperInstance.isEnd) {
      toast.info("No more slides in this direction");
    } else if (swiperInstance && swiperInstance.slideNext) {
      swiperInstance.slideNext();
    }
  };

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}

      <div className="admin-product-sidebar-layout">
        <ToastContainer />
        {/* left scroll button */}
        <div
          className="left-scroll-container admin-product-slider-left-btn"
          onClick={handleSlidePrev}
        >
          <img src={Leftbtn} alt="Left button" />
        </div>
        {/* admin-product-box-container */}
        <Swiper
          onSwiper={setSwiperInstance}
          slidesPerView={3}
          centeredSlides={false}
          spaceBetween={16}
          slidesPerGroup={1}
          grabCursor={true}
          loop={false}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: ".admin-product-slider-left-btn",
            nextEl: ".admin-product-slider-right-btn",
          }}
          modules={[Navigation]}
          className="admin-product-box-container"
        >
          {filteredCategories.map((elm, idx) => (
            <SwiperSlide
              key={idx} // Always provide a key when rendering lists
              className="admin-product-box"
              id={
                elm?.subCategory === activeSubCategory &&
                "admin-product-active-box"
              }
              onClick={() => {
                setActiveSubCategory(elm?.subCategory);
              }}
            >
              {elm?.subCategory?.length > 12
                ? elm?.subCategory?.slice(0, 12) + "..."
                : elm?.subCategory}
            </SwiperSlide>
          ))}
        </Swiper>
        {/* =========================== */}
        {/* right scroll button */}
        <div
          className="right-scroll-container admin-product-slider-right-btn"
          onClick={handleSlideNext}
        >
          <img src={Rightbtn} alt="Right button" />
        </div>
      </div>
    </>
  );
};

export default Adminproductslider;