import { useState, useEffect } from "react";
import PopUp from "../PopUp/PopUp";
import Addnewaddress from "../Addnewaddress/addnewaddress";
import { useDispatch, useSelector } from "react-redux";
import { getUserCartSlice } from "../../redux/features/loginSlice/loginSlice";

const ReUploadDocument = (props) => {
  // console.log(props);
  const dispatch = useDispatch();
  const [openReupload, setOpenReupload] = useState(false);
  useEffect(() => {
    dispatch(getUserCartSlice());
  }, []);

  const addressData = useSelector((state) => state?.rootReducer?.orderInfo?.data?.currentAddress)

  const getUserCartById = useSelector(
    (state) => state.rootReducer?.userInfo?.currentUserInfo
  );



  return (
    <div className="re-upload-document-content" style={{ "justifyContent": "start" }}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M2 46H46M2 46V34.7079L24 12.1236M2 46L13 45.9999L35 23.4157M24 12.1236L31.8887 4.02525L31.8935 4.02045C32.9794 2.90568 33.5233 2.34731 34.1503 2.13817C34.7027 1.95394 35.2977 1.95394 35.85 2.13817C36.4765 2.34716 37.0198 2.9049 38.1042 4.01808L42.8887 8.92959C43.9777 10.0476 44.5225 10.6068 44.7265 11.2514C44.906 11.8184 44.9059 12.4291 44.7264 12.9961C44.5226 13.6402 43.9786 14.1986 42.8911 15.315L42.8887 15.3174L35 23.4157M24 12.1236L35 23.4157" stroke="url(#paint0_linear_59_552)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
          <linearGradient id="paint0_linear_59_552" x1="2" y1="2" x2="46.8266" y2="2.78476" gradientUnits="userSpaceOnUse">
            <stop stop-color="#1BACF4" />
            <stop offset="1" stop-color="#19EEB5" />
          </linearGradient>
        </defs>
      </svg>
      <div className="reupload-inner-container">
        <p>
          Edit the Address and corresponding document
        </p>
        <div className="reupload-sucessfull-done-button">
          <button
            onClick={(e) => setOpenReupload((pre) => !pre)}
            id="reupload-sucessfull-done"
          >
            Reupload Document
          </button>
        </div>
      </div>
      <PopUp
        display={openReupload}
        callback={setOpenReupload}
        center={true}
        uniqueId={"listProdPopup"}
        zIndex={150}
      >
        <Addnewaddress
          onclick={() => {
            setOpenReupload(!openReupload);
          }}
          key={addressData._id}
          id={addressData._id}
          cond="editAddress"
          address1={addressData?.address1}
          address2={addressData?.address2}
          phone={addressData?.phone}
          pincode={addressData?.pincode}
          document={addressData?.document}
          location={getUserCartById?.lastLocation}
        />
      </PopUp>
    </div>
  );
};

export default ReUploadDocument;
