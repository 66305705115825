import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  SUCCESS,
} from "../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

export const getBlogListReqSlice = createAsyncThunk(
  "get/admin/blog/get_all_blog",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/blog/get_all_blog`, {
        pagesize: data.pageSize,
        page: data.page,
        sort: data.sort,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);




export const createBlogReqSlice = createAsyncThunk(
  "get/user/blog/create",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/blog/create`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getBlogByIdReqSlice = createAsyncThunk(
  "get/admin/blog/readBlog",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/blog/readBlog`, {
        curId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);



export const updateBlogStatus = createAsyncThunk(
  "get/admin/blog/updateBlogStatus",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/blog/updateBlogStatus`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateBlogSlice = createAsyncThunk(
  "get/admin/blog/updateBlog",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/blog/updateBlog`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);



export const getAllUsersBlogSlice = createAsyncThunk(
  "get/public/blog/get_all_user_blog",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/public/blog/get_all_user_blog`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);



export const getAllUsersBlogByStatusSlice = createAsyncThunk(
  "get/admin/blog/approvedBlog",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/blog/approvedBlog`, {
        pagesize: data.pageSize,
        page: data.page,
        sort: data.sort,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);



export const getUserBlogByIdReqSlice = createAsyncThunk(
  "get/public/blog/readBlog",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/public/blog/readBlog`, {
        curId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);



export const postUserCommentSlice = createAsyncThunk(
  "get/user/blog/postComment",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/blog/postComment`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);





export const deleteBlogByIdSlice = createAsyncThunk(
  "post/admin/blog/deleteBlog",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/blog/deleteBlog`, {
        blogId: id
      })
      return res.data
    } catch (error) {
      return error.response.data;
    }
  }
)



// export const getApprovedBlogCountSlice = createAsyncThunk(
//   "get/admin/blog/getApprovedBlogCount",
//   async () => {
//     try {
//       const res = await axios.get(`${BASE_URL}/admin/blog/getApprovedBlogCount`)
//       return res.data
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// )






const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  updateDone: false,
  isError: false,
  filter: {},
  sort: {},
  status: {
    getBlogListReqSlice: IDLE,
    createBlogReqSlice: IDLE,
    getBlogByIdReqSlice: IDLE,
    updateBlogStatus: IDLE,
    updateBlogSlice: IDLE,
    getAllUsersBlog: IDLE,
    getUserBlogByIdReqSlice: IDLE,
    postUserCommentSlice: IDLE,
    postAdminCommentSlice: IDLE,
    deleteBlogByIdSlice: IDLE,
    getAllUsersBlogByStatusSlice: IDLE,
    // getApprovedBlogCountSlice: IDLE,
  },
  userName: "",
  userCurrentBlogData: {
    userCurrentBlogId: "",
    userCurrentBlog: {
      title: "",
      banner: "",
      description: "",
      contents: [{ heading: "", content: "" }],
      category: "",
      location: "",
      comments: [{ userRef: "", comment: "" }]
    },
  },
  data: {
    adminComment: [],
    userComment: [],
    usersAllBlog: [],
    currentBlogId: "",
    deleteBlogId: "",
    currentBlog: {
      title: "",
      banner: "",
      description: "",
      contents: [{ heading: "", content: "" }],
      category: "",
      location: "",
      comments: [{ userRef: "", comment: "" }]
    },
    req: {
      total: 0,
      data: [],
    },
    approvedBlog: {
      total: 0,
      data: [],
    }
    // approvedBlogCount: {}
  },
};

const blogReqSlice = createSlice({
  name: "blogReqSlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setCurrentBlog: (state, action) => {
      state.data.currentBlogId = action.payload;
    },
    setUserCurrentBlog: (state, action) => {
      state.userCurrentBlogData.userCurrentBlogId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get all users contactus ===============================================
      .addCase(getBlogListReqSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getBlogListReqSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.req = payload.data;
            state.status.getBlogListReqSlice = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getBlogListReqSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getBlogListReqSlice = ERROR;
      })

      // create contactus ===============================================
      .addCase(createBlogReqSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(createBlogReqSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.req = payload.data;
            state.status.createBlogReqSlice = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createBlogReqSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.createBlogReqSlice = ERROR;
      })

      // get blog by id ===============================================
      .addCase(getBlogByIdReqSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getBlogByIdReqSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.currentBlog = payload.data;
            state.status.getBlogByIdReqSlice = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getBlogByIdReqSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getBlogByIdReqSlice = ERROR;
      })

      // get blog by id ===============================================
      .addCase(updateBlogStatus.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateBlogStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.updateBlogStatus = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateBlogStatus.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.updateBlogStatus = ERROR;
      })


      // update blog by id ===============================================
      .addCase(updateBlogSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateBlogSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.currentBlog = payload.data;
            state.updateDone = !state.updateDone;
            state.status.updateBlogSlice = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateBlogSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.updateBlogSlice = ERROR;
      })

      // get all users blog ===============================================
      .addCase(getAllUsersBlogSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getAllUsersBlogSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.usersAllBlog = payload.data;
            state.status.getAllUsersBlog = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllUsersBlogSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getAllUsersBlog = ERROR;
      })

      // get users blog by id ===============================================
      .addCase(getUserBlogByIdReqSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserBlogByIdReqSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.userCurrentBlogData.userCurrentBlog = payload.data;
            state.status.getUserBlogByIdReqSlice = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserBlogByIdReqSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getUserBlogByIdReqSlice = ERROR;
      })

      //post comment by user ===============================================
      .addCase(postUserCommentSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(postUserCommentSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.userComment = payload.data;
            state.updateDone = !state.updateDone;
            state.status.postUserCommentSlice = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(postUserCommentSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.postUserCommentSlice = ERROR;
      })


      //delete blog by id ===============================================
      .addCase(deleteBlogByIdSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(deleteBlogByIdSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.deleteBlogId = payload.data;
            state.status.deleteBlogByIdSlice = FULFILLED;
            state.updateDone = !state.updateDone;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteBlogByIdSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.deleteBlogByIdSlice = ERROR;
      })


      //get blog by status ===============================================
      .addCase(getAllUsersBlogByStatusSlice.pending, (state, action) => {
        // state.status.createContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getAllUsersBlogByStatusSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.approvedBlog = payload.data;
            state.status.getAllUsersBlogByStatusSlice = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllUsersBlogByStatusSlice.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getAllUsersBlogByStatusSlice = ERROR;
      })

    // //get blog by status ===============================================
    // .addCase(getApprovedBlogCountSlice.pending, (state, action) => {
    //   // state.status.createContactUsSlice = LOADING;
    //   state.loading = true;
    // })
    // .addCase(getApprovedBlogCountSlice.fulfilled, (state, { payload }) => {
    //   switch (payload.type) {
    //     case SUCCESS:
    //       state.loading = false;
    //       state.data.approvedBlogCount = payload.data;
    //       state.status.getApprovedBlogCountSlice = FULFILLED;
    //       break;
    //     default:
    //       state.loading = false;
    //       state.isError = true;
    //       state.errorData = {
    //         message: payload.message,
    //         type: payload.type,
    //         errors: payload.errors,
    //       };
    //   }
    // })
    // .addCase(getApprovedBlogCountSlice.rejected, (state, action) => {
    //   state.loading = false;
    //   state.errorData = action.payload.error;
    //   state.status.getApprovedBlogCountSlice = ERROR;
    // });
  },
});

export default blogReqSlice.reducer;
export const { clearErrorSlice, setCurrentBlog, setUserCurrentBlog, setDeleteBlog } = blogReqSlice.actions;
