import { BASE_URL, ERROR, FULFILLED, IDLE, REJECTED_ERROR, SUCCESS } from '../../../constants/constants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from 'axios';

export const getAllLocationAsyncThunk = createAsyncThunk(
    'get/admin/location/get_all_location',
    async () => {
        try {
            const res = await axios.get(`${BASE_URL}/admin/location/get_all_location`);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }
)





const initialState = {
    loading: false,
    errorData: {
        message: "",
        type: "",
        errors: [],
    },
    updateDone: false,
    isError: false,
    status: {
        getAllLocationAsyncThunk: IDLE
    },
    data: [],
};



const locationSlice = createSlice({
    name: "locationSlice",
    initialState: initialState,
    reducers: {
        clearErrorSlice: (state, action) => {
            state.isError = false;
            state.errorData = {};
        },
    },
    extraReducers: (builder) => {
        builder
            // get all users contactus ===============================================
            .addCase(getAllLocationAsyncThunk.pending, (state, action) => {
                // state.status.getContactUsSlice = LOADING;
                state.loading = true;
            })
            .addCase(getAllLocationAsyncThunk.fulfilled, (state, { payload }) => {
                switch (payload.type) {
                    case SUCCESS:
                        state.data = payload.data;
                        state.status.getAllLocationAsyncThunk = FULFILLED;
                        state.loading = false;
                        break;
                    default:
                        state.loading = false;
                        state.isError = true;
                        state.errorData = {
                            message: payload.message,
                            type: payload.type,
                            errors: payload.errors,
                        };
                }
            })
            .addCase(getAllLocationAsyncThunk.rejected, (state, action) => {
                state.errorData = action.payload;
                state.loading = false;
                state.status.getAllLocationAsyncThunk = ERROR;
                state.isError = true
                state.errorData = REJECTED_ERROR;
            });
    }
})



export default locationSlice.reducer;
export const { clearErrorSlice } = locationSlice.actions;

