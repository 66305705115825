import "./ReelSwiper.css";

import React, { useEffect, useRef, useState } from "react";

import { PauseButton } from "../../assets/index";
import { PlayButton } from "../../assets/index";

const ReelSwiper = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(null);

  const videoRef = useRef([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState();

  const [video, setVideo] = useState(true);

  function getVerticalScrollPercentage(elm) {
    let p = elm.parentNode;
    return (
      ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100
    );
  }
  // document.onscroll = function () {
  //   let pos = Math.round(getVerticalScrollPercentage(document.body));
  //   if (pos > 20) {
  //     let slidesLength = slides.length;
  //     for (let i = 0; i < slidesLength; i++) {
  //       document.getElementsByClassName(`video-element${i}`)[0].pause();
  //     }
  //     setIsPlaying(false);
  //   }
  // };

  const showType = (url) => {
    const extension = url.toString().split(".").pop().toLowerCase();

    if (extension === "mp4") {
      return true;
    } else if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif"
    ) {
      return false;
    }
  };

  const togglePlay = (index) => {
    // console.log(videoRef);
    if (videoRef?.current[index]?.paused) {
      videoRef?.current[index]?.play();
    } else {
      videoRef?.current[index]?.pause();
    }
    setIsPlaying(!isPlaying);
  };

  // useEffect(() => {
  //   // setIsPlaying(!isPlaying);
  //   console.log(document.getElementsByClassName("video-element").paused);
  // }, []);

  useEffect(() => {
    if (nextIndex !== null && nextIndex !== currentIndex) {
      setCurrentIndex(nextIndex);
      setNextIndex(null);
    }
  }, [nextIndex, currentIndex]);

  const goToSlide = (index, slidesLength) => {
    if (
      document.getElementsByClassName(`video-element${index}`)[0].localName ===
      "video"
    ) {
      for (let i = 0; i < slidesLength; i++) {
        document.getElementsByClassName(`video-element${i}`)[0].pause();
      }
    }
    setIsPlaying(false);
    setNextIndex(index);
  };

  return (
    <div
      className={`swiper-container ${
        window.screen.width <= 576 ? "collapse " : ""
      }`}
      key={window.screen.width}
    >
      <div
        className="swiper-wrapper"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div className={`swiper-slides swiper-slide${index}`} key={index}>
            {showType(slide.video) ? (
              <div className="reel-div">
                <img
                  src={isPlaying ? PauseButton : PlayButton}
                  className="pause-play-btn"
                  onClick={() => togglePlay(index)}
                />
                <video
                  ref={(elm) => (videoRef.current[index] = elm)}
                  onClick={() => (window.location.href = slide.link)}
                  src={slide.video}
                  className={`video-element video-element${index}`}
                  loop
                />
              </div>
            ) : (
              <img
                src={slide.video}
                className={`video-element video-element${index}`}
                onClick={() => (window.location.href = slide.video)}
              />
            )}

            <div key={index} className="controls">
              {/* <button key={index} onClick={()=>togglePlay(index)}>
                {isPlaying ? <img src={Pause} /> : <img src={Play} />}
              </button> */}
            </div>
          </div>
        ))}
      </div>
      <div className="swiper-navigation">
        {slides.map((slide, index) => (
          <button
            key={index}
            className={`reel-btn  swiper-navigation-button ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => goToSlide(index, slides.length)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ReelSwiper;
