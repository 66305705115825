import { combineReducers, configureStore } from "@reduxjs/toolkit";

import AdminFrontendCoupon from "../features/adminCouponSlice/FrontendCouponSlice.js";
import adminAccess from "../features/adminAccessSlice/adminAccessSlice.js";
import adminContactUs from "../features/adminContactUsSlice/AdminContactUsSlice.js";
import adminFrontendAddon from "../features/adminFrontendSlice/adminFrontendAddonSlice.js";
import adminFrontendBanner from "../features/adminFrontendSlice/adminFrontendBannerSlice.js";
import adminFrontendBentoGrid from "../features/adminFrontendSlice/adminFrontendBentoGridSlice.js";
import adminFrontendPackage from "../features/adminFrontendackageSlice/adminFrontendPackageSlice.js";
import adminFrontendTrendingSlice from "../features/adminFrontendSlice/adminFrontendTrendingSlice.js";
import adminNotification from "../features/adminNotificationSlice/adminNotificatioSlice.js";
import blogReq from "../features/blogReqSlice.js";
import businessRequestQuery from "../features/businessRequestQuery/businessRequestQuerySlice.js";
import dashboardSlice from "../features/dashboardSlice/dashboardSlice.js";
import dataDownload from "../features/adminDataDownloadSlice/dataDownloadSlice.js";
import empInfo from "../features/empLoginSlice/empLoginSlice.js";
import faqQuestion from "../features/faqSlice/faqSlice.js";
import homePage from "../features/homePageSlice/homePageSlice.js";
import inventoryInfo from "../features/adminInventorySlice/inventorySlice.js";
import listingRequest from "../features/listingRequestInvenotry/linstingReqSlice.js";
import location from "../features/locationSlice/locationSlice.js";
import logisticSlice from "../features/logisticSlice/logisticSlice.js";
import orderInfo from "../features/orderSlice/orderSlice.js";
import payments from "../features/paymentSlice/paymentSlice.js";
import preLaunchAuth from "../features/preLaunchSlice/preLaunchSlice.js";
// import thunk from "redux-thunk";
import userFetch from "../features/adminUsersSlice/adminUsersSlice.js";
import userInfo from "../features/loginSlice/loginSlice.js";
import waitlistSlice from "../features/waitlistSlice/waitlistSlice.js";

const rootReducer = combineReducers({
  userInfo: userInfo,
  homePage: homePage,
  empInfo: empInfo,
  adminAccess: adminAccess,
  userFetch: userFetch,
  adminFrontendAddon: adminFrontendAddon,
  adminFrontendCoupon: AdminFrontendCoupon,
  adminFrontendBanner: adminFrontendBanner,
  adminFrontendTrending: adminFrontendTrendingSlice,
  adminContactUs: adminContactUs,
  businessRequestQuery: businessRequestQuery,
  adminFrontendPackage: adminFrontendPackage,
  faqQuestion: faqQuestion,
  blogReq: blogReq,
  dataDownload: dataDownload,
  inventoryInfo: inventoryInfo,
  orderInfo: orderInfo,
  location: location,
  listingRequest: listingRequest,
  payments: payments,
  adminNotification: adminNotification,
  preLaunchAuth: preLaunchAuth,
  waitlistSlice: waitlistSlice,
  dashboardSlice: dashboardSlice,
  logisticSlice: logisticSlice,
  adminFrontendBentoGrid:adminFrontendBentoGrid
});

export default configureStore({
  reducer: {
    rootReducer: rootReducer,
  },
  // to disable react-redux-devtools in production
  // devTools: false,
  // middleware: [thunk],
});
