import "./blogcard.css";

import { READ_BLOG } from "../../constants/links";
import React from "react";
import { useNavigate } from "react-router-dom";

const Blogcard = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="blog-card"
        onClick={() => {
          // console.log(READ_BLOG(props?.blogData?._id));
          navigate(READ_BLOG(props?.blogData?.customId));
        }}
      >
        <div className="blog-card-img">
          <img src={props.img} alt="blog" />
        </div>

        <p id="blog-card-heading">
          {`${props.heading}`.split(" ").slice(0, 5).join(" ")} . . .
        </p>

        <div className="blog-card-catogery-container">
          <p>{`${props.category}`.toUpperCase()}</p>
        </div>

        <div className="blog-card-date-time-container">
          <p>By {`${props.name}`.split(" ").slice(0, 1).join(" ")}</p>
          <p>{props.date}</p>
        </div>
      </div>
    </>
  );
};

export default Blogcard;
