import {
  // empLogout,
  // setSessionLogoutError,
} from "../redux/features/empLoginSlice/empLoginSlice";
import { useDispatch, useSelector } from "react-redux";

// import { ADMIN_LOGIN } from "../constants/links";
// import audio from "../assets/Audio/audio.mp3";
import socket from "./socketConnection";
import { stackNotification } from "../redux/features/adminNotificationSlice/adminNotificatioSlice";
import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

const useSocketSetup = () => {
  const userID = useSelector((state) => state.rootReducer.empInfo.data.empId);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  function playmusic() {
    // new Audio(audio).play();
  }

  // function logout() {
  //   dispatch(empLogout())
  //     .unwrap()
  //     .then((data) => {
  //       navigate(ADMIN_LOGIN);
  //     });
  // }

  useEffect(() => {
    if (userID) {
      socket.connect();
      socket.on("connect", () => {});
      // This is used to store the socket id against user id
      socket.emit("clientconnected", { id: userID, value: { type: "user" } });
      // socket.emit("aliveclient", socket.id);
      socket.on("notification", (data) => {
        // dispatching the data to the global Notification state
        playmusic();
        // notification sound pending unsolved souds pings every time app rerenders
        dispatch(stackNotification({ data: data }));
        // Add notification logic here
      });
      socket.on("session_logout", (data) => {
        // logout();
        // dispatch(setSessionLogoutError(data));
      });
    }
  }, [userID]);
};

export default useSocketSetup;
