import "./mylistingcard.css";

import {
  OrderAccepted,
  PaymentCard,
  Pc,
  Playstn,
  RightArrow,
  SmallCross,
  Threedots,
  Upi,
} from "../../assets";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import Mylistingagreement from "../Mylistingagreement/mylistingagreement";
import Mylistingcarddropdown from "../Mylistingcarddropdown/mylistingcarddropdown";
import PopUp from "../PopUp/PopUp";
import { useEffect } from "react";
import { usePopper } from "react-popper";

function Productconfirm(props) {
  const [isClick, setIsClick] = useState(false);
  const status = props.status;
  if (status === "Approval Pending") {
    return (
      <>
        <div className="mylisting-prod-confirm-container">
          <div className="mylisting-prod-confirm-content">
            <p id="cross-img">
              <img
                src={SmallCross}
                alt="smallcross"
                onClick={props.onclick}
                style={{ cursor: "pointer", width: "1.5rem", height: "1.5rem" }}
              />
            </p>
            <img src={OrderAccepted} alt="orderaccept" />
            <p id="prod-select-text">Product Selected</p>
            <p id="prod-select-desc">
              Hooray! Yout Product has been selected for Lending. Please fill
              the agreement and payment details to proceed.
            </p>
            <button onClick={() => setIsClick(!isClick)}>Proceed</button>
            {isClick && <Mylistingagreement onclick={props.onclick} />}
          </div>
        </div>
      </>
    );
  }
}

const Mylistingcard = (props) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [refOption, setRefOption] = useState();
  const [popOption, setPopOption] = useState();
  const RequestData = props.elm;

  useEffect(() => {
    if (isClick) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  const popUpParams = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  };
  const popper = usePopper(refOption, popOption, popUpParams);
  return (
    <>
      <div className="mylisting-card">
        <div className="mylisting-card-con">
          <div className="mylisting-card-prod-img-container">
            <img src={props.img} alt="playstation" />
          </div>

          <div className="mylisting-prod-name-container">
            <p>{RequestData.productInfo?.name}</p>
            <div
              className={props.class}
              style={{
                cursor: "pointer",
                color: props.status == "REJECTED" ? "#D34E4E" : "#DFDFDF",
              }}
              onClick={() => setIsClick(!isClick)}
            >
              <div className={props.dot}></div>
              <p
                style={{
                  color: props.status == "REJECTED" ? "#D34E4E" : "#DFDFDF",
                }}
              >
                {props.status}
              </p>
            </div>
            {isClick && (
              <Productconfirm
                status="Approval Pending"
                onclick={() => setIsClick(!isClick)}
              />
            )}
          </div>

          <div className="mylisting-prod-desc-container">
            <p id="my-listing-request-renting-text">
              <span>Renting Cost</span> -{" "}
            </p>
            <p id="my-listing-request-start-d-text">
              <span>Start Date</span>21/02/2022
            </p>
            <p id="my-listing-request-end-d-text">
              <span>End Date</span>21/06/2022
            </p>
          </div>
          <img
            ref={setRefOption}
            id="listing-request-option-image"
            src={Threedots}
            alt="threedots"
            style={{ width: ".25rem", height: "1.5rem" }}
            onClick={() => setIsDropdown(!isDropdown)}
          />

          <PopUp display={isDropdown} callback={setIsDropdown}>
            <div
              ref={setPopOption}
              style={{ ...popper?.styles?.popper }}
              {...popper.attributes}
            >
              <Mylistingcarddropdown
                listingReqData={props}
                onclick={() => setIsDropdown(!isDropdown)}
              />
            </div>
          </PopUp>
          {/* {isDropdown && (
            <Mylistingcarddropdown
              listingReqData={props}
              onclick={() => setIsDropdown(!isDropdown)}
            />
          )} */}
        </div>
      </div>
      {props.agreeContainer && (
        <>
          <div
            className={props.agreeContainer}
            onClick={() => setIsClick(!isClick)}
            style={{ cursor: "pointer" }}
          >
            <p>
              Enter your bank details and fill the agreement to proceed or{" "}
              <span>Contact Us</span>
            </p>
          </div>
          {isClick && (
            <Mylistingagreement onclick={() => setIsClick(!isClick)} />
          )}
        </>
      )}
    </>
  );
  // }
};

export default Mylistingcard;
