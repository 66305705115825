import "./Adminfrontendtrendingtag.css";
import "../Adminfrontendcoupons/adminfrontendcoupons.css";
import "../Adminfrontendeditaddons/adminfrontendeditaddons.css";
import "../Adminfrontendbanner/adminfrontendbanner.css";

import * as Yup from "yup";

import { FULFILLED, SUCCESS } from "../../../constants/constants";
import { FailureAlert, LoadingPage } from "../../../components";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { Option, RightNavigation } from "../../../assets";
import {
  addTagThunk,
  clearErrorSlice,
  deleteTagThunk,
  editTagThunk,
  getAllTagThunk,
} from "../../../redux/features/adminFrontendSlice/adminFrontendTrendingSlice";
import { useDispatch, useSelector } from "react-redux";

import { ADMIN_FRONTEND } from "../../../constants/links";
import PopUp from "../../../components/PopUp/PopUp";
import React from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import { usePopper } from "react-popper";
import { useState } from "react";

const Adminfrontendtrendingtag = () => {
  const [editOn, setEditOn] = useState(false);
  const [beforeEdit, setBeforeEdit] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  // constant error
  const isLoading = useSelector(
    (state) => state.rootReducer.adminFrontendTrending.loading
  );
  const customAlert = useSelector(
    (state) => state.rootReducer.adminFrontendTrending.isError
  );
  const error = useSelector(
    (state) => state.rootReducer.adminFrontendTrending.errorData
  );
  const updateDone = useSelector(
    (state) => state.rootReducer.adminFrontendTrending.updateDone
  );

  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  const tag = useSelector(
    (state) => state.rootReducer.adminFrontendTrending.data.trending
  );

  const removeTag = (tagId) => {
    const data = {
      tagId: tagId,
    };
    dispatch(deleteTagThunk(data));
  };

  const setData = (data) => {
    let params = Object.fromEntries(searchParams);
    params["tagId"] = data?._id;
    setSearchParams(createSearchParams(params));
    setBeforeEdit(false);
    trendingForm.values.name = data?.name;
    trendingForm.values.background = data?.background;
    trendingForm.values.location = data?.location;
  };

  const editTag = () => {
    const data = {
      name: trendingForm.values.name,
      background: trendingForm.values.background,
      location: trendingForm.values.location,
      tagId: searchParams.get("tagId"),
    };
    dispatch(editTagThunk(data)).then((data) => {
      if (data.payload.type === SUCCESS) {
        reset();
        setEditOn(false);
      }
    });
  };

  const trendingForm = useFormik({
    initialValues: {
      name: "",
      background: "",
      location: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().min(1, "Min length is 1").required("Name is required"),
      background: Yup.string().required("Background color is required"),
      location: Yup.string()
        .min(3, "Min length is 3")
        .required("Location is required"),
    }),
    onSubmit: (values) => {
      dispatch(addTagThunk(values)).then((data) => {
        if (data.payload.type == SUCCESS) {
          reset();
        }
      });
    },
  });

  const reset = () => {
    trendingForm.values.name = "";
    trendingForm.values.background = "";
    trendingForm.values.location = "";
    setBeforeEdit(true);
  };

  useEffect(() => {
    dispatch(getAllTagThunk());
  }, [updateDone]);

  const Tr = ({ data, idx }) => {
    const [option, setOption] = useState(false);
    const [refElmOptions, SetRefElmOptions] = useState();
    const [popElmOptions, setPopElmOptions] = useState();
    const popUpParams = {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 0],
          },
        },
      ],
    };

    const optionsPop = usePopper(refElmOptions, popElmOptions, popUpParams);
    return (
      <tr>
        <td style={{ width: "20%" }}>{idx + 1}</td>
        <td style={{ width: "50%" }}>
          <div className="admin-frontend-addons-img-outer-container">
            {data?.name}
          </div>
        </td>
        <td style={{ width: "50%" }}>
          <div className="color-box">
            <div style={{ background: `${data?.background}` }}></div>
            {data?.background.length > 15
              ? data?.background.slice(0, 15)
              : data?.background}
          </div>
        </td>
        <td style={{ width: "50%" }}>{data?.location}</td>
        <td
          style={{ width: "30%" }}
          id="remove-text"
          onClick={() => {
            setOption(!option);
          }}
          ref={SetRefElmOptions}
        >
          <img
            src={Option}
            alt="Remove"
            onClick={() => {
              setOption(!option);
            }}
          />
          <PopUp
            display={option}
            callback={setOption}
            uniqueId={"edit-pop-up"}
            fixed={true}
          >
            <div
              ref={setPopElmOptions}
              style={{ ...optionsPop.styles.popper }}
              {...optionsPop.attributes}
              className="banner-popup"
            >
              <div
                onClick={() => {
                  setEditOn(true);
                  setData(data);
                }}
                className="edit-banner"
              >
                Edit Tag
              </div>
              <div
                className="delete-banner"
                onClick={() => removeTag(data?._id)}
              >
                Delete Tag
              </div>
            </div>
          </PopUp>
        </td>
      </tr>
    );
  };

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {isLoading && <LoadingPage />}
      <div className="admin-frontend-coupons-details-inner-content">
        <div className="admin-frontend-coupons-details-link-outer-container">
          <div className="admin-frontend-coupons-details-link-container">
            <Link to={ADMIN_FRONTEND}>Frontend</Link>
            <img src={RightNavigation} alt="rightnavigation" />
            <p>Trending Tag</p>
          </div>
        </div>

        <div className="admin-coupons-records-container">
          <p>Trending Tags</p>
          <div className="admin-product-avail-records-table-container">
            <table>
              <thead style={{ padding: "0rem 0rem" }}>
                <tr>
                  <th style={{ borderTopLeftRadius: "6px", width: "20%" }}>
                    Sr.no
                  </th>
                  <th style={{ width: "50%" }}>Name</th>
                  <th style={{ width: "50%" }}>Background color</th>
                  <th style={{ width: "50%" }}>City</th>
                  <th
                    style={{ borderTopRightRadius: "6px", width: "30%" }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                <div
                  className="admin-product-avail-records-table-content"
                  style={{
                    paddingLeft: "0rem",
                    paddingRight: "0rem",
                  }}
                >
                  <div className="admin-product-avail-records-table-scroll-content">
                    {tag.map((data, idx) => (
                      <Tr data={data} idx={idx} />
                    ))}
                    <tr>
                      <td style={{ width: "20%" }}></td>
                      <td style={{ width: "50%" }}>
                        <div
                          className="admin-frontend-addons-img"
                          style={{ width: "100%" }}
                        >
                          <div className="admin-access-error-label-new">
                            <label>
                              {trendingForm.errors.name &&
                              trendingForm.touched.name
                                ? trendingForm.errors.name
                                : "\u00A0"}
                            </label>
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div
                          className="admin-frontend-addons-img"
                          style={{ width: "100%" }}
                        >
                          <div className="admin-access-error-label-new">
                            <label>
                              {trendingForm.errors.background &&
                              trendingForm.touched.background
                                ? trendingForm.errors.background
                                : "\u00A0"}
                            </label>
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div
                          className="admin-frontend-addons-img"
                          style={{ width: "100%" }}
                        >
                          <div className="admin-access-error-label-new">
                            <label>
                              {trendingForm.errors.location &&
                              trendingForm.touched.location
                                ? trendingForm.errors.location
                                : "\u00A0"}
                            </label>
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "30%" }}></td>
                    </tr>
                    <tr>
                      <td style={{ width: "20%" }}>-</td>
                      <td style={{ width: "50%", paddingLeft: "0rem" }}>
                        <div className="add-addon-input">
                          <input
                            type="text"
                            placeholder="Tag name"
                            value={trendingForm.values.name}
                            id="name"
                            onChange={(e) => {
                              trendingForm.handleChange(e);
                            }}
                            onBlur={trendingForm.handleBlur}
                          />
                        </div>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div className="background">
                          <input
                            type="color"
                            id="background"
                            value={trendingForm.values.background}
                            onChange={(e) => {
                              trendingForm.handleChange(e);
                            }}
                            onBlur={trendingForm.handleBlur}
                          />
                          <input
                            type="text"
                            id="background"
                            value={trendingForm.values.background}
                            placeholder="#000000"
                            onChange={(e) => {
                              trendingForm.handleChange(e);
                            }}
                            onBlur={trendingForm.handleBlur}
                          />
                        </div>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div className="add-addon-input">
                          <input
                            type="text"
                            placeholder="location"
                            id="location"
                            value={trendingForm.values.location}
                            onChange={(e) => {
                              trendingForm.handleChange(e);
                            }}
                            onBlur={trendingForm.handleBlur}
                          />
                        </div>
                      </td>
                      <td
                        style={{
                          width: "30%",
                          color: "rgb(41, 185, 146)",
                          cursor: "pointer",
                        }}
                      >
                        {editOn ? (
                          <p
                            onClick={() => {
                              editTag();
                            }}
                          >
                            save changes
                          </p>
                        ) : (
                          <p onClick={trendingForm.handleSubmit}>Add</p>
                        )}
                      </td>
                    </tr>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
};

export default Adminfrontendtrendingtag;
