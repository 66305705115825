import "./addsubcatogerypopup.css";

import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";

import { PopUpClose } from "../../../assets";
import React from "react";
import { SUCCESS } from "../../../constants/constants";
import { createSubCategory } from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { useFormik } from "formik";

const Addsubcatogerypopup = (props) => {
  // Constant Error
  const dispatch = useDispatch();
  const superCategories = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allSuperCategories
  );

  const loading = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.loading
  );

  // ------------ Error end
  // const [isDropdown, setIsdropdown] = useState(false);
  const subCategoryForm = useFormik({
    initialValues: {
      // category: activeCategory,
      category: "",
      subCategory: "",
    },
    validationSchema: Yup.object({
      subCategory: Yup.string("Must be a string")
        .min(1, "Min length is 1")
        .max(30, "Max length is 30")
        .required("Sub category is required"),
    }),
    onSubmit: (values) => {
      if (!subCategoryForm) {
        return;
      }
      console.log(values.gst);
      dispatch(
        createSubCategory({
          category: values.category,
          subCategory: values.subCategory,
          gst:values.gst,
        })
      ).then((data) => {
        if (data.payload.type === SUCCESS) {
          props.onclick();
        }
      });
    },
  });

  return (
    <>
      {/* <div className="admin-req-create-new-subcatogery-container"> */}
      <div className="admin-req-create-new-subcatogery-content">
        <img src={PopUpClose} alt="popupclose" onClick={props.onclick} />
        <p>Add Sub-category Name</p>
        <div className="add-sub-category-select">
          <select
            onChange={subCategoryForm.handleChange}
            onBlur={subCategoryForm.handleBlur}
            value={
              !!subCategoryForm.values?.category
                ? subCategoryForm.values?.category
                : "Select category"
            }
            name="category"
          >
            {!!!subCategoryForm.values?.category && (
              <option value={""}>Select Category</option>
            )}
            {superCategories.map((elm) => {
              return (
                <option value={elm.category}>
                  {elm.category?.[0] + elm.category?.slice(1).toLowerCase()}
                </option>
              );
            })}
          </select>
        </div>
        <label className="create-new-sub-category-error-label">
          {subCategoryForm.errors.subCategory &&
          subCategoryForm.touched.subCategory
            ? subCategoryForm.errors.subCategory
            : "\u00A0"}
        </label>
        <input
          type="text"
          value={subCategoryForm.values.subCategory}
          onChange={subCategoryForm.handleChange}
          onBlur={subCategoryForm.handleBlur}
          name="subCategory"
          id=""
          placeholder="Enter sub-catogery name"
        />
        <label className="create-new-sub-category-error-label" for="gst">
          {subCategoryForm.errors.gst &&
          subCategoryForm.touched.gst
            ? subCategoryForm.errors.gst
            : "\u00A0"}
        </label>
        <input
          type="number"
          value={subCategoryForm.values.gst}
          onChange={subCategoryForm.handleChange}
          onBlur={subCategoryForm.handleBlur}
          name="gst"
          id="gst"
          placeholder="Enter GST in Percentage"
        />

        <button
          onClick={() => {
            subCategoryForm.handleSubmit();
          }}
          disabled={loading}
          id="add-subcat-button"
        >
          {!loading && "Create"}
        </button>
      </div>
      {/* </div> */}
    </>
  );
};

export default Addsubcatogerypopup;
