import React, { useState } from 'react'
import './Explore.css'
import { Link } from 'react-router-dom';

const Explore = ({isOpen,setIsOpen}) => {
//   const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="navigation">
    <ul className={`${isOpen ? 'open' : 'd-none'}`}>
      <li>
        <Link to="/dealhouse/gamingroom" onClick={()=>{setIsOpen(!isOpen)}}>Gaming Room</Link>
      </li>
      <li>
        <Link to="/dealhouse/bedroom" onClick={()=>{setIsOpen(!isOpen)}}>Bedroom</Link>
      </li>
      <li>
        <Link to="/dealhouse/studioroom" onClick={()=>{setIsOpen(!isOpen)}}>Studio</Link>
      </li>
      {/* Add more links here if needed */}
    </ul>
    <button onClick={() => setIsOpen(!isOpen)}>Explore</button>
  </div>
  )
}

export default Explore