import "./loading.css";

import React, { useEffect, useState } from "react";

const Loading = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 200);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-base-div">
          <div className="loading-inner-content">
            <div className="loading-bar">
              <div className="loading-line-low"></div>
              <div className="loading-line-High"> </div>
            </div>
          </div>
          <div className="loadin-text">
            <p id="loading-text"> Loading </p>

            <p className="point-loading1">.</p>
            <p className="point-loading2">.</p>
            <p className="point-loading3">.</p>
          </div>
        </div>
      )}
      {!isLoading && <div></div>}
    </>
  );
};

export default Loading;
