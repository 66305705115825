import React, { useState } from "react";
import "./Bedroom.css";
import DealProduct from "../../../components/dealhouseProduct/DealProduct";
import { Link } from "react-router-dom";
import Explore from "../Explore";

const Bedroom = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section>
      <div className={`bedroom-background-container ${isOpen?"blur":""}`} >

        <DealProduct prodTitle="Playstation 5" top="32%" left="27.7%" navLink="/dealhouse/gamingroom" />

        <DealProduct prodTitle="Go Pro" top="31%" left="9%" navLink="/dealhouse/gamingroom" />
        
        <DealProduct prodTitle="Mini Speakers" top="27%" left="44%" navLink="/dealhouse/gamingroom" />

        <DealProduct prodTitle="Sony DJ Speakers" top="26%" left="48.5%" navLink="/dealhouse/gamingroom" />

        <DealProduct prodTitle="Jordan Shoes" top="40%" left="52%" navLink="/dealhouse/gamingroom" />

        <DealProduct prodTitle="Projecter" top="46%" left="49.5%" navLink="/dealhouse/gamingroom" />

        <DealProduct prodTitle="Ipad Pro" top="53%" left="49%" navLink="/dealhouse/gamingroom" />

        <DealProduct prodTitle="MacBook M3 Pro" top="58%" left="58%" navLink="/dealhouse/gamingroom" />

        <DealProduct prodTitle="PlayStation Controller" top="67%" left="66%" navLink="/dealhouse/gamingroom" />
        
      </div>
      <Explore isOpen={isOpen} setIsOpen={setIsOpen}/>
    </section>
  );
};

export default Bedroom;
