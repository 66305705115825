import "./admincreatepackage.css";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";

import {
  Bluerightnavigation,
  Calender,
  Cross,
  Dcamera,
  Downtitle,
  Leftar,
  PopUpClose,
  Rightar,
  Uploaddone,
  Uploadprodimg,
  Uptitle,
} from "../../../assets";
import {
  CustomSelectCatogery,
  DatePicker,
  FailureAlert,
  LoadingPage,
} from "../..";
import { ErrorMessage, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  clearErrorSlice,
  createPackageSlice,
  getAdminPrice,
  getAllAddons,
  getAllCategories,
  setError,
  setSelectedCategory,
} from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { dateFormatter, getDuration } from "../../../utils/displayUtils";
import { useDispatch, useSelector } from "react-redux";

import Customselectsubcatogery from "../CustomSelectSubCatogery/customselectsubcatogery";
import PopUp from "../../PopUp/PopUp";
import { SUCCESS } from "../../../constants/constants";
import { getAllAddonsList } from "../../../redux/features/adminFrontendSlice/adminFrontendAddonSlice";
import { usePopper } from "react-popper";

const Admincreatepackage = (props) => {
  const [isProceed, setIsProceed] = useState(false);
  const [isProceed2, setIsProceed2] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isPackagePreview, setIsPackagePreview] = useState(false);
  const [isFaqs, setIsFaqs] = useState(false);
  const [createdPackage, setCreatedPackage] = useState(false);
  const [tags, setTags] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [imgTags, setImgTags] = useState([]);
  const [imgTouched, setImgTouched] = useState(false);
  const [boxTags, setBoxTags] = useState([]);
  // const [rentedTags, setRentedTags] = useState([]);
  const [freeAddonTags, setFreeAddonTags] = useState([]);
  const [paidAddonTags, setPaidAddonTags] = useState([]);
  const [faqTags, setFaqTags] = useState([]);
  const [inTheBoxTags, setInTheBoxTags] = useState([]);
  const [inTheBoxName, setInTheBoxName] = useState("");
  const [inTheBoxImg, setInTheBoxImg] = useState({});
  const [packageImages, setPackageImages] = useState([]);
  const [activeFaq, setActiveFaq] = useState(-1);
  const [imageMoreContaier, setImageMoreContaier] = useState(false);
  const [freeAddonsList, setFreeAddonsList] = useState([]);
  const [paidAddonsList, setPaidAddonsList] = useState([]);
  const [outOfStock, setOutOfStock] = useState(false);
  const [preBooking, setPreBooking] = useState(false);

  const today = new Date();
  const formattedDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const [isDate, setIsDate] = useState(false);
  const [dateValue, setDateValue] = useState(formattedDate);
  const [isPopup, setPopup] = useState(false);
  const [popElm, setPopElm] = useState();

  const [refElm, setRefElm] = useState();

  const popUpParamsBtmEnd = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };

  const popper = usePopper(refElm, popElm, popUpParamsBtmEnd);

  function addSpecs(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setSpecs([...specs, value]);
    e.target.value = "";
  }

  function addInBoxItem(value) {
    setInTheBoxTags([...inTheBoxTags, value]);
  }

  function addFaqQuestion(value) {
    setFaqTags([...faqTags, value]);
  }

  function removeFaqQuestion(value) {
    setFaqTags(faqTags.filter((elm, idx) => idx != value));
  }

  function addFreeAddons(value) {
    setFreeAddonTags([...freeAddonTags, JSON.parse(value)]);
  }
  function addPaidAddons(value) {
    setPaidAddonTags([...paidAddonTags, JSON.parse(value)]);
  }

  function removeSpecs(index) {
    setSpecs(specs.filter((el, i) => i !== index));
  }

  function removeFreeAddonTags(index) {
    setFreeAddonTags(freeAddonTags.filter((el, i) => i !== index));
  }
  function removePaidAddonTags(index) {
    setPaidAddonTags(paidAddonTags.filter((el, i) => i !== index));
  }

  const addImgTag = (e) => {
    const imgFile = e.target.files;
    const imgFilesArray = Array.from(imgFile);
    const imagesArray = imgFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    for (const value of e.target.files) {
      packageImages.push(value);
    }
    // setPackageImages(e.target.files); //for form data
    setImgTags([...imgTags, ...imagesArray]); //for client side display purpose
  };

  const removeImgTag = (index) => {
    setImgTags(imgTags.filter((el, i) => i !== index));
    setPackageImages(packageImages.filter((el, i) => i !== index));
    // setImgTags([]);
    // setPackageImages({});
  };

  const addBoxTag = (e) => {
    //========================================================
    //Continue here add in the box pending
    //images to be processed
    setInTheBoxImg(e.target.files);
  };

  function addInTheBoxItems() {
    if (inTheBoxName) {
      addInBoxItem({
        name: inTheBoxName,
        image: inTheBoxImg[0],
      });
      const imgFile = inTheBoxImg;
      const imgFilesArray = Array.from(imgFile);
      const imagesArray = imgFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      setBoxTags((previousArray) => previousArray.concat(imagesArray));
      setInTheBoxName("");
      setInTheBoxImg({});
    } else {
    }
  }

  const removeBoxTag = (index) => {
    setBoxTags(boxTags.filter((el, i) => i !== index));
    setInTheBoxTags(inTheBoxTags.filter((el, i) => i !== index));
  };

  const activeCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.activeCategory
  );
  const superCategories = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allSuperCategories
  );
  const allCategories = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allCategories
  );

  //---------------------------------------------------------
  const dispatch = useDispatch();
  // Constant Error
  const loading = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.loading
  );
  const customAlert = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.isError
  );
  const error = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.errorData
  );
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  // ------------ Error end

  const selectedCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.form.selectedCategory
  );
  const updateDone = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.updateDone
  );
  //Formik form -================================================

  const formCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.form.selectedCategory
  );
  const formSubCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.form.selectedSubCategory
  );
  const allAddons = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allAddons
  );
  const fetchedPrices = 0;

  const addonLists = useSelector(
    (state) => state.rootReducer.adminFrontendAddon.data?.addonLists
  );

  const activeCategoryData = superCategories.find(
    (category) => category.category === activeCategory
  );

  const gstValue = formCategory.gst ? formCategory.gst : 18;
  const packageForm = useFormik({
    initialValues: {
      packageName: "",
      dilveryInDays: "",
      // category: activeCategory,
      // subCategory: "",
      summary: "",
      rentAmount: "",
      rentDuration: "1",
      noOfTimesProductUsed: "",
      faqQuestion: "",
      faqAnswer: "",
      customPlan1: "1",
      customPlan2: "3",
      customPlan3: "7",
      customPlan4: "30",
      customPlan5: "90",
      customPlan6: "180",
      customPlan1Price: "",
      customPlan2Price: "",
      customPlan3Price: "",
      customPlan4Price: "",
      customPlan5Price: "",
      customPlan6Price: "",
      packagePrice: "",
      //
      // products: [],
      images: [],
      customAmount: [],
      faq: [],
      specs: "",
      inBox: [],
      frequentlyRented: [],
      selfPickup: false,
      bannerImages: [],
      packageWeight: "",
      isOutOfStock: outOfStock,
      isPreBooking: preBooking,
      preBooking: "",
      gst: gstValue,
    },
    validationSchema: Yup.object({
      packageName: Yup.string("Must be a string")
        .min(3, "Min length is 3")
        .max(100, "Max length is 100")
        .required("Package name is required"),
      dilveryInDays: Yup.number("Must be a number")
        .min(0, "Cannot be negative")
        .required("Delivery in days is required"),
      // rentAmount: Yup.number("Must be a number")
      //   .min(0, "Cannot be negative")
      //   .required("Rent amount is required"),
      rentDuration: Yup.number("Must be a number")
        .min(0, "Cannot be negative")
        .required("Rent duration is required"),
      noOfTimesProductUsed: Yup.number("Must be a number")
        .min(0, "Cannot be negative")
        .required("Number of times package used is required"),
      summary: Yup.string("Must be a string")
        .min(5, "Min length is 5")
        .max(500, "Max length is 500")
        .required("Package summary is required"),
      gst: Yup.number("Must be a number").required("GST must be a number"),
      // customPlan1: Yup.string().required("Duration is required"),
      // customPlan1Price: Yup.number("Must be a number")
      //   .min(0, "Cannot be negative")
      //   .required("Price is required"),
      // customPlan2: Yup.string().required("Duration is required"),
      // customPlan2Price: Yup.number("Must be a number")
      //   .min(0, "Cannot be negative")
      //   .required("Price is required"),
      // customPlan3: Yup.string().required("Duration is required"),
      // customPlan3Price: Yup.number("Must be a number")
      //   .min(0, "Cannot be negative")
      //   .required("Price is required"),
      // customPlan4: Yup.string().required("Duration is required"),
      // customPlan4Price: Yup.number("Must be a number")
      //   .min(0, "Cannot be negative")
      //   .required("Price is required"),
      // customPlan5: Yup.string().required("Duration is required"),
      // customPlan5Price: Yup.number("Must be a number")
      //   .min(0, "Cannot be negative")
      //   .required("Price is required"),
      // customPlan6: Yup.string().required("Duration is required"),
      // customPlan6Price: Yup.number("Must be a number")
      //   .min(0, "Cannot be negative")
      //   .required("Price is required"),
      packagePrice: Yup.number("Must be a number")
        .min(0, "Cannot be negative")
        .required("Price is required"),
      packageWeight: Yup.string("Must be a String")
        .min(1, "Min length is 1")
        .max(1, "Max length is 1")
        .required("Package Weight is required"),
    }),
    onSubmit: (values) => {
      // ** Note check for validations ======================================

      let formData = new FormData();
      formData.append("name", values.packageName);
      formData.append("deliveryDays", values.dilveryInDays);
      formData.append("category", formCategory);
      formData.append("subCategory", formSubCategory);
      formData.append(
        "rentAmount",
        fetchedPrices[packageForm.values.rentDuration]
      );
      formData.append("rentDuration", values.rentDuration);
      let freeCount = 0,
        paidCount = 0;
      freeAddonTags.forEach((elm) => {
        elm.list.forEach((el, id) => {
          formData.append(`freeAddons[${freeCount++}]`, el);
        });
      });
      paidAddonTags.forEach((elm, idx) => {
        elm.list.forEach((el, id) => {
          formData.append(`paidAddons[${paidCount++}]`, el);
        });
      });
      formData.append("noOfTimesProductUsed", values.noOfTimesProductUsed);
      formData.append("summary", values.summary);
      specs.forEach((elm, idx) => {
        formData.append(`specs[${idx}]`, elm);
      });
      faqTags.forEach((elm, idx) => {
        formData.append(`faqs[${idx}][question]`, elm.question);
        formData.append(`faqs[${idx}][answer]`, elm.answer);
      });
      inTheBoxTags.forEach((elm, idx) => {
        formData.append(`inBox[${idx}]`, elm.name);
      });
      if (isAdd && parseInt(packageForm.values.customPlan1)) {
        formData.append(
          `customPlans[0][duration]`,
          packageForm.values.customPlan1
        );
        formData.append(
          `customPlans[0][price]`,
          packageForm.values.customPlan1Price
        );
      }
      if (isAdd1 && parseInt(packageForm.values.customPlan2)) {
        formData.append(
          `customPlans[1][duration]`,
          packageForm.values.customPlan2
        );
        formData.append(
          `customPlans[1][price]`,
          // fetchedPrices[packageForm.values.customPlan2]
          packageForm.values.customPlan2Price
        );
      }
      if (isAdd2 && parseInt(packageForm.values.customPlan3)) {
        formData.append(
          `customPlans[2][duration]`,
          packageForm.values.customPlan3
        );
        formData.append(
          `customPlans[2][price]`,
          packageForm.values.customPlan3Price
        );
      }
      if (isAdd3 && parseInt(packageForm.values.customPlan4)) {
        formData.append(
          `customPlans[3][duration]`,
          packageForm.values.customPlan4
        );
        formData.append(
          `customPlans[3][price]`,
          fetchedPrices[packageForm.values.customPlan4]
        );
      }
      if (isAdd4 && parseInt(packageForm.values.customPlan5)) {
        formData.append(
          `customPlans[4][duration]`,
          packageForm.values.customPlan5
        );
        formData.append(
          `customPlans[4][price]`,
          fetchedPrices[packageForm.values.customPlan5]
        );
      }
      if (isAdd5 && parseInt(packageForm.values.customPlan6)) {
        formData.append(
          `customPlans[5][duration]`,
          packageForm.values.customPlan6
        );
        formData.append(
          `customPlans[5][price]`,
          fetchedPrices[packageForm.values.customPlan6]
        );
      }
      formData.append(`selfPickUpOption`, packageForm.values.selfPickup);
      formData.append(`packagePrice`, packageForm.values.packagePrice);
      formData.append(`isOutOfStock`, outOfStock);
      formData.append(`isPreBooking`, preBooking);
      formData.append("preBookingDate", dateValue);
      formData.append("gst", values.gst);

      //images

      imgTags.forEach((elm, idx) => {
        formData.append(`images`, packageImages[idx]);
      });
      inTheBoxTags.forEach((elm, idx) => {
        formData.append(`inBoxImage`, elm.image);
      });

      formData.append("packageWeight", packageForm.values.packageWeight);
      // tags.length > 0 &&
      // tags.length <= 20
      if (
        packageImages.length > 0 &&
        packageImages.length < 10 &&
        specs.length >= 2 &&
        specs.length <= 20
      ) {
        dispatch(createPackageSlice(formData)).then((data) => {
          if (data.payload.type == SUCCESS) {
            setCreatedPackage(!createdPackage);
          } else {
            toast.error("error submitting form");
          }
        });
      } else {
        dispatch(
          setError({
            message: "images, specs or tags length Error",
            type: "Validation Error",
            errors: [
              { 1: "packageImages length should be from 1 to 10" },
              { 2: "specs length should be from 2 to 20" },
              { 3: "tags length should be from 1 to 20" },
            ],
          })
        );
        toast.error("error submitting form");
      }
    },
  });

  // const  errorPopup=()=>{
  //   if(!packageForm.isValid){
  //     toast.error(`Please fix the following: </br> ${packageForm.errors.dilveryInDays} </br> ${packageForm.errors.noOfTimesProductUsed}</br> ${packageForm.errors.packageName}\n ${packageForm.errors.packagePrice}\n ${packageForm.errors.packageWeight}\n `);
  //   }
  // }
  // useEffect(()=>{
  //   toast.error(`Please fix the following: </br> ${packageForm.errors.dilveryInDays} </br> ${packageForm.errors.noOfTimesProductUsed}</br> ${packageForm.errors.packageName}\n ${packageForm.errors.packagePrice}\n ${packageForm.errors.packageWeight}\n `);
  // },[packageForm.errors])

  //Formik form end -================================================
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllAddons());
    dispatch(setSelectedCategory(activeCategory));
    // dispatch(getAllSuperCategories());
    // dispatch(getAllSubCategories());
  }, [updateDone]);

  // const getPriceFunction = () => {
  //   dispatch(getAdminPrice({ price: packageForm.values.packagePrice }));
  // };

  // useEffect(() => {
  //   getPriceFunction();
  // }, [packageForm.values.packagePrice]);

  useEffect(() => {
    dispatch(getAllAddonsList());
    // Check if allAddons and addonLists exist
    if (!allAddons || !addonLists) {
      return;
    }

    let { freeList, paidList } = categorizeAddons(addonLists, allAddons);
    // console.log({ freeList, paidLis  t });
    setFreeAddonsList(freeList);
    setPaidAddonsList(paidList);
  }, [allAddons]);

  function categorizeAddons(addonLists, allAddons) {
    let freeList = [];
    let paidList = [];
    let paidAddons = new Set();
    let freeAddons = new Set();

    // Iterate through allAddons to categorize them
    allAddons.forEach((item) => {
      if (item.price !== 0) {
        paidAddons.add(item._id);
      } else {
        freeAddons.add(item._id);
      }
    });

    // Iterate through addonLists to categorize based on paid and free addons
    addonLists.forEach((addonList) => {
      let { _id: id, name, list } = addonList;

      let paidListItems = list.filter((item) => paidAddons.has(item));
      if (paidListItems.length > 0) {
        paidList.push({ id, name, list: paidListItems });
      }

      let freeListItems = list.filter((item) => freeAddons.has(item));
      if (freeListItems.length > 0) {
        freeList.push({ id, name, list: freeListItems });
      }
    });

    return { freeList, paidList };
  }

 
  // console.log({ paidAddonTags, freeAddonTags });

  const [selectedOption, setSelectedOption] = useState(true);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (selectedOption === "option1") {
      setOutOfStock(true);
      setPreBooking(false);
    } else if (selectedOption === "option2") {
      setPreBooking(true);
      setOutOfStock(false);
    }
  };

  const [dateError, setDateError] = useState(false);

  const handleDateValue = () => {
    if (preBooking && dateValue <= new Date()) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const handleClearRadioSelection = () => {
    setOutOfStock(false);
    setPreBooking(false);
  };
  const [isAdd, setIsAdd] = useState(true);
  const [isAdd1, setIsAdd1] = useState(true);
  const [isAdd2, setIsAdd2] = useState(true);
  const [isAdd3, setIsAdd3] = useState(true);
  const [isAdd4, setIsAdd4] = useState(true);
  const [isAdd5, setIsAdd5] = useState(true);
  const handleAddClick = () => {
    setIsAdd(true);
  };

  const handleAddClick1 = () => {
    setIsAdd1(true);
  };

  const handleAddClick2 = () => {
    setIsAdd2(true);
  };

  const handleAddClick3 = () => {
    setIsAdd3(true);
  };

  const handleAddClick4 = () => {
    setIsAdd4(true);
  };

  const handleAddClick5 = () => {
    setIsAdd5(true);
  };
  const [editCustomPlan, setEditCustomPlan] = useState(true);

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {/* <ErrorMessage name="email" render={msg => <div>{msg}</div>} /> */}
      {loading && <LoadingPage />}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="admin-frontend-package-creation-popup-content">
        <img src={PopUpClose} alt="popupclose" onClick={props.onclick} />
        <p id="package-creation-text">Package Creations</p>
        <div className="admin-frontend-package-creation-popup-inner-content">
          <div className="package-creation-popup-left-part">
            <div className="create-package-error-container">
              <label className="create-package-error-label">
                {packageForm.errors.packageName &&
                packageForm.touched.packageName
                  ? packageForm.errors.packageName
                  : "\u00A0"}
              </label>
              <label className="create-package-error-label">
                {packageForm.errors.dilveryInDays &&
                packageForm.touched.dilveryInDays
                  ? packageForm.errors.dilveryInDays
                  : "\u00A0"}
              </label>
            </div>
            <div className="package-creation-package-name-container">
              <input
                type="text"
                value={packageForm.values.packageName}
                onChange={packageForm.handleChange}
                onBlur={packageForm.handleBlur}
                name="packageName"
                id=""
                placeholder="Product name"
              />
              <input
                type="number"
                value={packageForm.values.dilveryInDays}
                onChange={packageForm.handleChange}
                onBlur={packageForm.handleBlur}
                name="dilveryInDays"
                id=""
                placeholder="Delivered in days"
              />
            </div>
            <div className="create-package-error-container">
              <label className="create-package-error-label"> {"\u00A0"}</label>
              <label className="create-package-error-label"> {"\u00A0"}</label>
            </div>
            <div className="admin-package-creation-select-catogery-container">
              <div className="admin-package-creation-catogery-container">
                <CustomSelectCatogery
                  options={superCategories.map((elm) => elm.category)}
                  category={true}
                />
              </div>

              <div className="admin-package-creation-subcatogery-container">
                <Customselectsubcatogery
                  options={allCategories
                    .filter(
                      (elm) =>
                        elm.category === (selectedCategory || activeCategory) &&
                        !!elm.subCategory
                    )
                    .map((data) => data.subCategory)}
                  category={false}
                />
              </div>
            </div>

            {/* <div className="create-package-error-container">
              <label className="create-package-error-label">
                {tags.length < 1 && packageForm.touched.products
                  ? "Products are required"
                  : "\u00A0"}
              </label>
            </div> */}
            {/* <div className="package-creation-product-name-container">
              {tags.map((tag, index) => (
                <div className="admin-package-creation-tag-item" key={index}>
                  <span className="tag-item-tag">{tag}</span>
                  <span
                    className="tag-item-close"
                    onClick={() => removeTags(index)}
                  >
                    &times;
                  </span>
                </div>
              ))}
              <input
                onKeyDown={addTags}
                type="text"
                name="products"
                onBlur={packageForm.handleBlur}
                id=""
                className="tag-item-input"
                placeholder="Product's Name"
              />
            </div> */}

            <div className="create-package-error-container">
              <label className="create-package-error-label">
                {packageForm.errors.rentAmount && packageForm.touched.rentAmount
                  ? packageForm.errors.rentAmount
                  : "\u00A0"}
              </label>
              <label className="create-package-error-label">
                {packageForm.errors.rentDuration &&
                packageForm.touched.rentDuration
                  ? packageForm.errors.rentDuration
                  : "\u00A0"}
              </label>
            </div>
            <div className="package-creation-renting-amount-container">
              {/* <input
                type="number"
                name="rentAmount"
                id="renting-amt-input"
                value={packageForm.values.rentAmount}
                onChange={packageForm.handleChange}
                onBlur={packageForm.handleBlur}
                placeholder="Rent Amount"
              /> */}
              <div className="package-creation-custom-plans-content-input">
                {fetchedPrices[packageForm.values.rentDuration]}
              </div>
              {/* <input type="date" name="" id="renting-date-input" /> */}
              <select
                className="rent-duration-select"
                name="rentDuration"
                id=""
                value={packageForm.values.rentDuration}
                onChange={packageForm.handleChange}
                onBlur={packageForm.handleBlur}
              >
                <option value="" disabled selected>
                  Rent duration
                </option>
                <option value="1">1 Day</option>
                <option value="3">3 Days</option>
                <option value="7">1 Week</option>
                <option value="30">1 Month</option>
                <option value="90">3 Months</option>
                <option value="180">6 Months</option>
                <option value="365">1 Year</option>
              </select>
            </div>
            <div className="create-package-error-container">
              <label className="create-package-error-label">{"\u00A0"}</label>
              <label className="create-package-error-label">{"\u00A0"}</label>
            </div>
            <div className="package-creation-addons-container">
              {/* <div className="package-creation-free-addons-container">
                <p>Free addons</p>

                <img src={Downtitle} alt="downarrow" />
              </div> */}
              <select
                className="rent-duration-select"
                name=""
                id=""
                onChange={(e) => {
                  addFreeAddons(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Free Addons
                </option>
                {freeAddonsList.map((data) => (
                  <option value={JSON.stringify(data)}>{data.name}</option>
                ))}
              </select>

              <select
                className="rent-duration-select"
                name=""
                id=""
                onChange={(e) => {
                  addPaidAddons(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Paid Addons
                </option>
                {paidAddonsList.map((data) => (
                  <option value={JSON.stringify(data)}>{data.name}</option>
                ))}
              </select>
              {/* </div> */}
            </div>
            <div className="package-creation-addons-container">
              <div
                className="package-creation-product-name-container"
                style={{
                  height: "3.5rem",
                  width: "50%",
                  marginTop: "0.8rem",
                }}
              >
                {freeAddonTags.map((tag, index) => (
                  <div className="admin-package-creation-tag-item" key={index}>
                    <span className="tag-item-tag">{tag.name}</span>
                    <span
                      className="tag-item-close"
                      onClick={() => removeFreeAddonTags(index)}
                    >
                      &times;
                    </span>
                  </div>
                ))}
                {/* <input
                  onKeyDown={addTags}
                  type="text"
                  name=""
                  id=""
                  className="tag-item-input"
                  placeholder="Product's Name"
                /> */}
              </div>
              <div
                className="package-creation-product-name-container"
                style={{
                  height: "3.5rem",
                  width: "50%",
                  marginTop: "0.8rem",
                }}
              >
                {/* // {console.log(paidAddonTags)} */}
                {paidAddonTags.map((tag, index) => (
                  <div className="admin-package-creation-tag-item" key={index}>
                    <span className="tag-item-tag">{tag.name}</span>
                    <span
                      className="tag-item-close"
                      onClick={() => removePaidAddonTags(index)}
                    >
                      {" "}
                      &times;
                    </span>
                  </div>
                ))}
                {/* <input
                  onKeyDown={addTags}
                  type="text"
                  name=""
                  id=""
                  className="tag-item-input"
                  placeholder="Product's Name"
                /> */}
              </div>
            </div>
            <div className="package-creation-addons-container">
              <select
                className="rent-duration-select"
                name="packageWeight"
                id=""
                value={packageForm.values.packageWeight}
                onChange={packageForm.handleChange}
                onBlur={packageForm.handleBlur}
                style={{
                  marginTop: "3rem",
                }}
                required
              >
                <option value="" disabled selected>
                  Package Weight
                </option>
                <option value="L">Light</option>
                <option value="M">Medium</option>
                <option value="H">Heavy</option>
              </select>
              <div
                className="form-group"
                style={{ marginTop: "8%", display: "flex" }}
              >
                <label htmlFor="gst">GST </label>
                <select
                  className="form-control"
                  name="gst"
                  id="gst"
                  value={packageForm.values.gst}
                  onChange={packageForm.handleChange}
                  onBlur={packageForm.handleBlur}
                  style={{
                    padding: "0 0.5rem",
                    width: "4rem",
                    textAlign: "center",
                  }}
                >
                  <option value={gstValue}>{gstValue}</option>
                </select>
              </div>
              <div className="create-package-error-container">
                <label
                  className="create-package-error-label"
                  style={{
                    marginTop: "3rem",
                  }}
                >
                  {packageForm.errors.packageWeight &&
                  packageForm.touched.packageWeight
                    ? packageForm.errors.packageWeight
                    : "\u00A0"}
                </label>
              </div>
            </div>
            <div className="toggle-buttons-out">
              <div className="radio-buttons">
                <label>
                  <input
                    type="radio"
                    value="option1"
                    checked={outOfStock}
                    onChange={handleOptionChange}
                  />
                  Out of Stock
                </label>
                <label>
                  <input
                    type="radio"
                    value="option2"
                    checked={preBooking}
                    onChange={handleOptionChange}
                  />
                  Pre Booking
                </label>
                <div
                  className="clear-radio-btn"
                  onClick={handleClearRadioSelection}
                >
                  Clear Selection{" "}
                  <img className="cross-radio-img" src={Cross} />
                </div>
                {dateError ? (
                  <span
                    style={{ color: "red", fontFamily: "Montserrat" }}
                    className="date-error-prebook"
                  >
                    Pre Booking date cannot be less than the current date
                  </span>
                ) : (
                  ""
                )}
                {preBooking ? (
                  <div className="date-select-right-container">
                    <input
                      className="date-select-input"
                      type="text"
                      value={dateFormatter(dateValue)}
                    />
                    <div
                      ref={setRefElm}
                      className="date-select-calender-outer-container"
                      onClick={() => setIsDate(!isDate)}
                    >
                      <img
                        style={{ height: "1rem" }}
                        src={Calender}
                        alt="calendericon"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <PopUp
              display={isDate}
              callback={setIsDate}
              zIndex={12}
              uniqueId={"daily-pop-up"}
            >
              <div
                ref={setPopElm}
                style={{ ...popper.styles.popper }}
                {...popper.attributes}
                className="admin-date-popup-container"
              >
                <DatePicker date={dateValue} setDate={setDateValue} />
                <div className="admin-date-popup-bottom-container">
                  <button
                    onClick={() => {
                      setIsDate(!isDate);
                      handleDateValue();
                    }}
                  >
                    Set Date
                  </button>
                </div>
              </div>
            </PopUp>
          </div>
        </div>

        <button
          type="submit"
          onClick={() => {
            setIsProceed(!isProceed);
          }}
        >
          Proceed <img src={Rightar} alt="rightar" />
        </button>
      </div>

      {isProceed && (
        // <div className="admin-frontend-package-creation-popup-content">

        // <div className="admin-frontend-package-creation-popup-container">
        <div className="admin-frontend-package-creation-popup-content">
          <img src={PopUpClose} alt="popupclose" onClick={props.onclick} />
          <p id="package-creation-text">Package Creation</p>
          <div className="admin-frontend-package-creation-inner-content">
            <div className="admin-frontend-package-creation-left-container">
              <p>Custom Plans</p>
              <div className="package-creation-custom-plans-container">
                {/* <====================================1st custom package================================> */}
                <div className="create-package-error-container">
                  <label
                    className="create-package-error-label"
                    style={{ marginLeft: "2.5rem" }}
                  >
                    {packageForm.errors.customPlan1 &&
                    packageForm.touched.customPlan1
                      ? packageForm.errors.customPlan1
                      : "\u00A0"}
                  </label>
                  <label className="create-package-error-label">
                    {packageForm.errors.customPlan1Price &&
                    packageForm.touched.customPlan1Price
                      ? packageForm.errors.customPlan1Price
                      : "\u00A0"}
                  </label>
                </div>

                {isAdd ? (
                  <div
                    className="package-creation-custom-plans-content"
                    style={{ marginTop: "0rem" }}
                  >
                    {/* <p>1</p> */}
                    <select
                      name="customPlan1"
                      value={isAdd ? packageForm.values.customPlan1 : 0}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                    >
                      <option value="" selected disabled>
                        Duration
                      </option>
                      <option value="0">Remove</option>
                      <option value="1">1 Day</option>
                      <option value="3">3 Days</option>
                      <option value="7">1 Week</option>
                      <option value="30">1 Month</option>
                      <option value="90">3 Months</option>
                      <option value="180">6 Months</option>
                      <option value="365">1 Year</option>
                    </select>

                    <input
                      type="number"
                      value={packageForm.values.customPlan1Price}
                      onChange={(e) => {
                        packageForm.setFieldValue(
                          "customPlan1Price",
                          e.target.value
                        );
                      }}
                      className={`package-creation-custom-plans-content-input ${
                        editCustomPlan ? "" : "backgroundoffwhite"
                      }`}
                    />

                    <button
                      classname="delete-btn"
                      onClick={() => {
                        setIsAdd(false);
                      }}
                    >
                      delete
                    </button>
                  </div>
                ) : (
                  <button classname="text-primary" onClick={handleAddClick}>
                    add
                  </button>
                )}

                {/* <====================================2nd custom package================================> */}
                <div className="create-package-error-container">
                  <label
                    className="create-package-error-label"
                    style={{ marginLeft: "2.5rem" }}
                  >
                    {packageForm.errors.customPlan2 &&
                    packageForm.touched.customPlan2
                      ? packageForm.errors.customPlan2
                      : "\u00A0"}
                  </label>
                  <label className="create-package-error-label">
                    {packageForm.errors.customPlan2Price &&
                    packageForm.touched.customPlan2Price
                      ? packageForm.errors.customPlan2Price
                      : "\u00A0"}
                  </label>
                </div>

                {!isAdd1 && (
                  <button classname="text-primary" onClick={handleAddClick1}>
                    add
                  </button>
                )}
                {isAdd1 && (
                  <div
                    className="package-creation-custom-plans-content"
                    style={{ marginTop: "0rem" }}
                  >
                    {/* <p>2</p> */}
                    <select
                      name="customPlan2"
                      value={isAdd1 ? packageForm.values.customPlan2 : 0}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                    >
                      <option value="0" selected disabled>
                        Duration
                      </option>
                      <option value="0">Remove</option>
                      <option value="1">1 Day</option>
                      <option value="3">3 Days</option>
                      <option value="7">1 Week</option>
                      <option value="30">1 Month</option>
                      <option value="90">3 Months</option>
                      <option value="180">6 Months</option>
                      <option value="365">1 Year</option>
                    </select>
                    <input
                      value={packageForm.values.customPlan2Price}
                      onChange={(e) => {
                        packageForm.setFieldValue(
                          "customPlan2Price",
                          e.target.value
                        );
                      }}
                      className={`package-creation-custom-plans-content-input ${
                        editCustomPlan ? "" : "backgroundoffwhite"
                      }`}
                    />
                    <button
                      classname="delete-btn"
                      onClick={() => {
                        setIsAdd1(false);
                      }}
                    >
                      delete
                    </button>
                  </div>
                )}
                {/* <====================================3rd custom package================================> */}
                <div className="create-package-error-container">
                  <label
                    className="create-package-error-label"
                    style={{ marginLeft: "2.5rem" }}
                  >
                    {packageForm.errors.customPlan3 &&
                    packageForm.touched.customPlan3
                      ? packageForm.errors.customPlan3
                      : "\u00A0"}
                  </label>
                  <label className="create-package-error-label">
                    {packageForm.errors.customPlan3Price &&
                    packageForm.touched.customPlan3Price
                      ? packageForm.errors.customPlan3Price
                      : "\u00A0"}
                  </label>
                </div>

                {!isAdd2 && (
                  <button classname="text-primary" onClick={handleAddClick2}>
                    add
                  </button>
                )}
                {isAdd2 && (
                  <div
                    className="package-creation-custom-plans-content"
                    style={{ marginTop: "0rem" }}
                  >
                    {/* <p>3</p> */}
                    <select
                      name="customPlan3"
                      value={isAdd2 ? packageForm.values.customPlan3 : 0}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                    >
                      <option value="" selected disabled>
                        Duration
                      </option>
                      <option value="0">Remove</option>
                      <option value="1">1 Day</option>
                      <option value="3">3 Days</option>
                      <option value="7">1 Week</option>
                      <option value="30">1 Month</option>
                      <option value="90">3 Months</option>
                      <option value="180">6 Months</option>
                      <option value="365">1 Year</option>
                    </select>
                    {/* <div className="package-creation-custom-plans-content-input">
                      {fetchedPrices[packageForm.values.customPlan3]}
                    </div> */}
                    <input
                      value={packageForm.values.customPlan3Price}
                      onChange={(e) => {
                        packageForm.setFieldValue(
                          "customPlan3Price",
                          e.target.value
                        );
                      }}
                      className={`package-creation-custom-plans-content-input ${
                        editCustomPlan ? "" : "backgroundoffwhite"
                      }`}
                    />
                    <button
                      classname="delete-btn"
                      onClick={() => {
                        console.log("delete");
                        setIsAdd2(false);
                      }}
                    >
                      delete
                    </button>
                  </div>
                )}
                {/* <====================================4th custom package================================> */}
                <div className="create-package-error-container">
                  <label
                    className="create-package-error-label"
                    style={{ marginLeft: "2.5rem" }}
                  >
                    {packageForm.errors.customPlan4 &&
                    packageForm.touched.customPlan4
                      ? packageForm.errors.customPlan4
                      : "\u00A0"}
                  </label>
                  <label className="create-package-error-label">
                    {packageForm.errors.customPlan4Price &&
                    packageForm.touched.customPlan4Price
                      ? packageForm.errors.customPlan4Price
                      : "\u00A0"}
                  </label>
                </div>

                {!isAdd3 && (
                  <button classname="text-primary" onClick={handleAddClick3}>
                    add
                  </button>
                )}
                {isAdd3 && (
                  <div
                    className="package-creation-custom-plans-content"
                    style={{ marginTop: "0rem" }}
                  >
                    {/* <p>4</p> */}
                    <select
                      name="customPlan4"
                      value={isAdd3 ? packageForm.values.customPlan4 : 0}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                    >
                      <option value="" selected disabled>
                        Duration
                      </option>
                      <option value="0">Remove</option>
                      <option value="1">1 Day</option>
                      <option value="3">3 Days</option>
                      <option value="7">1 Week</option>
                      <option value="30">1 Month</option>
                      <option value="90">3 Months</option>
                      <option value="180">6 Months</option>
                      <option value="365">1 Year</option>
                    </select>

                    <input
                      value={packageForm.values.customPlan4Price}
                      onChange={(e) => {
                        packageForm.setFieldValue(
                          "customPlan4Price",
                          e.target.value
                        );
                      }}
                      className={`package-creation-custom-plans-content-input ${
                        editCustomPlan ? "" : "backgroundoffwhite"
                      }`}
                    />

                    <button
                      classname="delete-btn"
                      onClick={() => {
                        setIsAdd3(false);
                      }}
                    >
                      delete
                    </button>
                  </div>
                )}

                {/* <====================================5th custom package================================> */}
                <div className="create-package-error-container">
                  <label
                    className="create-package-error-label"
                    style={{ marginLeft: "2.5rem" }}
                  >
                    {packageForm.errors.customPlan5 &&
                    packageForm.touched.customPlan5
                      ? packageForm.errors.customPlan5
                      : "\u00A0"}
                  </label>
                  <label className="create-package-error-label">
                    {packageForm.errors.customPlan5Price &&
                    packageForm.touched.customPlan5Price
                      ? packageForm.errors.customPlan5Price
                      : "\u00A0"}
                  </label>
                </div>

                {!isAdd4 && (
                  <button classname="text-primary" onClick={handleAddClick4}>
                    add
                  </button>
                )}
                {isAdd4 && (
                  <div
                    className="package-creation-custom-plans-content"
                    style={{ marginTop: "0rem" }}
                  >
                    <select
                      name="customPlan5"
                      value={isAdd4 ? packageForm.values.customPlan5 : 0}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                    >
                      <option value="" selected disabled>
                        Duration
                      </option>
                      <option value="0">Remove</option>
                      <option value="1">1 Day</option>
                      <option value="3">3 Days</option>
                      <option value="7">1 Week</option>
                      <option value="30">1 Month</option>
                      <option value="90">3 Months</option>
                      <option value="180">6 Months</option>
                      <option value="365">1 Year</option>
                    </select>
                    <input
                      value={packageForm.values.customPlan5Price}
                      onChange={(e) => {
                        packageForm.setFieldValue(
                          "customPlan5Price",
                          e.target.value
                        );
                      }}
                      className={`package-creation-custom-plans-content-input ${
                        editCustomPlan ? "" : "backgroundoffwhite"
                      }`}
                    />

                    <button
                      classname="delete-btn"
                      onClick={() => {
                        setIsAdd4(false);
                      }}
                    >
                      delete
                    </button>
                  </div>
                )}
                {/* <====================================6th custom package================================> */}
                <div className="create-package-error-container">
                  <label
                    className="create-package-error-label"
                    style={{ marginLeft: "2.5rem" }}
                  >
                    {packageForm.errors.customPlan6 &&
                    packageForm.touched.customPlan6
                      ? packageForm.errors.customPlan6
                      : "\u00A0"}
                  </label>
                  <label className="create-package-error-label">
                    {packageForm.errors.customPlan6Price &&
                    packageForm.touched.customPlan6Price
                      ? packageForm.errors.customPlan6Price
                      : "\u00A0"}
                  </label>
                </div>

                {!isAdd5 && (
                  <button classname="text-primary" onClick={handleAddClick5}>
                    add
                  </button>
                )}

                {isAdd5 && (
                  <div
                    className="package-creation-custom-plans-content"
                    style={{ marginTop: "0rem" }}
                  >
                    {/* <p>6</p> */}
                    <select
                      name="customPlan6"
                      value={isAdd5 ? packageForm.values.customPlan6 : 0}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                    >
                      <option value="" selected disabled>
                        Duration
                      </option>
                      <option value="0">Remove</option>
                      <option value="1">1 Day</option>
                      <option value="3">3 Days</option>
                      <option value="7">1 Week</option>
                      <option value="30">1 Month</option>
                      <option value="90">3 Months</option>
                      <option value="180">6 Months</option>
                      <option value="365">1 Year</option>
                    </select>
                    <input
                      value={packageForm.values.customPlan6Price}
                      onChange={(e) => {
                        packageForm.setFieldValue(
                          "customPlan6Price",
                          e.target.value
                        );
                      }}
                      className={`package-creation-custom-plans-content-input ${
                        editCustomPlan ? "" : "backgroundoffwhite"
                      }`}
                    />

                    <button
                      classname="delete-btn"
                      onClick={() => {
                        setIsAdd5(false);
                      }}
                    >
                      delete
                    </button>
                  </div>
                )}
              </div>

              {/* <=================================================Amount===========================================> */}
              <p style={{ marginTop: "1rem" }}>Package Price</p>
              <div className="package-creation-custom-plans-container">
                <div className="create-package-error-container">
                  <label
                    className="create-package-error-label  align-left"
                    // style={{ marginLeft: "2.5rem" }}
                  >
                    {packageForm.errors.customPlan1 &&
                    packageForm.touched.customPlan1
                      ? packageForm.errors.customPlan1
                      : "\u00A0"}
                  </label>
                  <label className="create-package-error-label align-left">
                    {packageForm.errors.packagePrice &&
                    packageForm.touched.packagePrice
                      ? packageForm.errors.packagePrice
                      : "\u00A0"}
                  </label>
                </div>
                <div
                  className="package-creation-custom-plans-content"
                  style={{ marginTop: "0rem" }}
                >
                  <input
                    type="number"
                    name="packagePrice"
                    value={packageForm.values.packagePrice}
                    onChange={(e) => {
                      packageForm.setFieldValue("packagePrice", e.target.value);
                    }}
                    onBlur={packageForm.handleBlur}
                    id=""
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="admin-package-creation-preview-btn-container">
            <button onClick={() => setIsProceed(!isProceed)}>
              {" "}
              <img src={Leftar} alt="leftar" /> Back
            </button>

            <button
              className="submit"
              type="submit"
              onClick={() => setIsProceed2(!isProceed2)}
            >
              Proceed <img src={Rightar} alt="rightar" />
            </button>
          </div>
        </div>
        // </div>
        // </div>
      )}

      {isProceed2 && (
        <>
          <div className="admin-frontend-package-creation-second-popup-content">
            <img src={PopUpClose} alt="popupclose" onClick={props.onclick} />
            <p id="package-creation-text">Package Creation</p>
            <div className="admin-frontend-package-creation-inner-content">
              <div className="admin-frontend-package-creation-left-container">
                <label className="create-package-error-label">
                  {imgTags.length < 0 && imgTouched
                    ? "Atleast 1 image is required"
                    : "\u00A0"}
                </label>
                <div
                  className="admin-package-creation-upload-img-container"
                  style={{ marginBottom: "0.5rem" }}
                >
                  <div className="admin-package-creation-upload-img-content">
                    <label className="camerafull-icon">
                      <input
                        onClick={() => {
                          setImgTouched(true);
                        }}
                        onChange={addImgTag}
                        type="file"
                        name="images"
                        onBlur={packageForm.handleBlur}
                        id=""
                        multiple
                        accept="image/png, image/jpeg, image/webp"
                      />
                      <img src={Dcamera} alt="dcamera" />
                    </label>
                    <p>Upload Image</p>
                  </div>
                  <div className="admin-package-creation-preview-img-tag-container">
                    {imgTags.slice(0, 1).map((imgTag, index) => (
                      <div
                        className="admin-package-creation-preview-img-content"
                        key={index}
                      >
                        <div className="admin-package-creation-preview-img-image-container">
                          <img src={imgTag} alt="gopro" />
                        </div>
                        <p>image {index + 1}</p>
                        {imgTags.length === 1 && (
                          <img
                            src={PopUpClose}
                            alt="popupclose"
                            style={{
                              width: ".8rem",
                              height: ".8rem",
                              marginLeft: ".35rem",
                              cursor: "pointer",
                            }}
                            onClick={() => removeImgTag(index)}
                          />
                        )}
                        {imgTags.length > 1 && (
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => setImageMoreContaier(true)}
                          >
                            ...
                          </p>
                          // <img
                          //   src={PopUpClose}
                          //   alt="popupclose"
                          //   style={{
                          //     width: ".8rem",
                          //     height: ".8rem",
                          //     marginLeft: ".35rem",
                          //     cursor: "pointer",
                          //   }}
                          //   onClick={() => removeImgTag(index)}
                          // />
                        )}
                      </div>
                    ))}
                    {imageMoreContaier && (
                      <div className="admin-package-creation-preview-img-more-container">
                        <img
                          id="image-popupcontainer-minimize-button"
                          src={PopUpClose}
                          onClick={() =>
                            setImageMoreContaier(!imageMoreContaier)
                          }
                          alt="popImage"
                        />
                        <div className="admin-package-creation-preview-img-more-scroller-container">
                          <div className="admin-package-creation-preview-img-more-content">
                            {imgTags.map((imgTag, index) => (
                              <div
                                className="admin-package-creation-preview-img-content"
                                key={index}
                              >
                                <div className="admin-package-creation-preview-img-image-container">
                                  <img src={imgTag} alt="gopro" />
                                </div>
                                <p>image {index + 1}</p>
                                <img
                                  src={PopUpClose}
                                  alt="popupclose"
                                  style={{
                                    width: ".8rem",
                                    height: ".8rem",
                                    marginLeft: ".35rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeImgTag(index)}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <label className="create-package-error-label">
                  {packageForm.errors.noOfTimesProductUsed &&
                  packageForm.touched.noOfTimesProductUsed
                    ? packageForm.errors.noOfTimesProductUsed
                    : "\u00A0"}
                </label>

                <input
                  style={{
                    marginTop: "0rem",
                    marginBottom: "0.5rem",
                  }}
                  type="number"
                  value={packageForm.values.noOfTimesProductUsed}
                  onChange={packageForm.handleChange}
                  onBlur={packageForm.handleBlur}
                  name="noOfTimesProductUsed"
                  id="no-of-time-package-used"
                  placeholder="No of time package used"
                />

                <label className="create-package-error-label">
                  {packageForm.errors.summary && packageForm.touched.summary
                    ? packageForm.errors.summary
                    : "\u00A0"}
                </label>
                <textarea
                  name="summary"
                  style={{ marginTop: "0rem", marginBottom: "0.5rem" }}
                  id="admin-package-summary-textarea"
                  value={packageForm.values.summary}
                  onChange={packageForm.handleChange}
                  onBlur={packageForm.handleBlur}
                  cols="30"
                  rows="10"
                  placeholder="Package Summary"
                ></textarea>

                <label className="create-package-error-label">
                  {specs.length < 2 && packageForm.touched.specs
                    ? "Alteast 2 Specifications are required "
                    : "\u00A0"}
                </label>
                <div
                  className="admin-package-creation-specs-tag-box-container"
                  style={{ marginTop: "0rem", marginBottom: "0.5rem" }}
                >
                  {specs.map((tag, index) => (
                    <div
                      className="admin-package-creation-tag-item"
                      key={index}
                    >
                      <span className="tag-item-tag">{tag}</span>
                      <span
                        className="tag-item-close"
                        onClick={() => removeSpecs(index)}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                  <input
                    onKeyUp={addSpecs}
                    type="text"
                    name="specs"
                    onBlur={packageForm.handleBlur}
                    id=""
                    className="tag-item-input"
                    placeholder="Spec : value"
                  />
                </div>

                <label className="create-package-error-label">
                  {packageForm.errors.faqQuestion ||
                  packageForm.errors.faqAnswer
                    ? `Question : ${
                        packageForm.errors.faqQuestion
                          ? packageForm.errors.faqQuestion
                          : "All ok"
                      },  Answer : ${
                        packageForm.errors.faqAnswer
                          ? packageForm.errors.faqAnswer
                          : "All ok"
                      }`
                    : "\u00A0"}
                </label>
                <div
                  className="admin-package-creation-faqs-container"
                  style={{ marginTop: "0rem", marginBottom: "0.5rem" }}
                >
                  <div className="admin-package-creation-faqs-que-container">
                    <input
                      type="text"
                      name="faqQuestion"
                      value={packageForm.values.faqQuestion}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                      placeholder="FAQ Question"
                    />
                  </div>
                  <div className="admin-package-creation-faqs-ans-container">
                    <input
                      type="text"
                      name="faqAnswer"
                      value={packageForm.values.faqAnswer}
                      onChange={packageForm.handleChange}
                      onBlur={packageForm.handleBlur}
                      id=""
                      placeholder="FAQ Answer"
                    />
                    <img
                      onClick={() => {
                        addFaqQuestion({
                          question: packageForm.values.faqQuestion,
                          answer: packageForm.values.faqAnswer,
                        });
                        packageForm.values.faqQuestion = "";
                        packageForm.values.faqAnswer = "";
                      }}
                      src={Bluerightnavigation}
                      alt="bluerightnavigation"
                    />
                  </div>
                </div>

                <div
                  className="admin-package-creation-specs-tag-box-container"
                  style={{
                    marginTop: "0rem",
                    marginBottom: "0.5rem",
                    border: "0px",
                  }}
                >
                  {faqTags.map((tag, index) => (
                    <div
                      className="admin-package-creation-faqs-tag-container"
                      key={index}
                    >
                      <p
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                      >
                        Q{index + 1} {tag.question.slice(0, 4)}..{" "}
                        <img
                          src={PopUpClose}
                          alt="popupclose"
                          style={{ width: ".8rem", height: ".8rem" }}
                          onClick={() => {
                            removeFaqQuestion(index);
                          }}
                        />
                      </p>
                      {isShown && (
                        <>
                          <div className="admin-package-creation-faqs-tag-preview-container">
                            <p>
                              Que {index + 1}: {tag.question}
                            </p>
                            <p>
                              Ans {index + 1}: {tag.answer}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>

                <label htmlFor="" className="package-creation-checkbox">
                  <input
                    type="checkbox"
                    name="selfPickup"
                    value={packageForm.values.selfPickup}
                    onChange={packageForm.handleChange}
                    id=""
                  />
                  <p>Self pickup option</p>
                </label>
              </div>
              <div className="admin-frontend-package-creation-right-container">
                <div className="admin-frontend-package-creation-right-upper-part">
                  <p>In The Box</p>
                  <div className="admin-frontend-package-creation-box-prod-name-container">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Name"
                      value={inTheBoxName}
                      onChange={(e) => {
                        setInTheBoxName(e.target.value);
                      }}
                    />
                    <label className="admin-frontend-package-creation-upload-img-container">
                      <p>
                        {inTheBoxImg.length > 0
                          ? inTheBoxImg[0].name.slice(0, 4) + "..."
                          : "Upload Img"}{" "}
                        <img src={Uploadprodimg} alt="uploadprodimg" />
                      </p>
                      <input
                        onChange={addBoxTag}
                        type="file"
                        name=""
                        id=""
                        accept="image/png, image/jpeg, image/webp, image/jpg"
                      />
                    </label>

                    <div
                      className="admin-frontend-package-creation-upload-done-container"
                      onClick={() => {
                        addInTheBoxItems();
                      }}
                    >
                      <img src={Uploaddone} alt="uploaddone" />
                    </div>
                  </div>

                  <div className="admin-frontend-package-creation-upload-img-preview-container">
                    {inTheBoxTags.map((boxTag, index) => (
                      <div
                        className="admin-package-creation-preview-img-content"
                        key={index}
                      >
                        <div className="admin-package-creation-preview-img-image-container">
                          <img src={boxTags[index]} alt="gopro" />
                        </div>
                        <p>
                          {index + 1} {" " + boxTag.name.slice(0, 3) + "..."}
                        </p>
                        <img
                          src={PopUpClose}
                          alt="popupclose"
                          style={{
                            width: ".8rem",
                            height: ".8rem",
                            marginLeft: ".35rem",
                          }}
                          onClick={() => removeBoxTag(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-package-creation-preview-btn-container">
              <button onClick={() => setIsProceed2(!isProceed2)}>
                {" "}
                <img src={Leftar} alt="leftar" /> Back
              </button>
              <button onClick={() => setIsPackagePreview(!isPackagePreview)}>
                Package Preview
              </button>
              {isPackagePreview && (
                <>
                  {/* <div className="admin-frontend-package-creation-popup-container"> */}
                  <div className="admin-frontend-package-preview-popup-content">
                    <div className="admin-frontend-package-preview-popup-header-container">
                      <img
                        src={PopUpClose}
                        alt="popupclose"
                        onClick={() => setIsPackagePreview(!isPackagePreview)}
                      />
                      <p>Package Preview</p>
                    </div>
                    <div className="admin-frontend-package-preview-popup-middle-container">
                      <div className="admin-frontend-package-preview-middle-scroll-container">
                        <div className="admin-frontend-package-details-one-container">
                          <div className="admin-frontend-package-details-one-header-container">
                            <p>Package Details 1</p>
                          </div>
                          <p id="admin-header-one-prod-text">
                            {packageForm.values.packageName}
                          </p>
                          <p id="admin-header-one-assets-no">#486</p>

                          <div className="admin-scroll-content-catogery-content">
                            <p>
                              Category:{" "}
                              <span>
                                {formCategory[0] +
                                  formCategory.slice(1).toLowerCase()}
                              </span>
                            </p>
                            <p>
                              Subcategory:{" "}
                              <span>
                                {formSubCategory[0] +
                                  formSubCategory.slice(1).toLowerCase()}
                              </span>
                            </p>
                          </div>

                          <div id="admin-header-one-content">
                            <p id="admin-header-one-content-text">Content:</p>
                            {tags.map((elm, idx) => {
                              return (
                                <>
                                  <p id="admin-header-one-content-desc">
                                    {elm}
                                  </p>
                                  {tags.length > idx + 1 && (
                                    <p className="dot"></p>
                                  )}
                                </>
                              );
                            })}
                          </div>
                          <div id="admin-header-one-content">
                            <p id="admin-header-one-content-text">
                              Free Addons:{" "}
                            </p>
                            {freeAddonTags.map((elm, idx) => {
                              return (
                                <>
                                  <p id="admin-header-one-content-desc">
                                    {elm.addonName}
                                  </p>
                                  {freeAddonTags.length > idx + 1 && (
                                    <p className="dot"></p>
                                  )}
                                </>
                              );
                            })}
                          </div>

                          <div id="admin-header-one-content">
                            <p id="admin-header-one-content-text">
                              Paid Addons:{" "}
                            </p>
                            {paidAddonTags.map((elm, idx) => {
                              return (
                                <>
                                  <p id="admin-header-one-content-desc">
                                    {elm.addonName}
                                  </p>
                                  {paidAddonTags.length > idx + 1 && (
                                    <p className="dot"></p>
                                  )}
                                </>
                              );
                            })}
                          </div>

                          <p id="admin-text-content">
                            Delivered in:{" "}
                            <span>{packageForm.values.dilveryInDays} Days</span>
                          </p>
                          <p id="admin-text-content">
                            Rent Amount:{" "}
                            <span>
                              ₹{fetchedPrices[packageForm.values.rentDuration]}/
                              {getDuration(packageForm.values.rentDuration)}
                            </span>
                          </p>
                          <p id="admin-text-content">
                            Package Price:{" "}
                            <span>₹{packageForm.values.packagePrice}</span>
                          </p>
                          <p id="admin-text-content">
                            Package Weight:{" "}
                            <span>
                              {packageForm.values.packageWeight === "L"
                                ? "Light"
                                : packageForm.values.packageWeight === "M"
                                ? "Medium"
                                : "Heavy"}
                            </span>
                          </p>

                          <p id="admin-text-content">
                            Package Status:{" "}
                            <span>
                              {packageForm.values.isOutOfStock
                                ? "Out of Stock"
                                : packageForm.values.isPreBooking
                                ? "For Pre Booking"
                                : "Ready for Sell"}
                            </span>
                          </p>

                          <div className="admin-package-preview-custom-plans-container">
                            <p id="admin-header-one-content-text">
                              Custom Plans
                            </p>
                            <div className="admin-custom-plans-container">
                              <div className="admin-custom-plans-content">
                                <p>
                                  {getDuration(packageForm.values.customPlan1)}
                                </p>
                                <p>₹{packageForm.values.customPlan1Price}</p>
                              </div>

                              <div className="admin-custom-plans-content">
                                <p>
                                  {getDuration(packageForm.values.customPlan2)}
                                </p>
                                <p>₹{packageForm.values.customPlan2Price}</p>
                              </div>

                              <div className="admin-custom-plans-content">
                                <p>
                                  {getDuration(packageForm.values.customPlan3)}
                                </p>
                                <p>₹{packageForm.values.customPlan3Price}</p>
                              </div>

                              <div className="admin-custom-plans-content">
                                <p>
                                  {getDuration(packageForm.values.customPlan4)}
                                </p>
                                <p>₹{packageForm.values.customPlan4Price}</p>
                              </div>

                              <div className="admin-custom-plans-content">
                                <p>
                                  {getDuration(packageForm.values.customPlan5)}
                                </p>
                                <p>₹{packageForm.values.customPlan5Price}</p>
                              </div>

                              <div className="admin-custom-plans-content">
                                <p>
                                  {getDuration(packageForm.values.customPlan6)}
                                </p>
                                <p>₹{packageForm.values.customPlan6Price}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="admin-frontend-package-details-two-container">
                          <div className="admin-frontend-package-details-two-header-container">
                            <p>Package Details 2</p>
                          </div>
                          <div className="admin-frontend-package-two-details-header-img-container">
                            {imgTags.map((elm, idx) => {
                              return (
                                <div className="admin-package-details-two-img-content">
                                  <img src={elm} alt="headerimg" />
                                </div>
                              );
                            })}
                          </div>

                          <p id="admin-text-content">
                            Package Used:{" "}
                            <span>
                              {packageForm.values.noOfTimesProductUsed}
                            </span>
                          </p>

                          <div className="admin-package-details-summary-container">
                            <p id="admin-package-summary-text">
                              Package Summary
                            </p>
                            <p id="admin-package-summary-desc">
                              {packageForm.values.summary}
                            </p>
                          </div>

                          <div className="admin-package-details-tag-container">
                            <p id="admin-package-summary-text">
                              Package Specification
                            </p>
                            <div id="admin-package-specs-tag-content">
                              {specs.map((elm, idx) => {
                                return (
                                  <>
                                    <p id="admin-header-one-content-desc">
                                      {elm}
                                    </p>
                                    {specs.length > idx + 1 && (
                                      <p className="dot"></p>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="admin-package-preview-prod-box-container">
                            <p>In The Box</p>
                            <div className="admin-package-preview-prod-box-content">
                              {inTheBoxTags.map((elm, idx) => {
                                return (
                                  <div className="admin-package-prod-preview-box">
                                    <div className="admin-package-prod-preview-box-img-container">
                                      <img src={boxTags[idx]} alt="gopro" />
                                    </div>
                                    <p>{elm.name}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* <div className="admin-package-preview-prod-box-container">
                                  <p>Rented With</p>
                                  <div className="admin-package-preview-prod-box-content">
                                    <div className="admin-package-prod-preview-box">
                                      <div className="admin-package-prod-preview-box-img-container">
                                        <img src={GoPro} alt="gopro" />
                                      </div>
                                      <p>Controller</p>
                                    </div>

                                    <div className="admin-package-prod-preview-box">
                                      <div className="admin-package-prod-preview-box-img-container">
                                        <img src={GoPro} alt="gopro" />
                                      </div>
                                      <p>Controller</p>
                                    </div>

                                    <div className="admin-package-prod-preview-box">
                                      <div className="admin-package-prod-preview-box-img-container">
                                        <img src={GoPro} alt="gopro" />
                                      </div>
                                      <p>Controller</p>
                                    </div>

                                    <div className="admin-package-prod-preview-box">
                                      <div className="admin-package-prod-preview-box-img-container">
                                        <img src={GoPro} alt="gopro" />
                                      </div>
                                      <p>Controller</p>
                                    </div>
                                  </div>
                                </div> */}

                          <div className="admin-package-preview-faqs-container">
                            <p>FAQ</p>
                            {faqTags.map((elm, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="admin-package-preview-faqs-content"
                                  style={{
                                    height: isFaqs ? "4rem" : "2rem",
                                  }}
                                >
                                  <p
                                    onClick={() => {
                                      setIsFaqs(!isFaqs);
                                      setActiveFaq(idx);
                                    }}
                                  >
                                    {`Q${idx + 1} ${elm.question}`}{" "}
                                    {isFaqs && idx === activeFaq ? (
                                      <img src={Uptitle} alt="uptitle" />
                                    ) : (
                                      <img src={Downtitle} alt="downtitle" />
                                    )}
                                  </p>
                                  {isFaqs && idx === activeFaq && (
                                    <>
                                      <p>{elm.answer}</p>
                                    </>
                                  )}
                                </div>
                              );
                            })}

                            {/* <div
                                    className="admin-package-preview-faqs-content"
                                    style={{ height: isFaqs ? "4rem" : "2rem" }}
                                  >
                                    <p onClick={() => setIsFaqs(!isFaqs)}>
                                      Q1 How do I change my account info?{" "}
                                      {isFaqs ? (
                                        <img src={Uptitle} alt="uptitle" />
                                      ) : (
                                        <img src={Downtitle} alt="downtitle" />
                                      )}
                                    </p>
                                    {isFaqs && (
                                      <>
                                        <p>By visting the profile page</p>
                                      </>
                                    )}
                                  </div>

                                  <div
                                    className="admin-package-preview-faqs-content"
                                    style={{ height: isFaqs ? "4rem" : "2rem" }}
                                  >
                                    <p onClick={() => setIsFaqs(!isFaqs)}>
                                      Q1 How do I change my account info?{" "}
                                      {isFaqs ? (
                                        <img src={Uptitle} alt="uptitle" />
                                      ) : (
                                        <img src={Downtitle} alt="downtitle" />
                                      )}
                                    </p>
                                    {isFaqs && (
                                      <>
                                        <p>By visting the profile page</p>
                                      </>
                                    )}
                                  </div>

                                  <div
                                    className="admin-package-preview-faqs-content"
                                    style={{ height: isFaqs ? "4rem" : "2rem" }}
                                  >
                                    <p onClick={() => setIsFaqs(!isFaqs)}>
                                      Q1 How do I change my account info?{" "}
                                      {isFaqs ? (
                                        <img src={Uptitle} alt="uptitle" />
                                      ) : (
                                        <img src={Downtitle} alt="downtitle" />
                                      )}
                                    </p>
                                    {isFaqs && (
                                      <>
                                        <p>By visting the profile page</p>
                                      </>
                                    )}
                                  </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="admin-frontend-package-preview-popup-footer-container">
                      <button
                        type="submit"
                        onClick={() => {
                          packageForm.handleSubmit();
                          // errorPopup();
                        }}
                      >
                        Create Package
                      </button>
                    </div>
                  </div>
                  {/* </div> */}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {createdPackage && (
        <div className="admin-package-is-created-container">
          <div className="admin-package-is-created-content">
            <p>{packageForm.values.packageName} package created</p>
            <button onClick={props.onclick}>Done</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Admincreatepackage;
