import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  REJECTED_ERROR,
  SUCCESS,
  searchLinksData,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

export const getProductAvailablityThunk = createAsyncThunk(
  "post/admin/dashboard/getDashboardProductAvail",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/dashboard/getDashboardProductAvail`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOrderDetailsThunk = createAsyncThunk(
  "post/admin/dashboard/getOrderDetails",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/dashboard/getOrderDetails`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const rentedTimesThunk = createAsyncThunk(
  "post/admin/dashboard/rentedtimes",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/dashboard/rentedtimes`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
//todo thunk
export const postTodoThunk = createAsyncThunk(
  "post/admin/dashboard/todo",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/dashboard/todo`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllEmpThunk = createAsyncThunk(
  "post/admin/dashboard/getAllEmp",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/dashboard/getAllEmp`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getEmpTodoThunk = createAsyncThunk(
  "post/admin/dashboard/getEmpTodo",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/dashboard/getEmpTodo`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteEmpTodoThunk = createAsyncThunk(
  "post/admin/dashboard/deleteEmpTodo",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/dashboard/deleteEmpTodo`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOrderCountThunk = createAsyncThunk(
  "post/admin/dashboard/getOrderCount",
  async () => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/dashboard/getOrderCount`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTotalUser = createAsyncThunk(
  "get/admin/users/total_users",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/admin/users/total_users`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllActiveUser = createAsyncThunk(
  "get/admin/users/active_users",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/admin/users/active_users`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllNewUser = createAsyncThunk(
  "get/admin/users/new_users",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/admin/users/new_users`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  data: {
    productAvail: [],
    orderDetails: [],
    rentedTimes: [],
    getAllEmp: [],
    getEmpTodo: [],
    getOrderCount: [],
    totalUser:0,
    allActiveUser:0,
    allNewUser:0,
  },
  status: {
    getProductAvailablityThunk: IDLE,
    getOrderDetailsThunk: IDLE,
    rentedTimesThunk: IDLE,
    postTodoThunk: IDLE,
    getAllEmpThunk: IDLE,
    getEmpTodoThunk: IDLE,
    deleteEmpTodoThunk: IDLE,
    getOrderCountThunk: IDLE,
    getTotalUser:IDLE,
    getAllActiveUser:IDLE,
    getAllNewUser:IDLE,
  },
  updateDone: false,
  searchLinks: searchLinksData,
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setError: (state, { payload }) => {
      state.isError = true;
      state.errorData = {
        message: payload.message,
        type: payload.type,
        errors: payload.errors,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // get all product ===============================================
      .addCase(getProductAvailablityThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getProductAvailablityThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.productAvail = payload.data;
            state.status.getProductAvailablityThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getProductAvailablityThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getProductAvailablityThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get order details ===============================================
      .addCase(getOrderDetailsThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getOrderDetailsThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.orderDetails = payload.data;
            state.status.getOrderDetailsThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getOrderDetailsThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getOrderDetailsThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get rented times ===============================================
      .addCase(rentedTimesThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(rentedTimesThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.rentedTimes = payload.data;
            state.status.rentedTimesThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(rentedTimesThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.rentedTimesThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // post todo  ===============================================
      .addCase(postTodoThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(postTodoThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.postTodoThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(postTodoThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.postTodoThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get All Emp thunk
      .addCase(getAllEmpThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getAllEmpThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.getAllEmp = payload.data;
            state.status.getAllEmpThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllEmpThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllEmpThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get All Emp thunk========================================================================================
      .addCase(getEmpTodoThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getEmpTodoThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.getEmpTodo = payload.data;
            state.status.getEmpTodoThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getEmpTodoThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getEmpTodoThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get All Emp thunk
      .addCase(deleteEmpTodoThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(deleteEmpTodoThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deleteEmpTodoThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteEmpTodoThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deleteEmpTodoThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get All Emp thunk=======================================================================================
      .addCase(getOrderCountThunk.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getOrderCountThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.getOrderCount = payload.data;
            state.status.getOrderCountThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getOrderCountThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getOrderCountThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getAllActiveUser.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getAllActiveUser.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.allActiveUser = payload.data.activeUserCount;
            state.status.getAllActiveUser = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllActiveUser.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllActiveUser = ERROR;
        state.isError = true;
      })
      .addCase(getAllNewUser.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getAllNewUser.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.allNewUser = payload.data.newUsers;
            state.status.getAllNewUser = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllNewUser.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllNewUser = ERROR;
        state.isError = true;
      })
      .addCase(getTotalUser.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getTotalUser.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.totalUser = payload.data.totalUserCount;
            state.status.getTotalUser = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getTotalUser.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getTotalUser = ERROR;
        state.isError = true;
      })
  },
});

export default dashboardSlice.reducer;
export const { clearErrorSlice, setError } = dashboardSlice.actions;
