import React, { useState } from 'react'
import './Gaming.css'
import DealProduct from '../../../components/dealhouseProduct/DealProduct';
import Explore from '../Explore';


const Gaming = () => {
  const [isOpen, setIsOpen] = useState(false);
    return (
        <section>
          <div className={`gaming-background-container ${isOpen?"blur":""}`} >
            <DealProduct prodTitle="Shoes" top="39.5%" left="6%" navLink="/dealhouse/gamingroom" />

            <DealProduct prodTitle="Sony Vr Pro" top="63%" left="7%" navLink="/dealhouse/gamingroom" />

            <DealProduct prodTitle="Vr Controller" top="64%" left="17%" navLink="/dealhouse/gamingroom" />
    
            <DealProduct prodTitle="Mic" top="60%" left="54.4%" navLink="/dealhouse/gamingroom" />

            <DealProduct prodTitle="Logitech G29 Driving Force" top="64%" left="60.5%" navLink="/dealhouse/gamingroom" />
  
            <DealProduct prodTitle="Gaming Moniter" top="52%" left="77%" navLink="/dealhouse/gamingroom" />
    
            <DealProduct prodTitle="Gaminr Mouse" top="69%" left="78.5%" navLink="/dealhouse/gamingroom" />

            <DealProduct prodTitle="Gameing CPU" top="63%" left="85%" navLink="/dealhouse/gamingroom" />
    
            <DealProduct prodTitle="Gameing Laptop" top="82%" left="86%" navLink="/dealhouse/gamingroom" />
          </div>
          <Explore isOpen={isOpen} setIsOpen={setIsOpen}/>
        </section>
      );
}

export default Gaming