import "./myordercarddropdown.css";

import {
  Cancellation,
  Download,
  Help,
  Packagedetails,
  // OrderAccepted,
  // PriceInfo,
} from "../../assets";
import {
  MyorderCancellation,
  MyorderPackageDetails,
  MyorderProductsupport,
} from "..";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import PopUp from "../PopUp/PopUp";
import React from "react";
import { downloadInvoiceSlice , downloadAgreementSlice } from "../../redux/features/orderSlice/orderSlice";
import { useEffect } from "react";
import { useState } from "react";
import { CANCELLED, COMPLETE, DISAPPROVED, REJECTED } from "../../constants/constants";

const Myordercarddropdown = (props) => {
  const [isProduct, setIsProduct] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isCancellation, setIsCancellation] = useState(false);
  const [isOrderconfirm, setIsOrderConfirm] = useState(false);

  useEffect(() => {
    if (isProduct || isPackage || !isCancellation || isOrderconfirm) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  // async thunk
  const dispatch = useDispatch();

  // const invoice = useSelector(
  //   (state) => state.rootReducer.orderInfo.data.invoice
  // );

  // console.log("props: ", props)
  return (
    <div
      ref={props.setPopElm}
      className="myorder-dropdown-container"
      style={{ ...props.popper.styles.popper }}
      {...props.popper.attributes}
    >
      <p
        onClick={() =>
          props.status?.orderstatus == "ACTIVE" && setIsProduct(!isProduct)
        }
        style={{
          cursor: "pointer",
          opacity: props.status?.orderstatus !== "ACTIVE" && "0.2",
        }}
      >
        <img
          src={Help}
          alt="help"
          style={{
            width: "1.25rem",
            height: "1.25rem",
            marginRight: "0.688rem",
            cursor: "pointer",
          }}
        />{" "}
        Product Support
      </p>
      <PopUp
        uniqueId={"myOrderProductSupport"}
        zIndex={10}
        center={true}
        display={isProduct}
        callback={setIsProduct}
      >
        <MyorderProductsupport
          onclick={() => setIsProduct(!isProduct)}
          orderId={props.orderId}
          userName={props?.userName}
        />
      </PopUp>
      {false && (
        <MyorderProductsupport
          onclick={() => setIsProduct(!isProduct)}
          orderId={props.orderId}
          userName={props?.userName}
        />
      )}
      <p
        onClick={() =>
          dispatch(downloadAgreementSlice({ orderId: props?.orderId }))
        }
      >
        <img
          src={Download}
          alt="download"
          style={{
            width: "0.875rem",
            height: "1.125rem",
            marginRight: "1rem",
          }}
        />{" "}
        Download Agreement
      </p>
      <p style={{ cursor: "pointer" }} onClick={() => setIsPackage(!isPackage)}>
        <img
          src={Packagedetails}
          alt="packagedetails"
          style={{
            width: "1.25rem",
            height: ".75rem",
            marginRight: "0.688rem",
          }}
        />{" "}
        Package Details
      </p>
      <PopUp
        uniqueId={"packageDetail"}
        zIndex={10}
        center={true}
        display={isPackage}
        callback={setIsPackage}
      >
        <MyorderPackageDetails
          status={props}
          onclick={() => setIsPackage(!isPackage)}
        />
      </PopUp>
      {false && (
        <MyorderPackageDetails onclick={() => setIsPackage(!isPackage)} />
      )}
      <p
        onClick={() =>
          dispatch(downloadInvoiceSlice({ orderId: props?.orderId }))
        }
      >
        <img
          src={Download}
          alt="download"
          style={{
            width: "0.875rem",
            height: "1.125rem",
            marginRight: "1rem",
          }}
        />{" "}
        Download Invoice
      </p>
      {
        (props?.status?.data?.orderStatus?.status !== COMPLETE 
          && props?.status?.data?.orderStatus?.status !== DISAPPROVED 
          && props?.status?.data?.orderStatus?.status !== CANCELLED) &&
        <p
          style={{ cursor: "pointer" }}
          onClick={() => setIsCancellation(!isCancellation)}
        >
          <img
            src={Cancellation}
            alt="Cancellation"
            style={{
              width: "1.25rem",
              height: "1.25rem",
              marginRight: "0.688rem",
            }}
          />{" "}
          Request For Cancellation
        </p>
      }
      <PopUp
        zIndex={10}
        center={true}
        display={isCancellation}
        callback={setIsCancellation}
      >
        <MyorderCancellation
          onclick={() => setIsCancellation(!isCancellation)}
          orderId={props.orderId}
          setStatus={props.setStatus}
        />
      </PopUp>
      {false && (
        <MyorderCancellation
          onclick={() => setIsCancellation(!isCancellation)}
          orderId={props.orderId}
        />
      )}
    </div>
  );
  // }
};

export default Myordercarddropdown;
