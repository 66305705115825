import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { UnauthorizedAccessStatusSlice } from '../../redux/features/loginSlice/loginSlice';

function ProtectedRoute({ children }) {
    const token  = useSelector((state) => state.rootReducer.userInfo.loginToken)
    const dispatch = useDispatch();
    const location = useSelector(
      (state) => state.rootReducer.userInfo.data.lastLocation
    );

    useEffect(() => {
      if(token === null) dispatch(UnauthorizedAccessStatusSlice(true));
    }, [])

    if (token !== null) {
      return children
    } else {
      return <Navigate to={`/${location}/home`} />
    }
}

export default ProtectedRoute