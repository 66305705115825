import {
  BASE_URL,
  ERROR,
  FULFILLED,
  LOGGEDIN,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = true;
//============================================================
export const getEmpPhoneNumber = createAsyncThunk(
  "post/admin/auth/get_phone",
  async (phone) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/auth/get_phone`, {
        phone: phone,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const verifyEmpOtp = createAsyncThunk(
  "post/admin/auth/verify_otp",
  async (payload) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/auth/verify_otp`, {
        otp: payload.otp,
        phone: payload.phone,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const verifyAdminPassword = createAsyncThunk(
  "post/admin/auth/verify_password",
  async (payload) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/auth/verify_password`, {
        password: payload.password,
        phone: payload.phone,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);


export const loginEmpByToken = createAsyncThunk(
  "get/admin/auth/token_login",
  async () => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/auth/token_login`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const empLogout = createAsyncThunk(
  "post/admin/auth/logout",
  async () => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/auth/logout`, {});
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const resendOtpThunk = createAsyncThunk(
  "post/auth/resendOtp",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/resendOtp`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  isLogin: false,
  isAdmin: false,
  isSlider: false,
  data: {
    phone: "",
    userName: "",
    image: "",
    empLocation: "",
    empId: "",
    OTPExpiry: "",
  },
  isError: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  getEmpPhoneNumberStatus: "IDLE",
  verifyEmpOtpStatus: "IDLE",
  loginEmpByTokenStatus: "IDLE",
  empLogoutStatus: "IDLE",
  resendOtpThunk: "IDLE",
};

const empInfoSlice = createSlice({
  name: "empInfo",
  initialState: initialState,
  reducers: {
    setEmpPhone: (state, action) => {
      state.data.phone = action.payload.phone;
      state.data.OTPExpiry = action.payload.expiry;
    },
    empSetLogin: (state, action) => {
      state.isLogin = action.payload.isLogin;
      state.data.userName = action.payload.userName;
      state.data.image = action.payload.image;
      state.data.empLocation = action.payload.empLocation;
    },
    setEmpSlider: (state, action) => {
      state.isSlider = action.payload.isSlider;
    },
    setSessionLogoutError: (state, { payload }) => {
      // console.log(payload);
      state.isError = true;
      state.errorData = payload;
    },
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {
        message: "",
        type: "",
        errors: [],
      };
    },
    setOtpExpiry: (state, { payload }) => {
      state.data.OTPExpiry = 0;
    },
  },
  extraReducers: (builder) => {
    builder // ===============================================================================
      // getPhone /sendOTP;
      .addCase(getEmpPhoneNumber.pending, (state, action) => {
        state.getEmpPhoneNumberStatus = "LOADING";
      })
      .addCase(getEmpPhoneNumber.fulfilled, (state, { payload }) => {
        state.getEmpPhoneNumberStatus = "FULFILLED";
      })
      .addCase(getEmpPhoneNumber.rejected, (state, action) => {
        state.getEmpPhoneNumberStatus = "FAILED";
        state.error = action.error.message;
      })
      // ===============================================================================
      // verify otp;
      .addCase(verifyEmpOtp.pending, (state, action) => {
        state.verifyEmpOtpStatus = "LOADING";
      })
      .addCase(verifyEmpOtp.fulfilled, (state, { payload }) => {
        state.verifyEmpOtpStatus = "FULFILLED";
      })
      .addCase(verifyEmpOtp.rejected, (state, action) => {
        state.verifyEmpOtpStatus = "FAILED";
        state.error = action.error.message;
      })
      // ===============================================================================
      // verify otp;
      .addCase(loginEmpByToken.pending, (state, action) => {
        state.loginEmpByTokenStatus = "LOADING";
      })
      .addCase(loginEmpByToken.fulfilled, (state, { payload }) => {
        state.loginEmpByTokenStatus = "FULFILLED";
        switch (payload.type) {
          case LOGGEDIN:
            state.data.empId = payload.data.empId;
        }
      })
      .addCase(loginEmpByToken.rejected, (state, action) => {
        state.loginEmpByTokenStatus = "FAILED";
        state.error = action.error.message;
      })
      // ===============================================================================
      // emp logout;
      .addCase(empLogout.pending, (state, action) => {
        // state.empLogoutStatus = "LOADING";
      })
      .addCase(empLogout.fulfilled, (state, { payload }) => {
        state.empLogoutStatus = "FULFILLED";
        switch (payload.type) {
          case SUCCESS:
            state.data = {
              phone: "",
              userName: "",
              image: "",
              empLocation: "",
              empId: "",
            };
            state.isLogin = false;
            break;
          default:
            // state.isError = true;
            // state.errorData = {
            //   message: payload.message,
            //   type: payload.type,
            //   errors: payload.errors,
            // };
            break;
        }
      })
      .addCase(empLogout.rejected, (state, action) => {
        state.empLogoutStatus = "FAILED";
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // resend otp thunk ===============================================
      .addCase(resendOtpThunk.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(resendOtpThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.resendOtpThunk = FULFILLED;
            state.data.OTPExpiry = payload.data.expiry;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(resendOtpThunk.rejected, (state, action) => {
        state.loading = false;
        state.resendOtpThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      });
  },
});

export default empInfoSlice.reducer;
export const {
  setEmpPhone,
  empSetLogin,
  setEmpSlider,
  setSessionLogoutError,
  clearErrorSlice,
  setOtpExpiry,
} = empInfoSlice.actions;
