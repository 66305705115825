import {
  BASE_URL,
  // REGISTERED,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
// import { swap } from "formik";

axios.defaults.withCredentials = true;

export const createNewListingRequest = createAsyncThunk(
  "/listing_request/createListignReq",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/listing_request/createListignReq`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllListingRequest = createAsyncThunk(
  "fetch_all_listing_req/get_all_listingrequest",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listing_req/get_all_listingrequest`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getlistingRequestDetail = createAsyncThunk(
  "fetch/listing_req/get_listingrequest_detail",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listing_req/get_listingrequest_detail`,
        {
          currId: id,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUsersListigRequestApp = createAsyncThunk(
  "http://localhost:5000/user/listing_request/getUserListingRequest",
  async () => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/listing_request/getUserListingRequest`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getlistingRequestUserDetail = createAsyncThunk(
  "fetch/users/get_user_detailByMid",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/users/get_user_detailByMid`,
        {
          currId: id,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeListingRequestStatusSlice = createAsyncThunk(
  "admin/listing_req/change_listingrequest_status",
  async ({ data }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listing_req/change_listingrequest_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchAlllendingCancellationRequest = createAsyncThunk(
  "fetchAll/listing_req/getall_lending_cancellationrequest",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listing_req/getall_lending_cancellationrequest`,
        {}
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const lendingCancellationRequest = createAsyncThunk(
  "user/listing_req/change_listingrequest_status",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/listing_req/change_listingrequest_status`,
        { currId: id }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateListingRequestStatus = createAsyncThunk(
  "delete/user/listing_request/remove_listingReq",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/listing_request/remove_listingReq`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getListedAssets = createAsyncThunk(
  "admin/get_listed_asset_info",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listing_req/get_listed_asset_info`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const retunAssetThunk = createAsyncThunk(
  `/admin/listing_req/return_inventory`,
  async (data) => {
    try {
      const repo = await axios.post(
        `${BASE_URL}/admin/listing_req/return_inventory`,
        data
      );
      return repo;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeLendingCancellationStatus = createAsyncThunk(
  `/admin/listing_req/change_lending_canc_status`,
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listing_req/change_lending_canc_status`,
        data
      );
      return res;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserProductsThunk = createAsyncThunk(
  `/admin/listing_req/getUserProducts`,
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/listing_req/getUserProducts`,
        data
      );
      return res;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  updateDone: false,
  newRequest: [],
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  fetchedData: [],
  data: {
    data: {
      data: [],
      total: 0,
    },
    cancelled: {
      data: [],
      total: 0,
    },
    listingreqListedAsset: [],
    userDetails: {},
    request: {},
    userProducts: [],
  },
  isError: false,
};

const listingRequestSlice = createSlice({
  name: "listingRequestSlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    notificationcount: (state, { payload }) => {
      state.newRequest = payload.data;
    },
    setError: (state, { payload }) => {
      state.isError = true;
      state.errorData = {
        message: payload.message,
        type: payload.type,
        errors: payload.errors,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewListingRequest.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createNewListingRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createNewListingRequest.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getAllListingRequest.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAllListingRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.data = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllListingRequest.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getlistingRequestDetail.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getlistingRequestDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.request = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getlistingRequestDetail.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getlistingRequestUserDetail.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getlistingRequestUserDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.userDetails = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getlistingRequestUserDetail.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getUsersListigRequestApp.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUsersListigRequestApp.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.fetchedData = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUsersListigRequestApp.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(
        changeListingRequestStatusSlice.pending,
        (state, { payload }) => {
          state.loading = true;
        }
      )
      .addCase(
        changeListingRequestStatusSlice.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.updateDone = !state.updateDone;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        changeListingRequestStatusSlice.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.isError = true;
          state.errorData = REJECTED_ERROR;
        }
      )
      .addCase(updateListingRequestStatus.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateListingRequestStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateListingRequestStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(
        fetchAlllendingCancellationRequest.pending,
        (state, { payload }) => {
          state.loading = true;
        }
      )
      .addCase(
        fetchAlllendingCancellationRequest.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.data.cancelled = payload.data;
              break;
            default:
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        fetchAlllendingCancellationRequest.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.isError = true;
          state.errorData = REJECTED_ERROR;
        }
      )
      .addCase(getListedAssets.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getListedAssets.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.listingreqListedAsset = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getListedAssets.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(retunAssetThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(retunAssetThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone(!state.updateDone);
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(retunAssetThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = false;
        state.errorData = REJECTED_ERROR;
      })
      //getUserProductsThunk======================================================================================
      .addCase(getUserProductsThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUserProductsThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.data.type) {
          case SUCCESS:
            //state.updateDone(!state.updateDone);
            state.data.userProducts = payload.data?.data?.[0]?.AssetArray;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserProductsThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = false;
        state.errorData = REJECTED_ERROR;
      });
  },
});

export default listingRequestSlice.reducer;
export const { clearErrorSlice, setError, notificationcount } =
  listingRequestSlice.actions;
