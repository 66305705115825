import "../Adminorder/adminorder.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "../Adminlongterm/longterm.css";
import "./orderView.css";

import {
  ADMIN_ORDER,
  ADMIN_ORDER_NEW_ORDER_MORE_INFO,
} from "../../../constants/links.js";
import {
  Adminnavbar,
  Adminsidebar,
  FailureAlert,
  LoadingPage,
  Neworderpopup,
} from "../../../components/index.js";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Reload, RightNavigation } from "../../../assets/index.js";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  clearErrorSlice,
  getOrderByStatusSlice,
} from "../../../redux/features/orderSlice/orderSlice.js";
import {
  dateFormatter,
  displayDeliveryTime,
  getDuration,
} from "../../../utils/displayUtils.js";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import AdminPagination from "../AdminPaginationSlide/AdminPagination.jsx";
import { Navigation } from "swiper";
import { ObjectSchema } from "yup";
import React from "react";
import TableComponent from "../../../components/AdminTableComponent/TableComponent.jsx";
import cx from "classnames";

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `;

const RefreshButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.125rem;
  height: 100%;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  .refresh--icon.refresh--icon__is-refreshing {
    animation: ${rotate} 1s infinite;
    animation-timing-function: cubic-bezier(0.42, 0.2, 0.58, 1);
  }
`;

const OrderView = ({ status }) => {
  const dispatch = useDispatch();

  const [isSlider, setIsSlider] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [disapprove, setdisapprove] = useState(false);
  const [confirmdisapprove, setconfirmdisapprove] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    setIsRefreshing(true);
  };

  useEffect(() => {
    let refreshTimer;
    if (isRefreshing) {
      refreshTimer = setTimeout(() => {
        setIsRefreshing(false);
      }, 3000);
    }
    return () => {
      clearTimeout(refreshTimer);
    };
  }, [isRefreshing]);

  useEffect(() => {
    if (isPopup) {
      document.body.style.position = "fixed";
    } else if (disapprove) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  const Changestate = (value) => {
    setIsPopup(value);
  };

  function DispprovePopup() {
    return (
      <>
        <div className="admin-order-moreopt-disapprove-popup">
          <div className="admin-order-moreopt-disapprove-popup-inner-container">
            <div className="admin-order-moreopt-disapprove-popup-inner-content">
              <div className="disapprove-popup-head">
                <p id="dispprove-title-one">Disapprove Confirmation</p>
              </div>
              <div className="disapprove-order-customer-info">
                <p id="disapprove-orderinfo-p">
                  Disapprove order no.14553 from Raji Chauhan for PS5? Dispprove
                  Order can be found in disapprove list
                </p>
              </div>
              <div className="disapprove-order-textinput-container">
                <textarea
                  name=""
                  placeholder="Reason for Disapprove"
                  id="disapprove-reason"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="disapprove-inner-last-container">
                <div className="disapprove-buttons-container">
                  <button
                    onClick={() => {
                      setdisapprove(!disapprove);
                    }}
                    id="disapproval-cancel-btn"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      setconfirmdisapprove(!confirmdisapprove);
                    }}
                    id="disapproval-main-btn"
                  >
                    Disapprove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function Confirmdisapprove() {
    return (
      <>
        <div className="disapprove-confirm-main-container">
          <div className="disapprove-confirm-outer-main-container">
            <div className="disapprove-confirn-inner-main-container">
              <div className="disapprove-confirm-title-main">
                <p id="disapprove-confirm-title-one">Order Disapproved</p>
              </div>
              <div className="disapprove-confirm-order-informartion">
                <p id="dissapprove-confirm-orderno-information">
                  Order no.2649 has been dissapproved. Lorem ipsum dolor sit
                  amet consectetur
                </p>
              </div>
              <div className="dissapprove-confirm-done-button">
                <button
                  onClick={() => {
                    setdisapprove(!disapprove);
                    setconfirmdisapprove(!confirmdisapprove);
                  }}
                  id="dissapprove-confirm-done"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function Moreoptionpopup(props) {
    return (
      <>
        <div className="admin-new-order-popup-container">
          {/* <p id="approve">Approve</p>
          <p id="disapprove">Disapprove</p> */}
          {disapprove && <DispprovePopup />}
          {confirmdisapprove && <Confirmdisapprove />}
          <Link to={ADMIN_ORDER_NEW_ORDER_MORE_INFO(props.id)}>
            More Information{" "}
          </Link>
        </div>
      </>
    );
  }

  // Async thunk
  const getOrderReq = useSelector(
    (state) => state.rootReducer.orderInfo.data.req
  );

  // console.log(getOrderReq);

  const updateDone = useSelector(
    (state) => state.rootReducer.orderInfo.updateDone
  );

  // data for table
  const tableheaders = [
    "ID",
    "Name",
    "Package",
    "Date",
    "Tenure",
    "DeliveryTime",
    "Amount",
    "Order Status",
  ];

  const orderParser = (data) => {
    let array = [];
    for (const idx in data) {
      let temp = [];
      let allData = {};
      temp.push(parseInt(idx) + 1);
      temp.push(data?.[idx]?.customId);
      temp.push(data?.[idx]?.packageInfo?.packageName);
      temp.push(dateFormatter(data?.[idx]?.startDate));
      temp.push(data?.[idx]?.tenure + "Days");
      temp.push(
        displayDeliveryTime(
          data?.[idx]?.deliveryTime?.startHour,
          data?.[idx]?.deliveryTime?.endHour
        )
      );
      temp.push("₹ " + data?.[idx]?.totalPrice);

      temp.push(data?.[idx]?.orderStatus?.status);

      allData["navigateTo"] = data?.[idx]?.customId;
      allData["displayData"] = temp;
      array.push(allData);
    }
    return array;
  };

  // Constant Error
  const loading = useSelector((state) => state.rootReducer.orderInfo.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.orderInfo.isError
  );
  const error = useSelector((state) => state.rootReducer.orderInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  const pageSize = 10;
  const paginationArray = [...Array(getOrderReq.total).keys()];
  const paginationSlides = paginationArray.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 3);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    },
    []
  );

  //--------------------------
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState({ name: 1 });
  const [filterValue, setFilterValue] = useState("");
  const [searchParams, setSearchparams] = useSearchParams();

  const handlePagination = (page, pageSize) => {
    const params = Object.fromEntries(searchParams);
    params["page"] = page;
    params["pageSize"] = pageSize;
    setSearchparams(params);
  };
  const currpage = searchParams.get("page");
  // useEffect(() => {

  //   contactUsReq({ pageSize: pageSize, page: page, sort: sort });
  // }, [page]);

  function refreshPage() {
    window.location.reload(false);
  }

  // Expire Order Todo
  useEffect(() => {
    dispatch(
      getOrderByStatusSlice({
        status: status?.toUpperCase(),
        pageSize: pageSize,
        page: page,
        sort: sort,
        filter: {
          value: filterValue,
        },
      })
    );
  }, [page, sort, search, updateDone]);

  // useEffect(() => {
  //   dispatch(getOrderCountSlice());
  // }, [updateDone]);
  let title = status + " Orders";
  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}

      <div className="admin-order-inner-content-container">
        <div className="admin-order-navigator-container">
          <Link to={ADMIN_ORDER}>Order Page</Link>
          <img src={RightNavigation} alt="rightarow" />
          <p>{title}</p>
        </div>
        {status === "Pending" && (
          <div
            className="admin-order-button-container"
            style={{ height: "3rem", marginTop: "2rem" }}
          >
            <button
              onClick={() => setIsPopup(!isPopup)}
              className="buttonprimary"
            >
              Add Order +
            </button>
            {isPopup && (
              <Neworderpopup
                onchange={Changestate}
                slider={!isSlider}
                isPopup={isPopup}
              />
            )}
          </div>
        )}

        <div className="table-component-outer-white">
          <div className="admin-listing-req-table-header-container">
            <p>{title}</p>
            <div className="admin-listing-req-table-pagination-container">
              {/* <div className="admin-listing-req-table-pagination-content">
                <p className="prevpage">Previous</p>
                <div className="admin-listing-req-pagination-slider-container">
                  <Swiper
                    width={420}
                    slidesPerView={3}
                    spaceBetween={20}
                    centeredSlides={false}
                    slidesPerGroup={1}
                    grabCursor={true}
                    loop={true}
                    loopFillGroupWithBlank={false}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={{
                      prevEl: ".prevpage",
                      nextEl: ".nextpage",
                    }}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    {paginationSlides.map((data) => {
                      return (
                        <SwiperSlide key={data[0]}>
                          {data.map((pg) => (
                            <button
                              key={pg}
                              id={pg == page ? "active" : "inactive"}
                              onClick={() => {
                                setPage(pg);
                              }}
                            >
                              {pg + 1}
                            </button>
                          ))}
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                <p className="nextpage">Next</p>
              </div> */}
              {/* ============================ Admin Pagination ================================ */}
              <AdminPagination
                usersFetchedData={getOrderReq}
                page={currpage}
                setPage={setPage}
                pageSize={pageSize}
                handlePagination={handlePagination}
              />

              {/* =========================================================================== */}
              <RefreshButton onClick={handleRefresh}>
                <img
                  src={Reload}
                  alt="reload"
                  className={cx({
                    "refresh--icon": true,
                    "refresh--icon__is-refreshing": isRefreshing,
                  })}
                />
              </RefreshButton>
            </div>
          </div>

          <TableComponent
            tableheaders={tableheaders}
            tableRow={orderParser(getOrderReq?.pendingOrders)}
            Propspop={Moreoptionpopup}
            scrollblock={true}
          />
        </div>
      </div>
    </>
  );
};

export default OrderView;
