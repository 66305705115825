import "./navbar.css";

import {
  BANGLORE,
  DELHI,
  MUMBAI,
  PUNE,
  REGISTERED,
  SUCCESS,
} from "../../constants/constants.js";
import {
  Banglore,
  Cart,
  Cross,
  Delhi,
  DownArrow,
  GradientUser,
  Ham,
  HeaderLogo,
  Location,
  Logout,
  MobileSearch,
  Mumbai,
  Mycredit,
  Mylisting,
  Myorder,
  Myprofile,
  Pune,
  Recent,
  Search,
  UpArrow,
} from "../../assets";
import {
  FOR_BUSINESS,
  FOR_EVENT,
  MYORDERS,
  MY_CART,
  MY_CREDIT,
  MY_LISTING,
  MY_PROFILE,
  PRODUCT_DETAILS,
  USER_SEARCH_RESULTS,
} from "../../constants/links";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import React, { useState } from "react";
import {
  changeUserLocationPublic,
  getUserCartSlice,
  loginByToken,
  updateUserLocation,
  userLogout,
} from "../../redux/features/loginSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";

import Login from "../../pages/Login/login";
import PopUp from "../PopUp/PopUp";
import Portal from "../Portal/Portal";
import { UnauthorizedAccessStatusSlice } from "../../redux/features/loginSlice/loginSlice";
import { getPackageForSearchParamThunk } from "../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { setLaunchAuthSlice } from "../../redux/features/preLaunchSlice/preLaunchSlice";
import { testUserPhone } from "../../redux/features/testSlice/testSlice";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePopper } from "react-popper";
import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";
import axios from "axios";

// import {
//   getAllPackageNames,
//   setSearchName,
//   sortPackageNames,
// } from "../../redux/features/homePageSlice/homePageSlice";

// css

// images

// ========================== Searchsuggestion components============================
const Searchsuggestion = ({
  searchBar,
  setSearchBar,
  popperSearch,
  setPopElmSearch,
  // elementRef,
  searchResult,
  setSearchResult,
  focusedIndex,
  setFocusedIndex,
}) => {
  const allNames = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allfetchedPackages
  );

  // add Debounce
  useEffect(() => {
    setFocusedIndex(-1);
    setSearchResult(allNames);
  }, [allNames, setFocusedIndex, setSearchResult]);
  const navigate = useNavigate();
  return (
    <PopUp
      display={searchBar}
      callback={setSearchBar}
      uniqueId={"search-pop-up"}
      zIndex={10}
      center={false}
      fixed={true}
      // styles={{ background: "rgba(0,0,0,0.5)" }}
    >
      <div
        className="search-suggestion-conatainer-new"
        ref={setPopElmSearch}
        style={{ ...popperSearch?.styles?.popper }}
        {...popperSearch?.attributes}
        // ref={elementRef}
      >
        {searchResult.map((elm, idx) => {
          const isFocused = focusedIndex === idx;
          return (
            <div
              style={{
                background: isFocused
                  ? "rgb(155 155 155 / 33%)"
                  : "transparent",
              }}
              className="search-suggestion-row"
              onClick={() => {
                navigate(PRODUCT_DETAILS(elm.customId));
                setSearchBar(false);
              }}
            >
              <img src={Recent} alt="" className="search-recent" />
              {elm.name}
            </div>
          );
        })}
      </div>
    </PopUp>
  );
};
// Location pop up component
export const LocationPopUp = ({ isOpen, setIsOpen, toggleMenu }) => {
  // const [isProfile, setIsProfile] = useState(true);

  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const lastLocation = useSelector(
    (state) => state.rootReducer.userInfo.data.lastLocation
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changeUserLocation = (location) => {
    if (isLogin) {
      navigate(`/${location?.toLowerCase()}/home`);
      dispatch(updateUserLocation({ lastLocation: location })).then((data) => {
        if (data.payload.type === SUCCESS) {
          setIsOpen(false);
        }
      });
    } else {
      navigate(`/${location?.toLowerCase()}/home`);
      dispatch(changeUserLocationPublic({ lastLocation: location }));
      setIsOpen(false);
    }
  };

  return (
    <PopUp
      display={isOpen}
      callback={setIsOpen}
      uniqueId={"location-pop-up"}
      zIndex={12}
      center={true}
    >
      <div className="new-location-popup">
        <h1 className="location-select-new-city">Select your City</h1>
        <img
          src={Cross}
          alt="close"
          className="close-location-pop-up"
          onClick={() => setIsOpen(false)}
          style={{ width: "1rem", height: "1rem" }}
        />
        <div className="new-city-container">
          <div
            className={lastLocation === BANGLORE ? "city active-home" : "city"}
            onClick={() => {
              changeUserLocation(BANGLORE);
              toggleMenu(false);
            }}
          >
            <img src={Banglore} alt="" className="cityImg" />
            <p className="cityName bangloreCity">Bangalore</p>
          </div>
          <div
            className={lastLocation === MUMBAI ? "city active-home" : "city"}
            onClick={function () {
              changeUserLocation(MUMBAI);
              toggleMenu(false);
            }}
          >
            <img src={Mumbai} alt="" className="cityImg" />
            <p className="cityName mumbaiCity">Mumbai</p>
          </div>
          <div
            className={lastLocation === PUNE ? "city active-home" : "city"}
            onClick={function () {
              changeUserLocation(PUNE);
              toggleMenu(false);
            }}
          >
            <img src={Pune} alt="" className="cityImg" />
            <p className="cityName puneCity">Pune</p>
          </div>
          <div
            className={lastLocation === DELHI ? "city active-home" : "city"}
            onClick={function () {
              changeUserLocation(DELHI);
              toggleMenu(false);
            }}
          >
            <img src={Delhi} alt="" className="cityImg" />
            <p className="cityName delhiCity">Delhi</p>
          </div>
        </div>
      </div>
    </PopUp>
  );
};

// ========================== Dropdown components============================
const Dropdown = ({ setLoginPopUp, setToggleMenu }) => {
  //redux changes
  const dispatch = useDispatch();
  const userName = useSelector(
    (state) => state.rootReducer.userInfo.data.userName
  );
  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const lastLocation = useSelector(
    (state) => state.rootReducer.userInfo.data.lastLocation
  );

  // const userPhonNumber = useSelector((state) => state.rootReducer.userInfo.phone);
  // =================

  // const [isLoginOld, setIsLogin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [city, setCity] = useState("Mumbai");
  const [cityState, changeCityState] = useState({
    activeObject: null,
    objects: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
  });

  const toggleActive = (index) => {
    changeCityState({ ...cityState, activeObject: cityState.objects[index] });
  };
  const changeUserLocation = (location) => {
    if (isLogin) {
      navigate(`/${location?.toLowerCase()}/home`);
      dispatch(updateUserLocation({ lastLocation: location }));
    } else {
      navigate(`/${location?.toLowerCase()}/home`);
      dispatch(changeUserLocationPublic({ lastLocation: location }));
    }
  };

  // function toggleActiveStyles(value) {
  // if (cityState.objects[index] === cityState.activeObject) {
  //   return "city active-home";
  // } else {
  //   return "city ";
  // }
  // if (value.toUpperCase() === lastLocation) {
  //   return "city active-home";
  // } else {
  //   return "city";
  // }
  // }
  const getUserCartById = useSelector(
    (state) => state.rootReducer.userInfo.currentUserInfo
  );
  const updateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );
  const userLocation = useSelector(
    (state) => state.rootReducer.userInfo.data.lastLocation
  );

  useEffect(() => {
    // if (isLogin) {
    dispatch(getUserCartSlice());
    // }
  }, [updateDone, isLogin, dispatch]);

  const handleMenu = (path) => {
    setToggleMenu(false);
    navigate(path);
  };

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.position = "fixed";
  //   } else {
  //     document.body.style.position = "relative";
  //   }
  // }, [isOpen]);
  return (
    <div className="new-dropdown-container">
      {isLogin && (
        <ul className="display-tab">
          <li>
            <Link to="" className="backgournd-clipped">
              {userName?.split(" ")?.[0]}
            </Link>
          </li>
          <li className="display-tab">
            <Link
              to="/myprofile"
              className="new-menu-item"
              onClick={() => setToggleMenu(false)}
            >
              My Profile
            </Link>
          </li>
          <li className="display-tab">
            <Link
              to="/myorder"
              className="new-menu-item"
              onClick={() => setToggleMenu(false)}
            >
              My Order
            </Link>
          </li>
          <li className="display-tab">
            <Link
              to="/mylisting"
              className="new-menu-item"
              onClick={() => setToggleMenu(false)}
            >
              My Listing
            </Link>
          </li>
          <li className="display-tab">
            <Link
              to="/mycredit"
              className="new-menu-item"
              onClick={() => setToggleMenu(false)}
            >
              My Credit
            </Link>
          </li>
          <li className="display-tab">
            <Link
              to="#"
              onClick={() => {
                dispatch(userLogout())
                  .unwrap()
                  .then((data) => {
                    dispatch(setLaunchAuthSlice());
                    //navigate(USER_ROOT);
                    navigate("/preAuth");
                  });
              }}
              className="new-menu-item"
              style={{ color: "#B8352C", fontWeight: "600" }}
            >
              Logout
            </Link>
          </li>
          <hr />
        </ul>
      )}

      <ul>
        {!isLogin && (
          <li
            onClick={() => {
              !isLogin && setToggleMenu(false);
              setLoginPopUp(true);
            }}
            id="login-signup-links"
          >
            {/* <Link to="/login" className="new-menu-item"> */}
            Login/Signup
            {/* </Link> */}
          </li>
        )}
        <li style={{ cursor: "pointer" }}>
          <button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="backgournd-clipped"
          >
            {lastLocation
              ? lastLocation[0] + lastLocation.slice(1).toLowerCase()
              : "Select Location"}{" "}
            <img src={DownArrow} alt="downArrow" />
          </button>
          <LocationPopUp
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            toggleMenu={setToggleMenu}
          />
        </li>

        <li>
          <Link
            className="new-menu-item"
            onClick={(e) => {
              e.preventDefault();
              handleMenu(MY_CART + "?page=0&pagesize=5&sort=1");
            }}
          >
            My Cart
            {getUserCartById?.cart?.length > 0 ? (
              <span> ( {getUserCartById?.cart?.length} )</span>
            ) : (
              ""
            )}
          </Link>
        </li>

        <li>
          <Link
            className="new-menu-item"
            onClick={(e) => {
              e.preventDefault();
              handleMenu(
                `/${userLocation}/categories/?category=&subcategory=default`
              );
            }}
          >
            Categories
          </Link>
        </li>
        <hr />
        <li>
          <Link
            className="new-menu-item"
            onClick={(e) => {
              e.preventDefault();
              handleMenu(isLogin ? "/mylisting" : "/login");
            }}
          >
            List Items
          </Link>
        </li>
        <li>
          <Link
            to=""
            className="new-menu-item"
            onClick={(e) => {
              e.preventDefault();
              handleMenu("");
            }}
          >
            How Renting works
          </Link>
        </li>
        <li>
          <Link
            className="new-menu-item"
            onClick={(e) => {
              e.preventDefault();
              handleMenu(FOR_BUSINESS);
            }}
          >
            Rentkar for Business
          </Link>
        </li>
        <li>
          <Link
            className="new-menu-item"
            onClick={(e) => {
              e.preventDefault();
              handleMenu(FOR_EVENT);
            }}
          >
            Rentkar for Events
          </Link>
        </li>
        <li>
          <Link
            className="new-menu-item"
            onClick={(e) => {
              e.preventDefault();
              handleMenu("/listing_home");
            }}
          >
            Invest in Ecosystem
          </Link>
        </li>
      </ul>
    </div>
  );
};

// ========================== Navbar components============================
const Navbar = () => {
  //Redux setup
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // setter removed
  const [searchParams] = useSearchParams();
  // const elementRef = useRef();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [searchResult, setSearchResult] = useState([]);
  const [exactMatch, setExactMatch] = useState([]);

  const userName = useSelector(
    (state) => state.rootReducer.userInfo.data.userName
  );
  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  // const userPhonNumber = useSelector(
  //   (state) => state.rootReducer.userInfo.phone
  // );
  const lastLocation = useSelector(
    (state) => state.rootReducer.userInfo.data.lastLocation
  );
  //=====================

  // ===================== Hooks ==========================
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // not used in navbar component.
  // const changeUserLocation = (location) => {
  //   if (isLogin) {
  //     navigate(`/${location?.toLowerCase()}/home`);
  //     dispatch(updateUserLocation({ lastLocation: location }));
  //   } else {
  //     navigate(`/${location?.toLowerCase()}/home`);
  //     dispatch(changeUserLocationPublic({ lastLocation: location }));
  //   }
  // };

  useEffect(() => {
    dispatch(loginByToken()).then((data) => {
      if (data.payload.type === REGISTERED) {
        const data = Object.fromEntries(searchParams);
        navigate({
          path: `/registeration`,
          search: createSearchParams(data).toString(),
        });
      }
    });
  }, []);

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.position = "fixed";
  //   } else {
  //     document.body.style.position = "relative";
  //   }
  // }, [isOpen]);
  const [searchBar, setSearchBar] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  // Unused states
  // const [isClicked, setIsClicked] = useState(false);
  // const [isMobileSearch, setMobileSearch] = useState(false);
  // const [isLoginOld, setIsLogin] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  // const [city, setCity] = useState("Mumbai");
  // const [cityState, changeCityState] = useState({
  //   activeObject: null,
  //   objects: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
  // });

  // const toggleActive = (index) => {
  //   changeCityState({ ...cityState, activeObject: cityState.objects[index] });
  // };

  // function toggleActiveStyles(index) {
  //   if (cityState.objects[index] === cityState.activeObject) {
  //     return "city active-home";
  //   } else {
  //     return "city ";
  //   }
  // }

  const getUserCartById = useSelector(
    (state) => state.rootReducer.userInfo.currentUserInfo
  );

  const updateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );

  useEffect(() => {
    dispatch(getUserCartSlice());
  }, [dispatch, updateDone]);
  // useEffect(() => {
  //   setFocusedIndex(-1);
  // }, [searchResult]);
  // Popper js=====================================================
  const [refElmDrop, setRefElmDrop] = useState();
  const [popElmDrop, setPopElmDrop] = useState();
  const [refElmSearch, setRefElmSearch] = useState();
  const [popElmSearch, setPopElmSearch] = useState();
  const [refElmProfile, setRefElmProfile] = useState();

  const [popElmProfile, setPopElmProfile] = useState();
  const [loginPopUp, setLoginPopUp] = useState(false);

  const popUpParams = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };
  const popUpParamsProfile = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  };
  const popperDrop = usePopper(refElmDrop, popElmDrop, popUpParams);
  const popperSearch = usePopper(
    refElmSearch,
    // elementRef?.current,
    popElmSearch,
    popUpParams
  );
  const popperProfile = usePopper(
    refElmProfile,
    popElmProfile,
    popUpParamsProfile
  );

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  // ==============================================================
  // Search bar
  const [inputRef, setInputRef] = useState(true);

  const [showSuggestions, setShowSuggestions] = useState(false);

  const [productName, setProductName] = useState("");
  
  const [productId, setProductId] = useState("");

  const [allProducts, setAllProducts] = useState([])

  const handleSearch = async (e) => {
    const allPackages = [];
    const data = await axios.get(
      "http://localhost:5000/package/getallpackages"
    );
    console.log("data: ", data);
    allPackages.push(data.data.data);
    // allProducts.push(allPackages[0][0])
    setAllProducts(allPackages[0])
    console.log("All products in outside: ", allProducts)
    console.log("All Packages: ", allPackages);

    // allProducts.map((el) => {
    //   console.log("elem of all products: ", el.name)
    // })

    const userInput = e.target.value.toLowerCase();
    const productNames = allPackages[0][0].name.toLowerCase();
    const productIds = allPackages[0][0].customId;

    console.log("user input: ", userInput);
    console.log("product name: ", productNames);
    console.log("product custom Id: ", productIds);

    const isMatch = productNames.match(userInput);

    if (isMatch && isMatch[0] !== '') {
      setProductName(productNames);
      setProductId(productIds);
      setShowSuggestions(true);
      // alert(productName)
      // navigate(`/productdetails/${productId}`)
    } else {
      setProductId("");
      setProductName("");
      setShowSuggestions(false);
      // alert("Unavailable")
    }

    console.log("show suggestions: ", showSuggestions);

    console.log("isMatch: ", isMatch);

    // console.log("checker: ", !isMatch?.[0])

    const match = searchResult.filter((elm) => {
      return elm?.name.toLowerCase() === e.target.value.toLowerCase();
    });
    setExactMatch(match);

    // dispatch(sortPackageNames(e.target.value || ""));
    setSearchBar(true);

    dispatch(
      getPackageForSearchParamThunk({
        searchParam: e.target.value || "",
        userLocation: lastLocation,
      })
    );
  };

  const isUnauthorizedAccess = useSelector(
    (state) => state.rootReducer.userInfo.isUnauthorizedAccess
  );

  useEffect(() => {
    if (isUnauthorizedAccess) {
      setLoginPopUp(true);
      dispatch(UnauthorizedAccessStatusSlice(false));
    }
  }, [isUnauthorizedAccess]);

  const handleKeyDown = (e) => {
    if (!inputRef.valueOf()) {
      return;
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < searchResult.length - 1 ? prevIndex + 1 : prevIndex
      );
      if (popElmSearch) {
        const focusedElement = popElmSearch.children[focusedIndex + 1];
        if (focusedElement) {
          focusedElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      if (popElmSearch) {
        const focusedElement = popElmSearch.children[focusedIndex - 1];
        if (focusedElement) {
          focusedElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (focusedIndex !== -1) {
        navigate(PRODUCT_DETAILS(searchResult?.[focusedIndex]?.customId));
        //navigate(USER_SEARCH_RESULTS(lastLocation, e.target.value));
      } else {
        if (exactMatch.length !== 0) {
          navigate(PRODUCT_DETAILS(exactMatch?.[0]?.customId));
          setExactMatch([]);
        } else {
          navigate(USER_SEARCH_RESULTS(lastLocation, e.target.value));
        }
      }
      setFocusedIndex(-1);
      setSearchBar(false);
    }
  };

  useEffect(() => {
    if (!searchBar) {
      setInputRef("");
    }
  }, [searchBar]);

  const handleLink = (path) => {
    setIsProfile(false);
    navigate(path);
  };
  // useEffect(() => {
  //   // dispatch(getAllPackageNames({ userLocation: lastLocation }));
  // }, [searchTerm, lastLocation]);

  // ==============================================================
  // new window inner width
  const windowSize = useResponsiveWindow();
  return (
    <div className="navbarContainer">
      <div className="navbarContent">
        {/* logo */}
        <Link to="/" className="headerLogo">
          <img src={HeaderLogo} alt="" className="navbarLogo" />
        </Link>

        <button
          className="cityButton"
          onClick={() => {
            setIsOpen(!isOpen);
            handleLink(lastLocation);
          }}
        >
          <div className="buttonContent">
            <span className="locationLogo">
              <img src={Location} alt="" />
            </span>
            <span className="place" style={{ marginLeft: "0.2 rem" }}>
              {" "}
              {lastLocation
                ? lastLocation[0] + lastLocation.slice(1).toLowerCase()
                : "Select Location"}
            </span>
          </div>
        </button>
        <LocationPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggleMenu={setToggleMenu}
        />

        {/* search bar */}
        <div
          className={
            searchBar ? "new-search-button-after" : "new-search-button-before"
          }
          ref={setRefElmSearch}
        >
          <input
            value={inputRef}
            minLength={1}
            required={true}
            // onChange={(e) => debounce(handleSearch,2000)}
            onChange={(e) => {
              setInputRef(e.target.value);
              handleSearch(e);
            }}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={() => {
              setSearchBar(true);
            }}
            onBlur={() => {
              // setSearchBar(false);
            }}
            className={searchBar ? "search-input-after" : "search-input-before"}
            placeholder={windowSize?.phone ? "" : "Search product"}
          />
          {showSuggestions && (
            <div
              style={{
                backgroundColor: "gray",
                marginTop: "2px",
                padding: "10px",
                position: "absolute",
                top: "40px",
              }}
              className="showSuggestion"
            >
              {allProducts?.map((product) => (
                <p style={{
                  cursor: "pointer"
                }} key={product.customId} onClick={() => navigate(`/productdetails/${product.customId}`)}>{product.name}</p>
              ))}
            </div>
          )}
          <img
            className="search-img-mobile"
            src={MobileSearch}
            alt=""
            onClick={() => {
              setSearchBar(true);
              inputRef.current.focus();
            }}
          />
          {searchBar ? (
            <>
              <img
                className="search-img"
                src={Cross}
                alt=""
                onClick={() => {
                  setSearchBar(true);
                  // inputRef.current.focus();
                }}
              />
            </>
          ) : (
            <img
              className="search-img"
              src={Search}
              alt=""
              onClick={() => {
                setSearchBar(true);
                // inputRef.current.focus();
              }}
            />
          )}
        </div>
        <Searchsuggestion
          searchBar={searchBar}
          setSearchBar={setSearchBar}
          popperSearch={popperSearch}
          setPopElmSearch={setPopElmSearch}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          // elementRef={elementRef}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
        />

        {/* cart button */}
        <button className="cartButton">
          <Link
            to={MY_CART + "?page=0&pagesize=5&sort=1"}
            className="buttonContent"
            style={{ textDecorartion: "none", textDecorationLine: "none" }}
          >
            <span className="locationLogo">
              <img src={Cart} alt="" />
            </span>
            <span className="place" style={{ marginLeft: "0.5rem" }}>
              MyCart
            </span>
          </Link>
          {getUserCartById?.cart?.length > 0 ? (
            <div className="cart-count">{getUserCartById?.cart?.length}</div>
          ) : (
            ""
          )}
        </button>

        {/* login button */}
        <div
          onClick={() => {
            if (isLogin) {
              setIsProfile(true);
            }
            if (!isLogin) {
              // navigate(LOGIN);
            }
          }}
          ref={setRefElmProfile}
          className={
            isLogin
              ? "navbar-myprofile-container-new-logged-in"
              : "navbar-myprofile-container-new"
          }
          style={
            isProfile && isLogin
              ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
              : {}
          }
        >
          <PopUp
            display={loginPopUp}
            callback={setLoginPopUp}
            center={true}
            uniqueId={"loginPopup"}
          >
            <Login
              loginClose={() => {
                setLoginPopUp(false);
              }}
              isOpen={setIsOpen}
            />
          </PopUp>
          <div
            className={
              isLogin
                ? "navbar-myprofile-container-inner-logged-in"
                : "navbar-myprofile-container-inner"
            }
            style={
              isProfile
                ? {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }
                : {}
            }
            onClick={() => !isLogin && setLoginPopUp(true)}
          >
            {isLogin && (
              <img
                src={GradientUser}
                alt="gradientuser"
                style={{ width: "1rem", height: "1rem" }}
              />
            )}
            {isLogin ? userName?.split(" ")[0] : "Login/Signup"}
            {isLogin ? (
              isProfile ? (
                <img
                  src={UpArrow}
                  alt="uparrow"
                  style={{ width: "1rem", height: ".5rem" }}
                />
              ) : (
                <img
                  src={DownArrow}
                  alt="downarrow"
                  style={{ width: "1rem", height: ".5rem" }}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <PopUp
          display={isProfile && isLogin}
          callback={setIsProfile}
          uniqueId={"profile-pop-up"}
          popper={popperProfile}
        >
          <div
            className="navbar-myprofile-container-dropdown-container"
            ref={setPopElmProfile}
            style={{ ...popperProfile?.styles?.popper }}
            {...popperProfile?.attributes}
          >
            <div className="navbar-myprofile-container-dropdown">
              <div className="navbar-myprofile-container-dropdown-row">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleLink(MY_PROFILE);
                  }}
                >
                  <img src={Myprofile} alt="myprofile" />
                  My Profile
                </Link>
              </div>
              <div className="navbar-myprofile-container-dropdown-row">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleLink(MYORDERS);
                  }}
                >
                  <img src={Myorder} alt="myorder" />
                  My Order
                </Link>
              </div>
              <div className="navbar-myprofile-container-dropdown-row">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleLink(MY_LISTING);
                  }}
                >
                  <img src={Mylisting} alt="mylisting" />
                  My Listing
                </Link>
              </div>
              <div className="navbar-myprofile-container-dropdown-row">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleLink(MY_CREDIT);
                  }}
                >
                  <img src={Mycredit} alt="mycredit" />
                  My Credit
                </Link>
              </div>
              <div
                className="navbar-myprofile-container-dropdown-row"
                onClick={() => {
                  dispatch(userLogout())
                    .unwrap()
                    .then((data) => {
                      // old logic for page
                      // navigate("/login");
                      // new logic for popup
                      //navigate(USER_ROOT);
                      dispatch(setLaunchAuthSlice());
                      //navigate(USER_ROOT);
                      navigate("/preAuth");
                    });
                }}
                id={"navbar-logout-title"}
              >
                <p>
                  {/* <Link
                    to="#"
                    onClick={() => {
                      dispatch(userLogout())
                        .unwrap()
                        .then(() => {
                          //navigate(USER_ROOT);
                          dispatch(setLaunchAuthSlice())
                          //navigate(USER_ROOT);
                          navigate("/preAuth");
                        });
                    }}
                    style={{
                      color: "#B8352C",
                      fontWeight: 600,
                      fontSize: "0.78rem",
                    }}
                  /> */}
                  <img src={Logout} alt="logout" />
                  Logout
                </p>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </PopUp>

        {/* dropdown button */}
        <button
          ref={setRefElmDrop}
          className="dropButton"
          onClick={(e) => setToggleMenu(!toggleMenu)}
        >
          {toggleMenu ? (
            <img src={Cross} alt="" className="cross" />
          ) : (
            <img src={Ham} alt="" className="ham" />
          )}
        </button>
        <PopUp
          display={toggleMenu}
          callback={setToggleMenu}
          popper={popperDrop}
        >
          <div
            ref={setPopElmDrop}
            style={{ ...popperDrop?.styles?.popper }}
            {...popperDrop?.attributes}
          >
            <Dropdown
              setToggleMenu={setToggleMenu}
              setLoginPopUp={setLoginPopUp}
            />
          </div>
        </PopUp>
      </div>
    </div>
  );
};

export default Navbar;
