import "./CreditConfirmPopUp.css";

import React, { useEffect, useState } from "react";
import {
  setClick,
  setCredit,
  setIDisAgree,
} from "../../redux/features/orderSlice/orderSlice";
import { useDispatch, useSelector } from "react-redux";

import { updateCreditStatusSlice } from "../../redux/features/loginSlice/loginSlice";

// import { SmallCross } from "../../assets";

const CreditConfirmPopUp = (props) => {
  // Async thunk
  const [fetchCredit,setFetchCredit]=useState(false);
  const dispatch = useDispatch();
  const credit = useSelector((state) => state.rootReducer.orderInfo.useCredit);

  const handleYes = () => {
    dispatch(setCredit());
    dispatch(setClick());
    if (credit) {
      dispatch(
        updateCreditStatusSlice({
          cartId: props?.cartid,
          status: credit,
        })
      );
    }
    dispatch(setIDisAgree());
  };

  useEffect(()=>{
    dispatch(setCredit());
  },[fetchCredit])

  const handleNo = () => {
    dispatch(setClick());
    dispatch(setIDisAgree());
  };

  return (
    <div className="use-credit-popup-container">
      {/* <div className="use-credit-popup-close-btn">
            <img
                src={SmallCross}
                alt="cross"
                style={{
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                }}
                onClick={props.onclick}
            />
        </div> */}

      <div className="use-credit-popup-text-container">
        <p className="use-credit-popup-text">Do you want to use credits ?</p>
      </div>

      <div className="use-credit-options-container">
        <button
          id="credit-confirm-popup-yes"
          onClick={() => {
            handleYes();
            setFetchCredit(true);
          }}
        >
          Yes
        </button>
        <button
          id="credit-confirm-popup-no"
          onClick={() => {
            handleNo();
          }}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default CreditConfirmPopUp;
