import React from "react";
import "./eventPage.css";

import {
  Delivery,
  Deposit,
  Esport,
  EventPagePhone1,
  EventPagePhone2,
  EventPagePhone3,
  GoPro2,
  Laptop,
  Lbb,
  Light,
  Pc,
  Playstation,
  Playstn,
  Poster1,
  Poster2,
  Poster3,
  Poster4,
  Tech,
  Times,
  Union,
} from "../../assets";
import {
  Contactbutton,
  FailureAlert,
  Footer,
  LoadingPage,
  Scrollerbutton,
  Sliderproductcard,
} from "../../components";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import PeopleSaid from "../../components/PeopleSaid/PeopleSaid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import businessRequestQuery, {
  clearErrorSlice,
  createBusinessRequestQuerySlice,
} from "../../redux/features/businessRequestQuery/businessRequestQuerySlice.js";
import { SUCCESS } from "../../constants/constants";

const RentkarForEvents = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userLocation = useSelector(
    (state) => state.rootReducer?.userInfo?.data?.lastLocation
  );
  // console.log("from contact us page my location is ");
  // console.log(userLocation);

  // Constant Error
  const customAlert = useSelector(
    (state) => state.rootReducer.adminContactUs.isError
  );
  const error = useSelector(
    (state) => state.rootReducer.adminContactUs.errorData
  );
  const loading = useSelector(
    (state) => state.rootReducer.adminContactUs.loading
  );

  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  const createBusinessQuery = useFormik({
    initialValues: {
      name: "",
      email: "",
      company: "",
      phone: "",
      message: "",
      location: userLocation,
      contactType: "EVENTREQ",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, "Must be of 20 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phone: Yup.number("Invalid Number")
        .max(9999999999, "Invalid Number")
        .min(1000000000, "Invalid Number")
        .required("Required"),
      message: Yup.string()
        .max(300, "Must be of characters or less")
        .required("Required"),
      company: Yup.string()
        .max(300, "Must be of characters or less")
        .required("Required"),
    }),
    onSubmit: (values) => {
      // console.log({ values });
      dispatch(createBusinessRequestQuerySlice(values)).then((data) => {
        if (data.payload.type == SUCCESS) {
          navigate("/");
        }
      });
    },
  });

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div className="business-grow-with-us-container">
        <div className="business-grow-with-us-content">
          <div className="event-grow-with-us-left-container">
            <p id="grow-text">Organize Event with Rentkar </p>
            <p id="grow-desc">
              Elevate Your Occasion with Unmatched Equipment Rentals Count on
              Rentkar to be a trusted partner for all your event needs. From
              college festivals and corporate gatherings to music festivals and
              private celebrations, we've got everything you require covered.
              <br />
              <br />
              From consoles and custom PCs to VR sets, musical instruments,
              stage and sound equipment, and much more, our extensive inventory
              ensures that your event is equipped with everything it needs to
              shine. / to offer a hassle free and memorable experience to your
              audience.
            </p>
          </div>
          <div className="business-grow-with-us-right-container">
            <div className="validation-error-outline-box-991 text-center mx-2 text-danger">
              <label htmlFor="">
                {createBusinessQuery.errors.name &&
                createBusinessQuery.touched.name
                  ? createBusinessQuery.errors.name
                  : ""}
              </label>
            </div>
            <input
              type="text"
              name="name"
              id="name"
              value={createBusinessQuery.values.name}
              onChange={createBusinessQuery.handleChange}
              onBlur={createBusinessQuery.handleBlur}
              placeholder="Your Name"
            />
            <div className="validation-error-outline-box-991 text-center mx-2 text-danger">
              <label htmlFor="">
                {createBusinessQuery.errors.company &&
                createBusinessQuery.touched.company
                  ? createBusinessQuery.errors.company
                  : ""}
              </label>
            </div>
            <input
              type="text"
              name="company"
              id="company"
              value={createBusinessQuery.values.company}
              onChange={createBusinessQuery.handleChange}
              onBlur={createBusinessQuery.handleBlur}
              placeholder="Company Name"
            />
            <div className="validation-error-outline-box-991 text-center mx-2 text-danger">
              <label htmlFor="">
                {createBusinessQuery.errors.phone &&
                createBusinessQuery.touched.phone
                  ? createBusinessQuery.errors.phone
                  : ""}
              </label>
            </div>
            <input
              type="number"
              name="phone"
              id="phone"
              value={createBusinessQuery.values.phone}
              onChange={createBusinessQuery.handleChange}
              onBlur={createBusinessQuery.handleBlur}
              onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
              placeholder="Mobile-Number"
              maxLength={10}
            />
            <div className="validation-error-outline-box-991 text-center mx-2 text-danger">
              <label htmlFor="">
                {createBusinessQuery.errors.email &&
                createBusinessQuery.touched.email
                  ? createBusinessQuery.errors.email
                  : ""}
              </label>
            </div>
            <input
              type="email"
              name="email"
              id="email"
              value={createBusinessQuery.values.email}
              onChange={createBusinessQuery.handleChange}
              onBlur={createBusinessQuery.handleBlur}
              placeholder="Email Id"
            />
            <div className="validation-error-outline-box-991 text-center mx-2 text-danger">
              <label htmlFor="">
                {createBusinessQuery.errors.message &&
                createBusinessQuery.touched.message
                  ? createBusinessQuery.errors.namessagee
                  : ""}
              </label>
            </div>
            <textarea
              name="message"
              id="message"
              value={createBusinessQuery.values.message}
              onChange={createBusinessQuery.handleChange}
              onBlur={createBusinessQuery.handleBlur}
              cols="30"
              rows="10"
              placeholder="Name of Products Required"
            ></textarea>
            <button type="submit" onClick={createBusinessQuery.handleSubmit}>
              Send Message
            </button>
          </div>
        </div>
      </div>

      <div className="business-catogeries-container">
        <div className="business-catogeries-content">
          <p style={{ marginTop: "2.5rem" }}>Categories We Provide</p>
          <div className="business-catogeries-card-container">
            <div className="business-features-content">
              <div className="business-catogeries-card">
                <img src={Playstn} alt="playstation" />
                <p>
                  <span>Gaming</span>
                </p>
              </div>
            </div>

            <div className="business-features-content">
              <div className="business-catogeries-card">
                <img src={Playstation} alt="playstation" />
                <p>
                  <span>Musical</span>
                </p>
              </div>
            </div>

            <div className="business-features-content">
              <div className="business-features-content">
                <div className="business-catogeries-card">
                  <img src={GoPro2} alt="camera" />
                  <p>
                    <span>Cameras</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="business-features-content">
              <div className="business-catogeries-card">
                <img src={Laptop} alt="laptop" />
                <p>
                  <span>Laptops</span>
                </p>
              </div>
            </div>

            <div className="business-features-content">
              <div className="business-catogeries-card">
                <img src={Pc} alt="pc" />
                <p>
                  <span>Computers</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="poster-swiper"
        loop={true}
      >
        {[Poster1, Poster2, Poster3, Poster4].map((elm, idx) => (
          <SwiperSlide>
            <div
              key={idx}
              className={`carousel-item active`}
              style={{ borderRadius: "15px", height: "100%" }}
            >
              <img
                style={{
                  borderRadius: "15px",
                  width: "70%",
                  height: "100%",
                  //added
                  objectFit: "fill",
                }}
                // src={HeaderImg}
                src={elm}
                className="d-block w-100 h-100 headerImg"
                alt="..."
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className="business-catogeries-container"
        style={{ height: "fit-content" }}
      >
        <div className="business-catogeries-content">
          <h1 className="trendText mb-5">
            Need Product for any event Contact Us{" "}
          </h1>
          <div className="event-catogeries-card-container overflow-x-scroll h-100">
            <div className="d-flex row justify-content-center align-content-center text-center gap-3 event-image-container">
              <img src={EventPagePhone1} className="event-image" alt="icons" />
              <h1 className="display-6 text-white event-trendText">
                COLLEGE FEST
              </h1>
            </div>
            <div className="d-flex row justify-content-center align-content-center text-center gap-3 event-image-container">
              <img src={EventPagePhone2} className="event-image" alt="icons" />
              <h1 className="display-6 text-white event-trendText">
                BIRTHDAY PARTY
              </h1>
            </div>
            <div className="d-flex row justify-content-center align-content-center text-center gap-3 event-image-container">
              <img src={EventPagePhone3} className="event-image" alt="icons" />
              <h1 className="display-6 text-white event-trendText">
                COMPETITION
              </h1>
            </div>
          </div>
          <p
            className="text-center mb-2 m-5 font-1rem event-trendText"
            style={{ fontWeight: "350" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna,
            tincidunt proin elit nisi, id Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Urna, tincidunt proin elit nisi, id
            bLorem ipsum dolor sit amet, consectetur adipiscing elit. Urna,
            tincidunt proin elit nisi, id{" "}
          </p>
        </div>
      </div>

      <div className="product-help-business-container">
        <div className="product-help-business-content">
          <Sliderproductcard text="Products To help Your Business Grow" />
        </div>
      </div>

      {/* featured container */}

      <PeopleSaid key={window.screen.width} />

      <Scrollerbutton />

      <Contactbutton />

      <Footer />
    </>
  );
};

export default RentkarForEvents;
