import "./sliderproductcard.css";

import React from "react";

const TrendingTag = (props) => {
  const { name, background } = props.trend;

  return (
    <div className="trending-tag" style={{ background: background }}>
      {name}
      <div className="trending-tag-edge" style={{ background: background }}>
        <div id="treding-dark-portion"></div>
      </div>
    </div>
  );
};

export default TrendingTag;
