import {
  BASE_URL,
  ERROR,
  FAILURE,
  FULFILLED,
  IDLE,
  LOADING,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

// export const getAllAddonsSlice = createAsyncThunk(
//     "get/admin_frontend/addon/get_all_addons_global",
//     async () => {
//         try {
//             const res = await axios.get(`${BASE_URL}/admin_frontend/addon/get_all_addons_global`);
//             return res.data;
//         } catch (error) {
//             return error.response.data
//         }
//     }
// )

// export const getAllCouponsSlice = createAsyncThunk(
//     "fetch/admin/admin_frontend/coupon/fetchCoupons",
//     async () => {
//         try {
//             const res = await axios.get(`${BASE_URL}/admin/admin_frontend/coupon/fetchCoupons`);
//             return res.data;
//         } catch (error) {
//             return error.response.data
//         }
//     }
// )

// export const getAllLocationsSlice = createAsyncThunk(
//     "get/admin/location/get_all_location",
//     async () => {
//         try {
//             const res = await axios.get(`${BASE_URL}/admin/location/get_all_location`);
//             return res.data;
//         } catch (error) {
//             return error.response.data
//         }
//     }
// )

export const createAdminOrderSlice = createAsyncThunk(
  "post/admin/order/createOrder",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/order/createOrder`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOrderByStatusSlice = createAsyncThunk(
  "post/admin/order/pendingOrder",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/order/pendingOrder`, {
        status: data.status,
        pagesize: data.pageSize,
        page: data.page,
        sort: data.sort,
        filter: {
          value: data.filter.value,
        },
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOrderByIdSlice = createAsyncThunk(
  "post/admin/order/getOrder",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/order/getOrder`, {
        orderId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserOrderByIdSlice = createAsyncThunk(
  "post/user/order/getOrder",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/order/getOrder`, {
        orderId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeOrderStatusSlice = createAsyncThunk(
  "post/admin/order/changeOrderStatus",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/changeOrderStatus`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getLongTermOrdersSlice = createAsyncThunk(
  "post/admin/order/longtermorder",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/longtermorder`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getShortTermOrdersSlice = createAsyncThunk(
  "post/admin/order/shorttermorder",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/shorttermorder`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createUserOrderSlice = createAsyncThunk(
  "post/user/order/createUserOrder",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/order/createUserOrder`, {
        cartId: id,
      });

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDailyDeliveryOrderSlice = createAsyncThunk(
  "post/admin/order/dailyDeliveryOrder",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/dailyDeliveryOrder`,
        {
          showByDate: data.showByDate,
          startDate: data.startDate,
          pagesize: data.pageSize,
          page: data.page,
          sort: data.sort,
          filter: {
            value: data.filter.value,
          },
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDailyPickupOrderSlice = createAsyncThunk(
  "post/admin/order/dailyPickupOrder",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/order/dailyPickupOrder`, {
        showByDate: data.showByDate,
        endDate: data.endDate,
        pagesize: data.pageSize,
        page: data.page,
        sort: data.sort,
        filter: {
          value: data.filter.value,
        },
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// export const getOrderCountSlice = createAsyncThunk(
//   "get/admin/order/getOrdersCount",
//   async () => {
//     try {
//       const res = await axios.get(`${BASE_URL}/admin/order/getOrdersCount`);

//       return res.data;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );

export const upOrderPortersDetailsSlice = createAsyncThunk(
  "post/admin/order/upOrderPortersDetails",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/upOrderPortersDetails`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const upOrderAssignAssetsSlice = createAsyncThunk(
  "post/admin/order/upOrderAssignAssets",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/upOrderAssignAssets`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const upOrderDeleteAssetsSlice = createAsyncThunk(
  "post/admin/order/upOrderDeleteAssets",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/upOrderDeleteAssets`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const upOrderUploadImageSlice = createAsyncThunk(
  "post/admin/order/upOrderUploadImage",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/upOrderUploadImage`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const upOrderDeleteImageSlice = createAsyncThunk(
  "post/admin/order/upOrderDeleteImage",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/upOrderDeleteImage`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const orderAssignedDetailsSlice = createAsyncThunk(
  "post/admin/order/orderAssignedDetails",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/orderAssignedDetails`,
        {
          orderId: id,
        }
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const disapprovedOrderDetailsSlice = createAsyncThunk(
  "post/admin/order/disApprovedOrders",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/disApprovedOrders`,
        {
          pagesize: data.pageSize,
          page: data.page,
          sort: data.sort,
          filter: {
            value: data.filter.value,
          },
          packageName: data.packageName,
        }
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteOrderByIdSlice = createAsyncThunk(
  "post/admin/order/deleteOrderById",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/order/deleteOrderById`, {
        orderId: id,
      });

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeAssetsStatusSlice = createAsyncThunk(
  "post/admin/order/changeAssetsStatus",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/changeAssetsStatus`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserOrdersSlice = createAsyncThunk(
  "post/user/order/getUserOrders",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/order/getUserOrders`, {
        status: data.status,
        pagesize: data.pageSize,
        page: data.page,
        sort: data.sort,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const pushExtensionOfferSlice = createAsyncThunk(
  "post/admin/order/pushExtensionOffer",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/pushExtensionOffer`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getExtensionOfferSlice = createAsyncThunk(
  "post/admin/order/getAllExtensionOffer",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/getAllExtensionOffer`,
        {
          orderId: id,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteExtensionOfferSlice = createAsyncThunk(
  "post/admin/order/deleteExtensionOffer",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/deleteExtensionOffer`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserExtensionOfferSlice = createAsyncThunk(
  "post/user/order/getUserExtensionOffer",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/getUserExtensionOffer`,
        {
          orderId: id,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addUserProductSupportSlice = createAsyncThunk(
  "post/user/order/addUserProductSupport",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/addUserProductSupport`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Needs to change route and modification in api #TODO
export const cancelUserOrderSlice = createAsyncThunk(
  "put/user/order/cancelUserOrder",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/user/order/cancelUserOrder`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSingleOrderSlice = createAsyncThunk(
  "post/user/order/getSingleOrder",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/order/getSingleOrder`, {
        orderId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOfferPriceSlice = createAsyncThunk(
  "post/user/price/getOfferPrice",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/price/getOfferPrice`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const extendOrderSlice = createAsyncThunk(
  "post/user/order/extendOrder",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/order/extendOrder`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllOrderProdSuppSlice = createAsyncThunk(
  "post/admin/order/getOrderProdSupp",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/order/getOrderProdSupp`, {
        pageSize: data.pageSize,
        page: data.page,
        sort: data.sort,
        filters: data.filters,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPagintedPendingUserPaymentsThunk = createAsyncThunk(
  "post/admin/order/get_pending_user_payments",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/get_pending_user_payments`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deAssignAssetAndUpdateOrderThunk = createAsyncThunk(
  "post/admin/order/deassign_assets_update_order",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/deassign_assets_update_order`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// counts
export const allOrderCountThunk = createAsyncThunk(
  "get/admin/order/get_order_all_count",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/order/get_order_all_count`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getReuploadDocumentOrdersCount = createAsyncThunk(
  "get/admin/order/get_reupload_order_count",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/order/get_reupload_order_count`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const sendExtReqSlice = createAsyncThunk(
  "post/user/order/sendExtReq",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/order/sendExtReq`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getExtReqSlice = createAsyncThunk(
  "post/admin/order/getExtReq",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/order/getExtReq`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getProdSuppMoreInfoSlice = createAsyncThunk(
  "post/admin/order/getProdSuppMoreInfo",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/getProdSuppMoreInfo`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeProdSuppStatusSlice = createAsyncThunk(
  "post/admin/order/changeProdSuppStatus",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/changeProdSuppStatus`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const downloadInvoiceSlice = createAsyncThunk(
  "post/user/order/downloadInvoice",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/downloadInvoice`,
        data,
        {
          responseType: "blob",
          type: "application/zip",
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoices.zip");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        return {
          type: FAILURE,
          message: "payment has not been initiated yet",
          error: [],
        };
      }
      if (error.response.status === 404) {
        return {
          type: FAILURE,
          message: "order has not been approved yet",
          error: [],
        };
      }
      return error.response.data;
    }
  }
);

export const downloadAgreementSlice = createAsyncThunk(
  "post/user/order/downloadAgreement",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/downloadAgreement`,
        data,
        {
          responseType: "blob",
          type: "application/zip",
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "agreement.zip");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        return {
          type: FAILURE,
          message: "payment has not been initiated yet",
          error: [],
        };
      }
      if (error.response.status === 404) {
        return {
          type: FAILURE,
          message: "order has not been approved yet",
          error: [],
        };
      }
      return error.response.data;
    }
  }
);

export const getPackageCustomIdThunk = createAsyncThunk(
  "post/user/order/getPackageCustomId",
  async (data) => {
    try {
      const body = {
        packageId: data,
      };
      const res = await axios.post(
        `${BASE_URL}/user/order/getPackageCustomId`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//AP Rejected documents task
export const updateOrderAndDocumentSlice = createAsyncThunk(
  "post/user/order/updateOrderAndDocumentSlice",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/updateOrderAndDocument`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getExtendedOrderPriceDetails = createAsyncThunk(
  "post/user/order/getExtendedOrderPriceDetails",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/extendOrderPriceDetailsGetter`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateOrderUseCredit = createAsyncThunk(
  "post/user/order/update_use_credit_by_orderid",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/update_use_credit_by_orderid`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const renatalCancellationAdmin = createAsyncThunk(
  "post/admin/order/renatalCancellationAdmin",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/renatalCancellationAdmin`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateOrderPenaltyStatus = createAsyncThunk(
  "post/admin/order/update_order_penalty_status",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/update_order_penalty_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateOrderPenaltyPercentage = createAsyncThunk(
  "post/admin/order/update_order_penalty_percentage",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/update_order_penalty_percentage`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const verifyAddressDocument = createAsyncThunk(
  "post/admin/order/verify_document",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/admin/order/verify_document`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const rejectAddressDocument = createAsyncThunk(
  "post/admin/order/reject_document",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/admin/order/reject_document`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOrderByAddressId = createAsyncThunk(
  "post/admin/order/get_order_address",
  async (addressId) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/get_order_address`,
        {
          addressId: addressId,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const reuploadOrderDocuments = createAsyncThunk(
  "post/user/order/reupload_order_documents",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/user/order/reupload_order_documents`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const assignSelfieProofStatusThunk = createAsyncThunk(
  "post/admin/order/selfie_proof_assign_status",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/selfie_proof_assign_status`,
        {
          orderId: payload.OrderId,
          status: payload.status,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const assignAddressProofStatusThunk = createAsyncThunk(
  "post/admin/order/address_proof_assign_status",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/address_proof_assign_status`,
        {
          addressId: payload.addressId,
          status: payload.status,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserAddressById = createAsyncThunk(
  "post/user/order/get_user_address_by_id",
  async (addressId) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/get_user_address_by_id`,
        {
          addressId: addressId,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const reuploadUserAddressSlice = createAsyncThunk(
  "post/user/order/address_reupload",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/address_reupload`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateOrderRemainderStatus = createAsyncThunk(
  "post/admin/order/update_order_reminder_status",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/update_order_reminder_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserAddressByIdAdmin = createAsyncThunk(
  "post/admin/order/get_user_address_by_id_admin",
  async (addressId) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/get_user_address_by_id_admin`,
        {
          addressId: addressId,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateOrderStatusSlice = createAsyncThunk(
  "post/user/order/updateUserOrderStatus",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/updateUserOrderStatus`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getFirstPendingPayment = createAsyncThunk(
  "post/user/order/get_first_pending_payment",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/get_first_pending_payment`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateOrderRemainderStatusUser = createAsyncThunk(
  "post/user/order/update_order_reminder_status",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/order/update_order_reminder_status`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const assignBankStatementStatusThunk = createAsyncThunk(
  "post/admin/order/assign_bank_statement_status",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/order/assign_bank_statement_status`,
        {
          orderId: payload.OrderId,
          status: payload.status,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const bankStatementReuploadThunk = createAsyncThunk(
  "post/user/order/reupload_bank_statement_document",
  async (data) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/user/order/reupload_bank_statement_document`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  agree: false,
  useCredit: false,
  isClick: false,
  loading: false,
  changeReminderStatus: false,
  selfieImageURL: "",
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  updateDone: false,
  isError: false,
  filter: {},
  sort: {},
  gstDetails: {
    companyName: "",
    gstNo: "",
    gstAddress: "",
  },
  currentOrderId: "",
  data: {
    counts: {
      disapprovedOrders: 0,
      longTermOrders: 0,
      shortTermOrders: 0,
      dailyLogistic: 0,
      newOrders: 0,
      pendingPayments: 0,
      cancelled: 0,
      extensionReq: 0,
      newOrders: 0,
      reuploadOrder: 0,
      expiredOrder: 0,
      completedOrder: 0,
    },
    orderCreated: {},
    req: {
      total: 0,
      pendingOrders: [],
    },
    reuploadOrders: {
      total: 0,
      reuploadOrders: [],
      reuploadOrdersInfo: [],
    },
    dailyDeliveryOrders: {
      total: 0,
      data: [],
    },
    dailyPickupOrders: {
      total: 0,
      data: [],
    },
    disApprovedOrders: {
      total: 0,
      data: [],
    },
    userOrders: {
      total: 0,
      data: [],
    },
    allProdSupp: {
      total: 0,
      data: [],
    },
    pendingUserPayments: {
      total: 0,
      data: [],
    },
    extReq: {
      total: 0,
      data: [],
    },
    invocice: [],
    currentOrderInfo: {},
    longTermOrders: [],
    shortTermOrders: [],
    // ordersCount: [],
    extensionOffer: [],
    currentUserOrder: {},
    priceData: {},
    prodSuppMoreInfo: {},
    verifiedDocument: false,
    reminderStatus: false,
    documentReject: "", // not used yet
    currentAddress: {},
    pendingPayments: {},
  },
  status: {
    createAdminOrderSlice: IDLE,
    getOrderByStatusSlice: IDLE,
    getOrderByIdSlice: IDLE,
    changeOrderStatusSlice: IDLE,
    getLongTermOrdersSlice: IDLE,
    getShortTermOrdersSlice: IDLE,
    createUserOrderSlice: IDLE,
    getDailyDeliveryOrderSlice: IDLE,
    getDailyPickupOrderSlice: IDLE,
    upOrderPortersDetailsSlice: IDLE,
    upOrderAssignAssetsSlice: IDLE,
    upOrderDeleteAssetsSlice: IDLE,
    upOrderUploadImageSlice: IDLE,
    upOrderDeleteImageSlice: IDLE,
    orderAssignedDetailsSlice: IDLE,
    getOrderCountSlice: IDLE,
    disapprovedOrderDetailsSlice: IDLE,
    deleteOrderByIdSlice: IDLE,
    changeAssetsStatusSlice: IDLE,
    getUserOrdersSlice: IDLE,
    pushExtensionOfferSlice: IDLE,
    getExtensionOfferSlice: IDLE,
    deleteExtensionOfferSlice: IDLE,
    getUserExtensionOfferSlice: IDLE,
    cancelUserOrderSlice: IDLE,
    currentUserOrderSlice: IDLE,
    getOfferPriceSlice: IDLE,
    extendOrderSlice: IDLE,
    getAllOrderProdSuppSlice: IDLE,
    allOrderCountThunk: IDLE,
    getReuploadDocumentOrdersCount: IDLE,
    getPagintedPendingUserPaymentsThunk: IDLE,
    sendExtReqSlice: IDLE,
    getExtReqSlice: IDLE,
    getProdSuppMoreInfoSlice: IDLE,
    changeProdSuppStatusSlice: IDLE,
    downloadInvoiceSlice: IDLE,
    downloadAgreementSlice:IDLE,
    updateOrderPenaltyStatus: IDLE,
    updateOrderPenaltyPercentage: IDLE,
    verifyAddressDocument: IDLE,
    rejectAddressDocument: IDLE,
    getOrderByAddressId: IDLE,
    assignSelfieProofStatusThunk: IDLE,
    assignAddressProofStatusThunk: IDLE,
    getUserAddressById: IDLE,
    reuploadUserAddressSlice: IDLE,
    updateOrderStatusSlice: IDLE,
    reuploadOrderDocuments: IDLE,
    getUserAddressByIdAdmin: IDLE,
    updateOrderRemainderStatus: IDLE,
    getFirstPendingPayment: IDLE,
    updateOrderRemainderStatusUser: IDLE,
    assignBankStatementStatusThunk: IDLE,
    bankStatementReuploadThunk: IDLE,
  },
};

const orderSlice = createSlice({
  name: "orderSlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setGstDetails: (state, action) => {
      state.gstDetails = action.payload;
    },
    setError: (state, { payload }) => {
      state.isError = true;
      state.errorData = {
        message: payload.message,
        type: payload.type,
        errors: payload.errors,
      };
    },
    setIAgree: (state) => {
      state.agree = true;
    },
    setIDisAgree: (state) => {
      state.agree = false;
    },
    setClick: (state) => {
      state.isClick = !state.isClick;
    },
    setChangeReminderStatus: (state) => {
      state.changeReminderStatus = !state.changeReminderStatus
    },
    setCredit: (state) => {
      state.useCredit = !state.useCredit;
    },
    setSelfieImageURL: (state) => {
      state.selfieImageURL = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create admin order ===============================================
      .addCase(createAdminOrderSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(createAdminOrderSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.createAdminOrderSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createAdminOrderSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.createAdminOrderSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get order by status ===============================================
      .addCase(getOrderByStatusSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getOrderByStatusSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.req = payload.data;
            state.status.getOrderByStatusSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getOrderByStatusSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getOrderByStatusSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get order by id ===============================================
      .addCase(getOrderByIdSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getOrderByIdSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentOrderInfo = payload.data;
            state.status.getOrderByIdSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getOrderByIdSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getOrderByIdSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // to get order by orderId for client side
      .addCase(getUserOrderByIdSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserOrderByIdSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentOrderInfo = payload.data;
            state.status.getOrderByIdSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserOrderByIdSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getOrderByIdSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      //

      // Change Order Status ===============================================
      .addCase(changeOrderStatusSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(changeOrderStatusSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.changeOrderStatusSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(changeOrderStatusSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.changeOrderStatusSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // fetch all long term orders ===============================================
      .addCase(getLongTermOrdersSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getLongTermOrdersSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.longTermOrders = payload.data;
            state.status.getLongTermOrdersSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getLongTermOrdersSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getLongTermOrdersSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // fetch all short term orders ===============================================
      .addCase(getShortTermOrdersSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getShortTermOrdersSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.shortTermOrders = payload.data;
            state.status.getShortTermOrdersSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getShortTermOrdersSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getShortTermOrdersSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // create user orders ===============================================
      .addCase(createUserOrderSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(createUserOrderSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.orderCreated = payload.data;
            state.updateDone = !state.updateDone;
            state.status.createUserOrderSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createUserOrderSlice.rejected, (state, action) => {
        state.errorData = action.payload?.error;
        state.loading = false;
        state.status.createUserOrderSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // daily delivery orders ===============================================
      .addCase(getDailyDeliveryOrderSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getDailyDeliveryOrderSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.dailyDeliveryOrders = payload.data;
            state.status.getDailyDeliveryOrderSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getDailyDeliveryOrderSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getDailyDeliveryOrderSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // fetch daily pickup orders ===============================================
      .addCase(getDailyPickupOrderSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getDailyPickupOrderSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.dailyPickupOrders = payload.data;
            state.status.getDailyPickupOrderSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getDailyPickupOrderSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getDailyPickupOrderSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // add porters details ===============================================
      .addCase(upOrderPortersDetailsSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(upOrderPortersDetailsSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.upOrderPortersDetailsSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(upOrderPortersDetailsSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.upOrderPortersDetailsSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // assign assets details ===============================================
      .addCase(upOrderAssignAssetsSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(upOrderAssignAssetsSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.upOrderAssignAssetsSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(upOrderAssignAssetsSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.upOrderAssignAssetsSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // delete assets from orders  ===============================================
      .addCase(upOrderDeleteAssetsSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(upOrderDeleteAssetsSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.upOrderDeleteAssetsSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(upOrderDeleteAssetsSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.upOrderDeleteAssetsSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // upload package image  ===============================================
      .addCase(upOrderUploadImageSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(upOrderUploadImageSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.upOrderUploadImageSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(upOrderUploadImageSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.upOrderUploadImageSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // delete package image  ===============================================
      .addCase(upOrderDeleteImageSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(upOrderDeleteImageSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.upOrderDeleteImageSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(upOrderDeleteImageSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.upOrderDeleteImageSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get order assigned details
      .addCase(orderAssignedDetailsSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(orderAssignedDetailsSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentOrderInfo = payload.data;
            state.status.upOrderPortersDetailsSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(orderAssignedDetailsSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.orderAssignedDetailsSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // // get orders count
      // .addCase(getOrderCountSlice.pending, (state, action) => {
      //   // state.status.getContactUsSlice = LOADING;
      //   state.loading = true;
      // })
      // .addCase(getOrderCountSlice.fulfilled, (state, { payload }) => {
      //   switch (payload.type) {
      //     case SUCCESS:
      //       state.loading = false;
      //       state.data.ordersCount = payload.data;
      //       state.status.getOrderCountSlice = FULFILLED;
      //       break;
      //     default:
      //       state.loading = false;
      //       state.isError = true;
      //       state.errorData = {
      //         message: payload.message,
      //         type: payload.type,
      //         errors: payload.errors,
      //       };
      //   }
      // })
      // .addCase(getOrderCountSlice.rejected, (state, action) => {
      //   state.errorData = action.payload.error;
      //   state.loading = false;
      //   state.status.getOrderCountSlice = ERROR;
      //   state.isError = true;
      //   state.errorData = REJECTED_ERROR;
      // })

      // get disapproved orders
      .addCase(disapprovedOrderDetailsSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(disapprovedOrderDetailsSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.disApprovedOrders = payload.data;
            state.status.disapprovedOrderDetailsSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(disapprovedOrderDetailsSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getOrderCountSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // delete order by id
      .addCase(deleteOrderByIdSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(deleteOrderByIdSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deleteOrderByIdSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteOrderByIdSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deleteOrderByIdSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // deAssigned Assets
      .addCase(changeAssetsStatusSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(changeAssetsStatusSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.changeAssetsStatusSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(changeAssetsStatusSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.changeAssetsStatusSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get all users orders
      .addCase(getUserOrdersSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserOrdersSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.userOrders = payload.data;
            state.status.getUserOrdersSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserOrdersSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getUserOrdersSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // push extension offer
      .addCase(pushExtensionOfferSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(pushExtensionOfferSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.pushExtensionOfferSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(pushExtensionOfferSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.pushExtensionOfferSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get extension offer
      .addCase(getExtensionOfferSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getExtensionOfferSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.extensionOffer = payload.data;
            state.status.getExtensionOfferSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getExtensionOfferSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getExtensionOfferSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // delete extension offer
      .addCase(deleteExtensionOfferSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(deleteExtensionOfferSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deleteExtensionOfferSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteExtensionOfferSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deleteExtensionOfferSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // delete extension offer
      .addCase(getUserExtensionOfferSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getUserExtensionOfferSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.extensionOffer = payload.data;
            state.status.getUserExtensionOfferSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserExtensionOfferSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getUserExtensionOfferSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // order cancellation
      .addCase(cancelUserOrderSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(cancelUserOrderSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.cancelUserOrderSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(cancelUserOrderSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.cancelUserOrderSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get user Order
      .addCase(getSingleOrderSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(getSingleOrderSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentUserOrder = payload.data;
            state.status.getSingleOrderSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getSingleOrderSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getSingleOrderSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get Offer Price
      .addCase(getOfferPriceSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOfferPriceSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.priceData = payload.data;
            state.status.getOfferPriceSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getOfferPriceSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getOfferPriceSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // extend order
      .addCase(extendOrderSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(extendOrderSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.extendOrderSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(extendOrderSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.extendOrderSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get All Product Support
      .addCase(getAllOrderProdSuppSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOrderProdSuppSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.allProdSupp = payload.data;
            state.status.getAllOrderProdSuppSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllOrderProdSuppSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllOrderProdSuppSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all order count
      .addCase(allOrderCountThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allOrderCountThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.counts = payload.data;
            state.status.allOrderCountThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(allOrderCountThunk.rejected, (state, action) => {
        state.loading = false;
        state.status.allOrderCountThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all order count
      .addCase(getReuploadDocumentOrdersCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getReuploadDocumentOrdersCount.fulfilled,
        (state, { payload }) => {
          switch (payload.type) {
            case SUCCESS:
              state.loading = false;
              state.data.reuploadOrders.total = payload.count;
              state.status.getReuploadDocumentOrdersCount = FULFILLED;
              break;
            default:
              state.loading = false;
            // state.isError = true;
            // state.errorData = {
            //   message: payload.message,
            //   type: payload.type,
            //   errors: payload.errors,
            // };
          }
        }
      )
      .addCase(getReuploadDocumentOrdersCount.rejected, (state, action) => {
        state.loading = false;
        // state.status.getReuploadDocumentOrdersCount = ERROR;
        // state.isError = true;
        // state.errorData = REJECTED_ERROR;
      })
      // get all pending user payemnt orders
      .addCase(getPagintedPendingUserPaymentsThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getPagintedPendingUserPaymentsThunk.fulfilled,
        (state, { payload }) => {
          switch (payload.type) {
            case SUCCESS:
              state.loading = false;
              state.data.pendingUserPayments = payload.data;
              state.status.getPagintedPendingUserPaymentsThunk = FULFILLED;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        getPagintedPendingUserPaymentsThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.status.getPagintedPendingUserPaymentsThunk = ERROR;
          state.isError = true;
          state.errorData = REJECTED_ERROR;
        }
      )
      // de assign assets
      .addCase(deAssignAssetAndUpdateOrderThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        deAssignAssetAndUpdateOrderThunk.fulfilled,
        (state, { payload }) => {
          switch (payload.type) {
            case SUCCESS:
              state.loading = false;
              state.updateDone = !state.updateDone;
              state.status.deAssignAssetAndUpdateOrderThunk = FULFILLED;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(deAssignAssetAndUpdateOrderThunk.rejected, (state, action) => {
        state.loading = false;
        state.status.deAssignAssetAndUpdateOrderThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // send extension req slice
      .addCase(sendExtReqSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(sendExtReqSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.sendExtReqSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(sendExtReqSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.sendExtReqSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get Extension req
      .addCase(getExtReqSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getExtReqSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.extReq = payload.data;
            state.status.getExtReqSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getExtReqSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.getExtReqSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get product support more info
      .addCase(getProdSuppMoreInfoSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProdSuppMoreInfoSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.prodSuppMoreInfo = payload.data;
            state.status.getProdSuppMoreInfoSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getProdSuppMoreInfoSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.getProdSuppMoreInfoSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // change product support status
      .addCase(changeProdSuppStatusSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(changeProdSuppStatusSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.changeProdSuppStatusSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(changeProdSuppStatusSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.changeProdSuppStatusSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // change product support status
      .addCase(downloadInvoiceSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(downloadInvoiceSlice.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.invocice = payload.data;
            state.status.downloadInvoiceSlice = FULFILLED;
            break;
          case "application/zip":
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(downloadInvoiceSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.downloadInvoiceSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

            // change product support status
            .addCase(downloadAgreementSlice.pending, (state, action) => {
              state.loading = true;
            })
            .addCase(downloadAgreementSlice.fulfilled, (state, { payload }) => {
              state.loading = false;
              switch (payload.type) {
                case SUCCESS:
                  state.data.invocice = payload.data;
                  state.status.downloadAgreementSlice = FULFILLED;
                  break;
                case "application/zip":
                  break;
                default:
                  state.isError = true;
                  state.errorData = {
                    message: payload.message,
                    type: payload.type,
                    errors: payload.errors,
                  };
              }
            })
            .addCase(downloadAgreementSlice.rejected, (state, action) => {
              state.loading = false;
              state.status.downloadAgreementSlice = ERROR;
              state.isError = true;
              state.errorData = REJECTED_ERROR;
            })

      //actions for updateOrderAndDocumentSlice
      .addCase(updateOrderAndDocumentSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrderAndDocumentSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentUserOrder = payload.data;
            state.status.currentUserOrder = FULFILLED;
            break;

          case FAILURE:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            break;
          default:
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateOrderAndDocumentSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.currentOrderInfo = ERROR;
        state.errorData = REJECTED_ERROR;
      })

      // updateOrderUseCredit
      .addCase(updateOrderUseCredit.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrderUseCredit.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentUserOrder = payload.data;
            break;

          case FAILURE:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            break;

          default:
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateOrderUseCredit.rejected, (state, action) => {
        state.loading = false;
        state.errorData = REJECTED_ERROR;
      })

      //actions for getExtendedOrderPriceDetails
      .addCase(getExtendedOrderPriceDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getExtendedOrderPriceDetails.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.priceData = payload.data;
            break;

          case FAILURE:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            break;
          default:
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getExtendedOrderPriceDetails.rejected, (state, action) => {
        state.loading = false;
        state.status.currentOrderInfo = ERROR;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(renatalCancellationAdmin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(renatalCancellationAdmin.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            break;

          case FAILURE:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            break;
          default:
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(renatalCancellationAdmin.rejected, (state, action) => {
        state.loading = false;
        state.status.currentOrderInfo = ERROR;
        state.errorData = REJECTED_ERROR;
      })

      // ====================== Update Order Penalty Status ======================
      .addCase(updateOrderPenaltyStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrderPenaltyStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentOrderInfo = payload.data;
            state.status.updateOrderPenaltyStatus = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateOrderPenaltyStatus.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.updateOrderPenaltyStatus = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // ====================== Update Order Penalty Percentage ======================
      .addCase(updateOrderPenaltyPercentage.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrderPenaltyPercentage.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentOrderInfo = payload.data;
            state.status.updateOrderPenaltyPercentage = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateOrderPenaltyPercentage.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.updateOrderPenaltyPercentage = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getOrderByAddressId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrderByAddressId.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.isError = false;
            state.data.currentAddress = payload.data;
            state.data.verifiedDocument = payload.data.verified;
            state.status.getOrderByAddressId = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getOrderByAddressId.rejected, (state, action) => {
        state.loading = false;
        state.status.currentAddress = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(verifyAddressDocument.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifyAddressDocument.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.verifiedDocument = payload.verificationStatus;
            state.status.verifyAddressDocument = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(verifyAddressDocument.rejected, (state, action) => {
        state.loading = false;
        state.status.verifyAddressDocument = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(rejectAddressDocument.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(rejectAddressDocument.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.isError = false;
            state.data.verifiedDocument = payload.rejectedStatus;
            state.status.rejectAddressDocument = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(rejectAddressDocument.rejected, (state, action) => {
        state.loading = false;
        state.status.rejectAddressDocument = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(assignSelfieProofStatusThunk.pending, (state, action) => {
        // state.loading = true;
        state.status.assignSelfieProofStatusThunk = LOADING;
      })
      .addCase(assignSelfieProofStatusThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentOrderInfo = payload.data;
            state.status.assignSelfieProofStatusThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(assignSelfieProofStatusThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.assignSelfieProofStatusThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(assignAddressProofStatusThunk.pending, (state, action) => {
        // state.loading = true;
        state.status.assignAddressProofStatusThunk = LOADING;
      })
      .addCase(
        assignAddressProofStatusThunk.fulfilled,
        (state, { payload }) => {
          switch (payload.type) {
            case SUCCESS:
              state.loading = false;
              state.data.currentAddress = payload.data;
              state.status.assignAddressProofStatusThunk = FULFILLED;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(assignAddressProofStatusThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.assignAddressProofStatusThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(getUserAddressById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserAddressById.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.isError = false;
            state.data.currentAddress = payload.data;
            state.data.verifiedDocument = payload.data.verified;
            state.status.getUserAddressById = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserAddressById.rejected, (state, action) => {
        state.loading = false;
        state.status.currentAddress = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(reuploadUserAddressSlice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(reuploadUserAddressSlice.fulfilled, (state, { payload }) => {
        // console.log({ payload });
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.isError = false;
            state.data.currentAddress = payload.data;
            state.data.verifiedDocument = payload.data.verified;
            state.status.reuploadUserAddressSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(reuploadUserAddressSlice.rejected, (state, action) => {
        state.loading = false;
        state.status.currentAddress = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(updateOrderStatusSlice.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(updateOrderStatusSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentUserOrder = payload.data;
            state.status.updateOrderStatusSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateOrderStatusSlice.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.updateOrderStatusSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(reuploadOrderDocuments.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(reuploadOrderDocuments.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentUserOrder = payload.data;
            state.status.reuploadOrderDocuments = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(reuploadOrderDocuments.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.reuploadOrderDocuments = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(getUserAddressByIdAdmin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserAddressByIdAdmin.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.isError = false;
            state.data.currentAddress = payload.data;
            state.data.verifiedDocument = payload.data.verified;
            state.status.getUserAddressByIdAdmin = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserAddressByIdAdmin.rejected, (state, action) => {
        state.loading = false;
        state.status.currentAddress = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(updateOrderRemainderStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrderRemainderStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.isError = false;
            state.data.reminderStatus = payload.data.reminderStatus;
            state.status.updateOrderRemainderStatus = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateOrderRemainderStatus.rejected, (state, action) => {
        state.loading = false;
        state.status.updateOrderRemainderStatus = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getFirstPendingPayment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFirstPendingPayment.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.isError = false;
            state.data.pendingPayments = payload.data;
            state.status.getFirstPendingPayment = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getFirstPendingPayment.rejected, (state, action) => {
        state.loading = false;
        state.status.getFirstPendingPayment = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(assignBankStatementStatusThunk.pending, (state, action) => {
        // state.loading = true;
        state.status.assignBankStatementStatusThunk = LOADING;
      })
      .addCase(assignBankStatementStatusThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentOrderInfo = payload.data;
            state.status.assignBankStatementStatusThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(assignBankStatementStatusThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.assignBankStatementStatusThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(bankStatementReuploadThunk.pending, (state, action) => {
        // state.status.getContactUsSlice = LOADING;
        state.loading = true;
      })
      .addCase(bankStatementReuploadThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.currentUserOrder = payload.data;
            state.status.bankStatementReuploadThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(bankStatementReuploadThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.bankStatementReuploadThunk = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
  },
});

export default orderSlice.reducer;
export const {
  clearErrorSlice,
  setGstDetails,
  setCurrentOrderId,
  setError,
  setIAgree,
  setIDisAgree,
  setClick,
  setChangeReminderStatus,
  setCredit,
  setSelfieURL,
} = orderSlice.actions;
