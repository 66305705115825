// import {
//   DELIVERY_CHARGE_FOR_HEAVY,
//   DELIVERY_CHARGE_FOR_LIGHT,
//   DELIVERY_CHARGE_FOR_MEDIUM,
// } from "../constants/constants";

// import { DOMAIN } from "../constants/constants";
// import lodash from "lodash";

// const displayDeliveryTime = (startHour, endHour) => {
//   return `${startHour}:00 - ${endHour}:00`;
// };

// const getNoOfDays = (startDate, endDate) => {
//   const date1 = new Date(startDate);
//   const date2 = new Date(endDate);
//   const noOfDays = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
//   return noOfDays;
// };

// const ConvertToRupee = (paisa) => {
//   const rupees = paisa / 100.0;
//   return rupees;
// };

// const getDuration = (noOfDays) => {
//   switch (parseInt(noOfDays)) {
//     case 1:
//       return "1 Day";
//     case 3:
//       return "3 Days";
//     case 7:
//       return "1 Week";
//     case 30:
//       return "1 Mth";
//     case 90:
//       return "3 Mths";
//     case 180:
//       return "6 Mths";
//     case 365:
//       return "1 Year";
//     default:
//       return noOfDays + " Days";
//   }
// };

// function formatDateToDMY(dateStr) {
//   const inputDate = new Date(dateStr);
//   const day = String(inputDate.getDate()).padStart(2, "0");
//   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
//   return `${day}/${month}/${inputDate.getFullYear()}`;
// }

// const dateFormatter = (date) => {
//   const startDate = new Date(date);
//   return `${startDate.getDate()}/${startDate.getMonth() + 1
//     }/${startDate.getFullYear()}`;
// };

// const dateFormatterDash = (date) => {
//   const startDate = new Date(date);
//   return `${startDate.getFullYear()}-${startDate.getMonth() + 1
//     }-${startDate.getDate()}`;
// };

// const getDbTime = (date) => {
//   if (!date) {
//     return "na";
//   }
//   let mins =
//     new Date(date).getMinutes() <= 9
//       ? "0" + new Date(date).getMinutes()
//       : new Date(date).getMinutes();
//   let hrs =
//     new Date(date).getHours() < 9
//       ? "0" + new Date(date).getHours()
//       : new Date(date).getHours();

//   return hrs + ":" + mins;
// };

// const priceFormatter = (numDays, price) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays < 30) {
//     return `${lodash.round(price / numDays)}/Day`;
//   } else {
//     return `${lodash.round(price / numDays) * 30} /Mn`;
//   }
// };

// // latest
// const priceFormatterDaily = (numDays, price, credit) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays === 1) {
//     if (credit) {
//       return `${lodash.round(parseInt(price)) / numDays - credit}/Day`;
//     }
//     return `${lodash.round(parseInt(price))}/Day`;
//   }
//   if (numDays === 7) {
//     if (credit) {
//       return `${lodash.round(parseInt(price)) - credit}/Day`;
//     }
//     return `${lodash.round(parseInt(price))}/Week`;
//   }
//   if (numDays > 1 && numDays < 30) {
//     if (credit) {
//       return `${lodash.round(parseInt(price)) - credit}/${numDays} Days`;
//     }
//     // console.log(price);
//     // console.log(numDays);
//     return `${lodash.round(parseInt(price))}/${numDays} Days`;
//   }
//   if (numDays >= 30) {
//     if (credit) {
//       return `${lodash.round((parseInt(price) / parseInt(numDays)) * 30) - credit} /Mn`;
//     }
//     // return `${lodash.round(((parseInt(price) / parseInt(numDays)) * 30))} /Mn`;
//     return `${lodash.round(parseInt(price) * 30 / (parseInt(numDays)))} /Mn`;
//   }
// };

// const priceFormatterDaily_v1 = (numDays, price, credit) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays === 1) {
//     if (credit) {
//       return `${lodash.round(parseInt(price)) / numDays - credit}/Day`;
//     }
//     return `${lodash.round(parseInt(price))}/Day`;
//   }
//   if (numDays === 7) {
//     if (credit) {
//       return `${lodash.round(parseInt(price)*7) - credit}/Day`;
//     }
//     return `${lodash.round(parseInt(price)*7)}/Week`;
//   }
//   if (numDays > 1 && numDays < 30) {
//     if (credit) {
//       return `${lodash.round(parseInt(price)*parseInt(numDays)) - credit}/${numDays} Days`;
//     }
//     // console.log(price);
//     // console.log(numDays);
//     return `${lodash.round(parseInt(price)*parseInt(numDays))}/${numDays} Days`;
//   }
//   if (numDays >= 30) {
//     if (credit) {
//       return `${lodash.round((parseInt(price)) * 30) - credit} /Mn`;
//     }
//     // return `${lodash.round(((parseInt(price) / parseInt(numDays)) * 30))} /Mn`;
//     return `${lodash.round(parseInt(price) * 30 )} /Mn`;
//   }
// };




// const planPriceFormatterDaily = (numDays, price) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays < 30) {
//     return `${lodash.round(price / numDays)}/Day`;
//   } else {
//     return `${lodash.round((price / numDays) * 30)} /Mn`;
//   }
// };

// const priceFormatterrrr = (numDays, price) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays < 30) {
//     return `${price}/Day`;
//   } else {
//     return `${price}/Mn`;
//   }
// };

// const getOnlyPrice = (numDays, price) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays < 30) {
//     return `${Math.ceil(price / numDays)}`;
//   } else {
//     return `${Math.ceil(price / numDays) * 30}`;
//   }
// };

// const gstAmount = (numDays, price) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays < 30) {
//     const subTotal = `${Math.ceil(price / numDays)} `;
//     return Math.ceil((subTotal / 100) * 18);
//   } else {
//     const subTotal = `${Math.ceil(price / numDays) * 30}`;
//     return Math.ceil((subTotal / 100) * 18);
//   }
// };

// const getPriceAfterCoupon = (coupon, price) => {
//   let couponIsFlat =
//     coupon?.discount?.discountType === "PERCENTAGE" ? false : true;
//   const couponAmount = coupon?.discount?.amount ? coupon?.discount?.amount : 0;
//   if (couponIsFlat) {
//     return Math.ceil((price / 100) * couponAmount);
//   }
//   if (!couponIsFlat) {
//     return couponAmount;
//   }
// };

// const totalPrice = (price, gst, coupon) => {
//   const num1 = parseInt(price);
//   const num2 = parseInt(gst);
//   const num3 = parseInt(coupon);
//   const sum = num1 + num2;
//   return sum - num3;
// };

// const viewFormatter = (str) => {
//   return str?.[0]?.toUpperCase() + str?.slice(1).toLowerCase();
// };

// const closePopup = (e, value, callback) => {
//   if (e.target.id === value) {
//     callback(false);
//   }
// };

// const getDaysForPickup = (endDate) => {
//   let date1 = new Date();
//   date1.setHours(0, 0, 0, 0);
//   let date2 = new Date(endDate);
//   date2.setHours(0, 0, 0, 0);
//   const noOfDays = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
//   const today = new Date().setHours(0, 0, 0, 0);
//   if (today >= date2) {
//     return null;
//   } else {
//     return noOfDays + " Days for pickup";
//   }
// };

// const getDaysByDuration = (duration, tenure) => {
//   if (tenure === 1) {
//     return duration * 30;
//   }
//   if (tenure === 0) {
//     return duration;
//   }
// };

// const getSubTotal = (numDays, price) => {
//   // console.log(numDays);
//   // console.log(price);
//   if (!numDays || !price) {
//     return "NA";
//   } else {
//     return price;
//   }
// };

// const getOnePercent = (total) => {
//   return lodash.round(total * 0.01);
// };

// const getSubTotalMonthCap = (numDays, price) => {
//   if (!numDays || !price) {
//     return "NA";
//   } else {
//     return lodash.round(Math.min(numDays, 30) * price, 2);
//   }
// };

// const getTableDuration = (startDate, endDate) => {
//   const noOfDays = getNoOfDays(startDate, endDate);
//   if (noOfDays === 30) {
//     return "Month";
//   }
//   if (noOfDays < 30) {
//     if (noOfDays === 1) {
//       return noOfDays + " Day";
//     }
//     return noOfDays + " Days";
//   }
// };

// const getPaginatedArray = (creditHitstory, pageSize, page) => {
//   if (!creditHitstory || !pageSize) {
//     return {
//       total: 0,
//       data: [],
//     };
//   }
//   const total = creditHitstory?.length || 1;
//   const PAGESIZE = parseInt(pageSize) || 7;
//   const PAGE = parseInt(page) || 0;
//   const paginatedDetails = {
//     total: Math.ceil(total / PAGESIZE),
//     data: creditHitstory.slice(PAGESIZE * PAGE, PAGESIZE * PAGE + PAGESIZE),
//   };
//   return paginatedDetails;
// };

// const validOrderStartDate = (startDate) => {
//   if (!startDate) {
//     return !!startDate;
//   }
//   return (
//     new Date(startDate).getTime() < new Date().getTime() - 1000 * 60 * 60 * 24
//   );
// };

// function formatPrice(number) {
//   // Convert the number to a string
//   let priceString = number.toString();

//   // Split the string into whole and decimal parts
//   let parts = priceString.split(".");
//   let wholePart = parts[0];
//   let decimalPart = parts[1] || "";

//   // Add commas to the whole part
//   let formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//   // Concatenate the whole and decimal parts with a dot
//   let formattedPrice =
//     formattedWholePart + (decimalPart ? "." + decimalPart : "");
//   return formattedPrice;
// }

// const getPenaltyFinalAmount = (
//   rentAmount,
//   penaltyPercentage,
//   startDate,
//   endDate
// ) => {
//   let noOfDays = getNoOfDays(startDate, endDate);
//   let penaltyAmount = rentAmount * (penaltyPercentage / 100) * noOfDays;

//   return lodash.round(penaltyAmount, 0);
// };

// export const getDeliveryCharge = (packageWeight) => {
//   let deliveryCharge = 0;

//   switch (packageWeight) {
//     case "L":
//       deliveryCharge = DELIVERY_CHARGE_FOR_LIGHT;
//       break;

//     case "M":
//       deliveryCharge = DELIVERY_CHARGE_FOR_MEDIUM;
//       break;

//     case "H":
//       deliveryCharge = DELIVERY_CHARGE_FOR_HEAVY;
//       break;

//     default:
//       deliveryCharge = 100;
//   }

//   return deliveryCharge;
// };

// const INVOICE = {
//   PACKAGE_NAME: "PACKAGE_NAME",
//   DELIVERY_CHARGE: 100,
//   DELIVERY_CHARGE_GST: (deliveryCharge) => {
//     const ninePercent = lodash.round(deliveryCharge * 0.09 || 0, 2);
//     return {
//       description: "Delivery Charge",
//       rate: deliveryCharge,
//       discount: 0,
//       quantity: 1,
//       totalRate: deliveryCharge,
//       CGST: ninePercent,
//       SGST: ninePercent,
//       IGST: 0,
//       lineTotal: lodash.round(deliveryCharge + ninePercent * 2, 2),
//     };
//   },
// };

// export const getColorStatus = (currStatus) => {
//   const orderStatusColor = {
//     PENDING: "#D5C53E",
//     APPROVED: "#47BC1E",
//     DISAPPROVED: "#D34E4E",
//     ACTIVE: "#19EEB5",
//     INACTIVE: "#B8352C",
//     CANCELLED: "#D34E4E",
//     COMPLETE: "linear-gradient(91deg, #1BACF4 0%, #19EEB5 100.16%)",
//     REUPLOAD: "#D5C53E",
//     EXPIRED: "#D5C53E",
//   };
//   let style = { color: "#DFDFDF" };
//   for (const status of Object.keys(orderStatusColor)) {
//     if (status == currStatus) {
//       style = {
//         color: `${orderStatusColor[currStatus]}`,
//         fontWeight: "500",
//         marginTop: ".5rem",
//         fontFamily: "Montserrat",
//       };
//     }
//   }
//   return style;
// };
// export {
//   INVOICE,
//   formatPrice,
//   getDuration,
//   getOnlyPrice,
//   getPaginatedArray,
//   getPriceAfterCoupon,
//   dateFormatter,
//   getDbTime,
//   getNoOfDays,
//   priceFormatter,
//   gstAmount,
//   totalPrice,
//   viewFormatter,
//   priceFormatterrrr,
//   closePopup,
//   getDaysForPickup,
//   priceFormatterDaily,
//   priceFormatterDaily_v1,
//   getDaysByDuration,
//   planPriceFormatterDaily,
//   getSubTotal,
//   getTableDuration,
//   validOrderStartDate,
//   displayDeliveryTime,
//   getSubTotalMonthCap,
//   ConvertToRupee,
//   getOnePercent,
//   dateFormatterDash,
//   formatDateToDMY,
//   getPenaltyFinalAmount,
// };

import {
  DELIVERY_CHARGE_FOR_HEAVY,
  DELIVERY_CHARGE_FOR_LIGHT,
  DELIVERY_CHARGE_FOR_MEDIUM,
} from "../constants/constants";

// import { DOMAIN } from "../constants/constants";
import lodash from "lodash";

export const changeTimeFormat = (timeHour) => {
  if (timeHour === 0) {
    return "12:00 AM";
  } else if (timeHour === 12) {
    return "12:00 PM";
  } else if (timeHour > 0 && timeHour < 12) {
    return timeHour + ":00" + " AM";
  } else {
    let diff = timeHour - 12;
    return diff + ":00" + " PM";
  }
}

const displayDeliveryTime = (startHour, endHour) => {
  return `${startHour}:00 - ${endHour}:00`;
};

const getNoOfDays = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const noOfDays = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
  return noOfDays;
};

const ConvertToRupee = (paisa) => {
  const rupees = paisa / 100.0;
  return rupees;
};

const getDuration = (noOfDays) => {
  switch (parseInt(noOfDays)) {
    case 1:
      return "1 Day";
    case 3:
      return "3 Days";
    case 7:
      return "1 Week";
    case 30:
      return "1 Mth";
    case 90:
      return "3 Mths";
    case 180:
      return "6 Mths";
    case 365:
      return "1 Year";
    default:
      return noOfDays + " Days";
  }
};

function formatDateToDMY(dateStr) {
  const inputDate = new Date(dateStr);
  const day = String(inputDate.getDate()).padStart(2, "0");
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  return `${day}/${month}/${inputDate.getFullYear()}`;
}

const dateFormatter = (date) => {
  const startDate = new Date(date);
  return `${startDate.getDate()}/${startDate.getMonth() + 1
    }/${startDate.getFullYear()}`;
};

const dateFormatterDash = (date) => {
  const startDate = new Date(date);
  return `${startDate.getFullYear()}-${startDate.getMonth() + 1
    }-${startDate.getDate()}`;
};

const getDbTime = (date) => {
  if (!date) {
    return "na";
  }
  let mins =
    new Date(date).getMinutes() <= 9
      ? "0" + new Date(date).getMinutes()
      : new Date(date).getMinutes();
  let hrs =
    new Date(date).getHours() < 9
      ? "0" + new Date(date).getHours()
      : new Date(date).getHours();

  return hrs + ":" + mins;
};

const priceFormatter = (numDays, price) => {
  if (!price && !numDays) {
    return `Could not fetch price !`;
  }
  if (numDays < 30) {
    return `${lodash.round(price / numDays)}/Day`;
  } else {
    return `${lodash.round(price / numDays) * 30} /Mn`;
  }
};

// latest
const priceFormatterDaily = (numDays, price, credit = 0, gst = 0) => {
  if (!price && !numDays) {
    return `Could not fetch price !`;
  }
  if (numDays === 1) {
    if (credit) {
      return `${lodash.round(price) - credit}/Day`;
    }
    return `${lodash.round(price)}/Day`;
  }
  if (numDays === 7) {
    if (credit) {
      return `${lodash.round(price * numDays) - credit}/Day`;
    }
    return `${lodash.round(price * numDays)}/Week`;
  }
  if (numDays > 1 && numDays < 30) {
    if (credit) {
      return `${lodash.round(price * numDays) - credit}/${numDays} Days`;
    }
    // console.log(price);
    // console.log(numDays);
    return `${lodash.round(price * numDays)}/${numDays} Days`;
  }
  if (numDays >= 30) {
    if (credit) {
      return `${lodash.round(price * 30) - credit} /Mth`;
    }
    return `${lodash.round((price * 30) + gst)} /Mth`;
  }
};

const priceFormatterDaily_v1 = (numDays, price, credit = 0, gst = 0) => {
  if (!price && !numDays) {
    return `Could not fetch price !`;
  }
  if (numDays === 1) {
    if (credit) {
      return `${lodash.round(price) - credit}/Day`;
    }
    return `${lodash.round(price)}/Day`;
  }
  if (numDays === 7) {
    if (credit) {
      return `${lodash.round(price * numDays) - credit}/Day`;
    }
    return `${lodash.round(price * numDays)}/Week`;
  }
  if (numDays > 1 && numDays < 30) {
    if (credit) {
      return `${lodash.round(price * numDays) - credit}/${numDays} Days`;
    }
    // console.log(price);
    // console.log(numDays);
    return `${lodash.round(price * numDays)}/${numDays} Days`;
  }
  if (numDays >= 30) {
    if (credit) {
      return `${lodash.round(price * 30) - credit} /Mn`;
    }
    return `${lodash.round((price * 30) + gst)} /Mn`;
  }
};

// const priceFormatterDaily_v1 = (numDays, price, credit) => {
//   if (!price && !numDays) {
//     return `Could not fetch price !`;
//   }
//   if (numDays === 1) {
//     if (credit) {
//       return `${lodash.round(parseInt(price)) / numDays - credit}/Day`;
//     }
//     return `${lodash.round(parseInt(price))}/Day`;
//   }
//   if (numDays === 7) {
//     if (credit) {
//       return `${lodash.round(parseInt(price) * 7) - credit}/Day`;
//     }
//     return `${lodash.round(parseInt(price) * 7)}/Week`;
//   }
//   if (numDays > 1 && numDays < 30) {
//     if (credit) {
//       return `${lodash.round(parseInt(price) * parseInt(numDays)) - credit}/${numDays} Days`;
//     }
//     // console.log(price);
//     // console.log(numDays);
//     return `${lodash.round(parseInt(price) * parseInt(numDays))}/${numDays} Days`;
//   }
//   if (numDays >= 30) {
//     if (credit) {
//       return `${lodash.round((parseInt(price)) * 30) - credit} /Mn`;
//     }
//     // return `${lodash.round(((parseInt(price) / parseInt(numDays)) * 30))} /Mn`;
//     return `${lodash.round(parseInt(price) * 30)} /Mn`;
//   }
// };


const planPriceFormatterDaily = (numDays, price) => {
  if (!price && !numDays) {
    return `Could not fetch price !`;
  }
  if (numDays < 30) {
    return `${lodash.round(price / numDays)}/Day`;
  } else {
    return `${lodash.round((price / numDays) * 30)} /Mn`;
  }
};

const priceFormatterrrr = (numDays, price) => {
  if (!price && !numDays) {
    return `Could not fetch price !`;
  }
  if (numDays < 30) {
    return `${price}/Day`;
  } else {
    return `${price}/Mn`;
  }
};

const getOnlyPrice = (numDays, price) => {
  if (!price && !numDays) {
    return `Could not fetch price !`;
  }
  if (numDays < 30) {
    return `${Math.ceil(price / numDays)}`;
  } else {
    return `${Math.ceil(price / numDays) * 30}`;
  }
};

const gstAmount = (numDays, price) => {
  if (!price && !numDays) {
    return `Could not fetch price !`;
  }
  if (numDays < 30) {
    const subTotal = `${Math.ceil(price / numDays)} `;
    return Math.ceil((subTotal / 100) * 18);
  } else {
    const subTotal = `${Math.ceil(price / numDays) * 30}`;
    return Math.ceil((subTotal / 100) * 18);
  }
};

const getPriceAfterCoupon = (coupon, price) => {
  let couponIsFlat =
    coupon?.discount?.discountType === "PERCENTAGE" ? false : true;
  const couponAmount = coupon?.discount?.amount ? coupon?.discount?.amount : 0;
  if (couponIsFlat) {
    return Math.ceil((price / 100) * couponAmount);
  }
  if (!couponIsFlat) {
    return couponAmount;
  }
};

const totalPrice = (price, gst, coupon) => {
  const num1 = parseInt(price);
  const num2 = parseInt(gst);
  const num3 = parseInt(coupon);
  const sum = num1 + num2;
  return sum - num3;
};

const viewFormatter = (str) => {
  return str?.[0]?.toUpperCase() + str?.slice(1).toLowerCase();
};

const closePopup = (e, value, callback) => {
  if (e.target.id === value) {
    callback(false);
  }
};

const getDaysForPickup = (endDate) => {
  let date1 = new Date();
  date1.setHours(0, 0, 0, 0);
  let date2 = new Date(endDate);
  date2.setHours(0, 0, 0, 0);
  const noOfDays = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
  const today = new Date().setHours(0, 0, 0, 0);
  if (today >= date2) {
    return null;
  } else {
    return noOfDays + " Days for pickup";
  }
};

const getDaysByDuration = (duration, tenure) => {
  if (tenure === 1) {
    return duration * 30;
  }
  if (tenure === 0) {
    return duration;
  }
};

const getSubTotal = (numDays, price, gst = 0) => {
  if (!numDays || !price) {
    return "NA";
  } else {
    return lodash.round(numDays * price + gst, 2);
  }
};

const getOnePercent = (total) => {
  // return lodash.round(total * 0.01);
  return lodash.round(total);
};

const getSubTotalMonthCap = (numDays, price) => {
  if (!numDays || !price) {
    return "NA";
  } else {
    return lodash.round(Math.min(numDays, 30) * price, 2);
  }
};

const getTableDuration = (startDate, endDate) => {
  const noOfDays = getNoOfDays(startDate, endDate);
  if (noOfDays === 30) {
    return "Month";
  }
  if (noOfDays < 30) {
    if (noOfDays === 1) {
      return noOfDays + " Day";
    }
    return noOfDays + " Days";
  }
};

const getPaginatedArray = (creditHitstory, pageSize, page) => {
  if (!creditHitstory || !pageSize) {
    return {
      total: 0,
      data: [],
    };
  }
  const total = creditHitstory?.length || 1;
  const PAGESIZE = parseInt(pageSize) || 7;
  const PAGE = parseInt(page) || 0;
  const paginatedDetails = {
    total: Math.ceil(total / PAGESIZE),
    data: creditHitstory.slice(PAGESIZE * PAGE, PAGESIZE * PAGE + PAGESIZE),
  };
  return paginatedDetails;
};

// const validOrderStartDate = (startDate) => {
//   if (!startDate) {
//     return !!startDate;
//   }
//   return (
//     new Date(startDate).getTime() < new Date().getTime() - 1000 * 60 * 60 * 24
//   );
// };

const validOrderStartDate = (startDateUnix) => {
  if (!startDateUnix) {
    return false; // If no startDateUnix is available, don't disable the button
  }
  const currentTime = new Date().getTime(); // Get current time in milliseconds
  const startDateTime = new Date(startDateUnix).getTime(); // Convert startDateUnix to milliseconds
  const twentyFourHoursInMilliseconds = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
  
  // Check if the startDate is more than 24 hours old
  return (currentTime - startDateTime) > twentyFourHoursInMilliseconds;
};


function formatPrice(number) {
  // Convert the number to a string
  let priceString = number.toString();

  // Split the string into whole and decimal parts
  let parts = priceString.split(".");
  let wholePart = parts[0];
  let decimalPart = parts[1] || "";

  // Add commas to the whole part
  let formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate the whole and decimal parts with a dot
  let formattedPrice =
    formattedWholePart + (decimalPart ? "." + decimalPart : "");
  return formattedPrice;
}

const getPenaltyFinalAmount = (
  rentAmount,
  penaltyPercentage,
  startDate,
  endDate
) => {
  let noOfDays = getNoOfDays(startDate, endDate);
  let penaltyAmount = rentAmount * (penaltyPercentage / 100) * noOfDays;

  return lodash.round(penaltyAmount, 0);
};

export const getDeliveryCharge = (packageWeight) => {
  let deliveryCharge = 0;

  switch (packageWeight) {
    case "L":
      deliveryCharge = DELIVERY_CHARGE_FOR_LIGHT;
      break;

    case "M":
      deliveryCharge = DELIVERY_CHARGE_FOR_MEDIUM;
      break;

    case "H":
      deliveryCharge = DELIVERY_CHARGE_FOR_HEAVY;
      break;

    default:
      deliveryCharge = 100;
  }

  return deliveryCharge;
};

const INVOICE = {
  PACKAGE_NAME: "PACKAGE_NAME",
  DELIVERY_CHARGE: 100,
  DELIVERY_CHARGE_GST: (deliveryCharge) => {
    const ninePercent = lodash.round(deliveryCharge * 0.09 || 0, 2);
    return {
      description: "Delivery Charge",
      rate: deliveryCharge,
      discount: 0,
      quantity: 1,
      totalRate: deliveryCharge,
      CGST: ninePercent,
      SGST: ninePercent,
      IGST: 0,
      lineTotal: lodash.round(deliveryCharge + ninePercent * 2, 2),
    };
  },
};

export const getColorStatus = (currStatus) => {
  const orderStatusColor = {
    PENDING: "#D5C53E",
    APPROVED: "#47BC1E",
    DISAPPROVED: "#D34E4E",
    ACTIVE: "#19EEB5",
    INACTIVE: "#B8352C",
    CANCELLED: "#D34E4E",
    COMPLETE: "linear-gradient(91deg, #1BACF4 0%, #19EEB5 100.16%)",
    REUPLOAD: "#D5C53E",
    EXPIRED: "#D5C53E",
  };
  let style = { color: "#DFDFDF" };
  for (const status of Object.keys(orderStatusColor)) {
    if (status === currStatus) {
      style = {
        color: `${orderStatusColor[currStatus]}`,
        fontWeight: "500",
        marginTop: ".5rem",
        fontFamily: "Montserrat",
      };
    }
  }
  return style;
};
export {
  INVOICE,
  formatPrice,
  getDuration,
  getOnlyPrice,
  getPaginatedArray,
  getPriceAfterCoupon,
  dateFormatter,
  getDbTime,
  getNoOfDays,
  priceFormatter,
  gstAmount,
  totalPrice,
  viewFormatter,
  priceFormatterrrr,
  closePopup,
  getDaysForPickup,
  priceFormatterDaily,
  priceFormatterDaily_v1,
  getDaysByDuration,
  planPriceFormatterDaily,
  getSubTotal,
  getTableDuration,
  validOrderStartDate,
  displayDeliveryTime,
  getSubTotalMonthCap,
  ConvertToRupee,
  getOnePercent,
  dateFormatterDash,
  formatDateToDMY,
  getPenaltyFinalAmount,
};