import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import PopUp from "../PopUp/PopUp";
import { reuploadOrderDocuments } from "../../redux/features/orderSlice/orderSlice";
import SelfieePopUp from "./SelfieePopUp";

const LiveSelfiee = (props) => {
  // console.log("live selfie ", props.data._id);
  const dispatch = useDispatch();
  const [liveSelfie, setLiveSelfie] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const webcamRef = useRef(null);

  const [hasPhoto, setHasPhoto] = useState(false);

  const reuploadSelfie = () => {
    const orderId = props.data._id;

    const data = {
      selfieProof: imgSrc,
      orderId: orderId,
    };
    dispatch(reuploadOrderDocuments(data));
  };

  // useEffect(() => {
  //   // console.log("props: ", props.data);
  // }, []);

  return (
    <div
      className="re-upload-document-content"
      style={{ justifyContent: "start" }}
    >
      <svg
        width="48"
        height="40"
        viewBox="0 0 48 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8619 9.2H9.8227C7.08467 9.2 5.71463 9.2 4.66884 9.72317C3.74893 10.1834 3.00157 10.9171 2.53286 11.8203C2 12.8471 2 14.1922 2 16.8805V30.3205C2 33.0087 2 34.351 2.53286 35.3777C3.00157 36.2809 3.74893 37.0172 4.66884 37.4773C5.7136 38 7.08199 38 9.81467 38H38.1853C40.918 38 42.2844 38 43.3292 37.4773C44.2491 37.0172 44.999 36.2809 45.4677 35.3777C46 34.352 46 33.0104 46 30.3274V16.8726C46 14.1896 46 12.8461 45.4677 11.8203C44.999 10.9171 44.2491 10.1834 43.3292 9.72317C42.2834 9.2 40.9163 9.2 38.1783 9.2H30.1376M17.8619 9.2H18.013M17.8619 9.2C17.8926 9.20001 17.9248 9.20001 17.9587 9.2L18.013 9.2M17.8619 9.2C17.6025 9.19989 17.4572 9.19857 17.3422 9.18594C15.9059 9.02805 14.9208 7.68578 15.2224 6.29801C15.2505 6.16887 15.306 6.00546 15.4158 5.68188L15.4206 5.66819C15.546 5.29863 15.6088 5.11384 15.6781 4.95078C16.3877 3.28133 17.9945 2.14599 19.832 2.01299C20.0115 2 20.2087 2 20.6055 2H27.3943C27.7911 2 27.9899 2 28.1694 2.01299C30.0069 2.14599 31.612 3.28133 32.3216 4.95078C32.3909 5.11384 32.454 5.29842 32.5794 5.66797C32.6924 6.00078 32.7489 6.16726 32.7774 6.29824C33.079 7.68601 32.0955 9.02805 30.6592 9.18594C30.5442 9.19857 30.3974 9.19989 30.1376 9.2M18.013 9.2H29.9864M29.9864 9.2H30.1376M29.9864 9.2L30.0407 9.2C30.0746 9.20001 30.1069 9.20001 30.1376 9.2M24 30.8C19.9499 30.8 16.6667 27.5765 16.6667 23.6C16.6667 19.6236 19.9499 16.4 24 16.4C28.0501 16.4 31.3333 19.6236 31.3333 23.6C31.3333 27.5765 28.0501 30.8 24 30.8Z"
          stroke="url(#paint0_linear_59_559)"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_59_559"
            x1="2"
            y1="2"
            x2="46.8198"
            y2="2.95901"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1BACF4" />
            <stop offset="1" stop-color="#19EEB5" />
          </linearGradient>
        </defs>
      </svg>

      <div className="reupload-inner-container">
        <p>Let's take a self for verification</p>
        <div className="reupload-sucessfull-done-button">
          <button
            onClick={(e) => setLiveSelfie((pre) => !pre)}
            id="reupload-sucessfull-done"
          >
            Capture Selfiee
          </button>
        </div>
      </div>

      {/* <PopUp
        display={liveSelfie}
        callback={setLiveSelfie}
        center={true}
        uniqueId={"listProdPopup"}
        zIndex={150}
      >
        <div className="individual-upload-container-verify">
          <div className="header">
            <p id="upload-text">Upload a Selfie</p>
          </div>
          <div className="individual-upload-verify">
            <div className="header">
              <p
                style={{ display: hasPhoto ? "none" : "" }}
                id="individual-upload-text"
              >
                {hasPhoto ? "" : "Upload"}
              </p>
            </div>
          </div>
          <div className="individual-upload-desc-verify">
            <div className="identity-upload-outer">
              <div
                className={
                  "result" +
                  (hasPhoto ? "hasPhoto" : "") +
                  "-verify inner-preview"
                }
                id={hasPhoto ? "uploaded-image" : ""}
              >
                {hasPhoto ? (
                  <img src={imgSrc} id={hasPhoto ? "uploaded-image" : ""} />
                ) : (
                  <svg
                    width="113"
                    height="98"
                    viewBox="0 0 113 98"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.45"
                      d="M74.9088 97.8973L49.4524 50.4742L69.563 16.4162L112.33 97.8973H74.9088ZM32.142 38.1445L64.2171 97.8973H0.0668945L32.142 38.1445ZM18.7774 27.2803C11.3963 27.2803 5.41275 21.2002 5.41275 13.7001C5.41275 6.2 11.3963 0.119934 18.7774 0.119934C26.1584 0.119934 32.142 6.2 32.142 13.7001C32.142 21.2002 26.1584 27.2803 18.7774 27.2803Z"
                      fill="black"
                    />
                  </svg>
                )}
              </div>
            </div>
            <button
              onClick={capture}
              className="capture mt-4"
              style={{ width: "-webkit-fill-available" }}
            >
              {" "}
              {hasPhoto ? "Retake" : "Click Pic"}{" "}
            </button>
            <div
              className="camera"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              </div>
              <div className="preview">
              <Webcam
                ref={webcamRef}
                mirrored={true}
                width="100px"
                height="100px"
                videoConstraints={videoConstraints}
              />
            </div>
            <p id="upload-desc">
              Please upload a real-time Selfiee with peace sign. This step is
              very important to us so that no documents are misused.
            </p>
            <button
              className="capture"
              onClick={reuploadSelfie}
              style={{ width: "-webkit-fill-available" }}
            >
              Upload
            </button>
          </div>
        </div>
      </PopUp> */}
      <SelfieePopUp
        liveSelfie={liveSelfie}
        setLiveSelfie={setLiveSelfie}
        hasPhoto={hasPhoto}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        setHasPhoto={setHasPhoto}
        webcamRef={webcamRef}
        reuploadSelfie={reuploadSelfie}
      />
    </div>
  );
};

export default LiveSelfiee;
