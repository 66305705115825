import "./catogerycard.css";

import { Link, createSearchParams, useSearchParams } from "react-router-dom";

import React from "react";

const Catogerycard = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getNavigate = () => {
    let params = Object.fromEntries(searchParams);
    params["category"] = props?.name?.toLowerCase();
    params["subcategory"] = "default";
    params["page"] = 0;
    if (!params["pagesize"]) params["pagesize"] = 4;
    let link = `/${
      props.location?.toLowerCase() || "mumbai"
    }/categories/?${createSearchParams(params).toString()}`;
    return link;
  };
  return (
    <>
      <div
        className="catogeryCard"
        style={{
          height: props.height,
          width: props.height,
          minHeight: props.height,
          minWidth: props.height,
        }}
      >
        <Link
          // to={`/${
          //   props.location?.toLowerCase() || "mumbai"
          // }/categories/?category=${props?.name?.toLowerCase()}&subCategory=${"default"}&page=${0}&pageSize=${8}`}
          to={getNavigate()}
        >
          <img src={props.imgSrc} alt="Console" className="cat-card-img" />
          <p
            className={
              props?.activeCategory === props?.name?.toLowerCase()
                ? "catText gradient"
                : "catText"
            }
          >
            {props.name ? props.name : "Show All"}
          </p>
        </Link>
      </div>
    </>
  );
};

export default Catogerycard;
