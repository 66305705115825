import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Portal from "../Portal/Portal";

function PopUp({
  children,
  display,
  callback,
  // popper,
  uniqueId,
  zIndex,
  center,
  fixed,
  styles,
  isLocationPopup
}) {
  let centerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "-10",
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw",
    ...styles,
    backdropFilter: "blur(1px)",
    background: "rgba(0,0,0,0.5)",
  };
  let relativeStyles = {
    zIndex: "22",
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100vw",
    ...styles,
    background: "rgba(0,0,0,0.1)",
  };
  // const navigate = useNavigate()
  // const dispatch = useDispatch();

  const closePopup = (e) => {
    if (!isLocationPopup && (e.target.id === `pop-up-backdrop-${uniqueId || "no-id-given"}`)) {
      callback(false);
    }
  };

  useEffect(() => {
    let elm = document.getElementById(
      `pop-up-backdrop-${uniqueId || "no-id-given"}`
    );
    // let root = document.getElementById("root");
    if (display) {
      elm.style.zIndex = zIndex || 10;
      if (center) {
        // elm.style.backdropFilter = "blur(5px)";
        centerStyles = { backdropFilter: "blur(5px)", ...centerStyles };

        document.body.style.position = "fixed";
      }
      if (fixed) {
        document.body.style.position = "fixed";
      }
    }
    // Escape key close event
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        callback(false);
      }
    };
    // new event
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.addEventListener("keydown", keyDownHandler);
      document.body.style.position = "relative";
    };
  }, [display]);

  return (
    <Portal>
      {display && (
        <div
          id={`pop-up-backdrop-${uniqueId || "no-id-given"}`}
          onClick={(e) => closePopup(e)}
          style={center ? centerStyles : relativeStyles}
        >
          {children}
        </div>
      )}
    </Portal>
  );
}

export default PopUp;
