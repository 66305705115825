import "./productcard.css";

import { Gaadi, Hot, Rupees, WhiteGaadi } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { dateFormatter, formatPrice } from "../../utils/displayUtils";
import {
  getProductAvailability,
  getProductDetails,
} from "../../redux/features/homePageSlice/homePageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import PopUp from "../PopUp/PopUp";
import React from "react";
import { SUCCESS } from "../../constants/constants";
import WaitlistComponent from "../Waitlist/WaitlistComponent";

// import { fromPairs } from "lodash";
// import lodash from "lodash";

function Productcard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProductAvailability, setIsProductAvailability] = useState(false);
  const [waitlist, setWaitlist] = useState(false);
  const setActivePackage = () => {
    // dispatch(setCurrentPackageId(props?.packageId));
    dispatch(getProductDetails(props?.packageId)).then((data) => {
      if (data.payload.type == SUCCESS) {
        navigate(`/productdetails/${props?.packageId}`);
      }
    });
  };

  const location = useSelector(
    (state) => state.rootReducer.userInfo?.data?.lastLocation
  );

  // const checkAvailability = () => {
  //   dispatch(getProductAvailability({ location, name: props?.name })).then(
  //     (data) => {
  //       if (data.payload.type == SUCCESS) {
  //         setIsProductAvailability(true);
  //       }
  //     }
  //   );
  // };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(document.body.clientWidth <= 576);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeWaitlist = () => {
    setWaitlist(false);
  };

  // useEffect(() => {
  //   checkAvailability();
  // }, []);

  return (
    <div>
      <div
        className="card"
        onClick={() => {
          if (isSmallScreen) {
            setActivePackage();
          }
        }}
      >
        <div className="card-img-container">
          <div className="delivery-in-days-img-container">
            <img src={WhiteGaadi} alt="" className="gaadi" />
            <p className="day">{props?.day}</p>
          </div>

          {/* {props.trend && (
            <div className="tag">
              <div
                className="rectangle"
                style={{ background: `${props.trend?.background}` }}
              >
                {" "}
                {props?.trendTagName}
                <span
                  style={{
                    "border-left": `30px solid ${props.trend?.background}`,
                  }}
                ></span>
              </div>
            </div>
          )} */}
          <img src={props?.imgSrc} alt="Playstation" />
        </div>

        <div className="first-row-product-card">
          <p className="prodName">{props?.name}</p>
          <div className="delivery-in-days">
            <img src={Gaadi} alt="" className="gaadi" />
            <p className="day">{props?.day}</p>
          </div>
        </div>

        {props?.isPreBooking &&
        new Date().getTime() < new Date(props.preBookingDate).getTime() ? (
          <div className="prebook-date-admin">
            <p
              style={{
                color: "green",
                fontSize: "12px",
                fontFamily: "Montserrat",
              }}
            >
              Launching On: {dateFormatter(props?.preBookingDate)}
            </p>
          </div>
        ) : (
          ""
        )}

        <div className="second-row-product-card">
          <div className="row-1-product-card">
            <p className="start">Starts at</p>
            <p className="price">
              <img src={Rupees} alt="" />
              {formatPrice(props?.price)}
              {/* {console.log(props)} */}
            </p>
          </div>
          {!props?.isOutOfStock && (
            <div
              className="btn-rent-now"
              data="Rent Now"
              onClick={() => {
                setActivePackage();
              }}
            ></div>
          )}
          {props?.isOutOfStock && (
            <div
              className="btn-rent-now"
              data="Notify Me"
              onClick={() => {
                setWaitlist(true);
              }}
            ></div>
          )}
          {props?.isPreBooking &&
            new Date().getTime() < new Date(props.preBookingDate).getTime() && (
              <div
                className="btn-rent-now"
                data="Soon"
                onClick={() => {
                  setWaitlist(true);
                }}
              ></div>
            )}
          {!props?.isOutOfStock &&
            new Date().getTime() >=
              new Date(props.preBookingDate).getTime() && (
              <div
                className="btn-rent-now"
                data="Rent Now"
                onClick={() => {
                  setActivePackage();
                }}
              ></div>
            )}
        </div>
        <PopUp display={waitlist} callback={setWaitlist} center={true}>
          <WaitlistComponent closeCallback={closeWaitlist} />
        </PopUp>
        {/* <Link to="/productdetails" className="btn" data="Rent Now"></Link> */}
      </div>
      <div
        className="outer-second-div"
        onClick={() => {
          setActivePackage();
        }}
      >
        <p className="start">Starts at</p>
        <p className="price">
          <img src={Rupees} alt="" />
          {formatPrice(props?.price)}
          {/* {console.log("muprops", props)} */}
        </p>
      </div>
    </div>
  );
}

export default Productcard;
