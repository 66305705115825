import "./addcatogerypopup.css";

import * as Yup from "yup";

import { Bluecamera, PopUpClose } from "../../../assets";
import React, { useState } from "react";

import { SUCCESS } from "../../../constants/constants";
import { createNewCategory } from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

const Addcatogerypopup = (props) => {
  const [image, setImage] = useState({});
  const [fImage, setFimage] = useState({});
  const [addBento, setAddBento] = useState(false);
  const [customGst, setCustomGst] = useState(false);

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleFimage = (e) => {
    setFimage(e.target.files[0]);
  };

  const dispatch = useDispatch();
  const createCategoryForm = useFormik({
    initialValues: {
      name: "",
      isBentoGrid: false,
      gst: "",
      customGstValue: ""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Min length is 3")
        .max(20, "Max length is 20")
        .required("Name is required"),
      gst: Yup.string()
        .required("GST is required"),
      customGstValue: Yup.number()
        .when('gst', {
          is: 'custom',
          then: Yup.number()
            .positive("GST must be a positive number")
            .required("Custom GST value is required")
        })
    }),
    onSubmit: (values) => {
      let categoryData = new FormData();
      categoryData.append("category", values.name);
      categoryData.append("category_icon", image);
      categoryData.append("frontend_category_icon", fImage);
      categoryData.append("isBentoGrid", addBento);
      categoryData.append("gst", customGst ? values.customGstValue : values.gst);
      dispatch(createNewCategory(categoryData)).then((data) => {
        if (data.payload.type == SUCCESS) {
          props.onclick();
        }
      });
    },
  });

  return (
    <>
      <div className="admin-req-create-new-catogery-content">
        <img src={PopUpClose} alt="popupclose" onClick={props.onclick} />
        <p>Add Category Name</p>
        <label htmlFor="name" className="add-category-error-label">
          {createCategoryForm.errors.name && createCategoryForm.touched.name
            ? createCategoryForm.errors.name
            : "\u00A0"}
        </label>

        <input
          type="text"
          name="name"
          value={createCategoryForm.values.name}
          onChange={createCategoryForm.handleChange}
          onBlur={createCategoryForm.handleBlur}
          id=""
          placeholder="Enter catogery name"
        />
        <div className="category-container-part">
          <div className="admin-req-upload-an-icon-container">
            <img
              src={Bluecamera}
              alt="bluecamera"
              style={{
                width: "1.25rem",
                height: "1.125rem",
              }}
            />
            <input
              type="file"
              name="category_icon"
              id=""
              onChange={(e) => {
                handleImage(e);
              }}
            />
            <p>{image?.name ? image?.name : "Upload an Icon +"}</p>
          </div>

          <div className="admin-req-upload-an-icon-container">
            <img
              src={Bluecamera}
              alt="bluecamera"
              style={{
                width: "1.25rem",
                height: "1.125rem",
              }}
            />
            <input
              type="file"
              name="frontend_category_icon"
              id=""
              onChange={(e) => {
                handleFimage(e);
              }}
            />
            <p>{fImage.name ? fImage?.name : "Upload frontend Icon +"}</p>
          </div>
          <div>
            <select 
              className="form-select" 
              aria-label="Default select example" 
              name="gst" 
              value={createCategoryForm.values.gst} 
              onChange={(e) => {
                createCategoryForm.handleChange(e);
                const value = e.target.value;
                setCustomGst(value === 'custom');
              }}
            >
              <option selected>Select GST Rate</option>
              <option value="5">5 </option>
              <option value="12">12</option>
              <option value="18">18</option>
              <option value="28">28</option>
              <option value="custom">Custom</option>
            </select>
            {createCategoryForm.errors.gst && createCategoryForm.touched.gst && (
              <div className="error">{createCategoryForm.errors.gst}</div>
            )}
              {customGst && (
              <div>
                <input
                  type="number"
                  name="customGstValue"
                  value={createCategoryForm.values.customGstValue}
                  onChange={createCategoryForm.handleChange}
                  onBlur={createCategoryForm.handleBlur}
                  placeholder="Enter custom GST value"
                />
                {createCategoryForm.errors.customGstValue && createCategoryForm.touched.customGstValue && (
                  <div className="error">{createCategoryForm.errors.customGstValue}</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="is-bento-grid">
          <input
            type="checkbox"
            className="bento-category-checkbox"
            onClick={() => setAddBento(!addBento)}
          />

          <label>Add to Bento Grid</label>
        </div>

        <button
          onClick={() => {
            createCategoryForm.handleSubmit();
          }}
        >
          Create
        </button>
      </div>
      {/* </div> */}
    </>
  );
};

export default Addcatogerypopup;
