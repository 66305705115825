import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "./sliderproductcard.css";

import { LeftSwiperButton, Right, RightSwiperButton } from "../../assets";
import React, { useEffect, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import {
  getAllTrendingPackagesPublic,
  getAllTrendingPackagesUser,
} from "../../redux/features/homePageSlice/homePageSlice";
import { getDuration, priceFormatter } from "../../utils/displayUtils";
import { useDispatch, useSelector } from "react-redux";

import { Productcard } from "../../components";
import TrendingTag from "./TrendingTag";
import { useParams } from "react-router-dom";

// import { Navigation } from "swiper";

// components

// images

const ProductCard = () => {
  SwiperCore.use([Navigation]);
  const allTrendingPackages = useSelector(
    (state) => state.rootReducer.homePage.data.allTrendingPackages
  );

  // const TrendingTag = (props) => {
  //   const { name, background } = props.trend;

  //   return (
  //     <div className="trending-tag" style={{ background: background }}>
  //       {name}
  //       <div className="trending-tag-edge" style={{ background: background }}>
  //         <div id="treding-dark-portion"></div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    // <Swiper
    //   width={1500}
    //   slidesPerView={5}
    //   centeredSlides={false}
    //   spaceBetween={10}
    //   slidesPerGroup={1}
    //   grabCursor={true}
    //   loop={false}
    //   loopFillGroupWithBlank={true}
    //   pagination={{
    //     clickable: true,
    //   }}
    //   navigation={{
    //     prevEl: ".leftButton",
    //     nextEl: ".rightButton",
    //   }}
    //   modules={[Navigation]}
    //   className="mySwiper"
    // >
    //   {allTrendingPackages?.map((data, idx) => {
    //     return (
    //       <SwiperSlide className="prodCard" key={idx}>
    //         <Productcard
    //           trendTagName={data.trendingTag?.name}
    //           trendTagImage={data.trendingTag?.image}
    //           imgSrc={data.image?.[0]}
    //           name={data.name}
    //           day={data.deliveryDays + "day"}
    //           price={
    //             data.rent?.rentAmount +
    //             "/" +
    //             getDuration(data.rent?.rentDuration)
    //           }
    //           id={data._id}
    //           packageId={data.customId}
    //         />
    //       </SwiperSlide>
    //     );
    //   })}
    // </Swiper>
    <div
      className="flex-slider-product-card-container"
      id="flex-slider-product-card-container"
    >
      {/* <div
        className="left-swiper-button"
        onClick={() => {
          // swiper.slidePrev();
          // handleScrollClick("up");
        }}
      >
        <img
          src={LeftSwiperButton}
          alt=""
          // onMouseOver={(e) => (e.currentTarget.src = ExcludeLeft)}
          onMouseOut={(e) => (e.currentTarget.src = LeftSwiperButton)}
          className="left-swiper-vector"
        />
      </div> */}
      {allTrendingPackages?.map((data, idx) => {
        return (
          
            <div className="flex-slider-product-card" key={idx}>
              {data.trendingTag && <TrendingTag trend={data.trendingTag} />}
              <Productcard

                trend={data.trendingTag}
                trendTagName={data.trendingTag?.name}
                trendTagImage={data.trendingTag?.image}
                imgSrc={data.image?.[0]}
                name={data.name}
                day={data.deliveryDays + "Hrs"}
                price={
                  data?.rent?.rentAmount +
                  "/" +
                  getDuration(data.rent?.rentDuration)
                }
                isOutOfStock={data?.isOutOfStock}
                isPreBooking={data?.isPreBooking}
                preBookingDate={data?.preBookingDate}
                id={data._id}
                packageId={data.customId}
              />
            </div>
          
        );
      })}
      {/* <div
        className="rightButton"
        onClick={() => {
          // swiper.slideNext();
        }}
      >
        <img
          src={RightSwiperButton}
          alt=""
          // onMouseOver={(e) => (e.currentTarget.src = ExcludeRight)}
          onMouseOut={(e) => (e.currentTarget.src = RightSwiperButton)}
          className="rightVector"
        />
      </div> */}
    </div>
  );
};

const Sliderproductcard = (props) => {
  //call a use effect here
  // const swiper = useSwiper();
  const dispatch = useDispatch();
  const { location } = useParams();
  const [scrollPosition, setScrollPosition] = useState(0);
  const locationUpperCase = location?.toUpperCase();
  const isLogin = useSelector((state) => state.rootReducer.userInfo.isLogin);
  const userUpdateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );
  useEffect(() => {
    if (isLogin) {
      dispatch(getAllTrendingPackagesUser());
    }
    if (!isLogin) {
      dispatch(
        getAllTrendingPackagesPublic({
          location: locationUpperCase ? locationUpperCase : "MUMBAI",
        })
      );
    }
  }, [userUpdateDone]);

  function handleScrollClick(direction) {
    const container = document.getElementById(
      "flex-slider-product-card-container"
    );
    const scrollStep = 200; // adjust this value to change the scroll speed

    if (direction === "up") {
      container.scrollLeft = Math.max(0, scrollPosition - scrollStep);
      setScrollPosition(container.scrollLeft);
    }
    if (direction === "down") {
      container.scrollLeft = Math.min(
        container.scrollWidth - container.clientWidth,
        scrollPosition + scrollStep
      );
      setScrollPosition(container.scrollLeft);
    }
  }

  return (
    <>
      {/* Trending Now section */}
      <div className="trending-text-container">
        <h1 className="trendText">{props.text}</h1>
        {/* <div className="trendButton">
          <div
            className="leftButton"
            onClick={() => {
              // swiper.slidePrev();
              handleScrollClick("up");
            }}
          >
            <img
              src={Left}
              alt=""
              onMouseOver={(e) => (e.currentTarget.src = ExcludeLeft)}
              onMouseOut={(e) => (e.currentTarget.src = Left)}
              className="leftVector"
            />
          </div>
          <div
            className="rightButton"
            onClick={() => {
              // swiper.slideNext();
              handleScrollClick("down");
            }}
          >
            <img
              src={Right}
              alt=""
              onMouseOver={(e) => (e.currentTarget.src = ExcludeRight)}
              onMouseOut={(e) => (e.currentTarget.src = Right)}
              className="rightVector"
            />
          </div>
        </div> */}
      </div>
      <div className="card-container">
        <div
          className="left-swiper-button"
          onClick={() => {
            // swiper.slidePrev();
            handleScrollClick("up");
          }}
        >
          <img
            src={LeftSwiperButton}
            alt=""
            // onMouseOver={(e) => (e.currentTarget.src = ExcludeLeft)}
            onMouseOut={(e) => (e.currentTarget.src = LeftSwiperButton)}
            className="left-swiper-vector"
          />
        </div>
        <ProductCard />
        <div
          className="right-swiper-button"
          onClick={() => {
            // swiper.slidePrev();
            handleScrollClick("down");
          }}
        >
          <img
            src={RightSwiperButton}
            alt=""
            // onMouseOver={(e) => (e.currentTarget.src = ExcludeLeft)}
            onMouseOut={(e) => (e.currentTarget.src = RightSwiperButton)}
            className="left-swiper-vector"
          />
        </div>
      </div>
    </>
  );
};

export default Sliderproductcard;
