import { Navbar } from "../../components";
import { Outlet } from "react-router-dom";
import React from "react";

function UserLayout() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}

export default UserLayout;
