import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOADING,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;
// #===========================================

export const getAllSuperCategories = createAsyncThunk(
  "post/admin_frontend/category/unique_categories",
  async (data) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/category/unique_categories`
      );
      console.log(data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createNewCategory = createAsyncThunk(
  "post/admin_frontend/category/create_new_category",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/category/create_new_category`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCategoryById = createAsyncThunk(
  "get/admin_frontend/category",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/category/${id}`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllSubCategories = createAsyncThunk(
  "post/admin_frontend/category/get_all_sub_categories",
  async (data) => {
    try {
      const body = { category: data.category ? data.category : "" };
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/category/get_all_sub_categories`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createSubCategory = createAsyncThunk(
  "post/admin_frontend/category/create_new_sub_category",
  async (data) => {
    try {

      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/category/create_new_sub_category`,
        { category: data.category, subCategory: data.subCategory, gst: data.gst }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "post/admin_frontend/category/",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/admin/admin_frontend/category/`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllAddons = createAsyncThunk(
  "post/admin_frontend/addon/get_all_addons_global",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/addons/get_all_addons_global/`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const checkIfPackageExistsSlice = createAsyncThunk(
  "post/admin_frontend/package/checkIfPackageExists",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/checkIfPackageExists`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createPackageSlice = createAsyncThunk(
  "post/admin_frontend/package/create",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/create`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getNumPackagesSlice = createAsyncThunk(
  "post/admin_frontend/package/num_packages",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/package/num_packages`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPackageWithFiltersSlice = createAsyncThunk(
  "post/admin_frontend/package/get_with_filters",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/get_with_filters`,
        {
          category: data.category.toUpperCase(),
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getFilteredPackagesV1 = createAsyncThunk(
  "post/admin_frontend/package/get_with_filters_v1",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/get_with_filters_v1`,
        payload
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPackageInfoSlice = createAsyncThunk(
  "post/admin_frontend/package/get_package_info",
  async (data) => {
    try {
      const body = {
        packageId: data,
      };
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/get_package_info`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllTrendingTags = createAsyncThunk(
  "post/admin_frontend/trending_now/get_all",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/trending_now/get_all`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addPackageToTrending = createAsyncThunk(
  "post/admin_frontend/package/add_to_trending",
  async (data) => {
    try {
      const body = {
        packageId: data.packageId,
        trendingId: data.trendingId,
      };
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/add_to_trending`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllTrendingPackages = createAsyncThunk(
  "post/admin_frontend/package/get_all_trending",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/package/get_all_trending`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllPackagesThunk = createAsyncThunk(
  "get/admin_frontend/package/getAllPackagesthunk",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/package/getAllPackages`,
      );
      console.log(res.data,'from get all packages');
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removePackageFromTrendingSlice = createAsyncThunk(
  "post/admin_frontend/package/remove_trending",
  async (data) => {
    try {
      const body = { packageId: data };
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/remove_trending`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeTrendingStatus = createAsyncThunk(
  "post/admin_frontend/package/update_status",
  async (data) => {
    try {
      const body = { packageId: data.id, status: data.status };
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/update_status`,
        body
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAdminPrice = createAsyncThunk(
  "public/price/get_price_admin",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/price/get_price_admin`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPackagePriceSlice = createAsyncThunk(
  "admin/admin_frontend/package/getPackagePrice",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/getPackagePrice`,
        {
          packageId: id,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPackageForSearchParamThunk = createAsyncThunk(
  "public/package/search_package",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/package/search_package`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPackageForSearchParamFullResultsThunk = createAsyncThunk(
  "public/package/search_package/full",
  async (data) => {
    //console.log(data);
    try {
      const res = await axios.post(
        `${BASE_URL}/public/package/search_package`,
        { ...data, fullResults: true }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//edit package thunk
export const editPackageThunk = createAsyncThunk(
  "admin/admin_frontend/package/edit",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/edit`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const editPackageCsvThunk = createAsyncThunk(
  "admin/admin_frontend/package/csv/edit",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/csv/edit`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//delete image thunk
export const deleteImageThunk = createAsyncThunk(
  "admin/admin_frontend/package/edit/deleteimg",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/edit/deleteimg`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//inBox delete image
export const deleteInBoxImage = createAsyncThunk(
  "admin/admin_frontend/package/edit/deleteinboximg",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/package/edit/deleteinboximg`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// delete package
export const deletePackage = createAsyncThunk(
  "post/admin_frontend/package/delete",
  async (id) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/admin/admin_frontend/package/delete/${id}`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//delete superCategory thunk
export const deleteSuperCategoryThunk = createAsyncThunk(
  "admin/admin_frontend/category/deleteSuperCategory",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/category/deleteSuperCategory`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSearchPackageForCoupon = createAsyncThunk(
  "public/package/search_package_for_coupon",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/public/package/search_package_for_coupon`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeBentoCategoryThunk = createAsyncThunk(
  "post/admin_frontend/category/changeBentoCategoryStatus",
  async (categoryId) => {
    try {
      console.log(categoryId);
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/category/changeBentoCategoryStatus`,
        {
          categoryId: categoryId
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  activeCategory: "",
  activeSubCategory: "DEFAULT",
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  updateDone: false,
  form: {
    selectedCategory: "",
    selectedSubCategory: "",
  },
  currentPackageId: "",
  data: {
    searchedPackages: [],
    searchedPackagesFull: [],
    allTrendingPackages: [],
    allTrendingNowTags: [],
    currentPackageInfo: {},
    allPackages: [],
    allfetchedPackages: [],
    allPackagesDisplay: [],
    packageCount: 0,
    allCategories: [],
    allSubCategories: [],
    allSuperCategories: [],
    allAddons: [],
    adminPrices: {},
    packageBasePrice: {},
    bentoCategoryStatus: false,
  },
  status: {
    getAllSuperCategories: IDLE,
    createNewCategory: IDLE,
    getAllSubCategories: IDLE,
    getAllCategories: IDLE,
    createSubCategory: IDLE,
    getAllAddons: IDLE,
    createPackageSlice: IDLE,
    getNumPackagesSlice: IDLE,
    getPackageWithFiltersSlice: IDLE,
    getPackageInfoSlice: IDLE,
    getAllTrendingTags: IDLE,
    addPackageToTrending: IDLE,
    getAllTrendingPackages: IDLE,
    getAllPackages: IDLE,
    removePackageFromTrendingSlice: IDLE,
    changeTrendingStatus: IDLE,
    getAdminPrice: IDLE,
    getPackagePriceSlice: IDLE,
    getPackageForSearchParamThunk: IDLE,
    getPackageForSearchParamFullResultsThunk: IDLE,
    deleteSuperCategoryThunk: IDLE,
    getSearchPackageForCoupon: IDLE,
    changeBentoCategoryThunk: IDLE,
    deletePackage: IDLE
  },
  createCategoryPopup: false,
};

const adminFrontendPackageSlice = createSlice({
  name: "adminFrontendPackage",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setUpdateDoneSlice: (state, { payload }) => {
      state.updateDone = payload.value;
    },
    toggleCreateCategoryPopup: (state, { payload }) => {
      state.createCategoryPopup = payload.value;
    },
    setActiveCategory: (state, { payload }) => {
      state.activeCategory = payload.value;
    },
    setActiveSubCategory: (state, { payload }) => {
      state.activeSubCategory = payload.value;
    },
    setSelectedCategory: (state, { payload }) => {
      state.form.selectedCategory = payload;
    },
    setSelectedSubCategory: (state, { payload }) => {
      state.form.selectedSubCategory = payload;
    },
    setCurrentPackageId: (state, { payload }) => {
      state.currentPackageId = payload;
    },
    setAllPackagesDisplay: (state, { payload }) => {
      state.data.allPackagesDisplay = payload;
    },
    setError: (state, { payload }) => {
      state.isError = true;
      state.errorData = {
        message: payload.message,
        type: payload.type,
        errors: payload.errors,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // get all super categories ======================================================================
      .addCase(getAllSuperCategories.pending, (state, action) => {
        // state.status.getAllSuperCategories = LOADING;
        state.loading = true;
      })
      .addCase(getAllSuperCategories.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllSuperCategories = FULFILLED;
            state.loading = false;
            state.data.allSuperCategories = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllSuperCategories.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllSuperCategories = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // Create new category ======================================================================
      .addCase(createNewCategory.pending, (state, action) => {
        // state.status.createNewCategory = LOADING;
        state.loading = true;
      })
      .addCase(createNewCategory.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.createNewCategory = FULFILLED;
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createNewCategory.rejected, (state, action) => {
        state.errorData = action.payload;
        state.loading = false;
        state.status.createNewCategory = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all sub ======================================================================
      .addCase(getAllSubCategories.pending, (state, action) => {
        // state.status.getAllSubCategories = LOADING;
        state.loading = true;
      })
      .addCase(getAllSubCategories.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllSubCategories = FULFILLED;
            state.loading = false;
            state.data.allSubCategories = payload.data;

            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllSubCategories.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllSubCategories = ERROR;
        state.isError = true;
      })
      // get all categories ======================================================================
      .addCase(getAllCategories.pending, (state, action) => {
        // state.status.getAllCategories = LOADING;
        state.loading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllCategories = FULFILLED;
            state.loading = false;
            state.data.allCategories = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.errorData = action.payload;
        state.loading = false;
        state.status.getAllCategories = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // create new sub category ======================================================================
      .addCase(createSubCategory.pending, (state, action) => {
        // state.status.createSubCategory = LOADING;
        state.loading = true;
      })
      .addCase(createSubCategory.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.form.selectedCategory = payload?.data?.category;
            state.form.selectedSubCategory = payload?.data?.subCategory;
            state.activeCategory = payload?.data?.category;
            state.activeSubCategory = payload?.data?.subCategory;
            state.status.createSubCategory = FULFILLED;
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createSubCategory.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.createSubCategory = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all addons global======================================================================
      .addCase(getAllAddons.pending, (state, action) => {
        // state.status.getAllAddons = LOADING;
        state.loading = true;
      })
      .addCase(getAllAddons.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllAddons = FULFILLED;
            state.loading = false;
            state.data.allAddons = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllAddons.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllAddons = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // create package ======================================================================
      .addCase(createPackageSlice.pending, (state, action) => {
        // state.status.createPackageSlice = LOADING;
        state.loading = true;
      })
      .addCase(createPackageSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.createPackageSlice = FULFILLED;
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createPackageSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.createPackageSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // Get num packages ======================================================================
      .addCase(getNumPackagesSlice.pending, (state, action) => {
        // state.status.getNumPackagesSlice = LOADING;
        state.loading = true;
      })
      .addCase(getNumPackagesSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getNumPackagesSlice = FULFILLED;
            state.loading = false;
            state.data.packageCount = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getNumPackagesSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getNumPackagesSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // Get all packages ======================================================================
      .addCase(getPackageWithFiltersSlice.pending, (state, action) => {
        // state.status.getPackageWithFiltersSlice = LOADING;
        state.loading = true;
      })
      .addCase(getPackageWithFiltersSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getPackageWithFiltersSlice = FULFILLED;
            state.loading = false;
            state.data.allPackages = payload.data;
            state.data.allPackagesDisplay = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPackageWithFiltersSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getPackageWithFiltersSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // Get filteredPackages v1
      .addCase(getFilteredPackagesV1.pending, (state, action) => {
        // state.status.getPackageWithFiltersSlice = LOADING;
        state.loading = true;
      })
      .addCase(getFilteredPackagesV1.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.allPackages = payload.data;
            state.data.allPackagesDisplay = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getFilteredPackagesV1.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
      })
      // =============================================================
      // Get all package info ======================================================================
      .addCase(getPackageInfoSlice.pending, (state, action) => {
        // state.status.getPackageInfoSlice = LOADING;
        state.loading = true;
      })
      .addCase(getPackageInfoSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getPackageInfoSlice = FULFILLED;
            state.loading = false;
            state.data.currentPackageInfo = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPackageInfoSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getPackageInfoSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // Get all trending now tags ======================================================================
      .addCase(getAllTrendingTags.pending, (state, action) => {
        // state.status.getAllTrendingTags = LOADING;
        state.loading = true;
      })
      .addCase(getAllTrendingTags.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllTrendingTags = FULFILLED;
            state.loading = false;
            state.data.allTrendingNowTags = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllTrendingTags.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllTrendingTags = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // Add package to trending ======================================================================
      .addCase(addPackageToTrending.pending, (state, action) => {
        // state.status.addPackageToTrending = LOADING;
        state.loading = true;
      })
      .addCase(addPackageToTrending.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.addPackageToTrending = FULFILLED;
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addPackageToTrending.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.addPackageToTrending = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get all trending packages ======================================================================
      .addCase(getAllTrendingPackages.pending, (state, action) => {
        // state.status.getAllTrendingPackages = LOADING;
        state.loading = true;
      })
      .addCase(getAllTrendingPackages.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllTrendingPackages = FULFILLED;
            state.loading = false;
            state.data.allTrendingPackages = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllTrendingPackages.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllPackages = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getAllPackagesThunk.pending, (state, action) => {
        // state.status.getAllTrendingPackages = LOADING;
        state.loading = true;
      })
      .addCase(getAllPackagesThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllPackages = FULFILLED;
            state.loading = false;
            state.data.allfetchedPackages = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllPackagesThunk.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAllPackages = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // remove package from trending ======================================================================
      .addCase(removePackageFromTrendingSlice.pending, (state, action) => {
        // state.status.removePackageFromTrendingSlice = LOADING;
        state.loading = true;
      })
      .addCase(
        removePackageFromTrendingSlice.fulfilled,
        (state, { payload }) => {
          state.updateDone = !state.updateDone;
          switch (payload.type) {
            case SUCCESS:
              state.status.removePackageFromTrendingSlice = FULFILLED;
              state.loading = false;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(removePackageFromTrendingSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.removePackageFromTrendingSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // toggle trending status  ======================================================================
      .addCase(changeTrendingStatus.pending, (state, action) => {
        // state.status.changeTrendingStatus = LOADING;
        state.loading = true;
      })
      .addCase(changeTrendingStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.changeTrendingStatus = FULFILLED;
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(changeTrendingStatus.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.changeTrendingStatus = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get admin prices  ======================================================================
      .addCase(getAdminPrice.pending, (state, action) => {
        // state.status.getAdminPrice = LOADING;
        state.loading = true;
      })
      .addCase(getAdminPrice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.getAdminPrice = FULFILLED;
            state.loading = false;
            state.data.adminPrices = payload.data;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAdminPrice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getAdminPrice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      // get package base prices  ======================================================================
      .addCase(getPackagePriceSlice.pending, (state, action) => {
        // state.status.getAdminPrice = LOADING;
        state.loading = true;
      })
      .addCase(getPackagePriceSlice.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.packageBasePrice = payload.data;
            state.status.getPackagePriceSlice = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getPackagePriceSlice.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.status.getPackagePriceSlice = ERROR;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // get package for search params  ======================================================================
      .addCase(getPackageForSearchParamThunk.pending, (state, action) => {
        // state.status.getAdminPrice = LOADING;
        state.loading = true;
      })
      .addCase(
        getPackageForSearchParamThunk.fulfilled,
        (state, { payload }) => {
          switch (payload.type) {
            case SUCCESS:
              state.loading = false;
              state.data.searchedPackages = payload.data;
              state.status.getPackageForSearchParamThunk = FULFILLED;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(getPackageForSearchParamThunk.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
        state.status.getPackageForSearchParamThunk = ERROR;
      })
      //get searched package full results ======================================================================
      .addCase(
        getPackageForSearchParamFullResultsThunk.pending,
        (state, action) => {
          // state.status.getAdminPrice = LOADING;
          state.loading = true;
        }
      )
      .addCase(
        getPackageForSearchParamFullResultsThunk.fulfilled,
        (state, { payload }) => {
          switch (payload.type) {
            case SUCCESS:
              state.loading = false;
              state.data.searchedPackagesFull = payload.data;
              state.status.getPackageForSearchParamFullResultsThunk = FULFILLED;
              break;
            default:
              state.loading = false;
              state.isError = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        getPackageForSearchParamFullResultsThunk.rejected,
        (state, action) => {
          state.errorData = REJECTED_ERROR;
          state.loading = false;
          state.isError = true;
          state.status.getPackageForSearchParamFullResultsThunk = ERROR;
        }
      )
      // ========================================
      // deleteImageThunk
      .addCase(deleteImageThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteImageThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.deleteImageThunk = FULFILLED;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteImageThunk.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.status.deleteImageThunk = ERROR;
        state.errorData = REJECTED_ERROR;
      })

      //========================================================
      // deleteImageThunk
      .addCase(deleteInBoxImage.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteInBoxImage.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.deleteInBoxImage = FULFILLED;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteInBoxImage.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.status.deleteInBoxImage = ERROR;
        state.errorData = REJECTED_ERROR;
      })
      //edit package details=============================================================================
      .addCase(editPackageThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(editPackageThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            //state.data = payload.data;
            state.updateDone = !state.updateDone;
            break;

          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            break;
        }
      })
      .addCase(editPackageThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = {
          message: payload.message,
          type: payload.type,
          errors: payload.errors,
        };
      })


      .addCase(editPackageCsvThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(editPackageCsvThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            //state.data = payload.data;
            state.updateDone = !state.updateDone;
            break;

          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            break;
        }
      })
      .addCase(editPackageCsvThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = {
          message: payload.message,
          type: payload.type,
          errors: payload.errors,
        };
      })
      //delete super category=============================================================================
      .addCase(deleteSuperCategoryThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteSuperCategoryThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            state.status.deleteSuperCategoryThunk = FULFILLED;
            break;

          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            break;
        }
      })
      .addCase(deleteSuperCategoryThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.status.deleteSuperCategoryThunk = ERROR;
        state.errorData = {
          message: payload.message,
          type: payload.type,
          errors: payload.errors,
        };
      })

      .addCase(getSearchPackageForCoupon.pending, (state, action) => {
        // state.status.getAdminPrice = LOADING;
        state.loading = true;
      })
      .addCase(getSearchPackageForCoupon.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.searchedPackages = payload.data;
            state.status.getSearchPackageForCoupon = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getSearchPackageForCoupon.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
        state.status.getSearchPackageForCoupon = ERROR;
      })

      .addCase(changeBentoCategoryThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(changeBentoCategoryThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.bentoCategoryStatus = !state.data.bentoCategoryStatus;
            state.status.changeBentoCategoryThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(changeBentoCategoryThunk.rejected, (state, action) => {
        state.errorData = REJECTED_ERROR;
        state.loading = false;
        state.isError = true;
        state.status.changeBentoCategoryThunk = ERROR;
      })

      // delete banner
      .addCase(deletePackage.pending, (state, action) => {
        // state.status.getAllAddonsList = LOADING;
        state.loading = true;
      })
      .addCase(deletePackage.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deletePackage = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deletePackage.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deletePackage = ERROR;
      })

  },
});

export default adminFrontendPackageSlice.reducer;
export const {
  clearErrorSlice,
  toggleCreateCategoryPopup,
  setUpdateDoneSlice,
  setActiveCategory,
  setActiveSubCategory,
  setSelectedCategory,
  setSelectedSubCategory,
  setCurrentPackageId,
  setAllPackagesDisplay,
  setError,
} = adminFrontendPackageSlice.actions;
