import React, { useState } from "react";

import { Option } from "../../assets";
import PopUp from "../PopUp/PopUp";
import { usePopper } from "react-popper";

function TableRow({ tableRow, PopUpComponent }) {
  const [isPopup, setPopup] = useState(false);
  const [popElm, setPopElm] = useState();
  const [refElm, setRefElm] = useState();

  const popUpParamsBtmEnd = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-16, 0],
        },
      },
    ],
  };

  const popper = usePopper(refElm, popElm, popUpParamsBtmEnd);
  return (
    <tr>
      {tableRow.displayData.map((elm) => (
        <td>{elm}</td>
      ))}

      {PopUpComponent && (
        <td style={{ paddingRight: ".5rem" }} id="last-child-width">
          <img
            ref={setRefElm}
            onClick={() => setPopup(true)}
            src={Option}
            className={"option" + tableRow.displayData[0]}
          ></img>
          {/* {isPopup && <PopUpComponent/>} */}
          <PopUp
            display={isPopup}
            callback={setPopup}
            zIndex={11}
            uniqueId={"lastelm"}
            styles={{ background: "rgba(0,0,0,0.1)" }}
          >
            <div
              ref={setPopElm}
              style={{ ...popper.styles.popper }}
              {...popper.attributes}
            >
              {tableRow.PopUp ? (
                <tableRow.PopUp />
              ) : (
                <PopUpComponent
                  id={tableRow.navigateTo}
                  orderId={tableRow.navigateTo}
                />
              )}
            </div>
          </PopUp>
        </td>
      )}
    </tr>
  );
}

export default TableRow;
