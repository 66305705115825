import "./adminnavbar.css";

import { ADMIN_FRONTEND_FAQS, ADMIN_INV_LIST_REQ, ADMIN_ORDER } from "../../../constants/links";
import {
  Downarrowcolor,
  Listedinventorycolor,
  Ordercolor,
  Timercolor,
} from "../../../assets";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { remvoeSeenNotication } from "../../../redux/features/adminNotificationSlice/adminNotificatioSlice";

const AdminNotification = ({ attributes, styleProp, refProp }) => {
  const dispatch = useDispatch();
  const allNotification = useSelector(
    (state) => state.rootReducer.adminNotification.allNotification
  );
  const userId = useSelector((state) => state.rootReducer.empInfo.data.empId);
  const newOrder = useSelector(
    (state) => state.rootReducer.adminNotification.orderNotificationData
  );

  const newListing = useSelector(
    (state) => state.rootReducer.adminNotification.listingNotificationData
  );

  const faqRequests = allNotification.filter((notification) => {
    return notification.startsWith("FAQ_REQ");
  });
  
  // to get newOrder in it's String for deleting operation in redis
  const changeFormat = (arr) => {
    const newArr = [];
    for (const i of arr) {
      for (const v of allNotification) {
        if (i.id == v.split(":")?.[1]) {
          newArr.push(v);
        }
      }
    }
    return newArr;
  };

  function getTimeDifference(inputDateTime) {
    // Convert input date/time string to Date object
    const inputDate = new Date(inputDateTime);

    // Calculate the difference between the input date/time and the current time in milliseconds
    const timeDiffInMs = new Date() - inputDate;

    // Convert the time difference from milliseconds to hours, minutes, and seconds
    const hoursDiff = Math.floor(timeDiffInMs / 3600000);
    const minutesDiff = Math.floor((timeDiffInMs % 3600000) / 60000);
    const secondsDiff = Math.floor(((timeDiffInMs % 3600000) % 60000) / 1000);

    // Return the time difference as a string
    return `${hoursDiff > 0 ? `${hoursDiff} hrs ,` : ``}  ${
      minutesDiff > 0 ? `${minutesDiff} min ,` : ``
    } ${secondsDiff} sec ago`;
  }

  const removeseenNotification = (seenNotifications) => {
    dispatch(
      remvoeSeenNotication({
        userId: userId,
        seenNotifications: changeFormat(seenNotifications),
      })
    );
  };

  return (
    <div
      className="admin-notification-popup-container"
      ref={refProp}
      style={{ ...styleProp }}
      {...attributes}
    >
      <div className="admin-notification-popup-header">
        <p>Notification</p>
      </div>
      <div className="admin-notification-popup-content">
        <div className="admin-notification-popup-inner-content">
          {newOrder.length > 0 && (
            <Link to={ADMIN_ORDER}>
              <div
                onClick={() => removeseenNotification(newOrder)}
                className="admin-notification-popup-inner-container"
              > 
                <img
                  src={Ordercolor}
                  alt="ordercolor"
                  style={{ marginBottom: "1.3rem" }}
                />
                <div className="admin-notification-desc">
                  <p id="admin-popup-text">
                    {newOrder.length} New Order Arrived{" "}
                    <img src={Downarrowcolor} alt="downarrow" />
                  </p>
                  <p id="admin-popup-timer">
                    {" "}
                    <img src={Timercolor} alt="" />
                    {getTimeDifference(
                      new Date(parseInt(newOrder[newOrder.length - 1]?.time))
                    )}
                  </p>
                </div>
                {/* <img src={GradientDone} alt="gradientdone" /> */}
              </div>
            </Link>
          )}

           {newListing.length > 0 && (
            <Link to={ADMIN_INV_LIST_REQ}>
              <div
                onClick={() => removeseenNotification(newListing)}
                className="admin-notification-popup-inner-container"
              >
                <img
                  src={Listedinventorycolor}
                  alt="inventorycolor"
                  style={{ marginBottom: "1.3rem" }}
                />
                <div className="admin-notification-desc">
                  <p id="admin-popup-text">
                    {newListing.length} New Listing Request{" "}
                  </p>
                  <p id="admin-popup-timer">
                    {" "}
                    <img src={Timercolor} alt="" />
                    {getTimeDifference(
                      new Date(
                        parseInt(newListing[newListing.length - 1]?.time)
                      )
                    )}
                  </p>
                </div>
                {/* <img src={GradientDone} alt="gradientdone" /> */}
              </div>
            </Link>
          )}

          {faqRequests.length > 0 && (
            <Link to={ADMIN_FRONTEND_FAQS}>
              <div
                onClick={() => removeseenNotification(faqRequests)}
                className="admin-notification-popup-inner-container"
              >
                <div className="admin-notification-desc">
                  <p id="admin-popup-text">
                    {faqRequests.length} New FAQ Request{" "}
                  </p>
                  <p id="admin-popup-timer">
                    {" "}
                    <img src={Timercolor} alt="" />
                    {getTimeDifference(
                      new Date(
                        parseInt(
                          faqRequests[faqRequests.length - 1]?.split(":")[2]
                        )
                      )
                    )}
                  </p>
                </div>
              </div>
            </Link>
          )}
          {/*

          <div className="admin-notification-popup-inner-container">
            <img
              src={Productsupportcolor}
              alt="productrequest"
              style={{ marginBottom: "1.3rem" }}
            />
            <div className="admin-notification-desc">
              <p id="admin-popup-text">2 Product Sup Request</p>
              <p id="admin-popup-timer">
                {" "}
                <img src={Timercolor} alt="" /> 30 sec ago
              </p>
            </div>
            <img src={GradientDone} alt="gradientdone" />
          </div>

          <div className="admin-notification-popup-inner-container">
            <img
              src={Faqscolor}
              alt="faqscolor"
              style={{ marginBottom: "1.3rem" }}
            />
            <div className="admin-notification-desc">
              <p id="admin-popup-text">2 New FAQ Request </p>
              <p id="admin-popup-timer">
                {" "}
                <img src={Timercolor} alt="" /> 30 sec ago
              </p>
            </div>
            <img src={GradientDone} alt="gradientdone" />
          </div>

          <div className="admin-notification-popup-inner-container">
            <img
              src={Messagecolor}
              alt="messagecolor"
              style={{ marginBottom: "1.3rem" }}
            />
            <div className="admin-notification-desc">
              <p id="admin-popup-text">2 New Message </p>
              <p id="admin-popup-timer">
                {" "}
                <img src={Timercolor} alt="" /> 30 sec ago
              </p>
            </div>
            <img src={Donecolor} alt="gradientdone" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminNotification;
