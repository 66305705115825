import "./myorderpackagedetails.css";

import { CANCELLED, COMPLETE, DISAPPROVED } from "../../constants/constants";
import { MyorderExtendPackage, MyorderPackagePayment } from "..";
import { Pc, SmallCross } from "../../assets";
import React, { useEffect } from "react";
import { dateFormatter, getNoOfDays } from "../../utils/displayUtils";

import useResponsiveWindow from "../../customHooks/ResponsiveWindownHook";
import { useState } from "react";

import {
  getDuration,
  priceFormatterDaily,
  getSubTotalMonthCap,
} from "../../utils/displayUtils";
import { getPriceDataByOrderThunk } from "../../redux/features/paymentSlice/paymentSlice";
import { useDispatch } from "react-redux";
import { detectOverflow } from "@popperjs/core";

const Myorderpackagedetails = (props) => {
  const [isPackage, setIsPackage] = useState(false);
  const [isPayment, setIsPayment] = useState(false);

  const [isDone, setIsDone] = useState(false);
  const window = useResponsiveWindow();
  const [priceDetails, setPriceDetails] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPackage || isPayment || isDone) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  useEffect(() => {
    console.log(props);
    console.log(props?.status?.orderId);
    dispatch(getPriceDataByOrderThunk({ orderId: props?.status?.orderId }))
      .unwrap()
      .then((data) => {
        console.log(data);
        if (data.type === "SUCCESS") {
          setPriceDetails(data.data);
        }
      });
  }, []);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const Details = {
    packageName: priceDetails?.orderDetails?.packageInfo?.packageName,
    startDate: `${new Date(priceDetails?.orderDetails?.startDate).getDate()}, ${
      monthNames[new Date(priceDetails?.orderDetails?.startDate).getMonth()]
    } ${new Date(priceDetails?.orderDetails?.startDate).getFullYear()}`,
    endDate: `${new Date(priceDetails?.orderDetails?.endDate).getDate()}, ${
      monthNames[new Date(priceDetails?.orderDetails?.endDate).getMonth()]
    } ${new Date(priceDetails?.orderDetails?.endDate).getFullYear()}`,
    tenure: priceDetails?.numDays,
    addons: priceDetails?.price?.addons,
    price: priceDetails?.price,
  };

  const isPaymentDone = priceDetails?.orderDetails?.orders[0].paymentInitiated;

  {
    getSubTotalMonthCap(Details.tenure, Details.price?.addedGst) +
      Details.price?.delivery?.lineTotal;
  }

  return (
    <>
      {/* <div className="myorder-package-details-container"> */}
      <div className="myorder-package-details-content">
        <p id="package-details-text">
          Package Details{" "}
          <img
            src={SmallCross}
            alt="smallcross"
            style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
            onClick={props.onclick}
          />
        </p>
        <div className="myorder-package-product-desc">
          <div className="package-product-desc-img">
            <img
              src={props.status?.status?.data?.packageInfo?.image}
              alt="pc"
            />
          </div>

          <div className="package-product-name">
            {window.phone ? (
              <p>
                {props.status?.status?.data?.packageInfo?.packageName.slice(
                  0,
                  19
                )}
              </p>
            ) : (
              <p>{props.status?.status?.data?.packageInfo?.packageName}</p>
            )}
            <p>
              <span>Addons: </span> ..
            </p>
          </div>
        </div>

        <div className="myorder-package-renting-details">
          <div className="package-days-details">
            <p>
              <span>Start date: </span>{" "}
              {dateFormatter(props.status?.status?.data?.startDate)}
            </p>
            <p>
              <span>End date: </span>{" "}
              {dateFormatter(props.status?.status?.data?.endDate)}
            </p>
            <p>
              <span>Days left: </span>{" "}
              {Math.floor(
                getNoOfDays(new Date(), props.status?.status?.data?.endDate)
              )}
            </p>
            <p className="mobile-toatal-cost-status">
              <span>Total Cost: </span> ₹7200
            </p>
            <p className="mobile-toatal-cost-status">
              <span>Payment Status: </span>Paid
            </p>
            {window.tablet || window.phone ? (
              <button
                id="package-detail-done-button"
                type="submit"
                onClick={props.onclick}
              >
                Done
              </button>
            ) : (
              <button
                id="extend-btn"
                type="submit"
                onClick={() => setIsPackage(!isPackage)}
                disabled={
                  [DISAPPROVED, CANCELLED, COMPLETE].includes(
                    props?.status?.status?.data?.orderStatus?.status
                  )
                    ? true
                    : false
                }
              >
                Extend Date
              </button>
            )}
            {isPackage && (
              <MyorderExtendPackage
                onclick={props.onclick}
                orderId={props?.status?.status?.data?._id}
              />
            )}
          </div>

          <div className="package-renting-details">
            {/* <p>
              <span>
                {Details.tenure < 30 ? "Daily Rent" : "Monthly Rent"}:{" "}
              </span>
              ₹
              {priceFormatterDaily(Details?.tenure, Details?.price?.withAddons)}
            </p> */}

            {/* <p>
              <span>
                {Details.tenure < 30 ? "Daily Rent" : "Monthly Rent"}:{" "}
              </span>
              ₹
              {isPaymentDone
                ? Math.round(
                    (priceDetails.deliveryCharge +
                      priceDetails.orderDetails.totalPrice) /
                      (Details.tenure < 30
                        ? Details.tenure
                        : Details.tenure / 30) // Adjust for daily or monthly calculation
                  ) +
                  ` / ${
                    Details.tenure < 30
                      ? `${Details.tenure} days`
                      : `${Math.floor(Details.tenure / 30)} months`
                  }`
                : priceFormatterDaily(
                    Details.tenure,
                    Details.price?.withAddons
                  )}
            </p> */}

            {/* <p>
              <span>
                {Details.tenure < 30 ? "Daily Rent" : "Monthly Rent"}:{" "}
              </span>
              ₹{priceFormatterDaily(priceDetails?.orderDetails?.duration, Details.price?.withAddons)}
            </p> */}

            <p>
              <span>
                {priceDetails?.orderDetails?.duration < 30
                  ? "Daily Rent"
                  : "Monthly Rent"}
                :{" "}
              </span>
              ₹
              {priceFormatterDaily(
                priceDetails?.orderDetails?.duration,
                priceDetails?.orderDetails?.orders[0]?.price?.withAddons
              )}
            </p>

            <p>
              <span>Tenure: </span>{" "}
              {getDuration(priceDetails?.orderDetails?.duration)}
            </p>
            <p>
              <span>Remaining Pay: </span> ₹
              {/* {Math.floor(
                priceDetails?.orderDetails?.totalPrice +
                  priceDetails?.deliveryCharge -
                  (getSubTotalMonthCap(
                    Details?.tenure,
                    Details?.price?.addedGst
                  ) +
                    Details?.price?.delivery?.lineTotal)
              )} */}
              {/* {isPaymentDone ? "0" : Math.floor(
                priceDetails?.orderDetails?.totalPrice +
                  priceDetails?.deliveryCharge -
                  (getSubTotalMonthCap(
                    priceDetails?.orderDetails?.duration,
                    priceDetails?.orderDetails?.orders[0]?.price?.addedGst
                  ) +
                  priceDetails.deliveryCharge)
              )} */}
              {isPaymentDone
                ? "0"
                : Math.floor(
                    priceDetails?.orderDetails?.totalPrice +
                      priceDetails?.deliveryCharge
                  )}
            </p>
            <p>
              <span>Total Payment: </span> ₹
              {/* {getSubTotalMonthCap(Details.tenure, Details.price?.addedGst) +
                Details.price?.delivery?.lineTotal} */}
              {getSubTotalMonthCap(
                priceDetails?.orderDetails?.duration,
                priceDetails?.orderDetails?.orders[0]?.price?.addedGst
              ) + priceDetails.deliveryCharge}
            </p>
          </div>
        </div>

        <div className="myorder-payment-status-details">
          <p>
            Monthly Payment Status: <span id="pending-text">Pending</span>
          </p>
          {/* <p>
            Monthly Payment Status:{" "}
            {isPaymentDone ? (
              <span style={{ color: "green" }}>Completed</span>
            ) : (
              <span id="pending-text">Pending</span>
            )}
          </p> */}
          <p>
            Due Date: <span id="date">21/03/22</span>
          </p>
          <button
            type="submit"
            id="payment-btn"
            style={{ alignSelf: "self-start" }}
            onClick={() => setIsPayment(!isPayment)}
            disabled={
              [DISAPPROVED, CANCELLED, COMPLETE].includes(
                props?.status?.status?.data?.orderStatus?.status
              )
                ? true
                : false
            }
          >
            Pay Now
          </button>
          {isPayment && <MyorderPackagePayment onclick={props.onclick} />}
        </div>
      </div>
      {/* </div> */}
    </>
  );
  // }
};

export default Myorderpackagedetails;
