import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  // REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

var config = {
  responseType: "blob",
};

export const usersDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getPreviousData",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getPreviousData`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}UserData.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const faqDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getAllFaqData",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getAllFaqData`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
            location: payload.empLoaction,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}UserData.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const contactUsDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getAllContactUsData",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getAllContactUsData`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}ContactUs.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const longTermOrderDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getLongTermOrdersData",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getLongTermOrdersData`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new 
          
          ([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}UserData.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const shortTermOrderDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getShortTermOrdersData",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getShortTermOrdersData`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}UserData.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const listedInventoriesDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getListingRequestInventory",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getListingRequestInventory`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
            location: payload.location,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}ListingRequest.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllPackageasDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getAllPackagesData",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getAllPackagesData`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          console.log("hello");
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}AllPackages.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllProductSupportDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getProductSupport",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getProductSupport`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
            location: payload.location,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}ProductSupport.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllAvailProductDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getAllAvailProduct",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getAllAvailProduct`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
            location: payload.location,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}ProductAvailability.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllMaintainenceProductDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getAllMaintainenceProduct",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getAllMaintainenceProduct`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
            location: payload.location,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}AllMaintainence.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllListingRequestDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getAllListingRequest",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getAllListingRequest`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
            location: payload.location,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}AllListingRequest.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserPaymentsDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getUserPaymentsDownload",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getUserPaymentsDownload`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}UserPayment.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDailyLogisticsDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getDailyLogisticsDataDownload",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getDailyLogisticsDataDownload`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}DailyLogistics.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDisapprovedOrderDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getDisapprovedOrderDataDownload",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getDisapprovedOrderDataDownload`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}DisapprovedOrders.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getBusinessReqDataDownload = createAsyncThunk(
  "get/admin/admin_frontend/dataDownload/getBusinessReqDataDownload",
  async (payload) => {
    try {
      const res = await axios
        .post(
          `${BASE_URL}/admin/admin_frontend/dataDownload/getBusinessReqDataDownload`,
          {
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "octet-stream" });
          const href = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href,
            style: "display:none",
            download: `${new Date(new Date())}BuisnessRequests.csv`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          a.remove();
        })
        .catch((err) => console.log(err));

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  currDataOpt: "USER",
  loading: false,
  updateDone: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  status: {
    usersDataDownload: IDLE,
    faqDataDownload: IDLE,
    contactUsDataDownload: IDLE,
    longTermOrderDataDownload: IDLE,
    shortTermOrderDataDownload: IDLE,
    listedInventoriesDataDownload: IDLE,
    getAllPackageasDataDownload: IDLE,
    getAllProductSupportDataDownload: IDLE,
    getAllAvailProductDataDownload: IDLE,
    getAllMaintainenceProductDataDownload: IDLE,
    getAllListingRequestDataDownload: IDLE,
  },
  data: {},
};

const adminDataDownloadSlice = createSlice({
  name: "dataDownload",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, { payload }) => {
      state.isError = false;
      state.errorData = {};
    },
    setcurrentOpt: (state, { payload }) => {
      state.currDataOpt = payload.currData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(usersDataDownload.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.usersDataDownload = FULFILLED;
            state.updateDone = true;
            break;
          default:
            state.isError = true;
            state.loading = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(usersDataDownload.rejected, (state, { payload }) => {
        state.loading = false;
        state.status.usersDataDownload = ERROR;
      })
      .addCase(faqDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(faqDataDownload.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.status.faqDataDownload = FULFILLED;
            break;
          default:
            state.isError = true;
            state.loading = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(faqDataDownload.rejected, (state, { payload }) => {
        state.loading = false;
        // state.isError = true;
        // state.errorData = REJECTED_ERROR;
      })
      // contact us
      .addCase(contactUsDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(contactUsDataDownload.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.status.contactUsDataDownload = FULFILLED;
            break;
          default:
            state.isError = true;
            state.loading = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(contactUsDataDownload.rejected, (state, { payload }) => {
        state.loading = false;
        // state.isError = true;
        // state.errorData = REJECTED_ERROR;
      })
      // long term order
      .addCase(longTermOrderDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(longTermOrderDataDownload.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.status.longTermOrderDataDownload = FULFILLED;
            break;
          default:
            state.isError = true;
            state.loading = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(longTermOrderDataDownload.rejected, (state, { payload }) => {
        state.loading = false;
        // state.isError = true;
        // state.errorData = REJECTED_ERROR;
      })
      // long term order
      .addCase(shortTermOrderDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(shortTermOrderDataDownload.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.status.shortTermOrderDataDownload = FULFILLED;
            break;
          default:
            state.isError = true;
            state.loading = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(shortTermOrderDataDownload.rejected, (state, { payload }) => {
        state.loading = false;
        // state.isError = true;
        // state.errorData = REJECTED_ERROR;
      })
      //listed inventory
      .addCase(listedInventoriesDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(
        listedInventoriesDataDownload.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.status.listedInventoriesDataDownload = FULFILLED;
              break;
            default:
              state.isError = true;
              state.loading = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(listedInventoriesDataDownload.rejected, (state, { payload }) => {
        state.loading = false;
        // state.isError = true;
        // state.errorData = REJECTED_ERROR;
      })
      .addCase(getAllPackageasDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAllPackageasDataDownload.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.status.getAllPackageasDataDownload = FULFILLED;
            break;
          default:
            state.isError = true;
            state.loading = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllPackageasDataDownload.rejected, (state, { payload }) => {
        state.loading = false;
        // state.isError = true;
        // state.errorData = REJECTED_ERROR;
      })
      .addCase(
        getAllProductSupportDataDownload.pending,
        (state, { payload }) => {
          state.loading = true;
        }
      )
      .addCase(
        getAllProductSupportDataDownload.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.status.getAllProductSupportDataDownload = FULFILLED;
              break;
            default:
              state.isError = true;
              state.loading = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        getAllProductSupportDataDownload.rejected,
        (state, { payload }) => {
          state.loading = false;
          // state.isError = true;
          // state.errorData = REJECTED_ERROR;
        }
      )
      .addCase(getAllAvailProductDataDownload.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(
        getAllAvailProductDataDownload.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.status.getAllAvailProductDataDownload = FULFILLED;
              break;
            default:
              state.isError = true;
              state.loading = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        getAllAvailProductDataDownload.rejected,
        (state, { payload }) => {
          state.loading = false;
          // state.isError = true;
          // state.errorData = REJECTED_ERROR;
        }
      )
      .addCase(
        getAllMaintainenceProductDataDownload.pending,
        (state, { payload }) => {
          state.loading = true;
        }
      )
      .addCase(
        getAllMaintainenceProductDataDownload.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.status.getAllMaintainenceProductDataDownload = FULFILLED;
              break;
            default:
              state.isError = true;
              state.loading = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        getAllMaintainenceProductDataDownload.rejected,
        (state, { payload }) => {
          state.loading = false;
          // state.isError = true;
          // state.errorData = REJECTED_ERROR;
        }
      )
      .addCase(
        getAllListingRequestDataDownload.pending,
        (state, { payload }) => {
          state.loading = true;
        }
      )
      .addCase(
        getAllListingRequestDataDownload.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          switch (payload.type) {
            case SUCCESS:
              state.status.getAllListingRequestDataDownload = FULFILLED;
              break;
            default:
              state.isError = true;
              state.loading = true;
              state.errorData = {
                message: payload.message,
                type: payload.type,
                errors: payload.errors,
              };
          }
        }
      )
      .addCase(
        getAllListingRequestDataDownload.rejected,
        (state, { payload }) => {
          state.loading = false;
          // state.isError = true;
          // state.errorData = REJECTED_ERROR;
        }
      );
  },
});

export default adminDataDownloadSlice.reducer;
export const { clearErrorSlice, setcurrentOpt } =
  adminDataDownloadSlice.actions;
