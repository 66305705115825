import {
  BASE_URL,
  ERROR,
  FAILURE,
  FULFILLED,
  IDLE,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;

export const createFaqQuestionthunk = createAsyncThunk(
  "user/faqQustion/createFaqQuestion",
  async (payload) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/faqQuestion/createfaq`, {
        faqQuestion: payload.faqQuestion,
        questionOn: "Products",
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSenderInfo = createAsyncThunk(
  "http://localhost:5000/admin/admin_frontend/faqQuestion/fetchQuestionDetail",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/faqQuestion/fetchQuestionDetail`,
        { question_id: payload.id }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteFaqQuestion = createAsyncThunk(
  "delete/admin/admin_frontend/faqQuestion/delteFaqRequest",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/faqQuestion/delteFaqRequest`,
        {
          currId: payload.currId,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getFaqsByLocationThunk = createAsyncThunk(
  "/admin_frontend/faqQuestion/fetchFaqsByLocation",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/faqQuestion/fetchFaqsByLocation`,
        {
          page: payload.page,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  updateDone: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  status: {
    faqQestionSend: IDLE,
  },
  data: {
    totalReqCount: 0,
    total: 0,
    data: [],
  },
  detailData: {
    userDetail: {},
    questionDetail: {},
  },
};

const faqQuestionSlice = createSlice({
  name: "faqQuestion",
  initialState: initialState,
  reducers: {
    fetchAllFaq: (state, action) => {
      state.data = action.payload.data;
    },
    clearErrorSlice: (state, { payload }) => {
      state.isError = false;
      state.errorData = {};
    },
    setQuestionDetail: (state, { payload }) => {
      state.detailData.questionDetail = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFaqQuestionthunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createFaqQuestionthunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.faqQestionSend = FULFILLED;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createFaqQuestionthunk.rejected, (state, action) => {
        state.loading = false;
        state.status.faqQestionSend = ERROR;
        state.isError = true;
        state.errorData = {
          message: "server did not respond",
          type: "SERVER ERROR",
        };
      })
      .addCase(getFaqsByLocationThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getFaqsByLocationThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.total = payload.data.total;
            state.data.data = payload.data.data;
            state.data.totalReqCount = payload.data.totalElements;
        }
      })
      .addCase(getFaqsByLocationThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = {
          message: "server did not respond",
          type: "SERVER ERROR",
        };
      })
      .addCase(getSenderInfo.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getSenderInfo.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.detailData.userDetail = payload.data;
            break;
          default:
            state.isError = true;
            state.loading = false;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getSenderInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = {
          message: "Server did not respond",
          type: "SERVER ERROR",
        };
      })
      .addCase(deleteFaqQuestion.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteFaqQuestion.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteFaqQuestion.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      });
  },
});

export default faqQuestionSlice.reducer;
export const { fetchAllFaq, clearErrorSlice, setQuestionDetail } =
  faqQuestionSlice.actions;
