import "./addnewaddress.css";

import * as Yup from "yup";

import {
  // Location,
  // MobileSearch,
  Plus,
  // SearchResult,
  SmallCross,
  // documentUpload,
} from "../../assets";
import React, { useEffect, useState } from "react";
import {
  clearErrorSlice,
  updateUserAddressSlice,
} from "../../redux/features/loginSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";

import FailureAlert from "../failureAlert/failureAlert";
import { LoadingPage } from "..";
import { SUCCESS } from "../../constants/constants";
import { reuploadUserAddressSlice } from "../../redux/features/orderSlice/orderSlice";
import { useFormik } from "formik";

const Addnewaddress = (props) => {
  const [addressProof, setAddressProof] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [isAddaddressDocumentError, setIsAddaddressDocumentError] =
    useState(false);

  const [address1, setAddress1] = useState(false);

  // const [showGoogleMap, setShowGoogleMap] = useState(false);
  // const [map, setMap] = useState(null);
  // const [marker, setMarker] = useState(null);
  // Async thunk
  const dispatch = useDispatch();
  // Constant Error
  const loading = useSelector((state) => state.rootReducer.orderInfo.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.orderInfo.isError
  );
  const location = useSelector(
    (state) => state.rootReducer?.userInfo?.data?.lastLocation
  );
  const error = useSelector((state) => state.rootReducer.orderInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  useEffect(() => {
    if (props.cond === "editAddress") {
      setInputValue(props.address1);
    }
  }, []);

  const userAddAddress = useFormik({
    initialValues: {
      address1: props.cond == "editAddress" ? inputValue : "",
      address2: props.cond == "editAddress" ? props.address2 : "",
      pincode: props.cond == "editAddress" ? props.pincode : "",
      phone: props.cond == "editAddress" ? props.phone : "",
      // alternatePhone: props.cond == "editAddress" ? props.alternatePhone : "",
      document: props.cond == "editAddress" ? props.document : "",
      location:
        location.toLowerCase().at(0).toUpperCase() +
        location.toLowerCase().slice(1, props.location.length),
    },
    validationSchema: Yup.object({
      pincode: Yup.string()
        .min(6, "pincode must be of 6 length")
        .max(6, "pincode must be of 6 length")
        // .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "Invalid pincode")
        .required("Pincode is required"),
      address1: Yup.string()
        .required("Address is required"),
      address2: Yup.string()
        .required("Address is required"),
      phone: Yup.number("Invalid phone Number")
        .max(9999999999, "Invalid phone Number")
        .min(1000000000, "Invalid phone Number")
        .required("Phone number is required"),
      // document: Yup.array()
      //   .min(1, "at least one file should be uploaded")
      //   .max(7, "only seven documents are allowed")
      //   .required("Document is required"),
    }),
    onSubmit: (values) => {
      console.log(values)
      let addressData = new FormData();

      if (addressProof.length < 1 || addressProof.length > 7) {
        setIsAddaddressDocumentError(true);
        return;
      }

      if (addressProof.length >= 1) {
        setIsAddaddressDocumentError(false);
      }

      const addressline1 = document.getElementById("autocomplete-input");

      if (!addressline1.value) {
        setAddress1(true);
        return;
      }

      addressData.append("address1", inputValue);
      addressData.append("address2", values.address2);
      addressData.append("pincode", values.pincode);
      addressData.append("phone", values.phone);
      addressData.append("location", values.location);
      addressProof.forEach((img) => addressData.append("img", img));

      const editedData = new FormData();
      editedData.append("id", props.id);
      editedData.append("address1", inputValue);
      editedData.append("address2", values.address2);
      editedData.append("pincode", values.pincode);
      editedData.append("phone", values.phone);
      editedData.append(
        "location",
        location.toLowerCase().at(0).toUpperCase() +
        location.toLowerCase().slice(1, props.location.length)
      );
      addressProof.forEach((img) => editedData.append("img", img));
      if (props.cond == "editAddress") {
        // dispatch(editUserAddressSlice(editedData)).then((data) => {
        //   console.log(data);
        //   // console.log(data.error.stack);
        //   if (data.payload.type == SUCCESS) {
        //     props.onclick();
        //   }
        // });
        dispatch(reuploadUserAddressSlice(editedData)).then((data) => {
          // console.log(data);
          // console.log(data.error.stack);
          if (data.payload.type == SUCCESS) {
            props?.setIsAddressUpdated(true);
            props.onclick();
          }
        });
      } else {
        dispatch(updateUserAddressSlice(addressData)).then((data) => {
          if (data.payload.type == SUCCESS) {
            props.onclick();
            setAddress1(false);
          }
        });
      }
    },
  });

  const [file, setFile] = useState([]);
  function handleChange(e) {
    if (addressProof.length > 7) {
      setIsAddaddressDocumentError(true);
      return;
    }
    if (addressProof.length >= 1 || addressProof.length <= 7) {
      setIsAddaddressDocumentError(false);
    }
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const allowed = allowedTypes.includes(e.target.files[0].type);
    if (!allowed) {
      alert("file type not supported");
      return false;
    }
    setAddressProof((prev) =>
      prev?.length >= 1 ? [...prev, e.target.files[0]] : [e.target.files[0]]
    );
    setFile((prev) =>
      prev?.length >= 1
        ? [
          ...prev,
          e.target.files[0].type.split("/")[0] == "image"
            ? URL.createObjectURL(e.target.files[0]) + "$img"
            : URL.createObjectURL(e.target.files[0]) + "$doc",
        ]
        : [
          e.target.files[0].type.split("/")[0] == "image"
            ? URL.createObjectURL(e.target.files[0]) + "$img"
            : URL.createObjectURL(e.target.files[0]) + "$doc",
        ]
    );
  }

  function handleCancelDoc(deleteIdx) {
    setAddressProof((prev) => prev?.filter((elm, idx) => idx !== deleteIdx));
    setFile((prev) => prev?.filter((elm, idx) => idx !== deleteIdx));
  }

  useEffect(() => {
    //google Maps Address Suggestion
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    const handleInputChange = async (input) => {
      setInputValue(input);

      if (input.length > 0) {
        try {
          const predictions = await fetchAutocompletePredictions(
            autocompleteService,
            input
          );
          setPredictions(predictions);
        } catch (error) {
          console.error("Error fetching autocomplete predictions:", error);
        }
      } else {
        setPredictions([]);
      }
    };

    const fetchAutocompletePredictions = (service, input) => {
      return new Promise((resolve, reject) => {
        service.getPlacePredictions({ input }, (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        });
      });
    };

    const inputField = document.getElementById("autocomplete-input");
    inputField.addEventListener("input", (event) => {
      handleInputChange(event.target.value);
    });

    return () => {
      inputField.removeEventListener("input", handleInputChange);
    };
  }, []);

  // useEffect(() => {
  //   //location marker google maps api
  //   if (showGoogleMap) {
  //     const google = window.google;
  //     const map = new google.maps.Map(document.getElementById("map"), {
  //       center: { lat: 19.076, lng: 72.8777 }, // co ordinates for Mumbai city
  //       zoom: 12,
  //     });
  //     setMap(map);

  //     map.addListener("click", (event) => {
  //       placeMarker(event.latLng, map);
  //     });
  //   }
  // }, [showGoogleMap]);

  // const placeMarker = (location, map) => {
  //   if (marker) {
  //     marker.setMap(null);
  //   }
  //   const newMarker = new window.google.maps.Marker({
  //     position: location,
  //     map: map,
  //     title: "Selected Location",
  //   });
  //   setMarker(newMarker);

  //   const latitude = location.lat();
  //   const longitude = location.lng();

  //   const geocoder = new window.google.maps.Geocoder();
  //   const latlng = { lat: latitude, lng: longitude };
  //   geocoder.geocode({ location: latlng }, (results, status) => {
  //     if (status === "OK") {
  //       if (results[0]) {
  //         // console.log("Address:", results[0].formatted_address);
  //         setInputValue(results[0].formatted_address);
  //       } else {
  //         console.log("No results found");
  //       }
  //     } else {
  //       console.log("Geocoder failed due to:", status);
  //     }
  //   });
  // };

  const handlePlaceSelect = (place) => {
    setInputValue(place);
    setPredictions([]);
  };

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div className="delivery-new-address-container">
        <div className="delivery-new-address-content">
          <div
            id="add-delivery-address-heading-container"
            className="new-address-headers"
          >
            {props.cond == "editAddress" ? "Edit Address" : "New Address"}
            <img
              src={SmallCross}
              alt="cross"
              style={{
                width: "1.5rem",
                cursor: "pointer",
              }}
              onClick={props.onclick}
            />
          </div>
          <div className="delivery-popup-new-edit-inner-layout">
            <div className="delivery-popup-new-edit-address-input-container">
              {/* input container */}
              <div className="address-input-box-row">
                <div className="address-input-box">
                  <div className="input-field">
                    <span className="add-new-admin-order-container">
                      <label htmlFor=""></label>
                    </span>
                    <input
                      required
                      type="number"
                      name="phone"
                      id=""
                      className="addressInput input-font"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                      value={userAddAddress.values.phone}
                      onChange={userAddAddress.handleChange}
                      onBlur={userAddAddress.handleBlur}
                    />
                    <label
                      style={{
                        color:
                          userAddAddress.errors.phone &&
                          userAddAddress.touched.phone &&
                          "#D34E4E",
                        fontSize: "0.65rem",
                      }}
                    >
                      {userAddAddress.errors.phone &&
                        userAddAddress.touched.phone
                        ? userAddAddress.errors.phone
                        : `Mobile No`}
                    </label>
                  </div>
                </div>

                <div className="address-input-box">
                  <div className="input-field">
                    <span className="add-new-admin-order-container">
                      <label htmlFor=""></label>
                    </span>
                    <input
                      required
                      type="number"
                      name="pincode"
                      id="Pincode"
                      className="addressInput input-font"
                      value={userAddAddress.values.pincode}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 6))
                      }
                      onChange={userAddAddress.handleChange}
                      onBlur={userAddAddress.handleBlur}
                    />
                    <label
                      style={{
                        color:
                          userAddAddress.errors.pincode &&
                          userAddAddress.touched.pincode &&
                          "#D34E4E",
                        fontSize: "0.65rem",
                      }}
                    >
                      {userAddAddress.errors.pincode &&
                        userAddAddress.touched.pincode
                        ? userAddAddress.errors.pincode
                        : `Pincode`}
                    </label>
                  </div>
                </div>
              </div>

              <div className="address-child-container">
                <div className="address-input-box">
                  <div className="input-field">
                    <span className="add-new-admin-order-container">
                      <label htmlFor=""></label>
                    </span>
                    <input
                      required
                      type="text"
                      name="address1"
                      id="autocomplete-input"
                      value={inputValue}
                      onChange={(event) => {setInputValue(event.target.value)
                        userAddAddress.setFieldValue("address1", inputValue)
                      }}
                      className="addressInput input-font"
                      // value={userAddAddress.values.address1}
                      // onChange={userAddAddress.handleChange}
                      onBlur={userAddAddress.handleBlur}
                      autocomplete="off" 
                      autocorrect="off"
                    />
                    <label
                      style={{
                        color: address1 && "#D34E4E",
                        fontSize: "0.65rem",
                      }}
                    >
                      {/* {userAddAddress.errors.address1 &&
                      userAddAddress.touched.address1
                        ? userAddAddress.errors.address1
                        : `Address line 1`} */}

                      {address1 ? "Address 1 Required" : `Address line 1`}
                    </label>
                    {predictions && predictions.length > 0 ? (
                      <div
                        className="address-prediction"
                        onClick={() =>
                          handlePlaceSelect(predictions[0].description)
                        }
                        style={{
                          padding: "5px",
                          cursor: "pointer"
                        }}
                      >
                        {predictions[0].description}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="ddress-input-box">
                  <div className="input-field">
                    <span className="add-new-admin-order-container">
                      <label htmlFor=""></label>
                    </span>
                    <input
                      required
                      type="text"
                      name="address2"
                      id=""
                      className="addressInput input-font"
                      value={userAddAddress.values.address2}
                      onChange={userAddAddress.handleChange}
                      onBlur={userAddAddress.handleBlur}
                    />

                    <label
                      style={{
                        fontSize: "0.65rem",
                      }}
                    >
                      Address line 2
                    </label>
                  </div>
                </div>
              </div>

              <div className="address-input-box-row">
                {/* <div className="input-field">
                  <span className="add-new-admin-order-container">
                    <label htmlFor=""></label>
                  </span>
                  <input
                    required
                    type="text"
                    name="pincode"
                    id=""
                    className="addressInput input-font"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 6))
                    }
                    value={userAddAddress.values.pincode}
                    onChange={userAddAddress.handleChange}
                    onBlur={userAddAddress.handleBlur}
                    onClick={() => setShowGoogleMap(!showGoogleMap)}
                  />
                  <label style={{ fontSize: "0.67rem" }}>
                    Click to Open and Close Google Maps
                  </label>
                </div> */}
                <div className="input-field">
                  <span className="add-new-admin-order-container">
                    <label htmlFor=""></label>
                  </span>
                  <input
                    required
                    type="tel"
                    name="city"
                    id=""
                    className="addressInput input-font"
                    value={
                      location.toLowerCase().at(0).toUpperCase() +
                      location.toLowerCase().slice(1, props.location.length)
                    }
                    onChange={userAddAddress.handleChange}
                    onBlur={userAddAddress.handleBlur}
                  />
                  <label>City</label>
                </div>
              </div>
              {/* <div className="main-map-box">
                <div className="map-box" id="map"></div>
              </div> */}

              {/* {showGoogleMap ? (
                <div className="main-map-box">
                  <div className="map-box" id="map"></div>
                </div>
              ) : (
                ""
              )} */}

              <div className="document-upload">
                <div className="new-address-headers">
                  Address Proof Document
                  {isAddaddressDocumentError ? (
                    <p htmlFor="" style={{ color: "red", fontSize: "0.7rem" }}>
                      Minimum 1 or Maximum 7 documents are required
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="address-input-preview-container">
                  <div className="file-upload">
                    <div className="file-select file-select-box">
                      <button className="file-upload-custom-btn">
                        <img src={Plus} alt="" />
                      </button>

                      <input
                        onChange={handleChange}
                        type="file"
                        name="images"
                        onBlur={userAddAddress.handleBlur}
                        id=""
                        maxLength={1}
                        className="profileimg input-font"
                        {...(props.cond !== "editAddress"
                          ? "value={userAddAddress.values.document}"
                          : "")}
                      />
                    </div>
                  </div>
                  {file &&
                    file.map((elm, idx) => (
                      <div className="preview-container" key={idx}>
                        <div>
                          <img
                            src={SmallCross}
                            alt="cross"
                            style={{
                              width: ".6rem",
                              cursor: "pointer",
                            }}
                            onClick={() => handleCancelDoc(idx)}
                          />
                        </div>
                        {elm?.split("$")[1] == "img" ? (
                          <img src={elm?.split("$")[0]} alt="" />
                        ) : (
                          <img src={"/pdf.svg"} />
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <div className="add-address-button-container">
                <button
                  id="submit"
                  type="submit"
                  onClick={userAddAddress.handleSubmit}
                >
                  {props.cond == "editAddress"
                    ? "Update Address"
                    : "Add Address"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default Addnewaddress;
