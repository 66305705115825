import "./customselectsubcatogery.css";

import { Downtitle, Uptitle } from "../../../assets";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Addsubcatogerypopup from "../Addsubcatogerypopup/addsubcatogerypopup";
import PopUp from "../../PopUp/PopUp";
import { setSelectedSubCategory } from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { useState } from "react";

const Customselectsubcatogery = ({ options }) => {
  const [createNewSubCatogery, setCreateNewSubCatogery] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const optionsList = options.length > 0 ? options : ["Please select category"];

  const dispatch = useDispatch();

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case "":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };
  const activeSubCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.activeSubCategory
  );
  const allSubCategories = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allSubCategories
  );

  useEffect(() => {
    dispatch(setSelectedSubCategory(optionsList[0]));
  }, []);

  useEffect(() => {
    if (optionsList.indexOf(activeSubCategory) !== -1) {
      dispatch(setSelectedSubCategory(activeSubCategory));
      setSelectedOption(optionsList.indexOf(activeSubCategory));
    }
  }, [activeSubCategory, allSubCategories]);

  return (
    <>
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOptionsOpen}
        className={isOptionsOpen ? "expanded" : ""}
        onClick={toggleOptions}
        onKeyDown={handleListKeyDown}
        id="admin-sub-cat-dropdown-btn"
      >
        {optionsList?.[selectedOption]?.[0] +
          optionsList?.[selectedOption]?.slice(1).toLowerCase()}
        {isOptionsOpen ? (
          <img src={Uptitle} alt="" />
        ) : (
          <img src={Downtitle} alt="" />
        )}
      </button>
      <ul
        className={`options ${isOptionsOpen ? "show" : ""}`}
        role="listbox"
        aria-activedescendant={optionsList?.[selectedOption]}
        tabIndex={-1}
        id="admin-unordered-sub-cat-list"
        onKeyDown={handleListKeyDown}
      >
        {optionsList.map((option, index) => (
          <li
            key={index}
            id={option}
            role="option"
            aria-selected={selectedOption == index}
            tabIndex={0}
            onKeyDown={handleKeyDown(index)}
            onClick={() => {
              setSelectedOption(index);
              setIsOptionsOpen(false);
              dispatch(setSelectedSubCategory(optionsList[index]));
            }}
          >
            {option?.[0] + option?.slice(1).toLowerCase()}
          </li>
        ))}
        <div
          className="admin-req-create-catogery-btn-container"
          onClick={() => setCreateNewSubCatogery(!createNewSubCatogery)}
        >
          <p>Create New +</p>
        </div>
        {/* {createNewSubCatogery && ( */}
        <PopUp
          display={createNewSubCatogery}
          callback={setCreateNewSubCatogery}
          center={true}
          zIndex={12}
        >
          <Addsubcatogerypopup
            onclick={() => setCreateNewSubCatogery(!createNewSubCatogery)}
          />
        </PopUp>
        {/* )} */}
      </ul>
    </>
  );
};

export default Customselectsubcatogery;
