// user root
export const USER_ROOT = "/";

// preLaunch root
export const PRELAUNCH_LOGIN = "/preAuth";

export const LOGIN = USER_ROOT + "login";
export const HOME = (location) => `${USER_ROOT}${location || "mumbai"}/home`;
export const OTP = USER_ROOT + "otp";
export const REGISTERATION = USER_ROOT + "registeration";
export const CATEGORIES = (location) =>
  `${USER_ROOT}${location || "mumbai"}/categories`;
export const PRODUCT_DETAILS = (id) => `${USER_ROOT}productdetails/${id}`;
export const MY_CART = "/mycart";
export const DELIVERY_ADDRESS = (id) => `${USER_ROOT}deliveryaddress/${id}`;
export const ORDER_PAYMENT = (id) => `${USER_ROOT}order_payment/${id}`;
export const MYORDERS = USER_ROOT + "myorder";
export const MY_LISTING = USER_ROOT + "mylisting";
export const FAQS = USER_ROOT + "Faqs";
export const CONTACT_US = USER_ROOT + "contactus";
export const LISTING_HOME = USER_ROOT + "listing_home";
export const MY_PROFILE = USER_ROOT + "myprofile";
export const BLOG = USER_ROOT + "blog";
export const POLICY = USER_ROOT + "policy";
export const TERMANDCONDITION = USER_ROOT + "termandcondition"
export const READ_BLOG = (id) => `${BLOG}/read/${id}`;
export const MY_CREDIT = USER_ROOT + "mycredit";
export const ABOUT_US = USER_ROOT + "aboutus";
export const SUBMIT_BLOG = USER_ROOT + "submitblog";
export const FOR_BUSINESS = USER_ROOT + "forbusiness";
export const FOR_EVENT = USER_ROOT + "forevent";
export const USER_LOGOUT = USER_ROOT + "logout";
export const USER_SEARCH_RESULTS = (location, query) =>
  `${USER_ROOT}${location}/search/${query}`;
//
{
  /* <Adminlongtermmoreinfo />; */
}
// ADMIN LINKS
export const ADMIN_ROOT = "/admin";
export const ADMIN_LOGIN = ADMIN_ROOT + "/login";
export const ADMIN_OTP = ADMIN_ROOT + "/otp";
export const ADMIN_DASHBOARD = ADMIN_ROOT + "/dashboard";
export const ADMIN_ORDER = ADMIN_ROOT + "/order";
export const ADMIN_ORDER_NEW_ORDER = ADMIN_ORDER + "/new";
export const ADMIN_ORDER_REUPLOAD_ORDER = ADMIN_ORDER + "/reupload";
export const ADMIN_ORDER_EXPIRED_ORDER = ADMIN_ORDER + "/expired";
export const ADMIN_ORDER_COMPLETED_ORDER = ADMIN_ORDER + "/completed";
export const ADMIN_ORDER_NEW_ORDER_MORE_INFO = (id) =>
  `${ADMIN_ORDER_NEW_ORDER}/moreinfo/${id}`;
export const ADMIN_ORDER_NEW_ORDER_EDIT_INFO = (id) =>
  `${ADMIN_ORDER_NEW_ORDER}/editinfo/${id}`;
export const ADMIN_INV = ADMIN_ROOT + "/inventory";
export const ADMIN_ORDER_LONG = ADMIN_ORDER + "/long_term";
export const ADMIN_ORDER_LONG_MORE_INFO = (id) =>
  `${ADMIN_ORDER_LONG}/moreinfo/${id}`;
export const ADMIN_ORDER_SHORT = ADMIN_ORDER + "/short_term";
export const ADMIN_ORDER_SHORT_MORE_INFO = (id) =>
  `${ADMIN_ORDER_SHORT}/moreinfo/${id}`;
export const ADMIN_ORDER_DAILY_LOG = ADMIN_ORDER + "/daily_logistic";
export const ADMIN_ORDER_DAILY_LOG_DELI_MORE_INFO = (id) =>
  `${ADMIN_ORDER_DAILY_LOG}/delivery/moreinfo/${id}`;
export const ADMIN_ORDER_DAILY_LOG_PICK_MORE_INFO = (id) =>
  `${ADMIN_ORDER_DAILY_LOG}/pickup/moreinfo/${id}`;
export const ADMIN_ORDER_USER_PAY = ADMIN_ORDER + "/userpayment";
export const ADMIN_ORDER_USER_PAY_MORE_INFO = (id) =>
  `${ADMIN_ORDER}/userpayment/moreinfo/${id}`;
export const ADMIN_ORDER_CANCEL_REQ = ADMIN_ORDER + "/cancellation_req";
export const ADMIN_ORDER_CANCEL_REQ_MORE_INFO = (id) =>
  `${ADMIN_ORDER_CANCEL_REQ}/moreinfo/${id}`;
export const ADMIN_ORDER_DISAPPROVED_ORDER = ADMIN_ORDER + "/disapproved";
export const ADMIN_ORDER_DISAPPROVED_ORDER_MORE_INFO = (id) =>
  `${ADMIN_ORDER_DISAPPROVED_ORDER}/moreinfo/${id}`;
export const ADMIN_ORDER_EXT_REQ = ADMIN_ORDER + "/extension_req";
export const ADMIN_ORDER_EXT_MORE_INFO = (id) =>
  `${ADMIN_ORDER_EXT_REQ}/moreinfo/${id}`;
//
export const ADMIN_INV_LIST_REQ = ADMIN_INV + "/listing_req";
export const ADMIN_INV_LIST_REQ_MORE_INFO = (id) =>
  `${ADMIN_INV_LIST_REQ}/moreinfo/${id}`;
export const ADMIN_INV_LEND_CANCEL = ADMIN_INV + "/lending_cancellation";
export const ADMIN_INV_LEND_CANCEL_MORE_INFO = (id) =>
  `${ADMIN_INV_LEND_CANCEL}/moreinfo/${id}`;
export const ADMIN_INV_PROD_AVAIL = ADMIN_INV + "/product_availability";
export const ADMIN_INV_PROD_AVAIL_MORE_INFO = (id) =>
  `${ADMIN_INV_PROD_AVAIL}/moreinfo/${id}`;
export const ADMIN_INV_MAINTAIN_LIST = ADMIN_INV + "/maintenance_list";
export const ADMIN_INV_MAINTAIN_LIST_MORE_INFO = (id) =>
  `${ADMIN_INV_MAINTAIN_LIST}/moreinfo/${id}`;
export const ADMIN_INV_CATEGORY = ADMIN_INV + "/category";
export const ADMIN_INV_CATEGORY_PROD_MORE_INFO = (id) =>
  `${ADMIN_INV_CATEGORY}/moreinfo/${id}`;
//
export const ADMIN_USERS = ADMIN_ROOT + "/user";
export const ADMIN_USERS_MORE_INFO = (id) => `${ADMIN_USERS}/moreinfo/${id}`;
//
export const ADMIN_FRONTEND = ADMIN_ROOT + "/frontend";
export const ADMIN_FRONTEND_VIEW_PACKAGE = ADMIN_FRONTEND + "/view_package";
export const ADMIN_FRONTEND_VIEW_PACKAGE_CATEGORY =
  ADMIN_FRONTEND_VIEW_PACKAGE + "/category";
export const ADMIN_FRONTEND_PACKAGE_DETAILS = (id) =>
  `${ADMIN_FRONTEND_VIEW_PACKAGE}/package_details/${id}`;
export const ADMIN_FRONTEND_TRENDING_NOW = ADMIN_FRONTEND + "/trending_now";
export const ADMIN_FRONTEND_ADDONS = ADMIN_FRONTEND + "/addons";
export const ADMIN_FRONTEND_CREATE_ADDONS = ADMIN_FRONTEND_ADDONS + "/create";
export const ADMIN_FRONTEND_EDIT_ADDONS = (id) =>
  `${ADMIN_FRONTEND_ADDONS}/edit/${id}`;
export const ADMIN_FRONTEND_FAQS = ADMIN_FRONTEND + "/faq";
export const ADMIN_FRONTEND_FAQS_DETAILS = (id) =>
  `${ADMIN_FRONTEND_FAQS}/details/${id}`;
export const ADMIN_FRONTEND_COUPONS = ADMIN_FRONTEND + "/coupon";
export const ADMIN_FRONTEND_BANNER = ADMIN_FRONTEND + "/banner";
export const ADMIN_FRONTEND_TRENDING_TAG = ADMIN_FRONTEND + "/trending_tag";
export const ADMIN_FRONTEND_BLOGS = ADMIN_ROOT + "/blog";
export const ADMIN_FRONTEND_CREATE_BLOG = ADMIN_FRONTEND_BLOGS + "/create";
export const ADMIN_FRONTEND_EDIT_BLOG = ADMIN_FRONTEND_BLOGS + "/edit";
export const ADMIN_FRONTEND_BLOG_REQ = ADMIN_FRONTEND_BLOGS + "/request";
export const ADMIN_FRONTEND_BLOG_REQ_READ = (id) =>
  `${ADMIN_FRONTEND_BLOG_REQ}/read/${id}`;
export const ADMIN_FRONTEND_POST_BLOG = (id) =>
  `${ADMIN_FRONTEND_BLOG_REQ}/post/${id}`;
//
export const ADMIN_PRODUCT_SUP = ADMIN_ROOT + "/product_support";
export const ADMIN_PRODUCT_SUP_MORE_INFO = (id, prodId) =>
  `${ADMIN_ROOT}/product_support/moreinfo/${id}/${prodId}`;
//
export const ADMIN_CONTACT_US = ADMIN_ROOT + "/contact_us";
export const ADMIN_CONTACT_US_SEARCH_SAVE_REQ =
  ADMIN_CONTACT_US + "/search_saved_req";
export const ADMIN_CONTACT_US_SEARCH_REQ = ADMIN_CONTACT_US + "/search_req";
export const ADMIN_CONTACT_US_A = ADMIN_CONTACT_US + "/a";
export const ADMIN_CONTACT_US_WAIT_LIST = ADMIN_CONTACT_US + "/waitlist";
export const ADMIN_CONTACT_US_EVENT_REQ = ADMIN_CONTACT_US + "/event_req";
//
export const ADMIN_DATA = ADMIN_ROOT + "/data";
export const ADMIN_DATA_DOWNLOAD = ADMIN_DATA + "/download";
export const ADMIN_DATA_DOWNLOAD_NEW = (data) =>
  `${ADMIN_DATA}/download_latest/${data}`;

//
export const ADMIN_ACCESS = ADMIN_ROOT + "/access";

const userLinks = [
  USER_ROOT,
  LOGIN,
  HOME("test"),
  OTP,
  REGISTERATION,
  CATEGORIES("test"),
  PRODUCT_DETAILS("test"),
  MY_CART,
  DELIVERY_ADDRESS("test"),
  ORDER_PAYMENT("test"),
  MYORDERS,
  MY_LISTING,
  FAQS,
  CONTACT_US,
  MY_PROFILE,
  BLOG,
  POLICY,
  READ_BLOG,
  MY_CREDIT,
  ABOUT_US,
  SUBMIT_BLOG,
  FOR_BUSINESS,
  USER_LOGOUT,
];
const adminLinks = [
  ADMIN_ROOT,
  ADMIN_LOGIN,
  ADMIN_OTP,
  ADMIN_DASHBOARD,
  ADMIN_ORDER,
  ADMIN_ORDER_NEW_ORDER,
  ADMIN_ORDER_NEW_ORDER_MORE_INFO("test"),
  ADMIN_ORDER_NEW_ORDER_EDIT_INFO("test"),
  ADMIN_INV,
  ADMIN_ORDER_LONG,
  ADMIN_ORDER_LONG_MORE_INFO("test"),
  ADMIN_ORDER_SHORT,
  ADMIN_ORDER_SHORT_MORE_INFO("test"),
  ADMIN_ORDER_DAILY_LOG,
  ADMIN_ORDER_DAILY_LOG_DELI_MORE_INFO("test"),
  ADMIN_ORDER_DAILY_LOG_PICK_MORE_INFO("test"),
  ADMIN_ORDER_USER_PAY,
  ADMIN_ORDER_USER_PAY_MORE_INFO("test"),
  ADMIN_ORDER_CANCEL_REQ,
  ADMIN_ORDER_CANCEL_REQ_MORE_INFO("test"),
  ADMIN_ORDER_DISAPPROVED_ORDER,
  ADMIN_ORDER_DISAPPROVED_ORDER_MORE_INFO("test"),
  ADMIN_ORDER_EXT_REQ,
  ADMIN_ORDER_EXT_MORE_INFO("test"),
  ADMIN_INV_LIST_REQ,
  ADMIN_INV_LIST_REQ_MORE_INFO("test"),
  ADMIN_INV_LEND_CANCEL,
  ADMIN_INV_LEND_CANCEL_MORE_INFO("test"),
  ADMIN_INV_PROD_AVAIL,
  ADMIN_INV_PROD_AVAIL_MORE_INFO("test"),
  ADMIN_INV_MAINTAIN_LIST,
  ADMIN_INV_MAINTAIN_LIST_MORE_INFO("test"),
  ADMIN_INV_CATEGORY,
  ADMIN_INV_CATEGORY_PROD_MORE_INFO("test"),
  ADMIN_USERS,
  ADMIN_USERS_MORE_INFO("test"),
  ADMIN_FRONTEND,
  ADMIN_FRONTEND_VIEW_PACKAGE,
  ADMIN_FRONTEND_VIEW_PACKAGE_CATEGORY,
  ADMIN_FRONTEND_PACKAGE_DETAILS("test"),
  ADMIN_FRONTEND_TRENDING_NOW,
  ADMIN_FRONTEND_ADDONS,
  ADMIN_FRONTEND_CREATE_ADDONS,
  ADMIN_FRONTEND_EDIT_ADDONS("test"),
  ADMIN_FRONTEND_FAQS,
  ADMIN_FRONTEND_FAQS_DETAILS("test"),
  ADMIN_FRONTEND_COUPONS,
  ADMIN_FRONTEND_BLOGS,
  ADMIN_FRONTEND_CREATE_BLOG,
  ADMIN_FRONTEND_EDIT_BLOG,
  ADMIN_FRONTEND_BLOG_REQ,
  ADMIN_FRONTEND_BLOG_REQ_READ("test"),
  ADMIN_FRONTEND_POST_BLOG("test"),
  ADMIN_PRODUCT_SUP,
  ADMIN_PRODUCT_SUP_MORE_INFO("test"),
  ADMIN_CONTACT_US,
  ADMIN_CONTACT_US_SEARCH_SAVE_REQ,
  ADMIN_CONTACT_US_SEARCH_REQ,
  ADMIN_CONTACT_US_A,
  ADMIN_CONTACT_US_WAIT_LIST,
  ADMIN_CONTACT_US_EVENT_REQ,
  ADMIN_DATA,
  ADMIN_DATA_DOWNLOAD,
  ADMIN_DATA_DOWNLOAD_NEW,
  ADMIN_ACCESS,
];
// for (const val of userLinks) {

// }
