import "./paymentbutton.css";

import {
  CANCELLED,
  DISAPPROVED,
  RAZORPAY_ID,
  SUCCESS,
} from "../../constants/constants";
import { FailureAlert, LoadingPage, OrderAccepted } from "..";
import React, { useEffect, useState } from "react";
import {
  clearErrorSlice,
  createPaymentV1Thunk,
  fetchOrderForPaymentThunk,
  fetchRazorpayPaymentThunk,
  getPriceDataByOrderThunk,
  payUsingCreditThunkV1,
  verifyOrderV1Thunk,
} from "../../redux/features/paymentSlice/paymentSlice";
import { useDispatch, useSelector } from "react-redux";

import PaymentDetails from "../PaymentDetails/PaymentDetails";
import PopUp from "../PopUp/PopUp";
import { PriceInfo } from "../../assets";
import { getPersonalCreditInfoThunk } from "../../redux/features/loginSlice/loginSlice";
import { getSubTotal } from "../../utils/displayUtils";
import { setCredit } from "../../redux/features/orderSlice/orderSlice";
import { usePopper } from "react-popper";

export const PaymentButtonV1 = ({ orderId, handleCloseReminder, reminder }) => {
  // console.log(orderId);
  const dispatch = useDispatch();

  const [fetchedOrder, setFetchedOrder] = useState({});
  const [creditInfo, setCreditInfo] = useState({});
  const [updateDone, setUpdateDone] = useState(false);
  const [credits, setCredits] = useState("");
  const [creditPopUp, setCreditPopUp] = useState(false);
  const [refElm, setRefElm] = useState();
  const [popElm, setPopElm] = useState();
  const [paymentDetails, setPaymentDetails] = useState(false);
  // const [showCredit, setShowCredit] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [isCredit, setIsCredit] = useState(false);
  const popUpParamsStartEnd = {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };
  const popper = usePopper(refElm, popElm, popUpParamsStartEnd);

  // error slice --------------------------------------------------------------
  const loading = useSelector((state) => state.rootReducer.payments.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.payments.isError
  );
  const error = useSelector((state) => state.rootReducer.payments.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  // error slice --------------------------------------------------------------

  // const handleClickCredit = () => {
  //   dispatch(payUsingCreditThunkV1({ orderId: orderId }))
  //     .unwrap()
  //     .then((data) => {
  //       if (data.type == SUCCESS) {
  //         setUpdateDone((state) => !state);
  //         setShowCredit(false);
  //         setIsCredit(true);
  //         setCreditPopUp(true);
  //       }
  //     });
  // };

  // const verifyPayment = (response, extensionNumber, isSubs, isExtended) => {
  const verifyPayment = (response, extensionNumber, extensionSubNumber) => {
    dispatch(
      verifyOrderV1Thunk({
        response,
        orderId: fetchedOrder?._id,
        customId: fetchedOrder?.customId,
        extensionNumber: extensionNumber,
        extensionSubNumber: extensionSubNumber,
        // use isSubs parameter if razorpay subscription model in use
        // isSubs: isSubs,
      })
    ).then((data) => {
      if (data.payload.type === SUCCESS) {
        setCredits(data?.payload?.data?.credits);
        setUpdateDone((state) => !state);
        setCreditPopUp(true);
      }
    });
  };


  const razorpayPopup = (data) => { 
    let options = {
      key: RAZORPAY_ID,
      currency: "INR",
      name: data.userInfo.company,
      description: data.userInfo.description,
      image:
        "https://rentkar-testv1.s3.ap-south-1.amazonaws.com/assets/logo.jpeg",
      handler: function (response) {
        verifyPayment(
          response,
          data.extensionNumber,
          data.extensionSubNumber
          // passs isSubs argument if razorpay subscription model in use
          // data.isSubs,
        );
      },
      // here
      prefill: {
        name: data.userInfo.name,
        email: data.userInfo.email,
        contact: data.userInfo.phone,
      },
      theme: {
        color: "#0E9BE1",
        backdrop_color: "transparent",
      },
    };
    // if (data?.isSubs) {
    //   options = { ...options, subscription_id: data.sub.id };
    // } else {
    //   options = { ...options, order_id: data.order.id };
    // }
    options = { ...options, order_id: data.order.id };
    const rzp = window.Razorpay(options);
    return rzp;
  };

  const handleClick = () => {
    dispatch(createPaymentV1Thunk({ orderId: orderId }))
      .unwrap()
      .then((data) => {
        if (data.type === SUCCESS) {
          if (reminder) {
            handleCloseReminder(orderId);
            razorpayPopup(data.data).open();
          } else {
            razorpayPopup(data.data).open();
          }
        }
      });
  };

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderForPaymentThunk({ orderId: orderId }))
        .unwrap()
        .then((data) => {
          if (data.type === SUCCESS) {
            setFetchedOrder(data.data);
            // console.log(data);
            let paidOrders = data.data?.order?.orders?.filter(
              (elm) => elm?.razorPayDetails?.paymentId?.trim() !== ""
            );
            dispatch(fetchRazorpayPaymentThunk({ paidOrders: paidOrders }));
          }
        });
    }
    dispatch(getPersonalCreditInfoThunk())
      .unwrap()
      .then((data) => {
        if (data.type === SUCCESS) {
          setCreditInfo(data.data);
        }
      });
  }, [orderId, updateDone, handleCloseReminder]);

  useEffect(() => {
    if (fetchedOrder?._id) {
      dispatch(getPriceDataByOrderThunk({ orderId: fetchedOrder?._id }))
        .unwrap()
        .then((data) => {
          if (data.type === SUCCESS) {
            setPriceDetails(data.data);
          }
        });
    }
  }, [fetchedOrder?._id]);

  const credit = useSelector((state) => state.rootReducer.orderInfo.useCredit);

  useEffect(() => {
    if (credit) dispatch(setCredit());
  }, []);

  const totalDetail =
    getSubTotal(priceDetails?.numDays, priceDetails?.price?.addedGst) +
    priceDetails?.price?.delivery?.lineTotal;

  return (
    <div>
      <PopUp callback={setCreditPopUp} display={creditPopUp} center={true}>
        <OrderAccepted
          credits={credits}
          isCredit={isCredit}
          callback={setCreditPopUp}
        />
      </PopUp>
      {/* <PopUp display={showCredit} center={true} callback={setShowCredit}>
        <div className="payment-show-credit-base-container">
          <p>
            You are about to use <span>{totalDetail}</span> credits
          </p>
          <p>
            You have <span>{creditInfo?.credits}</span> credits
          </p>
          {creditInfo?.credits < totalDetail && (
            <p id="warning-text">Insufficient Credits</p>
          )}

          <div className="credit-option-button">
            <button
              onClick={() => {
                handleClickCredit();
              }}
              disabled={creditInfo?.credits < totalDetail}
            >
              Proceed
            </button>
            <button style={{ background: "#B8352C" }}>Cancel</button>
          </div>
        </div>
      </PopUp> */}
      {loading && <LoadingPage />}
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {fetchedOrder?.orders?.slice(-1)?.[0]?.razorPayDetails.paymentId !==
        "" && (
        <div className="paymentv1-button-container">
          <button
            disabled
            onClick={() => {
              handleClick();
            }}
          >
            Paid
          </button>
          <img
            ref={setRefElm}
            src={PriceInfo}
            alt=""
            onClick={() => {
              setPaymentDetails(!paymentDetails);
            }}
          />
          <PaymentDetails
            data={{
              order: fetchedOrder,
            }}
            zIndex={14}
            display={paymentDetails}
            setDisplay={setPaymentDetails}
            popper={popper}
            setPopper={setPopElm}
          />
        </div>
      )}
      {fetchedOrder?._id &&
        !fetchedOrder?.orders?.slice(-1)?.[0]?.paymentInitiated && (
          <div className="paymentv1-button-container">
            <button
              disabled={
                [DISAPPROVED, CANCELLED].includes(
                  fetchedOrder?.orderStatus?.status
                )
                  ? true
                  : false
              }
              onClick={() => {
                handleClick();
              }}
            >
              Pay Now
            </button>
            {/* <button
              onClick={() => {
                setShowCredit(true);
              }}
            >
              Use Credits
            </button> */}
            <img
              ref={setRefElm}
              src={PriceInfo}
              alt=""
              onClick={() => {
                setPaymentDetails(!paymentDetails);
              }}
            />
            {/* {console.log({ fetchedOrder })} */}
            <PaymentDetails
              data={{
                order: fetchedOrder,
              }}
              zIndex={14}
              display={paymentDetails}
              setDisplay={setPaymentDetails}
              popper={popper}
              setPopper={setPopElm}
            />
          </div>
        )}
    </div>
  );
};

// {
//   !fetchedOrder?._id && (
//     <div className="paymentv1-button-container">
//       <button disabled>Pay Now</button>
//       {/* <button disabled>Use Credits</button> */}
//     </div>
//   );
// }
