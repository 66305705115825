// import "./otp.css";

import "./otpnew.css";

import * as Yup from "yup";

import {
  Cancellation,
  CardCross,
  Frame,
  LoginImg,
  alertCancelSvg,
  headfoneCross,
} from "../../assets";
import {
  LOGGEDIN,
  OTP_FAILURE,
  REGISTERED,
  VALIDATION_ERROR,
} from "../../constants/constants";
import { LOGIN, REGISTERATION } from "../../constants/links";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  clearErrorSlice,
  loginOrRegister,
  resendOtpThunk,
  setOtpExpiry,
  userSetLogin,
  verifyOtp,
} from "../../redux/features/loginSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";

import { FailureAlert } from "../../components";
import Navbar from "../../components/Navbar/navbar";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

// images

const Otp = ({ callback, loginClose, addToCartCallback }) => {
  const phone = useSelector((state) => state.rootReducer.userInfo.data.phone);
  const OTPExpiry = useSelector(
    (state) => state.rootReducer.userInfo.data.OTPExpiry
  );
  // const userType = useSelector((state) => state.rootReducer.userInfo.userType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resendOtpTimer, setResendOtpTimer] = useState(-1);
  const intervalRef = useRef(null);
  // const [error, setError] = useState({});
  // const [customAlert, setCustomAlert] = useState(false);
  // const setNewError = (err) => {

  //   setError(err);
  //   setCustomAlert(true);
  // };
  //  to start otp timer
  const StartResendOtpTimer = (time) => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setResendOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
  };
  //  to stop otp timer
  const stopTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}.${
      (seconds / 100).toFixed(2).split(".")[1]
    }`;
  };

  useEffect(() => {
    // console.log(OTPExpiry, "changed");
    if (OTPExpiry) {
      setResendOtpTimer(OTPExpiry);
    }
  }, [OTPExpiry]);
  useEffect(() => {
    // console.log(resendOtpTimer, "resendOtpTimer chaged");
    if (resendOtpTimer) {
      StartResendOtpTimer();
    }
  }, [resendOtpTimer]);
  // for watching resendOtpTimer and stoping resendOtpTimer after given time
  useEffect(() => {
    if (resendOtpTimer == 0) {
      stopTimer();
      dispatch(setOtpExpiry());
    }
  }, [resendOtpTimer]);
  //resend timer end------------------------------------------------------------------------

  // Constant Error
  const customAlert = useSelector(
    (state) => state.rootReducer.userInfo.isError
  );
  const error = useSelector((state) => state.rootReducer.userInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  // ------------ Error end
  const otpForm = useFormik({
    initialValues: {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
    },
    validationSchema: Yup.object({
      input1: Yup.number().max(9).min(0).required("Invalid OTP"),
      input2: Yup.number().max(9).min(0).required("Invalid OTP"),
      input3: Yup.number().max(9).min(0).required("Invalid OTP"),
      input4: Yup.number().max(9).min(0).required("Invalid OTP"),
      input5: Yup.number().max(9).min(0).required("Invalid OTP"),
      input6: Yup.number().max(9).min(0).required("Invalid OTP"),
    }),
    onSubmit: (values) => {
      const otp = Object.values(values).join("");
      // console.log(phone, otp);
      dispatch(verifyOtp({ otp: otp, phone: phone })).then((data) => {
        if (data.payload.type == LOGGEDIN) {
          // changes made in order to facilitate the navigation to users last location
          // based on database
          const location = data.payload.data?.lastLocation?.toLowerCase();
          // ======================old logic to login over different pages
          // navigate(`/${location}/home`);
          // ======================logic to login over the popups through the props callaback
          loginClose();
          callback();
          // addToCartCallback();
          // ======================
          // navigate("/");
        }
        if (data.payload.type == REGISTERED) {
          loginClose();
          callback();
          navigate(`${REGISTERATION}?refer_id=${searchParams.get("refer_id")}`);
        }
      });
    },
  });

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);
  const input6 = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    // input1.current.focus();
  }, []);

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      <div className="otp-outer-container-new">
        <div className="otp-inner-container-new">
          <div className="otp-image-container-left">
            <img src={"/Frame.png"} alt="image" id="upper-image" />

            {/* <img src={Frame} alt="Frame" id="lower-image" /> */}
          </div>
          <div className="part-div-component" />
          <div className="otp-image-container-right">
            <div className="otp_content_wrapper">
              <div className="upper-div">
                <h1 className="right-container-title">Enter OTP</h1>

                {phone ? (
                  <p className="change-number">
                    Enter OTP code sent to{" "}
                    <span>+91 {phone || "123456789"}</span>
                  </p>
                ) : (
                  <p className="change-number">
                    No numbers found{" "}
                    <Link
                      to={LOGIN}
                      style={{ textDecoration: "none", color: "#0E9BE1" }}
                    >
                      Add Number
                    </Link>
                  </p>
                )}
                <p
                  onClick={() => callback()}
                  style={{ textDecoration: "none", color: "#0E9BE1" }}
                  className="change-number"
                >
                  {/* <Link
                  to={`${LOGIN}?refer_id=${searchParams.get("refer_id")}`}
                  style={{ textDecoration: "none", color: "#0E9BE1" }}
                > */}
                  Change Number
                  {/* </Link> */}
                </p>
              </div>

              <div className="">
                <div className="error-container">
                  <div className="otp-error-label">
                    <label>
                      {Object.values(otpForm.errors).length !== 0
                        ? Object.values(otpForm.errors)[0]
                        : "\u00A0"}
                    </label>
                  </div>
                  <div className="otpNumberContainer-new">
                    <input
                      name="input1"
                      type="number"
                      value={otpForm.values.input1}
                      autoComplete="off"
                      className="otpInput-new"
                      //tabIndex="1"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 1))
                      }
                      maxLength="1"
                      ref={input1}
                      onBlur={otpForm.handleBlur}
                      onChange={(e) => {
                        otpForm.handleChange(e);
                        if (e.target.value == "") {
                          input1.current.focus();
                        } else {
                          input2.current.focus();
                        }
                      }}
                    />
                    <input
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 1))
                      }
                      name="input2"
                      type="number"
                      value={otpForm.values.input2}
                      autoComplete="off"
                      className="otpInput-new"
                      //tabIndex="2"
                      maxLength="1"
                      ref={input2}
                      onBlur={otpForm.handleBlur}
                      onChange={(e) => {
                        otpForm.handleChange(e);
                        if (e.target.value == "") {
                          input1.current.focus();
                        } else {
                          input3.current.focus();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.target.value == "" && e.key === "Backspace") {
                          input1.current.focus();
                        }
                      }}
                    />
                    <input
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 1))
                      }
                      name="input3"
                      type="number"
                      value={otpForm.values.input3}
                      autoComplete="off"
                      className="otpInput-new"
                      //tabIndex="3"
                      maxLength="1"
                      ref={input3}
                      onBlur={otpForm.handleBlur}
                      onChange={(e) => {
                        otpForm.handleChange(e);
                        if (e.target.value == "") {
                          input2.current.focus();
                        } else {
                          input4.current.focus();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.target.value == "" && e.key === "Backspace") {
                          input2.current.focus();
                        }
                      }}
                    />
                    <input
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 1))
                      }
                      name="input4"
                      type="number"
                      value={otpForm.values.input4}
                      autoComplete="off"
                      className="otpInput-new"
                      //tabIndex="4"
                      maxLength="1"
                      ref={input4}
                      onBlur={otpForm.handleBlur}
                      onChange={(e) => {
                        otpForm.handleChange(e);
                        if (e.target.value == "") {
                          input3.current.focus();
                        } else {
                          input5.current.focus();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.target.value == "" && e.key === "Backspace") {
                          input3.current.focus();
                        }
                      }}
                    />

                    <input
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 1))
                      }
                      name="input5"
                      type="number"
                      value={otpForm.values.input5}
                      autoComplete="off"
                      className="otpInput-new"
                      //tabIndex="5"
                      maxLength="1"
                      ref={input5}
                      onBlur={otpForm.handleBlur}
                      onChange={(e) => {
                        otpForm.handleChange(e);
                        if (e.target.value == "") {
                          input4.current.focus();
                        } else {
                          input6.current.focus();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.target.value == "" && e.key === "Backspace") {
                          input4.current.focus();
                        }
                      }}
                    />

                    <input
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 1);
                        // buttonRef.current.focus();
                      }}
                      name="input6"
                      type="number"
                      value={otpForm.values.input6}
                      autoComplete="off"
                      className="otpInput-new"
                      //tabIndex="6"
                      maxLength="1"
                      ref={input6}
                      onBlur={otpForm.handleBlur}
                      onChange={(e) => {
                        otpForm.handleChange(e);

                        if (e.target.value == "") {
                          input5.current.focus();
                        } else {
                          input6.current.focus();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.target.value == "" && e.key === "Backspace") {
                          input5.current.focus();
                        } else if (e.key == "Enter") {
                          otpForm.handleSubmit();
                        }
                      }}
                    />
                  </div>
                </div>
                {phone && (
                  <p className="change-number">
                    {resendOtpTimer !== 0 && (
                      <span id="resender-otp-timer">
                        {" "}
                        {formatTime(resendOtpTimer)}
                      </span>
                    )}
                    Didn’t recieve the OTP?{" "}
                    {resendOtpTimer == 0 && (
                      <span
                        style={{
                          textDecoration: "none",
                          color: "#0E9BE1",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          dispatch(resendOtpThunk({ phone: phone }))
                        }
                      >
                        Resend OTP
                      </span>
                    )}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="sumbit-button otp"
                ref={buttonRef}
                onClick={otpForm.handleSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
