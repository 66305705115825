import {
    BASE_URL,
    ERROR,
    FULFILLED,
    IDLE,
    REJECTED_ERROR,
    SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";


export const getBusinessRequestQuerySlice = createAsyncThunk(
    "get/admin/business_query/get_types",
    async (data) => {
        try {
            const res = await axios.post(`${BASE_URL}/admin/contactus/get_type`, {
                pageSize: data.pageSize,
                page: data.page,
                sort: data.sort,
                filters: data.filters,
                contactType: data.contactType,
            });
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }
);

export const createBusinessRequestQuerySlice = createAsyncThunk(
    "post/public/business_query/creates",
    async (data) => {
        try {
            const res = await axios.post(`${BASE_URL}/public/contactus/create`, {
                name: data.name,
                email: data.email,
                phone: data.phone,
                message: data.message,
                contactType: data.contactType,
                company: data.company,
                product: data.product,
                location: data.location,
            });
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }
);

export const getPaginatedSearchReqThunk = createAsyncThunk(
    "admin/search_req/get_paginated",
    async (data) => {
        try {
            const res = await axios.post(
                `${BASE_URL}/admin/search_req/get_paginated`,
                data
            );
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }
);

const initialState = {
    loading: false,
    errorData: {
        message: "",
        type: "",
        errors: [],
    },
    data: {
        searchReqs: { total: 0, data: [] },
    },
    updateDone: false,
    isError: false,
    filter: {},
    sort: {},
    status: {
        getBusinessRequestQuerySlice: IDLE,
        createBusinessRequestQuerySlice: IDLE,
        getBusinessRequestQueryThunk: IDLE,
    },
    data: {
        req: {
            total: 0,
            data: [],
        },
    },
};

const businessRequestQuerySlice = createSlice({
    name: "businessRequestQuerySlice",
    initialState: initialState,
    reducers: {
        clearErrorSlice: (state, action) => {
            state.isError = false;
            state.errorData = {};
        },
    },
    extraReducers: (builder) => {
        builder
            // get all users contactus ===============================================
            .addCase(getBusinessRequestQuerySlice.pending, (state, action) => {
                // state.status.getContactUsSlice = LOADING;
                state.loading = true;
            })
            .addCase(getBusinessRequestQuerySlice.fulfilled, (state, { payload }) => {
                switch (payload.type) {
                    case SUCCESS:
                        state.loading = false;
                        state.data.req = payload.data;
                        state.status.getBusinessRequestQuerySlice = FULFILLED;
                        break;
                    default:
                        state.loading = false;
                        state.isError = true;
                        state.errorData = {
                            message: payload.message,
                            type: payload.type,
                            errors: payload.errors,
                        };
                }
            })
            .addCase(getBusinessRequestQuerySlice.rejected, (state, action) => {
                state.loading = false;
                state.errorData = action.payload.error;
                state.status.getBusinessRequestQuerySlice = ERROR;
            })

            // create contactus ===============================================
            .addCase(createBusinessRequestQuerySlice.pending, (state, action) => {
                // state.status.createContactUsSlice = LOADING;
                state.loading = true;
            })
            .addCase(createBusinessRequestQuerySlice.fulfilled, (state, { payload }) => {
                switch (payload.type) {
                    case SUCCESS:
                        state.loading = false;
                        state.updateDone = !state.updateDone;
                        state.status.createBusinessRequestQuerySlice = FULFILLED;
                        break;
                    default:
                        state.loading = false;
                        state.isError = true;
                        state.errorData = {
                            message: payload.message,
                            type: payload.type,
                            errors: payload.errors,
                        };
                }
            })
            .addCase(createBusinessRequestQuerySlice.rejected, (state, action) => {
                state.loading = false;
                state.errorData = action.payload.error;
                state.status.createBusinessRequestQuerySlice = ERROR;
            })
    },
});

export default businessRequestQuerySlice.reducer;
export const { clearErrorSlice } = businessRequestQuerySlice.actions;
