import "./myordercard.css";

import {
  DownArrow,
  Pc,
  PriceInfo,
  TabletDelete,
  TabletEdit,
  Threedots,
} from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import {
  MyorderDocumentReupload,
  MyorderExtendPackage,
  MyorderProductsupport,
  MyordercardDropdown,
} from "..";
import React, { useState } from "react";
import {
  getPackageCustomIdThunk,
  getSingleOrderSlice,
} from "../../redux/features/orderSlice/orderSlice";

import CreditPaymentButton from "../PaymentButton/CreditPaymentButton";
import PaymentButton from "../../components/PaymentButton/PaymentButton";
import { PaymentButtonV1 } from "../PaymentButton/PaymentButtonV1";
import PopUp from "../PopUp/PopUp";
import {
  CANCELLED,
  COMPLETE,
  DISAPPROVED,
  REUPLOAD,
  SUCCESS,
} from "../../constants/constants";
import { dateFormatter, getColorStatus } from "../../utils/displayUtils";
import { getProductDetails } from "../../redux/features/homePageSlice/homePageSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { usePopper } from "react-popper";

const Myordercard = (props) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [isProductsupport, setIsProductsupport] = useState(false);
  const [isPackageExtend, setIsPackageExtend] = useState(false);
  const [isReUploadClicked, setIsReUploadClicked] = useState(false);
  const [refElm, setRefElm] = useState();
  const [popElm, setPopElm] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data = {} } = props;

  const popUpParamsBtmStart = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };

  const popper = usePopper(refElm, popElm, popUpParamsBtmStart);
  useEffect(() => {
    if (isProductsupport || isPackageExtend || isReUploadClicked) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  const getPackageInfo = () => {
    dispatch(getPackageCustomIdThunk(props?.packageId)).then((data) => {
      if (data.payload.type === SUCCESS) {
        navigate(`/productdetails/${data.payload?.data?.customId}`);
      }
    });
  };

  return (
    <div className="n-myorder-card-base-container">
      <div
        className="n-myorder-card-css"
        style={{ marginBottom: !props.class && "2rem" }}
      >
        <div className="n-myorder-card-product-details">
          <img
            src={data?.packageInfo?.image}
            alt="packageimage"
            onClick={() => getPackageInfo()}
          />
          <div className="inner-product-details" style={{ content: "test" }}>
            {data?.packageInfo?.packageName.slice(0, 29)}
            {data?.packageInfo?.packageName.length > 29 ? "..." : ""}
            <div className="order-addon-info">
              <span>Comes with :</span>
              <div>
                {data?.packageInfo?.content?.length > 0 && (
                  <p>. {data?.packageInfo?.content?.[0]?.["name"]}</p>
                )}
                {data?.packageInfo?.content?.length > 1 && (
                  <p>. {data?.packageInfo?.content?.[1]?.["name"]}</p>
                )}
              </div>
            </div>
            <div className="n-myorder-button-container">
              <div>
                <PaymentButtonV1 orderId={data?._id} />
              </div>
              <button
                id="extend-button"
                disabled={
                  [DISAPPROVED, CANCELLED, COMPLETE].includes(
                    data?.orderStatus?.status
                  )
                    ? true
                    : false
                }
                onClick={() => setIsPackageExtend(true)}
              ></button>

              {/* <span className="tooltip">
                    Can't extend order once order is completed
                  </span> */}

              {isPackageExtend && (
                <MyorderExtendPackage
                  onclick={() => setIsPackageExtend(!isPackageExtend)}
                  orderId={data?._id}
                  endDate={data?.endDate}
                  pricing={data?.orders[data?.orders?.length - 1]?.price}
                  packageId={data?.packageInfo?.packageId}
                  paidAddons={data?.packageInfo?.paidAddons}
                  packageName={data?.packageInfo?.packageName}
                  index={props.index}
                />
              )}
            </div>
          </div>
          <div className="option-button-container">
            <svg
              id="option-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="39"
              viewBox="0 0 29 39"
              fill="none"
              onClick={() => setIsDropdown(true)}
              alt="option button"
              ref={setRefElm}
            >
              <ellipse
                cx="15.6726"
                cy="12.6388"
                rx="1.801"
                ry="1.80556"
                fill="white"
              />
              <ellipse
                cx="15.6726"
                cy="19.1388"
                rx="1.801"
                ry="1.80556"
                fill="white"
              />
              <ellipse
                cx="15.6726"
                cy="25.6388"
                rx="1.801"
                ry="1.80556"
                fill="white"
              />
            </svg>
          </div>
          {/* options dropdown */}
          <PopUp
            uniqueId={"myOrderDropDown"}
            styles={{ background: `rgba(0,0,0,0.3)` }}
            zIndex={10}
            display={isDropdown}
            callback={setIsDropdown}
          >
            <MyordercardDropdown
              status={props}
              popper={popper}
              setPopElm={setPopElm}
              orderId={data._id}
              userName={data?.userInfo?.userName}
              setStatus={props.setStatus}
            />
          </PopUp>
        </div>
        <div className="n-myorder-card-product-status">
          <p id="n-order-card-start-date">
            Start date <span>{dateFormatter(data?.startDate)}</span>
          </p>
          <p id="n-order-card-end-date">
            End date <span>{dateFormatter(data?.endDate)}</span>
          </p>
          <p id="n-order-card-status">
            Status{" "}
            <span id="n-my-order-status">
              {/* {console.log("data: ", data?.orderStatus?.status)} */}
              {
                // data.userInfo?.address?.document?.status === "REJECTED" ?
                data?.orderStatus?.status === REUPLOAD ? (
                  <>
                    <button
                      id="reupload-doc-button"
                      onClick={() => setIsReUploadClicked(true)}
                    >
                      REUPLOAD
                    </button>
                    <div id="reupload-docs-container">
                      {isReUploadClicked && (
                        <MyorderDocumentReupload
                          onclick={() =>
                            setIsReUploadClicked(!isReUploadClicked)
                          }
                          orderId={data?._id}
                          endDate={data?.endDate}
                          pricing={data?.orders[data?.orders.length - 1]?.price}
                          packageId={data?.packageInfo?.packageId}
                          paidAddons={data?.packageInfo?.paidAddons}
                          packageName={data?.packageInfo?.packageName}
                          index={props.index}
                          setStatus={props.setStatus}
                          data={data}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <span style={getColorStatus(data?.orderStatus?.status)}>
                    {data?.orderStatus?.status}
                  </span>
                )
              }
            </span>
          </p>
        </div>
      </div>
      {props.class && (
        <div className="deal-extension">
          <p>Deal Breaker:</p>{" "}
          <span>Get 20% Off on extending tennure for 2 Months</span>
        </div>
      )}
    </div>
  );
};

export default Myordercard;
