import React from "react";
import './DealProduct.css';
import { Link } from "react-router-dom";

const DealProduct = ({prodTitle,top,left,navLink}) => {
  return (
    <div className="linkMain" id="linkBorder" style={{top:top,left:left}}>
      <div className="circle"></div>
      <Link to={navLink} className="linkChild"></Link>
      <span>
        <Link to={navLink} className="para">{prodTitle}</Link>
      </span>
    </div>
  );
};

export default DealProduct;
