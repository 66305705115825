import "./adminsidebar.css";

import {
  ADMIN_ACCESS,
  ADMIN_CONTACT_US,
  ADMIN_DASHBOARD,
  ADMIN_DATA,
  ADMIN_FRONTEND,
  ADMIN_FRONTEND_BLOG_REQ,
  ADMIN_INV,
  ADMIN_ORDER,
  ADMIN_PRODUCT_SUP,
  ADMIN_USERS,
} from "../../../constants/links";
import {
  Adminaccess,
  Adminaccesswhite,
  Blogrequest,
  Blogrequestwhite,
  Contactus,
  Contactuswhite,
  Dash,
  Dashwhite,
  Data,
  Datawhite,
  Frontend,
  Frontendwhite,
  HeaderLogo,
  Listedinventory,
  Listedinventorywhite,
  Order,
  Orderwhite,
  Productsupport,
  Productsupportwhite,
  Users,
  Userswhite,
} from "../../../assets";
import React from "react";

import { NavLink } from "react-router-dom";

const Adminsidebar = ({ slider }) => {
  return (
    <>
      <div
        className={
          slider ? "menu-list-container" : "menu-list-container-closed"
        }
      >
        <div className="menu-list-content">
          <div className="menu-list-logo-container">
            <img src={HeaderLogo} alt="headerlogo" />
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_DASHBOARD}>
              <img src={Dashwhite} alt="dashboard" />
              <p>Dashboard</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_ORDER}>
              <img src={Orderwhite} alt="orderwhite" />
              <p>Order</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_INV}>
              <img src={Listedinventorywhite} alt="inventory" />
              <p>Listed Inventory</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_FRONTEND}>
              <img src={Frontendwhite} alt="frontend" />
              <p>Frontend</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_USERS}>
              <img src={Userswhite} alt="users" />
              <p>Users</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_PRODUCT_SUP}>
              <img src={Productsupportwhite} alt="productsupport" />
              <p>Product Support</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_CONTACT_US}>
              <img src={Contactuswhite} alt="contactus" />
              <p>Contact Us</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_DATA}>
              <img src={Datawhite} alt="datawhite" />
              <p>Data</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_FRONTEND_BLOG_REQ}>
              <img src={Blogrequestwhite} alt="blogrequest" />
              <p>Blog Request</p>
            </NavLink>
          </div>

          <div className="menu-list-dashboard-menu">
            <NavLink to={ADMIN_ACCESS}>
              <img src={Adminaccesswhite} alt="admin access" />
              <p>Admin Access</p>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adminsidebar;
