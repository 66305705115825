import React, { useState } from "react";

import "./contactbutton.css";

import {
  InstagramContainer,
  headfoneInstagram,
  PhoneContainer,
  headfoneWhatsapp,
  headfonePhone,
  headfoneCircle,
  headfoneCross,
  Head,
} from "../../assets";

const Contactbutton = () => {
  const [showPopUp, setShowPopUp] = useState(false);

  return (
    <>
      {/* {showPopUp ? (
        <div className="headfoneSocials">
          <div className="headfoneSocialsContent">
            <div className="instagramContainer">
              <img src={InstagramContainer} id="instaContainer" alt="" />
              <a href="https://www.instagram.com/rentkar_app/" target="_blank">
                @rentkar
              </a>
            </div>
            <img src={headfoneInstagram} className="headfoneInstagram" alt="" />
          </div>
          <div className="headfoneSocialsContent">
            <div className="phoneContainer1">
              <img src={PhoneContainer} id="phoneContainer" alt="" />
              <a
                href="https://wa.me/+917900042875/?helpref=uf_share"
                target="_blank"
              >
                +91 7900042875
              </a>
            </div>
            <img src={headfoneWhatsapp} className="headfoneWhatsapp" alt="" />
          </div>
        </div>
      ) : (
        <div className="headPhone">
          <div className="">
            <img
              src={Head}
              alt=""
              className="headImg"
              onClick={(e) => setShowPopUp(!showPopUp)}
            />
          </div>
        </div>
      )} */}

      <div className="social-chat-bot">
        <div className="social-chat-bot-container">
          <img
            src={Head}
            alt=""
            className="social-chat-bot-headphone"
            onClick={(e) => setShowPopUp(!showPopUp)}
          />
          <div
            className={`social-close-btn-container ${
              !showPopUp && "social-close-btn-container-hide"
            }`}
          >
            <img
              src={headfoneCircle}
              alt=""
              className="headphoneCircleImg"
              onClick={(e) => setShowPopUp(!showPopUp)}
            />
            <img
              src={headfoneCross}
              alt=""
              className="social-cross-icon"
              onClick={(e) => setShowPopUp(!showPopUp)}
            />
          </div>

          <div
            className={`headphone1 ${showPopUp ? "headphone1-translate" : ""}`}
          >
            <div className="headphone1-container">
            {showPopUp && (
                <div className="whatsapp-link">
                  <a
                    href="https://wa.me/+917900042875/?helpref=uf_share"
                    target="_blank"
                  >
                    +91 7900042875
                  </a>
                </div>
              )}
              <img src={headfonePhone} className="headphoneicon" alt="" />
            </div>
          </div>

          <div
            className={`headphone1 ${showPopUp ? "headphone2-translate" : ""}`}
          >
            <div className="headphone1-container">
              {showPopUp && (
                <div className="whatsapp-link">
                  <a
                    href="https://wa.me/+917900042875/?helpref=uf_share"
                    target="_blank"
                  >
                    +91 7900042875
                  </a>
                </div>
              )}
              <img src={headfoneWhatsapp} className="headphoneicon" alt="" />
            </div>
          </div>

          <div
            className={`headphone1 ${showPopUp ? "headphone3-translate" : ""}`}
          >
            <div className="headphone1-container">
            {showPopUp && (
                <div className="whatsapp-link">
                  <a href="https://www.instagram.com/rentkar_app/" target="_blank">
                @rentkar
              </a>
                </div>
              )}
              <img src={headfoneInstagram} className="headphoneicon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactbutton;
