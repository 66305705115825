import "./customselectcatogery.css";

import { Downtitle, Uptitle } from "../../../assets";
import React, { useEffect, useState } from "react";
import {
  getAllSuperCategories,
  setSelectedCategory,
} from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { useDispatch, useSelector } from "react-redux";

import Addcatogerypopup from "../Addcatogerypopup/addcatogerypopup";
import Addsubcatogerypopup from "../Addsubcatogerypopup/addsubcatogerypopup";
import PopUp from "../../PopUp/PopUp";
import { SUCCESS } from "../../../constants/constants";

const Customselectcatogery = ({ options, category }) => {
  const [createNewcatogery, setCreatenewcatogery] = useState(false);
  const [isOptionsOpen, setIsOptionOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  // const optionsList = options?.length > 0 ? options : ["SERVER DOWN"];
  // const optionsList = options;
  const [optionsList, setOptionsList] = useState(["Loading categories"]);

  const dispatch = useDispatch();

  const toggleOptions = () => {
    setIsOptionOpen(!isOptionsOpen);
  };

  const setSelectThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case "":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const activeCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.activeCategory
  );

  const formCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.form.formCategory
  );

  useEffect(() => {
    dispatch(getAllSuperCategories()).then((data) => {
      if (data.payload.type == SUCCESS) {
        setOptionsList(data.payload.data.map((elm) => elm.category));
      }
    });
  }, []);

  useEffect(() => {
    if (activeCategory) {
      const activeOptIdx = optionsList.indexOf(activeCategory);
      dispatch(
        setSelectedCategory(
          optionsList[activeOptIdx] ? optionsList[activeOptIdx] : 0
        )
      );
      setSelectedOption(optionsList[activeOptIdx] ? activeOptIdx : 0);
    } else {
      dispatch(setSelectedCategory(optionsList[0]));
    }
  }, [optionsList, activeCategory]);

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOptionsOpen}
        className={isOptionsOpen ? "expanded" : ""}
        onClick={toggleOptions}
        onKeyDown={handleListKeyDown}
        id="admin-cat-dropdown-btn"
      >
        {optionsList[selectedOption]?.[0] +
          optionsList[selectedOption]?.slice(1).toLowerCase()}
        {isOptionsOpen ? (
          <img src={Uptitle} alt="" />
        ) : (
          <img src={Downtitle} alt="" />
        )}
      </button>
      <ul
        className={`options ${isOptionsOpen ? "show" : ""}`}
        role="listbox"
        aria-activedescendant={optionsList[selectedOption]}
        tabIndex={-1}
        id="admin-unordered-cat-list"
        onKeyDown={handleListKeyDown}
      >
        {optionsList.map((option, index) => (
          <li
            key={index}
            id={option}
            role="option"
            aria-selected={selectedOption == index}
            tabIndex={0}
            onKeyDown={handleKeyDown(index)}
            onClick={() => {
              setSelectThenCloseDropdown(index);
              if (category) {
                dispatch(setSelectedCategory(optionsList[index]));
              }
            }}
          >
            {option[0] + option.slice(1).toLowerCase()}
          </li>
        ))}
        <div
          className="admin-req-create-catogery-btn-container"
          onClick={() => {
            setCreatenewcatogery(!createNewcatogery);
          }}
        >
          <p>Create New +</p>
        </div>
        {/* {createNewcatogery && category && ( */}
        <PopUp
          display={createNewcatogery}
          callback={setCreatenewcatogery}
          center={true}
          zIndex={12}
        >
          <>
            <Addcatogerypopup
              onclick={() => setCreatenewcatogery(!createNewcatogery)}
            />
          </>
        </PopUp>
        {/* )} */}

        <PopUp
          display={createNewcatogery}
          callback={setCreatenewcatogery}
          center={true}
          zIndex={12}
        >
          <Addsubcatogerypopup
            onclick={() => setCreatenewcatogery(!createNewcatogery)}
          />
        </PopUp>
      </ul>
    </>
  );
};

export default Customselectcatogery;
