import "./Neworder.css";

import * as Yup from "yup";

import { AdminCreateNewUser, DatePicker, LoadingPage } from "../..";
import {
  Adminsearch,
  Adressproof,
  Calender,
  CopyIcon,
  Dcamera,
  Doctemp,
  Identyproof,
  InfoIcon,
  Leftar,
  PopUpClose,
  Rightar,
  Search,
  SelfPicture,
} from "../../../assets";
import { ErrorMessage, useFormik } from "formik";
import React, { useEffect } from "react";
import {
  clearErrorSlice,
  createAdminOrderSlice,
} from "../../../redux/features/orderSlice/orderSlice";
import { useDispatch, useSelector } from "react-redux";

import Admingstpopup from "../Admingstpopup/admingstpopup";
import FailureAlert from "../../failureAlert/failureAlert";
import { SUCCESS } from "../../../constants/constants";
import { dateFormatter } from "../../../utils/displayUtils";
import { fetchAllCoupons } from "../../../redux/features/adminCouponSlice/FrontendCouponSlice";
import { getAllAddons } from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { getAllLocationAsyncThunk } from "../../../redux/features/locationSlice/locationSlice";
import { getUserByPhoneAndTypeSlice } from "../../../redux/features/loginSlice/loginSlice";
import { useState } from "react";

const Neworderpopup = (props) => {
  const today = new Date();
  const formattedDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const formattedNextDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );

  const [isGst, setIsGst] = useState(false);
  const [Popup2, setPopup2] = useState(false);
  const [Ispriview, setIspriview] = useState(false);
  const [placeorder, setplaceorder] = useState(false);
  const [showStartDatePopup, setShowStartDatePopup] = useState(false);
  const [showEndDatePopup, setShowEndDatePopup] = useState(false);
  const [freeAddonsShowMore, setFreeAddonsShowMore] = useState(false);
  const [paidAddonsShowMore, setPaidAddonsShowMore] = useState(false);
  const [FreeAddonsArray, setFreeAddonsArray] = useState([]);
  const [paidAddonsItems, setPaidAddonsItems] = useState([]);
  const [selfieProof, setSelfieProof] = useState([]);
  const [selfieURL, setSelfieURL] = useState("");
  const [identityProof, setIdentityProof] = useState([]);
  const [identityURL, setIdentityURL] = useState("");
  const [addressProof, setAddressProof] = useState([]);
  const [addressURL, setAddressURL] = useState("");
  const [rentalAgreement, setRentalAgreement] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [startDateValue, setStartDateValue] = useState(formattedDate);
  const [endDateValue, setEndDateValue] = useState(formattedNextDate);
  const [tenure, setTenure] = useState(1);
  const [noOfDays, setNoOfDays] = useState();
  // const [newUser, setNewUser] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  // const [userName, setUserName] = useState();
  const [phone, setPhone] = useState();
  const [alternatePhone, setAlternatePhone] = useState();

  const getNoOfDays = () => {
    return (
      (endDateValue.getTime() - startDateValue.getTime()) / (1000 * 3600 * 24)
    );
  };

  // const setPackageTenure = (tenure) => {
  //   setEndDateValue(startDateValue.getTime() + (1000))
  // }

  const handleSelfieImage = (e) => {
    setSelfieProof(e.target.files[0]);
    const imgFilesArray = Array.from(e.target.files);
    const imagesArray = imgFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelfieURL(imagesArray[0]);
  };

  const handleIdentityImage = (e) => {
    setIdentityProof(e.target.files[0]);
    const imgFilesArray = Array.from(e.target.files);
    const imagesArray = imgFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setIdentityURL(imagesArray[0]);
  };

  const handleAddressImage = (e) => {
    setAddressProof(e.target.files[0]);
    const imgFilesArray = Array.from(e.target.files);
    const imagesArray = imgFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setAddressURL(imagesArray[0]);
  };

  // create Async Thunk
  const dispatch = useDispatch();

  const gstData = useSelector(
    (state) => state.rootReducer.orderInfo.gstDetails
  );

  const allAddons = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allAddons
  );

  const allCoupons = useSelector(
    (state) => state.rootReducer.adminFrontendCoupon.data
  );

  const allLocation = useSelector((state) => state.rootReducer.location.data);

  const updateDone = useSelector(
    (state) => state.rootReducer.orderInfo.updateDone
  );

  // const getFreeAddons = () => {
  //   let freeAddonsArray = Array.from(freeAddons);
  //   const freeAddonsValue = freeAddonsArray.filter((el) => {
  //     return el.price == 0;
  //   });
  //   setFreeAddonsSelect(freeAddonsValue);
  // };

  // const getPaidAddons = () => {
  //   let paidAddonsArray = Array.from(paidAddons);
  //   const paidAddonsValue = paidAddonsArray.filter((el) => {
  //     return el.price > 0;
  //   });
  //   setPaidAddonsSelect(paidAddonsValue);
  // };

  // Constant Error
  const loading = useSelector((state) => state.rootReducer.orderInfo.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.orderInfo.isError
  );
  const error = useSelector((state) => state.rootReducer.orderInfo.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  const couponVal = (e) => {
    setCoupon(JSON.parse(e.target.value));
  };

  const FreeAddonsVal = (e) => {
    setFreeAddonsArray([...FreeAddonsArray, JSON.parse(e.target.value)]);
  };

  const PaidAddonval = (e) => {
    setPaidAddonsItems([...paidAddonsItems, JSON.parse(e.target.value)]);
  };

  const removeAddonsVal = (index) => {
    setFreeAddonsArray(FreeAddonsArray.filter((el, i) => i !== index));
  };

  const removePaidAddonsVal = (index) => {
    setPaidAddonsItems(paidAddonsItems.filter((el, i) => i !== index));
  };

  useEffect(() => {
    setEndDateValue(
      new Date(startDateValue.getTime() + 1000 * 3600 * 24 * tenure)
    );
  }, [startDateValue, tenure]);

  useEffect(() => {
    setNoOfDays(getNoOfDays);
  }, [startDateValue, endDateValue]);

  // useEffect(() => {
  //   dispatch(getUserByPhoneAndTypeSlice(parseInt(phoneNo)));
  // }, [phoneNo]);

  const getUserByPhone = useSelector(
    (state) => state.rootReducer.userInfo.currentUserInfo
  );

  const createOrder = useFormik({
    initialValues: {
      userName: "",
      userId: "",
      packageName: "",
      companyName: "",
      gstNo: "",
      gstAddress: "",
      total: "",
      pincode: "",
      address: "",
      phone: "",
      alternativePhone: "",
      selfieProof: [],
      identityProof: [],
      addressProof: [],
      rentalAgreement: [],
    },
    validationSchema: Yup.object({
      userName: Yup.string()
        .min(1, "userName cannot be empty")
        .max(100, "Must be of 100 characters or less")
        .required("Username is required"),
      userId: Yup.string()
        .max(50, "Must be of 50 characters or less")
        .required("UserId is required"),
      packageName: Yup.string()
        .max(100, "Must be of 100 characters or less")
        .required("Package name is required"),
      total: Yup.number()
        .min(0, "total cannot be negative")
        .required("total price is required"),
      pincode: Yup.string()
        .min(6, "pincode must be of 6 length")
        .max(6, "pincode must be of 6 length")
        // .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "Invalid pincode")
        .required("Pincode is required"),
      address: Yup.string()
        .min(20, "Address must be of minimum 20 length")
        .max(300, "Address must be of maximum 300 length")
        .required("Address is required"),
      phone: Yup.number("Invalid phone Number")
        .max(9999999999, "Invalid phone Number")
        .min(1000000000, "Invalid phone Number")
        .required("Phone number is required"),
      alternativePhone: Yup.number("Invalid alternative Number")
        .max(9999999999, "Invalid alternative Number")
        .min(1000000000, "Invalid alternative Number")
        .required("Alternative number is required"),
    }),
    onSubmit: (values) => {
      let createOrderData = new FormData();
      createOrderData.append("userName", getUserByPhone.fullname);
      createOrderData.append("userId", values.userId);
      createOrderData.append("packageName", values.packageName);
      createOrderData.append("tenure", tenure);
      FreeAddonsArray.forEach((elem, idx) => {
        createOrderData.append(`freeAddons[${idx}]`, elem._id);
      });
      paidAddonsItems.forEach((elem, idx) => {
        createOrderData.append(`paidAddons[${idx}]`, elem._id);
      });
      createOrderData.append("startDate", startDateValue);
      createOrderData.append("endDate", endDateValue);
      createOrderData.append("coupon", coupon._id);
      createOrderData.append("companyName", gstData.companyName);
      createOrderData.append("gstNo", gstData.gstNo);
      createOrderData.append("gstAddress", gstData.gstAddress);
      createOrderData.append("total", values.total);
      createOrderData.append("city", values.city);
      createOrderData.append("pincode", values.pincode);
      createOrderData.append("address", values.address);
      createOrderData.append("phone", getUserByPhone.phone);
      createOrderData.append("alternativePhone", getUserByPhone.alternatePhone);
      createOrderData.append("paymentStatus", values.paymentStatus);
      createOrderData.append("rentalAgreement", rentalAgreement);
      createOrderData.append("selfieProof", selfieProof);
      createOrderData.append("identityProof", identityProof);
      createOrderData.append("addressProof", addressProof);

      dispatch(createAdminOrderSlice(createOrderData)).then((data) => {
        if (data.payload.type == SUCCESS) {
          setplaceorder(!placeorder);
        } else {
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getAllAddons());
    dispatch(fetchAllCoupons());
    dispatch(getAllLocationAsyncThunk());
  }, [updateDone]);

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div className="main-popup-container">
        <div className="add-neworder-popup-contianer">
          <div className="add-order-title">
            <p>Add New Order</p>
            <img
              style={{ cursor: "pointer" }}
              src={PopUpClose}
              alt="close"
              onClick={() => props.onchange(!props.isPopup)}
            />
          </div>

          <div className="info-input-container">
            {/* <div className="add-new-admin-order-container">
              {/* <label htmlFor="">
                {createOrder.errors.userName && createOrder.touched.userName
                  ? createOrder.errors.userName
                  : "\u00A0"}
              </label> 
              <label htmlFor="">
                {createOrder.errors.phone && createOrder.touched.phone
                  ? createOrder.errors.phone
                  : "\u00A0"}
              </label>
            </div> */}
            <div className="row-setup">
              <input
                placeholder="User Name"
                type="text"
                name="userName"
                value={phoneNo ? getUserByPhone?.fullname : ""}
                // onChange={() => setUserName(getUserByPhone.fullname)}
              />

              <div className="row-setup-search-user-phone-container">
                <input
                  type="text"
                  name=""
                  placeholder="Mobile Number"
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
                <img
                  src={Adminsearch}
                  alt=""
                  onClick={() => {
                    dispatch(getUserByPhoneAndTypeSlice(parseInt(phoneNo)));
                  }}
                />
              </div>
            </div>
            {/* <p
              className="admin-create-new-user-link"
              onClick={() => setNewUser(!newUser)}
            >
              CREATE NEW USER?
            </p>
            {newUser && (
              <AdminCreateNewUser onclick={() => setNewUser(!newUser)} />
            )} */}

            <div className="add-new-admin-order-container">
              <label htmlFor="">
                {createOrder.errors.packageName &&
                createOrder.touched.packageName
                  ? createOrder.errors.packageName
                  : "\u00A0"}
              </label>

              {/* <label htmlFor="">
                {createOrder.errors.tenurePackage &&
                createOrder.touched.tenurePackage
                  ? createOrder.errors.tenurePackage
                  : "\u00A0"}
              </label> */}
            </div>
            <div className="row-setup" style={{ marginTop: "0rem" }}>
              <input
                placeholder="Package Name"
                type="text"
                name="packageName"
                value={createOrder.values.packageName}
                onChange={createOrder.handleChange}
                onBlur={createOrder.handleBlur}
              />

              <select
                id=""
                name="tenurePackage"
                onChange={(e) => {
                  setTenure(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Tenure Package
                </option>
                <option value="1">1 Day</option>
                <option value="2">2 Day</option>
                <option value="3">3 Day</option>
                <option value="7">1 Week</option>
                <option value="30">1 Month</option>
                <option value="90">3 Month</option>
                <option value="180">6 Month</option>
                <option value="365">1 Year</option>
              </select>
            </div>

            {/* <div className="add-new-admin-order-container">
              <label htmlFor="" style={{ marginLeft: "32%" }}>
                {createOrder.errors.startDate && createOrder.touched.startDate
                  ? createOrder.errors.startDate
                  : "\u00A0"}
              </label>

              <label htmlFor="" style={{ marginRight: "11%" }}>
                {createOrder.errors.endDate && createOrder.touched.endDate
                  ? createOrder.errors.endDate
                  : "\u00A0"}
              </label>
            </div> */}
            <div className="renting-period">
              <p> Renting period :</p>
              <div className="date-select-container">
                <input
                  type="text"
                  placeholder="Start Date"
                  name="startDate"
                  value={`${startDateValue.getDate()}/${
                    startDateValue.getMonth() + 1
                  }/${startDateValue.getFullYear()}`}
                  onChange={(e) => setStartDateValue(e.target.value)}
                />
                <div
                  className="calender-outer"
                  onClick={() => setShowStartDatePopup(!showStartDatePopup)}
                >
                  <img src={Calender} alt="CalenderIcon" />
                </div>
              </div>

              <div className="date-select-container">
                <input
                  type="text"
                  placeholder="End Date"
                  name="endDate"
                  value={dateFormatter(endDateValue)}
                  onChange={(e) => setEndDateValue(e.target.value)}
                />
                <div
                  className="calender-outer"
                  onClick={() => setShowEndDatePopup(!showEndDatePopup)}
                >
                  <img src={Calender} alt="CalenderIcon" />
                </div>
              </div>
            </div>

            {showStartDatePopup && (
              <>
                <div
                  className="product-details-start-date-container"
                  style={{ top: "37.5%", left: "4%" }}
                >
                  <DatePicker
                    date={startDateValue}
                    setDate={setStartDateValue}
                  />
                  <div className="date-picker-bottom-container">
                    <div className="date-picker-left-container">
                      <select name="" id="">
                        <option value="">10:00am - 12:00pm</option>
                        <option value="">12:00pm - 2:00pm</option>
                        <option value="">2:00pm - 4:00pm</option>
                        <option value="">4:00pm - 6:00pm</option>
                        <option value="">6:00pm - 8:00pm</option>
                      </select>
                    </div>
                    <button
                      onClick={() => setShowStartDatePopup(!showStartDatePopup)}
                    >
                      Set Date
                    </button>
                  </div>
                </div>
              </>
            )}

            {showEndDatePopup && (
              <>
                <div
                  className="product-details-end-date-container"
                  style={{ top: "37.5%", right: "12%" }}
                >
                  <DatePicker date={endDateValue} setDate={setEndDateValue} />
                  <div className="date-picker-bottom-container">
                    <div className="date-picker-left-container">
                      <p>No of Days: </p>
                      <div className="date-picker-no-of-days-container">
                        <p>{noOfDays}</p>
                      </div>
                    </div>
                    <button
                      onClick={() => setShowEndDatePopup(!showEndDatePopup)}
                    >
                      Set Date
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="row-setup">
              <select
                id="free-addons-selector"
                name="free-addons"
                onChange={(e) => FreeAddonsVal(e)}
              >
                <option value="" disabled selected>
                  Free Addons
                </option>
                {allAddons
                  .filter((data) => data.price == 0)
                  .map((data) => (
                    <option value={JSON.stringify(data)}>
                      {data.addonName}
                    </option>
                  ))}
              </select>
              <select
                name="paid-addons"
                id="paid-addons-selector"
                onChange={(e) => PaidAddonval(e)}
              >
                <option value="" disabled selected>
                  Paid Addons
                </option>
                {allAddons
                  .filter((data) => data.price > 0)
                  .map((data) => (
                    <option value={JSON.stringify(data)}>
                      {data.addonName}
                    </option>
                  ))}
              </select>
            </div>
            <div className="row-setup-addon-container">
              <div className="addon-holder-container">
                <div className="add-on-container1">
                  <p>
                    Free Addons
                    <div
                      onClick={() => setFreeAddonsShowMore(!freeAddonsShowMore)}
                      className="more-enable"
                    >
                      ...
                    </div>{" "}
                  </p>
                </div>
                {freeAddonsShowMore && (
                  <div className="free-addons-showmore-popup">
                    <div className="free-addons-showmore">
                      <p>Free Adddons</p>
                      <img
                        onClick={() =>
                          setFreeAddonsShowMore(!freeAddonsShowMore)
                        }
                        src={PopUpClose}
                        alt=""
                      />
                    </div>
                    <div className="free-addons-showmore-content">
                      <div className="free-addons-showmore-innner-content">
                        {FreeAddonsArray.map((elem, index) => (
                          <div className="add-on-container1" key={index}>
                            <p>
                              {elem.addonName}{" "}
                              <img
                                src={PopUpClose}
                                alt=""
                                onClick={() => removeAddonsVal(index)}
                              />
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="addon-holder-container">
                <div className="add-on-container1">
                  <p>
                    Paid Addons
                    <div
                      onClick={() => setPaidAddonsShowMore(!paidAddonsShowMore)}
                      className="more-enable"
                    >
                      ...
                    </div>{" "}
                  </p>
                </div>
                {paidAddonsShowMore && (
                  <div className="paid-addons-showmore-popup">
                    <div className="free-addons-showmore">
                      <p>Paid Adddons</p>
                      <img
                        onClick={() =>
                          setPaidAddonsShowMore(!paidAddonsShowMore)
                        }
                        src={PopUpClose}
                        alt="x"
                      />
                    </div>
                    <div className="free-addons-showmore-content">
                      <div className="free-addons-showmore-innner-content">
                        {paidAddonsItems.map((elem, index) => (
                          <div className="add-on-container1" key={index}>
                            <p>
                              {elem.addonName}{" "}
                              <img
                                src={PopUpClose}
                                alt=""
                                onClick={() => removePaidAddonsVal(index)}
                              />
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row-setup">
              <select
                name="coupon"
                id="paid-addons-selector"
                onChange={(e) => couponVal(e)}
              >
                <option value="" disabled selected>
                  Coupons
                </option>
                {allCoupons
                  .filter((data) => data.isActive == true)
                  .map((data) => (
                    <option value={JSON.stringify(data)}>
                      {data.couponName}
                    </option>
                  ))}
              </select>
              <div className="gst-container">
                <button onClick={() => setIsGst(!isGst)} className="gstbutton">
                  GST +
                </button>
                {isGst && <Admingstpopup onclick={() => setIsGst(!isGst)} />}
                <input type="checkbox" name="" id="" />
                <p className="damage-waiver-tittle">Damage Waiver</p>
              </div>
            </div>
            <div className="row-setup-addon-container">
              <div className="addon-holder-container">
                <div className="add-on-container1">
                  <p>
                    {coupon.couponName ? coupon.couponName : "Coupons"}
                    <img src={PopUpClose} alt="" />
                  </p>
                </div>
              </div>
            </div>

            <div className="add-new-admin-order-container">
              <label htmlFor="">
                {createOrder.errors.total && createOrder.touched.total
                  ? createOrder.errors.total
                  : "\u00A0"}
              </label>
            </div>
            <div className="row-setup" style={{ marginTop: "0rem" }}>
              <div className="total-container">
                <input
                  type="text"
                  name="total"
                  placeholder="Enter total price"
                  value={createOrder.values.total}
                  onChange={createOrder.handleChange}
                  onBlur={createOrder.handleBlur}
                />
                <img src={InfoIcon} alt="info" />
              </div>
              <div className="proceed-btn">
                <button
                  className="proceed-btn-in"
                  onClick={() => {
                    setPopup2(!Popup2);
                  }}
                >
                  Proceed
                  <img id="proceed-btm-img" src={Rightar} alt="" />
                </button>
                {Popup2 && (
                  <>
                    <div className="main-popup2-container">
                      <div className="popup2-container">
                        <div className="add-order-title">
                          <p>Add New Order</p>
                          <img
                            style={{ cursor: "pointer" }}
                            src={PopUpClose}
                            alt="close"
                            onClick={() => {
                              setPopup2(!Popup2);
                            }}
                          />
                        </div>
                        <div className="pop2-innerinputcontainer">
                          <div
                            className="add-new-admin-order-container"
                            style={{ paddingLeft: "53%" }}
                          >
                            <label htmlFor="">
                              {createOrder.errors.pincode &&
                              createOrder.touched.pincode
                                ? createOrder.errors.pincode
                                : "\u00A0"}
                            </label>
                          </div>
                          <div
                            className="row-setup"
                            style={{ marginTop: "0rem" }}
                          >
                            <select
                              name="city"
                              value={createOrder.values.city}
                              onChange={createOrder.handleChange}
                              onBlur={createOrder.handleBlur}
                            >
                              <option value="" disabled selected>
                                {" "}
                                Select Location{" "}
                              </option>
                              {allLocation.map((data) => (
                                <option value={data.name}>{data.name}</option>
                              ))}
                            </select>
                            <input
                              type="number"
                              name="pincode"
                              placeholder="Pincode"
                              value={createOrder.values.pincode}
                              onChange={createOrder.handleChange}
                              onBlur={createOrder.handleBlur}
                            />
                          </div>

                          <div className="add-new-admin-order-container">
                            <label htmlFor="">
                              {createOrder.errors.address &&
                              createOrder.touched.address
                                ? createOrder.errors.address
                                : "\u00A0"}
                            </label>
                          </div>
                          <div
                            className="row-setup-sigal-input"
                            style={{ marginTop: "0rem", width: "100%" }}
                          >
                            <select name="" id="">
                              <option value="Select Address" disabled>
                                Select Address
                              </option>
                              {getUserByPhone.address?.map((data) => (
                                <option value={getUserByPhone.address}>
                                  {}
                                </option>
                              ))}
                            </select>
                            {/* <input
                              type="text"
                              name="address"
                              id=""
                              placeholder="Address"
                              value={createOrder.values.address}
                              onChange={createOrder.handleChange}
                              onBlur={createOrder.handleBlur}
                            /> */}
                          </div>

                          {/* <div className="add-new-admin-order-container">
                            <label htmlFor="">
                              {createOrder.errors.phone &&
                              createOrder.touched.phone
                                ? createOrder.errors.phone
                                : "\u00A0"}
                            </label>
                            <label htmlFor="">
                              {createOrder.errors.alternativePhone &&
                              createOrder.touched.alternativePhone
                                ? createOrder.errors.alternativePhone
                                : "\u00A0"}
                            </label>
                          </div> */}
                          <div
                            className="row-setup"
                            // style={{ marginTop: "0rem" }}
                          >
                            <input
                              type="text"
                              name="phone"
                              placeholder="Mobile Number"
                              value={phoneNo ? getUserByPhone?.phone : ""}
                              onChange={() => setPhone(getUserByPhone.phone)}
                            />
                            <input
                              type="text"
                              name="alternativePhone"
                              placeholder="Alternative Mobile Number"
                              value={
                                phoneNo ? getUserByPhone?.alternatePhone : ""
                              }
                              onChange={() =>
                                setAlternatePhone(getUserByPhone.alternatePhone)
                              }
                            />
                          </div>

                          <div className="add-new-admin-order-container">
                            <label htmlFor="">
                              {createOrder.errors.paymentStatus &&
                              createOrder.touched.paymentStatus
                                ? createOrder.errors.paymentStatus
                                : "\u00A0"}
                            </label>
                          </div>
                          <div
                            className="row-setup"
                            style={{ marginTop: "0rem" }}
                          >
                            <select
                              name="paymentStatus"
                              value={createOrder.values.paymentStatus}
                              onChange={createOrder.handleChange}
                              onBlur={createOrder.handleBlur}
                            >
                              <option value="" disabled selected>
                                {" "}
                                Payment Status{" "}
                              </option>
                              <option value="0"> Pending </option>
                              <option value="1"> Cleared </option>
                            </select>
                            <div className="order-detail-inner-div">
                              <p>
                                ₹
                                {createOrder.values.total
                                  ? createOrder.values.total
                                  : "0"}
                                <img src={InfoIcon} alt="info"></img>
                              </p>
                            </div>
                          </div>

                          <div className="add-new-admin-order-container">
                            <label htmlFor="">
                              {createOrder.errors.selfieProof &&
                              createOrder.touched.selfieProof
                                ? createOrder.errors.selfieProof
                                : "\u00A0"}
                            </label>
                            <label htmlFor="">
                              {createOrder.errors.identityProof &&
                              createOrder.touched.identityProof
                                ? createOrder.errors.identityProof
                                : "\u00A0"}
                            </label>
                          </div>
                          <div
                            className="documets-input"
                            style={{ marginTop: "0rem" }}
                          >
                            <div
                              className="doc-outer"
                              style={{ width: "15rem" }}
                            >
                              <label className="camerafull-icon">
                                <input
                                  type="file"
                                  name="selfieProof"
                                  multiple
                                  accept="image/*"
                                  onChange={(e) => handleSelfieImage(e)}
                                />
                                <img src={Dcamera} alt="" />
                              </label>
                              <p>
                                {selfieProof.name
                                  ? selfieProof.name
                                  : "Upload selfie proof"}
                              </p>
                            </div>

                            <div
                              className="doc-outer"
                              style={{ width: "16rem" }}
                            >
                              <label className="camerafull-icon">
                                <input
                                  type="file"
                                  name="identityProof"
                                  multiple
                                  accept="image/*"
                                  onChange={(e) => handleIdentityImage(e)}
                                />
                                <img src={Dcamera} alt="" />
                              </label>
                              <p>
                                {identityProof.name
                                  ? identityProof.name
                                  : "Upload identity proof"}
                              </p>
                            </div>
                          </div>

                          <div className="add-new-admin-order-container">
                            <label htmlFor="">
                              {createOrder.errors.addressProof &&
                              createOrder.touched.addressProof
                                ? createOrder.errors.addressProof
                                : "\u00A0"}
                            </label>
                          </div>
                          <div
                            className="doc-outer"
                            style={{ width: "99%", marginTop: "0rem" }}
                          >
                            <label className="camerafull-icon">
                              <input
                                type="file"
                                name="addressProof"
                                multiple
                                accept="image/*"
                                onChange={(e) => handleAddressImage(e)}
                              />
                              <img src={Dcamera} alt="" />
                            </label>
                            <p>
                              {addressProof.name
                                ? addressProof.name
                                : "Upload address proof"}
                            </p>
                          </div>
                          <div className="popup2-buttons">
                            <div className="popup2-button-innerdiv">
                              <button
                                onClick={() => {
                                  setPopup2(!Popup2);
                                }}
                                className="back-btn"
                              >
                                <img
                                  style={{
                                    width: "0.7rem",
                                    marginRight: "0.2rem",
                                  }}
                                  src={Leftar}
                                  alt="leftar"
                                />
                                Back
                              </button>
                              <button
                                onClick={() => {
                                  setIspriview(!Ispriview);
                                }}
                                className="order-preview-btn"
                              >
                                Order Preview
                              </button>
                              {Ispriview && (
                                <div className="order-priview-main">
                                  <div className="order-periview-container">
                                    <div className="add-order-title">
                                      <h5>Order Preview</h5>
                                      <img
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        src={PopUpClose}
                                        alt=""
                                        onClick={() => {
                                          setIspriview(!Ispriview);
                                        }}
                                      />
                                    </div>
                                    <div className="orderpriview-mid-content">
                                      <div className="inner-scroller">
                                        <div className="scroll-content">
                                          <div className="order-priview-title">
                                            <p className="order-head-title">
                                              Package
                                            </p>
                                          </div>
                                          <p className="order-detail-inner-div-title">
                                            {createOrder.values.packageName}
                                          </p>
                                          <div className="oneline">
                                            <p className="order-head">
                                              Tenure :
                                            </p>
                                            <p className="order-data">
                                              {tenure == 1
                                                ? `${tenure} Day`
                                                : `${tenure} Days`}
                                            </p>
                                          </div>
                                          <div className="oneline-orderdata">
                                            <p className="order-head">
                                              Starting Date :
                                            </p>
                                            <p className="order-data">
                                              {`${startDateValue.getDate()}/${
                                                startDateValue.getMonth() + 1
                                              }/${startDateValue.getFullYear()}`}
                                            </p>
                                            <p className="order-head">
                                              Ending Date :
                                            </p>
                                            <p className="order-data">
                                              {`${endDateValue.getDate()}/${
                                                endDateValue.getMonth() + 1
                                              }/${endDateValue.getFullYear()}`}
                                            </p>
                                          </div>
                                          <div className="order-grid-partition">
                                            <p className="order-head">
                                              Free Addons :
                                            </p>
                                            <div className="order-partition-oneline">
                                              {FreeAddonsArray.map(
                                                (elem, idx) => (
                                                  <>
                                                    <p className="order-data order-data-background">
                                                      {elem.addonName}
                                                    </p>
                                                    <span className="dot-intersec"></span>
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                          <div className="order-grid-partition">
                                            <p className="order-head">
                                              Paid Addons :
                                            </p>
                                            <div className="order-partition-oneline">
                                              {paidAddonsItems.map(
                                                (elem, idx) => (
                                                  <>
                                                    <p className="order-data order-data-background">
                                                      {elem.addonName}
                                                    </p>
                                                    <span className="dot-intersec"></span>
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                          <div className="order-priview-title">
                                            <p className="order-head-title">
                                              Payment
                                            </p>
                                          </div>
                                          <div className="oneline">
                                            <p className="order-head">Rent :</p>
                                            <p className="order-data">
                                              ₹{createOrder.values.total}
                                            </p>
                                          </div>
                                          <div className="oneline">
                                            <p className="order-head">
                                              Coupon :
                                            </p>
                                            <p className="order-data">
                                              {coupon.couponName
                                                ? coupon.couponName
                                                : "Null"}
                                            </p>
                                          </div>
                                          <div className="oneline">
                                            <p className="order-head">
                                              Damage Waiver :
                                            </p>
                                            <p className="order-data">Null</p>
                                          </div>
                                          <div className="oneline">
                                            <p className="order-head">GST :</p>
                                            <p className="order-data">
                                              {gstData.companyName
                                                ? "Claimed"
                                                : "Not Claimed"}
                                            </p>
                                          </div>
                                          <div className="oneline padleft">
                                            <p className="order-data">
                                              {gstData.companyName}
                                            </p>
                                            <span className="dot-intersec"></span>
                                            <p className="order-data">
                                              {gstData.gstNo}
                                            </p>
                                            <span className="dot-intersec"></span>
                                          </div>
                                          <div className="oneline padleft">
                                            <p className="order-data">
                                              {" "}
                                              {gstData.gstAddress}
                                            </p>
                                          </div>
                                          <div className="order-priview-title">
                                            <p className="order-head-title">
                                              User Information
                                            </p>
                                          </div>
                                          <div className="oneline">
                                            <p className="order-data">
                                              {getUserByPhone?.fullname}
                                            </p>
                                          </div>
                                          <p
                                            style={{
                                              color: "#646464",
                                              marginBottom: "0rem",
                                              fontSize: "0.8rem",
                                              marginLeft: "1rem",
                                            }}
                                          >
                                            {getUserByPhone.customId}
                                          </p>
                                          <div className="oneline">
                                            <p className="order-data">
                                              {createOrder.values.city}
                                            </p>
                                            <span className="dot-intersec"></span>
                                            <p className="order-data">
                                              {createOrder.values.pincode}
                                            </p>
                                          </div>
                                          <div className="oneline">
                                            <p className="order-data padright">
                                              {createOrder.values.address}
                                            </p>
                                          </div>
                                          <div className="oneline">
                                            <p className="order-data">
                                              {getUserByPhone?.phone}
                                            </p>
                                            <span className="dot-intersec"></span>
                                            <p className="order-data">
                                              {getUserByPhone?.alternatePhone}
                                            </p>
                                          </div>
                                          <div className="rental-agreement-container">
                                            <div className="rental-agreement-box">
                                              <p
                                                style={{
                                                  marginBottom: "0rem",
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    height: "1rem",
                                                    marginRight: "0.5rem",
                                                    marginBottom: "0.1rem",
                                                  }}
                                                  src={Doctemp}
                                                  alt=""
                                                />
                                                <input
                                                  type="file"
                                                  name="rentalAgreement"
                                                  id=""
                                                  accept="application/pdf"
                                                  onChange={(e) =>
                                                    setRentalAgreement(
                                                      e.target.files[0]
                                                    )
                                                  }
                                                />
                                                {rentalAgreement.name
                                                  ? rentalAgreement.name
                                                  : "Rental Agreement"}
                                              </p>
                                            </div>
                                            <div className="documets-columns">
                                              <div className="docs-box">
                                                <img src={selfieURL} alt="" />
                                                <p>Self Picture</p>
                                              </div>
                                              <div className="docs-box">
                                                <img src={identityURL} alt="" />
                                                <p>Identity Proof</p>
                                              </div>
                                              <div className="docs-box">
                                                <img src={addressURL} alt="" />
                                                <p>Address Proof</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="order-priview-last-container">
                                      <div className="order-priview-total-container">
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "0rem",
                                            paddingBottom: "0.2rem",
                                            paddingRight: "10rem",
                                            fontWeight: "600",
                                            fontSize: "1.2rem",
                                            marginLeft: "0.5rem",
                                          }}
                                        >
                                          ₹
                                          {createOrder.values.total
                                            ? createOrder.values.total
                                            : 0}
                                          <img
                                            style={{
                                              width: "1rem",
                                              height: "1rem",
                                              marginBottom: "0.1rem",
                                              marginLeft: "0.3rem",
                                            }}
                                            src={InfoIcon}
                                            alt="infoicon"
                                          />
                                        </p>
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "0rem",
                                            paddingBottom: "0.2rem",
                                            paddingRight: "12rem",
                                            fontWeight: "600",
                                            fontSize: "0.8rem",
                                            marginLeft: "0.5rem",
                                          }}
                                        >
                                          {createOrder.values.paymentStatus == 0
                                            ? "Pending"
                                            : "Cleared"}
                                        </p>
                                      </div>
                                      <div className="order-priview-buttons">
                                        <button
                                          onClick={() => {
                                            setPopup2(!Popup2);
                                            setIspriview(!Ispriview);
                                          }}
                                          style={{ width: "4rem" }}
                                          className="order-priview-edit-btn"
                                        >
                                          Edit
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={createOrder.handleSubmit}
                                          className="order-priview-edit-btn"
                                        >
                                          Place Order
                                        </button>
                                        {placeorder && (
                                          <div className="order-success-main">
                                            <div className="order-success-container">
                                              <div className="order-success-title">
                                                <p>
                                                  New Order has been created.
                                                </p>
                                                <p>Order id : 2469</p>
                                              </div>
                                              <div className="order-success-downcontainer">
                                                <p className="copycontainer">
                                                  www.linkforpsaaymetrendarap/p
                                                  <div className="pasteicon">
                                                    <img
                                                      style={{ height: "1rem" }}
                                                      src={CopyIcon}
                                                      alt=""
                                                    />
                                                  </div>
                                                </p>
                                                <div className="buttondiv">
                                                  <button
                                                    onClick={() => {
                                                      setIspriview(!Ispriview);
                                                      setplaceorder(
                                                        !placeorder
                                                      );
                                                      setPopup2(!Popup2);
                                                      props.onchange(
                                                        !props.isPopup
                                                      );
                                                    }}
                                                    className="order-priview-edit-btn"
                                                  >
                                                    Done
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Neworderpopup;
