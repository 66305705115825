import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  // LOADING,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;

export const fetchAllCoupons = createAsyncThunk(
  "fetch/admin/admin_frontend/coupon/fetchCoupons",
  async () => {
    // const prom = new Promise((res, rej) =>
    //   setTimeout(() => {
    //     res("val");
    //   }, 1000)
    // );
    try {
      const res = await axios.get(
        `${BASE_URL}/admin/admin_frontend/coupon/fetchCoupons`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createNewCoupon = createAsyncThunk(
  "fetch/admin/admin_frontend/coupon/createCoupon",
  async (payload) => {
    try {
      const data = {
        couponName: payload.couponName,
        content: payload.content,
        discountType: payload.discountType,
        amount: payload.amount,
        maxAmount: payload.maxAmount,
        location: payload.location,
        selectedPackageList: payload.selectedPackageList,
        Type: payload.Type
      };
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/coupon/createCoupon`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteCouponA = createAsyncThunk(
  "post/admin/admin_frontend/coupon/delteFunctiocalled",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/coupon/removeCoupon`,
        {
          couponId: id,
        }
      );
      return res.data;
    } catch (error) {
      return error.resopnse.data;
    }
  }
);

export const editCouponStatus = createAsyncThunk(
  "fetch/admin/admin_frontend/coupon/editCoupon",
  async (payload) => {
    try {
      const data = {
        couponId: payload.id,
        isActive: payload.status,
      };
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/coupon/updateCouponStatus`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateCouponById = createAsyncThunk(
  "fetch/admin/admin_frontend/coupon/update_coupon_by_Id",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/coupon/update_coupon_by_Id`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  createdConfirmation: false,
  updateDone: false,
  loading: false,
  pageState: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  data: [],
  status: {
    fetchAllCoupons: IDLE,
    editCouponStatus: IDLE,
    deleteCouponStatus: IDLE,
    createNewCouponStatus: IDLE,
    updateCouponById: IDLE,
  },
};

// -------------------------------Redux----------------------------------//
const adminFrontendCouponSlice = createSlice({
  name: "adminCoupon",
  initialState: initialState,
  reducers: {
    fetchedCoupons: (state, action) => {
      state.data = action.payload.data;
    },
    setPageState: (state, action) => {
      state.pageState = action.payload.pageState;
    },
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
    setUpdateDone: (state, action) => {
      state.updateDone = !action.payload.value;
    },
    setIsCouponDone: (state, acttion) => {
      state.createdConfirmation = !state.createdConfirmation;
    },
  },
  extraReducers: (builder) => {
    builder
      // EXTRA REDUCER FOR FETCHALLCOUPON
      .addCase(fetchAllCoupons.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAllCoupons.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data = payload.data;
            state.status.fetchAllCoupons = FULFILLED;
            state.pageState = !state.pageState;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(fetchAllCoupons.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.status.fetchAllCoupons = ERROR;
      })
      // EXTRA REDUCER FOR EDITCOUPONSTATUS
      .addCase(editCouponStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editCouponStatus.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.editCouponStatus = FULFILLED;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(editCouponStatus.rejected, (state, { payload }) => {
        state.loading = true;
        state.errorData = payload.error;
        state.status.editCouponStatus = ERROR;
      })
      // EXTRA REDUCER FOR DELETE COUPON
      .addCase(deleteCouponA.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteCouponA.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.status.deleteCouponStatus = FULFILLED;
            state.loading = false;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteCouponA.rejected, (state, action) => {
        state.loading = false;
        state.status.deleteCouponStatus = ERROR;
      })
      .addCase(createNewCoupon.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createNewCoupon.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.createNewCouponStatus = FULFILLED;
            state.createdConfirmation = !state.createdConfirmation;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createNewCoupon.rejected, (state, { payload }) => {
        state.loading = false;
        state.status.createNewCouponStatus = ERROR;
      })

      .addCase(updateCouponById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateCouponById.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.status.updateCouponById = FULFILLED;
            state.createdConfirmation = !state.createdConfirmation;
            state.updateDone = !state.updateDone;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
            state.loading = false;
        }
      })
      .addCase(updateCouponById.rejected, (state, { payload }) => {
        state.loading = true;
        state.errorData = payload.error;
        state.status.updateCouponById = ERROR;
      })
  },
});

export default adminFrontendCouponSlice.reducer;
export const {
  fetchedCoupons,
  clearErrorSlice,
  setPageState,
  setUpdateDone,
  clearCurrentStatus,
  clearUpdateCurrentStatus,
  setIsCouponDone,
} = adminFrontendCouponSlice.actions;
