import {
  ADMIN_ACCESS,
  ADMIN_CONTACT_US,
  ADMIN_CONTACT_US_A,
  ADMIN_CONTACT_US_EVENT_REQ,
  ADMIN_CONTACT_US_SEARCH_REQ,
  ADMIN_CONTACT_US_SEARCH_SAVE_REQ,
  ADMIN_CONTACT_US_WAIT_LIST,
  ADMIN_DASHBOARD,
  ADMIN_DATA,
  ADMIN_DATA_DOWNLOAD_NEW,
  ADMIN_FRONTEND,
  ADMIN_FRONTEND_ADDONS,
  ADMIN_FRONTEND_BLOGS,
  ADMIN_FRONTEND_BLOG_REQ,
  ADMIN_FRONTEND_COUPONS,
  ADMIN_FRONTEND_CREATE_ADDONS,
  ADMIN_FRONTEND_CREATE_BLOG,
  ADMIN_FRONTEND_EDIT_BLOG,
  ADMIN_FRONTEND_FAQS,
  ADMIN_FRONTEND_TRENDING_NOW,
  ADMIN_FRONTEND_VIEW_PACKAGE,
  ADMIN_FRONTEND_VIEW_PACKAGE_CATEGORY,
  ADMIN_INV,
  ADMIN_INV_LEND_CANCEL,
  ADMIN_INV_LIST_REQ,
  ADMIN_INV_MAINTAIN_LIST,
  ADMIN_INV_PROD_AVAIL,
  ADMIN_LOGIN,
  ADMIN_ORDER,
  ADMIN_ORDER_CANCEL_REQ,
  ADMIN_ORDER_DAILY_LOG,
  ADMIN_ORDER_DISAPPROVED_ORDER,
  ADMIN_ORDER_LONG,
  ADMIN_ORDER_NEW_ORDER,
  ADMIN_ORDER_SHORT,
  ADMIN_ORDER_USER_PAY,
  ADMIN_PRODUCT_SUP,
  ADMIN_ROOT,
  ADMIN_USERS,
} from "./links";


export const BASE_URL = `https://rentkar.app/api`;
// export const BASE_URL = `http://localhost:5000/api`;

export const FAILURE = "FAILURE";
export const OTP_FAILURE = "OTP_FAILURE";
export const OTP_ACTIVE = "OTP_ACTIVE";
export const SUCCESS = "SUCCESS";
export const REGISTERED = "REGISTERED";
export const LOGGEDIN = "LOGGEDIN";
export const INVALID_FILE = "INVALID_FILE";
export const SERVER_ERROR = "SERVER_ERROR";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const DUPLICATE_VALUE = "DUPLICATE_VALUE";
export const LOCATION_ERROR = "LOCATION_ERROR";
export const LOADING = "LOADING";
export const FULFILLED = "FULFILLED";
export const ERROR = "ERROR";
export const IDLE = "IDLE";
export const ORDER = "ORDER";
export const LISTING_REQ = "LISTING_REQ";
export const LISTING_REQUEST = "LISTING_REQUEST";

// order statuses
export const PENDING = "PENDING";
export const APPROVED = "APPROVED";
export const DISAPPROVED = "DISAPPROVED";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const CANCELLED = "CANCELLED";
export const COMPLETE = "COMPLETE";
export const CREDIT_PAYMENT = "CREDIT_PAYMENT";
//Razor pay
export const RAZORPAY_ID = "rzp_test_YgXdVqSMsfNgdR";

// cities
export const BANGLORE = "BANGALORE";
export const MUMBAI = "MUMBAI";
export const PUNE = "PUNE";
export const DELHI = "DELHI";

// LOGISTIC ADDRESS
export const LOGISTIC_ADDRESS = {
  MUMBAI: {
    address:
      "Royal Enclave Parsi Panchayat Road, Old Nagardas Rd, Mumbai, Maharashtra 400069",
    floor: "3",
    landMark: "apple chemist",
    pincode: "400069",
    state: "maharashtra",
    city: "mumbai",
    lat: "19.115627485553432",
    lng: "72.86023984436974",
  },
  PUNE: {
    address:
      "Building 2, Sangam Complex, Sangam Bridge, Sangamvadi, Pune, Maharashtra 411001",
    landMark: "Sangam Bridge",
    appartmentAddress: "Pune Office No.40",
    state: "maharashtra",
    city: "pune",
    lat: "18.530170138401573",
    lng: " 73.86171114067291",
    pincode: "411001",
  },
};
//DELIVERY_TYPE
export const DELIVERY_TYPE = {
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
};
//errors
export const REJECTED_ERROR = {
  message: "server did not respond",
  type: "SERVER ERROR",
};
// inv statuses
export const RETURNED = "RETURNED";
export const RENTED = "RENTED";
export const LOST = "LOST";
export const INSTORE = "INSTORE";
export const MAINTENANCE = "MAINTENANCE";
export const INTERNAL = "INTERNAL";
export const EXTERNAL = "EXTERNAL";

//dropdown constants
export const DROP_DOWN_CATEGORY = "drop_down_category";
export const DROP_DOWN_SUB_CATEGORY = "drop_down_sub_category";

//data wanted
export const USERS_DATA = "USERS";
export const FAQ_REQUEST_DATA = "FAQ_REQUEST";
export const CONTACTUS_DATA = "CONTACTUS";
export const LONG_TERM_ORDERS_DATA = "LONG_TERM_ORDERS";
export const SHORT_TERM_ORDERS_DATA = "SHORT_TERM_ORDERS";
export const ALL_PRODUCT = "ALL_PRODUCT";
export const ALL_PACKAGES = "ALL_PACKAGES";
export const ALL_PROD_SUPPORT = "ALL_PROD_SUPPORT";
export const ALL_LISTED_INVENTORY = "ALL_LISTED_INVENTORY";
export const ALL_PRODUCT_AVAILABILITY = "ALL_PRODUCT_AVAILABILITY";
export const ALL_MAINTAINENCE = "ALL_MAINTAINENCE";
export const ALL_LISTING_REQUEST = "ALL_LISTING_REQUEST";
export const USER_PAYMENTS_DATA = "USER_PAYMENTS_DATA";
export const DAILY_LOGISTICS_DATA = "DAILY_LOGISTICS_DATA";
export const DISAPPROVED_ORDER_DATA = "DISAPPROVED_ORDER_DATA";
export const BUSINESS_REQUEST_DATA = "BUSINESS_REQUEST_DATA"

//search pages
export const searchLinksData = [
  {
    title: "Home page",
    keywords: "home",
    link: ADMIN_ROOT,
    Description: "home page",
  },
  //Dashboard=================================================================
  {
    title: "Dashboard page",
    keywords: "dashboard",
    link: ADMIN_DASHBOARD,
    Description: "dashboard of renkar admin site",
  },
  //Login========================================================================
  {
    title: "Login page",
    keywords: "login signup register",
    link: ADMIN_LOGIN,
    Description: "login page of rentkar admin site",
  },
  //Order=========================================================================
  {
    title: "Order page",
    keywords: "order",
    link: ADMIN_ORDER,
    Description: "orders of rentkar site",
  },
  {
    title: "New order page",
    keywords: "order new neworder",
    link: ADMIN_ORDER_NEW_ORDER,
    Description: "new orders or recent orders",
  },
  {
    title: "Long term order page",
    keywords: "longorder longtermorder long term order",
    link: ADMIN_ORDER_LONG,
    Description: "long term orders of site",
  },
  {
    title: "Short term order page",
    keywords: "shortorder shorttermorder short term order",
    link: ADMIN_ORDER_SHORT,
    Description: "short term orders of site",
  },
  {
    title: "Daily logistic page",
    keywords: " dailylogistic daily logistic order",
    link: ADMIN_ORDER_DAILY_LOG,
    Description: "daily logistics order",
  },
  {
    title: "User payment page",
    keywords: "userpayment user pay payment",
    link: ADMIN_ORDER_USER_PAY,
    Description: "user payment page of admin",
  },
  {
    title: "Cancel request page",
    keywords: "cancel request cancelrequest cancellation",
    link: ADMIN_ORDER_CANCEL_REQ,
    Description: "Request Cancellation of user page",
  },
  {
    title: "Disapproved orders page",
    keywords: "disapproved disapprovedorders",
    link: ADMIN_ORDER_DISAPPROVED_ORDER,
    Description: "Order that got disapproved",
  },
  //Listed Inventory================================================================
  {
    title: "Listed inventory",
    keywords: "listed inventory listedinventory store",
    link: ADMIN_INV,
    Description: "inventory all stocks of product",
  },
  {
    title: "New listing request",
    keywords: "newlisting listing",
    link: ADMIN_INV_LIST_REQ,
    Description: "List of new listing products",
  },
  {
    title: "Lending cancellation",
    keywords: "lending cancellation",
    link: ADMIN_INV_LEND_CANCEL,
    Description: "List of requests for cancellation",
  },
  {
    title: "Maintenance list",
    keywords: "maintenance",
    link: ADMIN_INV_MAINTAIN_LIST,
    Description: "List of maintenance product",
  },
  {
    title: "Product availability",
    keywords: "availability product",
    link: ADMIN_INV_PROD_AVAIL,
    Description: "List of product availability",
  },

  //Admin access=====================================================================
  {
    title: "All admins",
    keywords: "admin access adminaccess  addusers addadmin",
    link: ADMIN_ACCESS,
    Description: "List of users who have access to admin panel",
  },
  //Data===============================================================================
  {
    title: "Data",
    keywords:
      "data all data alldata downloadlatest latest download data datadownload",
    link: ADMIN_DATA,
    Description: "All kind of data",
  },
  //Contact Us=====================================================================
  {
    title: "Contact Us",
    keywords: "contactus contact us",
    link: ADMIN_CONTACT_US_A,
    Description: "Contact Us page",
  },
  {
    title: "Business request",
    keywords: "businessrequest business request",
    link: ADMIN_CONTACT_US,
    Description: "list of business request",
  },
  {
    title: "Search request",
    keywords: "searchrequest search request",
    link: ADMIN_CONTACT_US_SEARCH_REQ,
    Description: "list of search request",
  },
  {
    title: "Saved search request",
    keywords: "savedsearchrequest search saved request",
    link: ADMIN_CONTACT_US_SEARCH_SAVE_REQ,
    Description: "list of saved search request",
  },
  {
    title: "Event request",
    keywords: "eventrequest event request",
    link: ADMIN_CONTACT_US_EVENT_REQ,
    Description: "List of event request",
  },
  {
    title: "Wait list",
    keywords: "waitlist wait list",
    link: ADMIN_CONTACT_US_WAIT_LIST,
    Description: "List of users in waitlist",
  },

  //Product support===============================================================
  {
    title: "Product support",
    keywords: "productsupport products support",
    link: ADMIN_PRODUCT_SUP,
    Description: "Users list for product support",
  },
  //Frontend============================================================================
  {
    title: "Frontend",
    keywords: "frontend",
    link: ADMIN_FRONTEND,
    Description: "Frontend page",
  },
  {
    title: "View packages",
    keywords: "viewpackages view packages categories",
    link: ADMIN_FRONTEND_VIEW_PACKAGE,
    Description: "Categories of package",
  },
  {
    title: "All products",
    keywords: "allproduct productcategories categories",
    link: ADMIN_FRONTEND_VIEW_PACKAGE_CATEGORY,
    Description: "List of all products in category",
  },
  {
    title: "Trending Now",
    keywords: "trending now trendingnow",
    link: ADMIN_FRONTEND_TRENDING_NOW,
    Description: "Manage clientside products",
  },
  {
    title: "Addons",
    keywords: "addons",
    link: ADMIN_FRONTEND_ADDONS,
    Description: "List of addons",
  },
  {
    title: "Create addons",
    keywords: "create addons createaddons",
    link: ADMIN_FRONTEND_CREATE_ADDONS,
    Description: "Create addons",
  },
  {
    title: "FAQS",
    keywords: "faqrequests faq",
    link: ADMIN_FRONTEND_FAQS,
    Description: "Users list of faqs",
  },
  {
    title: "Coupons",
    keywords: "coupons",
    link: ADMIN_FRONTEND_COUPONS,
    Description: "List of coupons",
  },
  //Blog Request===========================================================================
  {
    title: "Blogs Request",
    keywords: "blogs request blogsrequest",
    link: ADMIN_FRONTEND_BLOGS,
    Description: "List of blog request",
  },
  {
    title: "Create blog ",
    keywords: "create createblogs blogs",
    link: ADMIN_FRONTEND_CREATE_BLOG,
    Description: "Create blog",
  },
  {
    title: "Edit blog ",
    keywords: "edit editblogs blogs",
    link: ADMIN_FRONTEND_EDIT_BLOG,
    Description: "Edit blog",
  },
  //User==================================================================================
  {
    title: "Users",
    keywords: "users user",
    link: ADMIN_USERS,
    Description: "Registered users",
  },
];

// Delivery Charge based on item weight
export const DELIVERY_CHARGE_FOR_HEAVY = 500;
export const DELIVERY_CHARGE_FOR_MEDIUM = 250;
export const DELIVERY_CHARGE_FOR_LIGHT = 100;

// documents status
export const REJECTED = "REJECTED";
export const REUPLOAD = "REUPLOAD";
export const EXPIRED = "EXPIRED";

// Triggers for expirey and bankStatement
export const BANK_STATEMENT_TRIGGER = 80000;
export const ADDRESS_EXPIREY_IN_MONTHS = 1;
export const USER_DOCUMENTS_EXPIREY_IN_MONTHS = 1;

// Delivery type
export const COMPANY = "COMPANY";
export const INDIVIDUAL = "INDIVIDUAL";