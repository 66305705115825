import "./pagenotfound.css";

import { Link } from "react-router-dom";
import React from "react";
import { USER_ROOT } from "../../constants/links";

function PageNotFound() {
  return (
    <div className="page-not-found-container">
      <p id="page-not-found"> Page Not found </p>
      <Link to={USER_ROOT} id="page-not-found-link">
        Go to Home
      </Link>
    </div>
  );
}

export default PageNotFound;
