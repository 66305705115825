import "./failureAlert.css";

import React, { useEffect } from "react";

import {
Cancellation
} from "../../assets";
import { useSelector } from "react-redux";

const FailureAlert = ({ setAlert, message, type, errors }) => {
  const theme = useSelector((state) => state.rootReducer.empInfo.isLogin);
  // temp basis unitl all ui is changed
  let array = [];
  if (typeof errors == "object") {
    array = errors.map((data) => Object.values(data)[0]);
  }

  const closePopup = (e) => {
    if (e.target.id === `error-backdrop-for-outside-click`) {
      setAlert();
    }
  };
  useEffect(() => {
    // Escape key close event
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        setAlert();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.addEventListener("keydown", keyDownHandler);
    };
  });
  return (
    <>
      <div
        className="global-alert-base-container"
        id="error-backdrop-for-outside-click"
        onClick={(e) => closePopup(e)}
      >
        <div
          className={
            theme == true
              ? "global-alert-container-light"
              : "global-alert-container"
          }
        >
          <div className="global-alert-cacel-outer">
            <img src={Cancellation} alt="" onClick={() => setAlert()} />
          </div>
          <div className="global-alert-content-container">
            <p>
              <strong>{type}</strong>
              {message}
              {/* <p>{errors + "hardcoded"}</p> */}
              <p>{[...array].join(", ")}</p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FailureAlert;
