import React, { useEffect, useRef } from "react";
import "./waitlistComponent.css";
import { Calender, Cross } from "../../assets";
import { useState } from "react";
import {
  dateFormatter,
  getDuration,
  planPriceFormatterDaily,
} from "../../utils/displayUtils";
import PopUp from "../PopUp/PopUp";
import { usePopper } from "react-popper";
import Datepicker from "../Datepicker/datepicker";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  clearErrorSlice,
  createUserWaitlist,
  getUserWaitlistDetails,
} from "../../redux/features/waitlistSlice/waitlistSlice";
import FailureAlert from "../failureAlert/failureAlert";
import { LoadingPage } from "..";
import { SUCCESS } from "../../constants/constants";
// import { loginByToken } from "../../redux/features/loginSlice/loginSlice";

const WaitlistComponent = ({ closeCallback }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.rootReducer.userInfo.data);

  const currentUserInfo = useSelector(
    (state) => state.rootReducer.userInfo.currentUserInfo
  );

  const currentPackageDetails = useSelector(
    (state) => state.rootReducer.homePage.data.packageDetails
  );

  const checkUserFound = useSelector(
    (state) => state.rootReducer.waitlistSlice.isUserExit
  );
  const updateDone = useSelector(
    (state) => state.rootReducer.waitlistSlice.updateDone
  );

  const [noOfDays, setNoOfDays] = useState(0);
  const [duration, setDuration] = useState(1);
  //set duration
  const selectPackageDuration = (dura) => {
    setDuration(dura);
    setNoOfDays(parseInt(dura));
    setEndDateValue(
      new Date(startDateValue.getTime() + 1000 * 3600 * 24 * parseInt(dura))
    );
  };
  const today = new Date();
  const formattedDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const formattedNextDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  const [startDateValue, setStartDateValue] = useState(formattedDate);
  const [endDateValue, setEndDateValue] = useState(formattedNextDate);
  const [endDate, setEndDate] = useState(false);
  const [startDate, setStartDate] = useState(false);
  // Popper references ======================================================
  // start date
  const [refWait, setRefWait] = useState(useRef(null));
  const [popWait, setPopWait] = useState(useRef(null));
  //end date
  const [refEndWait, setRefEndWait] = useState();
  const [popEndWait, setpopEndWait] = useState();
  //console.log(refEndWait, popEndWait);

  // popper
  const popUpParamsBtmStart = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };
  const popUpParamsBtmEnd = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };

  const popperStartDate = usePopper(refWait, popWait, popUpParamsBtmStart);
  const popperEndDate = usePopper(refEndWait, popEndWait, popUpParamsBtmEnd);

  // Constant Error
  const loading = useSelector(
    (state) => state.rootReducer.waitlistSlice.loading
  );
  const customAlert = useSelector(
    (state) => state.rootReducer.waitlistSlice.isError
  );
  const error = useSelector(
    (state) => state.rootReducer.waitlistSlice.errorData
  );
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  // ------------ Error end

  //phone number regex
  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const createWaitlist = useFormik({
    initialValues: {
      phoneNumber: "",
      address: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.number("Invalid Number")
        .max(9999999999, "Invalid Number")
        .min(1000000000, "Invalid Number")
        .required("Phone number is required"),
      address: Yup.string()
        .min(5, "Address must be of 6 length")
        .max(300, "Address must be of 6 length")
        // .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "Invalid pincode")
        .required("Address is required"),
      pincode: Yup.string()
        .min(6, "Pincode must be of 6 length")
        .max(6, "Pincode must be of 6 length")
        // .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "Invalid pincode")
        .required("Pincode is required"),
    }),
    onSubmit: (values) => {
      const data = {
        packageId: currentPackageDetails._id,
        address: values.address,
        phone: values.phoneNumber,
        startDate: startDateValue,
        endDate: endDateValue,
        pincode: values.pincode,
        duration: noOfDays,
      };
      dispatch(createUserWaitlist(data)).then(
        (data) => data?.payload?.type == SUCCESS && closeCallback()
      );
    },
  });
  useEffect(() => {
    //console.log(userInfo);
    createWaitlist.values.phoneNumber = userInfo?.phone;
    //console.log(userInfo);
  }, [userInfo]);

  useEffect(() => {
    dispatch(
      getUserWaitlistDetails({
        userId: currentUserInfo?._id,
        packageId: currentPackageDetails._id,
      })
    );
  }, [updateDone]);

  //console.log(createWaitlist.errors);
  //console.log(createWaitlist.values);
  // console.log(userInfo);
  return (
    <div className="waitlist-base-container">
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <p className="wait-list-container-header">
        Wait List
        <img
          onClick={() => {
            closeCallback();
          }}
          src={Cross}
          alt=""
        />
      </p>
      <div className="waitlist-container-grid-layout">
        <div id="name-input">
          <label className="create-package-error-label">
            {createWaitlist.errors.userInfo && createWaitlist.touched.userInfo
              ? createWaitlist.errors.userInfo
              : "\u00A0"}
          </label>
          <input
            className="waitlist-input-container"
            type="text"
            placeholder="Name"
            value={userInfo?.userName}
            disabled
          />
        </div>
        <div id="number-input">
          <label className="create-package-error-label">
            {createWaitlist.errors.phoneNumber &&
            createWaitlist.touched.phoneNumber
              ? createWaitlist.errors.phoneNumber
              : "\u00A0"}
          </label>
          <input
            className="waitlist-input-container"
            placeholder="Number"
            name="phoneNumber"
            value={createWaitlist.values.phoneNumber}
            onChange={createWaitlist.handleChange}
            onBlur={createWaitlist.handleBlur}
          />
        </div>
        <div id="address-input">
          <label className="create-package-error-label">
            {createWaitlist.errors.address && createWaitlist.touched.address
              ? createWaitlist.errors.address
              : "\u00A0"}
          </label>
          <input
            className="waitlist-input-container"
            placeholder="Address"
            name="address"
            value={createWaitlist.values.address}
            onChange={createWaitlist.handleChange}
            onBlur={createWaitlist.handleBlur}
          />
        </div>

        <div id="renting-days-card-container">
          {currentPackageDetails.customPlans?.map((elm, idx) => (
            <div
              className={
                elm.duration == duration
                  ? "renting-card-outer-active"
                  : "renting-card-outer"
              }
              onClick={() => {
                selectPackageDuration(elm.duration);
              }}
            >
              <div className="renting-days-card">
                <p id={elm.duration == duration && "active-card"}>
                  {getDuration(elm.duration)}
                  <span>
                    ₹{planPriceFormatterDaily(elm.duration, elm.price)}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>

        <div id="startdate" className="dates">
          <p>From</p>
          <div ref={setRefWait}>
            <input type="type" value={dateFormatter(startDateValue)} />
            <div
              onClick={() => setStartDate(!startDate)}
              className="img-container"
            >
              <img src={Calender} alt="" />
            </div>
          </div>
        </div>
        {/*  */}

        <div id="enddate" className="dates">
          <p>Upto</p>
          <div ref={setRefEndWait}>
            <input value={dateFormatter(endDateValue)} />
            <div className="img-container right">
              <img src={Calender} alt="" />
            </div>
          </div>
        </div>

        <div id="waitlist-city">
          <label className="create-package-error-label">{"\u00A0"}</label>{" "}
          <div className="location-input-container">
            <div id="waitlist-city-gredient">Mumbai</div>
          </div>
        </div>
        <div id="waitlist-pincode">
          <label className="create-package-error-label">
            {createWaitlist.errors.pincode && createWaitlist.touched.pincode
              ? createWaitlist.errors.pincode
              : "\u00A0"}
          </label>
          <input
            placeholder="Pincode"
            className="location-input-container"
            name="pincode"
            type="number"
            value={createWaitlist.values.pincode}
            onChange={createWaitlist.handleChange}
            onBlur={createWaitlist.handleBlur}
          />
        </div>

        <div id="waitlist-button">
          <button
            className="waitlist-button"
            type="submit"
            onClick={createWaitlist.handleSubmit}
            disabled={checkUserFound}
          >
            Enter WaitList
          </button>
        </div>
      </div>
      <PopUp
        display={startDate && !window.phone}
        callback={setStartDate}
        popper={popperStartDate}
        uniqueId={"StartDate"}
        zIndex={13}
      >
        <div
          ref={setPopWait}
          // ref={setPopStartMobile}
          style={{ ...popperStartDate?.styles?.popper }}
          {...popperStartDate?.attributes}
        >
          <Datepicker
            rounded={true}
            boxShadow={true}
            disbaleBeforeDate={today}
            date={startDateValue}
            setDate={setStartDateValue}
          />
        </div>
      </PopUp>
      <PopUp
        display={endDate && !window.phone}
        callback={setEndDate}
        popper={popperEndDate}
        uniqueId={"EndDate"}
        zIndex={13}
      >
        <div
          ref={setpopEndWait}
          style={{ ...popperEndDate?.styles?.popper }}
          {...popperEndDate?.attributes}
        >
          <div className="product-details-end-date-container">
            <Datepicker
              rounded={true}
              boxShadow={true}
              disbaleBeforeDate={startDateValue}
              date={endDateValue}
              setDate={setEndDateValue}
            />
            {/* <div className="date-picker-bottom-container">
              <div className="date-picker-left-container">
                <p>No of Days: </p>
                <div className="date-picker-no-of-days-container">
                  <p>{noOfDays}</p>
                </div>
              </div>
              <button onClick={() => getNoOfDays()}>Set Date</button>
            </div> */}
          </div>
        </div>
      </PopUp>
    </div>
  );
};

export default WaitlistComponent;
