import './admintogglebtn.css'

import React from 'react'

const Admintogglebtn = ({label, isActive}) => {
  return (
    <>  
        <div className="toggle-switch">
            <input type="checkbox" name={label} id={label} className='checkbox' checked= {isActive ? "true" : "false"} />
            <label className="label" htmlFor={label}>
                <span className='inner'/>
                <span className='switch'/>
            </label>
        </div>  
    </>
  )
}

export default Admintogglebtn