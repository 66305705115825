import "./documentReUploadPopUp.css";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { REUPLOAD, SUCCESS } from "../../../constants/constants";
import { changeOrderStatusSlice } from "../../../redux/features/orderSlice/orderSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

const DocumentReUploadPopUp = (props) => {
  const [confirmReUpload, setConfirmReUpload] = useState(false);

  useEffect(() => {
    if (confirmReUpload) {
      document.body.style.position = "fixed";
    }
  });

  const dispatch = useDispatch();

  // const orderStatus = useFormik({
  //   initialValues: {
  //     status: "",
  //     message: "",
  //     document: [],
  //   },
  //   validationSchema: Yup.object({
  //     message: Yup.string()
  //       .min(10, "* message cannot be less than 10")
  //       .max(300, "* message cannot be more than 300"),
  //     document: Yup.array().min(1, "* At least on document must be rejected")
  //   }),
  //   onSubmit: (values) => {
  //     const data = {
  //       orderId: props.mongoId,
  //       status: props.orderStatus,
  //       message: values.message,
  //       document: values.document,
  //     };
  //     setConfirmReUpload(!confirmReUpload);
  //     dispatch(changeOrderStatusSlice(data)).then((data) => {
  //       if (data.payload.type == SUCCESS) {
  //         setConfirmReUpload(!confirmReUpload);
  //       }
  //     });
  //   },
  // });

  const reuploadBtnHandler = () => {
    const data = {
      orderId: props.mongoId,
      status: REUPLOAD,
    };
    // console.log("data.status: ", data.status)
    setConfirmReUpload(!confirmReUpload);
    dispatch(changeOrderStatusSlice(data)).then((data) => {
      if (data.payload.type == SUCCESS) {
        setConfirmReUpload(!confirmReUpload);
      }
    });
  };

  return (
    <>
      <div className="admin-order-moreopt-reupload-popup">
        <div className="admin-order-moreopt-reupload-popup-inner-container">
          <div className="admin-order-moreopt-reupload-popup-inner-content">
            <div className="reupload-popup-head">
              <p id="reupload-title-one">Re-upload Confirmation</p>
            </div>
            <div className="reupload-order-customer-info">
              <p id="reupload-orderinfo-p">
                Are you sure you want to send order no.{" "}
                <span> {props.orderId} </span> of
                <span> {props.userName} </span>
                for package <span> {props.packageName} </span> for document
                re-upload?
              </p>
            </div>
            {/* <div
              className="add-new-admin-order-container"
              style={{ marginTop: "0rem" }}
            >
              <label htmlFor="">
                {orderStatus.errors.message && orderStatus.touched.message
                  ? orderStatus.errors.message
                  : "\u00A0"}
              </label>
              <label htmlFor="">
                {orderStatus.errors.document && orderStatus.touched.message
                  ? orderStatus.errors.document
                  : "\u00A0"}
              </label>
            </div>
            <div
              className="reupload-order-textinput-container"
              style={{ marginTop: "0rem" }}
            >
              <textarea
                id="reupload-reason"
                cols="30"
                rows="10"
                name="message"
                placeholder="Reason for re-uploading documents"
                value={orderStatus.values.message}
                onChange={orderStatus.handleChange}
                onBlur={orderStatus.handleBlur}
              ></textarea>
            </div>
            <div className="check-box-container">
                <div className="check-box-container-cb-align">
                    <div className="checkbox-box-design">
                        <input
                            name="document"
                            type="checkbox"
                            onChange={orderStatus.handleChange}
                            value="selfieProof"
                        />
                        <label>
                                Selfie Proof
                        </label>
                    </div>
                    <div className="checkbox-box-design">
                        <input
                            name="document"
                            type="checkbox"
                            onChange={orderStatus.handleChange}
                            value="identityProof"
                        />
                        <label>
                            Identity Proof
                        </label>
                    </div>
                    <div className="checkbox-box-design">
                        <input
                            name="document"
                            type="checkbox"
                            onChange={orderStatus.handleChange}
                            value="addressProof"
                        />
                        <label className="">
                            Address Proof
                        </label>
                    </div>
                </div>
            </div> */}
            <div className="reupload-inner-last-container">
              <div className="reupload-buttons-container">
                <button onClick={props.onclick} id="reupload-cancel-btn">
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    props.setState("REUPLOAD");
                    reuploadBtnHandler();
                    // orderStatus.handleSubmit();
                  }}
                  id="reupload-main-btn"
                >
                  Assign Reupload
                </button>
                {confirmReUpload && (
                  <>
                    <div className="reupload-confirm-main-container">
                      <div className="reupload-confirm-outer-main-container">
                        <div className="reupload-confirn-inner-main-container">
                          <div className="reupload-confirm-title-main">
                            <p id="reupload-confirm-title-one">
                              Order applied for re-upload
                            </p>
                          </div>
                          <div className="reupload-confirm-order-informartion">
                            <p id="reupload-confirm-orderno-information">
                              Order no.{props.orderId} has been assigned for
                              re-upload. by you
                            </p>
                          </div>
                          <div className="reupload-confirm-done-button">
                            <button
                              onClick={props.onclick}
                              id="reupload-confirm-done"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentReUploadPopUp;
