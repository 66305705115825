import "./mylistingcarddropdown.css";

import {
  Cancellation,
  Download,
  Help,
  OrderAccepted,
  Packagedetails,
  PriceInfo,
} from "../../assets";

import { Link } from "react-router-dom";
import Mylistinglendingcancelation from "../Mylistinglendingcancelation/mylistinglendingcancelation";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PopUp from "../PopUp/PopUp";

const Mylistingcarddropdown = (props) => {
  const [isCancel, setIsCancel] = useState(false);
  const [isPackage, setIsPackage] = useState(false);

  return (
    <>
      <div className="mylisting-card-dropdown-container">
        <div className="mylisting-card-dropdown-content">
          <p onClick={() => setIsCancel(!isCancel)}>
            <img
              src={Cancellation}
              alt="cancellation"
              style={{ width: "1rem", height: "1rem", marginRight: ".5rem" }}
            />{" "}
            Cancel Lending
          </p>
          <PopUp
            display={isCancel}
            callback={setIsCancel}
            uniqueId={"isCancel"}
            zIndex={13}
            center={true}
            fixed={true}
          >
            <Mylistinglendingcancelation
              listingReqData={props.listingReqData}
              onclick={props.onclick}
            />
          </PopUp>

          <p>
            <img
              src={Download}
              alt="download"
              style={{ marginRight: ".6rem" }}
            />{" "}
            Download Agreement{" "}
          </p>
          <p>
            <img
              src={Download}
              alt="download"
              style={{ marginRight: ".6rem" }}
            />{" "}
            Download Invoice
          </p>
          <p>
            <img
              src={PriceInfo}
              alt="priceinfo"
              style={{ marginRight: ".6rem" }}
            />{" "}
            Product Support
          </p>
        </div>
      </div>
    </>
  );
};
// };

export default Mylistingcarddropdown;
