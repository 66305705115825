import React, { useRef } from "react";
import { mainDealRoom } from "../../../assets";
import "./Dealhouse.css";
import { Link } from "react-router-dom";
import DealProduct from "../../../components/dealhouseProduct/DealProduct";

const Dealhouse = ({ paddTop ,dealHouseRef}) => {
  return (
    <div className="dealhouseMain" ref={dealHouseRef}>
      <div className="dealContent d-flex my-5" style={{ paddingTop: paddTop }}>
        <div className="dealImg col-md-7 col-12">
          <img src={mainDealRoom} alt="" srcset="" className="img-fluid" />
          <DealProduct prodTitle={"Studio Room"} top={"34%"} left={"44%"} navLink={"/dealhouse/studioroom"} />
          <DealProduct prodTitle={"Gaming Room"} top={"17%"} left={"67%"} navLink={"/dealhouse/gamingroom"} />
          <DealProduct prodTitle={"BedRoom"} top={"35%"} left={"56%"} navLink={"/dealhouse/bedroom"} />
        </div>
        <div className="col-md-5 col-12 dealMesLink">
          <div className="dealMessage">
            <h1>Dealhouse</h1>
            <h2>A new way to explore deals and products.</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna,
              risus aenean lectus at amet.
            </p>
          </div>
          <div className="links">
            <h2>Explore</h2>
            <ul>
              <li>
                <Link to="/dealhouse/gamingroom">Gaming Room</Link>
              </li>
              <li>
                <Link to="/dealhouse/bedroom">Bedroom</Link>
              </li>
              <li>
                <Link to="/dealhouse/studioroom">Studio Room</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dealhouse;
