import "./mycartcard.css";

import {
  Delete,
  DownArrow,
  Edit,
  GradientEdit,
  Pc,
  PriceInfo,
  RedDelete,
  Rupees,
  SmallCross,
} from "../../assets";
import React, { useEffect, useState } from "react";
import {
  dateFormatter,
  getDuration,
  getNoOfDays,
  validOrderStartDate,
} from "../../utils/displayUtils";
import {
  deleteUserCartSlice,
  setUserCartId,
  updateUserCartSlice,
} from "../../redux/features/loginSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";

import { DatePicker } from "..";
import { Link } from "react-router-dom";
import PopUp from "../PopUp/PopUp";
import Pricebreakdown from "../Pricebreakdown/pricebreakdown";
import { usePopper } from "react-popper";

const Mycartcard = (props) => {
  const today = new Date();
  const propsStartDate = new Date(props?.startDateUnix);
  const propsEndDate = new Date(props?.endDateUnix);
  const todayHour = today.getHours();
  const todayDay = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();
  // const formattedDate = new Date(
  //   propsStartDate.getFullYear(),
  //   propsStartDate.getMonth(),
  //   propsStartDate.getDate()
  // );
  // const formattedNextDate = new Date(
  //   propsEndDate.getFullYear(),
  //   propsEndDate.getMonth(),
  //   propsEndDate.getDate()
  // );
  const [tenure, setTenure] = useState(
    getNoOfDays(props?.startDateUnix, props?.endDateUnix)
  );

  const [startDate, setStartDate] = useState(false);
  const [startDateValue, setStartDateValue] = useState(propsStartDate);
  const [endDateValue, setEndDateValue] = useState(propsEndDate);
  const [deliveryTime, setDeliveryTime] = useState({
    startHour: props?.deliveryTime?.startHour,
    endHour: props?.deliveryTime?.endHour,
  });
  const [viewDetails, setViewDetails] = useState(false);
  // Popper references ======================================================
  // start date
  const [refStart, setRefStart] = useState();
  const [popStart, setPopStart] = useState();
  // start date mobile
  const [refStartMobile, setRefStartMobile] = useState();
  // const [popStartMobile, setPopStartMobile] = useState();
  const [popSM, setPopSM] = useState();
  const [deletePop, setDeletePop] = useState(false);

  const popUpParamsBtmStart = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };

  const popper3 = usePopper(refStart, popStart, popUpParamsBtmStart);
  const popperStartMobile = usePopper(
    refStartMobile,
    popSM,
    popUpParamsBtmStart
  );

  // Async thunk
  const dispatch = useDispatch();

  const updateDone = useSelector(
    (state) => state.rootReducer.userInfo.updateDone
  );

  const selectChangeHandler = (e) => {
    setTenure(e.target.value);
    const data = {
      cartId: props?.id,
      startDate: startDateValue,
      endDate: new Date(
        startDateValue.getTime() + 1000 * 3600 * 24 * parseInt(e.target.value)
      ),
      deliveryTime: deliveryTime,
    };
    dispatch(updateUserCartSlice(data));
  };

  useEffect(() => {
    setEndDateValue(
      new Date(startDateValue.getTime() + 1000 * 3600 * 24 * parseInt(tenure))
    );
  }, [startDateValue, tenure]);

  useEffect(() => {
    setTenure(getNoOfDays(props?.startDateUnix, props?.endDateUnix));
  }, [props?.id]);

  return (
    <>
      {/*-------------------------------------------------- mobile ui --------------------------------------------------*/}
      <div className="mycart-card-mobile">
        <div className="mycart-card-product-img">
          <img src={props?.imgSrc?.image?.[0]} alt="playstation" />
        </div>
        <p id="mycart-order-content">{props?.packageName?.name}</p>
        <p id="mycart-card-tenure">
          Tenure
          <span style={{ marginTop: "0rem" }}>
            {
              <select
                className="custom-select"
                onChange={(e) => selectChangeHandler(e)}
              >
                {props?.packageName?.customPlans?.map((data) => (
                  <option
                    value={data?.duration}
                    selected={tenure == data.duration}
                  >
                    {getDuration(data?.duration)}
                  </option>
                ))}
              </select>
            }
          </span>
        </p>
        <p id="mycart-card-pricing">
          <span id="mycart-card-price">
            {"₹ "}
            {props?.total}
          </span>

          <div id="package-details" onClick={""}>Package details</div>
        </p>{" "}
        <p id="mycart-card-delivery">
          Start date
          <span
            onClick={() => {
              setStartDate(!startDate);
            }}
            ref={setRefStartMobile}
          >
            {dateFormatter(startDateValue)}{" "}
            <img src={DownArrow} alt="downArrow" />
          </span>
        </p>
        <div className="mycart-checkout-btn">
          <Link to={`/deliveryaddress/${props?.id}`}>
            <button
              disabled={
                validOrderStartDate(props?.startDateUnix) ? true : false
              }
            >
              checkout →
            </button>
          </Link>
        </div>
        <div className="mycart-order-edit">
          {/* <img src={GradientEdit} alt="edit" style={{ marginRight: ".2rem" }} />{" "} */}
          <img
            src={RedDelete}
            alt="delete"
            // onClick={() => dispatch(deleteUserCartSlice(props.id))}
            onClick={() => setDeletePop(true)}
          />
        </div>
      </div>

      {/* ----------------------------------------------------Pc----------------------------------------------------------  */}
      <div className="mycart-card" style={{ marginTop: "1.75rem" }}>
        <div className="mycart-card-product-img">
          <img src={props?.imgSrc?.image[0]} alt="playstation" />
        </div>
        <div className="mycart-card-desc">
          <p id="mycart-order-content">{props?.packageName?.name}</p>
          <p id="mycart-order-addons">
            Add ons:{" "}
            {props?.addons?.slice(0, 3)?.map((data, idx) => (
              <span style={{ marginLeft: ".75rem", marginTop: "0.75rem" }}>
                {data.addonName}
              </span>
            ))}
            {props?.addons?.length > 0 ? (
              <span> . . .</span>
            ) : (
              <span>No addons selected !</span>
            )}
          </p>
          <div className="mycart-card-shipping-details">
            <p id="mycart-card-pricing">
              <span id="mycart-card-price">
                <img src={Rupees} alt="ruppees" />
                {props.total}
              </span>

              <div id="package-details">
                <img
                  src={PriceInfo}
                  alt="priceInfo"
                  style={{
                    marginRight: ".5rem",
                    width: "0.938rem",
                    height: "0.938rem",
                  }}
                />
                Package details
              </div>
            </p>

            <p id="mycart-card-delivery" ref={setRefStart}>
              Start date
              <span onClick={() => setStartDate(!startDate)}>
                {dateFormatter(startDateValue)}{" "}
                <img src={DownArrow} alt="downArrow" />
              </span>
            </p>

            <p id="mycart-card-subscription-enddate">
              End date
              <span>{dateFormatter(endDateValue)}</span>
            </p>

            <p id="mycart-card-tenure">
              Tenure
              <span style={{ marginTop: "0rem" }}>
                {
                  <select
                    className="custom-select"
                    onChange={(e) => selectChangeHandler(e)}
                  >
                    {props?.packageName?.customPlans?.map((data) => (
                      <option
                        value={data?.duration}
                        selected={tenure == data.duration}
                      >
                        {getDuration(data?.duration)}
                      </option>
                    ))}
                  </select>
                }
              </span>
            </p>
          </div>
        </div>
        {/* 
        {console.log(startDateValue.toDateString())}
        {console.log(today.toDateString())} */}
        {/* {console.log(today.toDateString() - startDateValue.toDateString()===0)} */}
        {/* {console.log(today.toDateString()-startDateValue.toDateString()===0)} */}
        <PopUp
          display={startDate && !window.phone}
          callback={setStartDate}
          popper={popper3}
        >
          <div
            ref={setPopStart}
            style={{ ...popper3?.styles?.popper }}
            {...popper3?.attributes}
          >
            <div className="product-details-start-date-container">
              <DatePicker
                disbaleBeforeDate={today}
                date={startDateValue}
                setDate={setStartDateValue}
              />
              <div className="date-picker-bottom-container">
                <div className="date-picker-left-container">
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setDeliveryTime(JSON.parse(e.target.value));
                    }}
                  // value={deliveryTime}
                  >
                    <option
                      value={JSON.stringify({
                        startHour: 10,
                        endHour: 12,
                      })}
                      // selected={
                      //   deliveryTime?.startHour == 10 &&
                      //   deliveryTime?.endHour == 12
                      // }
                      onClick={() => setDeliveryTime(deliveryTime)}
                      disabled={
                        (today >= startDateValue && todayHour >= 8) ? true : false
                      }
                    >
                      10:00am - 12:00pm
                    </option>
                    <option
                      value={JSON.stringify({
                        startHour: 12,
                        endHour: 14,
                      })}
                      // selected={
                      //   deliveryTime?.startHour == 12 &&
                      //   deliveryTime?.endHour == 14
                      // }
                      onClick={() => setDeliveryTime(deliveryTime)}
                      disabled={
                        (today >= startDateValue && todayHour >= 10) ? true : false
                      }
                    >
                      12:00pm - 2:00pm
                    </option>
                    <option
                      value={JSON.stringify({
                        startHour: 14,
                        endHour: 16,
                      })}
                      // selected={
                      //   deliveryTime?.startHour == 14 &&
                      //   deliveryTime?.endHour == 16
                      // }
                      onClick={() => setDeliveryTime(deliveryTime)}
                      disabled={
                        (today >= startDateValue && todayHour >= 12) ? true : false
                      }
                    >
                      2:00pm - 4:00pm
                    </option>
                    <option
                      value={JSON.stringify({
                        startHour: 16,
                        endHour: 18,
                      })}
                      // selected={
                      //   deliveryTime?.startHour == 16 &&
                      //   deliveryTime?.endHour == 18
                      // }
                      onClick={() => setDeliveryTime(deliveryTime)}
                      disabled={
                        (today >= startDateValue && todayHour) >= 14 ? true : false
                      }
                    >
                      4:00pm - 6:00pm
                    </option>
                    <option
                      value={JSON.stringify({
                        startHour: 18,
                        endHour: 20,
                      })}
                      // selected={
                      //   deliveryTime?.startHour == 18 &&
                      //   deliveryTime?.endHour == 20
                      // }
                      onClick={() => setDeliveryTime(deliveryTime)}
                      disabled={
                        (today >= startDateValue && todayHour >= 16) ? true : false
                      }
                    >
                      6:00pm - 8:00pm
                    </option>
                  </select>
                </div>
                <button
                  onClick={() => {
                    const data = {
                      cartId: props?.id,
                      startDate: startDateValue,
                      endDate: endDateValue,
                      deliveryTime: deliveryTime,
                    };
                    setStartDate(!startDate);
                    setDeliveryTime(deliveryTime);
                    dispatch(updateUserCartSlice(data)).then((data) => {
                      setStartDate(!startDate);
                    });
                  }}
                >
                  Set Date
                </button>
              </div>
            </div>
          </div>
        </PopUp>

        <div className="mycart-checkout-container">
          <div className="mycart-order-edit">
            <p>
              {/* <div className="edit-delete-button" id="edit-button-content">
                <img
                  src={GradientEdit}
                  alt="edit"
                  style={{ marginRight: ".2rem" }}
                />{" "}
              </div> */}
              <div
                id="delete-button-content"
                className="edit-delete-button"
                onClick={() => setDeletePop(true)}
                style={{ marginRight: ".2rem" }}
              >
                <img src={RedDelete} alt="delete" />
              </div>
              {deletePop && (
                <PopUp
                  center={true}
                  callback={setDeletePop}
                  display={deletePop}
                  key={"delete"}
                >
                  <div className="use-credit-popup-container">
                    <div className="use-credit-popup-text-container">
                      <p className="use-credit-popup-text">
                        Do you want to delete this cart ?
                      </p>
                    </div>

                    <div className="use-credit-options-container">
                      <button
                        id="credit-confirm-popup-no"
                        onClick={() => {
                          dispatch(deleteUserCartSlice(props?.id));
                          setDeletePop(false);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        id="credit-confirm-popup-yes"
                        onClick={() => {
                          setDeletePop(false);
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </PopUp>
              )}
            </p>
          </div>

          <div className="mycart-checkout-btn">
            <Link to={`/deliveryaddress/${props?.id}`} deliveryTime={deliveryTime}>
              <button
                // disabled={
                //   validOrderStartDate(props?.startDateUnix) ? true : false
                // }
                disabled={validOrderStartDate(props?.startDateUnix)}
              >
                checkout →
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default Mycartcard;