import "./css/root.css";
import Test from "./pages/Test/Test.jsx";

import {
  ABOUT_US,
  ADMIN_ACCESS,
  ADMIN_CONTACT_US,
  ADMIN_CONTACT_US_A,
  ADMIN_CONTACT_US_EVENT_REQ,
  ADMIN_CONTACT_US_SEARCH_REQ,
  ADMIN_CONTACT_US_SEARCH_SAVE_REQ,
  ADMIN_CONTACT_US_WAIT_LIST,
  ADMIN_DASHBOARD,
  ADMIN_DATA,
  ADMIN_DATA_DOWNLOAD,
  ADMIN_DATA_DOWNLOAD_NEW,
  ADMIN_FRONTEND,
  ADMIN_FRONTEND_ADDONS,
  ADMIN_FRONTEND_BANNER,
  ADMIN_FRONTEND_BLOGS,
  ADMIN_FRONTEND_BLOG_REQ,
  ADMIN_FRONTEND_BLOG_REQ_READ,
  ADMIN_FRONTEND_COUPONS,
  ADMIN_FRONTEND_CREATE_ADDONS,
  ADMIN_FRONTEND_CREATE_BLOG,
  // ADMIN_FRONTEND_CREATE_BLOGS,
  ADMIN_FRONTEND_EDIT_ADDONS,
  ADMIN_FRONTEND_EDIT_BLOG,
  // ADMIN_FRONTEND_EDIt_ADDONS,
  // ADMIN_FRONTEND_EDIt_BLOG,
  ADMIN_FRONTEND_FAQS,
  ADMIN_FRONTEND_FAQS_DETAILS,
  ADMIN_FRONTEND_PACKAGE_DETAILS,
  ADMIN_FRONTEND_POST_BLOG,
  ADMIN_FRONTEND_TRENDING_NOW,
  // ADMIN_FRONTEND_TRENDING_NOW_PACKAGE_DETAILS,
  ADMIN_FRONTEND_TRENDING_TAG,
  ADMIN_FRONTEND_VIEW_PACKAGE,
  ADMIN_FRONTEND_VIEW_PACKAGE_CATEGORY,
  ADMIN_INV,
  ADMIN_INV_CATEGORY,
  ADMIN_INV_CATEGORY_PROD_MORE_INFO,
  ADMIN_INV_LEND_CANCEL,
  ADMIN_INV_LEND_CANCEL_MORE_INFO,
  ADMIN_INV_LIST_REQ,
  ADMIN_INV_LIST_REQ_MORE_INFO,
  ADMIN_INV_MAINTAIN_LIST,
  ADMIN_INV_MAINTAIN_LIST_MORE_INFO,
  ADMIN_INV_PROD_AVAIL,
  ADMIN_INV_PROD_AVAIL_MORE_INFO,
  ADMIN_LOGIN,
  ADMIN_ORDER,
  // ADMIN_ORDER_CANCEL,
  ADMIN_ORDER_CANCEL_REQ,
  ADMIN_ORDER_CANCEL_REQ_MORE_INFO,
  ADMIN_ORDER_COMPLETED_ORDER,
  ADMIN_ORDER_DAILY_LOG,
  ADMIN_ORDER_DAILY_LOG_DELI_MORE_INFO,
  // ADMIN_ORDER_DAILY_LOG_MORE_INFO,
  ADMIN_ORDER_DAILY_LOG_PICK_MORE_INFO,
  ADMIN_ORDER_DISAPPROVED_ORDER,
  ADMIN_ORDER_DISAPPROVED_ORDER_MORE_INFO,
  ADMIN_ORDER_EXPIRED_ORDER,
  ADMIN_ORDER_EXT_MORE_INFO,
  ADMIN_ORDER_EXT_REQ,
  ADMIN_ORDER_LONG,
  ADMIN_ORDER_LONG_MORE_INFO,
  ADMIN_ORDER_NEW_ORDER,
  ADMIN_ORDER_NEW_ORDER_EDIT_INFO,
  ADMIN_ORDER_NEW_ORDER_MORE_INFO,
  ADMIN_ORDER_REUPLOAD_ORDER,
  ADMIN_ORDER_SHORT,
  ADMIN_ORDER_SHORT_MORE_INFO,
  ADMIN_ORDER_USER_PAY,
  ADMIN_ORDER_USER_PAY_MORE_INFO,
  ADMIN_OTP,
  ADMIN_PRODUCT_SUP,
  ADMIN_PRODUCT_SUP_MORE_INFO,
  ADMIN_ROOT,
  ADMIN_USERS,
  ADMIN_USERS_MORE_INFO,
  BLOG,
  CATEGORIES,
  CONTACT_US,
  DELIVERY_ADDRESS,
  FAQS,
  FOR_BUSINESS,
  FOR_EVENT,
  HOME,
  LISTING_HOME,
  LOGIN,
  MYORDERS,
  MY_CART,
  MY_CREDIT,
  MY_LISTING,
  MY_PROFILE,
  ORDER_PAYMENT,
  // OTP,
  POLICY,
  TERMANDCONDITION,
  PRELAUNCH_LOGIN,
  PRODUCT_DETAILS,
  READ_BLOG,
  REGISTERATION,
  SUBMIT_BLOG,
  USER_ROOT,
  USER_SEARCH_RESULTS,
} from "./constants/links";
import {
  Footer,
  LoadingPage,
  Navbar,
  // Navbar
} from "./components";
import {
  // Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import AdminLayout from "./layouts/AdminLayout/AdminLayout";
import AdminPageNotFound from "./pages/PageNorFound/Adminpagenotfound";
import Adminfrontendtrendingtag from "./pages/Admin/Adminfrontendtrendingtag/Adminfrontendtrendingtag";
import Home from "../src/pages/Home/home";
import Homeredirect from "./pages/Homeredirect/Homeredirect";
import PageNotFound from "./pages/PageNorFound/Pagenotfound";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import React from "react";
import ScrollToTop from "./ScrollToTop";
import UserLayout from "./layouts/UserLayout/UserLayout";
import OrderView from "./pages/Admin/OrderView/OrderView.jsx";
import RentkarForEvents from "./pages/EventPage/RentkarForEvents.jsx";
import Bedroom from "./pages/Dealhouse/Bedroom/Bedroom.jsx";
import Gaming from "./pages/Dealhouse/Gamingroom/Gaming.jsx";
import Studio from "./pages/Dealhouse/Studio/Studio.jsx";
import Dealhouse from "./pages/Dealhouse/Dealhouse/Dealhouse.jsx";
// import Test from "./pages/Test/Test.jsx";

// const Homeredirect = React.lazy(() =>
//   import("./pages/Homeredirect/Homeredirect")
// );
// const Home = React.lazy(() => import("../src/pages/Home/home"));
// -------------------------------------------------
const PaymentPage = React.lazy(() => import("./pages/PaymentPage/PaymentPage"));
// import Viewpackages from "./pages/Admin/Viewpackages/viewpackages";
const Viewpackages = React.lazy(() =>
  import("./pages/Admin/Viewpackages/viewpackages")
);

// import Userspayment from "./pages/Admin/Adminuserpayment/userspayment";
const Userspayment = React.lazy(() =>
  import("./pages/Admin/Adminuserpayment/userspayment")
);

// import Usersmoreinfo from "./pages/Admin/Adminusersmoreinfo/Usersmoreinfo";
const Usersmoreinfo = React.lazy(() =>
  import("./pages/Admin/Adminusersmoreinfo/Usersmoreinfo")
);

// import UsersData from "./pages/Admin/AdminUserDatadownload/usersData";
const UsersData = React.lazy(() =>
  import("./pages/Admin/AdminUserDatadownload/usersData")
);

// import Users from "./pages/Admin/Adminusers/Users";
const Users = React.lazy(() => import("./pages/Admin/Adminusers/Users"));

// import UserApp from "./UserApp";
// import Userpaymentmoreinfo from "./pages/Admin/Adminuserpaymentmoreinfo/userpaymentmoreinfo";
const Userpaymentmoreinfo = React.lazy(() =>
  import("./pages/Admin/Adminuserpaymentmoreinfo/userpaymentmoreinfo")
);

// import Submitblog from "./pages/Submitblog/submitblog";
const Submitblog = React.lazy(() => import("./pages/Submitblog/submitblog"));

// import Shorttermmoreinfo from "./pages/Admin/Adminshorttermmoreinfo/shorttermmoreinfo";
// const Shorttermmoreinfo = React.lazy(() =>
//   import("./pages/Admin/Adminshorttermmoreinfo/shorttermmoreinfo")
// );

// import Shortterm from "./pages/Admin/Adminshortterm/shortterm";
const Shortterm = React.lazy(() =>
  import("./pages/Admin/Adminshortterm/shortterm")
);

// import Searchsavedreq from "./pages/Admin/Adminsearchsavedreq/Searchsavedreq";
const Searchsavedreq = React.lazy(() =>
  import("./pages/Admin/Adminsearchsavedreq/Searchsavedreq")
);

// import Searchreq from "./pages/Admin/Adminsearchreq/Searchreq";
const Searchreq = React.lazy(() =>
  import("./pages/Admin/Adminsearchreq/Searchreq")
);

// import Readblog from "./pages/Readblog/readblog";
const Readblog = React.lazy(() => import("./pages/Readblog/readblog"));

// import Productsupportmore from "./pages/Admin/Adminproductsupportmore/Productsupportmore";
const Productsupportmore = React.lazy(() =>
  import("./pages/Admin/Adminproductsupportmore/Productsupportmore")
);

// new search page
const NewSearchPage = React.lazy(() =>
  import("./pages/NewSearchPage/SearchPage")
);

// import Productsupport from "./pages/Admin/Adminproductsupport/Productsupport";
const Productsupport = React.lazy(() =>
  import("./pages/Admin/Adminproductsupport/Productsupport")
);

// import Productinfo from "./pages/Admin/ProductInfo/productinfo";
const Productinfo = React.lazy(() =>
  import("./pages/Admin/ProductInfo/productinfo")
);

// import Productavailablity from "./pages/Admin/Productavailablity/productavailablity";
const Productavailablity = React.lazy(() =>
  import("./pages/Admin/Productavailablity/productavailablity")
);

// import Policy from "./pages/Policy/policy";
const Policy = React.lazy(() => import("./pages/Policy/policy"));

// import Term and Condition From "./pages/TermAndCondition/termandcondition"
const TermAndCondition = React.lazy(() =>
  import("./pages/TermAndCondition/termandcondition")
);
// import Pickupmoreinfo from "./pages/Admin/Adminlogisticpickupmoreinfo/pickupmoreinfo";
const Pickupmoreinfo = React.lazy(() =>
  import("./pages/Admin/Adminlogisticpickupmoreinfo/pickupmoreinfo")
);

// import Payment from "./pages/Payment/payment";
// const Payment = React.lazy(() => import("./pages/Payment/payment"));

// import Ordermoreinfo from "./pages/Admin/Adminordermoreinformation/ordermoreinfo";
const Ordermoreinfo = React.lazy(() =>
  import("./pages/Admin/Adminordermoreinformation/ordermoreinfo")
);

// import Ordercancellationreq from "./pages/Admin/Adminordercancellation/ordercancellationreq";
const Ordercancellationreq = React.lazy(() =>
  import("./pages/Admin/Adminordercancellation/ordercancellationreq")
);

// import Ordercancellationmore from "./pages/Admin/Adminordercancellationmore/ordercancellationmore";
const Ordercancellationmore = React.lazy(() =>
  import("./pages/Admin/Adminordercancellationmore/ordercancellationmore")
);

// import Myprofile from "./pages/Myprofile/myprofile";
const Myprofile = React.lazy(() => import("./pages/Myprofile/myprofile"));

// import Myorder from "./pages/Myorder/myorder";
const Myorder = React.lazy(() => import("./pages/Myorder/myorder"));

// import Mylisting from "./pages/Mylisting/mylisting";
const Mylisting = React.lazy(() => import("./pages/Mylisting/mylisting"));

// import Mycart from "./pages/Mycart/mycart";
const Mycart = React.lazy(() => import("./pages/Mycart/mycart"));

// import Mobileredeemcoupon from "./pages/Mobileredeemcoupon/mobileredeemcoupon";
// const Mobileredeemcoupon = React.lazy(() =>
//   import("./pages/Mobileredeemcoupon/mobileredeemcoupon")
// );

// import Mobilepaidaddons from "./pages/Mobilepaidaddons/mobilepaidaddons";
// const Mobilepaidaddons = React.lazy(() =>
//   import("./pages/Mobilepaidaddons/mobilepaidaddons")
// );

// import Mobileordersummary from "./pages/Mobileordersummary/mobileordersummary";
// const Mobileordersummary = React.lazy(() =>
//   import("./pages/Mobileordersummary/mobileordersummary")
// );

// import Mobilemyorderproductsupp from "./pages/Mobilemyorderproductsupp/mobilemyorderproductsupp";
// const Mobilemyorderproductsupp = React.lazy(() =>
//   import("./pages/Mobilemyorderproductsupp/mobilemyorderproductsupp")
// );

// import Mobilemyorderproductcancel from "./pages/Mobilemyorderproductcancel/mobilemyorderproductcancel";
// const Mobilemyorderproductcancel = React.lazy(() =>
//   import("./pages/Mobilemyorderproductcancel/mobilemyorderproductcancel")
// );

// import Mobilemyorderextend from "./pages/Mobilemyorderextend/mobilemyorderextend";
// const Mobilemyorderextend = React.lazy(() =>
//   import("./pages/Mobilemyorderextend/mobilemyorderextend")
// );

// import Mobilemynewlistingpayment from "./pages/Mobilemynewlistingpayment/mobilemynewlistingpayment";
// const Mobilemynewlistingpayment = React.lazy(() =>
//   import("./pages/Mobilemynewlistingpayment/mobilemynewlistingpayment")
// );

// import Mobilemynewlistingagreement from "./pages/Mobilemynewlistingagreement/mobilemynewlistingagreement";
// const Mobilemynewlistingagreement = React.lazy(
//   "./pages/Mobilemynewlistingagreement/mobilemynewlistingagreement"
// );

// import Mobilemynewlisting from "./pages/Mobilemynewlisting/mobilemynewlisting";
// const Mobilemynewlisting = React.lazy(() =>
//   import("./pages/Mobilemynewlisting/mobilemynewlisting")
// );

// import Mobilemylistingcancellation from "./pages/Mobilemylistingcancellation/mobilemylistingcancellation";
// const Mobilemylistingcancellation = React.lazy(() =>
//   import("./pages/Mobilemylistingcancellation/mobilemylistingcancellation")
// );

// import Mobileaddons from "./pages/Mobileaddons/mobileaddons";
// const Mobileaddons = React.lazy(() =>
//   import("./pages/Mobileaddons/mobileaddons")
// );

// import Maintenancelist from "./pages/Admin/Maintenancelist/maintenancelist";
const Maintenancelist = React.lazy(() =>
  import("./pages/Admin/Maintenancelist/maintenancelist")
);

// import Maintainproddetails from "./pages/Admin/Productdetails/productdetails";
const Maintainproddetails = React.lazy(() =>
  import("./pages/Admin/Productdetails/productdetails")
);

// import Longtermpanel from "./pages/Admin/Adminlongterm/longtermpanel";
const Longtermpanel = React.lazy(() =>
  import("./pages/Admin/Adminlongterm/longtermpanel")
);

// import Listinghome from "./pages/Listinghome/listinghome";
const Listinghome = React.lazy(() => import("./pages/Listinghome/listinghome"));

// import Listedinventory from "./pages/Admin/Listedinventory/listedinventory";
const Listedinventory = React.lazy(() =>
  import("./pages/Admin/Listedinventory/listedinventory")
);

// import Individualpurchase from "./pages/Individualpurchase/individualpurchase";
// const Individualpurchase = React.lazy(() =>
//   import("./pages/Individualpurchase/individualpurchase")
// );
// import Lendingcanceldetails from "./pages/Admin/Lendingcanceldetails/lendingcanceldetails";
const Lendingcanceldetails = React.lazy(() =>
  import("./pages/Admin/Lendingcanceldetails/lendingcanceldetails")
);

// import Frontendcatogerygaming from "./pages/Admin/Frontendcatogerygaming/frontendcatogerygaming";
const Frontendcatogerygaming = React.lazy(() =>
  import("./pages/Admin/Frontendcatogerygaming/frontendcatogerygaming")
);

// import Faqs from "./pages/FAQs/faqs";
const Faqs = React.lazy(() => import("./pages/FAQs/faqs"));

// import Extensionreqmore from "./pages/Admin/Adminextensionmore/extensionreqmore";
const Extensionreqmore = React.lazy(() =>
  import("./pages/Admin/Adminextensionmore/extensionreqmore")
);

// import Extensionreq from "./pages/Admin/Adminextensionreq/extensionreq";
const Extensionreq = React.lazy(() =>
  import("./pages/Admin/Adminextensionreq/extensionreq")
);

// import Eventrequest from "./pages/Admin/Admineventreq/Eventrequest";
const Eventrequest = React.lazy(() =>
  import("./pages/Admin/Admineventreq/Eventrequest")
);

// import Disapprovedordermoreinfo from "./pages/Admin/Admindisapprovedordermore/disapprovedordermoreinfo";
const Disapprovedordermoreinfo = React.lazy(() =>
  import("./pages/Admin/Admindisapprovedordermore/disapprovedordermoreinfo")
);

// import Disapprovedorder from "./pages/Admin/Admindisapproved/disapprovedorder";
const Disapprovedorder = React.lazy(() =>
  import("./pages/Admin/Admindisapproved/disapprovedorder")
);

// import Deliveryaddress from "./pages/Deliveryaddress/deliveryaddress";
const Deliveryaddress = React.lazy(() =>
  import("./pages/Deliveryaddress/deliveryaddress")
);
// import Deliverymoreinfo from "./pages/Admin/AdminLogisticDeliveryMore/deliverymoreinfo";
const Deliverymoreinfo = React.lazy(() =>
  import("./pages/Admin/AdminLogisticDeliveryMore/deliverymoreinfo")
);

// import Datadownload from "./pages/Admin/Admindatadownload/Datadownload";
const Datadownload = React.lazy(() =>
  import("./pages/Admin/Admindatadownload/Datadownload")
);

// import Dailylogistic from "./pages/Admin/Admindailylogistic/dailylogistic";
const Dailylogistic = React.lazy(() =>
  import("./pages/Admin/Admindailylogistic/dailylogistic")
);

// import Creditreferal from "./pages/Creditrefral/creditreferal";
const Creditreferal = React.lazy(() =>
  import("./pages/Creditrefral/creditreferal")
);

// import Contactus from "./pages/Contactus/contactus";
const Contactus = React.lazy(() => import("./pages/Contactus/contactus"));

// import Companypurchase from "./pages/Companypurchase/companypurchase";
// const Companypurchase = React.lazy(() =>
//   import("./pages/Companypurchase/companypurchase")
// );

// import Catogeryproddetails from "./pages/Admin/Catogeryproductdetails/catogeryproductdetails";
const Catogeryproddetails = React.lazy(() =>
  import("./pages/Admin/Catogeryproductdetails/catogeryproductdetails")
);

// import Catogeriesinventory from "./pages/Admin/Catogeriesinventory/catogeriesinventory";
const Catogeriesinventory = React.lazy(() =>
  import("./pages/Admin/Catogeriesinventory/catogeriesinventory")
);

// import Calculator from "./pages/Calculator/calculator";
// const Calculator = React.lazy(() => import("./pages/Calculator/calculator"));

// import Businessrequest from "./pages/Admin/Admincontactinfo/Businessrequest";
const Businessrequest = React.lazy(() =>
  import("./pages/Admin/Admincontactinfo/Businessrequest")
);

// import Businesspage from "./pages/Businesspage/businesspage";
const Businesspage = React.lazy(() =>
  import("./pages/Businesspage/businesspage")
);

// import Blogrequest from "./pages/Admin/Adminblogrequest/blogrequest";
const Blogrequest = React.lazy(() =>
  import("./pages/Admin/Adminblogrequest/blogrequest")
);

// import Agreement from "./pages/Agreement/agreement";
// const Agreement = React.lazy(() => import("./pages/Agreement/agreement"));
// import Blog from "./pages/Blog/blog";
const Blog = React.lazy(() => import("./pages/Blog/blog"));

// import Adminreqdetails from "./pages/Admin/Adminreqdetails/adminreqdetails";
const Adminreqdetails = React.lazy(() =>
  import("./pages/Admin/Adminreqdetails/adminreqdetails")
);

// import Adminpostblog from "./pages/Admin/Adminpostblog/adminpostblog";
const Adminpostblog = React.lazy(() =>
  import("./pages/Admin/Adminpostblog/adminpostblog")
);

// import Adminpaneldata from "./pages/Admin/Adminpaneldata/adminpaneldata";
const Adminpaneldata = React.lazy(() =>
  import("./pages/Admin/Adminpaneldata/adminpaneldata")
);

// import Adminotp from "./pages/Admin/Adminotp/adminotp";
const Adminotp = React.lazy(() => import("./pages/Admin/Adminotp/adminotp"));

// import Adminordereditinformation from "./pages/Admin/Adminordereditinformation/adminordereditinformation";
const Adminordereditinformation = React.lazy(() =>
  import("./pages/Admin/Adminordereditinformation/adminordereditinformation")
);

// import Adminorder from "./pages/Admin/Adminorder/adminorder";
const Adminorder = React.lazy(() =>
  import("./pages/Admin/Adminorder/adminorder")
);

// import Adminlongtermmoreinfo from "./pages/Admin/AdminLongtermmoreinfo/adminlongtermmoreinfo";
const Adminlongtermmoreinfo = React.lazy(() =>
  import("./pages/Admin/AdminLongtermmoreinfo/adminlongtermmoreinfo")
);

// import Adminlogin from "./pages/Admin/Adminlogin/adminlogin";
const Adminlogin = React.lazy(() =>
  import("./pages/Admin/Adminlogin/adminlogin")
);

// import Adminlistingreq from "./pages/Admin/Adminlistingreq/adminlistingreq";
const Adminlistingreq = React.lazy(() =>
  import("./pages/Admin/Adminlistingreq/adminlistingreq")
);

// import Adminlendingcancellation from "./pages/Admin/Adminlendingcancellation/adminlendingcancellation";
const Adminlendingcancellation = React.lazy(() =>
  import("./pages/Admin/Adminlendingcancellation/adminlendingcancellation")
);

// import Adminfrontendtrendingpackage from "./pages/Admin/Adminfrontendtrendingpackage/adminfrontendtrendingpackage";
// const Adminfrontendtrendingpackage = React.lazy(() =>
//   import(
//     "./pages/Admin/Adminfrontendtrendingpackage/adminfrontendtrendingpackage"
//   )
// );

// import Adminfrontendtrending from "./pages/Admin/Adminfrontendtrending/adminfrontendtrending";
const Adminfrontendtrending = React.lazy(() =>
  import("./pages/Admin/Adminfrontendtrending/adminfrontendtrending")
);

// import Adminfrontendpackagedetails from "./pages/Admin/Adminfrontendpackagedetails/adminfrontendpackagedetails";
const Adminfrontendpackagedetails = React.lazy(() =>
  import(
    "./pages/Admin/Adminfrontendpackagedetails/adminfrontendpackagedetails"
  )
);

// import Adminfrontendfaqsdetails from "./pages/Admin/Adminfrontendfaqsdetails/adminfrontendfaqsdetails";
const Adminfrontendfaqsdetails = React.lazy(() =>
  import("./pages/Admin/Adminfrontendfaqsdetails/adminfrontendfaqsdetails")
);

// import Adminfrontendeditblog from "./pages/Admin/Adminfrontendeditblog/adminfrontendeditblog";
const Adminfrontendeditblog = React.lazy(() =>
  import("./pages/Admin/Adminfrontendeditblog/adminfrontendeditblog")
);
// import Adminfrontendfaqs from "./pages/Admin/Adminfrontendfaqs/adminfrontendfaqs";
const Adminfrontendfaqs = React.lazy(() =>
  import("./pages/Admin/Adminfrontendfaqs/adminfrontendfaqs")
);

// import Adminfrontendeditaddons from "./pages/Admin/Adminfrontendeditaddons/adminfrontendeditaddons";
const Adminfrontendeditaddons = React.lazy(() =>
  import("./pages/Admin/Adminfrontendeditaddons/adminfrontendeditaddons")
);

// import Adminfrontendcreateaddons from "./pages/Admin/Adminfrontendcreateaddons/adminfrontcreateaddons";
const Adminfrontendcreateaddons = React.lazy(() =>
  import("./pages/Admin/Adminfrontendcreateaddons/adminfrontcreateaddons")
);

// import Adminfrontendblog from "./pages/Admin/Adminfrontendblog/adminfrontendblog";
const Adminfrontendblog = React.lazy(() =>
  import("./pages/Admin/Adminfrontendblog/adminfrontendblog")
);
// import Adminfrontendcoupons from "./pages/Admin/Adminfrontendcoupons/adminfrontendcoupons";
const Adminfrontendcoupons = React.lazy(() =>
  import("./pages/Admin/Adminfrontendcoupons/adminfrontendcoupons")
);

// import Adminfrontendbanner from "./pages/Admin/Adminfrontendbanner/adminfrontendbanner";
const Adminfrontendbanner = React.lazy(() =>
  import("./pages/Admin/Adminfrontendbanner/adminfrontendbanner")
);

// import Admindashboard from "./pages/Admin/Dashboard/dashboard";
const Admindashboard = React.lazy(() =>
  import("./pages/Admin/Dashboard/dashboard")
);
// import Adminfrontend from "./pages/Admin/Adminfrontend/adminfrontend";
const Adminfrontend = React.lazy(() =>
  import("./pages/Admin/Adminfrontend/adminfrontend")
);
// import Adminfrontendaddons from "./pages/Admin/Adminfrontendaddons/adminfrontendaddons";
const Adminfrontendaddons = React.lazy(() =>
  import("./pages/Admin/Adminfrontendaddons/adminfrontendaddons")
);

// import Adminaccess from "./pages/Admin/Adminaccess/adminaccess";
const Adminaccess = React.lazy(() =>
  import("./pages/Admin/Adminaccess/adminaccess")
);
// import Admincreateblog from "./pages/Admin/Adminfrontendcreateblog/adminfrontendcreateblog";
const Admincreateblog = React.lazy(() =>
  import("./pages/Admin/Adminfrontendcreateblog/adminfrontendcreateblog")
);

// import Acontactus from "./pages/Admin/Admincontactus/Acontactus";
const Acontactus = React.lazy(() =>
  import("./pages/Admin/Admincontactus/Acontactus")
);
// import AdminReadblog from "./pages/Admin/Adminreadblog/readblog";
const AdminReadblog = React.lazy(() =>
  import("./pages/Admin/Adminreadblog/readblog")
);

// import prelauch from
const PreLaunchAuth = React.lazy(() =>
  import("../src/pages/preLaunchPage/prelaunchpage")
);

// import Login from "../src/pages/Login/login";
// const Login = React.lazy(() => import("../src/pages/Login/login"));

// import Otp from "./pages/Otp/otp";
// const Otp = React.lazy(() => import("./pages/Otp/otp"));

// import Registeration from "./pages/Registeration/registeration";
const Registeration = React.lazy(() =>
  import("./pages/Registeration/registeration")
);

// import Catogery from "./pages/Catogery/catogery";
const Catogery = React.lazy(() => import("./pages/Catogery/catogery"));

// import Searchfound from "./pages/Searchfound/searchfound";
// const Searchfound = React.lazy(() => import("./pages/Searchfound/searchfound"));

// import Searchnotfound from "./pages/Searchnotfound/searchnotfound";
// const Searchnotfound = React.lazy(() =>
//   import("./pages/Searchnotfound/searchnotfound")
// );

// import Productdetails from "./pages/Productdetails/productdetails";
const Productdetails = React.lazy(() =>
  import("./pages/Productdetails/productdetails")
);

// import Aboutus from "./pages/Aboutus/aboutus";
const Aboutus = React.lazy(() => import("./pages/Aboutus/aboutus"));

// import Waitlist from "./pages/Admin/Adminwaitlist/Waitlist";
const Waitlist = React.lazy(() =>
  import("./pages/Admin/Adminwaitlist/Waitlist")
);

const NewOrder = React.lazy(() =>
  import("./pages/Admin/NewOrders/NewOrder.jsx")
);

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              index
              path="/test"
              element={
                // <React.Suspense fallback={<LoadingPage />}>
                <Test />
                // </React.Suspense>
              }
            />
            <Route
              index
              path={PRELAUNCH_LOGIN}
              element={
                // <React.Suspense fallback={<LoadingPage />}>
                <PreLaunchAuth />
                // </React.Suspense>
              }
            />

            <Route
              path="/dealhouse"
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <PrivateRoute>
                    <Navbar />
                    <Dealhouse paddTop={"3rem"}/>
                    <Footer />
                  </PrivateRoute>
                </React.Suspense>
              }
            />

            <Route
              path="/dealhouse/bedroom"
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <PrivateRoute>
                    <Bedroom />
                  </PrivateRoute>
                </React.Suspense>
              }
            />

            <Route
              path="/dealhouse/gamingroom"
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <PrivateRoute>
                    <Gaming />
                  </PrivateRoute>
                </React.Suspense>
              }
            />

            <Route
              path="/dealhouse/studioroom"
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <PrivateRoute>
                    <Studio />
                  </PrivateRoute>
                </React.Suspense>
              }
            />


            <Route
              path={USER_ROOT}
              element={
                // <React.Suspense fallback={<LoadingPage />}>
                <PrivateRoute>
                  <UserLayout />
                </PrivateRoute>
                // </React.Suspense>
              }
            >
              <Route
                path={USER_ROOT}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Homeredirect />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={HOME(":location")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              {/* <Route
              path={LOGIN}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <Login />
                </React.Suspense>
              }
            /> */}
              {/* <Route
              path={OTP}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <Otp />
                </React.Suspense>
              }
            /> */}

              <Route
                path={REGISTERATION}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Registeration />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={CATEGORIES(":location")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Catogery />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={PRODUCT_DETAILS(":packageId")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Productdetails />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={MY_CART}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <ProtectedRoute>
                        <Mycart />
                      </ProtectedRoute>
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={DELIVERY_ADDRESS(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Deliveryaddress />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={ORDER_PAYMENT(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <ProtectedRoute>
                        <PaymentPage />
                      </ProtectedRoute>
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={MYORDERS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <ProtectedRoute>
                        <Myorder />
                      </ProtectedRoute>
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={MY_LISTING}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <ProtectedRoute>
                        <Mylisting />
                      </ProtectedRoute>
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={FAQS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Faqs />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={CONTACT_US}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Contactus />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={LISTING_HOME}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Listinghome />
                  </React.Suspense>
                }
              />
              <Route
                path={MY_PROFILE}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <ProtectedRoute>
                        <Myprofile />
                      </ProtectedRoute>
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={BLOG}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Blog />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={POLICY}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Policy />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={TERMANDCONDITION}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <TermAndCondition />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={READ_BLOG(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Readblog />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={MY_CREDIT}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Creditreferal />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={ABOUT_US}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Aboutus />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={SUBMIT_BLOG}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Submitblog />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={FOR_BUSINESS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <Businesspage />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={FOR_EVENT}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <RentkarForEvents />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path={USER_SEARCH_RESULTS(":location", ":searchQuery")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PrivateRoute>
                      <NewSearchPage />
                    </PrivateRoute>
                  </React.Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <PageNotFound />
                  </React.Suspense>
                }
              />
            </Route>
            {/* Admin side */}
            {/* These paths do not require navbar and side bar */}
            <Route
              path={ADMIN_LOGIN}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <Adminlogin />
                </React.Suspense>
              }
            />
            <Route
              path={ADMIN_OTP}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <Adminotp />
                </React.Suspense>
              }
            />
            <Route
              path={ADMIN_ROOT}
              element={
                // <React.Suspense fallback={<LoadingPage />}>
                <AdminLayout />
                // </React.Suspense>
              }
            >
              <Route
                path={ADMIN_DASHBOARD}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Admindashboard />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminorder />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_NEW_ORDER}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <OrderView status={"Pending"} />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_REUPLOAD_ORDER}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <OrderView status={"Reupload"} />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_EXPIRED_ORDER}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <OrderView status={"Expired"} />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_COMPLETED_ORDER}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <OrderView status={"Complete"} />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_NEW_ORDER_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Ordermoreinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_NEW_ORDER_EDIT_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminordereditinformation />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Listedinventory />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_LONG}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Longtermpanel />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_LONG_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminlongtermmoreinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_SHORT}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Shortterm />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_SHORT_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    {/* <Shorttermmoreinfo /> */}
                    <Adminlongtermmoreinfo isShort={true} />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_DAILY_LOG}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Dailylogistic />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_DAILY_LOG_DELI_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Deliverymoreinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_DAILY_LOG_PICK_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Pickupmoreinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_USER_PAY}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Userspayment />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_USER_PAY_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Userpaymentmoreinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_CANCEL_REQ}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Ordercancellationreq />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_CANCEL_REQ_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Ordercancellationmore />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_LIST_REQ}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminlistingreq />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_LIST_REQ_MORE_INFO(":requestId")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminreqdetails />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_DISAPPROVED_ORDER}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Disapprovedorder />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_DISAPPROVED_ORDER_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Disapprovedordermoreinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_EXT_REQ}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Extensionreq />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ORDER_EXT_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Extensionreqmore />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_LEND_CANCEL}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminlendingcancellation />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_LEND_CANCEL_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Lendingcanceldetails />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_PROD_AVAIL}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Productavailablity />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_PROD_AVAIL_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Productinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_MAINTAIN_LIST}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Maintenancelist />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_MAINTAIN_LIST_MORE_INFO(":customId")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Maintainproddetails />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_CATEGORY}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Catogeriesinventory />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_INV_CATEGORY_PROD_MORE_INFO(":productId")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Catogeryproddetails />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_USERS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Users />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_USERS_MORE_INFO(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Usersmoreinfo />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontend />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_PRODUCT_SUP}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Productsupport />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_PRODUCT_SUP_MORE_INFO(":id", ":suppId")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Productsupportmore />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_CONTACT_US}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Businessrequest />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_CONTACT_US_SEARCH_SAVE_REQ}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Searchsavedreq />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_CONTACT_US_SEARCH_REQ}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Searchreq />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_CONTACT_US_A}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Acontactus />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_CONTACT_US_WAIT_LIST}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Waitlist />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_CONTACT_US_EVENT_REQ}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Eventrequest />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_VIEW_PACKAGE}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Viewpackages />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_VIEW_PACKAGE_CATEGORY}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Frontendcatogerygaming />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_PACKAGE_DETAILS(":packageId")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendpackagedetails />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_TRENDING_NOW}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendtrending />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_ADDONS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendaddons />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_CREATE_ADDONS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendcreateaddons />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_EDIT_ADDONS(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendeditaddons />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_DATA}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminpaneldata />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_DATA_DOWNLOAD}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Datadownload />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_ACCESS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminaccess />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_FAQS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendfaqs />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_FAQS_DETAILS(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendfaqsdetails />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_COUPONS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendcoupons />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_BANNER}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendbanner />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_TRENDING_TAG}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendtrendingtag />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_BLOGS}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendblog />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_CREATE_BLOG}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Admincreateblog />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_EDIT_BLOG}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminfrontendeditblog />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_BLOG_REQ}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Blogrequest />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_BLOG_REQ_READ(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <AdminReadblog />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_FRONTEND_POST_BLOG(":id")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <Adminpostblog />
                  </React.Suspense>
                }
              />
              <Route
                path={ADMIN_DATA_DOWNLOAD_NEW(":data")}
                element={
                  <React.Suspense fallback={<LoadingPage />}>
                    <UsersData />
                  </React.Suspense>
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <AdminPageNotFound />
                </React.Suspense>
              }
            />
          </Routes>
        </ScrollToTop>
      </Router>
      {/* =============================================================== */}

      {/* 
        <Route
            path="/:location/home"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="/otp"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Otp />
              </React.Suspense>
            }
          />
          <Route
            path="/registeration"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Registeration />
              </React.Suspense>
            }
          />
          <Route
            path="/:location/categories/"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Catogery />
              </React.Suspense>
            }
          />
          <Route
            path="/productdetails/:packageId"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Productdetails />
              </React.Suspense>
            }
          />
          <Route
            path="/mycart"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mycart />
              </React.Suspense>
            }
          />
          <Route
            path="/deliveryaddress/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Deliveryaddress />
              </React.Suspense>
            }
          />
          <Route
            path="/order_payment/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <PaymentPage />
              </React.Suspense>
            }
          />
          <Route
            path="/myorder"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Myorder />
              </React.Suspense>
            }
          />
          <Route
            path="/mylisting"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mylisting />
              </React.Suspense>
            }
          />
          <Route
            path="/Faqs"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Faqs />
              </React.Suspense>
            }
          />
          <Route
            path="/contactus"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Contactus />
              </React.Suspense>
            }
          />
          <Route
            path="/myprofile"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Myprofile />
              </React.Suspense>
            }
          />
          <Route
            path="/blog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Blog />
              </React.Suspense>
            }
          />
          <Route
            path="/policy"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Policy />
              </React.Suspense>
            }
          />
          <Route
            path="/readblog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Readblog />
              </React.Suspense>
            }
          />
          <Route
            path="/mycredit"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Creditreferal />
              </React.Suspense>
            }
          />
          <Route
            path="/aboutus"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Aboutus />
              </React.Suspense>
            }
          />
          <Route
            path="/submitblog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Submitblog />
              </React.Suspense>
            }
          />
          <Route
            path="/forbusiness"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Businesspage />
              </React.Suspense>
            }
          />
          <Route
            path="/adminlogin"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminlogin />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/ordercancellationreq"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Ordercancellationreq />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/listingrequest/requestdetails/:requestId"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminreqdetails />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/disapprovedorder"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Disapprovedorder />
              </React.Suspense>
            }
          />
          // Admin side===================================================
          <Route
            path="/adminlogin"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminlogin />
              </React.Suspense>
            }
          />
          <Route
            path="/adminotp"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminotp />
              </React.Suspense>
            }
          />
          <Route
            path="/admindashboard"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Admindashboard />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/new_order"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <NewOrder />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/new_order/moreinfo/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Ordermoreinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/new_order/moreinfo/editinformation"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminordereditinformation />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Listedinventory />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/longterm"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Longtermpanel />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/longtermmoreinfo/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminlongtermmoreinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/shortterm"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Shortterm />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/shorttermmoreinfo/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Shorttermmoreinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/dailylogistic"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Dailylogistic />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/logisticdeliverymoreinfo/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Deliverymoreinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/logisticpickupmoreinfo/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Pickupmoreinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/userspayment"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Userspayment />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/userspaymentmoreinfo"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Userpaymentmoreinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/ordercancellationreq"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Ordercancellationreq />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/ordercancellationmore/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Ordercancellationmore />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/listingrequest"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminlistingreq />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/disapprovedordermoreinfo/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Disapprovedordermoreinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/extensionreq"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Extensionreq />
              </React.Suspense>
            }
          />
          <Route
            path="/adminorder/extensionreqmoreinfo"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Extensionreqmore />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/lendingcancellation"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminlendingcancellation />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/productavailablity"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Productavailablity />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/productavailablity/productinfo"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Productinfo />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/maintenancelist/maintenanceproddetails/:customId"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Maintainproddetails />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/catogerygaming"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Catogeriesinventory />
              </React.Suspense>
            }
          />
          <Route
            path="/admininventory/catogerygaming/catogeryproddetails/:productId"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Catogeryproddetails />
              </React.Suspense>
            }
          />
          <Route
            path="/adminusers"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Users />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontend />
              </React.Suspense>
            }
          />
          <Route
            path="/adminproductsupport"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Productsupport />
              </React.Suspense>
            }
          />
          <Route
            path="/adminproductsupport/productsupportmoreinfo"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Productsupportmore />
              </React.Suspense>
            }
          />
          <Route
            path="/admincontactus"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Businessrequest />
              </React.Suspense>
            }
          />
          <Route
            path="/admincontactus/searchsavedreq"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Searchsavedreq />
              </React.Suspense>
            }
          />
          <Route
            path="/admincontactus/searchreq"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Searchreq />
              </React.Suspense>
            }
          />
          <Route
            path="/admincontactus/acontactus"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Acontactus />
              </React.Suspense>
            }
          />
          <Route
            path="/admincontactus/waitlist"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Waitlist />
              </React.Suspense>
            }
          />
          <Route
            path="/admincontactus/eventreq"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Eventrequest />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/adminviewpackages"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Viewpackages />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/adminviewpackages/frontendcatogerygaming"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Frontendcatogerygaming />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/adminviewpackages/general_category"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Frontendcatogerygaming />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/adminviewpackages/frontendcatogerygaming/packagedetails/:packageId"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendpackagedetails />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/addons"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendaddons />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/addons/createaddons"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendcreateaddons />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/addons/editaddons/"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendeditaddons />
              </React.Suspense>
            }
          />
          <Route
            path="/admindata/datadownload"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Datadownload />
              </React.Suspense>
            }
          />
          <Route
            path="/admindata"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminpaneldata />
              </React.Suspense>
            }
          />
          <Route
            path="/adminaccess"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminaccess />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/faqs"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendfaqs />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/faqs/faqsdetails/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendfaqsdetails />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/coupons"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendcoupons />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/adminblog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendblog />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/admincreateblog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Admincreateblog />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/adminblog/admineditblog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendeditblog />
              </React.Suspense>
            }
          />
          <Route
            path="/adminblogrequest"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Blogrequest />
              </React.Suspense>
            }
          />
          <Route
            path="/adminblogrequest/readblog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <AdminReadblog />
              </React.Suspense>
            }
          />
          <Route
            path="/adminblogrequest/postblog"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminpostblog />
              </React.Suspense>
            }
          />
          <Route
            path="/admindata/allUserDataDownload"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <UsersData />
              </React.Suspense>
            }
          />
        <Route
            path="/searchresultfound"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Searchfound />
              </React.Suspense>
            }
          />
         */}
      {/* =============================================================== */}
      {/* <Routes>
          <Route
            index
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Homeredirect />
              </React.Suspense>
            }
          />
          <Route
            path="/searchresultfound"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Searchfound />
              </React.Suspense>
            }
          />
          <Route
            path="/searchresultnotfound"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Searchnotfound />
              </React.Suspense>
            }
          />
          <Route
            path="/freeaddons"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobileaddons />
              </React.Suspense>
            }
          />
          <Route
            path="/paidaddons"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilepaidaddons />
              </React.Suspense>
            }
          />
          <Route
            path="/redeemcoupon"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobileredeemcoupon />
              </React.Suspense>
            }
          />
          <Route
            path="/payment/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Payment />
              </React.Suspense>
            }
          />
          <Route
            path="/agreement/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Agreement />
              </React.Suspense>
            }
          />
          <Route
            path="/individualpurchase/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Individualpurchase />
              </React.Suspense>
            }
          />
          <Route
            path="/companypurchase/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Companypurchase />
              </React.Suspense>
            }
          />
          <Route
            path="/ordersummary/:id"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobileordersummary />
              </React.Suspense>
            }
          />
          <Route
            path="/mobilemyorderextend"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilemyorderextend />
              </React.Suspense>
            }
          />
          <Route
            path="/mobilemyorderproductsupp"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilemyorderproductsupp />
              </React.Suspense>
            }
          />
          <Route
            path="/mobilemyorderproductcancel"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilemyorderproductcancel />
              </React.Suspense>
            }
          />
          <Route
            path="/listinghome"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Listinghome />
              </React.Suspense>
            }
          />
          <Route
            path="/mobilemynewlisting"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilemynewlisting />
              </React.Suspense>
            }
          />
          <Route
            path="/mobilemynewlistingagreement"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilemynewlistingagreement />
              </React.Suspense>
            }
          />
          <Route
            path="/mobilemynewlistingpayment"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilemynewlistingpayment />
              </React.Suspense>
            }
          />
          <Route
            path="/mobilemynewlistingcancellation"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Mobilemylistingcancellation />
              </React.Suspense>
            }
          />
          <Route
            path="/adminfrontend/trendingnow/packagedetails"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Adminfrontendtrendingpackage />
              </React.Suspense>
            }
          />
          <Route
            path="/calculator"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Calculator />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <PageNotFound />
              </React.Suspense>
            }
          />
        </Routes> */}
      {/* </Routes> */}
    </>
  );
};

// const App = () =>{return(
//   <>
//   <Router>
//     <Routes>
//       <Route path="/user" element={<UserApp/>}/>
//       <Route path="/admin" element={<AdminApp/>}/>
//     </Routes>
//   </Router>
//   </>)
// }

export default App;
