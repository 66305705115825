import { BASE_URL, LISTING_REQ, ORDER, FAQ_REQUEST_DATA } from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import audio from "../../../assets/Audio/audio.mp3";
import axios from "axios";

axios.defaults.withCredentials = true;

export const remvoeSeenNotication = createAsyncThunk(
  "remove_user_seen_notification",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/notification/remove_user_seen_notification`,
        {
          userId: payload.userId,
          seenNotifications: payload.seenNotifications,
        }
      );
      console.log("Response received from server:", res);
      return res;
    } catch (error) {
      return error.response.data;
    }
  }
);

const intitialState = {
  allNotification: [],
  orderNotificationData: [],
  listingNotificationData: [],
  // faqReqNotificationData: []
};

const adminNotificationSlice = createSlice({
  name: "adminNotification",
  initialState: intitialState,
  reducers: {
    stackNotification: (state, { payload }) => {
      console.log("Previous state:", state);
      state.allNotification = payload.data;
      state.orderNotificationData = [];
      state.listingNotificationData = [];
      state.faqReqNotificationData = [];
      for (const i of payload.data) {
        switch (i.split(":")?.[0]) {
          case ORDER:
            state.orderNotificationData.push({
              time: i.split(":")?.[2],
              id: i.split(":")?.[1],
            });
            break;
          case LISTING_REQ:
            state.listingNotificationData.push({
              time: i.split(":")?.[2],
              id: i.split(":")?.[1],
            });
            break;
          // case FAQ_REQUEST_DATA:
          //   state.faqReqNotificationData.push({
          //     time: i.split(":")?.[2],
          //     id: i.split(":")?.[2]
          //   })
        }
      }
      console.log("Updated state:", state);
    },
  },
});

export default adminNotificationSlice.reducer;
export const { stackNotification } = adminNotificationSlice.actions;
