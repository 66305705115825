import "./datepicker.css";

import {
  DownArrow,
  LeftWhiteArrow,
  RightWhiteArrow,
  UpArrow,
  // Downtitle,
  // Uptitle,
} from "../../assets";
import React, { useEffect, useRef } from "react";

import { useState } from "react";

const Datepicker = ({
  date,
  setDate,
  disbaleBeforeDate,
  rounded,
  boxShadow,
}) => {
  // console.log(date);
  const formattedDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  // const formattedDate = new Date(date.getTime());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [tempYear, setTempYear] = useState(0);
  const monthList = [
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dayList = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const [disableBefore, setDisableBefore] = useState();
  // const [yearSelector, setYearSelector] = useState(false);
  const inputRef = useRef(null);

  const getNumberOfDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getSortedDays = (year, month) => {
    const dayIndex = new Date(year, month, 1).getDay();
    const noDays = getNumberOfDaysInMonth(year, month);
    const nNull = new Array(dayIndex).fill(null);
    const daysArray = [...Array(noDays).keys()].map((ele) => ele + 1);
    const days = [...nNull, ...daysArray];
    return days;
  };

  const nextMonth = () => {
    if (currentMonth < 11) {
      setCurrentMonth((prev) => prev + 1);
    } else {
      setCurrentMonth(0);
      setCurrentYear((prev) => prev + 1);
    }
  };

  const prevMonth = () => {
    if (currentMonth > 0) {
      setCurrentMonth((prev) => prev - 1);
    } else {
      setCurrentMonth(11);
      setCurrentYear((prev) => prev - 1);
    }
  };

  const handleHover = () => {
    // console.log("hover called");
    inputRef?.current?.focus();
  };

  useEffect(() => {
    if (disbaleBeforeDate) {
      setDisableBefore(
        new Date(
          new Date(disbaleBeforeDate).getFullYear(),
          new Date(disbaleBeforeDate).getMonth(),
          new Date(disbaleBeforeDate).getDate()
        ).getTime()
      );
    }
  }, []);

  useEffect(() => {
    if (date) {
      setCurrentMonth(date.getMonth());
      setCurrentYear(date.getFullYear());
      setTempYear(date.getFullYear());
    }
  }, [date]);

  useEffect(() => {
    if (tempYear > 1900 && tempYear < 2100) {
      setCurrentYear(tempYear);
    }
  }, [tempYear]);

  let latestYear = new Date().getFullYear().toFixed();

  return (
    <>
      <div
        className={`date-picker-container ${rounded ? "roundedDate" : ""} ${
          boxShadow ? "box-shadow" : ""
        }`}
      >
        <div className="date-picker-header">
          <img src={LeftWhiteArrow} alt="leftwhitearrow" onClick={prevMonth} />
          <p className="year-container">
            {monthList[currentMonth]}{" "}
            <input
              className=""
              type="number"
              name=""
              id=""
              max={currentYear}
              onChange={(e) => {
                const year = parseInt(e.target.value) || 1;
                if (year <= latestYear) {
                  setTempYear(year);
                }
              }}
              ref={inputRef}
              value={tempYear}
              onMouseEnter={handleHover}
            />
            <span className="year-arrows-container">
              <img
                src={UpArrow}
                alt=""
                onClick={() => {
                  if (tempYear < latestYear) {
                    setTempYear(tempYear + 1);
                  }
                }}
              />{" "}
              <img
                src={DownArrow}
                alt=""
                onClick={() => {
                  setTempYear(tempYear - 1);
                }}
              />
            </span>
          </p>
          <img
            src={RightWhiteArrow}
            alt="rightwhitearrow"
            onClick={nextMonth}
          />
        </div>
        <div className="date-picker-body">
          <div className="date-picker-day-container">
            {dayList.map((day) => (
              <p>{day}</p>
            ))}
          </div>
          <div className="date-picker-day-container">
            {getSortedDays(currentYear, currentMonth).map((day) => (
              <p
                id={
                  disableBefore >
                  new Date(currentYear, currentMonth, day).getTime()
                    ? "disbaled-date"
                    : "allowed-date"
                }
                onClick={() => {
                  if (
                    disableBefore <=
                      new Date(currentYear, currentMonth, day).getTime() ||
                    !disableBefore
                  )
                    setDate(new Date(currentYear, currentMonth, day));
                }}
                className={
                  formattedDate?.getTime() ===
                  new Date(currentYear, currentMonth, day).getTime()
                    ? "selected"
                    : ""
                }
              >
                {day}
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Datepicker;
