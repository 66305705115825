import React from 'react'

const Test = () => {
  // const cityOfferBanner = useSelector(
  //   (state) => state?.rootReducer?.homePage?.data?.cityOfferBanner
  // );
  return (
    <div className='new-bento-grid'>
       {/* <ReelSwiper slides={slides} /> */}
    </div>
  )
}

export default Test