import {
  BASE_URL,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;

export const fetchPagenatedUsers = createAsyncThunk(
  "fetch/admin/users/get_all_users",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/users/get_all_users`, {
        pageSize: data.pageSize,
        page: data.page,
        sort: data.sort,
        filters: data.filters,
      });
      // const res =  await axios.post(
      //    `${BASE_URL}/admin/users/get_all_users`,
      //     {}
      // );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "/users/get_user_detail",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/users/get_user_detail`, {
        currId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserIofo = createAsyncThunk(
  "/users/get_user_detail",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/users/get_user_detailByMid`, {
        currId: id,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const blockUser = createAsyncThunk(
  "/users/block_unBlock_user",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/users/block_unBlock_user`,
        {
          currId: payload.currId,
          isBlock: payload.isBlock,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserById = createAsyncThunk(
  "post/auth/get_info",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/users/get_user_by_id`,
        {
          currId: payload.currId,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const assignIdentityProofStatusThunk = createAsyncThunk(
  "post/auth/identity_proof_assign_status",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/users/identity_proof_assign_status`,
        {
          userId: payload.userId,
          status: payload.status
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const assignEnterpriseProofStatusThunk = createAsyncThunk(
  "post/auth/enterprise_proof_assign_status",
  async (payload) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/users/enterprise_proof_assign_status`,
        {
          userId: payload.userId,
          status: payload.status
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  data: {
    total: 0,
    data: [],
  },
  isError: false,
  loading: false,
  moreInformationPopup: { key: -1, value: false },
  userData: {},
  errorData: {
    message: "",
    type: " ",
    errors: [],
  },
};

const adminUserSlice = createSlice({
  name: "adminUserData",
  initialState: initialState,
  reducers: {
    fetchAllUser: (state, action) => {
      state.data = action.payload.data;
    },
    setMoreOption: (state, action) => {
      state.moreInformationPopup = action.payload;
    },
    fetchUserDetail: (state, action) => {
      state.userData = action.payload;
    },
    setCustomAlert: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPagenatedUsers.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(fetchPagenatedUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(fetchPagenatedUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      .addCase(getUserDetails.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.userData = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(getUserById.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.userData = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getUserById.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(assignIdentityProofStatusThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(assignIdentityProofStatusThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.userData = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(assignIdentityProofStatusThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })

      .addCase(assignEnterpriseProofStatusThunk.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(assignEnterpriseProofStatusThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        switch (payload.type) {
          case SUCCESS:
            state.userData = payload.data;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(assignEnterpriseProofStatusThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
  },
});

export default adminUserSlice.reducer;
export const { setCustomAlert, fetchAllUser, setMoreOption, fetchUserDetail } =
  adminUserSlice.actions;
