import "./adminpagenotfound.css";

import { ADMIN_DASHBOARD, ADMIN_ROOT } from "../../constants/links";

import { Link } from "react-router-dom";
import React from "react";

function AdminPageNotFound() {
  return (
    <div className="admin-page-not-found-container">
      <p id="admin-page-not-found"> Page Not found </p>
      <Link to={ADMIN_DASHBOARD} id="admin-page-not-found-link">
        Go to Admin Home
      </Link>
    </div>
  );
}

export default AdminPageNotFound;
