import {
  BASE_URL,
  // ERROR,
  FAILURE,
  FULFILLED,
  IDLE,
  // LOADING,
  REJECTED_ERROR,
  SUCCESS,
} from "../../../constants/constants";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

export const createUserWaitlist = createAsyncThunk(
  "user/waitlist/create",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/waitlist/create`, data);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUserWaitlistDetails = createAsyncThunk(
  "user/waitlist/checkForUser",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/user/waitlist/checkForUser`,
        data
      );

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllWaitListThunk = createAsyncThunk(
  "${BASE_URL}/admin/waitlist/getWaitlist",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/waitlist/getWaitlist`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteWaitListThunk = createAsyncThunk(
  "${BASE_URL}/admin/waitlist/delWaitlist",
  async (data) => {
    try {
      const res = await axios.post(`${BASE_URL}/admin/waitlist/delWaitlist`, {
        id: data,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  data: {
    waitlistInfo: {},
    createWaitlist: {},
    waitListData: {
      data: [],
      total: 0,
    },
  },
  errorData: {
    message: "",
    type: " ",
    errors: [],
  },
  status: {
    deleteWaitListThunk: IDLE,
    getUserWaitlistDetails: IDLE,
    getAllWaitListThunk: IDLE,
  },
  updateDone: false,
  isUserExit: false,
};

const waitlistSlice = createSlice({
  name: "waitlistSlice",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // get user waitlist=====================================================
      .addCase(getUserWaitlistDetails.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(getUserWaitlistDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        switch (payload.type) {
          case SUCCESS:
            state.status.getUserWaitlistDetails = FULFILLED;
            break;
          case FAILURE:
            state.isUserExit = true;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
          default:
            break;
        }
      })
      .addCase(getUserWaitlistDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // create user waitlist=====================================================
      .addCase(createUserWaitlist.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(createUserWaitlist.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.createWaitlist = payload.data;
            state.updateDone = !state.updateDone;
            break;
          default:
            // state.data.createWaitlist = payload.data;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createUserWaitlist.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // getAllWaitListThunk ==================================================================================
      .addCase(getAllWaitListThunk.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(getAllWaitListThunk.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        switch (payload.type) {
          case SUCCESS:
            state.data.waitListData.data = payload.data.data;
            state.data.waitListData.total = payload.data.total;
            state.status.getAllWaitListThunk = FULFILLED;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllWaitListThunk.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      })
      // deleteWaitListThunk ==================================================================================
      .addCase(deleteWaitListThunk.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(deleteWaitListThunk.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            state.status.deleteWaitListThunk = FULFILLED;
            break;
          default:
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteWaitListThunk.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.errorData = REJECTED_ERROR;
      });
  },
});

export default waitlistSlice.reducer;

export const { clearErrorSlice } = waitlistSlice.actions;
