// import {
//   formatDateToDMY,
//   getNoOfDays,
//   getSubTotal,
// } from "../../utils/displayUtils";

// import lodash from "lodash";
// import { useMemo } from "react";

const LateFeeInfoContainer = ({
  setPopElm,
  lateFeepopper,
  get_amount_details,
  isDelivery,
}) => {
  // console.log(get_amount_details?.totalAmount());
  if (isDelivery) {
    return (
      <div
        ref={setPopElm}
        style={{
          ...lateFeepopper?.styles?.popper,
        }}
        {...lateFeepopper?.attributes}
        className="late-feeinfo-container"
      >
        <div className="late-fee-total m-b1">
          <p className="base-title">Monthly</p>
          <p className="late-fee-total-amount">
            ₹ {get_amount_details?.monthly}
          </p>
        </div>
        <div className="late-fee-total m-b1">
          <p className="base-title">Delivery Charge</p>
          <p className="late-fee-total-amount">
            + ₹ {get_amount_details.deliveryInfo().deliveryTotal}
          </p>
        </div>
        {get_amount_details.creditInfo().creditApplicable && (
          <div className="late-fee-total m-b1">
            <p className="base-title">credit</p>
            <p className="green-text bold">
              - ₹ {get_amount_details.creditInfo().accessibleCredit}
            </p>
          </div>
        )}
        <div className="late-fee-total border-gray-top">
          <p className="base-title">
            Total Amount <span className="green-text">(Incl. Taxes)</span>
          </p>
          <p className="late-fee-total-amount">
            ₹ {get_amount_details?.totalAmount()}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        ref={setPopElm}
        style={{
          ...lateFeepopper?.styles?.popper,
        }}
        {...lateFeepopper?.attributes}
        className="late-feeinfo-container"
      >
        <div className="late-fee-total m-b1">
          <p className="base-title">Due date</p>
          <p className="red-number">{get_amount_details?.dueDate}</p>
        </div>
        <div className="late-fee-total m-b1">
          <p className="base-title">Monthly</p>
          <p className="late-fee-total-amount">
            ₹ {get_amount_details?.monthly}
          </p>
        </div>
        <div className="late-fee-total m-b1">
          <p className="base-title">{`${get_amount_details.overdue_days()} days overdue`}</p>
          <p className="red-number">+ ₹ {get_amount_details?.lateCharge()}</p>
        </div>
        {get_amount_details.deliveryInfo().isApplicable && (
          <div className="late-fee-total m-b1">
            <p className="base-title">Delivery Charge</p>
            <p className="late-fee-total-amount">
              + ₹ {get_amount_details.deliveryInfo().deliveryTotal}
            </p>
          </div>
        )}
        {get_amount_details.creditInfo().creditApplicable && (
          <div className="late-fee-total m-b1">
            <p className="base-title">credit</p>
            <p className="late-fee-total-amount">
              - ₹ {get_amount_details.creditInfo().accessibleCredit}
            </p>
          </div>
        )}
        <div className="late-fee-total border-gray-top">
          <p className="base-title">
            Total Amount <span className="green-text">(Incl. Taxes)</span>
          </p>
          <p className="late-fee-total-amount">
            ₹ {get_amount_details?.totalAmount()}
          </p>
        </div>
      </div>
    );
  }
};

export default LateFeeInfoContainer;
