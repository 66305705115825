import "./myorderproductsupport.css";

import * as Yup from "yup";

import { 
  GradientCamera, OrderAccepted, SmallCross,
  // GoPro, 
} from "../../assets";

// import { Link } from "react-router-dom";
import React from "react";
import { SUCCESS } from "../../constants/constants";
import { addUserProductSupportSlice } from "../../redux/features/orderSlice/orderSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";

const Myorderproductsupport = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [imgArr, setImgArr] = useState([]);
  const [imgTags, setImgTags] = useState([]);

  useEffect(() => {
    if (imgTags || isSubmit) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  function addImgTags(e) {
    const imgFile = e.target.files;
    const imagesFilesArray = Array.from(imgFile);
    const imagesArray = imagesFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    for (const value of e.target.files) {
      imgArr.push(value);
    }
    setImgTags((previousArray) => previousArray.concat(imagesArray));
  }

  function removeImgTags(index) {
    setImgTags(imgTags.filter((el, i) => i !== index));
    setImgArr(imgArr.filter((el, i) => i !== index));
  }

  useEffect(() => {
    if (isSubmit) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  });

  // Async thunk
  const dispatch = useDispatch();

  const prodSupp = useFormik({
    initialValues: {
      problem: "",
    },
    validationSchema: Yup.object({
      problem: Yup.string()
        .min(3, "problem must be of minimum length 3")
        .max(500, "problem must be of maximum length 500")
        .required("problem is required"),
    }),
    onSubmit: (values) => {
      let prodSuppData = new FormData();
      prodSuppData.append("orderId", props.orderId);
      prodSuppData.append("userName", props.userName);
      prodSuppData.append("problem", values.problem);
      imgArr.forEach((elm, idx) => {
        prodSuppData.append("image", elm);
      });
      prodSuppData.append("suppDate", new Date());
      dispatch(addUserProductSupportSlice(prodSuppData)).then((data) => {
        if (data.payload.type === SUCCESS) {
          props.onclick();
        }
      });
    },
  });

  // if (window.innerWidth <= 991) {
  //   return (
  //     <>
  //       {/* <div className="myorder-productsupport-container"> */}
  //       <div className="myorder-productsupport-content">
  //         <p id="support-text">
  //           Product Support{" "}
  //           <img
  //             src={SmallCross}
  //             alt="cross"
  //             style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
  //             onClick={props.onclick}
  //           />
  //         </p>
  //         <p id="exp-heading">Are you experiencing turbulence?</p>
  //         <p id="support-desc">
  //           Please give us a detailed synopsis of the issue faced by you below
  //           and we will get back to you ASAP!
  //         </p>
  //         <textarea
  //           name="productproblem"
  //           id=""
  //           cols="30"
  //           rows="10"
  //           placeholder="Mention your problem...."
  //         ></textarea>
  //         <div className="myorder-prodsupp-img-tag-container">
  //           {imgTags.map((imgTag, index) => (
  //             <div className="myorder-product-issue-img" key={index}>
  //               <span id="myorder-prod-issue-tag-img">
  //                 <img src={imgTag} alt="gopro" />
  //               </span>
  //               <p>Image {index + 1}</p>
  //               <span
  //                 id="myorder-prod-issue-tag-close"
  //                 onClick={() => removeImgTags(index)}
  //               >
  //                 <img src={SmallCross} alt="smallcross" />
  //               </span>
  //             </div>
  //           ))}
  //         </div>

  //         <div className="myorder-upload-img-container">
  //           <img
  //             src={GradientCamera}
  //             alt="camera"
  //             style={{
  //               width: "1.25rem",
  //               height: "1.125rem",
  //               marginRight: ".5rem",
  //             }}
  //           />
  //           <p>Upload Image of the Issue +</p>
  //           <input type="file" name="productissueimg" accept="image/*" />
  //         </div>

  //         <button type="submit" onClick={() => setIsSubmit(!isSubmit)}>
  //           Submit Issue
  //         </button>
  //         {isSubmit && (
  //           <>
  //             <div className="myorder-submit-container">
  //               <div className="myorder-submit-content">
  //                 <img src={OrderAccepted} alt="orderaccept" />
  //                 <p id="submitted-text">Submitted</p>
  //                 <p id="submitted-desc">
  //                   Sorry for the inconvenience caused. We are working to get
  //                   your problem resolved ASAP! For further query you can call
  //                   us on <span>+91 79000 42875</span>
  //                 </p>
  //                 <button
  //                   type="submit"
  //                   id="submitted-btn"
  //                   onClick={props.onclick}
  //                 >
  //                   Done
  //                 </button>
  //               </div>
  //             </div>
  //           </>
  //         )}
  //       </div>
  //       {/* </div> */}
  //     </>
  //   );
  // } else {
  return (
    <>
      {/* <div className="myorder-productsupport-container"> */}
      {/* <div className="myorder-productsupport-outer-container"> */}
      <div className="myorder-productsupport-content">
        <p id="support-text">
          Product Support{" "}
          <img
            src={SmallCross}
            alt="cross"
            style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
            onClick={props.onclick}
          />
        </p>
        <p id="exp-heading">Are you experiencing turbulence?</p>
        <p id="support-desc">
          Please give us a detailed synopsis of the issue faced by you below and
          we will get back to you ASAP!
        </p>
        <p
          id="mobile-error-div"
          style={{
            marginBottom: "0rem",
            marginTop: "2rem",
            color: "#F41B1B",
          }}
        >
          {prodSupp.errors.problem && prodSupp.touched.problem
            ? prodSupp.errors.problem
            : "\u00A0"}
        </p>
        <textarea
          name="problem"
          id=""
          cols="30"
          rows="10"
          placeholder="Mention your problem...."
          style={{ marginTop: "0rem" }}
          value={prodSupp.values.problem}
          onChange={prodSupp.handleChange}
          onBlur={prodSupp.handleBlur}
        ></textarea>
        <div className="myorder-prodsupp-img-tag-container">
          {imgTags.map((imgTag, index) => (
            <div className="myorder-product-issue-img" key={index}>
              <img src={imgTag} alt="gopro" />
              <span
                id="myorder-prod-issue-tag-close"
                onClick={() => removeImgTags(index)}
              >
                <img src={SmallCross} alt="smallcross" />
              </span>
            </div>
          ))}
        </div>
        <div className="myorder-upload-img-container">
          <img
            src={GradientCamera}
            alt="camera"
            style={{
              width: "1.25rem",
              height: "1.125rem",
              marginRight: ".5rem",
            }}
          />
          <p>Upload Image of the Issue +</p>
          <input
            onChange={addImgTags}
            type="file"
            name="productissueimg"
            multiple
            accept="image/png, image/jpeg, image/webp"
          />
        </div>
        <button type="submit" onClick={() => setIsSubmit(!isSubmit)}>
          Submit Issue
        </button>
        {isSubmit && (
          <>
            <div className="myorder-submit-container">
              <div className="myorder-submit-content">
                <img src={OrderAccepted} alt="orderaccept" />
                <p id="submitted-text">Submitted</p>
                <p id="submitted-desc">
                  Sorry for the inconvenience caused. We are working to get your
                  problem resolved ASAP! For further query you can call us on{" "}
                  <span style={{ color: "#0E9BE1" }}>+91 79000 42875</span>
                </p>
                <button
                  type="submit"
                  id="submitted-btn"
                  onClick={prodSupp.handleSubmit}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
        ``
      </div>
      {/* </div> */}
    </>
  );
  // }
};

export default Myorderproductsupport;
