import React, { useState } from "react";
import PopUp from "../PopUp/PopUp";
import { UploadFile } from "../../assets";
import { useDispatch } from "react-redux";
import "./MyorderDocumentReupload.css";
import { useFormik } from "formik";
import { updateUserDocumentsSlice } from "../../redux/features/loginSlice/loginSlice";

const IdentityProof = (props) => {
  const dispatch = useDispatch();
  const [identityProof, setIdentityProof] = useState(false);
  const [identityURL, setIdentityURL] = useState("");

  const handleIdentityImage = (e) => {
    setIdentityProof(e.target.files[0]);
    const imgFilesArray = Array.from(e.target.files);
    const imagesArray = imgFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setIdentityURL(imagesArray[0]);
  };

  const [confirmReUpload, setConfirmReUpload] = useState(false);

  const uploadProof = useFormik({
    initialValues: {
      identityProof: [],
    },
    onSubmit: () => {
      let uploadProofData = new FormData();

      uploadProofData.append("orderId", props.orderId);
      uploadProofData.append("identityProof", identityProof);

      dispatch(updateUserDocumentsSlice(uploadProofData));
      setConfirmReUpload(!confirmReUpload);
    },
  });

  return (
    <div
      className="re-upload-document-content"
      style={{ justifyContent: "start" }}
    >
      <svg
        width="46"
        height="55"
        viewBox="0 0 36 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9998 36.1667V22.5M17.9998 22.5L11.1427 27.0555M17.9998 22.5L24.8568 27.0556M20.2855 2.00198C20.0672 2 19.8221 2 19.542 2H9.31463C6.75443 2 5.47337 2 4.4955 2.49653C3.63534 2.93328 2.93652 3.62969 2.49825 4.48687C2 5.46136 2 6.73798 2 9.28933V35.7115C2 38.2629 2 39.5379 2.49825 40.5124C2.93652 41.3696 3.63534 42.0672 4.4955 42.504C5.47242 43 6.75194 43 9.30719 43L26.6924 43C29.2476 43 30.5253 43 31.5022 42.504C32.3624 42.0672 33.0635 41.3696 33.5018 40.5124C33.9996 39.5389 33.9996 38.2656 33.9996 35.7193V16.4085C33.9996 16.129 33.9995 15.8844 33.9974 15.6667M20.2855 2.00198C20.9383 2.0079 21.3514 2.03154 21.7456 2.12587C22.2121 2.23747 22.6577 2.42199 23.0667 2.67177C23.5279 2.9534 23.9239 3.34803 24.714 4.13542L31.8578 11.2546C32.6485 12.0425 33.0416 12.4353 33.3243 12.8951C33.5749 13.3026 33.7603 13.7471 33.8723 14.2119C33.9669 14.6047 33.9912 15.0164 33.9974 15.6667M20.2855 2.00198V8.37778C20.2855 10.9291 20.2855 12.2039 20.7837 13.1784C21.222 14.0356 21.9208 14.7339 22.781 15.1706C23.7579 15.6667 25.0374 15.6667 27.5926 15.6667H33.9974M33.9974 15.6667H34"
          stroke="url(#paint0_linear_59_570)"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_59_570"
            x1="2"
            y1="2"
            x2="34.6051"
            y2="2.4455"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1BACF4" />
            <stop offset="1" stop-color="#19EEB5" />
          </linearGradient>
        </defs>
      </svg>

      <div className="reupload-inner-container">
        <p>Verify Your Identity proof and Documents</p>
        <div className="reupload-sucessfull-done-button">
          <button
            onClick={(e) => setIdentityProof((pre) => !pre)}
            id="reupload-sucessfull-done"
          >
            Upload Document
          </button>
        </div>
      </div>
      <PopUp
        display={identityProof}
        callback={setIdentityProof}
        center={true}
        uniqueId={"listProdPopup"}
        zIndex={150}
      >
        <div style={{ width: "reset", backgroundColor: "black" }}>
          <div className="identity-content">
            <div className="header">
              <h3>Identity Verification</h3>
            </div>
            <div className="identity-upload-outer">
              <div className="identity-upload">
                <img
                  id={identityURL ? "uploaded-image" : ""}
                  src={identityURL ? identityURL : UploadFile}
                  alt="uploaddocs"
                />
                <p
                  style={{ display: identityURL ? "none" : "" }}
                  id="individual-upload-text"
                >
                  {identityURL ? "" : "Upload"}
                </p>
                <input
                  type="file"
                  name="identityProof"
                  accept="image/*"
                  value={uploadProof.values.identityProof}
                  onChange={(e) => handleIdentityImage(e)}
                />
              </div>
            </div>
            <div className="individual-upload-desc">
              <p id="upload-desc">
                Upload an address proof from your Aadhar card, broadband bill,
                Electricity bill, and Rental agreement for yours product
                delivery address..
              </p>
            </div>
            {/* <div className="reupload-sucessfull-done-button"> */}
              <button
                onClick={() => uploadProof.handleSubmit()}
                className="capture"
                style={{ width: "24rem" }}
              >
                Submit
              </button>
            {/* </div> */}
          </div>
        </div>
      </PopUp>
    </div>
  );
};

export default IdentityProof;
