import "./addproductpopup.css";
import "./addproductcomp.css";

import * as Yup from "yup";

import { Calender, Cross, Dcamera, PopUpClose } from "../../../assets";
import { CustomSelectCatogery, FailureAlert, LoadingPage } from "../..";
import React, { useEffect, useState } from "react";
import {
  clearErrorSlice,
  createInventoryThunkV1,
  getInvMaxcount,
} from "../../../redux/features/adminInventorySlice/inventorySlice";
import {
  getAllCategories,
  getAllSubCategories,
  getAllSuperCategories,
  setSelectedCategory,
} from "../../../redux/features/adminFrontendackageSlice/adminFrontendPackageSlice";
import { useDispatch, useSelector } from "react-redux";

import Customselectsubcatogery from "../CustomSelectSubCatogery/customselectsubcatogery";
import PopUp from "../../PopUp/PopUp";
import {
  SUCCESS
} from "../../../constants/constants";
import { useFormik } from "formik";

const Addproductpopup = (props) => {
  console.log(props)
  const [packageImage, setPackageImages] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [isDone, setIsdone] = useState(false);
  const [isImages, setIsImages] = useState(false);
  // V: can remove above to states.

  //for removing added images from the array
  const removeImage = (idxx) => {
    setImageArray(
      imageArray.filter((elm, idx) => {
        return idx !== idxx;
      })
    );
    setPackageImages(
      packageImage.filter((elm, idx) => {
        return idx !== idxx;
      })
    );
  };
  // commit test

  const addImgTag = (e) => {
    const imgFile = e.target.files;
    const imgFilesArray = Array.from(imgFile);

    const imagesArray = imgFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    for (const value of e.target.files) {
      packageImage.push(value);
    }

    setImageArray([...imageArray, ...imagesArray]); //for client side display purpose
  };

  const dispatch = useDispatch();

  const allCategories = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allCategories
  );
  const selectedCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.form.selectedCategory
  );
  const activeCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.activeCategory
  );

  const updateDone = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.updateDone
  );
  const superCategories = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.data.allSuperCategories
  );
  const formCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.form.selectedCategory
  );
  const formSubCategory = useSelector(
    (state) => state.rootReducer.adminFrontendPackage.form.selectedSubCategory
  );
  const customAlert = useSelector(
    (state) => state.rootReducer.inventoryInfo.isError
  );
  const error = useSelector(
    (state) => state.rootReducer.inventoryInfo.errorData
  );
  const loading = useSelector(
    (state) => state.rootReducer.inventoryInfo.loading
  );
  const inventoryCount = useSelector(
    (state) => state.rootReducer.inventoryInfo.inventoryCount
  );

  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };

  useEffect(() => {
    dispatch(getInvMaxcount());
    dispatch(getAllCategories());
    dispatch(setSelectedCategory(activeCategory));
    dispatch(getAllSuperCategories());
    dispatch(getAllSubCategories({ category: activeCategory }));
  }, []);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(setSelectedCategory(activeCategory));
    dispatch(getAllSuperCategories());
    dispatch(getAllSubCategories({ category: activeCategory }));
    dispatch(getInvMaxcount());
  }, [updateDone]);

  // formik section for add product
  let date = new Date();
  // Get year, month, and day part from the date
  let year = date.toLocaleString("default", { year: "numeric" });
  let month = date.toLocaleString("default", { month: "2-digit" });
  let day = date.toLocaleString("default", { day: "2-digit" });

  // Generate yyyy-mm-dd date string
  let formattedDate = year + "-" + month + "-" + day;

  console.log("requestDeatil", props.requestDetail);
  const addProductForm = useFormik({
    initialValues: {
      name: "",
      description: "",
      lenderName: props.requestDetail?.userInfo?.fullname || "",
      lenderPhone: props.requestDetail?.userInfo?.phone || "",
      lenderAddress: props.requestDetail?.userInfo?.address
        ? props.requestDetail?.userInfo?.address[0]?.address
        : "",
      startDate: formattedDate,
      endDate: "",
      type: "",
      warranty: "",
      image: "",
    },
    validationSchema: Yup.object({
      name: Yup.string("must be a string")
        .min(3, "Min length is 3")
        .max(30, "Max length is 30")
        .required("Product name  is required"),
      description: Yup.string()
        .min(3, "Min length is 3")
        .max(100, "Max length is 100")
        .required("Accessories/Description is required"),
      lenderName: Yup.string("must be string").min(3, "min length is 3"),
      lenderPhone: Yup.string("must be string")
        .min(10, "enter valid phone number")
        .max(10, "enter valid phone number"),
      lenderAddress: Yup.string("must be string")
        .min(10, "min length is 10")
        .max(100, "max length 100"),
      startDate: Yup.string(),
      endDate: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      let inventoryData = new FormData();
      inventoryData.append("name", values.name);
      inventoryData.append("description", values.description);
      if (formCategory && formCategory !== undefined) {
        inventoryData.append("category", formCategory);
      }
      if (formSubCategory && formSubCategory !== undefined) {
        inventoryData.append("subCategory", formSubCategory);
      }
      if (values?.lenderName !== undefined) {
        inventoryData.append("lenderInfo[name]", values?.lenderName);
      }
      if (values?.lenderPhone !== undefined) {
        inventoryData.append("lenderInfo[phone]", values?.lenderPhone);
      }
      if (props.requestDetail?.userInfo._id !== undefined) {
        inventoryData.append(
          "lenderInfo[lenderUserId]",
          props.requestDetail?.userInfo._id
        );
      }
      if (values?.lenderPhone !== undefined) {
        inventoryData.append("lenderInfo[address]", values?.lenderAddress);
      }
      if (values?.startDate !== undefined) {
        inventoryData.append("lendingPeriod[startDate]", values?.startDate);
      }
      if (values?.endDate !== undefined) {
        inventoryData.append("lendingPeriod[endDate]", values?.endDate);
      }
      inventoryData.append("warranty", values.warranty);
      inventoryData.append("assetNo", inventoryCount);
      inventoryData.append("status", "INSTORE");
      inventoryData.append(
        "type",
        props.requestDetail?._id ? "EXTERNAL" : "INTERNAL"
      );
      inventoryData.append("listingReqId", props.requestDetail?._id);

      packageImage.forEach((element, idx) => {
        inventoryData.append(`image`, element);
      });

      //dispatch------------------------------
      dispatch(createInventoryThunkV1(inventoryData)).then((data) => {
        if (data.payload.type === SUCCESS) {
          resetForm();
          setIsdone(!isDone);
        }
      });
    },
  });

  return (
    <>
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      {loading && <LoadingPage />}
      <div className="listing-inventory-addproduct-container">
        <p className="addproduct-container-header">
          Add Product{" "}
          <img
            id="addproduct-cross-img"
            src={PopUpClose}
            alt="cross-icon"
            onClick={() => props.onclick()}
          />
        </p>
        <div
          className="admin-req-details-select-catogery-container"
          style={{ marginBottom: "1rem", margin: "0rem" }}
        >
          <div className="admin-package-creation-catogery-container">
            <CustomSelectCatogery
              options={superCategories.map((elm) => elm.category)}
              category={true}
            />
          </div>
          <div className="admin-package-creation-subcatogery-container">
            <Customselectsubcatogery
              options={allCategories
                .filter(
                  (elm) =>
                    elm?.category === (selectedCategory || activeCategory) &&
                    elm?.subCategory !== undefined
                )
                .map((data) => data?.subCategory)}
              category={false}
            />
          </div>
        </div>
        {/* =========================== */}
        <div className="addproduct-container-partial">
          <span>
            {" "}
            {addProductForm.errors.name && addProductForm.touched.name
              ? addProductForm.errors.name
              : "\u00A0"}
          </span>
          <span></span>
        </div>
        <div className="addproduct-container-partial">
          <input
            type="text"
            value={addProductForm.values.name}
            onChange={addProductForm.handleChange}
            onBlur={addProductForm.handleBlur}
            name="name"
            placeholder="Product Name"
          />
          <p>Asset Number: #{inventoryCount + 1}</p>
        </div>
        {/* =========================== */}
        <div className="addproduct-container-partial">
          <span>
            {" "}
            {addProductForm.errors.description &&
            addProductForm.touched.description
              ? addProductForm.errors.description
              : "\u00A0"}
          </span>
          <span></span>
        </div>
        <div className="addproduct-container-single">
          <input
            type="text"
            name="description"
            value={addProductForm.values.description}
            onChange={addProductForm.handleChange}
            onBlur={addProductForm.handleBlur}
            placeholder="Description"
          />
        </div>
        {/* =========================== */}
        <div className="addproduct-container-partial">
          <span>
            {" "}
            {addProductForm.errors.lenderName &&
            addProductForm.touched.lenderName
              ? addProductForm.errors.lenderName
              : "\u00A0"}
          </span>
          <span>
            {addProductForm.errors.lenderPhone &&
            addProductForm.touched.lenderPhone
              ? addProductForm.errors.lenderPhone
              : "\u00A0"}
          </span>
        </div>
        <div className="addproduct-container-partial">
          <input
            type="text"
            name="lenderName"
            value={addProductForm.values.lenderName}
            onChange={addProductForm.handleChange}
            onBlur={addProductForm.handleBlur}
            id=""
            placeholder="Enter lenders name"
            disabled={!props?.requestDetail}
          />
          <input
            type="number"
            name="lenderPhone"
            onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
            value={addProductForm.values.lenderPhone}
            onChange={addProductForm.handleChange}
            onBlur={addProductForm.handleBlur}
            id=""
            minLength={10}
            maxLength={10}
            placeholder="Enter your phone no"
            disabled={!props?.requestDetail}
          />
        </div>
        {/* =========================== */}
        <div className="addproduct-container-partial">
          <span>
            {" "}
            {addProductForm.errors.lenderAddress &&
            addProductForm.touched.lenderAddress
              ? addProductForm.errors.lenderAddress
              : "\u00A0"}
          </span>
          <span></span>
        </div>
        <div className="addproduct-container-single">
          <input
            type="text"
            name="lenderAddress"
            value={addProductForm.values.lenderAddress}
            onChange={addProductForm.handleChange}
            onBlur={addProductForm.handleBlur}
            placeholder="Lender's Address"
            disabled={!props?.requestDetail}
          />
        </div>
        {/* =========================== */}
        <div className="addproduct-container-partial">
          <span>
            {addProductForm.errors.startDate && addProductForm.touched.startDate
              ? addProductForm.errors.startDate
              : "\u00A0"}
          </span>
          <span>
            {addProductForm.errors.startDate && addProductForm.touched.startDate
              ? addProductForm.errors.startDate
              : "\u00A0"}
          </span>
        </div>
        <div className="addproduct-container-single-date-container">
          <div
            className="admin-req-details-lending-period"
            style={{ marginTop: "0rem" }}
          >
            <p>Lending period :</p>
            <div className="admin-req-details-date-container">
              <input
                type="date"
                value={addProductForm.values.startDate}
                onChange={addProductForm.handleChange}
                onBlur={addProductForm.handleBlur}
                name="startDate"
                disabled={!props?.requestDetail}
              />
              <div className="admin-req-details-calender-symbol-container">
                <img src={Calender} alt="calender" />
              </div>
            </div>

            <div className="admin-req-details-date-container">
              <input
                type="date"
                value={addProductForm.values.endDate}
                onChange={addProductForm.handleChange}
                onBlur={addProductForm.handleBlur}
                name="endDate"
                disabled={!props?.requestDetail}
              />
              <div className="admin-req-details-calender-symbol-container">
                <img src={Calender} alt="calender" />
              </div>
            </div>
          </div>
        </div>

        {/* =========================== */}
        <div
          className="admin-req-details-warranty-container"
          style={{ gap: "1.5rem" }}
        >
          <div className="admin-req-details-img-preview-container">
            <div className="admin-img-preview-img-container">
              <img
                id={imageArray?.[0] && "admin-product-image-container"}
                src={imageArray?.[0] ? imageArray?.[0] : Dcamera}
                alt="dcamera"
              />
            </div>
            <input
              type="file"
              name="image"
              multiple
              id=""
              onChange={(e) => {
                // handleAddonImage(e);
                addImgTag(e);
              }}
            />
            {imageArray?.[0] ? (
              <p onClick={() => setIsImages(!isImages)}>Product’s Image...</p>
            ) : (
              <p>Upload Product’s Image</p>
            )}
          </div>

          <PopUp
            display={isImages}
            callback={setIsImages}
            center={true}
            zIndex={13}
            uniqueId={"imageDisplay"}
          >
            <div className="image-show-container">
              {imageArray.map((elm, idx) => (
                <div className="image-show-box">
                  <p onClick={() => removeImage(idx)}>
                    <img src={Cross} alt="cross" />
                  </p>
                  <img src={elm} alt="cross" />
                </div>
              ))}
            </div>
          </PopUp>
          <input
            name="warranty"
            value={addProductForm.values.warranty}
            onChange={addProductForm.handleChange}
            type="text"
            id=""
            placeholder="Warranty if any"
          />
        </div>
        <div className="addproduct-button-container">
          <button
            type="submit"
            disabled={loading}
            onClick={addProductForm.handleSubmit}
            id="create-product-button"
          >
            {!loading && "Create Product"}
          </button>
        </div>
        {isDone && (
          <>
            <div className="admin-req-is-done-container">
              <div className="admin-req-is-done-content">
                <p>New Product has been added to the list</p>
                <button onClick={props.onclick}>Done</button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Addproductpopup;
