import "./tableComponent.css";

import React from "react";
import TableRow from "./TableRow";

// TableComponent useCase//
// TableComponent Properties
// tableheaders this will be an array which will define your headers of your tagle
// tableRow this will be an array which will define data of your talbe
// scrollblock={true} this property will change overflow scroll property to hidden of table in y axis
// Propspop to show moreInformation popup etc...

const TableComponent = ({
  tableheaders,
  tableRow,
  scrollblock,
  Propspop,
  blockTopm,
}) => {
  return (
    <>
      <div
        className="gridtable"
        style={{ marginTop: blockTopm ? "0rem" : "2rem" }}
      >
        <table>
          <thead>
            <tr>
              {tableheaders?.map((elm) => (
                <th>{elm}</th>
              ))}
              {Propspop && <th id="last-child-width"> </th>}
            </tr>
          </thead>
          <tbody
            style={{
              overflowY: scrollblock ? "hidden" : "scroll",
              maxHeight: scrollblock ? "none" : "60vh",
            }}
          >
            {tableRow?.map((elm) => (
              <TableRow tableRow={elm} PopUpComponent={Propspop} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableComponent;
