import React, { useEffect } from "react";

import PopUp from "../PopUp/PopUp";
import Webcam from "react-webcam";

const SelfieePopUp = ({
  liveSelfie,
  setLiveSelfie,
  hasPhoto,
  imgSrc,
  webcamRef,
  reuploadSelfie,
  setHasPhoto,
  setImgSrc,
  condition,
}) => {
  async function capture() {
    const imageSrc = await webcamRef.current.getScreenshot();
    if (imageSrc) {
      setHasPhoto(true);
      // setImgSrc("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAY8AAADyCAYAAAC1UUlMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANuSURBVHhe7dUxAcAgEMDAp/49A0MFkP1uiYSsfQ0ABN9fAHhmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUBmHgBk5gFAZh4AZOYBQGYeAGTmAUA0cwC8NwXgyKcmGQAAAABJRU5ErkJggg==");
      setImgSrc(imageSrc);
    }
  }
  const videoConstraints = {
    audio: false,
    screenshotQuality: 1,
    screenshotFormat: "image/jpeg",
    facingMode: "user",
  };

  const handleUploadClick = () => {
    if (!imgSrc) {
      return;
    }
    setLiveSelfie(liveSelfie);
    if (condition !== "New") {
      reuploadSelfie();
    }
  };

  const handlePopUp = (e) => {
    if (!e.target.classList.contains("capture")) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <PopUp
      display={liveSelfie}
      callback={setLiveSelfie}
      center={true}
      uniqueId={"listProdPopup"}
      zIndex={150}
    >
      <div className="individual-upload-container-verify" onClick={handlePopUp}>
        <div className="header">
          <p id="upload-text">Upload a Selfie</p>
        </div>
        {/* <div className="individual-upload-verify">
            <div className="header">
              <p
                style={{ display: hasPhoto ? "none" : "" }}
                id="individual-upload-text"
              >
                {hasPhoto ? "" : "Upload"}
              </p>
            </div>
          </div> */}
        <div className="individual-upload-desc-verify">
          <div className="identity-upload-outer">
            <div
              className={
                "result" +
                (hasPhoto ? "hasPhoto" : "") +
                "-verify inner-preview"
              }
              id={hasPhoto ? "uploaded-image" : ""}
            >
              {hasPhoto ? (
                <img src={imgSrc} id={hasPhoto ? "uploaded-image" : ""} />
              ) : (
                <svg
                  width="113"
                  height="98"
                  viewBox="0 0 113 98"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.45"
                    d="M74.9088 97.8973L49.4524 50.4742L69.563 16.4162L112.33 97.8973H74.9088ZM32.142 38.1445L64.2171 97.8973H0.0668945L32.142 38.1445ZM18.7774 27.2803C11.3963 27.2803 5.41275 21.2002 5.41275 13.7001C5.41275 6.2 11.3963 0.119934 18.7774 0.119934C26.1584 0.119934 32.142 6.2 32.142 13.7001C32.142 21.2002 26.1584 27.2803 18.7774 27.2803Z"
                    fill="black"
                  />
                </svg>
              )}
            </div>
          </div>
          <button
            onClick={capture}
            className="captures mt-4"
            style={{ width: "-webkit-fill-available" }}
          >
            {" "}
            {hasPhoto ? "Retake" : "Click Pic"}{" "}
          </button>
          <div
            className="camera"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* </div> */}
            {/* <div className="preview"> */}
            <Webcam
              ref={webcamRef}
              mirrored={true}
              width="100px"
              height="100px"
              videoConstraints={videoConstraints}
            />
          </div>
          <p id="upload-desc">
            Please upload a real-time Selfiee with peace sign. This step is very
            important to us so that no documents are misused.
          </p>
          <button
            className="capture"
            onClick={() => {
              handleUploadClick();
            }}
            style={{ width: "-webkit-fill-available" }}
          >
            Upload
          </button>
        </div>
      </div>
    </PopUp>
  );
};

export default SelfieePopUp;
