import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  LOADING,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;

export const getAllTagThunk = createAsyncThunk(
  "get/admin/admin_frontend/trending/getAllTag",
  async () => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/trending/getAllTag`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const addTagThunk = createAsyncThunk(
  "get/admin/admin_frontend/trending/addtag",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/trending/addtag`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const editTagThunk = createAsyncThunk(
  "get/admin/admin_frontend/trending/edittag",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/trending/editTag`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteTagThunk = createAsyncThunk(
  "get/admin/admin_frontend/trending/deleteTag",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_frontend/trending/deleteTag`,
        data
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  updateDone: false,
  data: {
    trending: [],
  },
  status: {
    getAllTagThunk: IDLE,
    addTagThunk: IDLE,
    editTagThunk: IDLE,
    deleteTagThunk: IDLE,
  },
};

const adminFrontendTrendingSlice = createSlice({
  name: "adminFrontendTrending",
  initialState: initialState,
  reducers: {
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // get all Tags============================================================================================================================
      .addCase(getAllTagThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllTagThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.data.trending = payload.data;
            state.status.getAllTagThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllTagThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.getAllTagThunk = ERROR;
      })
      // Add Tag thunk============================================================================================================================
      .addCase(addTagThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTagThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.addTagThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(addTagThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.addTagThunk = ERROR;
      })
      // edit Tag thunk============================================================================================================================
      .addCase(editTagThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editTagThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.editTagThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(editTagThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.editTagThunk = ERROR;
      })
      // delter Tag thunk============================================================================================================================
      .addCase(deleteTagThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteTagThunk.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.loading = false;
            state.updateDone = !state.updateDone;
            state.status.deleteTagThunk = FULFILLED;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(deleteTagThunk.rejected, (state, action) => {
        state.errorData = action.payload.error;
        state.loading = false;
        state.status.deleteTagThunk = ERROR;
      });
  },
});

export default adminFrontendTrendingSlice.reducer;
export const { clearErrorSlice } = adminFrontendTrendingSlice.actions;
