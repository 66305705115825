import "./paymentbutton.css";

import { DISAPPROVED, 
//  RAZORPAY_ID, SUCCESS 
} from "../../constants/constants";
import { FailureAlert, LoadingPage } from "..";
import {
  clearErrorSlice,
  payUsingCreditThunk,
  // createOrderThunk,
  // createSubscriptionThunk,
  // verifyOrderThunk,
  // verifySubscriptionThunk,
} from "../../redux/features/paymentSlice/paymentSlice";
import { useDispatch, useSelector } from "react-redux";

import React from "react";

function CreditPaymentButton({ styles, id, text, paymentDetails, textPaid }) {
  const dispatch = useDispatch();
  const isDisable = () => {
    // console.log(paymentDetails?.status);
    return (
      !!paymentDetails?.paymentInitiated || paymentDetails.status === DISAPPROVED
    );
  };

  // error slice --------------------------------------------------------------
  const loading = useSelector((state) => state.rootReducer.payments.loading);
  const customAlert = useSelector(
    (state) => state.rootReducer.payments.isError
  );
  const error = useSelector((state) => state.rootReducer.payments.errorData);
  const setCustomAlert = () => {
    dispatch(clearErrorSlice());
  };
  // error slice --------------------------------------------------------------
  const handleClickCredit = () => {
    dispatch(
      payUsingCreditThunk({
        orderId: paymentDetails?.orderId,
        paymentAmount: paymentDetails?.price?.addedGst,
        deliveryCharges: 100,
      })
    );
  };
  return (
    <>
      {loading && <LoadingPage />}
      {customAlert && (
        <FailureAlert
          setAlert={setCustomAlert}
          message={error.message}
          type={error.type}
          errors={error.errors}
        />
      )}
      <button
        disabled={isDisable()}
        id={id}
        onClick={() => {
          handleClickCredit();
        }}
        style={styles}
      >
        Credit Payment
      </button>
    </>
  );
}

export default CreditPaymentButton;
