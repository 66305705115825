import {
  BASE_URL,
  ERROR,
  FULFILLED,
  IDLE,
  SUCCESS,
} from "../../../constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

axios.defaults.withCredentials = true;
//============================================================

export const createNewEmployee = createAsyncThunk(
  "post/admin/admin_access/create_emp",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_access/create_emp`,
        {
          name: data.name,
          phone: data.phone,
          email: data.email,
          location: data.location,
          jobRole: data.jobRole,
          isAdmin: data.isAdmin,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllEmployee = createAsyncThunk(
  "get/admin/admin_access/get_all_emp",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_access/get_all_emp`,
        {
          filter: data.filter,
          sort: data.sort,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "get/admin/admin_access/update_emp",
  async (data, idx) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_access/update_emp`,
        {
          name: data.name,
          phone: data.phone,
          email: data.email,
          location: data.location,
          jobRole: data.jobRole,
          isAdmin: data.isAdmin,
          currId: data.currId,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "get/admin/admin_access/update_emp",
  async (data) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/admin/admin_access/delete_emp`,
        {
          currId: data.currId,
        }
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "get/admin/roles/get_all",
  async (data) => {
    try {
      const res = await axios.get(`${BASE_URL}/admin/roles/get_all`);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  loading: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
  isError: false,
  //local constants
  addEmployee: false,
  addEmployeeConfirmation: false,
  editOption: { key: 0, value: false },
  editEmployeePopUp: { key: 0, value: false },
  updateDone: false,
  deletion: { key: 0, value: false },
  deletionConfirmation: { key: 0, value: false },
  lastAddedEmp: "",
  lastDeletedEmp: "",
  filter: {},
  sort: {},
  isFilterPopUp: false,
  status: {
    getAllEmployee: IDLE,
    updateEmployee: IDLE,
    createNewEmployee: IDLE,
    getAllRoles: IDLE,
  },
  data: {
    allEmps: [],
    roles: [],
  },
};

const adminAccessSlice = createSlice({
  name: "adminAccess",
  initialState: initialState,
  reducers: {
    setAllEmps: (state, action) => {
      state.data.allEmps = action.payload.data;
    },
    setAddemployeeSlice: (state, action) => {
      state.addEmployee = action.payload.value;
    },
    setaddEmployeeConfirmationSlice: (state, action) => {
      state.addEmployeeConfirmation = action.payload.value;
    },
    setEditOptionSlice: (state, action) => {
      state.editOption = action.payload.value;
    },
    setEditEmployeePopUpSlice: (state, action) => {
      state.editEmployeePopUp = action.payload.value;
    },
    setDeletionSlice: (state, action) => {
      state.deletion = action.payload.value;
    },
    setDeletionConfirmationSlice: (state, action) => {
      state.deletionConfirmation = action.payload.value;
    },
    setUpdateDoneSlice: (state, action) => {
      state.updateDone = action.payload.value;
    },
    setLastAddedEmp: (state, action) => {
      state.lastAddedEmp = action.payload.value;
    },
    setLastDeletedEmp: (state, action) => {
      state.lastDeletedEmp = action.payload.value;
    },
    setIsFilterPopUp: (state, action) => {
      state.isFilterPopUp = action.payload.value;
    },
    setAdminSort: (state, action) => {
      state.sort = action.payload.value;
    },
    setAdminFilter: (state, action) => {
      state.filter = action.payload.value;
    },
    clearErrorSlice: (state, action) => {
      state.isError = false;
      state.errorData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // get all emps ===============================================
      .addCase(getAllEmployee.pending, (state, action) => {
        // state.status.getAllEmployee = LOADING;
        state.loading = true;
      })
      .addCase(getAllEmployee.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.data.allEmps = payload.data;
            state.status.getAllEmployee = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllEmployee.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getAllEmployee = ERROR;
      })
      // Update emps ===============================================
      .addCase(updateEmployee.pending, (state, action) => {
        // state.status.updateEmployee = LOADING;
        state.loading = true;
      })
      .addCase(updateEmployee.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            state.status.updateEmployee = FULFILLED;
            state.loading = false;
            state.editEmployeePopUp = {
              ...state.editEmployeePopUp,
              value: !state.editEmployeePopUp.value,
            };
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.updateEmployee = ERROR;
      })
      // Update emps ===============================================
      .addCase(createNewEmployee.pending, (state, action) => {
        // state.status.createNewEmployee = LOADING;
        state.loading = true;
      })
      .addCase(createNewEmployee.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            state.addEmployeeConfirmation = !state.addEmployeeConfirmation;
            state.addEmployee = !state.addEmployee;
            state.status.createNewEmployee = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(createNewEmployee.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.createNewEmployee = ERROR;
      })
      // get all roles ===============================================
      .addCase(getAllRoles.pending, (state, action) => {
        // state.status.getAllRoles = LOADING;
        state.loading = true;
      })
      .addCase(getAllRoles.fulfilled, (state, { payload }) => {
        switch (payload.type) {
          case SUCCESS:
            state.updateDone = !state.updateDone;
            state.data.roles = payload.data;
            state.status.getAllRoles = FULFILLED;
            state.loading = false;
            break;
          default:
            state.loading = false;
            state.isError = true;
            state.errorData = {
              message: payload.message,
              type: payload.type,
              errors: payload.errors,
            };
        }
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.payload.error;
        state.status.getAllRoles = ERROR;
      });
  },
});

export default adminAccessSlice.reducer;
export const {
  setAddemployeeSlice,
  setaddEmployeeConfirmationSlice,
  setEditOptionSlice,
  setEditEmployeePopUpSlice,
  setDeletionSlice,
  setDeletionConfirmationSlice,
  setAllEmps,
  setUpdateDoneSlice,
  setLastDeletedEmp,
  setLastAddedEmp,
  setIsFilterPopUp,
  setAdminSort,
  setAdminFilter,
  clearErrorSlice,
} = adminAccessSlice.actions;
