import "./mylistinglendingcancelation.css";

import * as Yup from "yup";

import { Calender, OrderAccepted, SmallCross } from "../../assets";
import {
  deleteListingRequest,
  updateLendingCancellationStatus,
  updateListingRequestStatus,
} from "../../redux/features/listingRequestInvenotry/linstingReqSlice";

import { DatePicker } from "..";
import PopUp from "../PopUp/PopUp";
import React from "react";
import { SUCCESS } from "../../constants/constants";
import { dateFormatter } from "../../utils/displayUtils";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { usePopper } from "react-popper";
import { useState } from "react";

const Mylistinglendingcancelation = (props) => {
  const [cancellationReason, setCancellationReason] = useState("");
  const [isDone, setIsDone] = useState(false);
  const [calender, setCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const [pop, setPop] = useState();
  const [ref, setRef] = useState();

  const popUpParams = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  };
  const popper = usePopper(ref, pop, popUpParams);
  const cancelLending = () => {};

  const defaultReason = [
    "Product not required",
    "Product not required",
    "Product not required",
    "Product not required",
    "Others reason please specify to improve our service and products",
  ];

  const cancllationReasonForm = useFormik({
    initialValues: {
      cancellationDate: new Date(),
      cancellationReason: cancellationReason,
    },
    validationSchema: Yup.object({
      cancellationReason: Yup.string()
        .min(3, "Min length is 3")
        .max(300, "Max length is 300")
        .required("producnt name  is required"),
    }),

    onSubmit: (values) => {
      dispatch(
        updateListingRequestStatus({
          currId: props.listingReqData?.elm?._id,
          data: { ...values, resolutionStatus: "UNRESOLVED" },
        })
      ).then((data) => {
        if (data.payload.type == SUCCESS) {
          setIsDone(true);
          props.onclick();
        }
      });
    },
  });

  return (
    <>
      {/* <div className="mylisting-lending-cancelation-container"> */}
      <div className="mylisting-lending-cancelation-content">
        <p id="mylisting-cancel-text">
          Cancel Lending
          <img
            src={SmallCross}
            alt="smallcross"
            style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
            onClick={props.onclick}
          />
        </p>
        <p id="mylisting-cancel-desc">
          Lorem Ipsum you will be charged for cancelling the product before the
          tennure period. Cancellation request may take 24hr.
        </p>
        <p id="mylisting-select-cancel-reason-heading">
          <p style={{ fontSize: "0.875rem", color: "#D34E4E" }}>
            {cancllationReasonForm.values.cancellationReason == "" &&
              "select reason for cancellation"}
            &nbsp;
          </p>
          Reason for ending subscription
        </p>

        {defaultReason.map((elm, idx) => (
          <label htmlFor="">
            <input
              type="radio"
              value={elm}
              name="cancellationReason"
              id=""
              onChange={(e) => {
                setCancellationReason(e.target.value);
                cancllationReasonForm.handleChange(e);
              }}
            />
            <span>{elm}</span>
          </label>
        ))}

        <input
          type="text"
          name="cancellationReason"
          value={cancllationReasonForm.values.cancellationReason}
          id=""
          onChange={cancllationReasonForm.handleChange}
          placeholder={cancllationReasonForm.values.cancellationReason}
        />
        <div className="mylisting-cancellation-button-container">
          <div className="mylisting-cancellation-calender">
            <p>{dateFormatter(new Date())}</p>
            {/* <input
              name="cancellationDate"
              value={cancllationReasonForm.values.cancellationDate}
              onChange={cancllationReasonForm.handleChange}
              type="date"
              id=""
            /> */}
            <div
              ref={setRef}
              onClick={() => setCalender(true)}
              className="mylisting-cancellation-calender-logo"
            >
              <img src={Calender} alt="calender" />
            </div>
          </div>
          <PopUp display={calender} callback={setCalender} fixed={true}>
            <div
              ref={setPop}
              style={{ ...popper.styles.popper }}
              {...popper.attributes}
            >
              <DatePicker
                disbaleBeforeDate={new Date()}
                rounded={true}
                boxShadow={true}
                date={date}
                setDate={setDate}
              />
            </div>
          </PopUp>
          <button
            type="submit"
            id="lending-cancellation-btn"
            onClick={() => cancllationReasonForm.handleSubmit()}
          >
            Cancel Lending
          </button>
          {isDone && (
            <>
              <div className="mylisting-cancel-done-container">
                <div className="mylisting-cancel-done-content">
                  <img src={OrderAccepted} alt="orderaccept" />
                  <p id="cancellation-request-text">
                    Cancellation Request Sent
                  </p>
                  <p id="cancellation-request-desc">
                    Your order cancellation request is being processed. We will
                    get back to you within 24hrs, for further inquiry
                  </p>
                  <p id="cancellation-contact-text">Contact Us.</p>
                  <button onClick={props.onclick}>Done</button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Mylistinglendingcancelation;
