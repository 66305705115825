import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./adminpagination.css";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import { Navigation } from "swiper";
import React from "react";

const AdminPagination = ({
  usersFetchedData,
  page,
  setPage,
  handlePagination,
  pageSize,
}) => {
  const pagesize = pageSize;
  const paginationSlides = [...Array(usersFetchedData?.total).keys()];
  return (
    <>
      <div className="admin-paginated-base-container">
        <p className="admin-paginated-naviation-button prevpage">Previous</p>
        <Swiper
          slidesPerView={3}
          spaceBetween={2}
          style={{ padding: "0rem", width: "8rem" }}
          navigation={{
            prevEl: ".prevpage",
            nextEl: ".nextpage",
          }}
          loop={false}
          modules={[Navigation]}
          slidesPerGroup={3}
        >
          {paginationSlides.map((elm, idx) => (
            <SwiperSlide className="paginated-swiper-slide-container">
              <button
                className={`${
                  page == idx ? "paginate-buttons-active" : "paginate-buttons"
                }`}
                onClick={() => {
                  setPage(idx);
                  handlePagination && handlePagination(idx, pagesize);
                }}
              >
                {idx}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
        <p className="admin-paginated-naviation-button nextpage">Next</p>
      </div>
    </>
  );
};

export default AdminPagination;
