import React from 'react'
import { Calender, PopUpClose } from '../../../assets';

const Schedulepickup = (props) => {
    return (
        <div
          className="longterm-schedule-pickup-popup"
        >
          <div className="schedulte-pickup-popup-container">
            <div className="schedule-pickup-popup-title-container">
              <p id="sp-head">Schedule Pickup</p>
              <img
                onClick={() => props.setschedulepickup(!props.schedulepickup)}
                id="sp-close-btn"
                src={PopUpClose}
                alt=""
              />
            </div>
            <div className="schedule-pickup-popup-middle-container">
              <input
                type="text"
                placeholder="Ronak Kumar"
                className="schedule-pickup-input"
              />
              <div className="select-date-outer-container">
                <input
                  type="date"
                />
                <div className="selectdateinnercontainer">
                  <img src={Calender} alt="calendericon" />
                </div>
              </div>
              <select className="schedule-pickup-input" name="" id="">
                <option value="">Not Recieved</option>
              </select>
            </div>
            <div className="schedule-pickup-button-container">
              <button
                onClick={() => props.setschedulepickup(!props.schedulepickup)}
                id="assign-pickup-btn"
              >
                Assign Pickup
              </button>
            </div>
          </div>
        </div>
      );
}

export default Schedulepickup